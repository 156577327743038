let scrollOffset = 0;

export const lockScroll = () => {
  scrollOffset = window.scrollY;

  document.body.style.overflow = 'hidden';
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollOffset}px`;
  document.body.style.width = '100%';
};

export const unlockScroll = () => {
  document.body.style.overflow = '';
  document.body.style.position = '';
  document.body.style.top = '';
  document.body.style.width = '';
  window.scrollTo(0, scrollOffset);
};
