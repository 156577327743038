import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

class HistoryLink extends Component {
  static propTypes = {
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    backTo: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  render() {
    // removes staticContext check
    // eslint-disable-next-line
    const { children, location, to, backTo, staticContext, className, ...passableProps } = this.props;

    if (typeof to === 'string') {
      return (
        <Link
          to={{
            pathname: to,
            state: {
              prevLocation: location,
              backTo,
            },
          }}
          {...passableProps}
          className={className}
        >
          {children}
        </Link>
      );
    }

    const toCombined = {
      ...to,
      state: {
        ...to.state,
        backTo,
        prevLocation: location,
      },
    };

    return (
      <Link {...passableProps} to={toCombined} className={className}>
        {children}
      </Link>
    );
  }
}

export default withRouter(HistoryLink);
