import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-fela';
import classNames from 'classnames';

function Icon(props) {
  const IconComponent = require(`./${props.icon}`).default;

  const { style, styles, className, notifications, size, label } = props;

  const notificationClasses = {
    [styles.notificationCount]: true,
    [styles.largeNotification]: size >= 40,
  };

  return (
    <span className={classNames([styles.icon, className])} style={style}>
      <IconComponent />
      {notifications ? (
        <span className={classNames(notificationClasses)} aria-label={label ? label : 'notifications'}>
          {notifications > 99 ? '99+' : notifications}
        </span>
      ) : null}
    </span>
  );
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number,
  notifications: PropTypes.number,
  rotate: PropTypes.number,
};
Icon.defaultProps = {
  icon: 'Placeholder',
  className: null,
  fill: 'currentColor',
  size: 24,
};

const styles = {
  icon: props => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: `${props.size}px`,
    height: `${props.size}px`,
    background: 'transparent',
    pointerEvents: 'none',
    fill: props.fill,
    flexShrink: 0,
    transform: `rotate(${props.rotate}deg)`,

    '>svg': {
      width: '100%',
      height: '100%',
    },
  }),
  iconWrapper: props => ({
    position: 'relative',
  }),
  notificationCount: props => ({
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    height: '14px',
    minWidth: '14px',
    borderRadius: '11px',
    padding: '0 2px',
    backgroundColor: props.theme.colors.darkerRed,
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '9px',
    lineHeight: '14px',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.white,
  }),
  largeNotification: props => ({
    top: '-5px',
    right: '-8px',
    height: '22px',
    minWidth: '22px',
    border: '1px solid white',
    fontSize: '14px',
  }),
};

export default connect(styles)(Icon);
