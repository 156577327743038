import theme from '../../theme';
const styles = props => ({
  error: {
    color: theme.colors.red,
    fontSize: '12px',
    marginTop: '10px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5em',
  },
  smallText: {
    fontSize: '12px',

    color: '#A7A7A7',
  },
  link: {
    textDecoration: 'underline',
    color: theme.colors.blue,
  },
  container: {
    maxWidth: '357px',
    margin: '80px auto',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  subText: {
    fontWeight: 'normal',
    fontSize: '16px',
  },
  CTA: {
    fontSize: '14px',
    fontWeight: '300',
    padding: '5px',
    backgroundColor: 'rgba(26, 185, 196, 0.3)',
    borderRadius: theme.globalBorderRadius,
    margin: '20px 0',
  },
  btn: {
    borderRadius: '40px',
    backgroundColor: theme.colors.orange,
    padding: '0.5em 2em',
    color: theme.colors.white,
    boxShadow: '0px 3px 7px 0px rgba(0, 0, 0, 0.12)',
  },
  disabled: {
    borderRadius: '40px',
    backgroundColor: theme.colors.gray,
    padding: '0.5em 2em',
    color: theme.colors.white,
  },
  input: {
    padding: '10px 18px',
    borderRadius: theme.globalBorderRadius,
    border: `1px solid #A7A7A7`,
    width: '238px',
  },
  submitContainer: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: '0.5em',
  },
  processing: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5em',
  },
});

export default styles;
