import moment from 'moment';

moment.defineLocale('en-biology', {
  relativeTime: {
    h: 'this Week',
    hh: 'this Week',
    d: 'this Week',
    dd: number => {
      const weeks = Math.ceil(number / 7);
      return weeks > 1 ? weeks + ' Weeks' : 'this Week';
    },
    M: '1 Month',
    MM: '%d Months',
  },
});

moment.defineLocale('en-messaging', {
  relativeTime: {
    future: '%s ago',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

moment.defineLocale('en-profiles', {
  relativeTime: {
    s: '1 day',
    ss: '1 day',
    m: '1 day',
    mm: '1 day',
    h: '1 day',
    hh: '1 day',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});
