export const stateList = [
  {
    state: 'Alabama',
    short: 'AL',
    capital: 'Montgomery',
  },
  {
    state: 'Alaska',
    short: 'AK',
    capital: 'Juneau',
  },
  {
    state: 'American Samoa',
    short: 'AS',
    capital: 'Pago Pago',
  },
  {
    state: 'Arizona',
    short: 'AZ',
    capital: 'Phoenix',
  },
  {
    state: 'Arkansas',
    short: 'AR',
    capital: 'Little Rock',
  },
  {
    state: 'California',
    short: 'CA',
    capital: 'Sacramento',
  },
  {
    state: 'Colorado',
    short: 'CO',
    capital: 'Denver',
  },
  {
    state: 'Connecticut',
    short: 'CT',
    capital: 'Hartford',
  },
  {
    state: 'Delaware',
    short: 'DE',
    capital: 'Dover',
  },
  {
    state: 'District of Columbia',
    short: 'DC',
    capital: '',
  },
  {
    state: 'Federated States of Micronesia',
    short: 'FM',
    capital: 'Palikir',
  },
  {
    state: 'Florida',
    short: 'FL',
    capital: 'Tallahassee',
  },
  {
    state: 'Georgia',
    short: 'GA',
    capital: 'Atlanta',
  },
  {
    state: 'Guam',
    short: 'GU',
    capital: 'Hagåtña',
  },
  {
    state: 'Hawaii',
    short: 'HI',
    capital: 'Honolulu',
  },
  {
    state: 'Idaho',
    short: 'ID',
    capital: 'Boise',
  },
  {
    state: 'Illinois',
    short: 'IL',
    capital: 'Springfield',
  },
  {
    state: 'Indiana',
    short: 'IN',
    capital: 'Indianapolis',
  },
  {
    state: 'Iowa',
    short: 'IA',
    capital: 'Des Moines',
  },
  {
    state: 'Kansas',
    short: 'KS',
    capital: 'Topeka',
  },
  {
    state: 'Kentucky',
    short: 'KY',
    capital: 'Frankfort',
  },
  {
    state: 'Louisiana',
    short: 'LA',
    capital: 'Baton Rouge',
  },
  {
    state: 'Maine',
    short: 'ME',
    capital: 'Augusta',
  },
  {
    state: 'Marshall Islands',
    short: 'MH',
    capital: 'Majuro',
  },
  {
    state: 'Maryland',
    short: 'MD',
    capital: 'Annapolis',
  },
  {
    state: 'Massachusetts',
    short: 'MA',
    capital: 'Boston',
  },
  {
    state: 'Michigan',
    short: 'MI',
    capital: 'Lansing',
  },
  {
    state: 'Minnesota',
    short: 'MN',
    capital: 'Saint Paul',
  },
  {
    state: 'Mississippi',
    short: 'MS',
    capital: 'Jackson',
  },
  {
    state: 'Missouri',
    short: 'MO',
    capital: 'Jefferson City',
  },
  {
    state: 'Montana',
    short: 'MT',
    capital: 'Helena',
  },
  {
    state: 'Nebraska',
    short: 'NE',
    capital: 'Lincoln',
  },
  {
    state: 'Nevada',
    short: 'NV',
    capital: 'Carson City',
  },
  {
    state: 'New Hampshire',
    short: 'NH',
    capital: 'Concord',
  },
  {
    state: 'New Jersey',
    short: 'NJ',
    capital: 'Trenton',
  },
  {
    state: 'New Mexico',
    short: 'NM',
    capital: 'Santa Fe',
  },
  {
    state: 'New York',
    short: 'NY',
    capital: 'Albany',
  },
  {
    state: 'North Carolina',
    short: 'NC',
    capital: 'Raleigh',
  },
  {
    state: 'North Dakota',
    short: 'ND',
    capital: 'Bismarck',
  },
  {
    state: 'Northern Mariana Islands',
    short: 'MP',
    capital: 'Saipan',
  },
  {
    state: 'Ohio',
    short: 'OH',
    capital: 'Columbus',
  },
  {
    state: 'Oklahoma',
    short: 'OK',
    capital: 'Oklahoma City',
  },
  {
    state: 'Oregon',
    short: 'OR',
    capital: 'Salem',
  },
  {
    state: 'Palau',
    short: 'PW',
    capital: 'Ngerulmud',
  },
  {
    state: 'Pennsylvania',
    short: 'PA',
    capital: 'Harrisburg',
  },
  {
    state: 'Puerto Rico',
    short: 'PR',
    capital: 'San Juan',
  },
  {
    state: 'Rhode Island',
    short: 'RI',
    capital: 'Providence',
  },
  {
    state: 'South Carolina',
    short: 'SC',
    capital: 'Columbia',
  },
  {
    state: 'South Dakota',
    short: 'SD',
    capital: 'Pierre',
  },
  {
    state: 'Tennessee',
    short: 'TN',
    capital: 'Nashville',
  },
  {
    state: 'Texas',
    short: 'TX',
    capital: 'Austin',
  },
  {
    state: 'Utah',
    short: 'UT',
    capital: 'Salt Lake City',
  },
  {
    state: 'Vermont',
    short: 'VT',
    capital: 'Montpelier',
  },
  {
    state: 'Virgin Islands',
    short: 'VI',
    capital: 'Charlotte Amalie',
  },
  {
    state: 'Virginia',
    short: 'VA',
    capital: 'Richmond',
  },
  {
    state: 'Washington',
    short: 'WA',
    capital: 'Olympia',
  },
  {
    state: 'West Virginia',
    short: 'WV',
    capital: 'Charleston',
  },
  {
    state: 'Wisconsin',
    short: 'WI',
    capital: 'Madison',
  },
  {
    state: 'Wyoming',
    short: 'WY',
    capital: 'Cheyenne',
  },
];

export default function getStateData(state = '') {
  const formattedState = state.split('-').join(' ');
  const found = stateList.find(s => {
    return s.short === formattedState.toUpperCase() || s.state.toLowerCase() === formattedState.toLowerCase();
  });

  return found;
}
