import React, { Component } from 'react';
import { connect } from 'react-fela';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ButtonPlain extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    center: PropTypes.bool,
    variant: PropTypes.oneOf(['primary', 'red', 'lightBlue', 'orange']),
    fontSize: PropTypes.number,
    className: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    variant: 'primary',
    onClick: () => {},
  };

  handleClick = e => {
    e.currentTarget.blur();
    this.props.onClick(e);
  };

  render() {
    const { children, styles, variant, className, fontSize, center, onClick, ...passthrough } = this.props;

    const classes = {
      [styles.root]: true,
      [styles.red]: variant === 'red',
      [styles.orange]: variant === 'orange',
      [className]: className,
    };

    return (
      <button className={classNames(classes)} onClick={this.handleClick} {...passthrough}>
        {children}
      </button>
    );
  }
}

const styles = props => ({
  root: {
    display: 'inline-block',
    color: props.theme.colors.blue,
    cursor: props.disabled ? 'auto' : 'pointer',
    transition: props.theme.globalTransition,
    flexShrink: 0,
    fontWeight: props.theme.typography.sansBold,
    textDecoration: 'underline',
    fontSize: props.fontSize ? `${props.fontSize}px` : 'inherit',
    textAlign: props.center ? 'center' : 'left',
    backgroundColor: 'transparent',
    ':hover': {
      color: props.theme.colors.darkerBlue,
    },
    ':disabled': {
      color: props.theme.colors.disabledText,
    },
    ':disabled:hover': {
      color: props.theme.colors.disabledText,
    },
    ':active': {
      outline: 'none',
    },
  },
  red: {
    color: props.theme.colors.red,
    ':hover': {
      color: `${props.theme.colors.darkerRed} !important`,
    },
  },
  orange: {
    color: `${props.theme.colors.orange} !important`,
    ':hover': {
      color: `${props.theme.colors.orangeHover} !important`,
    },
  },
  lightBlue: {
    color: '#46BBF1',
    ':hover': {
      color: '#3EA6D6',
    },
  },
});

export default connect(styles)(ButtonPlain);
