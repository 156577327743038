import React from 'react';

function PuppyOutline() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M6.847 19.431c-.018-.637.114-1.348.213-1.997.392-2.574 1.52-4.854 3.21-6.194-.825-.464-1.427-1.391-1.856-2.75-.831-2.635.108-5.29 2.624-6.16 2.31-.8 4.096-.151 5.017 1.996.134-.021.275-.037.427-.045a.643.643 0 0 1 .614-.44c.355.009.637.316.629.688a.702.702 0 0 1-.044.224c.83.982 1.53 3.375-1.509 5.78 1.584.988 1.914 3.321 3.455 7.11 1.32 3.24 1.99 3.825.612 3.844-1.051.015-3.09-4.538-3.09-4.538.155 2.899-1.978 3.186-1.978 3.186 3.335-.038 2.8 1.52 2.8 1.52-1.322.25-2.758.373-4.26.34-1.675-.037-3.874-.319-5.154-.638-1.515.087-4.589-.158-4.557-3.574 0 0 1.412 1.871 2.847 1.648"
        stroke="#1AB9C4"
        strokeWidth=".25"
        fill="none"
        fillRule="evenodd"
        strokeDasharray=".621"
      />
    </svg>
  );
  /* eslint-enable */
}

export default PuppyOutline;
