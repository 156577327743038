import { connect } from 'react-fela';
import Icon from '../../../../common/components/icons/icon';
import theme from '../../../../theme';

const EmailVerified = ({ user, styles }) => {
  return (
    <div>
      <div className={styles.successIcon}>
        <Icon size={90} fill={theme.colors.green} icon="CheckCircle" />
      </div>
      <div className={styles.container}>
        <div className={styles.body}>
          <p>
            <b className={styles.bold}>Email Verified</b>
            <br />
            Your verified email address is <b className={styles.bold}>{user.get('email')}</b>
            <br />
            This is the first step in building a community of trust. Thanks!
            <br />
            <b className={styles.bold}>You can close this tab at any time.</b>
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  successIcon: props => ({
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
    marginBottom: '40px',
    marginTop: '40px',
    textAlign: 'center',
  }),
  container: props => ({
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
  }),
  body: props => ({
    padding: '0 20px 40px 20px',
    textAlign: 'center',
    borderBottom: props.theme.globalDashedBorder,
  }),
  bold: props => ({
    fontWeight: props.theme.typography.sansBold,
  }),
};

export default connect(styles)(EmailVerified);
