import React from 'react';
import { Field, withFormik } from 'formik';
import { Map } from 'immutable';
import compose from 'lodash/flowRight';
import styled from 'styled-components';
import * as yup from 'yup';

import {
  Button,
  InputError,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PlainLink,
  StarRating,
  Textarea,
  TextInput,
} from '../';
import DatePickerInput from '../DatePickerInput';

const BodyText = styled.p`
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
`;

const ErrorContainer = styled.div`
  div {
    width: 100%;
    text-align: center;
  }
`;

const StarRatingContainer = styled(StarRating)`
  margin-top: 20px;

  > .dv-star-rating-star {
    margin: 0 4px;
  }
`;

const SellerInfo = styled.div`{
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  margin-bottom: 2%;
  > div {
    min-width: 49%;
  }
`;

export const TestimonialModalSellerForm = ({
  errors = {},
  handleSubmit = () => {},
  isSubmitting,
  seller = Map({}),
  setFieldValue = () => {},
  status = {},
  values = {},
  isReply = false,
}) => {
  const modalHeaderText = isReply ? 'Write a Reply' : 'Leave a Testimonial';
  const sellerName = seller.get('name')
    ? seller.get('name')
    : `${seller.get('first_name')} ${seller.get('last_name')?.charAt(0)}`;

  const removeNotNumericCharacters = event => {
    const v = event.target.value.replace(/\D/g, '');
    setFieldValue('price', v);
  };
  return (
    <form id="testimonial-seller-form" onSubmit={handleSubmit}>
      <ModalHeader title={modalHeaderText} />
      <ModalBody>
        {isReply ? null : (
          <div>
            <BodyText>
              All testimonials must follow our{' '}
              <PlainLink
                to="https://help.puppies.com/article/125-puppies-com-community-standards"
                target="new"
                variant="blue"
              >
                community standards
              </PlainLink>
              . You are leaving a testimonial for <strong>{sellerName}</strong>.
            </BodyText>
            <SellerInfo>
              <Field name="date_of_purchase" component={DatePickerInput} label="Date of Purchase (required)" />
              <Field
                name="price"
                component={TextInput}
                label="Price (required)"
                type="text"
                onChange={event => {
                  removeNotNumericCharacters(event);
                }}
              />
              <Field name="payment_method" component={TextInput} label="Payment Method (required)" />
            </SellerInfo>
          </div>
        )}

        <Field
          label="Comment (required)"
          name={isReply ? 'reply' : 'body'}
          component={Textarea}
          rows={8}
          maxLength={2000}
          value={isReply ? values.reply : values.body}
          aria-label={isReply ? 'Testimonial reply' : 'Testimonial text'}
        />
        {isReply ? null : (
          <StarRatingContainer
            name="rating"
            value={values.rating}
            onStarClick={value => {
              setFieldValue('rating', value);
            }}
          />
        )}
        <ErrorContainer>
          {errors.date_of_purchase && <InputError>{errors.date_of_purchase}</InputError>}
          {errors.price && <InputError>{errors.price}</InputError>}
          {errors.payment_method && <InputError>{errors.payment_method}</InputError>}
          {errors.body && <InputError>{errors.body}</InputError>}
          {errors.rating && <InputError>{errors.rating}</InputError>}
          {status.error && <InputError>{status.error}</InputError>}
        </ErrorContainer>
      </ModalBody>
      <ModalFooter
        actions={[
          <Button type="submit" buttonType="round" disabled={isSubmitting}>
            Submit {isReply ? 'Reply' : 'Testimonial'}
          </Button>,
        ]}
      />
    </form>
  );
};

export default compose(
  withFormik({
    mapPropsToValues: () => {
      return {
        rating: 0,
      };
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: props => {
      const shape = {};
      if (props.isReply) {
        shape.reply = yup.string().required();
      } else {
        shape.date_of_purchase = yup.string().required('Date of purchase is required');
        shape.price = yup.string().required();
        shape.body = yup.string().required('Comment required.');
        shape.payment_method = yup.string().required('Payment method is required.');
        shape.rating = yup
          .number()
          .min(1, 'Please provide a star rating for this seller.')
          .required();
      }
      return yup.object().shape(shape);
    },
    handleSubmit: (values, { props, resetForm, setStatus, setSubmitting }) => {
      const payload = { ...values };

      const { isReply, testimonialId, seller, replyToTestimonial, createOrUpdateTestimonial } = props;

      if (isReply) {
        const id = testimonialId;
        if (!id) {
          setStatus({ error: 'An error occurred. Please try again, or refresh the page.' });
          setSubmitting(false);
          return;
        }
        replyToTestimonial(id, payload.reply).then(action => {
          if (action?.response?.ok) {
            resetForm();
            setStatus({ success: true });
            props.onSave();
          } else {
            setStatus({
              error: action?.json?.message || `Could not submit reply at this time. Please try again later.`,
            });
            setSubmitting(false);
          }
        });
      } else {
        const id = seller?.getIn(['user', 'id']);
        if (!id) {
          setStatus({ error: 'An error occurred. Please try again, or refresh the page.' });
          setSubmitting(false);
          return;
        }
        createOrUpdateTestimonial(id, payload).then(action => {
          if (action?.response?.ok) {
            resetForm();
            setStatus({ success: true });
            props.onSave();
          } else {
            setStatus({
              error: action?.json?.message || `Could not submit testimonial at this time. Please try again later.`,
            });
            setSubmitting(false);
          }
        });
      }
    },
  })
)(TestimonialModalSellerForm);
