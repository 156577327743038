import client from '../../../common/utils/client';

export const getProfile = profileId => {
  return {
    promise: client.get(`users/${profileId}/profile`),
    type: 'GET_PROFILE',
  };
};

export const getProfileListings = profileId => {
  return {
    promise: client.get(`listings/search?seller=${profileId}&page_size=100`),
    type: 'GET_PROFILE_LISTINGS',
  };
};

export const getProfileListingsBySlug = slug => {
  return {
    promise: client.get(`listings/search?premier_slug=${slug}&page_size=100`),
    type: 'GET_PROFILE_LISTINGS',
  };
};

export const resetProfile = () => ({
  type: 'RESET_PROFILE',
});

export const resetProfileListings = () => ({
  type: 'RESET_PROFILE_LISTINGS',
});
