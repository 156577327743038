import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SearchListings from './SearchListings';

import { resetListingsSearch, searchListings } from './redux/actions';
import { resultsSelector } from './redux/selectors';
import { addSavedSearch, getRandomTips, getBreedInformation, getLocationInformation } from '../../redux/actions';
import { isLoggedInSelector } from '../../redux/selectors';

import { showAuthenticationModal } from '../Modal/redux/actions';

const mapStateToProps = state => {
  return {
    breedInformation: state.breedInformation,
    isLoggedIn: isLoggedInSelector(state),
    isMobile: state.browser.mediaType.indexOf('mobile') === 0,
    loaded: state.search.loaded,
    loading: state.search.loading,
    locationInformation: state.locationInformation,
    matchedPlace: state.search.matchedPlace,
    randomTips: state.randomTips,
    results: resultsSelector(state),
    total: state.search.total,
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      addSavedSearch,
      getBreedInformation,
      getLocationInformation,
      getRandomTips,
      resetListingsSearch,
      searchListings,
      showAuthenticationModal,
    },
    dispatch
  ),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchListings);
