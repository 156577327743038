import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import classNames from 'classnames';

function InitialsIcon(props) {
  const { initials, style, styles, className, notifications, onClick } = props;

  return (
    <span className={classNames([styles.icon, className])} onClick={onClick} style={style}>
      <span className={styles.text}>{initials}</span>
      {notifications && <span className={styles.notificationCount}>{notifications}</span>}
    </span>
  );
}

InitialsIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number,
  notifications: PropTypes.number,
  rotate: PropTypes.number,
  initials: PropTypes.string,
  whiteBg: PropTypes.bool,
};

InitialsIcon.defaultProps = {
  className: null,
  fill: 'currentColor',
  size: 24,
  initials: '',
};

const styles = {
  icon: props => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: `${props.size}px`,
    height: `${props.size}px`,
    background: `${props.whiteBg ? props.theme.colors.white : 'transparent'}`,
    fill: props.fill,
    userSelect: 'none',
    flexShrink: 0,
    transform: `rotate(${props.rotate}deg)`,
    fontFamily: props.theme.typography.sans,
    fontWeight: props.theme.typography.sansBold,
    borderWidth: `${props.size > 64 ? '4px' : props.size > 30 ? '3px' : '2px'}`,
    borderStyle: 'solid',
    borderColor: props.fill,
    borderRadius: '50%',

    '>svg': {
      width: '100%',
      height: '100%',
    },
  }),
  text: props => ({
    fontSize: `${props.size * 0.467}px`,
    color: props.fill,
  }),
  notificationCount: props => ({
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    height: '16px',
    width: '16px',
    borderRadius: '50%',
    backgroundColor: props.theme.colors.darkerRed,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.white,
  }),
};

export default connect(styles)(InitialsIcon);
