import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';
import debounce from 'lodash/debounce';
import compose from 'lodash/flowRight';
import { Button, Dropdown, Autocomplete } from '../../../common/components';
import { resetBreedsAutocomplete, updateBreedsAutocomplete } from '../../../redux/actions';
import { withRouter } from 'react-router-dom';

let breedName;

class SearchForm extends Component {
  updateAutocomplete = debounce(value => {
    if (value) {
      this.props.dispatch(updateBreedsAutocomplete(value));
    } else {
      this.props.dispatch(resetBreedsAutocomplete());
    }
    breedName = value;
  }, 250);

  handleBreedSelect = breed => {
    const path = `/breeds/${breed.id}`;
    this.props.history.push(path);
  };

  componentWillUnmount() {
    this.props.dispatch(resetBreedsAutocomplete());
  }

  render() {
    const { values, handleSubmit, isSubmitting, styles, breeds = [] } = this.props;
    return (
      <div className={styles.root}>
        <form id="search-form" onSubmit={handleSubmit}>
          <div className={styles.wrapper}>
            <div className={styles.formLeft}>
              <div className={styles.breedAutocomplete}>
                <Field
                  name="breed"
                  component={Autocomplete}
                  items={breeds}
                  disabled={false}
                  initialValue={this.props.formatted_breed}
                  onInputChange={this.updateAutocomplete}
                  onSelect={value => this.handleBreedSelect(value)}
                  placeholder="Type a breed"
                />
              </div>
              <span>OR</span>
              <div className={styles.breedGroupInput}>
                <Field
                  name="group_id"
                  component={Dropdown}
                  variant="secondary"
                  placeholder="Breed Group"
                  options={[{ value: '', label: 'All Breed Groups' }, ...values.breedGroupOptions]}
                />
              </div>
            </div>
            <div className={styles.button}>
              <Button type="submit" disabled={isSubmitting}>
                SEARCH
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const FormikSearchForm = withFormik({
  mapPropsToValues: props => ({
    group_id: props.locationObj.group_id ? parseInt(props.locationObj.group_id, 10) : '',
    breedGroupOptions: props.breedGroupOptions,
    formatted_breed: '',
    breed: '',
  }),
  enableReinitialize: true,
  handleSubmit: (values, { props, setSubmitting, setStatus }) => {
    if (breedName && !values.breed) {
      values.breed = breedName;
    }
    setStatus({});
    props.submitAction(values);
    setSubmitting(false);
  },
})(SearchForm);
const styles = props => ({
  root: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    [props.theme.breakpoints.mobileLarge]: {
      flexDirection: 'row',
    },
  },
  formLeft: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    '> * + *': {
      marginTop: '10px',
    },
    [props.theme.breakpoints.mobileLarge]: {
      flexDirection: 'row',
      '> * + *': {
        marginTop: 0,
        marginLeft: '10px',
      },
    },
  },
  breedAutocomplete: {
    width: '100%',
    [props.theme.breakpoints.mobileLarge]: {
      width: '200px',
    },
  },
  breedGroupInput: {
    width: '100%',
    [props.theme.breakpoints.mobileLarge]: {
      width: '171px',
    },
  },
  button: {
    marginTop: '15px',
    width: '100%',
    '>button': {
      width: '100%',
    },
    [props.theme.breakpoints.mobileLarge]: {
      width: 'inherit',
      marginLeft: '20px',
      marginTop: 0,
    },
  },
});

export default compose(felaConnect(styles), connect(), withRouter)(FormikSearchForm);
