import React, { Component, Fragment } from 'react';
import { Button, ButtonPlain, Modal, ModalHeader, ModalBody, ModalFooter } from '..';
import PropTypes from 'prop-types';

class ConfirmOnHoldModal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    onConfirmOnHold: PropTypes.func.isRequired,
    userVM: PropTypes.object.isRequired,
  };

  confirmChangeFragment() {
    const { visible, onRequestClose, onConfirmOnHold } = this.props;
    return (
      <Fragment>
        <Modal isOpen={visible} onClose={() => onRequestClose()} closeOnOutsideClick closeOnEscape>
          <ModalHeader title={'Mark as On Hold?'} variant="blue" />
          <ModalBody>
            If you're waiting on payment from a buyer, or need to hold a listing for a buyer, mark it as On Hold. This
            will prevent the listing from showing up in search.
          </ModalBody>
          <ModalFooter
            actions={[
              <ButtonPlain type="button" variant="red" onClick={() => onRequestClose()}>
                Cancel
              </ButtonPlain>,
              <Button type="button" buttonType="round" onClick={() => onConfirmOnHold()}>
                Confirm
              </Button>,
            ]}
          />
        </Modal>
      </Fragment>
    );
  }

  newSellerFragment() {
    // This scenario shouldn't happen, but just in case..
    const { onRequestClose } = this.props;
    return (
      <Fragment>
        <ModalHeader title={'Welcome to Puppies.com'} variant="blue" />
        <ModalBody>
          Your account is being reviewed by our staff. You may not change the listing status during this period.
        </ModalBody>
        <ModalFooter
          actions={[
            <ButtonPlain type="button" variant="red" onClick={() => onRequestClose()}>
              Ok
            </ButtonPlain>,
          ]}
        />
      </Fragment>
    );
  }

  expiredSellerFragment() {
    const { onRequestClose } = this.props;
    return (
      <Fragment>
        <ModalHeader title={'Membership Expired'} variant="blue" />
        <ModalBody>
          You must have an active paid membership to change your listing status. Please re-subscribe or purchase a new
          membership to continue.
        </ModalBody>
        <ModalFooter
          actions={[
            <ButtonPlain type="button" variant="red" onClick={() => onRequestClose()}>
              Cancel
            </ButtonPlain>,
            <Button
              type="button"
              buttonType="round"
              onClick={() => {
                window.location.href = '/account/membership';
              }}
            >
              Upgrade Now
            </Button>,
          ]}
        />
      </Fragment>
    );
  }

  membershipRequiredFragment() {
    const { userVM } = this.props;
    const isNewSeller = userVM && userVM.get('isNewSeller');
    return isNewSeller ? this.newSellerFragment() : this.expiredSellerFragment();
  }

  renderConfirmationModal() {
    const { userVM } = this.props;
    const hasActiveMembership = userVM && userVM.get('isActive') && !userVM.get('isNewSeller');

    return hasActiveMembership ? this.confirmChangeFragment() : this.membershipRequiredFragment();
  }

  render() {
    const { visible, onRequestClose } = this.props;

    return (
      <Modal isOpen={visible} onClose={onRequestClose} closeOnOutsideClick closeOnEscape>
        {this.renderConfirmationModal()}
      </Modal>
    );
  }
}
export default ConfirmOnHoldModal;
