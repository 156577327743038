import React from 'react';
import PropTypes from 'prop-types';

import { ButtonLink, Icon } from '../';
import theme from '../../../theme';

const BackButton = ({ location = {} }) => {
  const { state } = location;

  if (state?.backTo && state?.prevLocation) {
    return (
      <ButtonLink to={{ ...state.prevLocation }}>
        <Icon icon="CaretLeft" size={16} fill={theme.colors.blue} />
        <span>Back To {state.backTo}</span>
      </ButtonLink>
    );
  }

  return null;
};

BackButton.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      backTo: PropTypes.string,
      prevLocation: PropTypes.object,
    }),
  }),
};

export default BackButton;
