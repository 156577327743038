import React, { useState, useEffect } from 'react';
import { Button, ButtonPlain, ModalFooter, ModalBody } from '..';
const PurchaseBoosts = function({
  listingDays,
  boostedListings,
  user,
  styles,
  onSubmit,
  getBraintreeToken,
  resetBoost,
}) {
  const [token, setToken] = useState(null);
  const { handleSubmit, isFormLoaded, isFormSubmitting } = useBraintreePaymentForm(token, onSubmit);

  useEffect(() => {
    getBraintreeToken('boost', listingDays, boostedListings.length).then(res => {
      if (res && res.response.ok) {
        setToken(res.json.clientToken);
      } else {
        // setHasError(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form id="purchase-boost-form" onSubmit={handleSubmit}>
      <div className={styles.header}>
        <div className={styles.modalHeader}>Complete your purchase!</div>
        <div className={styles.boostText}>
          You'll be charged{' '}
          <strong>${(listingDays === 7 ? listingDays - 1 : listingDays) * boostedListings.length}</strong> for a{' '}
          <strong>{listingDays}-Day</strong> for <strong>{boostedListings.length}</strong> listings. This is a one-time
          charge.
        </div>
      </div>
      <ModalBody>
        <div id="braintree-container"></div>
      </ModalBody>

      {isFormLoaded ? (
        <ModalFooter
          actions={[
            <ButtonPlain
              variant="red"
              onClick={() => {
                resetBoost(1);
              }}
            >
              Cancel
            </ButtonPlain>,
            <Button buttonType="round" type="button" onClick={handleSubmit} disabled={isFormSubmitting}>
              Pay Now
            </Button>,
          ]}
        />
      ) : null}

      <input
        type="hidden"
        data-for="google"
        id="payment_amount"
        value={((listingDays === 7 ? listingDays - 1 : listingDays) * boostedListings.length) / 100}
      />
      <input type="hidden" data-for="google" id="user_email" value={user.get('email')} />
    </form>
  );
};

const useBraintreePaymentForm = (token, onSubmit) => {
  const [isFormLoaded, setIsFormLoaded] = React.useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);
  const [instance, setInstance] = React.useState(null);

  React.useEffect(() => {
    if (token) {
      (async () => {
        const braintree = await import('braintree-web-drop-in');
        braintree.create(
          {
            authorization: token,
            container: '#braintree-container',
            card: {
              cardholderName: {
                required: true,
              },
              overrides: {
                styles: {
                  input: {
                    padding: '0 8px',
                  },
                },
              },
            },
          },
          (err, instance) => {
            if (instance) {
              setInstance(instance);
              setIsFormLoaded(true);
            } else {
              console.error(err || 'Payment form instance could not be initialized');
            }
          }
        );
      })();
    }
  }, [token]);

  React.useEffect(() => {
    return () => {
      if (instance) {
        instance.teardown();
      }
    };
  }, [instance]);

  const handleSubmit = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setIsFormSubmitting(true);

    if (!instance) {
      console.error('Payment form instance not initialized yet');
      setIsFormSubmitting(false);
    } else {
      instance.requestPaymentMethod((err, payload) => {
        if (payload) {
          onSubmit(payload.nonce);
        } else {
          console.error(err || 'An unknown error occurred when submitting payment form');
          setIsFormSubmitting(false);
        }
      });
    }
  };

  return {
    handleSubmit,
    isFormLoaded,
    isFormSubmitting,
  };
};
export default PurchaseBoosts;
