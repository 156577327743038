import React from 'react';
import { connect as felaConnect } from 'react-fela';

class VerifyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.setState({
      sessionId: this.props.sessionId,
      closeModal: this.props.closeModal,
      modalVisible: this.props.modalVisible,
    });
  }

  render() {
    const { styles, sessionId } = this.props;
    return (
      <a
        href={`https://ivs.idenfy.com/api/v2/redirect?authToken=${sessionId}`}
        target="_blank"
        rel="noreferrer"
        className={styles.idVerifyButton}
      >
        Continue
      </a>
    );
  }
}

const styles = {
  idVerifyButton: {
    whiteSpace: 'nowrap',
    border: '2px solid transparent',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 3px 0px',
    padding: '6px 12px',
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: '40px',
    color: 'rgb(26, 185, 196)',
    transition: 'all 100ms ease-in-out 0s',
    textTransform: 'uppercase',
    fontWeight: 'bolder',
    fontSize: '12px',
    '&:hover': {
      borderColor: 'rgb(250, 97, 37)',
    },
  },
};

export default felaConnect(styles)(VerifyButton);
