import { connect } from 'react-redux';

import { isLoggedInSelector } from '../../../../../redux/selectors';
import FavoritePremiers from './FavoritePremiers';

export default connect(state => ({
  user: state.user,
  isLoggedIn: isLoggedInSelector(state),
  results: state.account.favoritePremiersResults,
  loaded: state.account.favoritePremiersLoaded,
  loading: state.account.favoritePremiersLoading,
  total: state.account.favoritePremiersTotal,
}))(FavoritePremiers);
