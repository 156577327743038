import React, { Component } from 'react';
import uuid from 'uuid';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-fela';
import get from 'lodash/get';
import moment from 'moment';

import { ButtonPlain, SubNav, Loading, Icon } from '../../../../common/components/';
import theme from '../.././../../theme';
import ListingForm from '../../common/ListingForm';
import getListingLimit from '../../../../common/utils/listingLimits';
import { getCoatPatterns, getColors, getListingCount } from '../../../../redux/actions';
import { Helmet } from 'react-helmet';
class AddListing extends Component {
  static propTypes = {
    coatPatternsLoading: PropTypes.bool.isRequired,
    coatPatterns: PropTypes.array.isRequired,
  };

  state = {
    createdListing: null,
    instanceId: uuid.v4(),
  };

  static fetchData(location, match, user) {
    return [getCoatPatterns(), getColors(), getListingCount()];
  }

  componentDidMount() {
    document.title = 'New Listing - Puppies.com';
    const { location, match, user, dispatch, coatPatterns, colors } = this.props;

    if (!colors.size || !coatPatterns.length) {
      AddListing.fetchData(location, match, user).forEach(action => dispatch(action));
    } else {
      dispatch(getListingCount());
    }
  }

  isDuplicate() {
    return !!get(this.props, 'location.state.duplicate');
  }

  onDuplicate = () => {
    if (!this.state.createdListing) {
      throw new Error('Expected createdListing in state');
    }
    this.props.history.push(`/listings/duplicate`, { duplicate: this.state.createdListing });
  };

  isDuplicateLoaded() {
    return !this.duplicated;
  }

  onSubmit = values => {
    return this.props.createListing(values).then(action => {
      if (action?.response.ok) {
        this.setState({ createdListing: action.json });
      }
      return action;
    });
  };

  getFormValues() {
    if (!this.isDuplicate()) {
      return {};
    }

    const duplicatedListing = this.props.location.state.duplicate;

    const birthDateTime = duplicatedListing.birthdate;
    const birthDate = birthDateTime.substr(0, birthDateTime.indexOf('T'));

    return {
      birthdate: moment(birthDate),
      breed: get(duplicatedListing, 'breeds.0.id'),
      breed2: get(duplicatedListing, 'breeds.1.id'),
      formatted_breed: get(duplicatedListing, 'breeds.0.name'),
      formatted_breed2: get(duplicatedListing, 'breeds.1.name'),
      pickup_available: duplicatedListing.pickup_available,
      local_delivery_available: duplicatedListing.local_delivery_available,
      shipping_available: duplicatedListing.shipping_available,
      champion_sired: duplicatedListing.champion_sired,
      show_quality: duplicatedListing.show_quality,
      champion_blood: duplicatedListing.champion_blood,
      registered: duplicatedListing.registered,
      registrable: duplicatedListing.registrable,
      vaccinated: duplicatedListing.vaccinated,
      vet_exam: duplicatedListing.vet_exam,
      health_cert: duplicatedListing.health_cert,
      health_guarantee: duplicatedListing.health_guarantee,
      pedigree: duplicatedListing.pedigree,
      description: duplicatedListing.description,
      colors: duplicatedListing.colors.map(color => color.id),
      breeding_certificate: duplicatedListing.breeding_certificate,
    };
  }

  renderHeaderMessage = () => {
    return this.props.user.get('listings').size > 0 ? 'Create a New Listing' : 'Create Your First Listing';
  };

  renderSubNav = () => {
    const { location, styles } = this.props;
    if (this.isDuplicate() && !this.state.errorLoadingDuplicate) {
      return (
        <SubNav
          helperText="This is a duplicate of the previous listing"
          helperTextIcon={<Icon icon="Duplicate" size={20} fill={theme.colors.orange} />}
          childrenClassName={styles.subnavChildren}
          keepMainHelperText
          headerRightChildren={
            <Link className={styles.link} to={(location.state && location.state.fromPath) || '/listings'}>
              <ButtonPlain variant="red">Cancel</ButtonPlain>
            </Link>
          }
        />
      );
    }
    return (
      <SubNav
        titleText={<strong>{this.renderHeaderMessage()}</strong>}
        childrenClassName={styles.subnavChildren}
        keepMainHelperText
        headerRightChildren={
          <Link className={styles.link} to={(location.state && location.state.fromPath) || '/listings'}>
            <ButtonPlain variant="red">Cancel</ButtonPlain>
          </Link>
        }
      />
    );
  };

  render() {
    if (this.props.coatPatternsLoading || !this.props.user || this.props.colorsLoading) {
      return <Loading dark center />;
    }

    const { colors, styles, coatPatterns, user, userVM, listingCount } = this.props;
    if (!user.get('lat') && !user.get('lng') && !user.get('formatted_location')) {
      return (
        <Redirect
          to={{
            pathname: '/account/my-profile',
            state: { backTo: '/listings/create' },
          }}
        />
      );
    }
    const userId = user.get('id');

    return (
      <div className={styles.root}>
        <Helmet>
          <title>Create a New Listing - Puppies.com</title>
        </Helmet>
        {this.renderSubNav()}
        <div className={styles.container}>
          <ListingForm
            key={this.state.instanceId}
            onResetForm={() => this.setState({ instanceId: uuid.v4() })}
            userId={userId}
            coatPatterns={coatPatterns}
            submitAction={this.onSubmit}
            onDuplicate={this.onDuplicate}
            formValues={this.getFormValues()}
            colors={colors}
            user={user}
            userVM={userVM}
            atListingLimit={listingCount > getListingLimit(userVM) - 2}
            disallowAddNew={listingCount > getListingLimit(userVM) - 1}
            getUploadToken={this.props.getUploadToken}
          />
        </div>
      </div>
    );
  }
}

const styles = props => ({
  root: {
    backgroundColor: props.theme.colors.tan,
  },
  container: {
    width: '100%',
    ...props.theme.globalPageWrapper,
    display: 'block',
  },
  link: {
    fontWeight: props.theme.typography.sansBold,
    textDecoration: 'underline',
  },
  subnavChildren: {
    paddingBottom: '10px',
    textAlign: 'center',
    [props.theme.breakpoints.mobileLarge]: {
      paddingBottom: 0,
      textAlign: 'left',
    },
  },
});

export default connect(styles)(AddListing);
