import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import classNames from 'classnames';

class Label extends Component {
  static propTypes = {
    children: PropTypes.node,
    styles: PropTypes.object,
    disabled: PropTypes.bool,
    variant: PropTypes.oneOf(['primary', 'secondary']),
  };
  static defaultProps = {
    children: null,
    disabled: false,
    variant: 'primary',
  };
  render() {
    const { children, styles, variant, disabled, ...passableProps } = this.props;

    const labelClasses = {
      [styles.label]: true,
      [styles.primary]: variant === 'primary',
      [styles.secondary]: variant === 'secondary',
      [styles.disabled]: disabled,
    };

    return (
      <label className={classNames(labelClasses)} {...passableProps}>
        {children}
      </label>
    );
  }
}

const styles = {
  label: props => ({
    fontWeight: props.theme.typography.sansBold,
    display: 'block',
    fontSize: '12px',
    textTransform: 'uppercase',
    '> input': {
      marginTop: '8px',
    },
  }),
  primary: props => ({
    color: props.theme.colors.blue,
  }),
  secondary: props => ({
    textTransform: 'capitalize',
    color: props.theme.typography.bodyColor,
  }),
  disabled: props => ({
    color: props.theme.colors.disabledLabel,
  }),
};

export default connect(styles)(Label);
