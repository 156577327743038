import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ErrorBar, BreedLayoutImage, PlainLink, Loading } from '../../common/components';
import { fetchRandomBreed, resetBreed } from '../../redux/actions';

class UserAuthenticationLayout extends Component {
  static propTypes = {
    children: PropTypes.node,
    errorBarMessage: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    signIn: PropTypes.bool,
    signUp: PropTypes.bool,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchRandomBreed());
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetBreed());
  }

  render() {
    const { styles, errorBarMessage, isTablet, title, subTitle, signIn, signUp, children, randomBreed } = this.props;

    if (!randomBreed) {
      return <Loading center dark />;
    }

    return (
      <div className={styles.root}>
        {errorBarMessage ? <ErrorBar message={errorBarMessage} /> : null}
        <div className={styles.container}>
          {!isTablet && <BreedLayoutImage breed={randomBreed} />}
          <div className={styles.wrapper}>
            <div className={styles.formWrapper}>
              {title ? <h1 className={styles.title}>{title}</h1> : null}
              {subTitle ? <p className={styles.subTitle}>{subTitle}</p> : null}
              {children}
              {signIn ? (
                <Fragment>
                  <div className={styles.accountLink}>
                    Need an account?{' '}
                    <PlainLink variant="blue" to="/sign-up">
                      Sign up for free!
                    </PlainLink>
                  </div>
                  <div className={styles.accountLink}>
                    Forgot your password?{' '}
                    <PlainLink variant="blue" to="/forgot-password">
                      Reset it here
                    </PlainLink>
                  </div>
                </Fragment>
              ) : null}
              {signUp ? (
                <div className={styles.accountLink}>
                  Already have an account?{' '}
                  <PlainLink variant="blue" to="/sign-in">
                    Sign in
                  </PlainLink>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = props => ({
  root: {
    ...props.theme.globalBackgroundContainer,
    [props.theme.breakpoints.tablet]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  container: {
    display: 'flex',
    height: '100%',
  },
  wrapper: {
    ...props.theme.globalPageWrapper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    [props.theme.breakpoints.tablet]: {
      flexBasis: '40%',
      maxWidth: '510px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      margin: '0 40px',
    },
  },
  title: {
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltBold,
    color: props.theme.colors.orange,
    fontSize: '30px',
    textAlign: 'center',
    [props.theme.breakpoints.tablet]: {
      textAlign: 'left',
      paddingTop: '0px',
    },
  },
  subTitle: {
    textAlign: 'center',
    marginTop: '12px',
    fontSize: '14px',
    [props.theme.breakpoints.tablet]: {
      textAlign: 'left',
    },
    marginBottom: '12px',
  },
  buttonWrapper: {
    marginTop: '20px',
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    margin: '30px 0',
    fontWeight: props.theme.typography.sansBold,
    '> * + *': {
      marginLeft: '10px',
    },
  },
  dividerBorder: {
    height: '1px',
    width: '100%',
    borderTop: props.theme.globalDashedBorder,
  },
  accountLink: {
    marginTop: '24px',
    fontSize: '14px',
    textAlign: 'center',
    [props.theme.breakpoints.tablet]: {
      textAlign: 'left',
    },
  },
});

const mapStateToProps = state => ({
  isTablet: state.browser.lessThan.tabletLarge,
  randomBreed: state.breedInformation,
});

export default connect(mapStateToProps)(felaConnect(styles)(withRouter(UserAuthenticationLayout)));
