const colors = {
  blue: '#1AB9C4',
  blueGlow: 'rgba(26, 185, 196, .15)',
  brighterBlue: '#04C5FF',
  darkerBlue: '#1EA0AA',
  darkerBlueHover: '#2FC0CB',
  navyBlue: '#206175',
  premierBlue: '#0085F2',
  yellow: '#FFC232',
  orange: '#FA6125',
  boostRocket: '#FA7225',
  boostBorder: '#FED1BE',
  orangeHover: '#FF8859',
  red: '#EB3700',
  darkerRed: '#9B1412',
  brown: '#49372C',
  lightBrown: '#8A6D5B',
  extraLightBrown: '#BAA995',
  lightTan: '#F8F6F3',
  tan: '#F8F6F3',
  darkTan: '#EDEAE2',
  darkerTan: '#E9E5D9',
  black: '#000000',
  white: '#ffffff',
  gray: '#AAAAAA',
  darkerGray: '#595959',
  green: '#74CF3C',
  disabled: '#E6E6E6',
  disabledText: '#9B9B9B',
  disabledLabel: '#A4A4A4',
  textDark: '#414042',
  boostBlue: '#006AE7',
};

const breakpointPixelValues = {
  mobileSmall: 375,
  mobile: 576,
  mobileLarge: 810,
  tablet: 864,
  tabletMid: 960,
  tabletLarge: 1079,
  desktop: 1152,
};

const breakpoints = {
  mobileSmall: `@media (min-width: ${breakpointPixelValues.mobileSmall}px)`,
  mobile: `@media (min-width: ${breakpointPixelValues.mobile}px)`,
  mobileLarge: `@media (min-width: ${breakpointPixelValues.mobileLarge}px)`,
  tablet: `@media (min-width: ${breakpointPixelValues.tablet}px)`,
  tabletMid: `@media (min-width: ${breakpointPixelValues.tabletMid}px)`,
  tabletLarge: `@media (min-width: ${breakpointPixelValues.tabletLarge}px)`,
  desktop: `@media (min-width: ${breakpointPixelValues.desktop}px)`,
};

const browsers = {
  ie11: '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)',
  edge: '@supports (-ms-ime-align:auto)',
  firefox: '@-moz-document url-prefix()',
};

const globalPadding = {
  padding: '0 10px',
  [breakpoints.mobile]: {
    padding: '0 20px',
  },
};

const globalMaxWidth = '1108px';

const globalBackgroundContainer = {
  width: '100%',
  backgroundColor: colors.tan,
  color: colors.brown,
};

const globalPageWrapper = {
  width: '100%',
  maxWidth: globalMaxWidth,
  margin: '0 auto',
  ...globalPadding,
  paddingTop: '32px !important',
  paddingBottom: '32px !important',
};

const globalBorderRadius = '2px';

const globalBoxShadow = '0 1px 3px 0 rgba(0,0,0,0.24)';

const globalTransition = 'all 100ms ease-in-out';

const globalDashedBorder = '1px dashed #979797';

const typography = {
  sans: '"Open Sans", sans-serif',
  sansAlt: '"Antartida Rounded", sans-serif',
  sansLight: 300,
  sansRegular: 400,
  sansSemi: 600,
  sansBold: 700,
  sansAltLight: 300,
  sansAltMedium: 500,
  sansAltBold: 800,
  sansAltBlack: 900,
  bodyColor: colors.brown,
  anchorColor: colors.blue,
  fontBase: '1rem',
  titleText: {
    fontFamily: '"Antartida Rounded", sans-serif',
    fontWeight: 300,
    fontSize: '28px',
    color: colors.orange,
    lineHeight: '34px',
    '> strong': {
      fontWeight: 900,
    },
  },
};

const buttonTypes = {
  primary: {
    fontFamily: typography.sansAlt,
    fontWeight: typography.sansAltBold,
    backgroundColor: colors.orange,
    padding: '12px 30px',
    hoverBackgroundColor: colors.orangeHover,
    color: colors.white,
    hoverColor: colors.white,
    borderColor: 'transparent',
    minWidth: '160px',
    borderRadius: globalBorderRadius,
    boxShadow: globalBoxShadow,
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  primaryLarge: {
    fontFamily: typography.sansAlt,
    fontWeight: typography.sansAltBold,
    backgroundColor: colors.orange,
    padding: '16px 30px',
    hoverBackgroundColor: colors.orangeHover,
    color: colors.white,
    hoverColor: colors.white,
    borderColor: 'transparent',
    borderRadius: globalBorderRadius,
    boxShadow: globalBoxShadow,
    fontSize: '20px',
    textTransform: 'uppercase',
    [breakpoints.mobile]: {
      minWidth: '280px',
    },
  },
  round: {
    fontFamily: typography.sansAlt,
    fontWeight: typography.sansAltBlack,
    backgroundColor: colors.white,
    padding: '8px 20px',
    hoverBackgroundColor: colors.white,
    color: colors.blue,
    hoverColor: colors.blue,
    borderColor: 'transparent',
    hoverBorderColor: colors.orange,
    minWidth: '150px',
    borderRadius: '40px',
    boxShadow: globalBoxShadow,
    fontSize: '12px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    borderWidth: '2px',
  },
  roundSmall: {
    fontFamily: typography.sansAlt,
    fontWeight: typography.sansAltBlack,
    backgroundColor: colors.white,
    padding: '6px 12px',
    hoverBackgroundColor: colors.white,
    color: colors.blue,
    hoverColor: colors.blue,
    borderColor: 'transparent',
    hoverBorderColor: colors.orange,
    minWidth: 'auto',
    borderRadius: '40px',
    borderWidth: '2px',
    boxShadow: globalBoxShadow,
    fontSize: '12px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  roundOrange: {
    fontFamily: typography.sansAlt,
    fontWeight: typography.sansAltBlack,
    backgroundColor: colors.orange,
    padding: '10px 24px',
    hoverBackgroundColor: colors.orangeHover,
    color: colors.white,
    hoverColor: colors.white,
    borderColor: 'transparent',
    minWidth: '150px',
    borderRadius: '40px',
    boxShadow: globalBoxShadow,
    fontSize: '12px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  roundWhite: {
    fontFamily: typography.sansAlt,
    fontWeight: typography.sansAltBlack,
    backgroundColor: 'transparent',
    padding: '10px 16px',
    hoverBackgroundColor: colors.orangeHover,
    color: colors.white,
    hoverColor: colors.white,
    borderColor: colors.white,
    minWidth: '150px',
    borderRadius: '40px',
    boxShadow: globalBoxShadow,
    borderWidth: '2px',
    fontSize: '14px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  largeRoundOrangeOutline: {
    fontFamily: typography.sansAlt,
    fontWeight: typography.sansAltBlack,
    backgroundColor: colors.orange,
    padding: '10px 32px',
    hoverBackgroundColor: colors.orangeHover,
    color: colors.white,
    hoverColor: colors.white,
    borderColor: colors.white,
    borderWidth: '4px',
    minWidth: '150px',
    borderRadius: '40px',
    boxShadow: globalBoxShadow,
    fontSize: '20px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  blue: {
    fontFamily: typography.sans,
    fontWeight: typography.sansBold,
    backgroundColor: colors.darkerBlue,
    padding: '12px',
    hoverBackgroundColor: colors.darkerBlueHover,
    color: colors.white,
    hoverColor: colors.white,
    borderColor: 'transparent',
    minWidth: '128px',
    borderRadius: globalBorderRadius,
    boxShadow: '0 2px 0 0 #167178;',
    fontSize: '12px',
  },
  brandBlue: {
    fontFamily: typography.sans,
    fontWeight: typography.sansBold,
    backgroundColor: colors.blue,
    padding: '12px',
    hoverBackgroundColor: colors.darkerBlue,
    color: colors.white,
    hoverColor: colors.white,
    borderColor: 'transparent',
    minWidth: '128px',
    borderRadius: globalBorderRadius,
    boxShadow: `0 2px 0 0 ${colors.darkerBlue}`,
    fontSize: '12px',
  },
  white: {
    fontFamily: typography.sans,
    fontWeight: typography.sansBold,
    backgroundColor: colors.white,
    padding: '6px 12px',
    hoverBackgroundColor: colors.white,
    color: colors.blue,
    hoverColor: colors.blue,
    borderColor: 'transparent',
    minWidth: '90px',
    borderRadius: globalBorderRadius,
    boxShadow: '0 2px 0 0 #9B9B9B;',
    fontSize: '12px',
  },
  icon: {
    backgroundColor: colors.darkerBlue,
    padding: '10px',
    hoverBackgroundColor: colors.darkerBlueHover,
    color: colors.white,
    hoverColor: colors.white,
    borderColor: 'transparent',
    minWidth: 0,
    boxShadow: '0 2px 0 0 #167178;',
    borderRadius: globalBorderRadius,
  },
  smallOrange: {
    fontFamily: typography.sansAlt,
    fontWeight: typography.sansAltBlack,
    backgroundColor: colors.orange,
    hoverBackgroundColor: colors.orangeHover,
    padding: '10px 18px',
    color: colors.white,
    hoverColor: colors.white,
    borderColor: 'transparent',
    hoverBorderColor: colors.orange,
    borderRadius: '40px',
    boxShadow: globalBoxShadow,
    fontSize: '12px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    borderWidth: '2px',
  },
  boost: {
    fontFamily: typography.sansAlt,
    fontWeight: typography.sansAltBlack,
    backgroundColor: colors.boostBlue,
    padding: '10px 18px',
    color: colors.white,
    hoverColor: colors.white,
    borderColor: 'transparent',
    borderRadius: '40px',
  },
};
const defaultLinkStyles = {
  lineHeight: '14px',
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  gap: '5px',
  border: 'solid white 2px',
  ':hover': {
    backgroundColor: colors.orangeHover,
  },
};
const linkStyles = {
  roundWhite: {
    ...buttonTypes.roundWhite,
    ...defaultLinkStyles,
  },
  largeRoundOrangeOutline: {
    ...buttonTypes.largeRoundOrangeOutline,
    ...defaultLinkStyles,
  },
};

const globalContainer = {
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
};

const theme = {
  breakpoints,
  breakpointPixelValues,
  browsers,
  globalPadding,
  globalPageWrapper,
  globalBorderRadius,
  globalBoxShadow,
  globalTransition,
  globalDashedBorder,
  globalMaxWidth,
  globalBackgroundContainer,
  buttonTypes,
  colors,
  typography,
  globalContainer,
  linkStyles,
};

export default theme;
