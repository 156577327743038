import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import { Icon, InputError } from '..';

class ConfirmationModalBody extends Component {
  static propTypes = {
    icon: PropTypes.string,
    iconFill: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    noBorder: PropTypes.bool,
    actions: PropTypes.array,
    inputError: PropTypes.string,
  };

  render() {
    const { icon, iconFill, image, title, subTitle, styles, actions, inputError } = this.props;
    return (
      <div className={styles.root}>
        {icon && (
          <div className={styles.icon}>
            <Icon icon={icon} size={67} fill={iconFill} />
          </div>
        )}
        {image && image}
        <h1 className={styles.title}>{title}</h1>
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
        {inputError && (
          <div className={styles.inputError}>
            <InputError>{inputError}</InputError>
          </div>
        )}
        {actions && (
          <div className={styles.actions}>
            {actions.map((action, i) => (
              <div key={i}>{action}</div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const styles = props => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 30px',
    backgroundColor: '#F5F5F5',
    borderRadius: '6px',
  },
  title: {
    fontSize: '28px',
    fontWeight: props.theme.typography.sansBold,
    textAlign: 'center',
    margin: '20px 0',
    borderRadius: '3px',
    lineHeight: '30px',
  },
  subTitle: {
    fontSize: '14px',
    width: '100%',
  },
  actions: {
    width: '100%',
    padding: '0 30px',
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: props.actions && props.actions.length === 1 ? 'center' : 'space-between',
  },
  inputError: {
    margin: '20px 0 0',
  },
});

export default connect(styles)(ConfirmationModalBody);
