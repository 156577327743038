// this goes along with an admin file in admin-frontend/src/common/utils/listingReportReasons.js
// any updates to this file need to be made to the other file
module.exports = {
  inappropriate: 'Inappropriate content',
  scam: 'Suspicious/scammer account',
  breed: 'Incorrect breed',
  stud: 'Stud service listing',
  planned: 'Planned breeding listing',
  duplicate: 'Duplicate listing',
  sold: 'Sold listing',
  other: 'Other',
};
