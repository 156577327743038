import { Map } from 'immutable';
import React, { Fragment } from 'react';
import { Button, Icon } from '../../../../../common/components';
// import calculateCouponPricing from '../../../../../common/utils/calculateCouponPricing';
import formatPrice from '../../../../../common/utils/formatPrice';

const StandardCard = ({ styles, onSelectPlan, planId, membershipPlans, price }) => {
  const plan = membershipPlans.get(planId, Map());

  const fillColor = planId === 'thirty_day' ? '#FFC232' : '#74CF3C';
  return (
    <div className={[styles.premierCard, styles.membershipCard]}>
      <div
        className={styles.premierCardLeft}
        style={{
          borderTopWidth: '20px',
          borderTopStyle: 'solid',
          borderTopColor: fillColor,
        }}
      >
        <div className={styles.membershipCardTop}>
          <div className={styles.premierPuppyIcons}>
            <Icon icon="Puppy" size={24} fill={fillColor} className={styles.premierPuppyIcon} />
            <Icon icon="Puppy" size={48} fill={fillColor} />
          </div>
          <div className={styles.memberShipCardPricing}>
            <h3 className={styles.membershipCardTitle}>{plan.get('name')}</h3>
            <p className={styles.membershipCardFrequency}>One-time Charge</p>
            <div className={styles.membershipCardPayment}>
              <h4>{formatPrice(price)}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.premierCardRight}>
        <div className={styles.planInfoContainer}>
          <strong>Paid features include</strong>
          <ul className={styles.planPerksList}>
            <Fragment>
              <li>Access to the largest network of potential buyers.</li>
              <li>Your own profile page with listings and testimonials.</li>
              <li>Post up to 20 listings.</li>
              <li>Securely communicate with potential buyers.</li>
              <li>Track listing views and clicks.</li>
            </Fragment>
          </ul>
        </div>
        <div className={styles.planActionContainer}>
          <h4>Get a {plan.get('name')}!</h4>
          <p>{formatPrice(price)}, one-time charge</p>
          <Button
            id={`select-${plan.get('id')}-membership`}
            type="button"
            buttonType="round"
            fontSize="14px"
            fullWidth
            onClick={() => {
              onSelectPlan(planId);
            }}
          >
            SELECT PLAN
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StandardCard;
