import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import { Icon } from '../../components/';
import theme from '../../../theme';
import { Link } from 'react-router-dom';
class ErrorBar extends Component {
  render() {
    const { styles, message, link, linkText } = this.props;
    if (!message) {
      return null;
    }
    return (
      <div className={styles.root}>
        <div className={styles.message}>
          <Icon icon="Warning" size={16} fill={theme.colors.white} />
          {link ? (
            <div>
              <span>{message}</span>
              <Link style={{ textDecoration: 'underline', marginLeft: '5px' }} to={link}>
                {linkText}
              </Link>
            </div>
          ) : (
            <span>{message}</span>
          )}
        </div>
      </div>
    );
  }
}

ErrorBar.propTypes = {
  message: PropTypes.node,
};

const styles = {
  root: props => ({
    height: 'fit-content',
    padding: '12px 0',
    backgroundColor: props.theme.colors.red,
  }),
  message: props => ({
    display: 'flex',
    alignItems: 'center',
    ...props.theme.globalPadding,
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
    color: props.theme.colors.white,
    fontWeight: props.theme.typography.sansBold,
    '> * + *': {
      marginLeft: '10px',
    },
  }),
};

export default connect(styles)(ErrorBar);
