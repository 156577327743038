import querystring from 'query-string';

import { defaults, mapFormValuesToQuery } from './search-config';
import getStateData from '../../../common/utils/getStateData';
import toKebabCase from '../../../common/utils/toKebabCase';
import { getBreedFromUrl, getLocationFromUrl, formatCityAndState } from '../../../common/utils/search';

const DIFF_IGNORED_KEYS = ['city', 'location', 'saved_search', 'search_by', 'state'];
const FILTERED_IGNORED_KEYS = [...DIFF_IGNORED_KEYS, 'advanced', 'formatted_location', 'show_matchmaker'];

export const buildInitialQuery = match => {
  const breed = getBreedFromUrl(match?.params?.breedOrState);
  const matchLocation = getLocationFromUrl(match);

  const settings = {
    ...defaults,
    breed: breed ? [breed.id] : [],
    city: matchLocation ? matchLocation.city?.toLowerCase() : '',
    formatted_breed: breed ? [breed.name] : [],
    formatted_location: matchLocation ? matchLocation?.formatted_location : null,
    state: matchLocation ? matchLocation.short?.toUpperCase() : '',
  };

  if (matchLocation?.city) {
    settings.search_by = 'city';
  }

  return sanitizeQuery(mapFormValuesToQuery(settings));
};

export const getInitialValues = ({ isMobile, location, match }) => {
  const queryValues = querystring.parse(location?.search);
  const breed = getBreedFromUrl(match?.params?.breedOrState);
  const matchLocation = getLocationFromUrl(match);
  const city = matchLocation ? matchLocation.city.toLowerCase() : queryValues.city ? queryValues.city : null;
  const state = matchLocation ? matchLocation.short.toUpperCase() : queryValues.state ? queryValues.state : null;

  const settings = {
    ...defaults,
    advanced: isMobile ? true : defaults.advanced,
    breed: breed ? [breed.id] : [],
    city: city || '',
    formatted_breed: breed ? [breed.name] : [],
    formatted_location: matchLocation
      ? matchLocation?.formatted_location
      : city && state
      ? formatCityAndState(city, state)
      : null,
    state: state || '',
    ...queryValues, // override from query string
  };

  if (state) {
    settings.search_by = 'state';
  }

  if (city) {
    settings.search_by = 'city';
  }

  return settings;
};

export const getIsQueryFiltered = diff => {
  return Object.keys(diff).filter(key => !FILTERED_IGNORED_KEYS.includes(key)).length > 0;
};

export const getQueryDifferences = (query1 = {}, query2 = mapFormValuesToQuery(defaults)) => {
  return Object.keys(query1).reduce((diff, key) => {
    if (DIFF_IGNORED_KEYS.includes(key)) {
      return diff;
    }

    if (JSON.stringify(query1[key]) === JSON.stringify(query2[key])) {
      return diff;
    }

    return {
      ...diff,
      [key]: query1[key],
    };
  }, {});
};

export const getRedirectUrl = (settings = {}) => {
  const basePath = '/find-a-puppy';

  if (!Object.keys(settings).length) {
    return basePath;
  }

  let redirectPath = basePath;

  const hasBreed = settings.breed?.length === 1;
  if (hasBreed) {
    const breed = toKebabCase(settings.formatted_breed[0], ' ');
    redirectPath = `${redirectPath}/${breed}`;
  }

  const hasLocation = settings?.formatted_location;
  const hasState = settings?.state;

  if (hasLocation || hasState) {
    let city = '';
    let state = '';

    if (hasState) {
      state = settings.state;
    }

    if (hasLocation) {
      const location = settings.formatted_location.split(', ');
      city = location[0];
      state = location[1];
    }

    const stateData = getStateData(state);

    if (stateData) {
      const cityName = toKebabCase(city, ' ');
      const stateName = toKebabCase(stateData.state, ' ');

      redirectPath = `${redirectPath}/${stateName}`;

      if (cityName) {
        redirectPath = `${redirectPath}/${cityName}`;
      }
    }
  }

  return redirectPath;
};

// taken from listingSearchSchema from API
export const SAFE_KEYS = [
  'age',
  'birthdate',
  'breed',
  'coat_pattern',
  'city',
  'colors',
  'created_at',
  'distance',
  'favorited_by',
  'formatted_location',
  'has_photo',
  'ignore_legacy',
  'location',
  'page',
  'page_size',
  'premier_slug',
  'premiers_only',
  'price',
  'seller',
  'sex',
  'sort_breed',
  'sort_by',
  'sort_dir',
  'state',
  'affection_rating',
  'barking_rating',
  'cat_rating',
  'child_rating',
  'dog_rating',
  'exercise_rating',
  'grooming_rating',
  'is_hypoallergenic',
  'playfulness_rating',
  'size_max',
  'size_min',
  'space_rating',
  'trainability_rating',
  'watchdog_rating',
];

export const sanitizeQuery = (searchQuery = {}) => {
  const sanitized = { ...searchQuery };

  Object.keys(searchQuery).forEach(key => {
    if (!SAFE_KEYS.includes(key)) {
      delete sanitized[key];
    }
  });

  return sanitized;
};
