import React, { Component } from 'react';
import {
  ButtonPlain,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  Textarea,
  InputError,
  ConfirmationModalBody,
} from '..';
import { withFormik, Field } from 'formik';
import compose from 'lodash/flowRight';
import { connect as felaConnect } from 'react-fela';
import { withRouter } from 'react-router-dom';
import theme from '../../../theme';

class PremierUpdateModal extends Component {
  onClose = () => {
    const { setStatus, onClose, resetForm } = this.props;

    setStatus({ status: false, error: null });
    resetForm();
    onClose();
  };

  renderForm() {
    const { currentUpdate, values, handleSubmit, isSubmitting, status } = this.props;
    return (
      <form id="premier-puppy-update-form" onSubmit={handleSubmit}>
        <ModalHeader
          title="Post a Puppy Update!"
          subTitle="This will be visible to the public on your Premier Profile. Keep visitors to your page in the loop with a short update. Talk about new litters or any other changes!"
        />
        <ModalBody>
          <Field
            name="body"
            component={Textarea}
            rows={8}
            maxLength={500}
            value={values.body || currentUpdate?.get('body')}
          />
          {status && status.error ? <InputError>{status.error}</InputError> : null}
        </ModalBody>
        <ModalFooter
          actions={[
            <ButtonPlain type="button" variant="red" onClick={this.onClose} disabled={isSubmitting}>
              Cancel
            </ButtonPlain>,
            <Button type="submit" buttonType="round" disabled={isSubmitting || values.body.trim() === ''}>
              Post Update
            </Button>,
          ]}
        />
      </form>
    );
  }

  renderSuccess() {
    const { history, status, premierSlug } = this.props;

    return status && status.success ? (
      <ConfirmationModalBody
        icon="Megaphone"
        iconFill={theme.colors.yellow}
        title="Update Posted!"
        subTitle="We recommend posting one every week"
        actions={[
          <Button
            buttonType="round"
            onClick={() => (premierSlug ? history.push(`/premier/${premierSlug}`) : this.onClose())}
          >
            View Update
          </Button>,
        ]}
      />
    ) : null;
  }

  render() {
    const { isOpen, status } = this.props;

    if (isOpen) {
      return (
        <Modal isOpen={true} onClose={this.onClose} closeOnEscape closeOnOutsideClick>
          {status && status.success ? this.renderSuccess() : this.renderForm()}
        </Modal>
      );
    }

    return null;
  }
}

const styles = props => ({
  successModalBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    fontWeight: props.theme.typography.sansBold,
    padding: '120px 40px !important',
    color: props.theme.colors.white,
    '> h1': {
      fontSize: '35px',
      lineHeight: '47px',
      marginBottom: '42px',
      marginTop: '20px',
    },
  },
});

export default compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => ({
      body: '',
    }),
    handleSubmit(values, { props, setStatus, resetForm, setSubmitting }) {
      props.createPremierUpdate(values).then(action => {
        if (action.response.ok) {
          resetForm();
          setStatus({ success: true });
        } else {
          setStatus({ error: 'Could not post update at this time' });
          setSubmitting(false);
        }
      });
    },
  }),
  felaConnect(styles),
  withRouter
)(PremierUpdateModal);
