import React from 'react';

function Amex() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="16" viewBox="0 0 24 16" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <radialGradient
          cx="33.582%"
          cy="18.955%"
          fx="33.582%"
          fy="18.955%"
          r="100%"
          gradientTransform="matrix(0 1 -.65625 0 .46 -.146)"
          id="a"
        >
          <stop stopColor="#71C8F2" offset="0%" />
          <stop stopColor="#0071BD" offset="100%" />
        </radialGradient>
        <filter x="-2.1%" y="-3.2%" width="104.2%" height="106.3%" filterUnits="objectBoundingBox" id="c">
          <feMorphology radius="1" in="SourceAlpha" result="shadowSpreadInner1" />
          <feOffset in="shadowSpreadInner1" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" in="shadowInnerInner1" />
        </filter>
        <rect id="b" x="0" y="0" width="24" height="15.75" rx="1.125" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect fill="url(#a)" width="24" height="15.75" rx="1.125" />
        <use fillOpacity="0" fill="#E5E5E5" xlinkHref="#b" />
        <use fill="#000" filter="url(#c)" xlinkHref="#b" />
        <path
          d="M6.083 7.925l.317-.873-.342-.124v-.004l-.005.002-.006-.002v.004l-.343.12.309.877h-.466l.353.504.147-.406.141.402.354-.5h-.46zm.413 1.365H5.59l-.301.835h-1.66l1.742-4.5h1.38l1.724 4.5H6.786l-.29-.835zm3.85-1.864l.593-1.801h1.835v4.5h-1.541v-.88l-.312.88h-1.18l-.312-.874v.874H7.888v-4.5h1.83l.627 1.801zm-.015 2.12l-.017.046.017.013.017-.012-.017-.047zm4.029-2.478v.054h1.86v1.39h-1.86v.17h2.07v1.443h-3.664v-4.5h3.621v1.443H14.36zm1.277-.693h.319l.028-.028-.028-.029h-.319v.057zm-.166 1.497h.265l.055-.055-.055-.054h-.265v.109zm.21 1.56h.318l.028-.029-.028-.028h-.319v.057zm1.43-1.645l-1.497-2.162h1.902l.55.877.572-.877h1.841l-1.49 2.167 1.595 2.333h-1.92l-.638-.986-.65.986h-1.858l1.594-2.338z"
          stroke="#FFF"
          strokeWidth=".75"
          fill="none"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default Amex;
