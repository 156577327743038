import React from 'react';

function FatAdd() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M14.496 24H9.504C9.223 24 9 23.777 9 23.496V15H.504C.223 15 0 14.777 0 14.496V9.504C0 9.223.223 9 .504 9H9V.504C9 .223 9.223 0 9.504 0h4.992c.281 0 .504.223.504.504V9h8.496c.281 0 .504.223.504.504v4.992c0 .281-.223.504-.504.504H15v8.496c0 .281-.223.504-.504.504z" />
    </svg>
  );
  /* eslint-enable */
}

export default FatAdd;
