import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import PropTypes from 'prop-types';
import { Button, ModalFooter, ModalBody, ButtonPlain, Icon } from '../../../../common/components';
import styles from './styles';
import moment from 'moment/moment';

class AccountCancellationWizard extends Component {
  static propTypes = {
    userVM: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    cancelMembership: PropTypes.func.isRequired,
  };

  state = {
    showSuccess: false,
    errorMessage: '',
    cancellingPending: false,
  };

  cancelMembership = () => {
    const { cancelMembership } = this.props;
    cancelMembership().then(results => {
      if (results.response.ok) {
        this.setState({ showSuccess: true });
      } else {
        this.setState({ errorMessage: results.json.message });
      }
    });
  };

  renderCancelConfirmation = () => {
    const { onCancel, styles, userVM } = this.props;
    const paidThroughDate = userVM.get('expirationDate');

    // if (subscription.get('status') === 'Pending') {
    //   paidThroughDate = previousSubscription ? previousSubscription.get('paid_through_date') : paidThroughDate;
    //   if (!this.state.cancellingPending) {
    //     this.setState({ cancellingPending: true });
    //   }
    // }

    return (
      <div>
        <ModalBody>
          <div className={styles.cancelModalBody}>
            <Icon icon="Warning" size={40} />
            <h1>Sure about that?</h1>
            {this.state.errorMessage}
            <p>
              Your Premier Membership will remain active for{' '}
              <strong>{moment(paidThroughDate).diff(moment(), 'days')} days.</strong>
            </p>
            <p>
              This will cancel billing for the card on file for this membership, your listings will <strong>not</strong>{' '}
              be deleted.
            </p>
          </div>
        </ModalBody>
        <ModalFooter
          actions={[
            <ButtonPlain onClick={onCancel}>Never mind, go back</ButtonPlain>,
            <Button type="button" buttonType="round" onClick={this.cancelMembership} id="confirmCancelMembershipButton">
              CONFIRM
            </Button>,
          ]}
        />
      </div>
    );
  };

  renderSuccess = () => {
    const { styles, onCancel, userVM } = this.props;
    const paidThroughDate = userVM.get('expirationDate');

    // if (this.state.cancellingPending) {
    //   paidThroughDate = previousSubscription ? previousSubscription.get('paid_through_date') : paidThroughDate;
    // }

    const days = moment(paidThroughDate).diff(moment(), 'days');
    return (
      <div>
        <ModalBody>
          <div className={styles.cancelModalBody}>
            <h1>Membership canceled.</h1>
            <p>
              Your Premier Membership will expire in <strong>{days}</strong> day{days === 1 ? '' : 's'} and you will not
              be re-billed.
            </p>
          </div>
        </ModalBody>
        <ModalFooter
          actions={[
            <Button type="button" buttonType="round" onClick={onCancel} id="closeMembershipCanceledSuccessModal">
              DONE
            </Button>,
          ]}
        />
      </div>
    );
  };

  render() {
    return this.state.showSuccess ? this.renderSuccess() : this.renderCancelConfirmation();
  }
}

export default felaConnect(styles)(AccountCancellationWizard);
