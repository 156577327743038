import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import { Icon, Button, ButtonLink } from '../';
import classNames from 'classnames';

class NoContentPlaceholder extends Component {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  static defaultProps = {
    title: 'Sorry, no puppies are available at this time.',
  };

  render() {
    const {
      styles,
      title,
      icon,
      description,
      onClick,
      buttonText,
      className,
      globalPadding,
      topMargin,
      to,
      disabled,
      topTitle,
    } = this.props;

    const classes = {
      [styles.root]: true,
      [styles.globalPadding]: globalPadding,
      [styles.topMargin]: topMargin,
      [className]: className,
    };

    return (
      <div className={classNames(classes)}>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            {icon ? <Icon icon={icon} size={140} /> : null}
            {topTitle ? <h2 className={styles.topTitle}>{topTitle}</h2> : null}
            {title ? <h2 className={styles.title}>{title}</h2> : null}
            {description ? <p>{description}</p> : null}
            {onClick && (
              <div className={styles.button}>
                <Button buttonType="round" disabled={disabled} onClick={onClick}>
                  {buttonText}
                </Button>
              </div>
            )}
            {to && (
              <div className={styles.button}>
                <ButtonLink buttonType="round" disabled={disabled} to={to}>
                  {buttonText}
                </ButtonLink>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const styles = props => ({
  root: {
    width: '100%',
    margin: '20px auto',
    maxWidth: props.theme.globalMaxWidth,
  },
  globalPadding: {
    ...props.theme.globalPadding,
  },
  topMargin: {
    margin: '80px auto',
  },
  container: {
    backgroundColor: props.theme.colors.tan,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: 'max-content',
  },
  wrapper: {
    width: '100%',
    textAlign: 'center',
    padding: '32px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '> span': {
      height: 'auto',
      marginBottom: '16px',
    },
    '>p': {
      marginTop: '16px',
      fontSize: '14px',
      lineHeight: '24px',
      color: props.theme.colors.brown,
    },
  },
  title: {
    fontFamily: props.theme.typography.sans,
    fontWeight: props.theme.typography.sansRegular,
    fontSize: '28px',
    color: props.theme.colors.orange,
    lineHeight: '1.25',
    textAlign: 'center',
    '@media (max-width: 320px)': {
      fontSize: '24px',
    },
  },
  topTitle: {
    display: 'flex',
    alignSelf: 'flex-start',
    fontFamily: props.theme.typography.sans,
    fontWeight: props.theme.typography.sansRegular,
    fontSize: '24px',
    color: props.theme.colors.orange,
    lineHeight: '1.25',
    textAlign: 'center',

    '@media (max-width: 320px)': {
      fontSize: '20px',
    },
  },
  button: {
    marginTop: props.title || props.topTitle ? '24px' : '0',
    marginBottom: props.title || props.topTitle ? '10px' : '24px',
  },
});

export default felaConnect(styles)(NoContentPlaceholder);
