import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import classNames from 'classnames';

import { InputScaffold } from '../';

const checkedIcon = (
  <svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.667 0H2.333C1.045 0 0 1.045 0 2.333v16.334C0 19.955 1.045 21 2.333 21h16.334C19.955 21 21 19.955 21 18.667V2.333C21 1.045 19.955 0 18.667 0zm-10.5 16.333L2.333 10.5l1.645-1.645 4.189 4.177 8.855-8.855 1.645 1.656-10.5 10.5z" />
  </svg>
);

const uncheckedIcon = (
  <svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <rect id="a" width="21" height="21" rx="2" />
      <filter x="-4.8%" y="-4.8%" width="109.5%" height="109.5%" filterUnits="objectBoundingBox" id="b">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          values="0 0 0 0 0.607843137 0 0 0 0 0.607843137 0 0 0 0 0.607843137 0 0 0 1 0"
          in="shadowInnerInner1"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#FFF" xlinkHref="#a" />
      <use fill="#000" filter="url(#b)" xlinkHref="#a" />
      <rect stroke="#E6E6E6" strokeLinejoin="square" x=".5" y=".5" width="20" height="20" rx="2" />
    </g>
  </svg>
);

class Checkbox extends Component {
  static propTypes = {
    className: PropTypes.string,
    styles: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.string,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
    }),
    label: PropTypes.node,
    labelVariant: PropTypes.oneOf(['primary', 'secondary', 'labelLeft']),
    disabled: PropTypes.bool,
  };

  static defaultProps = { tabIndex: '0' };

  render() {
    const {
      field,
      form,
      className,
      styles,
      label,
      labelLeft,
      labelVariant,
      labelIcon,
      disabled,
      tabIndex,
      labelWrapperClass,
      noPadding,
      noLeftPadding,
      ...passableProps
    } = this.props;
    const error = form.touched[field.name] && form.errors[field.name];

    const labelWrapperClasses = {
      [styles.labelWrapper]: true,
      [labelWrapperClass]: labelWrapperClass,
    };

    return (
      <InputScaffold labelVariant={labelVariant} error={error} disabled={disabled}>
        <div
          className={styles.root}
          ref={l => {
            this.label = l;
          }}
          onKeyDown={e => {
            if (e.keyCode === 32 || e.keyCode === 13) {
              e.preventDefault();
              this.label.click();
            }
          }}
          tabIndex={passableProps.disabled ? null : tabIndex}
          onClick={() => this.label.blur()}
        >
          <input
            {...field}
            type="checkbox"
            checked={field.value}
            disabled={disabled}
            {...passableProps}
            style={{ display: 'none' }}
          />
          <span
            className={classNames(styles.toggle, noPadding && styles.noPadding, noLeftPadding && styles.noLeftPadding)}
          >
            {field.value ? checkedIcon : uncheckedIcon}
          </span>
          <div className={classNames(labelWrapperClasses)}>
            {labelIcon ? labelIcon : null}
            <span className={[styles.label, labelLeft ? styles.labelLeft : null]}>{label}</span>
          </div>
        </div>
      </InputScaffold>
    );
  }
}

const styles = {
  root: props => ({
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    opacity: props.disabled ? '0.65' : '1',

    // https://github.com/rofrischmann/fela/blob/master/docs/basics/Rules.md#6-other-selectors
    '& svg': {
      fill: props.field.value ? props.theme.colors.blue : props.theme.colors.gray400,
    },

    ':active': {
      outline: 'none',
    },
  }),
  toggle: props => ({
    position: 'relative',
    display: 'inline-block',
    width: '41px',
    height: '41px',
    borderRadius: '4px',
    padding: '10px',
    flexShrink: '0',
    overflow: 'hidden',
  }),
  noPadding: props => ({
    width: '21px',
    height: '21px',
    padding: '0',
  }),
  noLeftPadding: props => ({
    width: '32px',
    paddingLeft: '0',
  }),
  labelWrapper: props => ({
    display: 'flex',
    flexDirection: 'row',
    color: props.theme.colors.textDark,
    '> * + *': {
      marginLeft: '4px',
    },
  }),
  label: props => ({
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: '700',
    textTransform: 'capitalize', // force first letter to capitalize
    userSelect: 'none',
    paddingLeft: props.noPadding ? '10px' : 0,
  }),
  labelLeft: props => ({
    display: 'block',
    textAlign: 'left',
    lineHeight: '18px',
    padding: '0 12px !important',
  }),
};

export default connect(styles)(Checkbox);
