import getBreedFromUrlUtil from '../getBreedFromUrl';
import getStateData from '../getStateData';
import toKebabCase from '../toKebabCase';

export const formatCityAndState = (city = '', state = '') => {
  const formattedCity = city
    .split('-')
    .map(c => c.charAt(0).toUpperCase() + c.slice(1).toLowerCase())
    .join(' ');
  const formattedState = state.toUpperCase();

  return `${formattedCity}, ${formattedState}`;
};

export const getBreedFromUrl = getBreedFromUrlUtil;

export const getLocationFromUrl = (match = {}, forceCapital = false) => {
  if (match.params?.breedOrState) {
    const stateData = getStateData(match.params.breedOrState);
    if (stateData) {
      const state = {
        ...stateData,
        city: match.params?.stateOrCity || '',
      };

      if (forceCapital) {
        state.formatted_location = formatCityAndState(stateData.capital, stateData.short);
      }

      if (state.city) {
        state.formatted_location = formatCityAndState(state.city, stateData.short);
      }

      return state;
    }
  }

  if (match?.params?.stateOrCity) {
    const stateData = getStateData(match.params.stateOrCity);
    if (stateData) {
      const state = {
        ...stateData,
        city: match.params?.city || '',
      };

      if (forceCapital) {
        state.formatted_location = formatCityAndState(stateData.capital, stateData.short);
      }

      if (state.city) {
        state.formatted_location = formatCityAndState(state.city, stateData.short);
      }

      return state;
    }
  }

  return null;
};

export const getPathFromBreedAndLocation = (formattedBreed = '', formattedLocation = '') => {
  let path = '/find-a-puppy';

  if (formattedBreed) {
    const replacedBreed = formattedBreed
      .replace(/-/gi, '')
      .replace(/\(/gi, '')
      .replace(/\)/gi, '');
    path = `${path}/${toKebabCase(replacedBreed, ' ')}`;
  }

  if (formattedLocation) {
    const [city, state] = formattedLocation.split(', ');
    const stateData = getStateData(state);

    if (stateData) {
      path = `${path}/${toKebabCase(stateData.state, ' ')}/${toKebabCase(city, ' ')}`;
    }
  }

  return path;
};
