import React from 'react';

function Avatar() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M11.71 21.426c5.363 0 9.71-4.349 9.71-9.713C21.42 6.349 17.073 2 11.71 2S2 6.349 2 11.713c0 5.364 4.347 9.713 9.71 9.713zm0 1.333C5.61 22.76.667 17.813.667 11.713S5.61.667 11.71.667c6.1 0 11.044 4.945 11.044 11.046 0 6.1-4.945 11.046-11.044 11.046zm6.057-6.536c0-2.592-4.663-2.07-4.663-4.147 0 0 1.554-1.554 1.554-4.401a3.114 3.114 0 0 0-3.108-3.108 3.114 3.114 0 0 0-3.108 3.108c0 2.847 1.554 4.401 1.554 4.401 0 2.077-4.663 1.555-4.663 4.147v.516a.26.26 0 0 0 .261.261h11.912a.26.26 0 0 0 .26-.261v-.516z" />
    </svg>
  );
  /* eslint-enable */
}

export default Avatar;
