import React from 'react';
import PropTypes from 'prop-types';

import { Button, ModalBody, ModalFooter, ModalHeader } from '../../../../../common/components';

const RequestError = ({ onCancel = () => {} }) => {
  return (
    <>
      <ModalHeader title="Request Failure" />
      <ModalBody>Could not complete your request at this time. Please try again later.</ModalBody>
      <ModalFooter
        actions={[
          <Button buttonType="round" onClick={onCancel} type="button">
            DONE
          </Button>,
        ]}
      />
    </>
  );
};

RequestError.propTypes = {
  errors: PropTypes.number,
  onCancel: PropTypes.func,
};

export default RequestError;
