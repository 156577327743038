import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { resetPassword } from '../../redux/actions';
import { isLoggedInSelector } from '../../redux/selectors';
import ResetPassword from './ResetPassword';

const mapStateToProps = state => ({
  isLoggedIn: isLoggedInSelector(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({ resetPassword }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
