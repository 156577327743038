import { connect } from 'react-redux';

import BuyerLandingPage from './BuyerLandingPage';
import { isLoggedInSelector } from '../../redux/selectors';
const mapStateToProps = state => ({
  listings: state.landingSearch.results,
  loaded: state.landingSearch.loaded,
  error: state.landingSearch.error,
  isLoggedIn: isLoggedInSelector(state),
  user: state.user,
  userVM: state.userVM,
});

export default connect(mapStateToProps)(BuyerLandingPage);
