let TARGET_X_OFFSET = 10;
let TOOL_TIP_ARROW_WIDTH = 30;
const config = {
  currentTargetPosition(rect) {
    let oneThirdWidth = document.body.clientWidth / 3;
    let x = 'left';
    let y = 'top';

    if (rect.x >= oneThirdWidth * 2) {
      x = 'right';
    } else if (rect.x >= oneThirdWidth) {
      x = 'center';
    }

    let oneThirdHeight = document.body.clientHeight / 3;

    if (rect.y >= oneThirdHeight * 2) {
      y = 'bottom';
    } else if (rect.y >= oneThirdHeight) {
      y = 'center';
    }
    return {
      x,
      y,
    };
  },

  calculateTipPosition({ rect, toolTipContainer, target }) {
    let right;
    let left;
    let transform;
    let top = rect.height + rect.bottom;

    if (target.x === 'right') {
      left = rect.right - toolTipContainer.width + TARGET_X_OFFSET;
      if (target.y === 'center') {
        top = rect.bottom - toolTipContainer.height / 2 - rect.height / 2;
        left = rect.left - toolTipContainer.width - TOOL_TIP_ARROW_WIDTH;
      }
    } else if (target.x === 'center') {
      left = rect.right - toolTipContainer.width / 2;
    } else {
      left = rect.left - TARGET_X_OFFSET;
      if (target.y === 'center') {
        top = rect.bottom - toolTipContainer.height / 2 - rect.height / 2;
        left = rect.width + rect.x + 20;
      }
    }

    if (target.y === 'bottom') {
      top = rect.top - toolTipContainer.height - 20;
    }

    return {
      top,
      right,
      transform,
      left,
    };
  },

  calculateArrowPosition({ rect, toolTipContainer, target }) {
    // x
    let right;
    let left;
    let transform;
    let top = -20;

    if (target.x === 'right') {
      right = rect.width / 2 + TARGET_X_OFFSET - TOOL_TIP_ARROW_WIDTH / 2;
      if (target.y === 'center') {
        transform = 'rotate(90deg)';
        right = -TOOL_TIP_ARROW_WIDTH + 5;
        top = toolTipContainer.height / 2 - 10;
      }
    } else if (target.x === 'center') {
      right = toolTipContainer.width / 2;
    } else {
      left = rect.width / 2 + TARGET_X_OFFSET - TOOL_TIP_ARROW_WIDTH / 2;
      if (target.y === 'center') {
        transform = 'rotate(-90deg)';
        left = -TOOL_TIP_ARROW_WIDTH + 5;
        top = toolTipContainer.height / 2 - 10;
      }
    }

    if (target.y === 'bottom') {
      top = toolTipContainer.height;
      transform = 'rotate(180deg)';
    }

    return {
      top,
      right,
      transform,
      left,
    };
  },
};

export default config;
