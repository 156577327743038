import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';

class Tbody extends Component {
  static propTypes = {
    styles: PropTypes.object,
    children: PropTypes.node.isRequired,
    notRounded: PropTypes.bool, // removes border radius
  };

  render() {
    const { styles, children } = this.props;
    return <tbody className={styles.root}>{children}</tbody>;
  }
}

const styles = {
  root: props => ({
    [props.theme.breakpoints.tabletLarge]: {
      '>tr:first-of-type > td:first-of-type': {
        borderTopLeftRadius: props.notRounded ? null : '4px',
      },
      '>tr:first-of-type > td:last-of-type': {
        borderTopRightRadius: props.notRounded ? null : '4px',
      },
      '>tr:last-of-type > td:first-of-type': {
        borderBottomLeftRadius: props.notRounded ? null : '4px',
      },
      '>tr:last-of-type > td:last-of-type': {
        borderBottomRightRadius: props.notRounded ? null : '4px',
      },
    },
  }),
};

export default connect(styles)(Tbody);
