import React from 'react';
import Pusher from 'pusher-js';
import config from '../common/config';
import client from '../common/utils/client';

export default function PusherEvents({ enabled, channelName, events }) {
  const pusher = usePusherInstance(enabled);
  useSubscribeToEvents(pusher, channelName, events);
  return <></>;
}

function usePusherInstance(enabled) {
  const [pusherInstance, setPusherInstance] = React.useState();
  React.useEffect(() => {
    if (enabled) {
      const instance = createPusherInstance();
      setPusherInstance(instance);
    } else {
      setPusherInstance(null);
    }
  }, [enabled]);
  return pusherInstance;
}

function useSubscribeToEvents(pusherInstance, channelName, events) {
  const eventsRef = React.useRef(events);
  React.useEffect(() => {
    if (pusherInstance) {
      const channel = pusherInstance.subscribe(channelName);
      eventsRef.current.forEach(({ eventName, handler }) => {
        channel.bind(eventName, handler);
      });
      return () => {
        pusherInstance.unsubscribe(channelName);
      };
    }
  }, [pusherInstance, channelName]);
}

function createPusherInstance() {
  return new Pusher(config.pusher.key, {
    cluster: config.pusher.cluster,
    authorizer: (channel, options) => ({
      authorize: async (socket_id, callback) => {
        try {
          const response = await client.post('pusher/auth', { channel_name: channel.name, socket_id });
          if (response.ok) {
            const json = await response.json();
            callback(false, json);
          } else {
            callback(true);
          }
        } catch (e) {
          console.error(e);
          callback(true);
        }
      },
    }),
  });
}
