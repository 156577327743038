import React from 'react';

function CircleCheck() {
  /* eslint-disable max-len */
  return (
    <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 36.5C72 43.521 69.9179 50.3849 66.0174 56.2229C62.1168 62.0608 56.5717 66.6111 50.085 69.2975C43.5982 71.9849 36.461 72.6878 29.574 71.3175C22.6878 69.9481 16.3625 66.5667 11.3977 61.602C6.43292 56.6373 3.05191 50.3121 1.68214 43.426C0.312375 36.539 1.01536 29.4018 3.70226 22.915C6.38917 16.4279 10.9394 10.8836 16.7773 6.98281C22.6151 3.08207 29.479 1 36.5 1M72 9.60606L39.196 42.697C38.7786 43.12 38.2819 43.4549 37.7349 43.6836C37.1888 43.9123 36.603 44.0303 36.011 44.0303C35.419 44.0303 34.8333 43.9123 34.2871 43.6836C33.7401 43.4549 33.2435 43.12 32.826 42.697L22.5152 32.296"
        stroke="#1AB9C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  /* eslint-enable */
}

export default CircleCheck;
