/*
 *  when updating messages in this file you need to update it's sister file in
 *  admin-frontend/src/views/User/utils/get-system-message-text
 */
export default function getSystemMessageText(message, currentUser, listing, otherUser) {
  const isOwnListing = listing && currentUser.get('id') === listing.get('seller');
  const soldToName = otherUser ? `${otherUser.get('first_name')} ${otherUser.get('last_initial')}` : '';

  if (isOwnListing) {
    switch (message.get('body')) {
      case 'SOLD':
        return `You sold ${listing.get('name') || 'this puppy'} to ${soldToName || 'this user'}`;
      case 'NO_LONGER_AVAILABLE':
        return 'You sold this puppy to someone else';
      case 'TESTIMONIAL_CREATED':
        return `You have received a new testimonial in Testimonials`;
      case 'TESTIMONIAL_REPLY':
        return `You have replied to the buyer's review. View the message in Testimonials`;
      case 'REDACTED':
        return "The message sender's account has been removed due to violating our Terms of Service.";
      case 'RESTRICTED':
        return 'You need to upgrade to view this message.';
      default:
        console.log(`Unknown system message key: ${message.get('body')}`);
        return null;
    }
  } else {
    switch (message.get('body')) {
      case 'SOLD':
        return "Congratulations, you've got a new puppy on the way! Be sure to leave a testimonial after everything is settled.";
      case 'TESTIMONIAL_CREATED':
        return 'Your testimonial has been sent.';
      case 'TESTIMONIAL_REPLY':
        return 'The seller has replied to your testimonial.';
      case 'REDACTED':
        return "The message sender's account has been removed due to violating our Terms of Service.";
      case 'RESTRICTED':
        return 'You need to upgrade to view this message.';
      default:
        console.log(`Unknown system message key: ${message.get('body')}`);
        return null;
    }
  }
}
