import React from 'react';
import PropTypes from 'prop-types';

import { Button, ModalBody, ModalFooter, ModalHeader } from '../../../../../common/components';

const GenericMessage = ({ onCancel = () => {}, title, message, buttonText }) => {
  return (
    <>
      <ModalHeader title={title} />
      <ModalBody>{message}</ModalBody>
      <ModalFooter
        actions={[
          <Button buttonType="round" onClick={onCancel} type="button">
            {buttonText}
          </Button>,
        ]}
      />
    </>
  );
};

GenericMessage.propTypes = {
  errors: PropTypes.number,
  onCancel: PropTypes.func,
};

export default GenericMessage;
