import React, { useState } from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';

import { PhotoGalleryContextProvider, usePhotoGallery } from './PhotoGalleryContext';
import SortableList from './SortableList';
import { ErrorText, Heading, Subheading } from './styled';

import { InputError } from '../../../common/components';

const PHOTO_COUNT_MAX = 10;

export const PhotoGalleryUploader = ({ header = '', onDelete, onSort, photos, userPhotos = [] }) => {
  const { editPhoto, photoUploads, removePhoto, uploadPhotos } = usePhotoGallery();
  const [error, setError] = useState('');
  const photoLimit = PHOTO_COUNT_MAX - userPhotos.length - photoUploads.length;

  return (
    <div>
      {header && (
        <>
          <Heading>{header}</Heading>
          <Subheading>Drag and drop to reorder images.</Subheading>
        </>
      )}
      <SortableList
        axis="xy"
        distance={1}
        lockToContainerEdges
        photoLimit={photoLimit}
        photos={photos}
        userPhotos={userPhotos}
        handleDelete={onDelete}
        handleEdit={({ hasCropped = false, image, sortIndex, title = '' }) =>
          editPhoto({ hasCropped, image, setError, sortIndex, title })
        }
        handleRemove={photo => removePhoto({ photo, setError })}
        handleUpload={images => uploadPhotos({ images, setError })}
        onSortEnd={onSort}
        setError={setError}
      />
      {error && (
        <ErrorText>
          <InputError>{error}</InputError>
        </ErrorText>
      )}
    </div>
  );
};

PhotoGalleryUploader.propTypes = {
  header: PropTypes.node,
  onDelete: PropTypes.func,
  onSort: PropTypes.func,
  photos: PropTypes.instanceOf(List),
  userPhotos: PropTypes.arrayOf(PropTypes.object),
};

const ProviderComponent = (props = {}) => {
  return (
    <PhotoGalleryContextProvider onEdit={props.onEdit} onUpload={props.onUpload} path={props.path}>
      <PhotoGalleryUploader {...props} />
    </PhotoGalleryContextProvider>
  );
};

export default ProviderComponent;
