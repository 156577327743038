import React, { useState } from 'react';
import SlidingPane from 'react-sliding-pane';

import AdvancedSearchListingsFormMobile from './AdvancedSearchListingsFormMobile';

import { Icon } from '../../../common/components';

import theme from '../../../theme';

import { RefineSearchButtonContainer, MobileUpdateButton, SearchContainer, SlidingPaneTop } from './styled';

const SearchListingsFormMobile = ({
  breeds = [],
  handleBreedSelect,
  handleBreedPlaceholder,
  handleInputChange,
  handleSubmit,
  onSubmit,
  query,
  setGeoCompleteTouched = () => {},
  styles,
  values = {},
  ...props
}) => {
  const [showMore, setShowMore] = useState(false);

  const showHideFreestar = shouldHideAds => {
    if (typeof window !== 'undefined') {
      window.freestar?.queue.push(function() {
        if (shouldHideAds === true) {
          window.freestar?.deleteStickyFooter();
          window.freestar?.setVideoAdhesionEnabled(false);
        } else {
          window.freestar?.setVideoAdhesionEnabled(true);
          window.freestar?.newStickyFooter();
        }
      });
    }
  };

  return (
    <SearchContainer>
      <form id="search-listings-form-mobile" onSubmit={handleSubmit}>
        <RefineSearchButtonContainer>
          <MobileUpdateButton
            data-testid="toggle-advanced-btn"
            onClick={e => {
              e.preventDefault();
              showHideFreestar(true);
              setShowMore(true);
            }}
          >
            REFINE SEARCH
          </MobileUpdateButton>
        </RefineSearchButtonContainer>
      </form>
      <SlidingPane isOpen={showMore} onRequestClose={() => setShowMore(false)} from="bottom" hideHeader width="100%">
        <SlidingPaneTop>
          <h2>Find a puppy</h2>
          <button
            type="button"
            onClick={() => {
              setShowMore(false);
              showHideFreestar(false);
            }}
          >
            <Icon icon="Close" size={20} fill={theme.colors.blue} />
          </button>
        </SlidingPaneTop>
        <AdvancedSearchListingsFormMobile
          breeds={breeds}
          query={query}
          values={values}
          handleBreedSelect={handleBreedSelect}
          handleBreedPlaceholder={handleBreedPlaceholder}
          handleInputChange={handleInputChange}
          handleSubmit={values => {
            const search = { ...values };
            if (search.breed && search.formatted_breed) {
              const autocompleteBreed = breeds.find(breed => breed.id === search.breed);
              search.formatted_breed = autocompleteBreed ? autocompleteBreed.label : search.formatted_breed;
            }
            handleSubmit(search);
            setShowMore(false);
          }}
          setGeoCompleteTouched={setGeoCompleteTouched}
          {...props}
        />
      </SlidingPane>
    </SearchContainer>
  );
};

export default SearchListingsFormMobile;
