import React, { useEffect } from 'react';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';
import localstorage from 'store2';
import { withFormik, Field } from 'formik';
import { compose } from 'redux';

import { Button, InputError, TextInput, PlainLink, Modal } from '../../common/components';
import equalTo from '../../common/utils/yupEqualTo';
import config from '../../common/config';

yup.addMethod(yup.string, 'equalTo', equalTo);

const SignUpForm = props => {
  const loadRecaptcha = () => {
    window.grecaptcha.ready();
  };

  useEffect(() => {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${config.reCaptchaKey}`;
    script.type = 'text/javascript';
    script.addEventListener('load', loadRecaptcha);
    head.appendChild(script);
  }, []);

  const { isSubmitting, status, styles, isModal, setStatus, handleSubmit } = props;

  return (
    <div>
      <form id="sign-up-form" onSubmit={handleSubmit}>
        <div className={styles.form}>
          <div className={styles.formRow}>
            <Field
              label="First Name"
              labelVariant="secondary"
              autoComplete="first-name"
              name="first_name"
              component={TextInput}
              fixederror={!isModal}
            />
            <Field
              label="Last Name"
              labelVariant="secondary"
              autoComplete="last-name"
              name="last_name"
              component={TextInput}
              fixederror={!isModal}
            />
          </div>
          <Field
            label="Email Address"
            labelVariant="secondary"
            name="email"
            type="email"
            autoComplete="new-email"
            component={TextInput}
            fixederror={!isModal}
          />
          <Field
            label="Confirm Email Address"
            labelVariant="secondary"
            name="email_confirmation"
            type="email"
            component={TextInput}
            fixederror={!isModal}
          />
          <div className={styles.formRow}>
            <Field
              label="Password"
              labelVariant="secondary"
              name="password"
              type="password"
              autoComplete="new-password"
              component={TextInput}
              fixederror={!isModal}
              password
            />
            <Field
              label="Confirm Password"
              labelVariant="secondary"
              name="password_confirmation"
              type="password"
              autoComplete="new-password"
              component={TextInput}
              fixederror={!isModal}
              password
            />
          </div>
          <div className={styles.agreements}>
            By signing up for a Puppies.com account, you affirm that you have read, understand, and agree to the{' '}
            <PlainLink variant="blue" to="/tos">
              Terms of Service
            </PlainLink>
            .
          </div>
          {status && status.error && <InputError>{status.error}</InputError>}
          <Button type="submit" buttonType="primaryLarge" fullWidth disabled={isSubmitting} data-testid="signUpButton">
            SIGN UP
          </Button>
        </div>
      </form>
      <Modal
        isOpen={status && status.showContactSupportModal ? true : false}
        onClose={() => setStatus({ showContactSupportModal: false })}
        closeOnOutsideClick={true}
        closeOnEscape={true}
      >
        Please Contact Customer Support
      </Modal>
    </div>
  );
};

const FormikSignUpForm = withFormik({
  mapPropsToValues: () => {
    return {
      first_name: '',
      last_name: '',
      email: '',
      email_confirmation: '',
      password: '',
      password_confirmation: '',
    };
  },
  validateOnChange: false,
  validationSchema: yup.object().shape({
    first_name: yup
      .string()
      .trim()
      .max(40, 'First name must be 40 characters or less')
      .required('First name is required'),
    last_name: yup
      .string()
      .trim()
      .max(40, 'Last name must be 40 characters or less')
      .required('Last name is required'),
    email: yup
      .string()
      .trim()
      .email('Email must be a valid email address')
      .max(64, 'Email must be 64 characters or less')
      .required('Email address is required'),
    email_confirmation: yup
      .string()
      .equalTo(yup.ref('email'), 'This does not match what was entered in the Email field')
      .required('Confirmation email is required'),
    password: yup
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .max(255, 'Password may not exceed 255 characters')
      .required('Password is required'),
    password_confirmation: yup
      .string()
      .equalTo(yup.ref('password'), 'This does not match what was entered in the Password field')
      .required('Confirmation password is required'),
  }),
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    setSubmitting(true);

    const token = await window.grecaptcha
      .execute(config.reCaptchaKey, { action: 'submit' })
      .then(token => {
        if (token) {
          return token;
        }
        throw new Error();
      })
      .catch(error => {
        console.error(error);
        props.setError(
          <div>
            There was an issue loading a Google Recaptcha token. If applicable, please update Chrome, disable any VPN,
            or refresh the page and try again.
          </div>
        );
      });

    if (!token) {
      return;
    }

    const { ...restValues } = values;
    restValues.reCaptchaToken = token;
    setStatus({});
    const cachedLocation = JSON.parse(localstorage.get('location'));
    if (cachedLocation && cachedLocation.location) {
      restValues.formatted_location = cachedLocation.label;
      restValues.lat = cachedLocation.location.lat;
      restValues.lng = cachedLocation.location.lng;
      localstorage.remove('location');
    }
    delete restValues.email_confirmation;
    const action = await props.submitAction(restValues);

    if (!action?.response.ok) {
      setSubmitting(false);
      if (action.json && action.json.message === 'user-exists') {
        setSubmitting(false);
        props.setError(
          <div>
            There is already an account for this email address. Try <PlainLink to="/sign-in">signing in</PlainLink> or{' '}
            <PlainLink to="/forgot-password">reset your password.</PlainLink>
          </div>
        );
      } else if (action.json && action.json.message === 'contact customer service') {
        setStatus({ showContactSupportModal: true });
      } else {
        props.setError(action.json.message || 'An unknown error occurred');
      }
    } else {
      const matchProfileId = matchPath(props.location.pathname, {
        path: '/profile/:profileId',
        exact: true,
        strict: false,
      });
      const matchPremierId = matchPath(props.location.pathname, {
        path: '/premier/:premierId',
        exact: true,
        strict: false,
      });

      const isOnSellerProfile = !!matchProfileId || !!matchPremierId;

      if (isOnSellerProfile && localstorage('showTestimonialModal')) {
        props.history.push(`/verify-phone-number?to=${props.location.pathname}`);
      } else if (props.location.pathname === '/sign-up' || props.location.pathname === '/sign-in') {
        const pathname =
          !!props.location.state && !!props.location.state.purchaseType ? '/account/my-profile' : '/find-a-puppy';
        props.history.push(`/verify-phone-number?to=${pathname}`, { state: props.location.state || null });
      } else {
        props.history.push(`/verify-phone-number?to=${props.location.pathname}`);
      }
    }
  },
})(SignUpForm);

const styles = props => ({
  form: {
    '> * + *': {
      marginTop: props.isModal ? '20px' : '28px',
    },
    '> button': {
      marginTop: '40px',
    },
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '> *': {
      width: '50%',
    },
    '> * + *': {
      marginLeft: '20px',
    },
  },
  agreements: {
    color: props.theme.colors.darkerGray,
    lineHeight: '21px',
    fontSize: '14px',
    margin: '24px 0 0',
    textAlign: 'center',
    [props.theme.breakpoints.tablet]: {
      textAlign: 'left',
    },
  },
});

export default compose(withRouter, connect(), felaConnect(styles))(FormikSignUpForm);
