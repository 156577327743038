import React from 'react';

function Archive() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M23.918 7.031c.059.14.082.305.082.469v15c0 .832-.668 1.5-1.5 1.5h-21C.668 24 0 23.332 0 22.5v-15c0-.164.023-.328.082-.469l2.004-6C2.273.421 2.859 0 3.504 0h16.992c.645 0 1.23.422 1.418 1.031l2.004 6zM3.13 7.304h17.74L19.378 3.13H4.622L3.13 7.304z" />
    </svg>
  );
  /* eslint-enable */
}

export default Archive;
