import React from 'react';

function Warning() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M23.727 20.39c.391.765.36 1.678-.082 2.415-.458.74-1.264 1.191-2.135 1.195H2.493c-.87-.004-1.676-.456-2.134-1.195-.445-.727-.468-1.64-.117-2.415L9.75 1.394C10.177.541 11.048.001 12 0c.951 0 1.807.528 2.23 1.394l9.496 18.996zM10.502 6.504V15h3V6.504h-3zm1.5 15c1.113 0 2.005-.89 2.005-2.004 0-1.114-.892-2.004-2.004-2.004-1.115 0-2.006.89-2.006 2.004 0 1.114.891 2.004 2.005 2.004z" />
    </svg>
  );
  /* eslint-enable */
}

export default Warning;
