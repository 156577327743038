import client from '../../../common/utils/client';

export const updateTestimonialVisibility = testimonialId => ({
  type: 'UPDATE_TESTIMONIAL_VISIBILITY',
  promise: client.post(`testimonials/${testimonialId}/update-visibility`, { testimonialId }),
});

export const updateReplyVisibility = testimonialId => ({
  type: 'UPDATE_REPLY_VISIBILITY',
  promise: client.post(`testimonials/${testimonialId}/update-reply-visibility`, { testimonialId }),
});

export const createOrUpdateTestimonial = (sellerId, payload) => ({
  type: 'CREATE_OR_UPDATE_TESTIMONIAL',
  promise: client.put(`users/${sellerId}/testimonial`, payload),
});

export const replyToTestimonial = (testimonialId, reply) => ({
  type: 'REPLY_TO_TESTIMONIAL',
  promise: client.put(`testimonials/${testimonialId}/reply`, { reply }),
});

export const getTestimonialsForUser = (userId, page = 1, pageSize = 16) => ({
  type: 'GET_TESTIMONIALS_FOR_USER',
  promise: client.get(`users/${userId}/testimonials?include=for&page=${page}&pageSize=${pageSize}`),
  userId,
});

export const getTestimonialsByUser = (userId, page = 1, pageSize = 16) => ({
  type: 'GET_TESTIMONIALS_BY_USER',
  promise: client.get(`users/${userId}/testimonials?include=by&page=${page}&pageSize=${pageSize}`),
  userId,
});

export const getTestimonialsForAndByUser = (userId, page = 1, pageSize = 16) => ({
  type: 'GET_TESTIMONIALS_FOR_AND_BY_USER',
  promise: client.get(`users/${userId}/testimonials?include=all&page=${page}&pageSize=${pageSize}`),
  userId,
});

export const getTestimonialsByUserAsUser = (userId, page = 1, pageSize = 16) => ({
  type: 'GET_TESTIMONIALS_BY_USER',
  promise: client.get(`users/${userId}/testimonials-user?include=by&page=${page}&pageSize=${pageSize}`),
  userId,
});

export const getTestimonialsForAndByUserAsUser = (userId, page = 1, pageSize = 16) => ({
  type: 'GET_TESTIMONIALS_FOR_AND_BY_USER',
  promise: client.get(`users/${userId}/testimonials-user?include=all&page=${page}&pageSize=${pageSize}`),
  userId,
});

export const getListingsForBuyer = sellerId => ({
  type: 'GET_LISTINGS_FOR_BUYER',
  promise: client.get(`listings/user/${sellerId}/buyer`),
});

export const getFinalRejectedByUser = userId => ({
  type: 'GET_FINAL_REJECTED_BY_USER',
  promise: client.get(`users/${userId}/final-rejected`),
});
