import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { login, setErrorBarMessage, clearErrorBar } from '../../redux/actions';
import { isLoggedInSelector } from '../../redux/selectors';
import Login from './Login';

const mapStateToProps = state => ({
  isLoggedIn: isLoggedInSelector(state),
  user: state.user,
  userVM: state.userVM,
});

const mapDispatchToProps = dispatch => bindActionCreators({ login, setErrorBarMessage, clearErrorBar }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
