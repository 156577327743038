import React from 'react';
import classNames from 'classnames';
import SystemMessageButton from './SystemMessageButton';
import MessageTimestamp from './MessageTimestamp';
import getSystemMessageText from '../../../../utils/get-system-message-text';

const AutomatedMessage = ({
  message,
  currentUser,
  styles,
  newFromId,
  listing,
  location,
  showTestimonialModal,
  author,
  conversation,
}) => {
  const isNewMessage = newFromId === message.get('id');
  const isOwnMessage = author && currentUser.get('id') === author.get('id');
  const isOwnListing = listing && currentUser.get('id') === listing.get('seller');
  const dontShow = shouldMessageBeHidden(message, isOwnListing);

  const otherUser = conversation && conversation.get('users').find(user => user.get('id') !== currentUser.get('id'));

  if (dontShow) {
    return null;
  }

  return (
    <div
      key={message.get('id')}
      className={classNames(
        styles.messageWrapper,
        isNewMessage ? styles.messageWrapperNew : null,
        isOwnMessage ? styles.yourMessage : null
      )}
      aria-label={'Automatically Generated Message'}
      role="log"
    >
      {isNewMessage ? <div className={styles.newMessageBorder}>New</div> : null}
      <div className={styles.innerMessageWrapper}>
        <div className={classNames({}, isOwnMessage ? styles.yourSentMessage : null)}>
          <pre className={styles.systemMessageText} style={{ whiteSpace: 'pre-line' }}>
            {getSystemMessageText(message, currentUser, listing, otherUser)}
          </pre>
        </div>
        <SystemMessageButton
          message={message}
          currentUser={currentUser}
          listing={listing}
          showTestimonialModal={showTestimonialModal}
          location={location}
        />
      </div>
      <MessageTimestamp styles={styles} message={message} />
    </div>
  );
};

function shouldMessageBeHidden(message, isOwnListing) {
  if (message.get('body') === 'TESTIMONIAL_REPLY' && isOwnListing) {
    return true;
  }

  return false;
}

export default AutomatedMessage;
