import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-fela';

import {
  Icon,
  Hero,
  LandingPageFooter,
  PopularBreedsFooter,
  Button,
  StartTrialModal,
  LandingPageCopy,
  Footer,
  ButtonLink,
  TopNav,
} from '../../common/components';

const copy = [
  {
    title: "It's free to get started",
    description: (
      <Fragment>
        Getting started is <strong>free</strong> and only takes a few minutes. It's the first step in listing a puppy.
      </Fragment>
    ),
    image: '',
    imagePosition: 'left',
  },
  {
    title: 'Safely sell your puppies online',
    description: 'Since 2003, Puppies.com has been connecting breeders like you with people all over the world.',
    image: '',
    imagePosition: 'right',
  },
  {
    title: 'Always here to help',
    description: 'Have a question? Our US-Based customer support team is standing by to help.',
    image: '',
    imagePosition: 'left',
  },
];

class SellerLandingPage extends Component {
  state = {
    showTrialModal: false,
  };

  handleTrialStart = () => {
    this.props.history.push({ pathname: '/sign-up', state: { purchaseType: 'trial' } });
  };

  render() {
    const { styles, isLoggedIn, user, userVM } = this.props;
    return (
      <div>
        <Helmet>
          <title>Sell Your Puppies Online at Puppies.com</title>
          <meta
            name="description"
            content="Selling your puppy online has never been easier than with the new Puppies.com. Advertise your puppy with a Puppies.com listing!"
          />
          <meta name="keywords" content="sell,puppy,online,advertise,list" />
        </Helmet>
        <TopNav
          isSeller={user && user.get('listings').size > 0}
          user={user}
          userVM={userVM}
          isLoggedIn={isLoggedIn}
          errorBarError={''}
          messageCount={''}
        />
        <Hero
          headline="Bring a little wiggle to someone's life!"
          buttonLeft={
            <ButtonLink to="/find-a-puppy" buttonType="roundWhite" iconLeft={<Icon size={14} icon="Search" />}>
              FIND A PUPPY
            </ButtonLink>
          }
          buttonRight={
            <ButtonLink to="/sign-in" buttonType="roundWhite" iconLeft={<Icon size={14} icon="Avatar" />}>
              SIGN IN
            </ButtonLink>
          }
          calloutAction={
            <Button buttonType="largeRoundOrangeOutline" onClick={() => this.setState({ showTrialModal: true })}>
              GET STARTED FOR FREE
            </Button>
          }
        />
        <div className={styles.tpSection}>
          <div className={styles.container}>
            <div
              className="trustpilot-widget"
              data-locale="en-US"
              data-template-id="53aa8912dec7e10d38f59f36"
              data-businessunit-id="5f0633436d8c8600014e68af"
              data-style-height="128px"
              data-style-width="100%"
              data-theme="light"
              data-stars="4,5"
              data-review-languages="en"
            >
              <a href="https://www.trustpilot.com/review/puppies.com" target="_blank" rel="noopener noreferrer">
                Trustpilot
              </a>
            </div>
          </div>
        </div>
        <div className={styles.youtubeEmbed}>
          <iframe
            src="https://www.youtube.com/embed/1v00xNwVcFA?si=PPGqz3DGZmBuEcAn"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <LandingPageCopy sections={copy} headerText="Why choose Puppies.com?" />
        <LandingPageFooter />
        <PopularBreedsFooter />
        <Footer isLoggedIn={false} />
        <StartTrialModal
          isOpen={this.state.showTrialModal}
          onClose={() => {
            this.setState({ showTrialModal: false });
          }}
          onSubmit={this.handleTrialStart}
          closeOnEscape
          closeOnOutsideClick
        />
      </div>
    );
  }
}

const styles = props => ({
  tpSection: {
    ...props.theme.globalBackgroundContainer,
    paddingBottom: '40px',
  },
  container: {
    position: 'relative',
    background: 'white',
    margin: '0 auto',
    maxWidth: '1108px',
    marginTop: '-84px',
    padding: '20px',
    // borderRadius: '6px',
    // boxShadow: '0 1px 3px 0 rgba(0,0,0,0.24)',
  },
  youtubeEmbed: {
    margin: '40px auto 0',
    position: 'relative',
    width: '100%',
    maxWidth: '1108px',
    height: '315px',
    minHeight: '315px',
    '@media all and (min-width: 805px)': {
      height: '400px',
    },
    '@media all and (min-width: 1080px)': {
      height: '600px',
    },
    '> iframe': {
      margin: '0 40px',
      position: 'absolute',
      top: '0',
      left: '0',
      width: 'calc(100% - 80px)',
      height: '100%',
    },
  },
});

export default connect(styles)(SellerLandingPage);
