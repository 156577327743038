import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import {
  Icon,
  InputError,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonPlain,
  Button,
  ButtonLink,
  Textarea,
} from '..';
import { withFormik, Field } from 'formik';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';

class ContactModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    recipientInfo: PropTypes.node.isRequired,
    requestVerification: PropTypes.bool,
    recipientName: PropTypes.string,
    backTo: PropTypes.string,
  };

  static defaultProps = {
    requestVerification: false,
  };

  onClose = () => {
    this.props.resetForm();
    this.props.onClose();
  };

  renderForm() {
    const { values, handleSubmit, isSubmitting, recipientInfo, status, requestVerification } = this.props;
    return (
      <form id="contact-seller-form" onSubmit={handleSubmit}>
        <ModalHeader title={requestVerification ? 'Request Verification' : 'Send a Message'} />
        <ModalBody>
          {recipientInfo}
          <Field name="body" component={Textarea} rows={8} maxLength={3000} value={values.body} />
          {status && status.error ? <InputError>{status.error}</InputError> : null}
        </ModalBody>
        <ModalFooter
          actions={[
            <ButtonPlain type="button" variant="red" onClick={this.onClose} disabled={isSubmitting}>
              Cancel
            </ButtonPlain>,
            <div>
              Stay safe! Review our{' '}
              <a
                href="https://help.puppies.com/article/129-buyer-tips"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#1AB9C4', fontWeight: 'bold', textDecoration: 'underline' }}
              >
                Buyer Tips
              </a>
            </div>,
            <Button
              type="submit"
              buttonType="round"
              disabled={isSubmitting || values.body.trim() === ''}
              data-testid={'sendMessageModalButton'}
            >
              Send
            </Button>,
          ]}
        />
      </form>
    );
  }

  renderSuccess() {
    const { status, styles, backTo, location } = this.props;

    return status && status.success ? (
      <ModalBody className={styles.successModalBody}>
        <Icon icon="Send" size={55} />
        <h1>Message Sent</h1>
        <ButtonLink
          buttonType="round"
          to={{
            pathname: `/messages/inbox/${status.success}`,
            state: {
              prevLocation: location,
              backTo,
            },
          }}
        >
          View Conversation
        </ButtonLink>
      </ModalBody>
    ) : null;
  }

  render() {
    const { isOpen, status } = this.props;

    return (
      <Modal isOpen={isOpen} onClose={this.onClose} closeOnOutsideClick closeOnEscape>
        {status && status.success ? this.renderSuccess() : this.renderForm()}
      </Modal>
    );
  }
}

const styles = props => ({
  successModalBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: props.theme.colors.blue,
    fontWeight: props.theme.typography.sansBold,
    padding: '120px 40px !important',
    color: props.theme.colors.white,
    borderRadius: '4px',
    '> h1': {
      fontSize: '35px',
      lineHeight: '47px',
      marginBottom: '42px',
      marginTop: '20px',
    },
  },
  link: {
    color: props.theme.colors.blue,
  },
});

export default compose(
  withFormik({
    mapPropsToValues: props => ({
      body: props.requestVerification
        ? `Hi ${props.recipientName}, can you take a moment to add more verifications to your account on the account verifications page?`
        : '',
    }),
    enableReinitialize: true,
    handleSubmit(values, { props, setStatus, resetForm, setSubmitting }) {
      props.sendMessage(values.body, props.requestVerification).then(action => {
        if (action.response.ok) {
          resetForm();
          setStatus({ success: action.json.id });
        } else {
          setStatus({ error: action.json ? action.json.message : 'Could not send message at this time' });
          setSubmitting(false);
        }
      });
    },
  }),
  withRouter,
  felaConnect(styles)
)(ContactModal);
