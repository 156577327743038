import React from 'react';
import { Modal, ConfirmationModalBody, Button, CircleAvatar } from '../../../../../common/components';

const TrialModalPhoto = `/static/trial-modal-photo.jpg`;

function CouponSuccessModal({ onClose, visible, type }) {
  return (
    <Modal isOpen={visible} onClose={onClose} closeOnOutsideClick closeOnEscape>
      <ConfirmationModalBody
        image={<CircleAvatar size={150} imageUrl={TrialModalPhoto} placeholderIcon="PuppyCircle" />}
        title="Discount applied!"
        subTitle={`Enjoy a discount on a ${type === 'premium' ? 'Standard' : 'Premier'} Membership plan!`}
        actions={[
          <Button type="button" buttonType="round" onClick={onClose}>
            {type === 'premium' ? `View Plans` : `Okay`}
          </Button>,
        ]}
      />
    </Modal>
  );
}

export default CouponSuccessModal;
