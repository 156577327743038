import React from 'react';
import PropTypes from 'prop-types';

import { Pager } from '../../common/components';

import { MAX_LISTINGS_PER_PAGE } from './common/search-config';

const ResultsPager = ({ handlePaginate, total }) => {
  return (
    <div>
      <Pager handlePaginate={handlePaginate} pageSize={MAX_LISTINGS_PER_PAGE} totalItems={total} />
    </div>
  );
};

ResultsPager.propTypes = {
  handlePaginate: PropTypes.func,
  location: PropTypes.object,
  total: PropTypes.number,
};

export default ResultsPager;
