import React from 'react';
import moment from 'moment';
import { Table, Tbody, Thead, Td, Th, Tr, Pager } from '../../../../../common/components';
import formatPrice from '../../../../../common/utils/formatPrice';
import Icon from '../../../../../common/components/icons/icon';

// filter out the refunds of voided transactions
const filterVoidRefunds = payments => {
  const listOfVoidedTransactions = [];

  payments.forEach(payment => {
    if (payment?.get('status') === 'Void') {
      listOfVoidedTransactions.push(payment?.get('id'));
    }
  });

  return payments.filter(payment => {
    if (listOfVoidedTransactions.includes(payment?.get('refunded_transaction_id'))) {
      return null;
    }

    return payment;
  });
};

const PaymentTable = ({ payments, paymentsPagination, styles }) => {
  const applicablePayments = filterVoidRefunds(payments);
  return (
    <>
      <div className={styles.table}>
        <Table>
          <Thead>
            <Tr transparent className={styles.tr}>
              <Th>Date</Th>
              <Th>Product</Th>
              <Th>Status</Th>
              <Th>Payment Method</Th>
              <Th>Total</Th>
            </Tr>
          </Thead>
          <Tbody notRounded>
            {applicablePayments.map(payment => (
              <Tr key={payment.get('id')} className={styles.tr}>
                <Td>{moment(payment.get('created_at')).format('MM/DD/YY')}</Td>
                <Td>{displayPurchaseDescription(payment)}</Td>
                <Td>{payment.get('status')}</Td>
                <Td className={styles.lastFourContainer}>
                  {renderCardLogoChip(payment.get('card_type'))}
                  <div className={styles.lastFour}>{payment.get('last_4')}</div>
                </Td>
                <Td>{displayPrice(payment)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
      {paymentsPagination.get('totalItems') > 0 && (
        <Pager pageSize={paymentsPagination.get('pageSize')} totalItems={paymentsPagination.get('totalItems')} />
      )}
    </>
  );

  function displayPurchaseDescription(payment) {
    if (payment.get('type') === 'refund') {
      return `${payment.get('description')} (Refund)`;
    }
    if (payment.get('type') === 'automated charge') {
      return `${payment.get('description')} (Automated Charge)`;
    }
    return payment.get('description');
  }

  function displayPrice(payment) {
    if (payment.get('type') === 'refund') {
      return `-${formatPrice(payment.get('amount'))}`;
    }
    return formatPrice(payment.get('amount'));
  }

  function renderCardLogoChip(card_type) {
    switch (card_type) {
      case 'Visa':
        return <Icon icon="Visa" size={32} />;
      case 'American Express':
        return (
          <div style={{ background: '#006fcf' }}>
            <Icon icon="Amex" size={32} />
          </div>
        );
      case 'Discover':
        return (
          <div style={{ background: 'white', border: '1px solid #ff6000' }}>
            <Icon icon="Discover" size={32} />
          </div>
        );
      case 'MasterCard':
        return <Icon icon="MasterCard" size={32} />;
      default:
        if (!!card_type) {
          return card_type + ' *';
        }
        return null;
    }
  }
};

export default PaymentTable;
