import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-fela';
import classNames from 'classnames';

class TabGroup extends Component {
  static propTypes = {
    buttonwidth: PropTypes.number,
    linkArray: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        route: PropTypes.string.isRequired,
      })
    ).isRequired,
    textArray: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        active: PropTypes.bool,
      })
    ),
  };

  static defaultProps = { tabIndex: '0', linkArray: [], textArray: [] };

  render() {
    const { styles, linkArray, textArray, ...passThruProps } = this.props;
    return (
      <div className={styles.root}>
        {linkArray.map(link => (
          <NavLink
            to={link.route}
            key={link.route}
            activeClassName={styles.navLinkCurrent}
            className={styles.navLink}
            id={link.id}
            onClick={e => e.currentTarget.blur()}
            {...passThruProps}
          >
            {link.text}
          </NavLink>
        ))}
        {textArray.map(text => {
          const textStyles = {};
          textStyles[styles.navLinkCurrent] = text.active;

          return (
            <div
              key={text.text}
              className={classNames(styles.navLink, textStyles)}
              onClick={e => {
                e.currentTarget.blur();
                text.onClick();
              }}
              {...passThruProps}
            >
              {text.text}
            </div>
          );
        })}
      </div>
    );
  }
}

const styles = props => ({
  root: {
    display: 'flex',
  },
  navLink: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#ffffff',
    borderRadius: '2px',
    padding: '13px 12px',
    marginLeft: '10px',
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltBold,
    fontSize: '12px',
    color: props.theme.colors.blue,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
    transition: '0.2s',
    whiteSpace: 'nowrap',
    [props.theme.breakpoints.mobileLarge]: {
      width: props.buttonwidth ? `${props.buttonwidth}px` : 'inherit',
    },
    ':hover': {
      outline: 'none',
      color: props.theme.colors.white,
      backgroundColor: props.theme.colors.green,
      boxShadow: 'inset 0px 1px 3px rgba(0, 0, 0, 0.5)',
    },
    ':active': {
      outline: 'none',
      color: props.theme.colors.white,
      backgroundColor: props.theme.colors.green,
      boxShadow: 'inset 0px 1px 3px rgba(0, 0, 0, 0.5)',
    },
    ':first-of-type': {
      marginLeft: 0,
    },
  },
  navLinkCurrent: {
    color: `${props.theme.colors.white} !important`,
    background: props.theme.colors.green,
    boxShadow: 'inset 0px 1px 3px rgba(0, 0, 0, 0.5)',
  },
});

export default connect(styles)(TabGroup);
