import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import classNames from 'classnames';

class Th extends Component {
  static propTypes = {
    styles: PropTypes.object,
    children: PropTypes.node,
  };

  render() {
    const { styles, children, className } = this.props;
    return <th className={classNames([styles.root, className])}>{children}</th>;
  }
}

const styles = {
  root: props => ({
    padding: '20px 10px',
    textAlign: 'left',
    fontSize: '11px',
  }),
};

export default connect(styles)(Th);
