import { combineReducers } from 'redux';
import { fromJS, Map } from 'immutable';

const getListingLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_LISTING_REQUEST':
      return true;
    case 'GET_LISTING_FAILURE':
    case 'GET_LISTING_SUCCESS':
    case 'RESET_LISTING':
      return false;
    default:
      return state;
  }
};

const getListingLoaded = (state = { loaded: false, statusCode: null }, action) => {
  switch (action.type) {
    case 'GET_LISTING_REQUEST':
    case 'RESET_LISTING':
      return { loaded: false, statusCode: null };
    case 'GET_LISTING_FAILURE':
    case 'GET_LISTING_SUCCESS':
      return { loaded: true, statusCode: action.json.statusCode };
    default:
      return state;
  }
};

const currentListing = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_LISTING_REQUEST':
    case 'RESET_LISTING':
      return Map();
    case 'GET_LISTING_SUCCESS':
      return fromJS(action.json).update('favorite', favorite => favorite || state.get('favorite'));
    case 'EDIT_LISTING_SUCCESS':
      return fromJS(action.json);
    case 'CREATE_FAVORITE_LISTING_REQUEST':
      return state.set('favorite', true);
    case 'REMOVE_FAVORITE_LISTING_REQUEST':
      return state.set('favorite', null);
    case 'SEND_MESSAGE_TO_LISTING_OWNER_SUCCESS':
      return state.set('conversation', fromJS(action.json));

    default:
      return state;
  }
};

const deleteListingLoading = (state = false, action) => {
  switch (action.type) {
    case 'DELETE_LISTING_REQUEST':
      return true;
    case 'DELETE_LISTING_FAILURE':
    case 'DELETE_LISTING_SUCCESS':
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  getListingLoading,
  getListingLoaded,
  currentListing,
  deleteListingLoading,
});
