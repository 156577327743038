import React from 'react';

const BoostError = function({ styles, setBoostSteps, setBoostedListings, setShowModal }) {
  return (
    <div>
      <div className={styles.header}>
        <div className={styles.modalHeader}>Something went wrong.</div>
        <div>Sorry there was an error processing you payment. Please try again.</div>
      </div>
      <div className={styles.footer}>
        <div>
          <button
            onClick={() => {
              setBoostedListings([]);
              setBoostSteps(1);
              setShowModal(false);
            }}
            className={styles.closeBtn}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default BoostError;
