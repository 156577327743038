import React from 'react';
import { Field } from 'formik';

import { Autocomplete, Button, Chip, Dropdown, GeoComplete, RangeSlider } from '../../../common/components';

import {
  BREED_OPTIONS,
  DISTANCE_OPTIONS,
  SEARCH_BY_OPTIONS,
  SEX_OPTIONS,
  STATE_OPTIONS,
} from '../common/search-config';

import {
  BreedContainer,
  FieldContainer,
  FormContainer,
  FormRow,
  HelpText,
  InputContainer,
  SearchContainer,
  DesignerError,
} from './styled';

const AdvancedSearchListingsForm = ({
  breeds = [],
  handleBreedSelect,
  handleBreedPlaceholder,
  handleBreedRemove,
  handleInputChange,
  handleSubmit,
  isSubmitting,
  setGeoCompleteTouched = () => {},
  values = {},
}) => {
  return (
    <SearchContainer isAdvanced>
      <form id="advanced-search-listings-form" onSubmit={handleSubmit}>
        <FormContainer>
          <FormRow>
            <FieldContainer flex="1.5">
              <Field
                insetLabel
                name="breed"
                render={fieldProps => {
                  return (
                    <Autocomplete
                      insetLabel
                      items={breeds.filter(breed => values?.breed && !values?.breed?.includes?.(breed.id))}
                      key={Array.isArray(values.breed) ? values.breed.join(',') : values.breed}
                      label="Breed"
                      placeholder={handleBreedPlaceholder}
                      onInputChange={handleInputChange}
                      onSelect={handleBreedSelect}
                      disabled={values.sort_breed === 'mixed' && values.breed.length >= 2 ? 'true' : ''}
                      {...fieldProps}
                    />
                  );
                }}
              />
            </FieldContainer>
            <FieldContainer minWidth="160px">
              <Field component={Dropdown} name="search_by" options={SEARCH_BY_OPTIONS} variant="secondary" />
            </FieldContainer>
            {values.search_by !== 'everywhere' && (
              <FieldContainer minWidth="160px">
                {values.search_by === 'state' && (
                  <Field
                    component={Dropdown}
                    initialValue={values?.state?.toUpperCase()}
                    name="state"
                    options={STATE_OPTIONS}
                    variant="secondary"
                    placeholder="All states"
                  />
                )}
                {values.search_by === 'city' && (
                  <Field
                    component={GeoComplete}
                    initialValue={values.formatted_location}
                    insetLabel
                    label="Location"
                    name="location"
                    placeholder="Type a city"
                    onBlur={setGeoCompleteTouched}
                  />
                )}
              </FieldContainer>
            )}
            {values.search_by === 'city' && (
              <FieldContainer minWidth="100px">
                <Field
                  component={Dropdown}
                  disabled={!values.city}
                  name="distance"
                  options={DISTANCE_OPTIONS}
                  variant="secondary"
                />
              </FieldContainer>
            )}
            <FieldContainer minWidth="160px">
              <Field component={Dropdown} name="sort_breed" options={BREED_OPTIONS} variant="secondary" />
            </FieldContainer>
            <FieldContainer flex="0">
              <Button disabled={isSubmitting} type="submit" data-testid="advanced-search-btn">
                FIND PUPPIES
              </Button>
            </FieldContainer>
          </FormRow>
        </FormContainer>
        <FormContainer>
          <FormRow>
            <FieldContainer flex="1">
              <HelpText>Details</HelpText>
              <InputContainer margin="12">
                <Field component={Dropdown} name="sex" options={SEX_OPTIONS} variant="secondary" />
              </InputContainer>
            </FieldContainer>
            <BreedContainer flex="1">
              <HelpText>Selected Breeds</HelpText>
              {Array.isArray(values.formatted_breed) && values.formatted_breed.length > 0 ? (
                values.formatted_breed.map((breed, i) => (
                  <Chip
                    data-testid={`selected-breed-${values.breed[i]}`}
                    id={values.breed[i]}
                    key={values.breed[i]}
                    variant="secondary"
                    onRemove={handleBreedRemove}
                  >
                    {breed}
                  </Chip>
                ))
              ) : (
                <Chip>All Breeds</Chip>
              )}
              {values.sort_breed === 'mixed' && values.breed.length > 2 ? (
                <DesignerError>2 breeds max for designer. Remove some to continue.</DesignerError>
              ) : (
                ''
              )}
            </BreedContainer>
            <FieldContainer flex="1">
              <InputContainer>
                <Field
                  component={RangeSlider}
                  name="age"
                  maxSuffix="+"
                  minValue={0}
                  maxValue={18}
                  pluralSuffix=" Months"
                  step={1}
                  suffix=" Month"
                />
              </InputContainer>
              <InputContainer>
                <Field
                  component={RangeSlider}
                  maxSuffix="+"
                  minValue={100}
                  maxValue={5000}
                  name="price"
                  prefix="$"
                  step={100}
                />
              </InputContainer>
            </FieldContainer>
            <FieldContainer flex="0.5"></FieldContainer>
          </FormRow>
        </FormContainer>
      </form>
    </SearchContainer>
  );
};

export default AdvancedSearchListingsForm;
