import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../../../../common/config';

export default function ViewPremierMeta({ premier, listings }) {
  const listingsSchema = getListingSchema(listings);
  const title = getTitle(premier);
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={premier.get('facility_description')} />
      <meta property="og:image" content={premier.getIn(['user', 'photo'])} />
      <meta name="title" content={title} />
      <meta name="description" content={premier.get('facility_description')} />
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org/',
          '@type': 'Organization',
          url: `${config.frontendHost}/premier/${premier.get('slug')}`,
          logo: premier.getIn(['user', 'photo']),
          hasOfferCatalog: {
            '@type': 'OfferCatalog',
            name: 'Puppies For Sale',
            itemListElement: listingsSchema,
          },
        })}
      </script>
    </Helmet>
  );
}

function getTitle(premier) {
  return premier.get('name') + ' is a breeder in ' + premier.getIn(['user', 'formatted_location']).replace(', US', '');
}

function getListingSchema(listings) {
  if (!listings) {
    return [];
  }
  const listingsSchema = [];
  listings.forEach((listing, index) => {
    const breedListSchema = [];
    listing.get('breeds').forEach((breed, index2) => {
      breedListSchema.push({
        '@type': 'listItem',
        position: index2 + 1,
        name: breed.get('name'),
      });
    });
    listingsSchema.push({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': `www.puppies.com/listings/${listing.get('id')}`,
        name: listing.get('name'),
        itemListElement: breedListSchema,
      },
    });
  });
}
