import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import theme from '../../../theme';
import classNames from 'classnames';
import ReactAutocomplete from './ReactAutocomplete';

import { InputScaffold } from '../';

class Autocomplete extends Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
      setFieldValue: PropTypes.func.isRequired,
    }),
    label: PropTypes.node,
    insetLabel: PropTypes.bool,
    labelVariant: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    items: PropTypes.array,
    onInputChange: PropTypes.func,
    onSelect: PropTypes.func,
    onBlur: PropTypes.func,
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    items: [{ id: 'Options not available', label: 'Options not available' }],
  };

  constructor(props) {
    super();
    this.state = {
      value: props.initialValue || '',
    };
  }

  componentDidUpdate() {
    const { form, initialValue } = this.props;

    // account for initialValue not being truthy at first
    if (!form?.dirty && initialValue && !this.state.value) {
      this.setState({ value: this.props.initialValue });
    }
  }

  handleChange = e => {
    if (this.props.onInputChange) {
      this.props.onInputChange(e.target.value);
    }
    this.setState({ value: e.target.value });
  };

  handleSelect = (value, item) => {
    const {
      form: { setFieldValue },
      field: { name },
      onSelect,
    } = this.props;

    if (onSelect) {
      onSelect(item);
    } else {
      setFieldValue(name, item.id);
    }

    this.setState({ value });
  };

  handleBlur = e => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
    this.props.field.onBlur(e);
  };

  getItemValue = item => item.label;

  renderItem = (item, highlighted) => {
    const { styles } = this.props;
    return (
      <div key={item.id} className={classNames([styles.renderItem, highlighted && styles.renderItemHighlighted])}>
        {item.label}
      </div>
    );
  };

  render() {
    const { field, form, label, labelVariant, items, disabled, insetLabel, placeholder, styles, required } = this.props;
    const error = form.touched[field.name] && form.errors[field.name];

    return (
      <InputScaffold
        label={label}
        labelVariant={labelVariant}
        error={error}
        insetLabel={insetLabel}
        required={required}
      >
        <ReactAutocomplete
          selectOnBlur={false}
          items={items}
          getItemValue={this.getItemValue}
          renderItem={this.renderItem}
          value={this.state.value}
          onSelect={this.handleSelect}
          onChange={this.handleChange}
          inputProps={{
            onBlur: this.handleBlur,
            name: field.name,
            disabled: disabled,
            className: [styles.root, error ? styles.error : null],
            style: {
              width: '100%',
            },
            placeholder,
          }}
          wrapperStyle={{
            position: 'relative',
            width: '100%',
          }}
          menuStyle={{
            boxShadow: 'inset 0 1px 0 0 #9B9B9B, 0 2px 3px 0 rgba(0,0,0,0.4)',
            backgroundColor: '#FFFFFF',
            borderRadius: 2,
            position: 'absolute',
            left: 0,
            top: 42,
            zIndex: 9999,
            color: theme.colors.blue,
            fontSize: '11px !important',
          }}
        />
      </InputScaffold>
    );
  }
}

const styles = props => ({
  root: {
    outline: 'none',
    width: '100%',
    height: '42px',
    padding: props.insetLabel ? '18px 12px 8px' : '12px 14px',
    border: '1px solid rgb(230, 230, 230)',
    borderRadius: '2px',
    backgroundColor: props.disabled ? props.theme.colors.tan : props.theme.colors.white,
    boxShadow: 'rgb(160, 160, 161) 0px 8px 1px -7px inset',
    fontFamily: props.theme.typography.sans,
    fontSize: props.insetLabel ? '11px' : '14px',
    fontWeight: 'normal',
    color: props.theme.colors.textDark,
    ':focus': {
      borderWidth: '1px !important',
      borderStyle: 'solid !important',
      borderColor: 'rgb(26, 185, 196)',
      borderImage: 'initial !important',
    },
    '::placeholder': {
      color: '#9C9C9C',
      fontWeight: 'normal',
      width: '100%',
    },
  },
  error: {
    color: props.theme.colors.red,
    borderColor: `${props.theme.colors.red} !important`,
    boxShadow: `0px 0px 0px 1px ${props.theme.colors.red} !important`,
    ':focus': {
      outline: 'none', // Disable default focus glow
      borderColor: `${props.theme.colors.red} !important`,
    },
  },
  renderItem: {
    padding: '12px 14px',
    fontSize: '11px',
    textAlign: 'left',
  },
  renderItemHighlighted: {
    backgroundColor: props.theme.colors.blue,
    color: props.theme.colors.white,
  },
});

export default connect(styles)(Autocomplete);
