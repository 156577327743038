/**
 * For future developers.
 * As of right now the new feature popup is geared towards migrated users.
 * This should changed after the migration is over so this component can be used to highlight new features, for all users.
 * Current Example:
 *  <PopupToolTip popupName="migratedUserInstructionsViewed" tips="newUser" migratedUserOnly={true} />
 *
 * If props 'migratedUserOnly' is not included it will default to false.
 */
import React from 'react';
import { connect as felaConnect } from 'react-fela';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import ShowPopupToolTip from './ShowPopupToolTip';

const ValidateUser = function({ popupName, tipsFor, migratedUserOnly = false, userVM, styles }) {
  const loggedIn = Boolean(userVM);
  const invalidUser = migratedUserOnly && !isValidMigrationUser(userVM);
  if (!loggedIn || !popupName || invalidUser) {
    return null;
  }

  return <ShowPopupToolTip popupName={popupName} tipsFor={tipsFor} styles={styles} />;
};

const isValidMigrationUser = function(userVM) {
  if (!userVM) {
    return false;
  }
  const isMigratedUser = userVM.get('isMigratedUser');
  const hasCompletedMigration = userVM.get('hasCompletedMigration');
  if (!isMigratedUser) {
    return false;
  }
  if (isMigratedUser && !hasCompletedMigration) {
    return false;
  }
  return true;
};

const mapStateToProps = state => ({
  userVM: state.userVM,
});

const styles = () => ({
  iconContainer: {
    marginRight: '5px',
  },
  flex: {
    display: 'flex',
  },
  toolTip: {
    boxShadow: '0 4px 16px rgba(0,0,0,0.25)',
    textTransform: 'none',
    color: 'white',
    position: 'fixed',
    zIndex: 100000001,
    top: '40px',
    backgroundColor: 'rgba(0,0,0, 0.8)',
    padding: '14px',
    borderRadius: '4px',
    textAlign: 'left',
    width: '200px',
  },
  toolTipTriangle: {
    position: 'absolute',
    zIndex: 1000,
    width: '0',
    height: '0',
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderBottom: '20px solid rgba(0,0,0, 0.8)',
  },
  toolTipTitle: {
    fontWeight: 'bold',
  },
  toolTipMessage: {
    padding: '14px 0',
    fontSize: '12px',
  },
  toolTipLinks: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
  next: {
    pointerEvents: 'auto',
    color: '#74CF3C',
    fontWeight: 'bold',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  skip: {
    pointerEvents: 'auto',
    color: '#FFC232',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
});

export default compose(connect(mapStateToProps), felaConnect(styles))(ValidateUser);
