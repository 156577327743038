const listingLimits = userVM => {
  if (!userVM.get('isActive') || !userVM.get('isMember')) {
    return;
  }

  if (userVM.get('isPremier')) {
    return 40;
  } else {
    return 20;
  }
};

export default listingLimits;
