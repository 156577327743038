import { connect } from 'react-redux';

import { favoriteListingsResultsSelector } from '../../../redux/selectors';
import { isLoggedInSelector } from '../../../../../redux/selectors';

import FavoriteListings from './FavoriteListings';

const mapStateToProps = state => {
  return {
    user: state.user,
    isLoggedInSelector: isLoggedInSelector(state),
    listings: favoriteListingsResultsSelector(state),
    loaded: state.account.favoriteListingsLoaded,
    loading: state.account.favoriteListingsLoading,
    total: state.account.favoriteListingsTotal,
    randomTips: state.randomTips,
  };
};

export default connect(mapStateToProps)(FavoriteListings);
