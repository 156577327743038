import React from 'react';
import RestrictedMessage from './RestrictedMessage';
import RedactedMessage from './RedactedMessage';
import AutomatedMessage from './AutomatedMessage';
import UserMessage from './UserMessage';

const Message = ({
  conversation,
  message,
  currentUser,
  styles,
  newFromId,
  listing,
  location,
  showTestimonialModal,
  history,
  deleteMessagePhoto,
}) => {
  const author = message.get('author');
  const isSystemMessage = !message.get('author_id');

  if (!author && message.get('body') === 'RESTRICTED') {
    return <RestrictedMessage message={message} history={history} styles={styles} newFromId={newFromId} />;
  }

  if (!author && message.get('body') === 'REDACTED') {
    return <RedactedMessage message={message} history={history} styles={styles} newFromId={newFromId} />;
  }

  if (isSystemMessage) {
    return (
      <AutomatedMessage
        message={message}
        currentUser={currentUser}
        styles={styles}
        newFromId={newFromId}
        listing={listing}
        location={location}
        showTestimonialModal={showTestimonialModal}
        author={author}
        conversation={conversation}
      />
    );
  }

  return (
    <UserMessage
      message={message}
      currentUser={currentUser}
      styles={styles}
      newFromId={newFromId}
      listing={listing}
      location={location}
      showTestimonialModal={showTestimonialModal}
      author={author}
      deleteMessagePhoto={deleteMessagePhoto}
    />
  );
};

export default Message;
