import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { Button } from '../';
import PuppyLogoWithText from '../icons/PuppyLogoWithText';

const HeroImage = isWebpSupported ? `/static/landingPageHero.webp` : `/static/landingPageHero.jpg`;

class ForceReloadPrompt extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };
  render() {
    const { styles } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <PuppyLogoWithText fill="#FFF" />
          <div className={styles.content}>
            <h1>New updates are available!</h1>
            <div className={styles.calloutAction}>
              <Button onClick={this.props.onClick} buttonType="largeRoundOrangeOutline">
                Update Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = props => ({
  root: {
    backgroundImage: `url(${HeroImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: 'auto',
    height: '100vh',
    position: 'relative',
    ':after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: 'rgba(138,109,91,.5)',
      height: '100%',
      width: '100%',
    },
  },
  wrapper: {
    content: '" "',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    ...props.theme.globalPageWrapper,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    '> h1': {
      fontSize: '28px',
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansAltBold,
      fontStyle: 'italic',
      color: props.theme.colors.white,
      textAlign: 'center',
      [props.theme.breakpoints.mobile]: {
        fontSize: '45px',
      },
    },
  },
  calloutAction: {
    textAlign: 'center',
    marginTop: '50px',
  },
});

export default felaConnect(styles)(ForceReloadPrompt);
