import React, { Component } from 'react';
import { connect } from 'react-fela';
import PropTypes from 'prop-types';
import { LoadingCircle } from '../';
import classNames from 'classnames';
import hasTouch from '../../utils/hasTouch';

class Button extends Component {
  static propTypes = {
    fullWidth: PropTypes.bool,
    buttonType: PropTypes.oneOf([
      'primary',
      'primaryLarge',
      'round',
      'roundSmall',
      'blue',
      'brandBlue',
      'icon',
      'white',
      'roundOrange',
      'roundWhite',
      'largeRoundOrangeOutline',
      'smallOrange',
      'subnavAction',
    ]).isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    iconLeft: PropTypes.node,
    iconRight: PropTypes.node,
    minwidth: PropTypes.string,
    padding: PropTypes.string,
    isSubmitting: PropTypes.bool,
    fontSize: PropTypes.string,
  };

  static defaultProps = {
    buttonType: 'primary',
    disabled: false,
    onClick: () => {},
  };

  handleClick = e => {
    e.currentTarget.blur();
    this.props.onClick(e);
  };

  render() {
    const {
      children,
      styles,
      buttonType,
      fullWidth,
      iconLeft,
      iconRight,
      isSubmitting,
      className,
      onClick,
      ...passthrough
    } = this.props;

    return (
      <button className={classNames([styles.root, className && className])} onClick={this.handleClick} {...passthrough}>
        <div className={styles.buttonChildren}>
          {iconLeft && <div className={styles.iconLeft}>{iconLeft}</div>}
          <span className={styles.child}>{children}</span>
          {isSubmitting ? (
            <div className={styles.loadingCircle}>
              <LoadingCircle />
            </div>
          ) : null}
          {iconRight && <div className={styles.iconRight}>{iconRight}</div>}
        </div>
      </button>
    );
  }
}

const styles = props => ({
  root: {
    display: 'inline-block',
    lineHeight: '14px',
    padding: props.padding || props.theme.buttonTypes[props.buttonType].padding,
    minWidth: props.minwidth || props.theme.buttonTypes[props.buttonType].minWidth,
    width: props.buttonType !== 'icon' ? (props.fullWidth ? '100%' : 'initial') : '40px',
    height: props.buttonType === 'icon' ? '40px' : 'initial',
    backgroundColor: props.theme.buttonTypes[props.buttonType].backgroundColor,
    borderRadius: props.theme.buttonTypes[props.buttonType].borderRadius,
    color: props.theme.buttonTypes[props.buttonType].color,
    boxShadow: props.theme.buttonTypes[props.buttonType].boxShadow,
    border:
      props.buttonType === 'round' ||
      props.buttonType === 'roundSmall' ||
      props.buttonType === 'roundWhite' ||
      props.buttonType === 'largeRoundOrangeOutline'
        ? `${props.theme.buttonTypes[props.buttonType].borderWidth} solid ${
            props.theme.buttonTypes[props.buttonType].borderColor
          }`
        : null,
    cursor: props.disabled ? 'auto' : 'pointer',
    transition: props.buttonType === 'icon' ? 'none' : props.theme.globalTransition,
    position: 'relative',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    ':hover': {
      backgroundColor: hasTouch()
        ? null
        : !props.disabled
        ? props.theme.buttonTypes[props.buttonType].hoverBackgroundColor
        : null,
      color: hasTouch() ? null : !props.disabled && props.theme.buttonTypes[props.buttonType].hoverColor,
      borderColor: hasTouch() ? null : !props.disabled && props.theme.buttonTypes[props.buttonType].hoverBorderColor,
    },

    ':disabled': {
      backgroundColor: props.theme.colors.disabled,
      boxShadow: 'none',
      color: props.theme.colors.disabledText,
    },
    ':active': {
      backgroundColor: !props.disabled && props.theme.colors.green,
      color: !props.disabled && props.theme.colors.white,
      boxShadow: !props.disabled && 'inset 0 2px 1px 0 #417505',
      borderColor: !props.disabled && 'transparent',
      outline: 'none',
    },
  },
  buttonChildren: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: props.theme.buttonTypes[props.buttonType].fontFamily,
    fontWeight: props.theme.buttonTypes[props.buttonType].fontWeight,
    letterSpacing: props.theme.buttonTypes[props.buttonType].letterSpacing,
    textTransform: props.theme.buttonTypes[props.buttonType].textTransform,
    fontSize: props.fontSize || props.theme.buttonTypes[props.buttonType].fontSize,
    '> a': {
      color: 'currentColor',
    },
  },
  iconLeft: {
    marginRight: '6px',
  },
  iconRight: {
    marginLeft: '6px',
  },
  child: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingCircle: {
    marginLeft: '16px',
  },
});

export default connect(styles)(Button);
