import React, { useState } from 'react';

import TestimonialModalSellerForm from './TestimonialModalSellerForm';
import TestimonialModalSellerSuccess from './TestimonialModalSellerSuccess';

import { Modal } from '../';

const TestimonialModalSeller = ({
  createOrUpdateTestimonial,
  replyToTestimonial,
  isOpen,
  seller,
  user,
  isReply,
  testimonialId,
  edits,
  onClose = () => {},
}) => {
  const [isSuccess, setIsSuccess] = useState(false);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOutsideClick closeOnEscape>
      <div data-testid="seller-testimonial-modal">
        {isSuccess ? (
          <TestimonialModalSellerSuccess isReply={isReply} />
        ) : (
          <TestimonialModalSellerForm
            createOrUpdateTestimonial={createOrUpdateTestimonial}
            replyToTestimonial={replyToTestimonial}
            seller={seller}
            user={user}
            onSave={() => setIsSuccess(true)}
            isReply={isReply}
            testimonialId={testimonialId}
            edits={edits}
          />
        )}
      </div>
    </Modal>
  );
};

export default TestimonialModalSeller;
