import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateUser,
  setErrorBarMessage,
  getUploadToken,
  clearErrorBar,
  deleteUserImage,
} from '../../../../redux/actions';
import MyProfile from './MyProfile';

import {
  purchaseMembership,
  getMembershipPlans,
  getPayments,
  getBraintreeToken,
  resetPaymentHistory,
} from '../../redux/actions';

const mapStateToProps = state => {
  return {
    user: state.user,
    userVM: state.userVM,
    purchaseErrors: state.account.purchaseErrors,
    membershipPlans: state.account.membershipPlans,
    membershipPlansLoaded: state.account.membershipPlansLoaded,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      purchaseMembership,
      updateUser,
      setErrorBarMessage,
      getUploadToken,
      clearErrorBar,
      getMembershipPlans,
      getPayments,
      getBraintreeToken,
      deleteUserImage,
      resetPaymentHistory,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
