import React from 'react';

function Caret() {
  /* eslint-disable max-len */
  return (
    <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle fill="#0085F2" fillRule="nonzero" cx="18" cy="18" r="18" />
        <path
          d="M27.4938439,19.6521476 C27.8085053,19.411245 27.8775773,19.0017104 27.6857106,18.4315741 L26.181476,13.7339721 L30.0341585,10.8431401 C30.4946385,10.4978463 30.6865051,10.1284622 30.5637105,9.73498788 C30.4409158,9.34151353 30.0802065,9.15682149 29.5122812,9.16485158 L24.7846867,9.19697193 L23.3495241,4.47527969 C23.1653321,3.89711329 22.8967188,3.6 22.5053108,3.6 C22.1062282,3.6 21.8376148,3.89711329 21.6534228,4.47527969 L20.2182602,9.19697193 L15.4906658,9.16485158 C14.9227405,9.15682149 14.5620311,9.34151353 14.4392365,9.73498788 C14.3087671,10.1284622 14.5083085,10.4978463 14.9687884,10.8431401 L18.821471,13.7339721 L17.3172364,18.4315741 C17.1253697,19.0017104 17.1944417,19.411245 17.509103,19.6521476 C17.8237643,19.9091105 18.2151723,19.8207795 18.683327,19.4674556 L22.5053108,16.5364731 L26.31962,19.4674556 C26.7877746,19.8207795 27.1791826,19.9091105 27.4938439,19.6521476 Z"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <line x1="13.5" y1="13.5" x2="6.3" y2="20.7" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" />
        <line x1="24.3" y1="22.5" x2="17.1" y2="29.7" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" />
        <line
          x1="15.3"
          y1="21.4714286"
          x2="8.1"
          y2="28.6714286"
          stroke="#FFFFFF"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default Caret;
