import React from 'react';
import Select, { components } from 'react-select';
import ArrowDown from '../icons/ArrowDown';
import theme from '../../../theme';

const Dropdown = ({
  options,
  variant = 'primary',
  placeholder = '',
  disabled = false,
  onBlur,
  inputId,
  selectedOption,
  onChange,
}) => {
  return (
    <Select
      options={options}
      classNamePrefix="react-select"
      styles={getCustomStyles(variant)}
      isDisabled={disabled}
      inputId={inputId}
      onBlur={onBlur}
      onChange={option => onChange(option)}
      value={selectedOption}
      isSearchable={false}
      components={{ DropdownIndicator }}
      placeholder={placeholder}
    />
  );
};

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDown />
      </components.DropdownIndicator>
    )
  );
};

const getCustomStyles = variant => ({
  control: (base, state) => ({
    background: state.isFocused
      ? `${variant === 'primary' ? theme.colors.darkerBlueHover : theme.colors.white}`
      : `${variant === 'primary' ? theme.colors.darkerBlue : theme.colors.white}`,
    borderRadius: '2px',
    border: state.isFocused ? `1px solid ${theme.colors.blue}` : '1px solid transparent',
    boxShadow: variant === 'primary' ? '0 2px 0 0 #167178' : '0 2px 0 0 #9B9B9B',
    display: 'flex',
    opacity: state.isDisabled ? '0.66' : '1.0',
    padding: variant === 'primary' ? '0 28px 0 16px' : '0 10px',
    height: variant === 'primary' ? '31px' : '40px',
    position: 'relative',
    fontSize: variant === 'primary' ? '12px' : '11px',
    transition: 'all 100ms ease-in-out',
    '> .react-select__indicators > .react-select__indicator': {
      fill: variant === 'primary' ? theme.colors.white : theme.colors.textDark,
    },
    ':hover': {
      backgroundColor: variant === 'primary' ? theme.colors.darkerBlueHover : null,
      '> .react-select__value-container > .react-select__single-value': {
        color: variant === 'primary' ? theme.colors.white : theme.colors.blue,
      },
      '> .react-select__value-container > .react-select__placeholder': {
        color: variant === 'primary' ? theme.colors.white : theme.colors.blue,
      },
      '> .react-select__indicators > .react-select__indicator': {
        fill: variant === 'primary' ? theme.colors.white : theme.colors.blue,
      },
    },
    cursor: 'pointer',
  }),
  container: (base, state) => ({
    ...base,
    input: {
      textIndent: '-9999999em',
    },
  }),
  placeholder: () => ({
    color: variant === 'primary' ? theme.colors.white : theme.colors.textDark,
    transition: 'all 100ms ease-in-out',
    textTransform: 'none',
  }),
  singleValue: (provided, state) => ({
    color: state.isDisabled
      ? theme.colors.disabledText
      : variant === 'primary'
      ? theme.colors.white
      : theme.colors.textDark,
    textTransform: 'none',
    transition: 'all 100ms ease-in-out',
    whiteSpace: 'nowrap',
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
    flexWrap: 'nowrap',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: base => ({
    ...base,
    fill: 'currentColor',
    position: 'absolute',
    padding: variant === 'primary' ? '4px 8px' : '8px',
    right: '-5px',
    top: variant === 'primary' ? -2 : 0,
    transition: 'all 100ms ease-in-out',
  }),
  // Select Menu
  menu: base => ({
    ...base,
    margin: 0,
    borderRadius: '0 0 3px 3px',
    background: variant === 'primary' ? theme.colors.darkerBlueHover : theme.colors.white,
    border: 'none',
    boxShadow: variant === 'primary' ? '0 2px 4px rgba(0,0,0, .5)' : '0 2px 4px 0 #9B9B9B',
    zIndex: 9999,
  }),
  option: (base, state) => ({
    ...base,
    textAlign: 'left',
    color: state.isFocused ? theme.colors.white : variant === 'primary' ? theme.colors.white : theme.colors.blue,
    textTransform: 'none',
    background: state.isFocused ? theme.colors.blue : 'transparent',
    cursor: 'pointer',
    fontSize: variant === 'primary' ? '12px' : '11px',
  }),
});

export default Dropdown;
