import React from 'react';

function Duplicate() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>
        <path d="M10.45 1v.011L6.574 4.815v1.27L4 8.606V23h13.427v-3.792H20V1h-9.55zM8.117 6.332h3.879V2.517h6.461V17.69H8.116V6.33zm-2.572 3.792h1.029v9.084h9.31v2.275H5.544V10.124z" />
        <path d="M14.207 9h-1.414v1.793H11v1.414h1.793V14h1.414v-1.793H16v-1.414h-1.793z" />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default Duplicate;
