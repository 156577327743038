import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import * as yup from 'yup';
import { withFormik, Field } from 'formik';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { withRouter } from 'react-router-dom';
import localstorage from 'store2';
import querystring from 'query-string';

import { Dropdown, RangeSlider, TwoButtonToggle, Button } from '../../common/components';
import { defaults } from '../SearchListings/common/search-config';
import { breedMatchRefineResult } from '../SearchListings/common/search-config';
import { Helmet } from 'react-helmet';
const spaceOptions = [
  { value: 1, label: 'No yard' },
  { value: 2, label: 'Patio' },
  { value: 3, label: 'Yard' },
  { value: 4, label: 'Acreage' },
];

class BreedMatchmaker extends Component {
  componentDidMount() {
    document.title = 'Breed Match Maker - Puppies.com';
  }
  render() {
    const { handleSubmit, styles, isSubmitting } = this.props;
    return (
      <div className={styles.pageWrapper}>
        <Helmet>
          <title>Breed Match Maker - Puppies.com</title>
        </Helmet>
        <form id="breed-matchmaker-form" onSubmit={handleSubmit}>
          <div className={styles.header}>
            <div className={styles.headerInner}>
              <div className={styles.headerTitle}>
                <h1>Breed Matchmaker</h1>
              </div>
              <p>
                Let’s make a match!
                <br />
                Take our short quiz and find the best breeds for your lifestyle.
              </p>
            </div>
          </div>
          <div className={styles.formWrapper}>
            <div className={styles.form}>
              <div className={styles.sliderSection + ' ' + styles.section}>
                <div className={styles.sliderWrapper}>
                  <h1 className={styles.title}>What size range is ok?</h1>
                  <div>
                    <div className={styles.sliders}>
                      <Field
                        name="size_range"
                        component={RangeSlider}
                        minValue={1}
                        maxValue={5}
                        hideChip
                        showTicks
                        step={1}
                        minOverwrite="Miniature"
                        maxOverwrite="Extra Large"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.sliderWrapper}>
                  <h1 className={styles.title}>How much grooming is ok?</h1>
                  <div>
                    <div className={styles.sliders}>
                      <Field
                        name="grooming_rating"
                        component={RangeSlider}
                        minValue={1}
                        maxValue={5}
                        hideChip
                        showTicks
                        step={1}
                        minOverwrite="Little to none"
                        maxOverwrite="High maintenance"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.sliderWrapper}>
                  <h1 className={styles.title}>Playfulness preference?</h1>
                  <div>
                    <div className={styles.sliders}>
                      <Field
                        name="playfulness_rating"
                        component={RangeSlider}
                        minValue={1}
                        maxValue={5}
                        hideChip
                        showTicks
                        step={1}
                        minOverwrite="Fetch? I think not."
                        maxOverwrite="Let's play!"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.sliderWrapper}>
                  <h1 className={styles.title}>Do you need a watchdog?</h1>
                  <div>
                    <div className={styles.sliders}>
                      <Field
                        name="watchdog_rating"
                        component={RangeSlider}
                        minValue={1}
                        maxValue={5}
                        hideChip
                        showTicks
                        step={1}
                        minOverwrite="Not really"
                        maxOverwrite="Absolutely"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.sliderWrapper}>
                  <h1 className={styles.title}>Do you want an active dog?</h1>
                  <div>
                    <div className={styles.sliders}>
                      <Field
                        name="exercise_rating"
                        component={RangeSlider}
                        minValue={1}
                        maxValue={5}
                        hideChip
                        showTicks
                        step={1}
                        minOverwrite="Couch potato"
                        maxOverwrite="Born to run"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.sliderWrapper}>
                  <h1 className={styles.title}>Is trainability important?</h1>
                  <div>
                    <div className={styles.sliders}>
                      <Field
                        name="trainability_rating"
                        component={RangeSlider}
                        minValue={1}
                        maxValue={5}
                        hideChip
                        showTicks
                        step={1}
                        minOverwrite="Not really"
                        maxOverwrite="Best in show"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.sliderWrapper}>
                  <h1 className={styles.title}>How much barking is tolerable?</h1>
                  <div>
                    <div className={styles.sliders}>
                      <Field
                        name="barking_rating"
                        component={RangeSlider}
                        minValue={1}
                        maxValue={5}
                        hideChip
                        showTicks
                        step={1}
                        minOverwrite="Very Little"
                        maxOverwrite="Woof!"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.sliderWrapper}>
                  <h1 className={styles.title}>Do you want a cuddly dog?</h1>
                  <div>
                    <div className={styles.sliders}>
                      <Field
                        name="affection_rating"
                        component={RangeSlider}
                        minValue={1}
                        maxValue={5}
                        hideChip
                        showTicks
                        step={1}
                        minOverwrite="Not really"
                        maxOverwrite="Never leave my side"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.spaceRow + ' ' + styles.section}>
                <h1 className={styles.title}>What outdoor space do you have?</h1>
                <div>
                  <div className={styles.dropdown}>
                    <Field name="space_rating" component={Dropdown} options={spaceOptions} variant="secondary" />
                  </div>
                </div>
              </div>
              <div className={styles.toggleSection + ' ' + styles.section}>
                <h1 className={styles.title}>Do you have...</h1>
                <div className={styles.toggleWrapper}>
                  <Field
                    label="Small Children"
                    name="child_rating"
                    primaryText="Yes"
                    secondaryText="No"
                    primaryValue={true}
                    secondaryValue={false}
                    component={TwoButtonToggle}
                    fixederror
                  />
                  <Field
                    label="Other Dogs"
                    name="dog_rating"
                    primaryText="Yes"
                    secondaryText="No"
                    primaryValue={true}
                    secondaryValue={false}
                    component={TwoButtonToggle}
                    fixederror
                  />
                  <Field
                    label="Cats"
                    name="cat_rating"
                    primaryText="Yes"
                    secondaryText="No"
                    primaryValue={true}
                    secondaryValue={false}
                    component={TwoButtonToggle}
                    fixederror
                  />
                  <Field
                    label="Dog Allergies"
                    name="is_hypoallergenic"
                    primaryText="Yes"
                    secondaryText="No"
                    primaryValue={true}
                    secondaryValue={false}
                    component={TwoButtonToggle}
                    fixederror
                  />
                </div>
              </div>
              <div className={styles.locationSection}>
                <h1>Let's find your perfect puppies!</h1>
                <Button type="submit" disabled={isSubmitting}>
                  Show My Matches!
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const FormikBreedMatchmaker = connect(mapStateToProps)(
  withFormik({
    mapPropsToValues: props => {
      let params = new URLSearchParams(props.location.search);
      const preformFormattedLocation = props.user ? props.user.get('formatted_location') : defaults.formatted_location;
      const preformLocation = props.user ? `${props.user.get('lat')},${props.user.get('lng')}` : defaults.location;
      let location = preformLocation
        ? {
            label: preformFormattedLocation,
            location: {
              lat: parseFloat(preformLocation.split(',')[0]),
              lng: parseFloat(preformLocation.split(',')[1]),
            },
          }
        : null;
      return {
        playfulness_rating: params.get('playfulness_rating') ? params.get('playfulness_rating') : 3,
        exercise_rating: params.get('exercise_rating') ? params.get('exercise_rating') : 3,
        barking_rating: params.get('barking_rating') ? params.get('barking_rating') : 3,
        grooming_rating: params.get('grooming_rating') ? params.get('grooming_rating') : 3,
        watchdog_rating: params.get('watchdog_rating') ? params.get('watchdog_rating') : 3,
        trainability_rating: params.get('trainability_rating') ? params.get('trainability_rating') : 3,
        affection_rating: params.get('affection_rating') ? params.get('affection_rating') : 3,
        child_rating: params.get('child_rating') ? JSON.parse(params.get('child_rating')) : false,
        cat_rating: params.get('cat_rating') ? JSON.parse(params.get('cat_rating')) : false,
        dog_rating: params.get('dog_rating') ? JSON.parse(params.get('dog_rating')) : false,
        is_hypoallergenic: params.get('is_hypoallergenic') ? JSON.parse(params.get('is_hypoallergenic')) : false,
        size_range: {
          min: params.get('size_min') ? params.get('size_min') : 1,
          max: params.get('size_max') ? params.get('size_max') : 5,
        },
        space_rating: params.get('space_rating') ? params.get('space_rating') : 1,
        location: location,
        formatted_location: preformFormattedLocation || '',
      };
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      child_rating: yup
        .boolean()
        .typeError('You must select either yes or no')
        .required(),
      dog_rating: yup
        .boolean()
        .typeError('You must select either yes or no')
        .required(),
      cat_rating: yup
        .boolean()
        .typeError('You must select either yes or no')
        .required(),
      is_hypoallergenic: yup
        .boolean()
        .typeError('You must select either yes or no')
        .required(),
      playfulness_rating: yup
        .number()
        .min(1)
        .max(5)
        .required(),
      exercise_rating: yup
        .number()
        .min(1)
        .max(5)
        .required(),
      barking_rating: yup
        .number()
        .min(1)
        .max(5)
        .required(),
      grooming_rating: yup
        .number()
        .min(1)
        .max(5)
        .required(),
      watchdog_rating: yup
        .number()
        .min(1)
        .max(5)
        .required(),
      trainability_rating: yup
        .number()
        .min(1)
        .max(5)
        .required(),
      affection_rating: yup
        .number()
        .min(1)
        .max(5)
        .required(),
      size_range: yup.object().shape({
        min: yup
          .number()
          .min(1)
          .max(5)
          .required(),
        max: yup
          .number()
          .min(1)
          .max(5)
          .required(),
      }),
      space_rating: yup
        .number()
        .min(1)
        .max(5)
        .required(),
      formatted_location: yup.string(),
    }),
    handleSubmit: async (values, { props, setFieldError, setSubmitting, setStatus, setErrors, setFieldValue }) => {
      // eslint-disable-next-line
      const { ...restValues } = values;
      if (!props.user && values.location) {
        localstorage.set('location', JSON.stringify(values.location));
      }
      let searchQuery = {
        breed: [],
        formatted_breed: [],
        location: null,
        advanced: true,
        show_matchmaker: true,
        slug: [],
      };

      if (restValues.location) {
        searchQuery.location = `${restValues.location.location.lat.toFixed(
          3
        )},${restValues.location.location.lng.toFixed(3)}`;
        searchQuery.formatted_location = restValues.location.label;
      }
      delete restValues.location;
      delete restValues.formatted_location;
      setStatus({});

      const action = await props.getMatchmakerResults(restValues);
      if (action.response.ok) {
        setSubmitting(false);
        setStatus({ success: true });
        for (let i = 0; i < action.json.length; i++) {
          searchQuery.breed.push(action.json[i]._id);
          searchQuery.formatted_breed.push(action.json[i]._source.name);
          searchQuery.slug.push(action.json[i]._source.slug);
        }

        const data = breedMatchRefineResult(values);
        const params = { ...data, ...searchQuery };
        const searchString = querystring.stringify(params);
        props.history.push(`/find-a-puppy?${searchString}`);
      }
    },
  })(BreedMatchmaker)
);

const styles = props => ({
  pageWrapper: {
    ...props.theme.globalBackgroundContainer,
  },
  header: {
    padding: '27px 0',
    backgroundColor: '#FF6984',
  },
  headerInner: {
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
    ...props.theme.globalPadding,
    color: props.theme.colors.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '> p': {
      textAlign: 'center',
      marginTop: '16px',
      fontSize: '14px',
      lineHeight: '18px',
    },
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      '> p': {
        textAlign: 'left',
        marginTop: 0,
        marginLeft: '20px',
      },
    },
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '28px',
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltLight,
    '> * + *': {
      paddingLeft: '10px',
    },
  },
  formWrapper: {
    ...props.theme.globalPadding,
  },
  form: {
    maxWidth: '880px',
    margin: '20px auto',
  },
  title: {
    fontSize: '24px',
    lineHeight: '34px',
    marginBottom: '28px',
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltLight,
    color: props.theme.colors.orange,
    [props.theme.breakpoints.tablet]: {
      fontSize: '28px',
      whiteSpace: 'pre',
      marginBottom: '34px',
    },
  },
  sliderSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px 40px !important',
    [props.theme.breakpoints.tablet]: {
      padding: '0 0 40px !important',
      justifyContent: 'space-between',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
  sliderWrapper: {
    width: '100%',
    marginTop: '42px',
    [props.theme.breakpoints.tablet]: {
      width: '340px',
    },
  },
  sliders: {
    width: '100%',
  },
  section: {
    padding: '40px 0',
    borderBottom: props.theme.globalDashedBorder,
  },
  spaceRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      '> h1': {
        marginBottom: 0,
      },
    },
  },
  dropdown: {
    width: '143px',
  },
  toggleSection: {
    '> h1': {
      marginBottom: '0 !important',
    },
  },
  toggleWrapper: {
    '> *': {
      marginTop: '32px',
    },
    [props.theme.breakpoints.mobile]: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  locationSection: {
    padding: '50px 0',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '> h1': {
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansAltBold,
      color: props.theme.colors.orange,
      fontSize: '28px',
      textAlign: 'center',
    },
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  locationFieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '32px',
    width: '100%',
    '> * + *': {
      marginTop: '20px',
    },
    '> button': {
      width: '100%',
      backgroundColor: '#FF6984',
      ':hover': {
        backgroundColor: '#F2647D',
      },
      ':active': {
        backgroundColor: props.theme.colors.green,
      },
    },
    [props.theme.breakpoints.tablet]: {
      width: 'inherit',
      marginTop: 0,
      flexDirection: 'row',
      '> * + *': {
        marginTop: 0,
        marginLeft: '20px',
      },
      '> button': {
        width: 'inherit',
      },
    },
  },
  locationField: {
    width: '100%',
    [props.theme.breakpoints.tablet]: {
      width: '190px',
    },
  },
  geocomplete: {
    bottom: '42px',
    top: 'inherit',
  },
});

export default compose(felaConnect(styles), withRouter)(FormikBreedMatchmaker);
