import React from 'react';
import classNames from 'classnames';
import { Button } from '../../../../../../common/components';
import MessageTimestamp from './MessageTimestamp';

const RestrictedMessage = ({ message, history, styles, newFromId }) => {
  return (
    <div
      key={message.get('id')}
      className={classNames(
        styles.messageWrapper,
        styles.messageWrapperExpired,
        newFromId === message.get('id') ? styles.messageWrapperNew : null
      )}
    >
      {newFromId === message.get('id') ? <div className={styles.newMessageBorder}>New</div> : null}
      <div className={styles.author}>Membership Expired</div>
      <div className={styles.innerMessageWrapper}>
        <div className={styles.message}>You need to upgrade to view this message</div>
        <Button buttonType="round" onClick={() => history.push('/account/membership')}>
          Upgrade Now!
        </Button>
      </div>
      <MessageTimestamp styles={styles} message={message} />
    </div>
  );
};

export default RestrictedMessage;
