import React, { useEffect, useState } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import uuid from 'uuid';

import { Loading, ModalBody } from '../../../../../common/components';

import PaymentError from './PaymentError';
import RequestError from './RequestError';
import PaymentStep from './PaymentStep';
import PaymentSuccess from './PaymentSuccess';
import GenericMessage from './GenericMessage';

import getPriceForPlan from './getPriceForPlan';

const PurchaseWizard = ({
  errors = 0,
  getBraintreeToken,
  onCancel = () => {},
  onSubmit = () => {},
  membershipPlans,
  plan,
  setAsSuccessfulPayment,
  userVM,
  user,
  checkForRecentTransaction,
}) => {
  const selectedPlan = membershipPlans.get(plan, Map());
  const price = getPriceForPlan(selectedPlan, userVM);
  const price_in_dollars = price / 100;

  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [requestError, setRequestError] = useState(false);
  const [nonce, setNonce] = useState();
  const [step, setStep] = useState(0);
  const [token, setToken] = useState();
  const [transaction, setTransaction] = useState(null);
  const [transactionRetrieved, setTransactionRetrieved] = useState(false);

  useEffect(() => {
    getBraintreeToken(plan).then(res => {
      if (res && res.response.ok) {
        setStep(currentStep => currentStep + 1);
        setToken(res.json.clientToken);
      } else {
        if (res && res.json && res.json['statusCode'] === 403) {
          setErrorMessage(res.json?.message);
        }
        setHasError(true);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (step === 2) {
      submitPayment();
    }
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (requestError) {
      checkForRecentTransaction().then(res => {
        if (res && res.response.ok) {
          setTransaction(res.json.transaction);
        }
        setTransactionRetrieved(true);
      });
    }
  }, [requestError]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitPayment = nonce => {
    setNonce(nonce);
    setStep(currentStep => currentStep + 1);
  };

  const submitPayment = async () => {
    const action = await onSubmit({ nonce, plan });

    if (!action) {
      setRequestError(true);
    } else {
      if (action.response.ok) {
        const id = action && action.json && action.json.transaction_id ? action.json.transaction_id : uuid.v4();
        const tagManagerArgs = {
          dataLayer: {
            event: 'ecommercePurchase',
            ecommerce: {
              purchase: {
                actionField: {
                  id: id,
                  revenue: price_in_dollars,
                },
              },
              products: [
                {
                  name: selectedPlan.get('name'),
                  id: selectedPlan.get('id'),
                  price: price_in_dollars,
                  category: selectedPlan.get('benefits') === 'premier' ? 'Monthly' : 'One-Time',
                  quantity: 1,
                },
              ],
            },
          },
        };
        TagManager.dataLayer({ ecommerce: null }); // Clear the previous ecommerce object.
        TagManager.dataLayer(tagManagerArgs);

        if (setAsSuccessfulPayment) {
          setAsSuccessfulPayment();
        }
      }
      setHasError(!action.response.ok);
      setErrorMessage(action.json?.message);
      setStep(currentStep => currentStep + 1);
    }
  };

  if (requestError && transactionRetrieved) {
    if (transaction) {
      const message = `We are processing the payment for your ${transaction.description}, please check back shortly.`;
      return (
        <GenericMessage
          onCancel={() => {
            window.location.reload();
          }}
          title="Payment Processing"
          message={message}
          buttonText="DONE"
        />
      );
    } else {
      return <RequestError onCancel={onCancel} />;
    }
  }

  if (hasError) {
    if (errorMessage) {
      return (
        <GenericMessage
          onCancel={() => {
            window.location = '/account/membership';
          }}
          title="Payment Processing"
          message={errorMessage}
          buttonText="DONE"
        />
      );
    } else {
      return <PaymentError errors={errors} errorMessage={errorMessage} onCancel={onCancel} />;
    }
  }

  if (step === 1) {
    const tagManagerArgs = {
      dataLayer: {
        event: 'ecommerceView',
        ecommerce: {
          name: selectedPlan.get('name'),
          id: selectedPlan.get('id'),
          price: price_in_dollars,
          category: selectedPlan.get('benefits') === 'premier' ? 'Monthly' : 'One-Time',
        },
      },
    };
    TagManager.dataLayer({ ecommerce: null }); // Clear the previous ecommerce object.
    TagManager.dataLayer(tagManagerArgs);

    return (
      <PaymentStep
        onCancel={onCancel}
        onSubmit={handleSubmitPayment}
        plan={plan}
        price={price}
        selectedPlan={selectedPlan}
        token={token}
        userVM={userVM}
        user={user}
      />
    );
  }

  if (step === 3) {
    return <PaymentSuccess onCancel={onCancel} price={price} selectedPlan={selectedPlan} />;
  }

  return (
    <ModalBody>
      <Loading center dark />
    </ModalBody>
  );
};

PurchaseWizard.propTypes = {
  errors: PropTypes.number,
  getBraintreeToken: PropTypes.func.isRequired,
  membershipPlans: PropTypes.instanceOf(Map).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  plan: PropTypes.string.isRequired,
  setAsSuccessfulPayment: PropTypes.func,
};

export default PurchaseWizard;
