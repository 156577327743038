import React from 'react';
import { Button } from '../../../../../../common/components';
import ViewFeedbackButton from '../ViewFeedbackButton';

const SystemMessageButton = ({ message, currentUser, listing, showTestimonialModal, location }) => {
  if (message.get('author_id') !== null) {
    return null;
  }

  const isOwnListing = currentUser.get('id') === listing.get('seller');

  if (!isOwnListing && message.get('body') === 'SOLD' && !listing.get('testimonial')) {
    return (
      <Button data-testid="write-testimonial-button" buttonType="round" onClick={() => showTestimonialModal()}>
        Write Testimonial
      </Button>
    );
  }

  if (message.get('body') === 'TESTIMONIAL_CREATED') {
    if (isOwnListing) {
      return <ViewFeedbackButton text={'View Testimonial'} tab={'received'} location={location} />;
    }
    return <ViewFeedbackButton text={'View Testimonial'} tab={'left'} location={location} />;
  } else if (message.get('body') === 'TESTIMONIAL_REPLY') {
    if (isOwnListing) {
      return null;
    }
    return <ViewFeedbackButton text={'View Reply'} tab={'left'} location={location} />;
  }

  return null;
};

export default SystemMessageButton;
