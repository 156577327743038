import React from 'react';
import { connect } from 'react-fela';
import { PlainLink } from '../../../../common/components';

function MyProfileHeader({ styles, showLinkToPublicProfile, userId }) {
  return (
    <h1 className={styles.profileTitle}>
      Primary Contact Information{' '}
      {showLinkToPublicProfile && (
        <PlainLink className={styles.profileLink} variant="blue" to={`/profile/${userId}`}>
          View Profile
        </PlainLink>
      )}
    </h1>
  );
}

const styles = {
  profileTitle: props => ({
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.orange,
    fontSize: '16px',
    marginBottom: '36px',
  }),
  profileLink: {
    marginLeft: '10px',
  },
};

export default connect(styles)(MyProfileHeader);
