import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import config from '../../config';

const title = 'Find Puppies for Sale at Puppies.com';
const description = `Puppies.com will help you find your perfect puppy for sale. We've connected loving homes to reputable breeders since 2003 and we want to help you find the puppy your whole family will love.`;

export default function GenericMeta({ user }) {
  const location = useLocation();
  const canonicalUrl = `${config.frontendHost}${location?.pathname}`;

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={`${config.frontendHost}/static/landingPageHero.jpg`} />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      {user && <meta name="user-uuid" content={user.get('id')} />}
      {user && <meta name="user-email" content={user.get('email_lower')} />}
      {user && <meta name="user-name" content={`${user.get('first_name')} ${user.get('last_name')}`} />}
    </Helmet>
  );
}
