import React from 'react';
import PropTypes from 'prop-types';

import MatchmakerHeader from './MatchmakerHeader';
import ListingSearchFormikMasterContainer from './components/ListingSearchFormikMasterContainer';

const SearchHeader = ({ isAdvancedSearch, isMatchmakerSearch, isMobile, onSubmit, query } = this.props) => {
  if (isMatchmakerSearch) {
    return <MatchmakerHeader query={query} />;
  }

  return (
    <ListingSearchFormikMasterContainer
      isSearchAdvanced={isAdvancedSearch}
      isMobile={isMobile}
      key={`adv-${JSON.stringify(query)}`}
      query={query}
      onSubmit={onSubmit}
    />
  );
};

SearchHeader.propTypes = {
  initialLocationInformation: PropTypes.object,
  isAdvancedSearch: PropTypes.bool,
  isMatchmakerSearch: PropTypes.bool,
  isMobile: PropTypes.bool,
  onSubmit: PropTypes.func,
  query: PropTypes.object.isRequired,
};

export default SearchHeader;
