import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import PropTypes from 'prop-types';

class IconMenu extends Component {
  state = { showMenu: false };

  openMenu = () => this.setState({ showMenu: true });

  closeMenu = () => this.setState({ showMenu: false });

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDomClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDomClick);
  }

  handleDomClick = e => {
    if (!this.ref.contains(e.target) && this.state.showMenu) {
      this.closeMenu();
    }
  };

  render() {
    const { children, icon, styles } = this.props;
    const { showMenu } = this.state;

    return (
      <div
        className={styles.root}
        onMouseOver={this.openMenu}
        onMouseLeave={this.closeMenu}
        ref={node => {
          this.ref = node;
        }}
      >
        <div className={styles.icon}>{icon}</div>
        {showMenu && (
          <div className={styles.verticalMenuWrapper}>
            <div className={styles.horizontalMenuWrapper}>
              <div className={styles.menu} onClick={this.closeMenu}>
                {children}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

IconMenu.propTypes = {
  icon: PropTypes.node.isRequired,
  children: PropTypes.any,
};

const styles = {
  root: {
    position: 'relative',
  },
  icon: {
    cursor: 'pointer',
  },
  verticalMenuWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    paddingTop: '52px',
    width: '48px',
    cursor: 'pointer',
  },
  horizontalMenuWrapper: {
    position: 'absolute',
    top: '23px',
    right: 0,
    paddingTop: '29px',
    width: '150px',
  },
  menu: props => ({
    position: 'absolute',
    backgroundColor: props.theme.colors.blue,
    borderRadius: props.theme.globalBorderRadius,
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.24)',
    zIndex: 9999,
    right: 0,
    '> a': {
      display: 'block',
      padding: '16px 20px',
      fontSize: '12px',
      color: props.theme.colors.white,
      textTransform: 'uppercase',
      fontWeight: props.theme.typography.sansBold,
      whiteSpace: 'nowrap',
      transition: props.theme.globalTransition,
      ':hover': {
        backgroundColor: props.theme.colors.darkerBlue,
      },
      ':first-of-type': {
        borderTopRightRadius: props.theme.globalBorderRadius,
        borderTopLeftRadius: props.theme.globalBorderRadius,
      },
      ':last-of-type': {
        borderBottomRightRadius: props.theme.globalBorderRadius,
        borderBottomLeftRadius: props.theme.globalBorderRadius,
      },
    },
    ':before': {
      content: "''",
      position: 'absolute',
      right: '8px',
      top: '-10px',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 8px 10px 8px',
      borderColor: `transparent transparent ${props.theme.colors.blue} transparent`,
      zIndex: 9999,
    },
  }),
};

export default felaConnect(styles)(IconMenu);
