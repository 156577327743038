import querystring from 'query-string';
import client from '../../../common/utils/client';

export const fetchArticles = (filter, filterValue, page = 0, page_size = 3) => ({
  promise: client.get(
    `articles?${querystring.stringify({
      filter,
      filterValue,
      page,
      page_size,
    })}`
  ),
  type: 'FETCH_ARTICLES',
});

export const getArticleById = (id, query) => ({
  promise: client.get(`articles/${id}${query}`),
  type: 'GET_ARTICLE',
});

export const getRecentArticles = () => ({
  promise: client.get(
    `articles?${querystring.stringify({
      filter: false,
      filterValue: false,
      page: 0,
      page_size: 6,
    })}`
  ),
  type: 'GET_RECENT_ARTICLES',
});

export const getFeaturedArticles = () => ({
  promise: client.get(
    `articles?${querystring.stringify({
      filter: 'featured',
      filterValue: true,
      page: 0,
      page_size: 6,
    })}`
  ),
  type: 'GET_FEATURED_ARTICLES',
});

export const resetArticles = () => ({
  type: 'RESET_ARTICLES',
});
