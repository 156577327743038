import React from 'react';

function Boost(fill) {
  /* eslint-disable max-len */
  return (
    <svg
      width={fill.width ? fill.width : '19px'}
      height={fill.height ? fill.height : '19px'}
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.17821 11.9036C1.13698 12.8216 0.905597 14.9837 0.857392 15.7043C0.853133 15.7356 0.857003 15.7674 0.868703 15.7971C0.880404 15.8269 0.899623 15.8538 0.924879 15.8759C0.977388 15.9193 1.04609 15.9437 1.1177 15.9446C1.93719 15.8931 4.35708 15.6872 5.39831 14.7606C5.61837 14.5768 5.79377 14.3549 5.91368 14.1085C6.03358 13.862 6.09544 13.5963 6.09544 13.3278C6.09544 13.0592 6.03358 12.7935 5.91368 12.5471C5.79377 12.3007 5.61837 12.0787 5.39831 11.895C4.95857 11.5068 4.36885 11.2828 3.7497 11.2687C3.45317 11.2747 3.16108 11.334 2.89101 11.4431C2.62094 11.5522 2.37847 11.7089 2.17821 11.9036Z"
        fill={fill ? fill.color : '#0085F2'}
      />
      <path
        d="M12.8606 10.9769L12.6003 10.3077C16.235 8.30007 19.3491 5.78624 18.5681 0.441137C18.5527 0.334621 18.4963 0.236168 18.4083 0.162614C18.3204 0.0890601 18.2065 0.0449741 18.0861 0.0378949C12.0508 -0.365347 9.40916 2.50883 7.30742 5.83772L6.53614 5.63181C6.05419 5.52577 5.54921 5.53584 5.07309 5.661C4.59696 5.78615 4.16687 6.02186 3.82701 6.34391L2.58332 7.45069C2.45045 7.5737 2.35641 7.72574 2.3107 7.89145C2.265 8.05716 2.26924 8.23066 2.32302 8.39444C2.37262 8.54725 2.46558 8.68612 2.59296 8.79769C2.74327 8.92563 2.93604 9.0069 3.1425 9.02934L5.74558 9.44116L8.56075 11.8263L9.13921 14.0999C9.18351 14.2852 9.28733 14.4551 9.43809 14.5889C9.56611 14.7 9.72634 14.7773 9.90086 14.812C10.0877 14.852 10.2831 14.8477 10.4675 14.7995C10.6518 14.7513 10.8188 14.661 10.9517 14.5375L12.2243 13.4564C12.5712 13.1323 12.8134 12.7309 12.9254 12.2945C13.0374 11.8581 13.015 11.4029 12.8606 10.9769ZM12.6196 5.58033C12.395 5.3896 12.2388 5.14393 12.1709 4.87432C12.103 4.60471 12.1264 4.32323 12.2381 4.06543C12.3498 3.80762 12.5448 3.58503 12.7985 3.42575C13.0522 3.26646 13.3532 3.17763 13.6636 3.17046C13.974 3.16328 14.2798 3.23809 14.5424 3.38543C14.8051 3.53278 15.0128 3.74606 15.1394 3.99836C15.2659 4.25067 15.3057 4.53068 15.2536 4.80307C15.2015 5.07545 15.0599 5.32801 14.8467 5.52885C14.5583 5.79725 14.1623 5.95328 13.7451 5.96292C13.3279 5.97257 12.9233 5.83504 12.6196 5.58033Z"
        fill={fill ? fill.color : '#0085F2'}
      />
    </svg>
  );
  /* eslint-enable */
}

export default Boost;
