import React from 'react';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';

import { Button, TextInput, InputError, PlainLink } from '../../common/components';

const ForgotPasswordForm = ({ emailSent, handleSubmit, isSubmitting, status, styles }) => (
  <form id="forgot-password-form" onSubmit={handleSubmit}>
    {status && status.success ? (
      <div className={styles.wrapper}>
        <h1>Email Sent</h1>
        <p>We've sent you an email. When you get it, follow the instructions to reset your password.</p>
        <p>
          <PlainLink variant="blue" to="/sign-in">
            Go Back
          </PlainLink>
        </p>
      </div>
    ) : (
      <div className={styles.wrapper}>
        <h1>Forgot Your Password?</h1>
        <p>Don't worry. We'll help you get back on track.</p>
        <div className={styles.inputWrapper}>
          <Field labelVariant="secondary" label="Email Address" type="email" name="email" component={TextInput} />
        </div>
        <div className={styles.buttonWrapper}>
          <Button buttonType="primaryLarge" fullWidth type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </div>
        <div className={styles.cancel}>
          <PlainLink variant="red" to="/sign-in">
            Cancel
          </PlainLink>
        </div>
      </div>
    )}
    {status && status.error ? <InputError>{status.error}</InputError> : null}
  </form>
);

const FormikForgotPasswordForm = withFormik({
  mapPropsToValues: () => ({ email: '' }),
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email('Email must be a valid email address')
      .required('Email is required'),
  }),
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    setStatus({});
    const action = await props.submitAction(values);
    setSubmitting(false);
    if (!action.response.ok) {
      setStatus({
        error: action.json ? action.json.message : 'An unknown error occurred',
      });
    } else {
      setStatus({ success: true });
    }
  },
})(ForgotPasswordForm);

const styles = props => ({
  wrapper: {
    '> h1': {
      paddingTop: '30px',
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansAltBold,
      color: props.theme.colors.orange,
      fontSize: '30px',
      textAlign: 'center',
      [props.theme.breakpoints.tablet]: {
        textAlign: 'left',
      },
    },
    '> p': {
      textAlign: 'center',
      marginTop: '12px',
      fontSize: '14px',
      [props.theme.breakpoints.tablet]: {
        textAlign: 'left',
      },
    },
  },
  inputWrapper: {
    marginTop: '30px',
  },
  buttonWrapper: {
    margin: '32px 0',
  },
  cancel: {
    fontSize: '14px',
    textAlign: 'center',
  },
});

export default compose(connect(), withRouter)(felaConnect(styles)(FormikForgotPasswordForm));
