import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getListing, trackListingWebsiteClick, resetListing } from '../../redux/actions';

import { addFavoriteListing, removeFavoriteListing } from '../../../../views/Account/redux/actions';
import { accountAgeSelector } from '../../redux/selectors';
import { isLoggedInSelector } from '../../../../redux/selectors';

import ViewListing from './ViewListing';
import { setActiveModal, showAuthenticationModal } from '../../../Modal/redux/actions';
import { resetSendMessageToListingOwner } from '../../../Messages/redux/actions';

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedInSelector(state),
    user: state.user,
    userVM: state.userVM,
    seller: state.listings.currentListing.get('user'),
    loading: state.listings.getListingLoading,
    loaded: state.listings.getListingLoaded,
    listing: state.listings.currentListing,
    sellerAccountAge: accountAgeSelector(state),
    sendMessageLoaded: state.messages.sendMessageLoaded,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getListing,
      addFavoriteListing,
      removeFavoriteListing,
      trackListingWebsiteClick,
      resetListing,
      resetSendMessageToListingOwner,
      showAuthenticationModal,
      setActiveModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ViewListing);
