import React, { useState, useEffect } from 'react';
import { connect as felaConnect } from 'react-fela';
import InputError from '../InputError';

function FunctionalTextArea({ value, field, form, styles, disabled, ...passableProps }) {
  const [textAreaHeight, setTextAreaHeight] = useState(2);
  const error = form.touched[field.name] && form.errors[field.name];

  const handleKeyUp = e => {
    const val = e.target.value;
    const numberOfLineBreaks = (val.match(/\n/g) || []).length;
    if (numberOfLineBreaks <= 15) {
      setTextAreaHeight(2 + numberOfLineBreaks * 1.5);
    }
  };

  useEffect(() => {
    if (value === '' && textAreaHeight > 2) {
      setTextAreaHeight(2);
    }
  }, [value, textAreaHeight]);

  return (
    <div>
      <textarea
        onKeyUp={handleKeyUp}
        {...field}
        className={styles.expandingArea}
        disabled={disabled}
        {...passableProps}
        style={{ height: `${textAreaHeight}rem` }}
      />
      {error && <InputError disabled={disabled}>{error}</InputError>}
    </div>
  );
}

let styles = () => ({
  expandingArea: {
    backgroundColor: 'white',
    border: '.8px solid #CCC',
    borderRadius: '2px',
    font: 'inherit',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: 'rgb(73, 55, 44)',
    minHeight: '40px',
    maxWidth: '668px',
    overflow: 'auto',
    padding: '8px',
    width: '100%',
    ':focus': {
      outline: 'none !important',
      border: '.8px solid rgb(26, 185, 196)',
      boxShadow: '0 0 .8px rgb(26, 185, 196)',
    },
  },
});

export default felaConnect(styles)(FunctionalTextArea);
