import { useState, useEffect } from 'react';
import toolTipHelperFunctions from './toolTipPositionHelpers';

const useToolTipPosition = ({ currentTip }) => {
  const [arrowPosition, setArrowPosition] = useState({});
  const [dimensions, setDimensions] = useState({ left: -9999 });

  useEffect(() => {
    let rect = document.getElementById(currentTip.elementId);

    if (rect) {
      let toolTipContainer = document.getElementById(`tool-tip-${currentTip.elementId}`);
      if (toolTipContainer) {
        toolTipContainer = toolTipContainer.getBoundingClientRect();
        rect = rect.getBoundingClientRect();
        let target = toolTipHelperFunctions.currentTargetPosition(rect);

        let tipPosition = toolTipHelperFunctions.calculateTipPosition({
          rect,
          toolTipContainer,
          target,
        });
        setDimensions(tipPosition);

        let arrowPosition = toolTipHelperFunctions.calculateArrowPosition({
          rect,
          toolTipContainer,
          target,
        });
        setArrowPosition(arrowPosition);
      }
    }
  }, [currentTip]);

  return {
    dimensions,
    arrowPosition,
  };
};

export default useToolTipPosition;
