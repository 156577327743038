import React from 'react';
import { connect as felaConnect } from 'react-fela';
import { motion } from 'framer-motion';

const ToolTipVariants = {
  visible: { opacity: 1, transition: { duration: 0.3 } },
  hidden: { opacity: 0 },
};

function Tooltip({ styles, show, children }) {
  return (
    <motion.div
      className={styles.tooltip}
      style={{ display: show ? null : 'none' }}
      variants={ToolTipVariants}
      animate={show ? 'visible' : 'hidden'}
    >
      {children}
    </motion.div>
  );
}

const styles = props => ({
  tooltip: {
    width: '325px',
    position: 'absolute',
    left: '50%',
    marginLeft: '-150px',
    top: '30px',
    backgroundColor: props.theme.colors.blue,
    color: props.theme.colors.white,
    padding: '20px',
    borderRadius: '4px',
    boxShadow: '0 3px 5px rgba(0,0,0,.35)',
    zIndex: 9999,
    ':before': {
      content: "''",
      position: 'absolute',
      left: '50%',
      marginLeft: '-10px',
      top: '-10px',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 8px 10px 8px',
      borderColor: `transparent transparent ${props.theme.colors.blue} transparent`,
      zIndex: 9999,
    },
  },
});
export default felaConnect(styles)(Tooltip);
