import { createSelector } from 'reselect';
import humanizeDate from '../../../common/utils/humanizeDate';

export const premierSelector = state => state.premiers.currentPremier;

export const accountAgeSelector = createSelector(premierSelector, premier => {
  if (premier) {
    const accountAge = humanizeDate(premier.getIn(['user', 'created_at']), 'en-profiles');
    return accountAge;
  }
});

export const resultsSelector = createSelector(
  state => state.premiers.searchResults,
  results => (results ? results.toJS() : null)
);
