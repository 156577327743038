import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';

class ModalFooter extends Component {
  static propTypes = {
    actions: PropTypes.array,
    noBorder: PropTypes.bool,
  };

  render() {
    const { actions, styles } = this.props;
    return <div className={styles.root}>{actions && actions.map((action, i) => <div key={i}>{action}</div>)}</div>;
  }
}

const styles = {
  root: props => ({
    backgroundColor: '#F5F5F5',
    width: '100%',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column-reverse',
    flexWrap: 'nowrap',
    justifyContent: props.actions && props.actions.length === 1 ? 'center' : 'space-between',
    alignItems: 'center',
    borderTop: props.noBorder ? null : props.theme.globalDashedBorder,
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    '> * + *': {
      marginBottom: '16px',
    },
    [props.theme.breakpoints.mobile]: {
      padding: '20px 40px',
      flexDirection: 'row',
      '> * + *': {
        marginBottom: 0,
        marginLeft: '16px',
      },
    },
  }),
};

export default connect(styles)(ModalFooter);
