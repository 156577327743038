const formatPhoneNumber = value => {
  if (!value) {
    return '';
  }

  let string = value.replace(/[^0-9]/g, '').split('');

  if (string.length <= 10) {
    if (string.length > 3 && string[3] !== '-') {
      string.splice(3, 0, '-');
    }

    if (string.length > 7 && string[7] !== '-') {
      string.splice(7, 0, '-');
    }
  } else {
    string.splice(15);
    string.splice(string.length - 4, 0, '-');
    string.splice(string.length - 8, 0, '-');
    string.splice(string.length - 12, 0, '-');
  }
  return string.join('');
};

export default formatPhoneNumber;
