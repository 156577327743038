import React from 'react';

function Stats() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15" viewBox="0 0 24 15">
      <path
        d="M23.4434 5.92152C20.8034 3.01152 16.4434 -0.078479 12.0134 0.00152099C7.58342 0.081521 3.22342 3.01152 0.583418 5.92152C0.208165 6.33094 0 6.86615 0 7.42152C0 7.9769 0.208165 8.5121 0.583418 8.92152C2.34342 10.8215 6.75342 14.8015 12.0134 14.8015C17.2734 14.8015 21.6734 10.8015 23.4434 8.87152C23.8047 8.46513 24.0043 7.94028 24.0043 7.39652C24.0043 6.85276 23.8047 6.32791 23.4434 5.92152ZM7.41342 7.40152C7.41342 6.49173 7.6832 5.60236 8.18866 4.8459C8.69411 4.08943 9.41253 3.49984 10.2531 3.15168C11.0936 2.80351 12.0185 2.71242 12.9108 2.88991C13.8031 3.0674 14.6228 3.50551 15.2661 4.14883C15.9094 4.79215 16.3475 5.61179 16.525 6.50411C16.7025 7.39642 16.6114 8.32132 16.2633 9.16186C15.9151 10.0024 15.3255 10.7208 14.569 11.2263C13.8126 11.7317 12.9232 12.0015 12.0134 12.0015C10.7934 12.0015 9.62339 11.5169 8.76073 10.6542C7.89806 9.79154 7.41342 8.62152 7.41342 7.40152Z"
        fill="#49372C"
      />
      <path
        d="M12.0134 9.40149C13.118 9.40149 14.0134 8.50606 14.0134 7.40149C14.0134 6.29692 13.118 5.40149 12.0134 5.40149C10.9089 5.40149 10.0134 6.29692 10.0134 7.40149C10.0134 8.50606 10.9089 9.40149 12.0134 9.40149Z"
        fill="#49372C"
      />
    </svg>
  );
  /* eslint-enable */
}

export default Stats;
