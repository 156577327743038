import * as yup from 'yup';
import { isValidPhoneNumber } from '../../utils/format-phone';

const phoneNumberSchema = yup
  .string()
  .test('phone-number-length', 'Number must not include country code', function(value) {
    return value ? value.replace(/[^0-9]/g, '').length < 11 : true;
  })
  .test('select-country-code', 'Please select a country code', function(value) {
    const countryCode = this.parent.phone_country_code;
    return value && countryCode ? countryCode.charAt(0) === '+' : true;
  })
  .test('is-valid-phone-number', 'Must be a valid phone number', function(value) {
    return isValidPhoneNumber(value ? this.parent.phone_country_code + value : '');
  });

export default phoneNumberSchema;
