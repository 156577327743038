import { Map } from 'immutable';

const getPriceForPlan = (plan, userVM) => {
  if (!Map.isMap(plan) || !Map.isMap(userVM)) {
    return 0;
  }

  const planPrices = {
    ninety_day: userVM.get('ninetyDayPrice'),
    premier: userVM.get('premierPrice'),
    premier_yearly: userVM.get('premierYearlyPrice'),
    thirty_day: userVM.get('thirtyDayPrice'),
  };

  if (planPrices.hasOwnProperty(plan.get('id'))) {
    return planPrices[plan.get('id')];
  }

  return plan.get('price') || 0;
};

export default getPriceForPlan;
