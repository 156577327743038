import React from 'react';
import { ButtonLink } from '../../../../../common/components';

const ViewFeedbackButton = ({ text, tab, location }) => {
  return (
    <ButtonLink
      buttonType="round"
      to={{
        pathname: `/feedback-manager/${tab}`,
        state: {
          prevLocation: location,
          backTo: 'Messages',
        },
      }}
    >
      {text}
    </ButtonLink>
  );
};

export default ViewFeedbackButton;
