import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import { Autocomplete, Button, Dropdown, GeoComplete } from '../../../common/components';

import { DISTANCE_OPTIONS, SEARCH_BY_OPTIONS, STATE_OPTIONS, BREED_OPTIONS } from '../common/search-config';

import { FieldContainer, FormContainer, FormRow, SearchContainer } from './styled';

function SearchListingsForm({
  breeds = [],
  handleBreedSelect = () => {},
  handleBreedPlaceholder = () => {},
  handleInputChange = () => {},
  handleSubmit,
  isSubmitting,
  setGeoCompleteTouched = () => {},
  values = {},
}) {
  return (
    <SearchContainer>
      <form
        id="search-listings-form"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <FormContainer>
          <FormRow>
            <FieldContainer flex="1.5">
              <Field
                component={Autocomplete}
                initialValue={
                  Array.isArray(values.formatted_breed) ? values.formatted_breed[0] : values.formatted_breed
                }
                insetLabel
                items={breeds}
                key={Array.isArray(values.breed) ? values.breed.join(',') : values.breed}
                label="Breed"
                name="breed"
                placeholder={handleBreedPlaceholder}
                onInputChange={handleInputChange}
                onSelect={value => handleBreedSelect(value)}
              />
            </FieldContainer>
            <FieldContainer minWidth="160px">
              <Field
                component={Dropdown}
                id="search_by"
                name="search_by"
                options={SEARCH_BY_OPTIONS}
                variant="secondary"
                placeholder="All states"
              />
            </FieldContainer>
            {values.search_by !== 'everywhere' && (
              <FieldContainer minWidth="160px">
                {values.search_by === 'state' && (
                  <Field
                    component={Dropdown}
                    initialValue={values.state?.toUpperCase()}
                    name="state"
                    placeholder="All states"
                    options={STATE_OPTIONS}
                    variant="secondary"
                  />
                )}
                {values.search_by === 'city' && (
                  <Field
                    component={GeoComplete}
                    initialValue={values.formatted_location}
                    insetLabel
                    label="Location"
                    name="location"
                    placeholder="Type a city"
                    onBlur={setGeoCompleteTouched}
                  />
                )}
              </FieldContainer>
            )}
            {values.search_by === 'city' && (
              <FieldContainer minWidth="100px">
                <Field
                  component={Dropdown}
                  disabled={!values.city}
                  id="distance"
                  name="distance"
                  options={DISTANCE_OPTIONS}
                  variant="secondary"
                />
              </FieldContainer>
            )}

            <FieldContainer minWidth="160px">
              <Field component={Dropdown} name="sort_breed" options={BREED_OPTIONS} variant="secondary" />
            </FieldContainer>
            <FieldContainer marginTop="0">
              <FormRow>
                <Button disabled={isSubmitting} data-testid="search-btn" padding="14px;">
                  FIND PUPPIES
                </Button>
              </FormRow>
            </FieldContainer>
          </FormRow>
        </FormContainer>
      </form>
    </SearchContainer>
  );
}

SearchListingsForm.propTypes = {
  breeds: PropTypes.arrayOf(PropTypes.object),
  handleBreedSelect: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  setGeoCompleteTouched: PropTypes.func,
  values: PropTypes.shape({
    breed: PropTypes.array,
    city: PropTypes.string,
    distance: PropTypes.number,
    formatted_breed: PropTypes.array,
    formatted_location: PropTypes.string,
    search_by: PropTypes.string,
    state: PropTypes.string,
  }),
};

export default SearchListingsForm;
