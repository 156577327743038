import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StandaloneField extends Component {
  static propTypes = {
    component: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    error: PropTypes.any,
    touched: PropTypes.any,
  };

  noop = () => {};

  render() {
    const { component: Component, onChange, onBlur, name, value, error, touched, ...restProps } = this.props;

    return (
      <Component
        field={{
          name,
          value,
          onChange: onChange || this.noop,
          onBlur: onBlur || this.noop,
        }}
        form={{
          touched: {
            [name]: touched,
          },
          errors: {
            [name]: error,
          },
          setFieldValue: (_, value) => onChange(value),
        }}
        {...restProps}
      />
    );
  }
}

export default StandaloneField;
