import React from 'react';
import { connect } from 'react-fela';
import classNames from 'classnames';

const ModalPaymentHeader = ({ title, subTitle, styles, planId }) => {
  const rootClasses = {
    [styles.root]: true,
    [styles.thirtyDay]: planId === 'thirty_day',
    [styles.ninetyDay]: planId === 'ninety_day',
  };

  return (
    <div className={classNames(rootClasses)}>
      <h1 className={styles.modalTitle}>{title}</h1>
      {subTitle && <p className={styles.modalSubtitle}>{subTitle}</p>}
    </div>
  );
};

const styles = {
  root: () => ({
    width: '100%',
    padding: '36px 40px 0',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    textAlign: 'center',
    borderTopWidth: `10px`,
    borderTopStyle: 'solid',
    borderTopColor: '#FF9732',
  }),
  thirtyDay: props => ({
    borderTopColor: props.theme.colors.blue,
  }),
  ninetyDay: props => ({
    borderTopColor: props.theme.colors.green,
  }),
  modalTitle: props => ({
    fontWeight: props.theme.typography.sansBold,
    fontSize: '28px',
    lineHeight: '30px',
    color: props.theme.colors.blue,
  }),
  modalSubtitle: props => ({
    fontSize: '14px',
    lineHeight: '1.25',
    marginTop: '24px',
  }),
};

export default connect(styles)(ModalPaymentHeader);
