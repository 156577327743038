import React, { Component } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import ArrowDown from '../icons/ArrowDown';
import theme from '../../../theme';

import { InputScaffold } from '../';

class Dropdown extends Component {
  static propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary']),
    options: PropTypes.array.isRequired,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
      setFieldValue: PropTypes.func.isRequired,
    }),
    label: PropTypes.node,
    labelVariant: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
  };

  static defaultProps = {
    variant: 'primary',
  };

  handleChange = selected => {
    const {
      form: { setFieldValue },
      field: { name },
    } = this.props;

    setFieldValue(name, selected.value);
  };

  render() {
    const {
      field,
      form,
      styles,
      disabled,
      label,
      labelVariant,
      variant,
      placeholder,
      required,
      fixederror,
      ...passableProps
    } = this.props;
    const error = form.touched[field.name] && form.errors[field.name];

    const selectedOption = passableProps.options.find(option => field.value === option.value) || '';

    const DropdownIndicator = props => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <ArrowDown />
          </components.DropdownIndicator>
        )
      );
    };

    const customStyles = {
      control: (base, state) => ({
        background: state.isFocused
          ? `${variant === 'primary' ? theme.colors.darkerBlueHover : theme.colors.white}`
          : `${variant === 'primary' ? theme.colors.darkerBlue : theme.colors.white}`,
        borderRadius: '2px',
        border: state.isFocused ? `1px solid ${theme.colors.blue}` : '1px solid transparent',
        boxShadow: variant === 'primary' ? '0 2px 0 0 #167178' : '0 2px 0 0 #9B9B9B',
        display: 'flex',
        opacity: state.isDisabled ? '0.66' : '1.0',
        padding: variant === 'primary' ? '0 28px 0 16px' : '0 10px',
        height: variant === 'primary' ? '31px' : '40px',
        position: 'relative',
        fontSize: variant === 'primary' ? '12px' : '11px',
        transition: 'all 100ms ease-in-out',
        '> .react-select__indicators > .react-select__indicator': {
          fill: variant === 'primary' ? theme.colors.white : theme.colors.textDark,
        },
        ':hover': {
          backgroundColor: variant === 'primary' ? theme.colors.darkerBlueHover : null,
          '> .react-select__value-container > .react-select__single-value': {
            color: variant === 'primary' ? theme.colors.white : theme.colors.blue,
          },
          '> .react-select__value-container > .react-select__placeholder': {
            color: variant === 'primary' ? theme.colors.white : theme.colors.blue,
          },
          '> .react-select__indicators > .react-select__indicator': {
            fill: variant === 'primary' ? theme.colors.white : theme.colors.blue,
          },
        },
        cursor: 'pointer',
      }),
      container: (base, state) => ({
        ...base,
        input: {
          textIndent: '-9999999em',
        },
      }),
      placeholder: () => ({
        color: variant === 'primary' ? theme.colors.white : theme.colors.textDark,
        transition: 'all 100ms ease-in-out',
        textTransform: 'none',
      }),
      singleValue: (provided, state) => ({
        color: state.isDisabled
          ? theme.colors.disabledText
          : variant === 'primary'
          ? theme.colors.white
          : theme.colors.textDark,
        textTransform: 'none',
        transition: 'all 100ms ease-in-out',
        whiteSpace: 'nowrap',
      }),
      valueContainer: base => ({
        ...base,
        padding: 0,
        flexWrap: 'nowrap',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: base => ({
        ...base,
        fill: 'currentColor',
        position: 'absolute',
        padding: variant === 'primary' ? '4px 8px' : '8px',
        right: '-5px',
        top: variant === 'primary' ? -2 : 0,
        transition: 'all 100ms ease-in-out',
      }),
      // Select Menu
      menu: base => ({
        ...base,
        margin: 0,
        borderRadius: '0 0 3px 3px',
        background: variant === 'primary' ? theme.colors.darkerBlueHover : theme.colors.white,
        border: 'none',
        boxShadow: variant === 'primary' ? '0 2px 4px rgba(0,0,0, .5)' : '0 2px 4px 0 #9B9B9B',
        zIndex: 9999,
      }),
      option: (base, state) => ({
        ...base,
        textAlign: 'left',
        color: state.isFocused ? theme.colors.white : variant === 'primary' ? theme.colors.white : theme.colors.blue,
        textTransform: 'none',
        background: state.isFocused ? theme.colors.blue : 'transparent',
        cursor: 'pointer',
      }),
    };

    return (
      <InputScaffold
        fixederror={fixederror}
        label={label}
        labelVariant={labelVariant}
        error={error}
        disabled={disabled}
        required={required}
      >
        <Select
          classNamePrefix="react-select"
          styles={customStyles}
          isDisabled={disabled}
          inputId={field.name}
          onBlur={field.onBlur}
          onChange={this.handleChange}
          value={selectedOption}
          {...passableProps}
          isSearchable={false}
          components={{ DropdownIndicator }}
          placeholder={placeholder}
        />
      </InputScaffold>
    );
  }
}

export default Dropdown;
