import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import { Icon } from '../../../../common/components/';
import PropTypes from 'prop-types';
import theme from '../../../../theme';

class Stats extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    views: PropTypes.number,
    clicks: PropTypes.number,
    inquiries: PropTypes.number,
    isPremier: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  };

  static defaultProps = {
    views: 0,
    clicks: 0,
    inquiries: 0,
    isPremier: false,
  };

  handleDomClick = e => {
    const { isOpen, onClose, currentId } = this.props;
    if (e.target.id !== currentId && !this.ref.contains(e.target) && isOpen) {
      onClose();
    }
  };

  setRef = node => {
    if (node) {
      this.ref = node;
      document.addEventListener('mousedown', this.handleDomClick);
    }
  };

  render() {
    const { isPremier, isOpen, views, clicks, inquiries, onClose, styles } = this.props;
    if (isOpen) {
      return (
        <div ref={this.setRef} className={styles.root}>
          <div className={styles.innerWrapper}>
            <span>
              Total listing views: <strong>{views}</strong>
            </span>
            {isPremier ? (
              <span>
                Clicks to website: <strong>{clicks}</strong>
              </span>
            ) : null}
            <span>
              Inquiries: <strong>{inquiries}</strong>
            </span>
            <button className={styles.closeButton} onClick={onClose}>
              <Icon icon="Close" fill={theme.colors.white} size={16} />
            </button>
          </div>
        </div>
      );
    }
    return null;
  }
}

const styles = {
  root: props => ({
    left: 0,
    right: 0,
    width: '100%',
    marginTop: '53px',
    zIndex: 1,
    position: 'absolute',
    backgroundColor: props.theme.colors.blue,
    boxShadow: '0 1px 3px 0 rgba(0,0,0,0.25)',
  }),
  innerWrapper: props => ({
    position: 'relative',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: props.theme.typography.sansRegular,
    color: props.theme.colors.white,
    '> * + *': {
      marginLeft: '78px',
    },
  }),
  closeButton: props => ({
    backgroundColor: props.theme.colors.blue,
    position: 'absolute',
    right: '18px',
    top: '18px',
  }),
};

export default felaConnect(styles)(Stats);
