import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContactModal from '../ContactModal';
import CircleAvatar from '../CircleAvatar';
import ContentCapitalized from '../ContentCapitalized';
import generateInitials from '../../utils/generateInitials';
import { connect as felaConnect } from 'react-fela';
import { Map } from 'immutable';

class ContactSellerModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    listing: PropTypes.instanceOf(Map),
    requestVerification: PropTypes.bool,
  };

  renderRecipientInfo() {
    const { listing, styles } = this.props;
    const user = listing.get('user');
    return (
      <div className={styles.recipientInfo}>
        <div className={styles.avatar}>
          <CircleAvatar
            size={50}
            photo={listing.getIn(['photos', 0])}
            placeholderIcon="PuppyCircle"
            alt={'message-recipient'}
          />
          <div className={styles.avatarInfo}>
            <span>
              <strong>{listing.get('name')}</strong>
            </span>
            <span className={styles.listingPrice}>
              <strong>${listing.get('price').toLocaleString()}</strong>
            </span>
          </div>
        </div>
        <div className={styles.avatar}>
          <CircleAvatar
            size={50}
            photo={user.getIn(['photo'])}
            initials={generateInitials(user.get('last_initial'), user.get('first_name'))}
            alt={'message-recipient'}
            verified={user.get('id_verified')}
          />
          <div className={styles.avatarInfo}>
            <span>Sold by</span>
            <span>
              <strong>
                <ContentCapitalized>
                  {user.get('first_name')} {user.get('last_initial')}
                </ContentCapitalized>
              </strong>
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { listing, isOpen, onClose, sendMessageToListingOwner, requestVerification } = this.props;
    return (
      <ContactModal
        sendMessage={sendMessageToListingOwner.bind(this, listing.get('id'))}
        isOpen={isOpen}
        onClose={onClose}
        recipientInfo={this.renderRecipientInfo()}
        recipientName={listing.getIn(['user', 'first_name'])}
        requestVerification={requestVerification}
        backTo="Listing"
      />
    );
  }
}

const styles = props => ({
  recipientInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '40px',
    fontSize: '14px',
    lineHeight: '19px',
    textAlign: 'left',
    '> * + *': {
      marginTop: '32px',
    },
    [props.theme.breakpoints.mobileSmall]: {
      flexDirection: 'row',
      alignItems: 'center',
      '> * + *': {
        marginTop: 0,
        borderLeft: props.theme.globalDashedBorder,
        paddingLeft: '10px',
      },
    },
    [props.theme.breakpoints.tablet]: {
      '> * + *': {
        paddingLeft: '40px',
      },
    },
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '16px',
    },
    [props.theme.breakpoints.mobileSmall]: {
      width: '50%',
      ':first-of-type': {
        paddingRight: '10px',
      },
    },
    [props.theme.breakpoints.tablet]: {
      ':first-of-type': {
        paddingLeft: '40px',
      },
    },
  },
  avatarInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
    alignItems: 'flex-start',
    overflow: 'hidden',
    '> span': {
      width: '75%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [props.theme.breakpoints.mobileSmall]: {
        width: '70%',
      },
    },
  },
  listingPrice: {
    color: props.theme.colors.green,
  },
});

export default felaConnect(styles)(ContactSellerModal);
