import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import PropTypes from 'prop-types';
import { Chip, InputScaffold } from '../';
import Slider from 'rc-slider';

class RangeSlider extends Component {
  static propTypes = {
    minValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    pluralSuffix: PropTypes.string,
    maxSuffix: PropTypes.string,
    maxOverwrite: PropTypes.string,
    minOverwrite: PropTypes.string,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
      setFieldValue: PropTypes.func.isRequired,
    }),
    label: PropTypes.node,
    labelVariant: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    prefix: '',
    suffix: '',
  };

  getPluralizedSuffix = value => {
    const { suffix, pluralSuffix } = this.props;

    return pluralSuffix && value !== 1 ? pluralSuffix : suffix;
  };

  formatLabel = value => {
    const { maxSuffix, pluralSuffix, prefix, maxValue, maxOverwrite, minOverwrite } = this.props;
    if (value === maxValue) {
      if (maxOverwrite) {
        return maxOverwrite;
      }
      if (maxSuffix) {
        return `${prefix}${value.toLocaleString()}${this.getPluralizedSuffix(value)}${maxSuffix}`;
      }
      if (pluralSuffix) {
        return `${prefix}${value.toLocaleString()}${this.getPluralizedSuffix(value)}`;
      }
    } else {
      if (minOverwrite) {
        return minOverwrite;
      }
      return `${prefix}${value.toLocaleString()}${this.getPluralizedSuffix(value)}`;
    }
  };

  handleChange = value => {
    const {
      form: { setFieldValue },
      field: { name },
      minValue,
      maxValue,
    } = this.props;

    if (
      Object.prototype.toString.call(value) === '[object Array]' &&
      value[0] >= minValue &&
      value[1] <= maxValue &&
      value[0] !== value[1]
    ) {
      setFieldValue(name, { min: value[0], max: value[1] });
    } else if (value >= minValue && value <= maxValue) {
      setFieldValue(name, value);
    }
  };

  renderChip = () => {
    const {
      maxSuffix,
      maxValue,
      prefix,
      field: { value },
    } = this.props;

    const suffix = maxSuffix && value.max === maxValue ? maxSuffix : '';

    return (
      <Chip>{`${prefix}${value.min.toLocaleString()} - ${prefix}${value.max.toLocaleString()}${this.getPluralizedSuffix(
        value.max
      )}${suffix}`}</Chip>
    );
  };

  formatValues = value => {
    if (value.hasOwnProperty('min') && value.hasOwnProperty('max')) {
      return [value.min, value.max];
    }

    return value;
  };

  render() {
    const {
      styles,
      prefix,
      suffix,
      pluralSuffix,
      field,
      form,
      label,
      labelVariant,
      disabled,
      hideChip,
      showTicks,
      minValue,
      maxValue,
      ...passableProps
    } = this.props;
    const error = form.touched[field.name] && form.errors[field.name];

    const labelStyle = {
      fontFamily: 'inherit',
      fontWeight: 'bold',
      color: '#49372c',
      bottom: '24px',
      fontSize: '11px',
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      userSelect: 'none',
    };

    const marks = {
      [minValue]: {
        style: { ...labelStyle, left: '10%' },
        label: this.formatLabel(minValue),
      },
      [maxValue]: {
        style: { ...labelStyle, left: '95%' },
        label: this.formatLabel(maxValue),
      },
    };

    return (
      <InputScaffold label={label} labelVariant={labelVariant} error={error} disabled={disabled}>
        <div className={styles.root}>
          {hideChip ? null : this.renderChip()}
          <Slider
            id={field.name}
            range={typeof field.value === 'object' ? true : false}
            name={field.name}
            min={minValue}
            max={maxValue}
            marks={marks}
            defaultValue={this.formatValues(field.value)}
            value={this.formatValues(field.value)}
            disabled={disabled}
            allowCross={false}
            onBlur={field.onBlur}
            onChange={this.handleChange}
            railStyle={{
              height: '10px',
              background: '#cac1b6',
              cursor: 'pointer',
            }}
            trackStyle={[
              {
                height: '10px',
                background: '#1ab9c4',
                cursor: 'pointer',
              },
            ]}
            handleStyle={[
              {
                background: '#7b6b6b',
                color: '#7b6b6b',
                borderColor: '#7b6b6b',
                height: '20px',
                width: '20px',
                opacity: 1,
              },
              {
                background: '#7b6b6b',
                color: '#7b6b6b',
                borderColor: '#7b6b6b',
                height: '20px',
                width: '20px',
                opacity: 1,
              },
            ]}
            dots={showTicks}
            dotStyle={{
              height: '10px',
              width: '1px',
              backgroundColor: '#979797',
              top: 0,
              border: 'none',
            }}
            activeDotStyle={{ display: 'none' }}
            {...passableProps}
          />
        </div>
      </InputScaffold>
    );
  }
}

const styles = {
  root: props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  ticks: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tick: {
    height: '10px',
    width: '1px',
    backgroundColor: '#979797',
    position: 'relative',
    top: '-10px',
    ':first-of-type': {
      opacity: 0,
    },
    ':last-of-type': {
      opacity: 0,
    },
  },
};

export default felaConnect(styles)(RangeSlider);
