import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import {
  TestimonialList,
  TestimonialModal,
  TestimonialModalSeller,
  ConfirmationModal,
  SubNav,
  TabGroup,
  ReportModal,
} from '../../common/components';
import queryString from 'query-string';
import testimonialReportReasons from '../../common/utils/testimonialReportReasons';
import { TESTIMONIAL, TESTIMONIAL_REPLY, TESTIMONIAL_LEGACY_ORPHAN } from '../../common/constants/report-types';
import PopupToolTip from '../../common/components/PopupTip';
import { Helmet } from 'react-helmet';
class FeedbackManager extends Component {
  state = {
    editing: null,
    replying: null,
    confirmingDelete: null,
    reporting: null,
    isReply: null,
    type: null,
  };

  renderTestimonialModal() {
    if (!this.state.editing && !this.state.replying) {
      return null;
    }

    const testimonial = this.state.editing || this.state.replying;

    if (this.state.type === 'seller') {
      return (
        <TestimonialModalSeller
          isOpen={true}
          seller={testimonial.get('seller')}
          user={testimonial.get('buyer')}
          onClose={() => this.setState({ editing: null, replying: null, type: null, testimonialId: null })}
          isReply={!!this.state.replying}
          testimonialId={testimonial.get('id')}
        />
      );
    } else {
      return (
        <TestimonialModal
          isOpen={true}
          onClose={() => this.setState({ editing: null, replying: null })}
          listing={testimonial.get('listing')}
          seller={testimonial.get('seller')}
          buyer={testimonial.get('buyer')}
          testimonial={testimonial}
          isReply={!!this.state.replying}
        />
      );
    }
  }

  renderDeleteConfirmation() {
    const testimonialId = this.state.confirmingDelete;
    if (!!testimonialId) {
      return (
        <ConfirmationModal
          title="Are you sure?"
          subTitle="Delete this reply?"
          onConfirm={() => this.getReplyType({ type: this.state.type, testimonialId })}
          onClose={() => this.setState({ confirmingDelete: null })}
          isOpen={!!this.state.confirmingDelete}
        />
      );
    }
  }

  getReplyType({ type, testimonialId }) {
    const { replyToTestimonial } = this.props;
    // if (type === 'seller') {
    //   return replyToTestimonial(testimonialId, null);
    // }
    // if (['legacy', 'legacy_orphan'].includes(type)) {
    //   return replyToLegacyTestimonial(testimonialId, null);
    // }
    return replyToTestimonial(testimonialId, null);
  }

  renderReportModal() {
    if (!this.state.reporting) {
      return null;
    }
    const testimonial = this.state.reporting;
    const type = this.state.type;
    const [user, reportType, buttonText, photo] = this.getTestimonialReportInfo({ type, testimonial });
    return (
      <ReportModal
        isOpen={!!this.state.reporting}
        onClose={() => this.setState({ reporting: null, isReply: null })}
        user={user}
        reasons={testimonialReportReasons}
        type={reportType}
        entity_id={testimonial.get('id')}
        buttonText={buttonText}
        photo={photo}
      />
    );
  }

  getTestimonialReportInfo({ type, testimonial }) {
    let user = testimonial.get('buyer');
    let reportType = TESTIMONIAL;
    let buttonText = 'Report Testimonial';
    let photo = testimonial.getIn(['buyer', 'photo']);

    if (type === 'reply') {
      user = testimonial.get('seller');
      reportType = TESTIMONIAL_REPLY;
      buttonText = 'Report Reply';
      photo = testimonial.getIn(['seller', 'photo']);
    }

    if (type === 'legacy_orphan') {
      if (!user) {
        user = testimonial.getIn(['listing', 'user']);
      }

      reportType = TESTIMONIAL_LEGACY_ORPHAN;
      photo = undefined;
    }

    return [user, reportType, buttonText, photo];
  }

  render() {
    const { location, user, styles, match } = this.props;
    const include = match.params.tab === 'left' ? 'by' : 'for';
    const query = queryString.parse(location.search);
    const viewerId = user && user.get('id') ? user.get('id') : null;
    const linkArray = [
      { route: '/feedback-manager/left', text: 'Testimonials Left', id: 'feedback-left-tip' },
      { route: '/feedback-manager/received', text: 'Testimonials Received', id: 'feedback-received-tip' },
    ];
    return (
      <div className={styles.root}>
        <Helmet>
          <title>My Testimonials - Puppies.com</title>
        </Helmet>
        <SubNav titleText={'My Testimonials'} headerRightChildren={<TabGroup linkArray={linkArray} />} />
        <PopupToolTip popupName="feedbackReceivedViewed" tipsFor="feedbackManager" migratedUserOnly={true} />
        <div className={styles.container}>
          <TestimonialList
            noHeader
            showFull
            tab={match.params.tab}
            include={include}
            userId={user.get('id')}
            viewerId={viewerId}
            page={query.page || 0}
            showHidden={true}
            pageSize={query.page_size || 16}
            onClickEdit={testimonial => this.setState({ editing: testimonial })}
            onClickReply={testimonial => this.setState({ replying: testimonial })}
            onClickDeleteReply={(testimonialId, type) => this.setState({ confirmingDelete: testimonialId, type })}
            onClickReport={(testimonial, type) => this.setState({ reporting: testimonial, type })}
          />
          {this.renderTestimonialModal()}
          {this.renderDeleteConfirmation()}
          {this.renderReportModal()}
        </div>
      </div>
    );
  }
}

const styles = props => ({
  root: {
    ...props.theme.white,
  },
  container: {
    ...props.theme.globalPageWrapper,
    '> h1': {
      color: props.theme.colors.orange,
    },
  },
});

export default felaConnect(styles)(FeedbackManager);
