import ContactListingOwnerModal from './ContactListingOwnerModal';
import { connect } from 'react-redux';
import { sendMessageToListingOwner } from '../../../views/Messages/redux/actions';
import { bindActionCreators } from 'redux';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({ sendMessageToListingOwner }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactListingOwnerModal);
