import config from '../config';

export function getThumbnail(photo) {
  if (typeof photo === 'string') {
    return photo;
  }
  const base = `${config.s3UrlBase}${photo.get('key')}`;
  if (photo.get('is_processed') === true) {
    return `${base}-thumb`;
  } else if (photo.get('has_crop') === true) {
    return `${base}-crop`;
  } else {
    return `${base}`;
  }
}

export function getMediumSize(photo, opts = {}) {
  if (typeof photo === 'string') {
    return photo;
  }
  if (photo.get('has_medium') === true) {
    return `${config.s3UrlBase}${photo.get('key')}-med-full`;
  } else {
    return getFullSize(photo, opts);
  }
}

export function getFullSize(photo) {
  if (typeof photo === 'string') {
    return photo;
  }
  const base = `${config.s3UrlBase}${photo.get('key')}`;
  if (photo.get('has_crop') === true && photo.get('is_processed') === true) {
    return `${base}-crop-opt`;
  } else if (photo.get('is_processed') === true) {
    return `${base}-opt`;
  } else {
    return `${base}`;
  }
}
