import PremierUpdateModal from './PremierUpdateModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createPremierUpdate } from '../../../views/Premiers/redux/actions';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createPremierUpdate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PremierUpdateModal);
