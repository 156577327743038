import React from 'react';

function Close() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M23.853 19.002c.094.12.147.254.147.414 0 .16-.053.281-.147.401l-4.036 4.036a.617.617 0 0 1-.4.147.653.653 0 0 1-.415-.147L12 16.851l-7.002 7.002a.653.653 0 0 1-.414.147.617.617 0 0 1-.401-.147L.147 19.817a.617.617 0 0 1-.147-.4c0-.16.053-.295.147-.415L7.149 12 .147 4.998A.653.653 0 0 1 0 4.584c0-.16.053-.281.147-.401L4.183.147a.617.617 0 0 1 .4-.147c.16 0 .295.053.415.147L12 7.149 19.002.147A.653.653 0 0 1 19.416 0c.16 0 .281.053.401.147l4.036 4.036c.094.12.147.24.147.4 0 .16-.053.295-.147.415L16.851 12l7.002 7.002z" />
    </svg>
  );
  /* eslint-enable */
}

export default Close;
