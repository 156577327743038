import React, { useState, useEffect } from 'react';
import tipsFromJson from '../../../tool_tips';
import useToolTipPosition from './useToolTipPosition';
import debounce from 'lodash/debounce';
import client from '../../utils/client';
import ToolTipBody from './ToolTipBody';
import PropTypes from 'prop-types';

const PopupToolTip = function({ popupName, tipsFor, styles }) {
  const [done, setDone] = useState(false);

  const tipsJson = tipsFromJson[tipsFor].tips;
  const [currentTipIndex, setCurrentTipIndex] = useState(0);
  const [currentTip, setCurrentTip] = useState(tipsJson[0]);

  const { arrowPosition, dimensions } = useToolTipPosition({ currentTip });

  useEffect(() => {
    const listener = debounce(() => {
      let tips = document.querySelectorAll('[data-tip]');
      tips.forEach(e => {
        let toolTipContainer = document.getElementById(e.id);
        if (toolTipContainer) {
          let tipNumber = toolTipContainer.getAttribute('data-tip');
          let num = parseInt(tipNumber);
          setCurrentTipIndex(num);
        }
      });
    }, 750);

    window.addEventListener('resize', listener);
    setCurrentTipIndex(0);
    return _ => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  // updates currentTipIndex state, renders tool tip with new content and location.
  useEffect(() => {
    if (currentTipIndex < tipsJson.length) {
      setCurrentTip(tipsJson[currentTipIndex]);
    }
  }, [currentTipIndex, tipsJson]);

  // sends viewed status to api
  useEffect(() => {
    if (done) {
      client.post('popup-viewed', {
        popup_name: popupName,
      });
    }
  }, [done, popupName]);

  return done ? (
    ''
  ) : (
    <ToolTipBody
      currentTip={currentTip}
      currentTipIndex={currentTipIndex}
      tipsJson={tipsJson}
      dimensions={dimensions}
      arrowPosition={arrowPosition}
      styles={styles}
      setDone={setDone}
      setCurrentTipIndex={setCurrentTipIndex}
    />
  );
};

PopupToolTip.propTypes = {
  popupName: PropTypes.string.isRequired,
  tipsFor: PropTypes.string.isRequired,
  migratedUserOnly: PropTypes.bool,
};

export default PopupToolTip;
