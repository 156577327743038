import React, { Component } from 'react';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import { Link, withRouter } from 'react-router-dom';
import { connect as felaConnect } from 'react-fela';
import compose from 'lodash/flowRight';
import theme from '../../../../theme';
import { handleFileUpload } from '../../../../common/utils/uploads/handleFileUpload';
import {
  Button,
  GeoComplete,
  InputError,
  TextInput,
  Icon,
  FileUploader,
  CircleAvatar,
  Modal,
  ModalFooter,
  ModalBody,
  ButtonPlain,
} from '../../../../common/components';

class PuppyFindMigration extends Component {
  state = {
    profilePhoto: '',
    showLocationConfirmationModal: false,
    showMigrationWelcomeModal: true,
    showMigrationInfoModal: false,
  };

  componentDidMount() {
    const { user } = this.props;

    if (!user.get('historicalUserRequirements') || !user.getIn(['historicalUserRequirements', 'needs_user_profile'])) {
      this.props.history.push('/');
    }
  }

  componentDidUpdate(prevProps) {
    const { status, user, history } = this.props;

    if ((!prevProps.status || !prevProps.status.success) && status && status.success) {
      if (user.getIn(['historicalUserRequirements', 'needs_premier_profile'])) {
        history.push(`/account/puppyfind-premier`);
      } else {
        history.push(`/account/puppyfind-complete`);
      }
    }
  }

  handlePhotoChange = ([{ data }]) => this.props.setFieldValue('photo', data);

  handleDelete = isSaved => {
    const { user } = this.props;
    if (isSaved) {
      this.props.setFieldValue('photoToDelete', user.getIn(['photo', 'key']));
    }
    this.props.setFieldValue('existingPhoto', null);
    this.props.setFieldValue('photo', null);
    this.props.setFieldValue('croppedPhoto', null);
  };

  handleCrop = image => {
    this.props.setFieldValue('croppedPhoto', image);
  };

  renderConfirmLocationChangeModal = submitForm => {
    const { styles } = this.props;
    return (
      <Modal
        closeOnOutsideClick
        isOpen={this.state.showLocationConfirmationModal}
        onClose={() => this.setState({ showLocationConfirmationModal: false })}
      >
        <ModalBody>
          <div className={styles.modalBody}>
            <Icon icon="Warning" size={40} />
            <h1>Sure about that?</h1>
            <p>Changing the location on your profile will update the location on all of your active listings</p>
          </div>
        </ModalBody>
        <ModalFooter
          actions={[
            <ButtonPlain onClick={() => this.setState({ showLocationConfirmationModal: false })}>
              Never mind
            </ButtonPlain>,
            <Button
              buttonType="round"
              onClick={() => {
                this.setState({ locationChangeConfirmed: true, showLocationConfirmationModal: false });
                submitForm();
              }}
            >
              CONFIRM
            </Button>,
          ]}
        />
      </Modal>
    );
  };

  renderMigrationInfoModal = () => {
    const { styles } = this.props;
    return (
      <Modal
        closeOnOutsideClick
        isOpen={this.state.showMigrationInfoModal}
        onClose={() => this.setState({ showMigrationInfoModal: false })}
      >
        <ModalBody>
          <div className={styles.modalBody}>
            <h1>Why do we need this information?</h1>
            <p>
              PuppyFind was originally launched in 2003. To stay up to date and secure, we may need to do things like
              verify your name, location, and password. This helps us ensure that your information is accurate for the
              transition, and helps improve the overall security of the site for everyone.
            </p>
          </div>
        </ModalBody>

        <div className={styles.modalFooter}>
          <Button buttonType="round" onClick={() => this.setState({ showMigrationInfoModal: false })}>
            Close
          </Button>
        </div>
      </Modal>
    );
  };

  renderMigrationWelcomeModal = () => {
    const { styles } = this.props;
    return (
      <Modal
        closeOnOutsideClick
        isOpen={this.state.showMigrationWelcomeModal}
        onClose={() => this.setState({ showMigrationWelcomeModal: false })}
      >
        <ModalBody>
          <div className={styles.modalBody}>
            <Icon icon="PuppyFindLogo" className={styles.puppyFindLogo} />
            <h4>Is now</h4>
            <div className={styles.puppyCrownLogoWrapper}>
              <Icon icon="PuppyLogoWithText" className={styles.puppyLogoText} />
            </div>
            <div className={styles.yellowBarContainer}>
              <hr className={styles.yellowBar} />
            </div>
            <p>
              <strong>New Name, New Look</strong>
            </p>
            <p>
              <strong className={styles.emphasize}>PuppyFind.com</strong> was redesigned and is now called{' '}
              <strong className={styles.emphasize}>Puppies.com.</strong> We've worked hard to improve the site for you
              and just need a couple things verified before we can continue.
            </p>
          </div>
        </ModalBody>

        <div className={styles.modalFooter}>
          <Button buttonType="round" onClick={() => this.setState({ showMigrationWelcomeModal: false })}>
            Continue
          </Button>
        </div>
      </Modal>
    );
  };

  onSubmit = event => {
    const { user, values, submitForm } = this.props;
    event.preventDefault();
    if (
      !this.state.locationChangeConfirmed &&
      user.get('formatted_location') &&
      values.location &&
      values.location.label !== user.get('formatted_location')
    ) {
      this.setState({ showLocationConfirmationModal: true });
      return;
    } else {
      submitForm();
    }
  };

  renderForm() {
    const { isSubmitting, status, submitForm, user, styles, values, touched, errors, userVM } = this.props;

    const buttonCopy = isSubmitting ? 'SAVING...' : 'SAVE AND CONTINUE';

    return (
      <form className={styles.formWrapper} onSubmit={this.onSubmit}>
        <div className={styles.logoToLogo}>
          <Icon icon="PuppyFindLogo" className={styles.puppyFindLogo} />
          <Icon icon="ThickArrowRight" className={styles.thickArrow} />
          <div className={styles.puppyCrownLogoWrapper}>
            <Icon icon="PuppyLogoWithText" className={styles.puppyLogoText} />
          </div>
        </div>
        <div className={styles.goldBar}>
          <span>Please verify your information below.</span>
        </div>
        <h3 className={styles.aboutYouHeader}>About you{userVM.get('isActive') ? ' (primary contact)' : ''}</h3>
        <div className={styles.profileTopWrapper}>
          <div className={styles.profileTop}>
            {values.photo || values.existingPhoto ? (
              <CircleAvatar
                size={150}
                photo={values.existingPhoto}
                imageUrl={values.photo}
                croppedImageUrl={values.croppedPhoto}
                editMode={true}
                handleCrop={this.handleCrop}
                handleDelete={this.handleDelete.bind(this, !!values.existingPhoto)}
              />
            ) : (
              <FileUploader
                variant="human"
                onFilesLoaded={this.handlePhotoChange}
                accept={{
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpeg', '.jpg'],
                }}
              />
            )}
          </div>

          <div className={styles.profileTopFields}>
            <div className={styles.fieldsInline}>
              <Field
                label="First Name"
                name="first_name"
                maxLength={40}
                component={TextInput}
                required
                placeholder="What's your first name?"
              />
              <Field
                label="Last Name"
                name="last_name"
                maxLength={40}
                component={TextInput}
                required
                placeholder="What's your last name?"
              />
            </div>
            <Field label="Email Address" name="email" autoComplete="change-email" component={TextInput} required />
          </div>
        </div>
        <div className={styles.profileBottom}>
          <div className={styles.locationField}>
            <Field
              label="Location"
              name="location"
              placeholder="City, State, or ZIP"
              component={GeoComplete}
              initialValue={user.get('formatted_location') || ''}
              required
            />
            {touched.location && errors.location && <InputError>{errors.location}</InputError>}
          </div>
        </div>
        <hr className={styles.hr} />
        <div className={styles.profileButtonWrapper}>
          {status && status.photoError && <InputError>{status.photoError}</InputError>}
          {status && status.error && <InputError>{status.error}</InputError>}

          {user.getIn(['historicalUserRequirements', 'needs_premier_profile']) ? (
            <div className={styles.reminder}>
              <div>Reminder: this is your account information, not your kennel/breeder information.</div>
              <div>We'll look at that on the next page.</div>
            </div>
          ) : null}

          <div className={styles.innerButton}>
            {status && status.success ? (
              <span>
                <Button buttonType="roundOrange" type="button" disabled={isSubmitting} onClick={this.onSubmit}>
                  SAVE AND CONTINUE
                </Button>
              </span>
            ) : (
              <Button buttonType="roundOrange" type="submit" disabled={isSubmitting} isSubmitting={isSubmitting}>
                {buttonCopy}
              </Button>
            )}

            {status && status.success ? <Icon icon="FatCheck" fill={theme.colors.green} /> : null}
          </div>
        </div>
        {this.renderConfirmLocationChangeModal(submitForm)}
        {this.renderMigrationInfoModal()}
        {this.renderMigrationWelcomeModal()}
      </form>
    );
  }

  renderSuccess() {
    const { styles, user } = this.props;
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    return (
      <div>
        <div className={styles.goldBar}>
          <span>Thanks for being a member!</span>
        </div>
        <div className={styles.successIcon}>
          <Icon size={120} fill={theme.colors.green} icon="CheckCircle" />
        </div>
        <div className={styles.successText}>
          <h3>You're all set.</h3>
          <div className={styles.notBold}>We've got all the information we need from you. Thanks for confirming!</div>
        </div>
        <hr className={styles.hr} />
        <div className={styles.profileButtonWrapper}>
          <div className={styles.successButtonWrapper}>
            <Button
              buttonType="roundOrange"
              type="button"
              onClick={async () => {
                const action = await this.props.completeMigration(user.get('id'));
                if (action.response.ok) {
                  this.props.history.push('/');
                }
              }}
            >
              GO TO THE NEW PUPPIES.COM!
            </Button>
            <ButtonPlain
              type="button"
              style={{ fontSize: '14px', textDecorationLine: 'none' }}
              onClick={() => this.props.setStatus({ success: false })}
            >
              Start Over
            </ButtonPlain>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <div>{this.renderForm()}</div>;
  }
}

const FormikPuppyFindMigration = withFormik({
  mapPropsToValues: props => {
    const { user } = props;
    const locObj = user.get('formatted_location')
      ? {
          label: user.get('formatted_location'),
          location: {
            lat: user.get('lat'),
            lng: user.get('lng'),
          },
        }
      : null;
    return {
      first_name: user.get('first_name') || '',
      last_name: user.get('last_name') || '',
      email: user.get('email') || '',
      location: locObj || '',
      photo: null,
      existingPhoto: user.get('photo'),
    };
  },

  validationSchema: yup.object().shape({
    first_name: yup
      .string()
      .trim()
      .max(40, 'First name must be 40 characters or less')
      .required('First name is required'),
    last_name: yup
      .string()
      .trim()
      .max(40, 'Last name must be 40 characters or less')
      .required('Last name is required'),
    email: yup
      .string()
      .trim()
      .email()
      .max(64, 'Email must be 64 characters or less')
      .required('Email address is required'),
    location: yup.mixed().required('Location is required'),
    photoToDelete: yup.string().trim(),
  }),

  handleSubmit: async (values, { props, setSubmitting, setStatus, setFieldValue, resetForm }) => {
    const { location, ...restValues } = values;
    const userId = props.user.get('id');

    if (restValues.photo && restValues.photo !== props.user.getIn(['photo', 'key'])) {
      const results = await handleFileUpload({
        getUploadToken: props.getUploadToken,
        file: restValues.photo,
        key: `${userId}/profile`,
      });
      restValues.photo = { key: results.public_id };
    } else if (restValues.photo !== null) {
      delete restValues.photo;
    }

    if (restValues.croppedPhoto) {
      const croppedResult = await handleFileUpload({
        getUploadToken: props.getUploadToken,
        file: restValues.croppedPhoto,
        key: `${userId}/profile`,
      });
      restValues.croppedPhotoKey = croppedResult.public_id;
    }

    delete restValues.croppedPhoto;

    if (restValues.photoToDelete) {
      await props.deleteUserImage(restValues.photoToDelete);
    }

    delete restValues.photoToDelete;
    setFieldValue('photoToDelete', '');

    if (!restValues.photo && restValues.existingPhoto) {
      delete restValues.photo;
    }
    delete restValues.existingPhoto;

    if (props.purchaseType && props.purchaseType === 'trial') {
      restValues.start_trial = true;
    }
    const newValues = Object.assign({}, restValues, {
      formatted_location: location.label,
      lat: location.location.lat,
      lng: location.location.lng,
    });

    setStatus({});
    const action = await props.updateUser(newValues);
    setSubmitting(false);

    if (!action.response.ok) {
      setStatus({
        error: action.json ? action.json.message : 'An unknown error occurred',
      });
    } else {
      if (props.user.get('email') !== values.email) {
        props.setErrorBarMessage(
          <div>
            Email Address Changed, please re-verify in{' '}
            <Link style={{ textDecoration: 'underline' }} to="/account/verifications">
              verification settings
            </Link>
          </div>
        );
      }
      resetForm(); // Can't submit form again without this
      setStatus({ success: true });
    }
  },
})(PuppyFindMigration);

const styles = (props, touched, errors) => ({
  profileTop: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginBottom: '40px',
    '& > * + *': {
      marginLeft: '64px',
    },
    '@media (max-width: 900px)': {
      flexDirection: 'column',
      padding: '0 20px 32px',
      '& > * + *': {
        marginLeft: '0',
        marginTop: '24px',
      },
    },
  },
  profileTopFields: {
    width: '100%',
    maxWidth: '520px',
    '& > * + *': {
      marginTop: '16px',
    },
  },
  profileBottom: {
    '& > * + *': {
      marginTop: '24px',
    },
    '@media (max-width: 552px)': {
      padding: '0 16px',
    },
  },
  locationField: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '48px',
    '> div': {
      width: '250px',
      '@media (max-width: 552px)': {
        width: '100%',
      },
    },
    '> div > label > div > div > input': {
      border:
        touched.location && errors.location
          ? `1px solid ${props.theme.colors.red}`
          : `1px solid ${props.theme.colors.disabled}`,
      '&::placeholder': {
        fontWeight: props.theme.typography.sansBold,
        color: '#CDCDCD',
      },
    },
  },
  hr: {
    border: 'none',
    borderTop: props.theme.globalDashedBorder,
    margin: '48px auto',
    width: '100%',
    maxWidth: '520px',
  },
  yellowBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  yellowBar: {
    backgroundColor: '#FFC232',
    border: 'none',
    width: '150px',
    height: '5px',
    margin: '24px 0',
  },
  profileButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '48px',
    '> * + *': {
      marginTop: '32px',
    },
    '> button': {
      borderRadius: '100px',
      minWidth: 'unset',
    },
    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  innerButton: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: '12px',
    },
  },
  modalChildren: {
    padding: '30px',
    backgroundColor: '#F5F5F5',
    borderRadius: '6px',
    '>p': {
      lineHeight: '16px',
      fontSize: '14px',
      marginBottom: '30px',
    },
  },
  modalTitle: {
    fontSize: '28px',
    lineHeight: '32px',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.brown,
    textAlign: 'center',
    margin: '30px 0 20px',
    borderRadius: '3px',
  },
  modalButtonGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '> * + *': {
      marginTop: '20px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      justifyContent: 'center',
      '> *': {
        width: '143px',
      },
      '> * + *': {
        marginTop: 0,
        marginLeft: '20px',
      },
    },
  },
  paymentModal: {
    borderRadius: '6px !important',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: '#D0021B',
    '>h1': {
      fontSize: '16px',
      color: props.theme.colors.textDark,
      marginBottom: '32px',
      fontWeight: props.theme.typography.sansBold,
    },
    '>p': {
      color: props.theme.colors.brown,
      fontSize: '16px',
      lineHeight: '1.5',
      width: '100%',
    },
    '>h4': {
      color: props.theme.colors.textDark,
      fontSize: '18px',
      fontWeight: props.theme.typography.sansBold,
      margin: '12px 0',
    },
  },
  modalFooter: {
    width: '100%',
    backgroundColor: props.theme.colors.darkerTan,
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  formWrapper: {
    height: '100%',
    backgroundColor: props.theme.colors.tan,
  },
  goldBar: {
    backgroundColor: props.theme.colors.yellow,
    padding: '16px',
    textAlign: 'center',
    fontWeight: props.theme.typography.sansBold,
    marginBottom: '32px',
  },
  puppyCrownLogoWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  puppyLogoText: {
    width: '175px !important',
    height: '35px !important',
    '@media (max-width: 480px)': {
      width: '150px !important',
      height: '26px !important',
    },
  },
  logoToLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px 16px',
    backgroundColor: props.theme.colors.tan,
  },
  puppyFindLogo: {
    width: '222px',
    height: '32px',
    '@media (max-width: 480px)': {
      width: '148px',
      height: '21px',
    },
    '@media (max-width: 360px)': {
      width: '111px',
      height: '16px',
    },
  },
  thickArrow: {
    width: '40px',
    height: '40px',
    fill: props.theme.colors.green,
    '@media (max-width: 480px)': {
      width: '24px',
      height: '24px',
    },
  },
  aboutYouHeader: {
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.orange,
    textAlign: 'center',
    marginBottom: '32px',
  },
  fieldsInline: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '24px',

    '> div': {
      flex: 1,
      width: '100%',
      '&:first-of-type': {
        marginRight: '8px',
      },
      '&:last-of-type': {
        marginLeft: '8px',
      },
    },
    '> * + *': {
      marginLeft: '24px',
    },
    '> div > label > div > input': {
      '&::placeholder': {
        fontWeight: props.theme.typography.sansBold,
        color: '#CDCDCD',
      },
    },
    '@media (max-width: 480px)': {
      flexDirection: 'column',
      '> * + *': {
        marginLeft: '0',
        marginTop: '16px',
      },
    },
  },
  profileTopWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 16px',
    marginBottom: '24px',
  },
  puppyLogoOnly: {
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
    padding: '24px 16px',
    backgroundColor: props.theme.colors.tan,
  },
  successText: {
    width: '100%',
    maxWidth: '520px',
    margin: '0 auto',
    padding: '0 16px',
    textAlign: 'center',
    color: props.theme.colors.textDark,
    fontSize: '16px',
    lineHeight: '1.5',
    '> h3': {
      fontWeight: props.theme.typography.sansBold,
    },
  },
  successIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
    marginBottom: '48px',
  },
  successButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '> * + *': {
      marginTop: '72px',
    },
  },
  reminder: {
    fontSize: '16px',
    color: 'black',
    fontWeight: props.theme.typography.sansBold,
    textAlign: 'center',
  },
  emphasize: {
    fontStyle: 'italic',
  },
});

export default compose(felaConnect(styles), connect(), withRouter)(FormikPuppyFindMigration);
