import { connect } from 'react-redux';
import { updateNotificationSettings } from '../../../../redux/actions';
import NotificationSettings from './NotificationSettings';

const mapStateToProps = state => {
  return {
    user: state.user,
    userVM: state.userVM,
  };
};

const mapDispatchToProps = {
  updateNotificationSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
