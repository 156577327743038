import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'lodash/flowRight';
import { connect as felaConnect } from 'react-fela';
import { Icon, Button } from '../';
import theme from '../../../theme';
import BackButton from '../BackButton';
import { useBrowser } from '../../../contexts/BrowserContext';

function SubNav({ history, styles, titleText, user, listing, headerRightChildren, location }) {
  const browser = useBrowser();
  const userIsSeller = user && listing && listing.get('seller') === user.get('id');
  return (
    <div className={styles.flex}>
      <div className={styles.wrapper}>
        <div className={styles.root}>
          {titleText && (
            <div className={styles.titleContainer}>
              <h2>{titleText}</h2>
            </div>
          )}
          <div
            className={styles.headerRight}
            style={{ alignSelf: browser.lessThan.tablet && userIsSeller ? 'auto' : 'flex-start' }}
          >
            <BackButton location={location} className={styles.backButton} />
            {userIsSeller ? (
              <Button
                onClick={() =>
                  history.push({
                    pathname: `/listings/${listing.get('id')}/edit`,
                    state: {
                      prevLocation: location,
                      backTo: 'Listing',
                    },
                  })
                }
                buttonType="white"
                iconLeft={<Icon icon="Pencil" size={16} fill={theme.colors.blue} />}
              >
                Edit Listing
              </Button>
            ) : null}
            {headerRightChildren && <div className={styles.headerRightChildren}>{headerRightChildren}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

SubNav.propTypes = {
  styles: PropTypes.object,
  titleText: PropTypes.any,
  headerRightChildren: PropTypes.node,
  profile: PropTypes.bool,
};

const styles = props => ({
  flex: {
    display: 'flex',
  },
  wrapper: {
    width: '100%',
    backgroundColor: props.theme.colors.darkerTan,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    width: '100%',
    maxWidth: props.theme.globalMaxWidth,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    '@media (min-width: 740px)': {
      flexDirection: 'row',
    },
    [props.theme.breakpoints.mobile]: {
      padding: '25px 20px',
    },
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    '> h2': {
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansLight,
      fontSize: '20px',
      color: props.theme.colors.orange,
      marginBottom:
        props.headerRightChildren ||
        (props.user && props.listing && props.listing.get('seller') === props.user.get('id'))
          ? '16px'
          : 0,
      '@media (min-width: 740px)': {
        fontSize: '28px',
        margin: 0,
      },
    },
    [props.theme.breakpoints.mobile]: {
      margin: '3px 0 4px',
    },
  },
  headerRight: {
    display: 'flex',
    flex: 1,
    justifyContent: !props.titleText && !props.headerRightChildren ? 'flex-start' : 'flex-end',
    alignItems: 'center',

    '> *': {
      flex: 1,
    },
    [props.theme.breakpoints.mobile]: {
      '> *': {
        flex: 'unset',
      },
    },
    '> * + *': {
      marginLeft: '16px',
    },
    '> button': {
      backgroundColor: 'white',
      color: props.theme.colors.blue,
      padding: '12px',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      boxShadow: props.theme.globalBoxShadow,
      transition: '0.2s',
      '> div': {
        '> span': {
          fontFamily: props.theme.typography.sansAlt,
        },
      },
      ':hover': {
        opacity: 0.8,
      },
    },
    '> a': {
      backgroundColor: 'white',
      color: props.theme.colors.blue,
      padding: '12px',
      textTransform: 'uppercase',
      letterSpacing: '0.5px',
      boxShadow: props.theme.globalBoxShadow,
      transition: '0.2s',
      '> div': {
        '> span': {
          '> span': {
            marginRight: '6px',
            ':last-of-type': {
              margin: 0,
            },
          },
        },
      },
      ':hover': {
        color: props.theme.colors.blue,
        backgroundColor: props.theme.colors.white,
        opacity: 0.8,
      },
      ':active': {
        backgroundColor: `${props.theme.colors.green} !important`,
        color: `${props.theme.colors.white} !important`,
        boxShadow: 'inset 0 2px 1px 0 #417505',
        borderColor: 'transparent',
        outline: 'none',
      },
    },
  },
  headerRightChildren: {
    '> button': {
      backgroundColor: 'white',
      color: props.theme.colors.blue,
      padding: '12px 18px',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      boxShadow: props.theme.globalBoxShadow,
      transition: '0.2s',
      '> div': {
        '> span': {
          fontFamily: props.theme.typography.sansAlt,
        },
      },
      ':hover': {
        opacity: 0.8,
      },
    },
  },
  backButton: {
    '> div': {
      '> span': {
        ':last-of-type': {
          fontFamily: props.theme.typography.sansAlt,
          fontWeight: '900 !important',
        },
      },
    },
  },
});

export default compose(felaConnect(styles), withRouter)(SubNav);
