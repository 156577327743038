import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ButtonPlain } from '../../common/components';
import SignUpForm from '../SignUp/SignUpForm';

import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';
import { bindActionCreators } from 'redux';
import { isLoggedInSelector } from '../../redux/selectors';
import { signUp, setErrorBarMessage, clearErrorBar } from '../../redux/actions';
import { setActiveModal } from '../Modal/redux/actions';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';

class SignUpModal extends Component {
  state = {
    error: null,
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div>{this.state.error}</div>;
  }

  render() {
    const { setActiveModal, signUp, showModal, styles } = this.props;

    return (
      <Modal
        isOpen={showModal}
        onClose={() => setActiveModal(null)}
        closeOnOutsideClick
        closeOnEscape
        hasMobileCloseButton
      >
        <ModalHeader
          title="Sign up for free!"
          subTitle="Sign up today and add a little wiggle to your life!"
          registered
        />
        <ModalBody>
          <div className={styles.error}>{this.renderError()}</div>
          <SignUpForm
            setError={error => this.setState({ error })}
            clearError={() => this.setState({ error: null })}
            submitAction={async payload => {
              const result = await signUp(payload);
              if (result.response.ok) {
                setActiveModal(null);
              }
              return result;
            }}
            isModal
          />
          <div className={styles.formFooter}>
            <span>Already have an account? </span>
            <div>
              <ButtonPlain onClick={() => setActiveModal('signin')} data-testid={'switchToSignInModalButton'}>
                Sign In
              </ButtonPlain>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedInSelector(state),
    showModal: state.modal.activeModal === 'signup',
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setActiveModal, signUp, setErrorBarMessage, clearErrorBar }, dispatch);

const styles = props => ({
  header: {
    backgroundColor: props.theme.colors.blue,
    padding: '20px',
    color: props.theme.colors.white,
    fontSize: '14px',
    '> h1': {
      fontSize: '30px',
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansAltBold,
      lineHeight: '37px',
    },
  },
  footer: {
    width: '100%',
    borderTop: props.theme.globalDashedBorder,
    padding: '25px',
    lineHeight: '19px',
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    '> p': {
      maxWidth: '396px',
    },
  },
  divider: {
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    margin: '30px 0',
    fontWeight: props.theme.typography.sansBold,
    '> * + *': {
      marginLeft: '10px',
    },
  },
  dividerBorder: {
    height: '1px',
    width: '100%',
    borderTop: props.theme.globalDashedBorder,
  },
  formFooter: {
    marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '> * + *': {
      marginLeft: '4px',
    },
  },
  error: {
    marginBottom: '20px',
    color: props.theme.colors.red,
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(felaConnect(styles)(SignUpModal));
