import React from 'react';
import { Loading } from '../../../../../common/components';
import PaymentsTable from './PaymentsTable';
import { connect as felaConnect } from 'react-fela';

const PaymentHistory = ({ styles, queryObject, payments, paymentsPagination, paymentsLoaded, getPayments, userVM }) => {
  React.useEffect(() => {
    getPayments(queryObject);
  }, [queryObject, getPayments, userVM]);

  if (!paymentsLoaded) {
    return <Loading dark center />;
  }

  return (
    <div>
      <div className={styles.header}>
        <h1>Payment History</h1>
      </div>
      <PaymentsTable styles={styles} payments={payments} paymentsPagination={paymentsPagination} />
    </div>
  );
};

const styles = props => ({
  header: {
    maxWidth: '1068px',
    margin: '50px auto 0',
    padding: '28px 0 0 10px',
    borderTop: props.theme.globalDashedBorder,
    '>h1': {
      color: props.theme.colors.orange,
      fontWeight: props.theme.typography.sansBold,
    },
    [props.theme.breakpoints.mobile]: {
      padding: '28px 0 0 20px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      padding: '28px 0 0 0',
    },
  },
  table: {
    marginBottom: '50px',
  },
  tr: {
    '>th': {
      padding: '14px 8px',
      fontSize: '10px',
    },
    '>td': {
      padding: '14px 8px',
      fontSize: '10px',
    },
    [props.theme.breakpoints.mobile]: {
      '>th': {
        padding: '14px 18px',
        fontSize: '11px',
      },
      '>td': {
        padding: '14px 18px',
        fontSize: '16px',
      },
    },
  },
  tableFooter: {
    ...props.theme.globalPadding,
    maxWidth: props.theme.globalMaxWidth,
    margin: '30px auto',
    display: 'flex',
    justifyContent: 'flex-end !important',
  },
  lastFourContainer: {
    display: 'flex',
    alignItems: 'center',
    '> span': {
      height: '21px',
    },
  },
  lastFour: {
    fontSize: '14px',
    marginLeft: '8px',
  },
});

export default felaConnect(styles)(PaymentHistory);
