import TestimonialModal from './TestimonialModal';
import { connect } from 'react-redux';
import {
  createOrUpdateTestimonial,
  replyToTestimonial,
  // replyToLegacyTestimonial,
  // createOrUpdateSellerTestimonial,
  // updateLegacyTestimonial,
} from '../../../views/Testimonials/redux/actions';
import { bindActionCreators } from 'redux';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createOrUpdateTestimonial,
      replyToTestimonial,
      // replyToLegacyTestimonial,
      // createOrUpdateSellerTestimonial,
      // updateLegacyTestimonial,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialModal);
