import { connect } from 'react-redux';

import LearningCenter from './LearningCenter';

const mapStateToProps = state => ({
  loading: state.articles.loading,
  articles: state.articles.articleList.get('list'),
  total: state.articles.articleList.get('total'),
  currentArticle: state.articles.currentArticle,
  featured: state.articles.featured,
  recent: state.articles.recent,
});

export default connect(mapStateToProps)(LearningCenter);
