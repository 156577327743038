import React from 'react';

function PuppyCircle() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>
        <path d="M12.16 22.295c5.594 0 10.129-4.537 10.129-10.132 0-5.596-4.535-10.132-10.13-10.132-5.594 0-10.128 4.536-10.128 10.132 0 5.595 4.534 10.132 10.129 10.132zm0 1.39C5.797 23.688.64 18.527.64 12.164S5.797.64 12.16.64c6.363 0 11.52 5.159 11.52 11.523 0 6.363-5.158 11.523-11.52 11.523z" />
        <path d="M8.176 16.525c-.012-.395.077-.835.144-1.237.266-1.594 1.028-3.006 2.172-3.836-.559-.287-.965-.861-1.256-1.702-.562-1.632.073-3.275 1.776-3.815 1.562-.495 2.77-.094 3.393 1.236.09-.013.186-.023.288-.028.05-.144.18-.25.336-.277.157-.027.316.03.416.15.099.119.122.28.06.42.561.608 1.035 2.09-1.02 3.58 1.07.61 1.294 2.055 2.336 4.401.893 2.007 1.347 2.37.415 2.38-.712.01-2.09-2.81-2.09-2.81.104 1.796-1.34 1.974-1.34 1.974 2.256-.024 1.895.942 1.895.942-.894.154-1.866.23-2.88.209-1.135-.023-2.622-.197-3.488-.394-1.025.053-3.104-.099-3.083-2.213 0 0 .955 1.158 1.926 1.02" />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default PuppyCircle;
