import React from 'react';
import { Redirect } from 'react-router-dom';

import App from './App';
import requireAuth from './common/utils/requireAuth';
import redirectAuth from './common/utils/redirectAuth';
import PrimaryLayout from './layouts/PrimaryLayout';
import Login from './views/Login';
import SignUp from './views/SignUp';
import ResetPassword from './views/ResetPassword';
import ForgotPassword from './views/ForgotPassword';
import Logout from './views/Logout';
import BreedsList from './views/BreedsList';
import Account from './views/Account';
import Verification from './views/Account/views/Verification';
import MyProfile from './views/Account/views/MyProfile';
import Membership from './views/Account/views/Membership';
import PremierProfile from './views/Account/views/PremierProfile';
import NotificationSettings from './views/Account/views/NotificationSettings';
import PuppyFindMigration from './views/Account/views/PuppyFindMigration';
import PuppyFindMigrationPremiers from './views/Account/views/PuppyFindMigrationPremiers';
import PuppyFindMigrationComplete from './views/Account/views/PuppyFindMigrationComplete';
import EmailVerified from './views/Account/views/EmailVerified';
import ChangePassword from './views/ChangePassword';
import VerifyEmail from './views/VerifyEmail';
import VerifyPhone from './views/VerifyPhone';
import SearchListings from './views/SearchListings';
import Profile from './views/Profile';
import ListingsContainer from './views/Listings';
import AddListing from './views/Listings/views/AddListing';
import EditListing from './views/Listings/views/EditListing';
import MyListings from './views/Listings/views/MyListings';
import ViewListing from './views/Listings/views/ViewListing';
import ViewPremier from './views/Premiers/views/ViewPremier';
import Favorites from './views/Account/views/Favorites';
import SavedSearch from './views/Account/views/SavedSearch';
import AutoLogin from './views/AutoLogin';
import AdoptionPlaceholder from './views/Adoption';
import MessageCenter from './views/Messages/views/MessageCenter';
import FeedbackManager from './views/Testimonials';
import BreedMatchmaker from './views/BreedMatchmaker';
import BuyerLandingPage from './views/BuyerLandingPage';
import SellerLandingPage from './views/SellerLandingPage';
import LearningCenter from './views/LearningCenter';
import ArticleList from './views/LearningCenter/views/ArticleList';
import SearchResults from './views/LearningCenter/views/SearchResults';
import ViewArticle from './views/LearningCenter/views/ViewArticle';
import AuthorArticles from './views/LearningCenter/views/AuthorArticles';
import TagArticles from './views/LearningCenter/views/TagArticles';
import Contact from './views/Contact';
import PageNotFound from './views/PageNotFound';
import Unsubscribe from './views/Unsubscribe';
import get from 'lodash/get';
import BreedInformation from './views/BreedInformation';
import PuppyFind from './views/PuppyFind';

const routes = [
  {
    component: App,
    routes: [
      {
        path: '/',
        exact: true,
        component: redirectAuth('/find-a-puppy')(BuyerLandingPage),
      },
      {
        path: '/sellers',
        exact: true,
        component: redirectAuth('/find-a-puppy')(SellerLandingPage),
      },
      {
        path: '/breeds/:slug',
        exact: true,
        component: BreedInformation,
      },
      {
        path: '/breeds/:slug/:state',
        exact: true,
        component: BreedInformation,
      },
      {
        path: '/breeds/:slug/:state/:city',
        exact: true,
        component: BreedInformation,
      },
      {
        component: PrimaryLayout,
        routes: [
          {
            path: '/account',
            component: Account,
            routes: [
              {
                path: '/account',
                exact: true,
                component: () => <Redirect to="/account/my-profile" />,
              },
              {
                path: '/account/verifications',
                exact: true,
                component: requireAuth()(Verification),
              },
              {
                path: '/account/my-profile',
                exact: true,
                component: requireAuth()(MyProfile),
              },
              {
                path: '/account/membership',
                exact: true,
                component: requireAuth()(Membership),
              },
              {
                path: '/account/change-password',
                exact: true,
                component: requireAuth()(ChangePassword),
              },
              {
                path: '/account/premier-profile',
                exact: true,
                component: requireAuth()(PremierProfile),
              },
              {
                path: '/account/notification-settings',
                exact: true,
                component: requireAuth()(NotificationSettings),
              },
              {
                path: '/account/puppyfind-user',
                exact: true,
                component: requireAuth()(PuppyFindMigration),
              },
              {
                path: '/account/puppyfind-premier',
                exact: true,
                component: requireAuth()(PuppyFindMigrationPremiers),
              },
              {
                path: '/account/puppyfind-complete',
                exact: true,
                component: requireAuth()(PuppyFindMigrationComplete),
              },
              {
                path: '/account/email-verified',
                exact: true,
                component: requireAuth()(EmailVerified),
              },
              {
                path: '/account/*',
                component: PageNotFound,
                notFoundButton: { to: '/find-a-puppy', buttonText: 'Find A Puppy' },
              },
            ],
          },
          {
            path: '/puppyfind',
            exact: true,
            component: PuppyFind,
          },
          {
            path: '/messages/:tab(inbox|archive)/:conversationId?',
            component: requireAuth()(MessageCenter),
          },
          {
            path: '/messages',
            exact: true,
            component: () => <Redirect to="/messages/inbox" />,
          },
          {
            path: '/breed-matchmaker',
            component: BreedMatchmaker,
          },
          {
            path: '/blog',
            component: LearningCenter,
            routes: [
              {
                path: '/blog',
                exact: true,
                component: ArticleList,
              },
              {
                path: '/blog/search',
                exact: true,
                component: SearchResults,
              },
              {
                path: '/blog/articles/:articleId',
                exact: true,
                component: ViewArticle,
              },
              {
                path: '/blog/author/:authorId',
                exact: true,
                component: AuthorArticles,
              },
              {
                path: '/blog/tags/:tag',
                exact: true,
                component: TagArticles,
              },
              {
                path: '/blog/*',
                component: PageNotFound,
                notFoundButton: { to: '/blog', buttonText: 'Blog' },
              },
            ],
          },
          {
            path: '/tos',
            exact: true,
            component: () => {
              window.location.href = 'https://help.puppies.com/article/124-puppies-com-terms-of-service';
              return null;
            },
          },
          {
            path: '/contact',
            exact: true,
            component: Contact,
          },
          {
            path: '/privacy-statement',
            exact: true,
            component: () => {
              window.location.href = 'https://help.puppies.com/article/128-puppies-com-privacy-policy';
              return null;
            },
          },
          {
            path: '/sign-in',
            exact: true,
            component: Login,
          },
          {
            path: '/auto-login',
            exact: true,
            component: AutoLogin,
          },
          {
            path: '/sign-up',
            exact: true,
            component: SignUp,
          },
          {
            path: '/forgot-password',
            exact: true,
            component: ForgotPassword,
          },
          {
            path: '/reset-password/:tokenId',
            exact: true,
            component: ResetPassword,
          },
          {
            path: '/breeds',
            exact: true,
            component: BreedsList,
          },
          {
            path: '/verify-email/:tokenId',
            exact: true,
            component: VerifyEmail,
          },
          {
            path: '/verify-phone-number',
            exact: true,
            component: requireAuth()(VerifyPhone),
          },
          {
            path: '/logout',
            exact: true,
            component: Logout,
          },
          {
            path: '/find-a-puppy',
            exact: true,
            component: SearchListings,
          },
          {
            path: '/find-a-puppy/:breedOrState',
            exact: true,
            component: SearchListings,
          },
          {
            path: '/find-a-puppy/:breedOrState/:stateOrCity',
            exact: true,
            component: SearchListings,
          },
          {
            path: '/find-a-puppy/:breedOrState/:stateOrCity/:city',
            exact: true,
            component: SearchListings,
          },
          {
            path: '/find-a-breeder',
            component: () => {
              window.location.replace(window.location.href.replace('breeder', 'puppy'));
              return null;
            },
          },
          {
            path: '/breeds/*',
            component: PageNotFound,
            notFoundButton: { to: '/breeds', buttonText: 'Browse All Breeds' },
          },
          {
            path: '/profile/:profileId',
            exact: true,
            component: Profile,
          },
          {
            path: '/premier/:slug',
            exact: true,
            component: ViewPremier,
          },
          {
            path: '/premier/*',
            component: PageNotFound,
            notFoundButton: { to: '/find-a-puppy', buttonText: 'Find A Puppy' },
          },
          {
            path: '/adoption',
            exact: true,
            component: AdoptionPlaceholder,
          },
          {
            path: '/saved-searches',
            exact: true,
            component: requireAuth()(SavedSearch),
          },
          {
            path: '/favorites',
            exact: true,
            component: () => <Redirect to="/favorites/listings" />,
          },
          {
            path: '/favorites/listings',
            exact: true,
            component: requireAuth()(Favorites),
          },
          {
            path: '/favorites/breeders',
            exact: true,
            component: requireAuth()(Favorites),
          },
          {
            path: '/feedback-manager',
            exact: true,
            component: () => <Redirect to="/feedback-manager/left" />,
          },
          {
            path: '/feedback-manager/:tab(left|received)',
            exact: true,
            component: requireAuth()(FeedbackManager),
          },
          {
            path: '/unsubscribe',
            exact: true,
            component: Unsubscribe,
          },
          {
            path: '/listings',
            component: ListingsContainer,
            routes: [
              {
                path: '/listings/:status(sold|hold)?',
                exact: true,
                component: requireAuth(() => <Redirect to={'/account/membership'} />, {
                  customVerify: state => state.userVM.get('isMember'),
                })(MyListings),
              },
              {
                path: '/listings/create',
                exact: true,
                component: requireAuth(() => <Redirect to={'/account/membership'} />, {
                  customVerify: state => state.userVM.get('isMember'),
                })(AddListing),
              },
              {
                path: '/listings/duplicate',
                exact: true,
                component: props => {
                  return (
                    <Redirect
                      to={{
                        pathname: '/listings/create',
                        state: get(props, 'location.state.duplicate')
                          ? { duplicate: get(props, 'location.state.duplicate') }
                          : null,
                      }}
                    />
                  );
                },
              },
              {
                path: '/listings/:listingId/edit',
                exact: true,
                component: requireAuth(() => <Redirect to={'/find-a-puppy'} />, {
                  customVerify: state => {
                    return (
                      state.userVM.get('isMember') &&
                      (!state.listings.currentListing.size ||
                        state.listings.currentListing.get('seller') === state.user.get('id'))
                    );
                  },
                })(EditListing),
              },
              {
                path: '/listings/:listingId',
                exact: true,
                component: ViewListing,
              },
              {
                path: '/listings/*',
                component: PageNotFound,
                notFoundButton: { to: '/find-a-puppy', buttonText: 'Find A Puppy' },
              },
            ],
          },
          {
            path: '*',
            component: PageNotFound,
            notFoundButton: { to: '/find-a-puppy', buttonText: 'Find A Puppy' },
          },
        ],
      },
    ],
  },
];

export default routes;
