import Client, { JwtPlugin } from '@synapsestudios/fetch-client';
import Cookies from 'js-cookie';

import config from '../config';

const client = new Client({
  url: config.apiUrl,
  get: { credentials: 'same-origin' },
  post: { credentials: 'same-origin' },
  patch: { credentials: 'same-origin' },
  put: { credentials: 'same-origin' },
  delete: { credentials: 'same-origin' },
});

// Special cases where we want to set the id_token in the Authorization header
client.addPlugin(new JwtPlugin());
client.setJwtTokenGetter(() => {
  // Microsoft Edge v42.x drops cookies from DELETE requests
  if (/Edge/.test(window.navigator.userAgent)) {
    return Cookies.get('id_token');
  }

  // Safari doesn't include cookies with SameSite (Lax or Strict) in the development environment
  if (config.appEnv === 'development' && /Safari/.test(window.navigator.userAgent)) {
    return Cookies.get('id_token');
  }
});

export default client;
