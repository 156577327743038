import React from 'react';

function PhoneVerificationInfo() {
  /* eslint-disable max-len */
  return (
    <svg width="85" height="103" viewBox="0 0 85 103" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57.7139 83.8896H1" stroke="#1AB9C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M29.3573 91.5241C29.6465 91.5241 29.9239 91.6388 30.1285 91.8435C30.333 92.0481 30.4479 92.3255 30.4479 92.6148C30.4479 92.904 30.333 93.1815 30.1285 93.3861C29.9239 93.5907 29.6465 93.7054 29.3573 93.7054C29.068 93.7054 28.7906 93.5907 28.586 93.3861C28.3815 93.1815 28.2666 92.904 28.2666 92.6148C28.2666 92.3255 28.3815 92.0481 28.586 91.8435C28.7906 91.6388 29.068 91.5241 29.3573 91.5241Z"
        stroke="#1AB9C4"
        strokeWidth="2"
        strokeLinecap="round"
        inejoin="round"
      />
      <path
        d="M57.7139 66.4391V92.6148C57.7139 94.9287 56.7947 97.148 55.1583 98.7844C53.5219 100.421 51.3026 101.34 48.9887 101.34H9.72522C7.41116 101.34 5.19186 100.421 3.55557 98.7844C1.91925 97.148 1 94.9287 1 92.6148V9.72522C1 7.41116 1.91925 5.19186 3.55557 3.55557C5.19186 1.91926 7.41116 1 9.72522 1H31.5383"
        stroke="#1AB9C4"
        strokeWidth="2"
        strokeLinecap="round"
        inejoin="round"
      />
      <path
        d="M57.7143 40.2635V24.9944C57.7143 24.4159 57.4844 23.8611 57.0756 23.452C56.6664 23.0429 56.1115 22.8131 55.533 22.8131H51.1704"
        stroke="#1AB9C4"
        strokeWidth="2"
        strokeLinecap="round"
        inejoin="round"
      />
      <path d="M51.1704 40.2634H64.2582" stroke="#1AB9C4" strokeWidth="2" strokeLinecap="round" inejoin="round" />
      <path
        d="M29.3573 93.7054C28.7549 93.7054 28.2666 93.2172 28.2666 92.6148C28.2666 92.0123 28.7549 91.5241 29.3573 91.5241"
        stroke="#1AB9C4"
        strokeWidth="2"
      />
      <path
        d="M29.3577 93.7054C29.96 93.7054 30.4483 93.2172 30.4483 92.6148C30.4483 92.0123 29.96 91.5241 29.3577 91.5241"
        stroke="#1AB9C4"
        strokeWidth="2"
      />
      <path
        d="M54.4425 14.0879C53.84 14.0879 53.3518 13.5996 53.3518 12.9972C53.3518 12.3949 53.84 11.9066 54.4425 11.9066"
        stroke="#1AB9C4"
        strokeWidth="2"
      />
      <path
        d="M54.4419 14.0879C55.0444 14.0879 55.5325 13.5996 55.5325 12.9972C55.5325 12.3949 55.0444 11.9066 54.4419 11.9066"
        stroke="#1AB9C4"
        strokeWidth="2"
      />
      <path
        d="M57.7145 53.3513C72.1708 53.3513 83.8901 41.6321 83.8901 27.1757C83.8901 12.7192 72.1708 1 57.7145 1C43.2581 1 31.5388 12.7192 31.5388 27.1757C31.5388 41.6321 43.2581 53.3513 57.7145 53.3513Z"
        stroke="#1AB9C4"
        strokeWidth="2"
        strokeLinecap="round"
        inejoin="round"
      />
    </svg>
  );
  /* eslint-enable */
}

export default PhoneVerificationInfo;
