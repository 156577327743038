import React from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';

import { Button, Icon, ModalFooter } from '../../../../../common/components';

import styles from '../styles';
import theme from '../../../../../theme';

const PaymentSuccess = ({ onCancel = () => {}, styles = {} }) => {
  return (
    <div>
      <div className={styles.innerWrapper}>
        <div className={styles.iconWrapper}>
          <Icon icon="CheckRounded" size={67} fill={theme.colors.green} />
        </div>
        <h1>Payment Complete</h1>
      </div>
      <ModalFooter
        noBorder
        actions={[
          <Button buttonType="round" onClick={onCancel} type="button">
            DONE
          </Button>,
        ]}
      />
    </div>
  );
};

PaymentSuccess.propTypes = {
  onCancel: PropTypes.func,
};

export default felaConnect(styles)(PaymentSuccess);
