import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Map } from 'immutable';
import { withRouter, Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { addFavoriteListing, removeFavoriteListing } from '../../../views/Account/redux/actions';
import { isLoggedInSelector } from '../../../redux/selectors';
import formatListviewPuppyBiology from '../../utils/formatListviewPuppyBiology';
import formatPuppyBiology from '../../utils/formatPuppyBiology';
import InteractiveBookmark from '../icons/InteractiveBookmark';
import { showAuthenticationModal } from '../../../views/Modal/redux/actions';
import { getMediumSize, getThumbnail } from '../../utils/photo-helpers';
import { Icon } from '..';
import BoostIcon from '../icons/Boost';
import hasTouch from '../../utils/hasTouch';
import theme from '../../../theme';
import { DoAfterLoginContext } from '../../../contexts/DoAfterLogin';

const puppyImage = `/static/puppies_placeholder.png`;

class PuppySquare extends Component {
  static contextType = DoAfterLoginContext;

  toggleFavorite = debounce(
    () => {
      const { listing, addFavoriteListing, removeFavoriteListing } = this.props;
      const bookmarked = !!listing.get('favorite');
      const id = listing.get('id');
      if (!bookmarked) {
        this.context.doAfterLogin(() => {
          addFavoriteListing(id);
        });
      } else {
        removeFavoriteListing(id);
      }
    },
    250,
    { leading: true, trailing: false, maxWait: 250 }
  );

  handleBookmarkClick = e => {
    e.preventDefault();
    e.stopPropagation();

    this.toggleFavorite();
  };

  renderStatusOverlayChip() {
    const { listing, styles } = this.props;
    const status = listing.get('status');

    if (status === 'for_sale' && !listing.get('inactive')) {
      return null;
    }

    let overlayText = listing.get('inactive') ? 'INACTIVE' : status === 'on_hold' ? 'ON HOLD' : 'SOLD';

    return (
      <div
        className={classNames([
          styles.overlayChip,
          listing.get('inactive') ? styles.overlayChipInactive : null,
          status === 'on_hold' ? styles.overlayChipOnHold : null,
        ])}
      >
        {overlayText}
      </div>
    );
  }

  renderVerificationCheckmark = () => {
    const { listing, styles } = this.props;

    if (!listing.get('isSellerVerified')) {
      return null;
    }

    return (
      <div className={styles.boost}>
        <Icon icon="CheckCircle" fill={theme.colors.green} />
      </div>
    );
  };

  isBoosted = () => {
    const { listing } = this.props;

    const boostString = listing.get('boost');

    var boostDate = new Date(boostString);
    var today = new Date();
    return boostDate >= today;
  };
  renderBoost = () => {
    const { styles, theme } = this.props;

    if (!this.isBoosted()) {
      return null;
    }
    return (
      <div className={styles.boost}>
        <BoostIcon color={theme.colors.boostRocket} width="24" height="24" />
      </div>
    );
  };

  renderPremierBadge = () => {
    const { listing, styles, isPremier } = this.props;

    if (!listing.get('isActivePremier') && !isPremier) {
      return null;
    }

    return (
      <div className={styles.badge}>
        <Icon icon="PremierBadgeWithBackground" />
      </div>
    );
  };

  renderBookmark = (shouldHide, stroke, className, filledColor = '#FA6125', emptyColor = 'rgba(168, 168, 168, .5)') => {
    const { listing } = this.props;
    const bookmarked = !!listing.get('favorite');
    if (this.props.hideBookmark || shouldHide) {
      return null;
    } else {
      return (
        <div
          aria-label={'bookmark' + (bookmarked ? ' bookmarked' : '')}
          className={className}
          onClick={this.handleBookmarkClick}
        >
          <InteractiveBookmark fill={bookmarked ? filledColor : emptyColor} stroke={stroke} />
        </div>
      );
    }
  };

  getImageURL = (listing, isMobile) => {
    const primaryPhotoIndex = listing.get('photos').findIndex(photo => photo.get('sort_index') === 0);
    if (listing.get('photos').size) {
      if (isMobile) {
        return getThumbnail(listing.getIn(['photos', primaryPhotoIndex]));
      } else {
        return getMediumSize(listing.getIn(['photos', primaryPhotoIndex]));
      }
    } else {
      return puppyImage;
    }
  };

  render() {
    const { user, onClick, styles, listing, to } = this.props;
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 810;
    const { name, sex, birthdate, price } = listing.toJS();
    const breed = listing
      .get('breeds')
      .toJS()
      .map(breed => breed.name)
      .join(' / ');
    const location = listing.get('formatted_location');

    let backgroundURL = this.getImageURL(listing, isMobile);
    const isBoosted = this.isBoosted();
    const imageWidth = isMobile ? (isBoosted ? 120 : 121) : 252;

    const userIsSeller = listing && user && listing.get('seller') === user.get('id');
    const stroke = isMobile ? '#1AB9C4' : '#fff';

    return (
      <div className={isBoosted ? classNames([styles.card, styles.boosted]) : styles.card}>
        <Link to={to}>
          <div
            className={classNames([styles.overlay, isBoosted ? styles.boostedImageContainer : styles.imageContainer])}
          >
            {this.renderStatusOverlayChip()}
            <div></div>
            <LazyLoadImage
              src={backgroundURL}
              alt={listing.get('name')}
              height="100%"
              width={imageWidth}
              className={styles.listingImage}
            />
          </div>

          <div className={styles.cardHeader} onClick={listing.get('inactive') ? null : onClick}>
            {userIsSeller ? <div className={styles.yourListing}>Your Listing</div> : <div></div>}
            {this.renderBookmark(isMobile, stroke, styles.cardBookmark)}
          </div>
          <div className={styles.listingDetails}>
            <div className={styles.detailsContainer}>
              <div className={styles.flexDetails} data-testid="listing" data-listingid={listing.get('id')}>
                <div className={styles.listingName}>{name}</div>
                <div className={styles.listingInfo}>
                  <div className={styles.cardDetailsBreed}>{breed}</div>
                  <div>{location ? location.replace(', US', '') : ''}</div>
                  <div className={styles.sexAge}>
                    {isMobile ? formatListviewPuppyBiology(sex, birthdate) : formatPuppyBiology(sex, birthdate)}
                  </div>
                </div>

                <div className={styles.cardDetailsFooter}>
                  {listing.get('inactive') ? null : (
                    <Fragment>
                      <div
                        className={isMobile ? styles.listDetailsPrice : styles.cardDetailsPrice}
                      >{`$${price.toLocaleString()}`}</div>
                      <div className={styles.badgeRow}>
                        {this.renderBoost()}
                        {this.renderVerificationCheckmark()}
                        {this.renderPremierBadge()}
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              {this.renderBookmark(!isMobile, stroke, styles.listCardBookmark, 'rgb(26, 185, 196)', '#fff')}
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
PuppySquare.propTypes = {
  onClick: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  listing: PropTypes.instanceOf(Map),
  hideBookmark: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

PuppySquare.defaultProps = {
  status: 'for_sale',
};

const styles = {
  listingImage: {
    width: '120px',
  },
  cardHeader: props => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2px',
    position: 'absolute',
    [props.theme.breakpoints.mobileLarge]: {
      position: 'relative',
      padding: '1rem',
    },
    zIndex: 10,
  }),
  yourListing: props => ({
    display: 'inline-block',
    padding: '4px',
    lineHeight: 'inherit',
    [props.theme.breakpoints.mobileLarge]: {
      padding: '8px 16px',
      lineHeight: '18px',
    },
    background: '#1AB9C4',
    color: '#ffffff',
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: 'bold',
  }),
  listCardBookmark: props => ({
    position: 'absolute',
    top: '8px',
    right: '5px',
    transform: 'scale(0.75)',
    [props.theme.breakpoints.mobileLarge]: {
      transform: 'scale(1.25)',
    },
    ':hover': {
      '> svg > path': {
        fill:
          !hasTouch() && !props.listing.get('favorite')
            ? 'rgba(255, 255, 255, 1.0)'
            : !hasTouch() && !!props.listing.get('favorite')
            ? props.theme.colors.teal
            : null,
      },
    },
  }),
  cardBookmark: props => ({
    display: 'flex',
    justifyContent: 'end',
    transform: 'scale(1.25)',
    ':hover': {
      '> svg > path': {
        fill:
          !hasTouch() && !props.listing.get('favorite')
            ? 'rgba(255, 255, 255, .5)'
            : !hasTouch() && !!props.listing.get('favorite')
            ? 'rgba(250, 97, 37, .5)'
            : null,
      },
    },
    [props.theme.breakpoints.mobile]: {
      transform: 'scale(1)',
    },
  }),

  card: props => ({
    display: 'inline-block',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1,
    marginBottom: '0px',
    width: '100%',
    border: `1px solid ${props.theme.colors.white}`,
    '&:nth-child(odd)': {
      backgroundColor: props.theme.colors.tan,
    },
    [props.theme.breakpoints.mobileLarge]: {
      borderRadius: '6px',
      height: '280px',
      border: 'none',
    },
    '> a ': {
      position: 'relative',
      display: 'flex',

      [props.theme.breakpoints.mobileLarge]: {
        justifyContent: 'space-between',
        height: '280px',
        flexDirection: 'column',
        color: 'white',
      },
    },
  }),
  boosted: props => ({
    border: `2px solid ${props.theme.colors.boostBorder}`,
  }),
  listingDetails: props => ({
    flexGrow: 1,
    padding: '10px',
    justifyContent: 'center',
    [props.theme.breakpoints.mobileLarge]: {
      flexGrow: 0,
      padding: '1rem',
    },
    position: 'relative',
    zIndex: 10,
  }),
  detailsContainer: {
    height: '100%',
  },
  flexDetails: {
    alignSelf: 'center',
  },
  listingName: props => ({
    fontWeight: 'bold',
    textShadow: 'none',
    marginRight: '35px',
    fontSize: '15px',
    lineHeight: '21px',
    color: '#49372C',
    [props.theme.breakpoints.mobileLarge]: {
      textShadow: '0 1px 2px rgba(0,0,0,0.25)',
      color: '#fff',
      fontSize: '16px',
      lineHeight: 'inherit',
    },
  }),
  cardDetailsBreed: props => ({
    fontStyle: 'normal',
    [props.theme.breakpoints.mobileLarge]: {
      fontStyle: 'italic',
    },
  }),
  listingInfo: props => ({
    fontSize: '15px',
    lineHeight: '21px',
    color: '#49372C',
    [props.theme.breakpoints.mobileLarge]: {
      color: '#fff',
      fontSize: '14px',
    },
  }),
  sexAge: props => ({
    fontWeight: 'bold',
    [props.theme.breakpoints.mobileLarge]: {
      fontWeight: 'normal',
    },
  }),
  badgeRow: {
    marginTop: '3px',
    display: 'flex',
    gap: '8px',
  },
  cardDetailsFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listDetailsPrice: {
    fontWeight: '700',
    backgroundColor: '#1ab9c4',
    color: '#fff',
    fontSize: '12px',
    padding: '4px 6px',
    marginTop: '8px',
    borderRadius: '2px',
  },
  cardDetailsPrice: props => ({
    fontWeight: '700',
    color: props.theme.colors.green,
    fontSize: '18px',
    [props.theme.breakpoints.mobile]: {
      fontSize: 'initial',
    },
  }),

  overlay: props => ({
    width: '25%',
    [props.theme.breakpoints.mobileLarge]: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 1,
      '> div': {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundImage: 'linear-gradient(0deg, rgba(0,0,0,0.50) 0%, rgba(0,0,0,0.00) 100%)',
      },
    },

    '> img ': {
      objectFit: 'cover',
      objectPosition: 'center',
      width: '100%',
    },
  }),
  boostedImageContainer: {
    minWidth: '120px',
  },
  imageContainer: {
    minWidth: '121px',
  },
};

const mapStateToProps = state => ({
  isLoggedIn: isLoggedInSelector(state),
  user: state.user,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const actionCreators = { addFavoriteListing, showAuthenticationModal };

  if (!ownProps.removeFavoriteListing) {
    actionCreators.removeFavoriteListing = removeFavoriteListing;
  }

  return bindActionCreators(actionCreators, dispatch);
};

export default compose(connect(mapStateToProps, mapDispatchToProps), felaConnect(styles), withRouter)(PuppySquare);
