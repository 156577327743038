import { combineReducers } from 'redux';
import { createResponsiveStateReducer } from 'redux-responsive';
import { fromJS, List, Map } from 'immutable';
import { shouldUpdateReducer as shouldUpdate } from '@synapsestudios/fetch-client-goalie';
import cookies from 'js-cookie';
import { getLoadedReducer, getLoadingReducer } from '../common/utils/reducer-utils';

import search from '../views/SearchListings/redux/reducers';
import landingSearch from '../views/BuyerLandingPage/redux/reducers';
import profile from '../views/Profile/redux/reducers';
import listings from '../views/Listings/redux/reducers';
import account from '../views/Account/redux/reducers';
import premiers from '../views/Premiers/redux/reducers';
import messages from '../views/Messages/redux/reducers';
import modal from '../views/Modal/redux/reducers';
import testimonials from '../views/Testimonials/redux/reducers';
import articles from '../views/LearningCenter/redux/reducers';

const token = (state = cookies.get('id_token') || null, action) => {
  switch (action.type) {
    case 'RESET_PASSWORD_SUCCESS':
    case 'SIGN_UP_SUCCESS':
    case 'GET_TOKEN_SUCCESS':
      return action.json.id_token;
    case 'GET_TOKEN_FAILURE':
      return null;
    default:
      return state;
  }
};

const userLoading = getLoadingReducer('GET_USER');
const userLoaded = getLoadedReducer('GET_USER');

const sendEmailVerificationLoading = getLoadingReducer('SEND_EMAIL_VERIFICATION');
const sendEmailVerificationLoaded = getLoadedReducer('SEND_EMAIL_VERIFICATION');

const user = (state = null, action) => {
  switch (action.type) {
    case 'GET_USER_SUCCESS':
    case 'REDEEM_PREMIER_COUPON_SUCCESS':
      return fromJS(action.json);
    case 'DISCONNECT_FACEBOOK_SUCCESS':
    case 'CONNECT_FACEBOOK_SUCCESS':
      return state && state.merge(action.json);
    case 'CREATE_PREMIER_UPDATE_SUCCESS':
      return state.setIn(['premierProfile', 'latestUpdate'], fromJS(action.json));
    case 'DELETE_PREMIER_UPDATE_SUCCESS':
      return state.setIn(['premierProfile', 'latestUpdate'], null);
    case 'SEARCH_PREMIERS_SUCCESS':
    case 'SEARCH_LISTINGS_SUCCESS':
      if (action.location && state && !state.get('formatted_location')) {
        const newUserState = state.merge(action.location);
        return newUserState;
      } else {
        return state;
      }
    case 'SEND_PHONE_VERIFICATION_SUCCESS':
    case 'CONFIRM_PHONE_VERIFICATION_SUCCESS':
    case 'EDIT_USER_SUCCESS':
    case 'UPDATE_NOTIFICATIONS_SUCCESS':
    case 'REPORT_USER_SUCCESS':
      const newState = state.merge(action.json);
      return newState;
    case 'GET_BRAINTREE_TOKEN_SUCCESS':
      const someState = state.merge({ braintree_customer_id: action.json.braintree_customer_id });
      return someState;
    case 'CREATE_SAVED_SEARCH_SUCCESS':
      const newData = fromJS(action.json);
      return state.set('savedSearches', state.get('savedSearches').push(newData));
    case 'REMOVE_SAVED_SEARCH_SUCCESS':
      return state.set(
        'savedSearches',
        state.get('savedSearches').filter(search => search.get('id') !== action.id)
      );
    case 'CONFIRM_EMAIL_VERIFICATION_SUCCESS':
      if (state) {
        return state.set('email_verified', true);
      }
      return state;
    case 'CREATE_PREMIER_PROFILE_SUCCESS':
    case 'EDIT_PREMIER_PROFILE_SUCCESS':
      const stateWithPremier = state.merge({ premierProfile: action.json });
      return stateWithPremier.merge(action.json.user);
    case 'GET_VERIFICATION_STATUS_SUCCESS':
      return state.set('verificationStatus', fromJS(action.json));
    case 'COMPLETE_ID_VERIFICATION_SUCCESS':
      const updatedState = state.set(
        'verificationStatus',
        fromJS({
          status: action.json.status,
          feeWaived: state.getIn(['verificationStatus', 'feeWaived']),
          attemptsRemaining: state.getIn(['verificationStatus', 'attemptsRemaining']) - 1,
          sessionId: state.json.sessionId,
        })
      );
      return updatedState.set('id_verified', action.json.id_verified);
    case 'RESTART_MIGRATION_SUCCESS':
      const newHistoricalState = state.merge(action.json);
      return newHistoricalState;
    default:
      return state;
  }
};

const userVM = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_MEMBERSHIP_VIEW_MODEL_SUCCESS':
      return fromJS(action.json);
    case 'COMPLETE_MIGRATION_SUCCESS':
      const updatedState = state.set('hasCompletedMigration', true);
      return updatedState;
    default:
      return state;
  }
};

const userVMLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_MEMBERSHIP_VIEW_MODEL_REQUEST':
      return true;
    case 'GET_MEMBERSHIP_VIEW_MODEL_SUCCESS':
    case 'GET_MEMBERSHIP_VIEW_MODEL_FAILURE':
    case 'GET_MEMBERSHIP_VIEW_MODEL_ERROR':
      return false;
    default: {
      return state;
    }
  }
};

const userVMLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_MEMBERSHIP_VIEW_MODEL_SUCCESS':
      return true;
    case 'REFRESH_MEMBERSHIP_VIEW_MODEL':
    case 'PURCHASE_MEMBERSHIP_SUCCESS':
    case 'CANCEL_MEMBERSHIP_SUCCESS':
      return false;
    default: {
      return state;
    }
  }
};

const legacyUser = (state = Map({ exists: false }), action) => {
  switch (action.type) {
    case 'GET_LEGACY_USER_SUCCESS':
      return fromJS(action.json);
    case 'GET_LEGACY_USER_REQUEST':
      return Map();
    default:
      return state;
  }
};

const locationInformation = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_LOCATION_INFORMATION_SUCCESS':
      return state.set(action.key, fromJS(action.json));
    default:
      return state;
  }
};

const facebookDisconnectStatus = (state = Map({ submitting: false, success: false, error: false }), action) => {
  switch (action.type) {
    case 'DISCONNECT_FACEBOOK_FAILURE':
      return Map({ submitting: false, error: true, success: false });
    case 'DISCONNECT_FACEBOOK_REQUEST':
      return Map({ submitting: true, error: false, success: false });
    case 'DISCONNECT_FACEBOOK_SUCCESS':
      return Map({ submitting: false, error: false, success: true });
    case 'DISCONNECT_FACEBOOK_RESET':
      return Map({ submitting: false, success: false, error: false });
    default:
      return state;
  }
};

const breedInformation = (state = null, action) => {
  switch (action.type) {
    case 'GET_BREED_SUCCESS':
      return fromJS(action.json);
    case 'GET_RANDOM_BREED_SUCCESS':
      return fromJS(action.json);
    case 'CREATE_FAVORITE_LISTING_REQUEST':
      if (state) {
        const index = state
          .getIn(['matchedListings', 'results'])
          .findIndex(listing => listing.get('id') === action.listing_id);
        if (index !== -1) {
          return state.setIn(['matchedListings', 'results', index, 'favorite'], true);
        }
      }
      return state;
    case 'REMOVE_FAVORITE_LISTING_REQUEST':
      if (state) {
        const index = state
          .getIn(['matchedListings', 'results'])
          .findIndex(listing => listing.get('id') === action.listing_id);
        if (index !== -1) {
          return state.setIn(['matchedListings', 'results', index, 'favorite'], null);
        }
      }
      return state;
    case 'RESET_RANDOM_BREED':
    case 'RESET_BREED':
      return null;
    default:
      return state;
  }
};

const breedInformationLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_BREED_REQUEST':
      return true;
    case 'GET_BREED_FAILURE':
    case 'GET_BREED_SUCCESS':
    case 'RESET_BREED':
      return false;
    default:
      return state;
  }
};

const breedInformationErrors = (state = null, action) => {
  switch (action.type) {
    case 'GET_BREED_REQUEST':
      return null;
    case 'GET_BREED_FAILURE':
      return action.response;
    default:
      return state;
  }
};

const breedsList = (state = null, action) => {
  switch (action.type) {
    case 'GET_BREEDS_SUCCESS':
      return fromJS(action.json.results);
    case 'RESET_BREEDS':
      return null;
    default:
      return state;
  }
};

const breedsListTotal = (state = 0, action) => {
  switch (action.type) {
    case 'GET_BREEDS_SUCCESS':
      return fromJS(action.json.total);
    case 'RESET_BREEDS':
      return 0;
    default:
      return state;
  }
};

const breedsListLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_BREEDS_REQUEST':
      return true;
    case 'GET_BREEDS_FAILURE':
    case 'GET_BREEDS_SUCCESS':
    case 'RESET_BREEDS':
      return false;
    default:
      return state;
  }
};

const breedsAutocomplete = (state = List(), action) => {
  switch (action.type) {
    case 'RESET_BREEDS_AUTOCOMPLETE':
      return List();
    case 'UPDATE_BREEDS_AUTOCOMPLETE_SUCCESS':
      return fromJS(action.json.results);
    default:
      return state;
  }
};

const premiersAutocomplete = (state = List(), action) => {
  switch (action.type) {
    case 'UPDATE_PREMIERS_AUTOCOMPLETE_SUCCESS':
      return fromJS(action.json.results);
    case 'RESET_PREMIERS_AUTOCOMPLETE':
      return List();
    default:
      return state;
  }
};

const coatPatternsLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_COAT_PATTERNS_REQUEST':
      return true;
    case 'GET_COAT_PATTERNS_SUCCESS':
    case 'GET_COAT_PATTERNS_FAILURE':
      return false;
    default:
      return state;
  }
};

const coatPatterns = (state = List(), action) => {
  switch (action.type) {
    case 'GET_COAT_PATTERNS_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const colorsLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_COLORS_REQUEST':
      return true;
    case 'GET_COLORS_SUCCESS':
    case 'GET_COLORS_FAILURE':
      return false;
    default:
      return state;
  }
};

const colors = (state = List(), action) => {
  switch (action.type) {
    case 'GET_COLORS_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const breedGroups = (state = List(), action) => {
  switch (action.type) {
    case 'GET_BREED_GROUPS_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const confirmEmailLoading = (state = true, action) => {
  switch (action.type) {
    case 'CONFIRM_EMAIL_VERIFICATION_FAILURE':
    case 'CONFIRM_EMAIL_VERIFICATION_SUCCESS':
      return false;
    default:
      return state;
  }
};

const errorBarError = (state = '', action) => {
  switch (action.type) {
    case 'EDIT_LISTING_FAILURE':
      return action.json.message;
    case 'SET_ERROR_BAR_MESSAGE':
      return action.message;
    case 'CLEAR_ERROR_BAR':
      return '';
    default:
      return state;
  }
};

const myListings = (state = List(), action) => {
  switch (action.type) {
    case 'GET_MY_LISTINGS_SUCCESS':
      return fromJS(action.json.results);
    case 'GET_MY_LISTINGS_FAILURE':
    case 'RESET_MY_LISTINGS':
      return List();
    default:
      return state;
  }
};

const myListingsTotal = (state = 0, action) => {
  switch (action.type) {
    case 'GET_MY_LISTINGS_SUCCESS':
      return action.json.total;
    case 'GET_MY_LISTINGS_REQUEST':
      return state;
    case 'GET_MY_LISTINGS_FAILURE':
    case 'RESET_MY_LISTINGS':
      return 0;
    default:
      return state;
  }
};

const myListingsLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_MY_LISTINGS_REQUEST':
      return true;
    case 'GET_MY_LISTINGS_SUCCESS':
    case 'GET_MY_LISTINGS_FAILURE':
    case 'RESET_MY_LISTINGS':
      return false;
    default:
      return state;
  }
};

const myListingsLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_MY_LISTINGS_REQUEST':
    case 'RESET_MY_LISTINGS':
    case 'GET_MY_LISTINGS_FAILURE':
      return false;
    case 'GET_MY_LISTINGS_SUCCESS':
      return true;
    default:
      return state;
  }
};

const listingCount = (state = null, action) => {
  switch (action.type) {
    case 'GET_LISTING_COUNT_SUCCESS':
      return action.json;

    default:
      return state;
  }
};

const randomTips = (state = List(), action) => {
  switch (action.type) {
    case 'GET_RANDOM_TIPS_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const contactSupport = (state = { error: false, loaded: false }, action) => {
  switch (action.type) {
    case 'CONTACT_SUPPORT_SUCCESS':
      return {
        error: false,
        loaded: true,
      };
    case 'CONTACT_SUPPORT_FAILURE':
      return {
        error: true,
        loaded: false,
      };
    case 'CONTACT_SUPPORT_RESET':
      return {
        error: false,
        loaded: false,
      };
    default:
      return state;
  }
};

const appReducer = combineReducers({
  browser: createResponsiveStateReducer({
    mobileSmall: 375,
    mobile: 576,
    mobileLarge: 810,
    tablet: 864,
    tabletLarge: 1079,
    desktop: 1152,
  }),
  shouldUpdate,
  token,
  user,
  userVM,
  userVMLoaded,
  userVMLoading,
  legacyUser,
  breedInformation,
  breedInformationLoading,
  breedInformationErrors,
  breedsList,
  breedsListLoading,
  breedsListTotal,
  coatPatternsLoading,
  coatPatterns,
  breedGroups,
  confirmEmailLoading,
  errorBarError,
  breedsAutocomplete,
  search,
  profile,
  myListings,
  myListingsLoading,
  myListingsTotal,
  myListingsLoaded,
  listingCount,
  listings,
  account,
  premiers,
  premiersAutocomplete,
  colors,
  colorsLoading,
  modal,
  messages,
  testimonials,
  articles,
  randomTips,
  facebookDisconnectStatus,
  landingSearch,
  contactSupport,
  userLoading,
  userLoaded,
  sendEmailVerificationLoading,
  sendEmailVerificationLoaded,
  locationInformation,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    state = { browser: state.browser };
  }
  return appReducer(state, action);
};

export default rootReducer;
