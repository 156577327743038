import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ViewPremier from './ViewPremier';
import { getPremierBySlug, deletePremierUpdate, trackPremierWebsiteView } from '../../redux/actions';
import { resetSendMessageToUser } from '../../../Messages/redux/actions';
import { isLoggedInSelector } from '../../../../redux/selectors';
import { getProfileListings } from '../../../Profile/redux/actions';
import { addFavoritePremier, removeFavoritePremier } from '../../../Account/redux/actions';
import { accountAgeSelector } from '../../redux/selectors';
import { setActiveModal, showAuthenticationModal } from '../../../Modal/redux/actions';

function mapStateToProps(state) {
  return {
    user: state.user,
    userVM: state.userVM,
    legacyUser: state.legacyUser,
    isLoggedIn: isLoggedInSelector(state),
    accountAge: accountAgeSelector(state),
    premier: state.premiers.currentPremier,
    loading: state.premiers.currentPremierLoading,
    loaded: state.premiers.currentPremierLoaded && !state.legacyUser.isEmpty(),
    listings: state.profile.listings,
    listingsError: state.profile.listingsError,
    total: state.profile.listingsTotal,
    sendMessageLoaded: state.messages.sendMessageLoaded,
  };
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      getPremierBySlug,
      getProfileListings,
      addFavoritePremier,
      removeFavoritePremier,
      showAuthenticationModal,
      deletePremierUpdate,
      trackPremierWebsiteView,
      resetSendMessageToUser,
      setActiveModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPremier);
