import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import { ButtonLink } from '../../common/components';
import { PopularBreedsFooter } from '../../common/components';
import { Helmet } from 'react-helmet';

class PuppyFind extends Component {
  render() {
    const { styles } = this.props;

    return (
      <div className={styles.container}>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className={styles.pfContainer}>
          <h1 className={styles.logo}>
            Puppy<span className={styles.boldItalic}>Find.</span>com<style className={styles.trademark}>&reg;</style>
          </h1>
          <p className={styles.description}>
            Finding the right puppy can be doggone hard work. PuppyFind® provides a convenient and efficient means of
            selecting and purchasing the perfect puppy from the comfort of your home, 24 hours a day, 7 days a week. Our
            goal is to make finding a puppy as enjoyable as owning one.
          </p>
        </div>
        <div className={styles.cta}>
          <ButtonLink buttonType="largeRoundOrangeOutline" to="/find-a-puppy">
            Find a puppy now!
          </ButtonLink>
        </div>
        <PopularBreedsFooter />
      </div>
    );
  }
}

const styles = props => ({
  container: {
    display: 'flex',
    height: '100%',
    flexFlow: 'column',
  },
  pfContainer: {
    width: '100%',
    backgroundColor: '#3c53a2',
    color: '#fff',
    display: 'flex',
    padding: '96px 0 125px',
    alignItems: 'center',
    flexDirection: 'column',
    flex: '0 1 auto',
  },
  logo: {
    fontSize: '48px !important',
    fontName: 'Open Sans, sans-serif',
    display: 'flex',
  },
  trademark: {
    fontSize: '18px',
    display: 'flex',
  },
  boldItalic: {
    fontWeight: '800',
    fontStyle: 'italic',
  },
  description: {
    fontSize: '18px',
    fontName: 'Open Sans, sans-serif',
    fontWeight: 'medium',
    width: '90%',
    [props.theme.breakpoints.tabletLarge]: {
      width: '45%',
    },
    marginTop: '30px',
    textAlign: 'center',
  },
  cta: {
    display: 'flex',
    flex: '0 1 auto',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '45px 0',
    backgroundColor: '#F8F6F3',
  },
});

export default felaConnect(styles)(PuppyFind);
