import React, { Component } from 'react';
import store from 'store2';
import querystring from 'query-string';

import { Loading, ErrorBar } from '../../common/components';

class AutoLogin extends Component {
  state = {
    error: null,
  };

  componentDidMount() {
    const searchParams = querystring.parse(this.props.location.search);
    const key = searchParams.key;
    const redirect = searchParams.redirect || store.get('pathOnFacebookLogin') || '/';

    // This component will mount twice during the AutoLogin process but should not run the autoLogin action if a token already exists
    if (!this.props.token) {
      this.props.autoLogin(key).then(action => {
        if (action.response.ok) {
          store.remove('pathOnFacebookLogin');
          this.props.history.replace(redirect);
        } else {
          this.setState({ error: true });
        }
      });
    } else {
      this.props.history.replace(redirect);
    }
  }

  render() {
    if (this.state.error) {
      return <ErrorBar message="There was an error while logging in" />;
    }

    return <Loading center dark />;
  }
}

export default AutoLogin;
