import React, { useEffect, useMemo, useState } from 'react';
import { connect as felaConnect } from 'react-fela';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';
import querystring from 'query-string';

import { Loading } from '../../../../common/components';
import CouponSuccessModal from './components/CouponSuccessModal';
import MembershipDetails from './MembershipDetails';
import styles from './styles';
import PurchaseFlow from './PurchaseFlow';
import PaymentHistory from './PaymentHistory';
import { Helmet } from 'react-helmet';
import ApprovalNecessary from './ApprovalNecessary';
const Membership = ({
  redeemCoupon,
  styles,
  user,
  userVM,
  membershipPlans,
  membershipPlansLoaded,
  purchaseMembership,
  checkForRecentTransaction,
  getBraintreeToken,
  getMembershipPlans,
  purchaseErrors,
  payments,
  paymentsLoaded,
  paymentsLoading,
  paymentsPagination,
  cancelMembership,
  getPayments,
  location,
  cancelPendingMembership,
  getUserViewModel,
  getPaymentMethods,
  paymentMethods,
  deletePaymentMethod,
  removedPaymentMethod,
}) => {
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [redeemedCouponType, setRedeemedCouponType] = useState(null);
  const [hasRedeemedCoupon, setHasRedeemedCoupon] = useState(false);
  const [deletingCard, setDeletingCard] = useState(true);
  const queryObject = useMemo(() => querystring.parse(location.search), [location.search]);

  useRedeemCouponOnLoad({
    queryObject,
    redeemCoupon,
    setHasRedeemedCoupon,
    setRedeemedCouponType,
  });

  useEffect(() => {
    const footer = document.getElementById('fs-sticky-footer');
    if (footer) {
      footer.style.display = 'none';
    }
  }, []);

  useEffect(() => {
    getMembershipPlans();
  }, [getMembershipPlans]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  useEffect(() => {
    // Payment method deleted (returns true)
    if (removedPaymentMethod) {
      // Reload payment methods
      getPaymentMethods();
      getUserViewModel(user.get('id'));
    }
    setDeletingCard(false);
  }, [removedPaymentMethod, getPaymentMethods, getUserViewModel, user]);

  const removePaymentMethod = token => {
    setDeletingCard(true);
    deletePaymentMethod(token);
  };

  const { showLoading, dispatchThenRefreshUserVM } = useShowLoading({
    userVM,
    refreshUserVm: () => getUserViewModel(user.get('id')),
    membershipPlansLoaded,
  });

  if (showLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Membership - Puppies.com</title>
      </Helmet>

      {user.get('is_approved_seller') ? (
        <>
          <MembershipDetails
            userVM={userVM}
            styles={styles}
            membershipPlans={membershipPlans}
            onSelectPlan={planId => setSelectedPlanId(planId)}
            onCancelPendingMemberhip={() => dispatchThenRefreshUserVM(cancelPendingMembership)}
            hasRedeemedCoupon={hasRedeemedCoupon}
            cancelMembership={cancelMembership}
            paymentMethods={paymentMethods}
          />
          <PurchaseFlow
            purchaseMembership={purchaseMembership}
            checkForRecentTransaction={checkForRecentTransaction}
            getBraintreeToken={getBraintreeToken}
            purchaseErrors={purchaseErrors}
            styles={styles}
            planId={selectedPlanId}
            membershipPlans={membershipPlans}
            user={user}
            userVM={userVM}
            onRequestCancel={() => {
              getPayments();
              setSelectedPlanId(null);
            }}
          />
          {paymentMethods.size > 0 ? (
            <div className={styles.paymentMethodContainer}>
              <h1>Payment Methods</h1>
              <div className={styles.cardContainer}>
                {deletingCard ? (
                  <div className={styles.deletingCard}>Removing card</div>
                ) : (
                  paymentMethods.map(method => {
                    return (
                      <div className={styles.paymentCard}>
                        <div>
                          <div>Credit Card: {method.get('maskedNumber')}</div>
                          <div>Type: {method.get('cardType')}</div>
                        </div>
                        <button onClick={() => removePaymentMethod(method.get('token'))} className={styles.remove}>
                          Remove this card
                        </button>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <ApprovalNecessary styles={styles} />
      )}

      {user.get('is_approved_seller') ? (
        <PaymentHistory
          styles={styles}
          payments={payments}
          paymentsLoaded={paymentsLoaded}
          paymentsLoading={paymentsLoading}
          paymentsPagination={paymentsPagination}
          getPayments={getPayments}
          location={location}
          userVM={userVM}
          queryObject={queryObject}
        />
      ) : null}
      <CouponSuccessModal
        onClose={() => {
          setRedeemedCouponType(null);
          getUserViewModel(user.get('id'));
        }}
        visible={Boolean(redeemedCouponType)}
        type={redeemedCouponType}
      />
    </div>
  );
};

const useRedeemCouponOnLoad = ({ queryObject, redeemCoupon, setHasRedeemedCoupon, setRedeemedCouponType }) => {
  useEffect(() => {
    if (queryObject.coupon) {
      redeemCoupon(queryObject.coupon);
      setRedeemedCouponType('premium');
      setHasRedeemedCoupon(true);
    } else if (queryObject.premierCoupon) {
      redeemCoupon(queryObject.premierCoupon);
      setRedeemedCouponType('premier');
      setHasRedeemedCoupon(true);
    }
  }, [queryObject, redeemCoupon, setHasRedeemedCoupon, setRedeemedCouponType]);
};

const useShowLoading = ({ userVM, refreshUserVm, membershipPlansLoaded }) => {
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  function dispatchThenRefreshUserVM(action) {
    setIsRefreshing(true);
    return action()
      .then(() => refreshUserVm())
      .then(() => setIsRefreshing(false))
      .catch(() => setIsRefreshing(false));
  }

  return { showLoading: !userVM || !membershipPlansLoaded || isRefreshing, dispatchThenRefreshUserVM };
};

export default compose(felaConnect(styles), withRouter)(Membership);
