import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import compose from 'lodash/flowRight';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

class Square extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    style: PropTypes.object,
  };

  render() {
    const { onClick, styles, style, className, adMargin } = this.props;

    return (
      <div
        className={classNames([styles.root, className && className, adMargin ? styles.adMargin : styles.nonAdMargin])}
        onClick={onClick}
        style={style}
      >
        {this.props.children}
      </div>
    );
  }
}

const styles = {
  root: props => ({
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    overflow: 'hidden',
    height: '400px',
    width: '100%',
    borderRadius: '0',
    [props.theme.breakpoints.mobile]: {
      height: '280px',
      borderRadius: '6px',
    },
  }),
  adMargin: {
    margin: '1px 0',
  },
  nonAdMargin: {
    margin: '0 0 4px',
  },
};

export default compose(felaConnect(styles), withRouter)(Square);
