import React from 'react';

function PuppyWithCrown() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M7.11 21.106c-.017-.554.103-1.172.194-1.735.356-2.237 1.384-4.218 2.923-5.382-.752-.403-1.3-1.209-1.69-2.39-.758-2.288.098-4.595 2.39-5.35 2.103-.695 3.73-.133 4.57 1.733.121-.018.25-.032.388-.039a.584.584 0 1 1 1.092.41c.756.854 1.394 2.933-1.374 5.022 1.442.858 1.743 2.885 3.147 6.176 1.201 2.816 1.813 3.324.557 3.341-.958.013-2.814-3.943-2.814-3.943.141 2.519-1.803 2.769-1.803 2.769 3.038-.034 2.55 1.32 2.55 1.32a19.609 19.609 0 0 1-3.878.295c-1.527-.032-3.53-.277-4.695-.554-1.38.075-4.18-.138-4.15-3.105 0 0 1.285 1.626 2.593 1.432" />
      <path d="M10.42 5.66c-1.515.499-2.396 1.681-2.597 3.084-.092.105-.187.207-.276.314L4 6.138a16.626 16.626 0 0 1 2.795-.324c.068-.906.215-1.83.446-2.765a16.95 16.95 0 0 1 2.787.339c.278-.864.64-1.732 1.087-2.588l1.831 4.208c-.267.116-.531.24-.79.371-.532-.017-1.112.075-1.736.28" />
    </svg>
  );
  /* eslint-enable */
}

export default PuppyWithCrown;
