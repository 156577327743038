import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import { matchPath } from 'react-router';
import localStorage from 'store2';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';
import compose from 'lodash/flowRight';
import { TextInput, Button, PlainLink, Modal } from '../../common/components';
import { withRouter } from 'react-router-dom';

class LoginForm extends Component {
  componentWillUnmount() {
    this.props.clearError();
  }
  render() {
    const { handleSubmit, isSubmitting, styles, status, setStatus } = this.props;
    return (
      <div>
        <form id="sign-in-form" onSubmit={handleSubmit}>
          <div className={styles.form}>
            <Field
              label="Email Address"
              labelVariant="secondary"
              autoComplete="email"
              name="email"
              type="email"
              component={TextInput}
              fixederror
            />
            <Field
              label="Password"
              labelVariant="secondary"
              name="password"
              type="password"
              autoComplete="current-password"
              component={TextInput}
              fixederror
            />
            <div className={styles.agreements}>
              By signing in to your Puppies.com account, you affirm that you have read, understand, and agree to the{' '}
              <PlainLink variant="blue" to="/tos">
                Terms of Service
              </PlainLink>
              .
            </div>
            <Button type="submit" buttonType="primaryLarge" fullWidth disabled={isSubmitting}>
              Sign In
            </Button>
          </div>
        </form>
        <Modal
          isOpen={status && status.showContactSupportModal ? true : false}
          onClose={() => setStatus({ showContactSupportModal: false })}
          closeOnOutsideClick={true}
          closeOnEscape={true}
        >
          <div className={styles.supportModalTitle}>Oops, something isn't right with your account</div>
          <div className={styles.supportModalBody}>
            There is a problem with your account. Please contact support to resolve the issue.
          </div>
        </Modal>
      </div>
    );
  }
}

const FormikLoginForm = withFormik({
  mapPropsToValues: () => ({ email: '', password: '' }),
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .trim()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: yup.string().required('Password is required'),
  }),
  handleSubmit: async (values, { props, setSubmitting, setStatus, setErrors }) => {
    setStatus({});
    const action = await props.submitAction(values.email, values.password);
    if (!action?.response.ok) {
      setSubmitting(false);
      if (action?.json && action?.json.message === 'contact customer service') {
        setStatus({ showContactSupportModal: true });
      } else {
        props.setError(action?.json ? action.json.message : 'An unknown error occurred');
      }
    } else {
      const matchProfileId = matchPath(props.location.pathname, {
        path: '/profile/:profileId',
        exact: true,
        strict: false,
      });
      const matchPremierId = matchPath(props.location.pathname, {
        path: '/premier/:premierId',
        exact: true,
        strict: false,
      });

      const isOnSellerProfile = !!matchProfileId || !!matchPremierId;

      if (isOnSellerProfile && localStorage('showTestimonialModal')) {
        props.history.push(props.location.pathname);
      }
    }
  },
})(LoginForm);

const styles = props => ({
  form: {
    '> * + *': {
      marginTop: '28px',
    },
    '> button': {
      marginTop: '40px',
    },
  },
  agreements: {
    color: props.theme.colors.darkerGray,
    lineHeight: '21px',
    fontSize: '14px',
    margin: '24px 0 0',
    textAlign: 'center',
    [props.theme.breakpoints.tablet]: {
      textAlign: 'left',
    },
  },
  supportModalTitle: {
    maxWidth: '80%',
    color: props.theme.colors.red,
    fontSize: '26px',
    fontWeight: 'bold',
    lineHeight: '32px',
    margin: '40px auto 0px',
    wordBreak: 'break-word',
  },
  supportModalBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px 40px',
  },
});

export default compose(felaConnect(styles), connect(), withRouter)(FormikLoginForm);
