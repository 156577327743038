import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getBraintreeToken, purchaseBoostingAction } from '../../../views/Account/redux/actions';
import { setErrorBarMessage, clearErrorBar, getUploadToken, getUser, getMyListings } from '../../../redux/actions';

import boostWizard from './BoostWizard';

const mapStateToProps = state => {
  return {
    user: state.user,
    userVM: state.userVM,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setErrorBarMessage,
      clearErrorBar,
      getUploadToken,
      getBraintreeToken,
      getUser,
      purchaseBoostingAction,
      getMyListings,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(boostWizard);
