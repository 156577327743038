import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import sortBy from 'lodash/sortBy';
import humanizeDate from '../../../common/utils/humanizeDate';
import isUnread from '../../Messages/utils/is-unread.js';

export const listingSelector = state => state.listings.currentListing;
export const messageSelector = state => state.messages.conversations;
export const userSelector = state => state.user;

export const accountAgeSelector = createSelector(listingSelector, listing => {
  return listing && humanizeDate(listing.getIn(['user', 'created_at']), 'en-profiles');
});

export const listingNewMessagesSelector = createSelector(messageSelector, userSelector, (conversations, user) => {
  const listingMessages = [];
  const conversationGroups = conversations.get('inbox');
  if (conversationGroups) {
    const listingConversationGroups = conversationGroups.filter(group => group.get('listing_id'));

    listingConversationGroups.forEach(group => {
      const allMessages = [];
      const listing = {
        listing_id: group.get('listing_id'),
        messages: [],
        newestConversationId: null,
      };
      if (!group.get('conversations')) {
        return null;
      }
      group.get('conversations').forEach(convo => {
        listing.messages.push(
          ...convo.get('messages').filter(message => isUnread(message, user, group.get('listing')))
        );
        allMessages.push(...convo.get('messages'));
      });

      const sortedNewMessages = sortBy(listing.messages, message => message.get('created_at')).reverse();

      if (sortedNewMessages.length) {
        listing.newestConversationId = sortedNewMessages[0].get('conversation_id');
      } else if (allMessages.length) {
        const sortedMessages = sortBy(allMessages, message => message.get('created_at')).reverse();
        listing.newestConversationId = sortedMessages[0].get('conversation_id');
      }

      listingMessages.push(listing);
    });
  }
  return fromJS(listingMessages);
});
