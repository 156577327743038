import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Membership from './Membership';
import {
  purchaseMembership,
  checkForRecentTransaction,
  getBraintreeToken,
  getPayments,
  cancelMembership,
  cancelPendingMembership,
  resetPaymentHistory,
  redeemCoupon,
  getMembershipPlans,
  getPaymentMethods,
  deletePaymentMethod,
} from '../../redux/actions';
import { getUserViewModel } from '../../../../redux/actions';

const mapStateToProps = state => {
  return {
    user: state.user,
    userVM: state.userVM,
    membershipPlans: state.account.membershipPlans,
    membershipPlansLoaded: state.account.membershipPlansLoaded,
    payments: state.account.payments,
    paymentsLoaded: state.account.paymentsLoaded,
    paymentsLoading: state.account.paymentsLoading,
    paymentsPagination: state.account.paymentsPagination,
    purchaseErrors: state.account.purchaseErrors,
    paymentMethods: state.account.paymentMethods,
    removedPaymentMethod: state.account.removedPaymentMethod,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPayments,
      purchaseMembership,
      checkForRecentTransaction,
      cancelMembership,
      getBraintreeToken,
      resetPaymentHistory,
      redeemCoupon,
      cancelPendingMembership,
      getUserViewModel,
      getMembershipPlans,
      getPaymentMethods,
      deletePaymentMethod,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Membership);
