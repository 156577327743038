import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

import { CircleAvatar } from '../../../common/components';

const Photo = SortableElement(({ handleDelete = () => {}, handleEdit = () => {}, photo = {}, photoMap, sortIndex }) => {
  return (
    <CircleAvatar
      key={sortIndex}
      croppedImageUrl={photo.croppedImage}
      editMode
      imageUrl={photo.value}
      mainPhoto={sortIndex === 0}
      photo={photoMap}
      photoTitle={photo.title}
      size={150}
      handleCrop={(image, title, hasCropped) => {
        return handleEdit({ hasCropped, image, sortIndex, title });
      }}
      handleDelete={() => {
        return handleDelete({ photoMap, sortIndex });
      }}
    />
  );
});

export default Photo;
