import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-fela';
import {
  Hero,
  PuppyList,
  LandingPageFooter,
  StartTrialModal,
  LandingPageCopy,
  PopularBreedsFooter,
  Footer,
  Icon,
  TopNav,
} from '../../common/components';

import { searchListings } from './redux/actions';
import theme from '../../theme';

const copy = [
  {
    title: 'Find Your Puppy, Completely Free!',
    description: (
      <Fragment>
        Finding your puppy is completely free, easy, and it only takes a few minutes to <a href="/sign-up">sign up</a>.
      </Fragment>
    ),
    imagePosition: 'left',
  },
  {
    title: 'The Perfect Pup',
    description:
      'Since 2003, Puppies.com has been linking people like you to the perfect puppy, from sellers across the country.',
    imagePosition: 'right',
  },
  {
    title: 'Discover the right breed for you',
    description: (
      <Fragment>
        With our <Link to="/breed-matchmaker">breed matchmaker</Link>, find the perfect breed that fits all your
        lifestyle and personality needs.
      </Fragment>
    ),
    imagePosition: 'left',
  },
];

class BuyerLandingPage extends Component {
  state = {
    showTrialModal: false,
  };

  static fetchData(location, match, user) {
    return [searchListings({ page_size: 8, has_photo: false, premiers_only: false, ignore_legacy: true })];
  }

  componentDidMount() {
    const { loaded, match, location, user, dispatch, error } = this.props;

    if (!loaded || error) {
      BuyerLandingPage.fetchData(location, match, user).forEach(action => dispatch(action));
    }
  }

  handleTrialStart = () => {
    this.props.history.push({ pathname: '/sign-up', state: { purchaseType: 'trial' } });
  };

  render() {
    const { listings, styles, isLoggedIn, user, userVM } = this.props;
    return (
      <div>
        <TopNav
          isSeller={user && user.get('listings').size > 0}
          user={user}
          userVM={userVM}
          isLoggedIn={isLoggedIn}
          errorBarError={''}
          messageCount={''}
        />
        <Hero
          headline="Add a little wiggle to your life!"
          registered
          buttonLeft={
            <button className={styles.roundWhite} onClick={() => this.setState({ showTrialModal: true })}>
              <Icon size={14} icon="FatAdd" />
              LIST A PUPPY
            </button>
          }
          buttonRight={
            <Link to="/sign-in" className={styles.roundWhite}>
              <Icon size={14} icon="Avatar" />
              SIGN IN
            </Link>
          }
          calloutAction={
            <Link to="/find-a-puppy" className={styles.largeRoundOrangeOutline}>
              FIND A Puppy
            </Link>
          }
        />
        <a
          aria-label="Scroll to Puppy Listings"
          href="#arrowpups"
          name="arrowpups"
          style={{ position: 'absolute', marginLeft: '-50%', color: '#F8F6F3', zIndex: '10000' }}
        >
          puppies
        </a>
        <div className={styles.tpSection}>
          <div className={styles.container}>
            <div
              className="trustpilot-widget"
              data-locale="en-US"
              data-template-id="53aa8912dec7e10d38f59f36"
              data-businessunit-id="5f0633436d8c8600014e68af"
              data-style-height="128px"
              data-style-width="100%"
              data-theme="light"
              data-stars="4,5"
              data-review-languages="en"
            >
              <a href="https://www.trustpilot.com/review/puppies.com" target="_blank" rel="noopener noreferrer">
                Trustpilot
              </a>
            </div>
          </div>
        </div>
        <h1 className={styles.copy}>Puppies for sale from across the USA!</h1>
        <PuppyList headerText="Puppies for sale from across the USA!" listings={listings} noAds={true} />
        <div className={styles.youtubeEmbed}>
          <iframe
            src="https://www.youtube.com/embed/7D5Mv0UabqA?si=MNoJjNWmAGWsYewf"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <LandingPageCopy sections={copy} headerText="Why choose Puppies.com?" />
        <LandingPageFooter />
        <PopularBreedsFooter />
        <Footer isLoggedIn={false} />
        <StartTrialModal
          isOpen={this.state.showTrialModal}
          onClose={() => {
            this.setState({ showTrialModal: false });
          }}
          onSubmit={this.handleTrialStart}
          closeOnEscape
          closeOnOutsideClick
        />
      </div>
    );
  }
}

const styles = props => ({
  roundWhite: {
    ...theme.linkStyles.roundWhite,
    background: 'white',
    color: theme.colors.blue,
    ':hover': {
      background: props.theme.colors.orange,
      color: props.theme.colors.white,
    },
  },
  largeRoundOrangeOutline: {
    ...theme.linkStyles.largeRoundOrangeOutline,
    width: '240px',
    height: '45px',
  },
  tpSection: {
    ...props.theme.globalBackgroundContainer,
  },
  container: {
    position: 'relative',
    background: 'white',
    margin: '0 auto',
    maxWidth: '1108px',
    marginTop: '-84px',
    padding: '20px',
    // borderRadius: '6px',
    // boxShadow: '0 1px 3px 0 rgba(0,0,0,0.24)',
  },
  copy: {
    ...props.theme.globalBackgroundContainer,
    textAlign: 'center',
    fontSize: '28px',
    lineHeight: '32px',
    padding: '42px 0',
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltBold,
    color: props.theme.colors.orange,
  },
  youtubeEmbed: {
    margin: '40px auto 0',
    position: 'relative',
    width: '100%',
    maxWidth: '1108px',
    height: '315px',
    minHeight: '315px',
    '@media all and (min-width: 805px)': {
      height: '400px',
    },
    '@media all and (min-width: 1080px)': {
      height: '600px',
    },
    '> iframe': {
      margin: '0 40px',
      position: 'absolute',
      top: '0',
      left: '0',
      width: 'calc(100% - 80px)',
      height: '100%',
    },
  },
});
export default connect(styles)(BuyerLandingPage);
