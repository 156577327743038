import React from 'react';

function Go() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M16.5 16.836l3-2.05v3.222c0 1.64-1.36 3-3 3H3c-1.64 0-3-1.36-3-3v-12c0-1.64 1.36-3 3-3h8.496v1.219c-1.805.281-3.328.855-4.605 1.781H3v12h13.5v-1.172zm7.277-8.25c.141.117.223.246.223.422 0 .164-.082.328-.223.41l-9.504 6.504c-.082.059-.164.082-.27.082-.081 0-.175-.023-.222-.058-.176-.082-.281-.247-.281-.446v-3.527c-6.105.14-7.559 2.285-7.582 2.285-.082.164-.246.246-.422.246h-.105c-.223-.058-.364-.246-.387-.469 0-.117-.2-3.175 1.992-5.472 1.477-1.582 3.668-2.45 6.504-2.531V2.504c0-.199.105-.363.281-.445.14-.082.364-.082.492.023l9.504 6.504z" />
    </svg>
  );
  /* eslint-enable */
}

export default Go;
