import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import Article from '../common/Article';
import { getArticleById, resetArticles } from '../redux/actions';
import { Loading, ContentNotFound } from '../../../common/components';

class ViewArticle extends Component {
  static propTypes = {
    article: PropTypes.instanceOf(Map),
    loading: PropTypes.bool,
  };

  static fetchData(location, match, user) {
    const query = location.search;

    return [getArticleById(match.params.articleId, query)];
  }

  componentDidMount() {
    const { location, match, user, dispatch, loaded } = this.props;

    if (!loaded) {
      ViewArticle.fetchData(location, match, user).forEach(action => dispatch(action));
    }
  }

  componentDidUpdate(prevProps) {
    const { location, match, user, dispatch, loaded } = this.props;

    const locationChanged = prevProps.location !== location;
    const loadedStatusChanged = prevProps.loaded && !loaded;
    if (locationChanged || loadedStatusChanged) {
      ViewArticle.fetchData(location, match, user).forEach(action => dispatch(action));
    }
  }

  render404() {
    return (
      <ContentNotFound
        icon="PuppyOutline"
        to="/blog"
        buttonText="Blog"
        title="Sorry, we couldn't find the page you were looking for."
      />
    );
  }

  componentWillUnmount() {
    this.props.dispatch(resetArticles());
  }

  renderSchema = () => {
    const { article } = this.props;
    const url = window.location.href;
    let description = '';
    if (article.get('body')) {
      description = article.get('body').slice(0, 170);
    }
    let title = 'Blog | Puppies.com';
    if (article.get('title')) {
      title = `${article.get('title')} | ${title}`;
    }
    return (
      <Helmet>
        <title>{title}</title>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            author: 'Puppies.com',
            datePublished: moment(article.get('publishDate')).format('MM/DD/YYYY'),
            dateModified: moment(article.get('updatedAt')).format('MM/DD/YYYY'),
            headline: article.get('title'),
            articleSection: article.get('title'),
            articleBody: description,
            publisher: {
              '@type': 'Organization',
              name: 'Puppies.com, LLC',
            },
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': typeof window !== 'undefined' && window.location.href,
            },
            image: {
              '@type': 'ImageObject',
              url: article.getIn(['image', 'fields', 'file', 'url']),
            },
          })}
        </script>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={article.get('title')} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={`https:${article.getIn(['image', 'fields', 'file', 'url'])}`} />
        <meta name="title" content={article.get('title')} />
        <meta name="description" content={description} />
        <link rel="canonical" href={url} />
      </Helmet>
    );
  };

  render() {
    const { article, match, loading, loaded, error } = this.props;

    if (loading) {
      return <Loading center dark />;
    } else if (loaded && !!error) {
      return this.render404();
    }

    return (
      <div>
        {this.renderSchema()}
        <Article article={article} id={match.params.articleId} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.articles.loading,
  article: state.articles.currentArticle,
  loaded: state.articles.currentArticle.get('loaded'),
  error: state.articles.getError,
});

export default connect(mapStateToProps)(ViewArticle);
