import React from 'react';
import Button from '../Button';

function RequestMoreVerificationButton({ userLoading, onClick, smallOrange, contactText }) {
  return (
    <Button buttonType={smallOrange ? 'smallOrange' : 'round'} disabled={userLoading} onClick={onClick}>
      {contactText}
    </Button>
  );
}

export default RequestMoreVerificationButton;
