import React from 'react';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';

import { Button, TextInput, InputError } from '../../common/components';
import equalTo from '../../common/utils/yupEqualTo';

yup.addMethod(yup.string, 'equalTo', equalTo);

const ResetPasswordForm = ({ status, handleSubmit, isSubmitting, styles }) => (
  <form id="reset-password-form" onSubmit={handleSubmit}>
    <div className={styles.inputs}>
      <div className={styles.inputWrapper}>
        <Field label="Password" name="password" type="password" autoComplete="new-password" component={TextInput} />
      </div>
      <div className={styles.inputWrapper}>
        <Field
          label="Confirm Password"
          name="password_confirmation"
          type="password"
          autoComplete="new-password"
          component={TextInput}
        />
      </div>
    </div>
    <div className={styles.buttonWrapper}>
      {status && status.error && <InputError>{status.error}</InputError>}
      <Button type="submit" buttonType="roundOrange" disabled={isSubmitting}>
        RESET PASSWORD
      </Button>
    </div>
  </form>
);

const FormikResetPasswordForm = withFormik({
  mapPropsToValues: () => ({ password: '', password_confirmation: '' }),
  validationSchema: yup.object().shape({
    password: yup
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .max(255, 'Password may not exceed 255 characters')
      .required('Password is required'),
    password_confirmation: yup
      .string()
      .equalTo(yup.ref('password'), 'Passwords must match')
      .required('Confirm your new password'),
  }),
  handleSubmit: async (values, { props, setSubmitting, setStatus, setErrors }) => {
    setStatus({});
    const action = await props.submitAction(props.tokenId, values);
    if (!action.response.ok) {
      setSubmitting(false);
      setStatus({
        error: action.json ? action.json.message : 'An unknown error occurred',
      });
    }
  },
})(ResetPasswordForm);

const styles = props => ({
  inputWrapper: {
    [props.theme.breakpoints.mobile]: {
      width: '182px',
    },
  },
  inputs: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    '> * + *': {
      marginTop: '20px',
    },
    [props.theme.breakpoints.mobile]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      '> * + *': {
        marginTop: 0,
        marginLeft: '20px',
      },
    },
  },
  buttonWrapper: {
    borderTop: props.theme.globalDashedBorder,
    paddingTop: '28px',
    margin: '50px 0 30px',
  },
});

export default connect()(felaConnect(styles)(FormikResetPasswordForm));
