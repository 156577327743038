import React, { useEffect, useState } from 'react';
import { connect as felaConnect } from 'react-fela';

export const popularBreeds = [
  { name: 'Cavalier King Charles Spaniel', slug: 'cavalier-king-charles-spaniel' },
  { name: 'Australian Shepherd', slug: 'australian-shepherd' },
  { name: 'Havanese', slug: 'havanese' },
  { name: 'Yorkshire Terrier', slug: 'yorkshire-terrier' },
  { name: 'Boston Terrier', slug: 'boston-terrier' },
  { name: 'Maltese', slug: 'maltese' },
  { name: 'Labrador Retriever', slug: 'labrador-retriever' },
  { name: 'English Bulldog', slug: 'english-bulldog' },
  { name: 'Jack Russell Terrier', slug: 'jack-russell-terrier' },
  { name: 'Mastiff', slug: 'mastiff' },
  { name: 'Poodle', slug: 'poodle' },
  { name: 'Cocker Spaniel', slug: 'cocker-spaniel' },
  { name: 'Dachshund', slug: 'dachshund' },
  { name: 'Saint Bernard', slug: 'saint-bernard' },
  { name: 'Golden Retriever', slug: 'golden-retriever' },
  { name: 'West Highland White Terrier', slug: 'west-highland-white-terrier' },
  { name: 'Weimaraner', slug: 'weimaraner' },
  { name: 'Pug', slug: 'pug' },
  { name: 'Lhasa Apso', slug: 'lhasa-apso' },
  { name: 'German Shepherd Dog', slug: 'german-shepherd-dog' },
  { name: 'Cairn Terrier', slug: 'cairn-terrier' },
  { name: 'Doberman Pinscher', slug: 'doberman-pinscher' },
  { name: 'Brittany', slug: 'brittany' },
  { name: 'Collie', slug: 'collie' },
  { name: 'Miniature Schnauzer', slug: 'miniature-schnauzer' },
  { name: 'Akita', slug: 'akita' },
  { name: 'Chesapeake Bay Retriever', slug: 'chesapeake-bay-retriever' },
  { name: 'Rottweiler', slug: 'rottweiler' },
  { name: 'Chinese Shar-Pei', slug: 'chinese-sharpei' },
  { name: 'Shetland Sheepdog', slug: 'shetland-sheepdog' },
  { name: 'Shih Tzu', slug: 'shih-tzu' },
  { name: 'English Springer Spaniel', slug: 'english-springer-spaniel' },
  { name: 'American Pit Bull Terrier', slug: 'american-pit-bull-terrier' },
  { name: 'Pomeranian', slug: 'pomeranian' },
  { name: 'German Shorthaired Pointer', slug: 'german-shorthaired-pointer' },
  { name: 'Pembroke Welsh Corgi', slug: 'pembroke-welsh-corgi' },
  { name: 'Great Dane', slug: 'great-dane' },
  { name: 'Beagle', slug: 'beagle' },
  { name: 'Chihuahua', slug: 'chihuahua' },
  { name: 'Siberian Husky', slug: 'siberian-husky' },
  { name: 'Bichon Frise', slug: 'bichon-frise' },
  { name: 'Boxer', slug: 'boxer' },
  { name: 'Papillon', slug: 'papillon' },
  { name: 'Pekingese', slug: 'pekingese' },
  { name: 'Basset Hound', slug: 'basset-hound' },
];

export const MAX_BREED_COUNT = 25;

const PopularBreedsFooter = ({ styles }) => {
  const [breeds, setBreeds] = useState([]);

  useEffect(() => {
    const shuffledBreeds = popularBreeds.sort(function() {
      return 0.5 - Math.random();
    });
    const selectedBreeds = shuffledBreeds.slice(0, MAX_BREED_COUNT);
    setBreeds(selectedBreeds);
  }, []);
  const renderBreedLinks = () => {
    const links = [];
    for (let i = 0; i < breeds.length; ++i) {
      const popularBreed = breeds[i];
      links.push(
        <div key={popularBreed.slug} className={styles.popularBreedsItem}>
          <a href={`/find-a-puppy/${popularBreed.slug}`}>{popularBreed.name}</a>
        </div>
      );
    }
    return links;
  };

  return (
    <div className={styles.root}>
      <div className={styles.popularBreedsContainer}>
        <div className={styles.titleBar}>
          <h1 className={styles.titleText}>Popular Breeds</h1>
        </div>

        <div className={styles.popularBreedsGrid}>{renderBreedLinks()}</div>
      </div>
    </div>
  );
};

const styles = {
  root: props => ({
    backgroundColor: props.theme.colors.darkerBlue,
  }),
  popularBreedsContainer: props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
    padding: '40px 0',
  }),
  titleBar: props => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }),
  titleText: props => ({
    ...props.theme.typography.titleText,
    color: props.theme.colors.white,
    marginBottom: '32px',
  }),
  popularBreedsGrid: props => ({
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: '0 16px',

    [props.theme.breakpoints.mobile]: {
      gridTemplateColumns: 'repeat(5, 1fr)',
      padding: '0',
    },
  }),
  popularBreedsItem: props => ({
    textAlign: 'left',
    margin: '8px 0',
    padding: '0 0 0 16px',
    [props.theme.breakpoints.tablet]: {
      textAlign: 'center',
      padding: '8px 24px',
    },
    '> a': {
      color: 'white',
      textDecoration: 'none',
      textAlign: 'center',
      padding: '0',
    },
  }),
};

export default felaConnect(styles)(PopularBreedsFooter);
