import React from 'react';
import { Helmet } from 'react-helmet';

import helpers from './helpers';

function HelpScout() {
  return (
    <div
      onClick={() => {
        if (typeof window !== 'undefined') {
          window.Beacon('open');
        }
      }}
      style={{ cursor: 'pointer' }}
    >
      <Helmet>
        <script type="text/javascript">{helpers.declare}</script>
        <script type="text/javascript">{helpers.init}</script>
        <script type="text/javascript">{helpers.config}</script>
      </Helmet>
      Contact
    </div>
  );
}

export default HelpScout;
