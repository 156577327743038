import React from 'react';

function VideoChat() {
  /* eslint-disable max-len */
  return (
    <svg width="80px" height="72px" viewBox="0 0 80 72" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72,0 L8,0 C3.5838009,0.0050148747 0.0050148747,3.5838009 0,8 L0,52 C0.00550966807,56.4159938 3.58400619,59.9944903 8,60 L24,60 L24,70 C24,70.7375905 24.4059695,71.4153106 25.0562843,71.7633484 C25.7065991,72.1113863 26.4956875,72.0732414 27.1094,71.6641 L44.6055,60 L72,60 C76.4159938,59.9944903 79.9944903,56.4159938 80,52 L80,8 C79.9949851,3.5838009 76.4161991,0.0050148747 72,0 Z M59.9999996,37.9999996 C59.9994482,39.1043409 59.1043409,39.9994482 57.9999996,39.9999996 C57.7848307,40.000279 57.5710682,39.9653163 57.3672,39.8965 L47.9999996,36.7742 L47.9999996,41.9999996 C48.0003186,42.5305306 47.7897072,43.0394236 47.4145654,43.4145654 C47.0394236,43.7897072 46.5305306,44.0003186 45.9999996,43.9999996 L21.9999996,43.9999996 C21.4694694,44.0003186 20.9605764,43.7897072 20.5854346,43.4145654 C20.2102928,43.0394236 19.9999996,42.5305306 19.9999996,41.9999996 L19.9999996,17.9999996 C19.9999996,17.4694694 20.2102928,16.9605764 20.5854346,16.5854346 C20.9605764,16.2102928 21.4694694,15.9996814 21.9999996,15.9999996 L45.9999996,15.9999996 C46.5305306,15.9996814 47.0394236,16.2102928 47.4145654,16.5854346 C47.7897072,16.9605764 48.0003186,17.4694694 47.9999996,17.9999996 L47.9999996,23.2258 L57.3672,20.1035 C57.9760934,19.8949087 58.6484953,19.9970311 59.168,20.377 C59.6903504,20.7525914 59.9999996,21.3566348 59.9999996,21.9999996 L59.9999996,37.9999996 Z"
        id="Shape"
      />
      <polygon points="48 32.559 56 35.225 56 24.775 48 27.441" />
      <rect id="Rectangle" x="24" y="20" width="20" height="20" />
    </svg>
  );
  /* eslint-enable */
}

export default VideoChat;
