import React from 'react';
import { Field } from 'formik';

import { Autocomplete, Chip, Dropdown, GeoComplete, RangeSlider } from '../../../common/components';

import {
  BREED_OPTIONS,
  DISTANCE_OPTIONS,
  SEARCH_BY_OPTIONS,
  SEX_OPTIONS,
  STATE_OPTIONS,
} from '../common/search-config';

import {
  BreedContainerRow,
  FieldContainer,
  FormContainer,
  FormRow,
  HelpText,
  MobileUpdateButton,
  SearchContainer,
} from './styled';

const AdvancedSearchListingsFormMobile = ({
  breeds = [],
  handleBreedRemove,
  handleBreedSelect,
  handleBreedPlaceholder,
  handleInputChange,
  handleSubmit,
  isSubmitting,
  setGeoCompleteTouched = () => {},
  values = {},
}) => {
  return (
    <SearchContainer isAdvanced sideMargin="-16">
      <form id="advanced-search-listings-form-mobile" onSubmit={handleSubmit}>
        <FormContainer>
          <FieldContainer flex="1">
            <FormRow>
              <FieldContainer flex="1">
                <Field
                  insetLabel
                  name="breed"
                  render={fieldProps => {
                    return (
                      <Autocomplete
                        insetLabel
                        items={breeds.filter(breed => values?.breed && !values?.breed?.includes?.(breed.id))}
                        key={Array.isArray(values.breed) ? values.breed.join(',') : values.breed}
                        label="Breed"
                        placeholder={handleBreedPlaceholder}
                        onInputChange={handleInputChange}
                        onSelect={handleBreedSelect}
                        {...fieldProps}
                      />
                    );
                  }}
                />
              </FieldContainer>
            </FormRow>
            <FormRow>
              <BreedContainerRow flex="1">
                <HelpText>Selected Breeds</HelpText>
                {Array.isArray(values.formatted_breed) && values.formatted_breed.length > 0 ? (
                  values.formatted_breed.map((breed, i) => (
                    <Chip
                      data-testid={`selected-breed-${values.breed[i]}`}
                      id={values.breed[i]}
                      key={values.breed[i]}
                      variant="secondary"
                      onRemove={handleBreedRemove}
                    >
                      {breed}
                    </Chip>
                  ))
                ) : (
                  <Chip>All Breeds</Chip>
                )}
              </BreedContainerRow>
            </FormRow>
            <FormRow>
              <FieldContainer flex="1">
                <Field
                  component={Dropdown}
                  id="search_ by"
                  name="search_by"
                  options={SEARCH_BY_OPTIONS}
                  variant="secondary"
                />
              </FieldContainer>
              {values.search_by === 'city' && (
                <FieldContainer flex="1">
                  <Field
                    component={Dropdown}
                    disabled={!values.city}
                    id="distance"
                    name="distance"
                    options={DISTANCE_OPTIONS}
                    variant="secondary"
                  />
                </FieldContainer>
              )}
            </FormRow>
            {values.search_by !== 'everywhere' && (
              <FormRow>
                <FieldContainer flex="1">
                  {values.search_by === 'state' && (
                    <Field
                      component={Dropdown}
                      initialValue={values?.state?.toUpperCase()}
                      name="state"
                      options={STATE_OPTIONS}
                      variant="secondary"
                      placeholder="All states"
                    />
                  )}
                  {values.search_by === 'city' && (
                    <Field
                      component={GeoComplete}
                      initialValue={values.formatted_location}
                      insetLabel
                      label="Location"
                      name="location"
                      placeholder="Type a city"
                      onBlur={setGeoCompleteTouched}
                    />
                  )}
                </FieldContainer>
              </FormRow>
            )}
            <FormRow>
              <FieldContainer flex="1">
                <Field component={Dropdown} name="sort_breed" options={BREED_OPTIONS} variant="secondary" />
              </FieldContainer>
            </FormRow>
            <FormRow>
              <FieldContainer flex="1" sidePadding="10">
                <HelpText>Age Range</HelpText>
                <Field
                  component={RangeSlider}
                  name="age"
                  maxSuffix="+"
                  minValue={0}
                  maxValue={18}
                  pluralSuffix=" Months"
                  step={1}
                  suffix=" Month"
                />
              </FieldContainer>
            </FormRow>
            <FormRow>
              <FieldContainer flex="1" sidePadding="10">
                <HelpText>Price</HelpText>
                <Field
                  component={RangeSlider}
                  maxSuffix="+"
                  minValue={100}
                  maxValue={5000}
                  name="price"
                  prefix="$"
                  step={100}
                />
              </FieldContainer>
            </FormRow>
            <FormRow>
              <FieldContainer flex="1">
                <Field component={Dropdown} name="sex" options={SEX_OPTIONS} variant="secondary" />
              </FieldContainer>
            </FormRow>
            <FormRow>
              <FieldContainer flex="1">
                <MobileUpdateButton disabled={isSubmitting} type="submit" data-testid="advanced-search-btn-mobile">
                  SEARCH
                </MobileUpdateButton>
              </FieldContainer>
            </FormRow>
          </FieldContainer>
        </FormContainer>
      </form>
    </SearchContainer>
  );
};

export default AdvancedSearchListingsFormMobile;
