import React from 'react';
import ButtonLink from '../ButtonLink';

function ViewConversationButton({ conversation, user, location, backTo }) {
  const archived = conversation
    .get('users')
    .find(convoUser => convoUser.get('id') === user.get('id') && convoUser.get('archived_at'));

  return (
    <ButtonLink
      buttonType="round"
      to={{
        pathname: `/messages/${archived ? 'archive' : 'inbox'}/${conversation.get('id')}`,
        state: {
          prevLocation: location,
          backTo,
        },
      }}
    >
      View Conversation
    </ButtonLink>
  );
}

export default ViewConversationButton;
