export default async function retryWithDelay(fn, retries = 3, delay = 3000) {
  try {
    const response = await fn();
    return response;
  } catch (err) {
    // if no retries left
    // throw error
    if (retries <= 0) {
      throw new Error(`Unable to upload file`);
    }

    //delay the next call
    await new Promise(resolve => {
      setTimeout(() => resolve(), delay);
    });

    //recursively call the same func
    return retryWithDelay(fn, retries - 1);
  }
}
