import { connect } from 'react-redux';
import { restartMigration, completeMigration, getUploadToken, setErrorBarMessage } from '../../../../redux/actions';
import PuppyFindMigrationComplete from './PuppyFindMigrationComplete';

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  restartMigration,
  completeMigration,
  getUploadToken,
  setErrorBarMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PuppyFindMigrationComplete);
