import React, { Component } from 'react';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { connect as felaConnect } from 'react-fela';
import { withFormik, Field } from 'formik';
import moment from 'moment';
import compose from 'lodash/flowRight';
import { withRouter, Link, Prompt } from 'react-router-dom';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {
  Icon,
  Button,
  ButtonPlain,
  InputError,
  TextInput,
  DatePickerInput,
  ToggleButton,
  TwoButtonToggle,
  Dropdown,
  Textarea,
  Autocomplete,
  Modal,
  StandaloneField,
  Checkbox,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ColorSquare,
  ColorSquareGrid,
  IconTopButton,
  PhotoGalleryUploader,
  FormErrors,
} from '../../../common/components/index';
import equalTo from '../../../common/utils/yupEqualTo';
import { trimAndSetNullFalsyValues } from '../../../common/utils/helpers';
import { updateBreedsAutocompleteListing, resetBreedsAutocomplete } from '../../../redux/actions';
import { selectBreedsAutocomplete } from '../../../redux/selectors';
import styles from './styles';

yup.addMethod(yup.string, 'equalTo', equalTo);

class ListingForm extends Component {
  state = {
    showBreedCertificateModal: false,
    addBreed: false,
    showDeleteModal: false,
    selectedField: null,
    imageErrors: [],
  };

  openBreedCertificateModal = field => {
    this.setState({ showBreedCertificateModal: true, selectedField: field });
  };

  closeBreedCertificateModal = () => {
    this.props.setFieldValue(this.state.selectedField, true);
    this.setState({ showBreedCertificateModal: false, selectedField: null });
  };

  cancelBreedCertificateModal = () => {
    this.props.setFieldValue('breeding_certificate', false);
    this.setState({ showBreedCertificateModal: false });
  };

  toggleBreedCertificate = () =>
    this.props.setFieldValue('breeding_certificate', !this.props.values.breeding_certificate);

  componentDidUpdate(prevProps) {
    const { values, setFieldValue } = this.props;
    /* breed certificate modal disabled for now
    if (!prevProps.values.shipping_available && values.shipping_available && !values.breeding_certificate) {
      this.openBreedCertificateModal('shipping_available');
      setFieldValue('shipping_available', false);
    }

    if (!prevProps.values.local_delivery_available && values.local_delivery_available && !values.breeding_certificate) {
      this.openBreedCertificateModal('local_delivery_available');
      setFieldValue('local_delivery_available', false);
    }
    */

    if (
      (prevProps.values.shipping_available !== values.shipping_available ||
        prevProps.values.local_delivery_available !== values.local_delivery_available) &&
      !values.shipping_available &&
      !values.local_delivery_available
    ) {
      setFieldValue('breeding_certificate', false);
    }

    if (prevProps.values.birthdate !== values.birthdate && moment().isBefore(values.birthdate)) {
      setFieldValue('sex', 'N');
    }
  }

  updateAutocomplete = debounce((value, field) => {
    if (value) {
      this.props.dispatch(updateBreedsAutocompleteListing(value));
    } else {
      this.props.dispatch(resetBreedsAutocomplete());
      this.props.setFieldValue(field, '');
    }
  }, 250);

  componentWillUnmount() {
    this.props.dispatch(resetBreedsAutocomplete());
  }

  handleBreedChange = (value, field) => {
    this.updateAutocomplete(value, field);
  };

  addBreed = () => this.setState({ addBreed: true });

  removeBreed = () => this.setState({ addBreed: false });

  handleDelete = ({ photoMap, sortIndex }) => {
    const { deleteImage, setFieldValue, values } = this.props;

    if (photoMap) {
      deleteImage(photoMap.get('key'));
    }

    const photos = values.photos.filter((_, index) => index !== sortIndex);
    setFieldValue('photos', photos);
  };

  handleEdit = (photo, sortIndex) => {
    const { setFieldValue, values } = this.props;

    const updatedPhotos = values.photos.map((p, index) => {
      if (index === sortIndex) {
        return {
          ...p,
          ...photo,
        };
      }

      return p;
    });

    setFieldValue('photos', updatedPhotos);
  };

  handleSort = ({ newIndex, oldIndex }) => {
    const { setFieldValue, values } = this.props;
    const updatedPhotos = [...values.photos];
    const element = updatedPhotos.splice(oldIndex, 1)[0];

    updatedPhotos.splice(newIndex, 0, element);

    setFieldValue('photos', updatedPhotos);
  };

  handleUpload = photo => {
    const { setFieldValue, values } = this.props;
    const photos = [...values.photos, photo];
    setFieldValue('photos', photos);
  };

  setShippingAvailable = () => {
    const { setFieldValue, values } = this.props;
    const shouldSetCShipper = values.shipping_available;
    setFieldValue('shipping_available', !shouldSetCShipper);
    if (!shouldSetCShipper) {
      return;
    }
  };

  render() {
    const {
      onResetForm,
      handleSubmit,
      isSubmitting,
      values,
      status,
      coatPatterns,
      listing,
      setFieldValue,
      history,
      breeds,
      styles,
      colors,
      handleDeleteListing,
      disallowAddNew,
      atListingLimit,
      user,
      userVM,
      errors,
      userId,
      dirty,
    } = this.props;
    const { showBreedCertificateModal, imageErrors } = this.state;
    const coatPatternOptions = [...coatPatterns];
    const select = { value: '', label: 'Select...' };

    if (values.coat_pattern_id !== '' && !isEqual(coatPatternOptions[0], select)) {
      coatPatternOptions.unshift(select);
    }

    return (
      <form id="listing-form" onSubmit={handleSubmit}>
        {dirty && status === undefined ? (
          <Prompt message="You have unsaved changes, are you sure you want to leave?" />
        ) : null}
        {listing ? (
          <Modal
            isOpen={this.state.showDeleteModal}
            onClose={() => {
              this.setState({ showDeleteModal: false });
            }}
            closeOnEscape
            closeOnOutsideClick
          >
            <ModalBody>
              <div className={styles.cancelModalBody}>
                <Icon icon="Warning" size={40} />
                <h1>Are you sure?</h1>
                <p>This will permanently delete your listing from Puppies.com</p>
              </div>
            </ModalBody>
            <ModalFooter
              actions={[
                <ButtonPlain
                  variant="red"
                  type="button"
                  onClick={() => {
                    this.setState({ showDeleteModal: false });
                  }}
                >
                  Cancel
                </ButtonPlain>,
                <Button
                  type="button"
                  disabled={this.props.deleteListingLoading}
                  buttonType="round"
                  onClick={handleDeleteListing}
                >
                  Delete Permanently
                </Button>,
              ]}
            />
          </Modal>
        ) : null}

        <PhotoGalleryUploader
          header="Upload photos"
          path={`${userId}/listings`}
          photos={listing ? listing.get('photos') : new List()}
          userPhotos={values.photos}
          onDelete={this.handleDelete}
          onEdit={this.handleEdit}
          onSort={this.handleSort}
          onUpload={this.handleUpload}
        />

        <div className={styles.individualPuppyWarning}>
          <Icon icon="Info" size={18} />
          Listings are for individual puppies only. Litter or multi-dog listings are not permitted. Please create
          individual listings for all puppies in a litter.
        </div>

        {imageErrors.length > 0 &&
          imageErrors.map(error => <InputError>{`Could not upload image: ${error?.message}`}</InputError>)}

        <hr className={styles.rule} />

        <div className={styles.listingDetails}>
          <div className={styles.formRow}>
            <div className={styles.nameBirthdayWrapper}>
              <div className={styles.puppyNameInput}>
                <Field label="PUPPY NAME" name="name" placeholder="Type a name" component={TextInput} required />
              </div>
              <div className={styles.puppyBirthDate}>
                <Field label="BORN OR EXPECTED ON" name="birthdate" component={DatePickerInput} maxDays={60} required />
              </div>
            </div>

            <div className={styles.breedField}>
              <div className={styles.singleBreedPlus}>
                <Field
                  label="Breed"
                  name="breed"
                  key={status && status.key ? `breed-${status.key}` : 'breed'}
                  component={Autocomplete}
                  items={breeds.filter(breed => breed.id !== values.breed2)}
                  onInputChange={val => this.handleBreedChange(val, 'breed')}
                  onSelect={value => {
                    this.props.dispatch(resetBreedsAutocomplete());
                    setFieldValue('breed', value.id);
                  }}
                  initialValue={values.formatted_breed || ''}
                  placeholder="Type a breed"
                  onBlur={() => this.props.dispatch(resetBreedsAutocomplete())}
                  required
                />
              </div>

              <div className={styles.mixedBreedMinus}>
                <Field
                  label="MIXED WITH (If Applicable)"
                  name="breed2"
                  key={status && status.key ? `breed2-${status.key}` : 'breed2'}
                  component={Autocomplete}
                  items={breeds.filter(breed => breed.id !== values.breed)}
                  onInputChange={val => this.handleBreedChange(val, 'breed2')}
                  onSelect={value => {
                    this.props.dispatch(resetBreedsAutocomplete());
                    setFieldValue('breed2', value.id);
                  }}
                  initialValue={values.formatted_breed2 || ''}
                  placeholder="Type a breed"
                  onBlur={() => this.props.dispatch(resetBreedsAutocomplete())}
                />
              </div>
            </div>
          </div>
          <div className={styles.priceGenerCoatRowWrapper}>
            <div className={styles.priceGenderCoatFormRow}>
              <div className={styles.priceField}>
                <Field label="PRICE" name="price" type="text" placeholder="$USD" component={TextInput} required />
              </div>
              <div className={styles.genderToggle}>
                <Field
                  label="GENDER"
                  disabled={values.birthdate && values.birthdate.isAfter(moment().endOf('day'))}
                  name="sex"
                  primaryText="Male"
                  secondaryText="Female"
                  primaryValue="M"
                  secondaryValue="F"
                  component={TwoButtonToggle}
                  required
                />
              </div>
              <div className={styles.coatPattern}>
                <Field
                  label="COAT PATTERN / MARKINGS"
                  options={coatPatternOptions}
                  name="coat_pattern_id"
                  variant="secondary"
                  component={Dropdown}
                />
              </div>
            </div>
            <ColorSquareGrid label="COLORS (MAXIMUM OF THREE)">
              {colors.map(color => (
                <Field
                  key={color.get('id')}
                  name="colors"
                  hex={color.get('hex')}
                  value={color.get('id')}
                  component={ColorSquare}
                  multiselect={true}
                  maxSelections={3}
                  className={styles.colorSquare}
                />
              ))}
            </ColorSquareGrid>
          </div>

          <div className={styles.characteristics}>
            <div className={styles.characteristicsLabel}>
              CHARACTERISTICS <span>(SELECT ALL THAT APPLY)</span>
            </div>
            <div className={styles.characteristicsListWrapper}>
              <div className={styles.characteristicsList}>
                <Field
                  name="champion_sired"
                  value={true}
                  primaryText="Champion Sired"
                  toggleType="pill"
                  component={ToggleButton}
                />
                <Field
                  name="show_quality"
                  value={true}
                  primaryText="Show Quality"
                  toggleType="pill"
                  component={ToggleButton}
                />
                <Field
                  name="champion_blood"
                  value={true}
                  primaryText="Champion Bloodline"
                  toggleType="pill"
                  component={ToggleButton}
                />
                <Field
                  name="registered"
                  value={true}
                  primaryText="Registered"
                  toggleType="pill"
                  component={ToggleButton}
                />
                <Field
                  name="registrable"
                  value={true}
                  primaryText="Registrable"
                  toggleType="pill"
                  component={ToggleButton}
                />
              </div>
              <div className={styles.characteristicsList}>
                <Field
                  name="vaccinated"
                  value={true}
                  primaryText="Current Vaccinations"
                  toggleType="pill"
                  component={ToggleButton}
                />
                <Field
                  name="vet_exam"
                  value={true}
                  primaryText="Veterinary Exam"
                  toggleType="pill"
                  component={ToggleButton}
                />
                <Field
                  name="health_cert"
                  value={true}
                  primaryText="Health Certificate"
                  toggleType="pill"
                  component={ToggleButton}
                />
                <Field
                  name="health_guarantee"
                  value={true}
                  primaryText="Health Guarantee"
                  toggleType="pill"
                  component={ToggleButton}
                />
                <Field name="pedigree" value={true} primaryText="Pedigree" toggleType="pill" component={ToggleButton} />
              </div>
            </div>
          </div>
        </div>

        <hr className={styles.rule} />

        <div className={styles.listingBio}>
          <h3 className={styles.sectionTitle}>
            <strong>Delivery options and bio</strong> Include detailed delivery information in the bio section!
          </h3>

          <div className={styles.formRowWider}>
            <Field
              label="AVAILABLE FOR ON-SITE PICKUP"
              name="pickup_available"
              primaryText="Yes"
              secondaryText="No"
              primaryValue={true}
              secondaryValue={false}
              component={TwoButtonToggle}
            />
            <Field
              label="AVAILABLE FOR LOCAL DELIVERY"
              name="local_delivery_available"
              primaryText="Yes"
              secondaryText="No"
              primaryValue={true}
              secondaryValue={false}
              component={TwoButtonToggle}
            />
            <Field
              label="AVAILABLE FOR SHIPPING"
              name="shipping_available"
              primaryText="Yes"
              secondaryText="No"
              onClick={this.setShippingAvailable}
              primaryValue={true}
              secondaryValue={false}
              component={TwoButtonToggle}
            />{' '}
          </div>

          <div className={styles.bioWrapper}>
            <div className={styles.bioField}>
              <Field
                label="DESCRIBE THIS PUPPY AND YOUR SHIPPING/DELIVERY POLICY"
                name="description"
                placeholder="Writer's Block?&#10;Describe your puppy's personality and traits.&#10;Give further details about how shipping and delivery works."
                component={Textarea}
                value={values.description}
              />
            </div>
            <div className={styles.bioFieldHelp}>
              <span>
                WARNING: Posting contact information here will make it publicly available.{' '}
                <Link to="/blog/articles/lsptJkxc8l0pDk6X9KFRd" target="_blank">
                  Click here
                </Link>{' '}
                to learn about how to avoid scams and fraud.
              </span>
            </div>
          </div>
        </div>

        <hr className={styles.rule} />

        <div className={styles.listingFormButtonWrapper}>
          <FormErrors errors={errors} />
          {status && status.error && <InputError>{status.error}</InputError>}
          {listing ? (
            <React.Fragment>
              <div className={styles.buttonRow}>
                <div className={styles.buttonWithIndicator}>
                  <Button
                    buttonType="roundOrange"
                    type="submit"
                    disabled={this.state.isLoading || isSubmitting}
                    isSubmitting={isSubmitting}
                  >
                    {isSubmitting ? 'Saving...' : this.state.isLoading ? 'Uploading Images...' : 'SAVE LISTING'}
                  </Button>
                </div>
                <ButtonPlain
                  type="button"
                  variant="red"
                  onClick={() => {
                    this.setState({ showDeleteModal: true });
                  }}
                >
                  Delete Listing
                </ButtonPlain>
              </div>
            </React.Fragment>
          ) : (
            <div className={styles.buttonWithIndicator}>
              <Button
                buttonType="roundOrange"
                type="submit"
                disabled={this.state.isLoading || isSubmitting}
                isSubmitting={isSubmitting}
              >
                {isSubmitting ? 'Posting...' : this.state.isLoading ? 'Uploading Images...' : 'POST LISTING'}
              </Button>
            </div>
          )}
        </div>
        <Modal
          isOpen={showBreedCertificateModal}
          onClose={() => this.setState({ showBreedCertificateModal: false })}
          closeOnOutsideClick
        >
          <ModalHeader
            title="Agree to terms"
            subTitle={`If you have more than four breeding females and you want to offer sight-unseen delivery or shipping of their puppies, you must have a current USDA Animal Welfare Act license, as required by law.`}
            link="https://www.aphis.usda.gov/animal_welfare/downloads/premiers/dogs/TechNote-QA-Dog-Activities-Requiring-a-USDA-License-or-Registration_11-29-2018.pdf"
            linkText="Learn More"
          />
          <ModalBody>
            <StandaloneField
              label="Yes, I have a current USDA Animal Welfare Act license or I am exempt from having a license."
              name="breedcert"
              value={values.breeding_certificate}
              component={Checkbox}
              onChange={this.toggleBreedCertificate}
              labelLeft
            />
          </ModalBody>
          <ModalFooter
            actions={[
              <ButtonPlain key="cancel" variant="red" onClick={this.cancelBreedCertificateModal}>
                Cancel
              </ButtonPlain>,
              <Button
                type="button"
                key="agree"
                buttonType="round"
                onClick={this.closeBreedCertificateModal}
                disabled={!values.breeding_certificate}
              >
                Agree To Terms
              </Button>,
            ]}
          />
        </Modal>
        <Modal
          closeOnOutsideClick
          isOpen={!!((status && status.successId) || disallowAddNew)}
          onClose={() => {
            history.push('/listings');
          }}
        >
          <div className={styles.modalChildren}>
            <div className={styles.modalTitle}>
              {atListingLimit
                ? 'Listing Limit Reached'
                : `Listing ${this.props.listing ? 'Updated' : 'Posted'} Successfully!`}
            </div>
            {user.get('is_approved_seller') ? null : (
              <div className={styles.newSellerMessage}>
                As a first-time seller, your listings will not be made public until you are approved by our staff.
                Approval may take up to 2 business days after creating your account.
              </div>
            )}
            {atListingLimit && <p>You have just reached the listing limit for your account type.</p>}
            <div className={classNames(styles.modalButtonGroup, atListingLimit && styles.modalButtonGroupTrial)}>
              {atListingLimit && !userVM.get('isPremier') && (
                <Link to="/account/membership">
                  <IconTopButton icon="Star">Upgrade my account now</IconTopButton>
                </Link>
              )}
              {!atListingLimit && (
                <Link to="/listings/create" onClick={() => (listing ? null : onResetForm())}>
                  <IconTopButton icon="FatAdd">Create a new listing</IconTopButton>
                </Link>
              )}
              {!atListingLimit && (
                <IconTopButton icon="Duplicate" onClick={this.props.onDuplicate}>
                  Add another puppy from this litter
                </IconTopButton>
              )}
              {listing && listing.get('status') !== 'for_sale' ? (
                <Link to={`/listings/${listing.get('status') === 'on_hold' ? 'hold' : 'sold'}`}>
                  <IconTopButton icon="FatCheck">I'm done. View my listings</IconTopButton>
                </Link>
              ) : (
                <Link to="/listings">
                  <IconTopButton icon="FatCheck">I'm done. View my listings</IconTopButton>
                </Link>
              )}
            </div>
          </div>
        </Modal>
      </form>
    );
  }
}

const countNumbersInString = input => {
  const numbers = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'cero',
    'uno',
    'dos',
    'tres',
    'cuatro',
    'cinco',
    'seis',
    'siete',
    'ocho',
    'nueve',
    'diez',
    'null',
    'eins',
    'zwei',
    'drei',
    'vier',
    'funf',
    'sechs',
    'sieben',
    'acht',
    'neun',
    'zehn',
    'zéro',
    'un',
    'deux',
    'trois',
    'quatre',
    'cinq',
    'sept',
    'huit',
    'neuf',
    'dix',
  ];
  var count = input.match(/\d/g) ? input.match(/\d/g).length : 0;
  for (let i = 0; i < numbers.length; i++) {
    if (input.toLowerCase().includes(numbers[i])) {
      count += 1;
    }
  }
  return count;
};

const FormikListingForm = withFormik({
  mapPropsToValues: props => {
    const listing = props.listing;
    if (!isEmpty(props.formValues)) {
      return {
        name: '',
        price: '',
        sex: 'N',
        coat_pattern_id: '',
        photos: [],
        ...props.formValues,
      };
    }

    let birthDate = null;
    if (listing) {
      const birthDatetime = listing.get('birthdate');
      birthDate = birthDatetime.substr(0, birthDatetime.indexOf('T'));
    }

    return {
      name: listing ? listing.get('name') : '',
      birthdate: birthDate ? moment(birthDate) : null,
      breeding_certificate: listing ? listing.get('breeding_certificate') : false,
      breed: listing ? listing.getIn(['breeds', 0, 'id']) : '',
      breed2: listing ? listing.getIn(['breeds', 1, 'id']) : '',
      colors: listing
        ? listing
            .get('colors')
            .map(color => color.get('id'))
            .toJS()
        : [],
      formatted_breed: listing ? listing.getIn(['breeds', 0, 'name']) : '',
      formatted_breed2: listing ? listing.getIn(['breeds', 1, 'name']) : '',
      price: listing ? listing.get('price') : '',
      sex: listing ? listing.get('sex') : '',
      coat_pattern_id: listing ? listing.get('coat_pattern_id') || '' : '',
      champion_sired: listing ? listing.get('champion_sired') : false,
      show_quality: listing ? listing.get('show_quality') : false,
      champion_blood: listing ? listing.get('champion_blood') : false,
      registered: listing ? listing.get('registered') : false,
      registrable: listing ? listing.get('registrable') : false,
      vaccinated: listing ? listing.get('vaccinated') : false,
      vet_exam: listing ? listing.get('vet_exam') : false,
      health_cert: listing ? listing.get('health_cert') : false,
      health_guarantee: listing ? listing.get('health_guarantee') : false,
      pedigree: listing ? listing.get('pedigree') : false,
      pickup_available: listing ? listing.get('pickup_available') : true,
      local_delivery_available: listing ? listing.get('local_delivery_available') : false,
      shipping_available: listing ? listing.get('shipping_available') : false,
      description: listing ? listing.get('description') : '',
      photos: listing
        ? listing
            .get('photos')
            .toJS()
            .map(photo => ({ id: photo.id, title: photo.title }))
        : [],
    };
  },
  enableReinitialize: true,
  validationSchema: yup.object().shape({
    name: yup
      .string()
      .trim()
      .min(1)
      .max(30, 'Puppy name must be 30 characters or less')
      .required('Puppy name is required'),
    /*.test('no-urls', 'Puppy name cannot contain any website urls', function(value) {
        return !/((http|ftp|https):\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/.test(value);
      }),*/
    birthdate: yup
      .mixed()
      .required('Birthdate is required')
      .test('date-too-old', 'Puppy must be younger than 30 years old', function(value) {
        if (value && value.isAfter(moment().subtract(30, 'Y'))) {
          return true;
        }
        return false;
      })
      .test('date-too-young', 'Date must be sooner than 60 days', function(value) {
        if (value && value.isAfter(moment().add(60, 'd'))) {
          return false;
        }
        return true;
      }),
    breed: yup
      .number()
      .min(1)
      .required('Breed is required'),
    breed2: yup.number(),
    price: yup
      .number()
      .integer('Price must be a whole number')
      .typeError('The price must be a number')
      .min(100, 'Price must be at least $100')
      .max(99999, 'Price is too high')
      .required('Price is required'),
    sex: yup.string().test('required-if-born', 'Gender is required', function(value) {
      if (this.parent.birthdate && this.parent.birthdate.isBefore(moment())) {
        return /^(M|F)$/.test(value);
      }
      return /^(M|F|N)$/.test(value);
    }),
    coat_pattern_id: yup.string(),
    champion_sired: yup.bool().required(),
    show_quality: yup.bool().required(),
    champion_blood: yup.bool().required(),
    registered: yup.bool().required(),
    registrable: yup.bool().required(),
    vaccinated: yup.bool().required(),
    vet_exam: yup.bool().required(),
    health_cert: yup.bool().required(),
    health_guarantee: yup.bool().required(),
    pedigree: yup.bool().required(),
    pickup_available: yup.bool().required(),
    local_delivery_available: yup.bool().required(),
    shipping_available: yup.bool().required(),
    description: yup.string().max(3000, 'Must be 3000 characters or less'),
    /*.test('no-urls', 'Description cannot contain any website urls', function(value) {
        return !/((http|ftp|https):\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/.test(value);
      }),*/
    photos: yup.array(),
    photosToSave: yup.array(),
  }),

  handleSubmit: async (values, { props, setSubmitting, setStatus, setErrors }) => {
    setSubmitting(true);
    const { name } = values;
    if (countNumbersInString(name) > 4) {
      setErrors({ name: 'First name contains too many numbers' });
      setSubmitting(false);
      return;
    }
    if (!values.local_delivery_available && !values.pickup_available && !values.shipping_available) {
      setSubmitting(false);
      return setStatus({ error: 'You must select at least one delivery or shipping method' });
    }

    const submitValues = cloneDeep(values);

    submitValues.colors = values.colors.map(colorid => ({ id: colorid }));

    submitValues.breeds = submitValues.breed ? [{ id: submitValues.breed }] : [];
    if (submitValues.breed2) {
      submitValues.breeds.push({ id: submitValues.breed2 });
    }
    delete submitValues.breed;
    delete submitValues.breed2;
    delete submitValues.formatted_breed;
    delete submitValues.formatted_breed2;

    if (moment.isMoment(submitValues.birthdate)) {
      submitValues.birthdate = submitValues.birthdate.format('YYYY-MM-DD');
    }

    submitValues.photos = submitValues.photos.map(
      ({ croppedImage, loading, percentage, type, value, ...rest }) => rest
    );

    const action = await props.submitAction(trimAndSetNullFalsyValues(submitValues, 'description'));
    setSubmitting(false);
    if (!action?.response.ok) {
      if (action?.json?.validationErrors) {
        const errors = action.json.validationErrors.reduce((c, e) => {
          return {
            ...c,
            [e.key]: e.message,
          };
        }, {});

        setErrors(errors);
      } else {
        setStatus({
          error: action?.json ? action.json.message : 'An unknown error occurred',
        });
      }
    } else {
      setStatus({ successId: action.json.id });
    }
  },
})(ListingForm);

const mapStateToProps = state => ({
  breeds: selectBreedsAutocomplete(state),
});

export default compose(felaConnect(styles), connect(mapStateToProps), withRouter)(FormikListingForm);
