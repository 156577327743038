import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { confirmEmailVerification } from '../../redux/actions';
import VerifyEmail from './VerifyEmail';

const mapStateToProps = state => {
  return {
    loading: state.confirmEmailLoading,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ confirmEmailVerification }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
