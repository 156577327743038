import querystring from 'query-string';
import client from '../../../common/utils/client';

export const getPayments = query => {
  return {
    promise: client.get(`payment/payments?${querystring.stringify(query)}`),
    type: 'GET_PAYMENTS',
  };
};

export const purchaseMembership = ({ payment_token, plan, paymentProcessor = 'braintree' }) => {
  return {
    promise: client.post(`memberships`, {
      plan,
      payment_processor: paymentProcessor,
      payment_token,
    }),
    type: 'PURCHASE_MEMBERSHIP',
  };
};

export const checkForRecentTransaction = () => {
  return {
    promise: client.get('memberships/recent'),
    type: 'CHECK_FOR_RECENT_TRANSACTION',
  };
};

export const purchaseBoostingAction = ({
  listingIds,
  duration,
  amount,
  payment_token,
  paymentProcessor = 'braintree',
}) => {
  return {
    promise: client.post(`listings/purchase-boost`, {
      listing_ids: listingIds,
      duration,
      amount,
      payment_token,
      payment_processor: paymentProcessor,
    }),
    type: 'PURCHASE_LISTINGS_BOOST',
  };
};

export const getPaymentMethods = () => ({
  promise: client.get(`payment/payment-methods`),
  type: 'PAYMENT_METHODS',
});

export const deletePaymentMethod = token => ({
  promise: client.post(`payment-method/delete`, { token }),
  type: 'DELETE_PAYMENT_METHODS',
});
export const getMembershipPlans = () => {
  return {
    promise: client.get(`memberships/plans`),
    type: 'GET_MEMBERSHIP_PLANS',
  };
};

export const getBraintreeToken = (type, days = null, numOfListings = null) => {
  let params = `type=${type}`;
  if (days) {
    params = `${params}&days=${days}`;
  }
  if (numOfListings) {
    params = `${params}&numOfListings=${numOfListings}`;
  }
  return {
    promise: client.get(`payment/braintree_token?${params}`),
    type: 'GET_BRAINTREE_TOKEN',
  };
};

export const getVerificationStatus = () => ({
  promise: client.get(`users/verification-status`),
  type: 'GET_VERIFICATION_STATUS',
});

export const purchaseIdVerification = nonce => ({
  promise: client.post(`users/verification`, { nonce }),
  type: 'PURCHASE_VERIFICATION',
});

export const searchFavoriteListings = query => ({
  promise: client.get(`listings/search?${querystring.stringify(query)}`),
  type: 'SEARCH_FAVORITE_LISTINGS',
});

export const resetSearchFavoriteListings = () => ({
  type: 'RESET_SEARCH_FAVORITE_LISTINGS',
});

export const addFavoriteListing = listing_id => ({
  promise: client.put(`listings/${listing_id}/favorite`),
  type: 'CREATE_FAVORITE_LISTING',
  listing_id,
});

export const removeFavoriteListing = (listing_id, nextPage) => ({
  promise: client.delete(`listings/${listing_id}/favorite`),
  type: 'REMOVE_FAVORITE_LISTING',
  listing_id,
  nextPage,
});

export const createPremierProfile = payload => ({
  promise: client.post('premiers', payload),
  type: 'CREATE_PREMIER_PROFILE',
});

export const editPremierProfile = payload => {
  const { id, ...restPayload } = payload;
  return {
    promise: client.put(`premiers/${id}`, { ...restPayload }),
    type: 'EDIT_PREMIER_PROFILE',
  };
};

export const addFavoritePremier = premier_id => ({
  promise: client.put(`premiers/${premier_id}/favorite`),
  type: 'CREATE_FAVORITE_PREMIER',
  premier_id,
});

export const removeFavoritePremier = (premier_id, nextPage) => ({
  promise: client.delete(`premiers/${premier_id}/favorite`),
  type: 'REMOVE_FAVORITE_PREMIER',
  premier_id,
  nextPage,
});

export const searchFavoritePremiers = query => ({
  promise: client.get(`premiers?${querystring.stringify(query)}`),
  type: 'SEARCH_FAVORITE_PREMIERS',
});

export const resetSearchFavoritePremiers = () => ({
  type: 'RESET_SEARCH_FAVORITE_PREMIERS',
});

export const cancelMembership = () => ({
  promise: client.post('memberships/cancel'),
  type: 'CANCEL_MEMBERSHIP',
});

export const cancelPendingMembership = () => ({
  promise: client.post('memberships/pending/cancel'),
  type: 'CANCEL_PENDING_MEMBERSHIP',
});

export const resetPaymentHistory = () => ({
  type: 'RESET_PAYMENT_HISTORY',
});

export const redeemCoupon = couponId => ({
  promise: client.patch(`coupons/redeem/${couponId}`),
  type: 'REDEEM_COUPON',
});
