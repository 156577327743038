import React from 'react';

import { SubNav, TabGroup } from '../../../../common/components/';

function MyProfileSubNav({ isPremier, isFirstTimeFillingOutProfile }) {
  if (isFirstTimeFillingOutProfile) {
    return <FirstTimeFillingOutProfileSubNav />;
  }

  if (isPremier) {
    return <PremierAccountSubNav />;
  }

  return <DefaultMyProfileSubNav />;
}

function FirstTimeFillingOutProfileSubNav() {
  return <SubNav titleText="Let's Get Started!" />;
}

function PremierAccountSubNav() {
  return (
    <SubNav
      titleText={'Edit Profile'}
      headerRightChildren={
        <TabGroup
          linkArray={[
            { route: '/account/my-profile', text: 'Basic Profile' },
            { route: '/account/premier-profile', text: 'Premier Profile' },
          ]}
        />
      }
    />
  );
}

function DefaultMyProfileSubNav() {
  return <SubNav titleText={'Edit Profile'} />;
}

export default MyProfileSubNav;
