import React from 'react';
import styled from 'styled-components';

import { Icon } from '../../common/components';

import theme from '../../theme';

const Container = styled.div`
  ${theme.breakpoints.tabletLarge} {
    ${theme.globalPadding}
    box-shadow: none;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: ${theme.globalMaxWidth};
  }
`;

const Button = styled.button`
  align-items: center;
  align-self: flex-end;
  background-color: ${theme.colors.darkerBlue};
  box-shadow: ${props => (props.isAdvancedSearch ? '0 2px 4px 0 rgba(0,0,0,0.5)' : 'none')};
  display: flex;
  justify-content: center;
  padding: 8px;
  width: 100%;
  color: ${theme.colors.white};
  font-size: 10px;
  font-weight: ${theme.typography.sansBold};

  > * + *: {
    margin-left: 9px;
  }

  &:active {
    outline: none;
  }

  &:hover {
    background-color: ${theme.colors.darkerBlueHover};
  }

  ${theme.breakpoints.tabletLarge} {
    border-radius: 0 0 4px 4px;
    width: 160px;
  }
`;

const ToggleAdvancedSearch = ({ handleToggleAdvanced, isAdvancedSearch, isMatchmakerSearch, isMobile }) => {
  if (isMatchmakerSearch || isMobile) {
    return null;
  }

  return (
    <Container>
      <Button type="button" onClick={handleToggleAdvanced}>
        {isAdvancedSearch ? <Icon icon="CaretUp" size={12} /> : <Icon icon="CaretDown" size={12} />}
        <span>{isAdvancedSearch ? 'Basic Search' : 'Advanced Search'}</span>
      </Button>
    </Container>
  );
};

export default ToggleAdvancedSearch;
