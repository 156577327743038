import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import classNames from 'classnames';

class ModalBody extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  };

  render() {
    const { children, styles, className } = this.props;
    const classes = {
      [styles.root]: true,
      [className]: className,
    };
    return <div className={classNames(classes)}>{children}</div>;
  }
}

const styles = {
  root: props => ({
    width: '100%',
    padding: '18px 24px',
    [props.theme.breakpoints.mobile]: {
      padding: '30px 40px',
    },
  }),
};

export default connect(styles)(ModalBody);
