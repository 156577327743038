import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import Icon from '../icons/icon';
import classNames from 'classnames';
import theme from '../../../theme';
import Loading from '../Loading';
import { getFullSize } from '../../utils/photo-helpers';
import { List } from 'immutable';
import { Helmet } from 'react-helmet';

const ARROW_LEFT = 37;
const ARROW_RIGHT = 39;

class LightBox extends Component {
  state = { currentImage: this.props.initialImage ? this.props.initialImage : 0, isLoaded: true };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleArrowClick = e => {
    const { value } = e.target;

    if (value === 'back') {
      this.showPreviousImage();
    } else {
      this.showNextImage();
    }
  };

  handleKeyDown = event => {
    if (event.defaultPrevented) {
      return; // Should do nothing if the default action has been canceled
    }

    let handled = false;
    if (event.keyCode === ARROW_LEFT) {
      this.showPreviousImage();
      handled = true;
    } else if (event.keyCode === ARROW_RIGHT) {
      this.showNextImage();
      handled = true;
    }

    if (handled) {
      event.preventDefault();
    }
  };

  handleClose = () => this.props.onClose();

  showNextImage = () => {
    let nextImage = this.state.currentImage;

    if (nextImage >= this.props.photos.size - 1) {
      nextImage = 0;
    } else {
      nextImage += 1;
    }

    if (nextImage !== this.state.currentImage) {
      this.setState(state => ({ isLoaded: false, currentImage: nextImage }));
    }
  };

  showPreviousImage = () => {
    let nextImage = this.state.currentImage;

    if (nextImage <= 0) {
      nextImage = this.props.photos.size - 1;
    } else {
      nextImage -= 1;
    }

    if (nextImage !== this.state.currentImage) {
      this.setState(state => ({ isLoaded: false, currentImage: nextImage }));
    }
  };

  imageLoaded = () => this.setState({ isLoaded: true });

  render() {
    const { currentImage, isLoaded } = this.state;
    const { photos, styles } = this.props;
    const totalImages = photos.size;
    if (!totalImages) {
      return (
        <div>
          No Images <button onClick={this.handleClose}>Close</button>
        </div>
      );
    }
    return (
      <>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"
          />
        </Helmet>
        <div className={styles.root}>
          {/* Close Button - Save This */}
          <div className={styles.header}>
            <button onClick={() => this.handleClose()} className={styles.iconClose}>
              <Icon icon="Close" size={30} fill={theme.colors.white} />
            </button>
          </div>
          <div className={styles.container}>
            {photos.size > 1 ? (
              <button
                value="back"
                onClick={this.handleArrowClick}
                className={classNames([styles.icon, styles.iconPrev])}
              >
                <Icon icon="Caret" size={40} fill={theme.colors.white} />
              </button>
            ) : null}
            <figure className={styles.figure}>
              <img
                className={styles.image}
                src={`${getFullSize(photos.get(currentImage))}`}
                alt="preview"
                onLoad={this.imageLoaded}
              />
              <div className={styles.imgTitle}>{photos.getIn([currentImage, 'title'])}</div>
              {isLoaded ? null : (
                <div className={styles.loading}>
                  <Loading />
                </div>
              )}
            </figure>
            {photos.size > 1 ? (
              <button
                value="next"
                onClick={this.handleArrowClick}
                className={classNames([styles.icon, styles.iconNext])}
              >
                <Icon icon="Caret" size={40} fill={theme.colors.white} />
              </button>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

LightBox.propTypes = {
  photos: PropTypes.instanceOf(List),
  onClose: PropTypes.func,
};

const styles = props => ({
  root: {
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
    position: 'relative',
  },
  container: {
    width: '100%',
    position: 'relative',
    display: 'block',
    [props.theme.breakpoints.tablet]: {
      margin: 0,
    },
  },
  figure: {
    margin: 0,
    display: 'inline-block',
    position: 'relative',
  },
  image: {
    display: 'block',
    height: 'auto',
    margin: '0 auto',
    maxWidth: props.fullLightbox ? '100%' : 'calc(100vw - 30px)',
    minWidth: '100px',
    maxHeight: 'calc(100vh - 140px)',
    border: '5px solid black',

    [props.theme.breakpoints.tablet]: {
      maxWidth: props.theme.globalMaxWidth,
    },
  },
  icon: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0%,-50%)',
    zIndex: 1,
    padding: '10px',
    height: '60px',
    width: '60px',

    '>span': {
      filter: `drop-shadow( 0px 2px 6px rgba(0,0,0,.5) )`,
    },
    [props.theme.breakpoints.desktop]: {
      '>span': {
        filter: 'none',
      },
    },
    ':focus': {
      outline: 'none',
    },
    [props.theme.breakpoints.mobile]: {
      padding: '20px',
      height: '80px',
      width: '80px',
    },
  },
  iconClose: {
    backgroundColor: 'transparent',
    ':focus': {
      outline: 'none',
    },
  },
  iconNext: {
    right: 0,
  },
  iconPrev: {
    left: 0,
    transform: 'rotate(180deg) translate(0%,50%)',
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  header: {
    position: 'absolute',
    right: '0',
    top: '0',
    height: '50px',
    margin: '24px',
  },
  imgTitle: {
    color: '#ffffff',
    textAlign: 'center',
    backgroundColor: 'black',
    padding: '4px 8px 8px',
  },
});

export default connect(styles)(LightBox);
