import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import Icon from '../icons/icon';
import theme from '../../../theme';

class Badge extends Component {
  render() {
    const { icon, styles } = this.props;

    return (
      <div className={styles.root}>
        <Icon icon={icon} />
      </div>
    );
  }
}

Badge.propTypes = {
  size: PropTypes.number,
  icon: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  fill: PropTypes.string,
};

Badge.defaultProps = {
  size: 18,
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.black,
  fill: theme.colors.black,
};

const styles = {
  root: props => ({
    display: 'inline-block',
    position: 'relative',
    borderRadius: '50%',
    backgroundColor: props.backgroundColor,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: props.borderColor,
    '>span': {
      width: `${props.size}px`,
      height: `${props.size}px`,
      fill: props.fill,
    },
  }),
};

export default connect(styles)(Badge);
