import { combineReducers } from 'redux';
import { fromJS, List, Map } from 'immutable';

const payments = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PAYMENTS_SUCCESS':
      return fromJS(action.json.results);
    default:
      return state;
  }
};

const paymentsPagination = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_PAYMENTS_SUCCESS':
      return fromJS(action.json.meta);
    default:
      return state;
  }
};

const paymentsLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PAYMENTS_REQUEST':
    case 'GET_PAYMENTS_FAILURE':
    case 'RESET_PAYMENTS':
      return false;
    case 'GET_PAYMENTS_SUCCESS':
      return true;
    default:
      return state;
  }
};

const paymentsLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_PAYMENTS_REQUEST':
      return true;
    case 'GET_PAYMENTS_FAILURE':
    case 'GET_PAYMENTS_SUCCESS':
    case 'RESET_PAYMENTS':
      return false;
    default:
      return state;
  }
};

const membershipPlans = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_MEMBERSHIP_PLANS_SUCCESS':
      return fromJS(action.json);
    case 'RESET_GET_MEMBERSHIP_PLANS':
      return Map();
    default:
      return state;
  }
};

const membershipPlansLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_MEMBERSHIP_PLANS_REQUEST':
    case 'GET_MEMBERSHIP_PLANS_FAILURE':
    case 'RESET_GET_MEMBERSHIP_PLANS':
      return false;
    case 'GET_MEMBERSHIP_PLANS_SUCCESS':
      return true;
    default:
      return state;
  }
};

const paymentHistoryTotal = (state = 0, action) => {
  switch (action.type) {
    case 'GET_PAYMENT_HISTORY_SUCCESS':
      return fromJS(action.json.total);
    case 'GET_PAYMENT_HISTORY_FAILURE':
    case 'RESET_PAYMENT_HISTORY':
      return 0;
    default:
      return state;
  }
};

const favoriteListingsResults = (state = List(), action) => {
  switch (action.type) {
    case 'SEARCH_FAVORITE_LISTINGS_SUCCESS':
      return fromJS(action.json.results);
    case 'SEARCH_FAVORITE_LISTINGS_FAILURE':
      return List();
    case 'CREATE_FAVORITE_LISTING_REQUEST':
      return state.map(listing => (listing.get('id') === action.listing_id ? listing.set('favorite', true) : listing));
    case 'RESET_SEARCH_FAVORITE_LISTINGS':
      return List();
    default:
      return state;
  }
};

const favoriteListingsTotal = (state = 0, action) => {
  switch (action.type) {
    case 'SEARCH_FAVORITE_LISTINGS_SUCCESS':
      return fromJS(action.json.total);
    case 'SEARCH_FAVORITE_LISTINGS_FAILURE':
    case 'RESET_SEARCH_FAVORITE_LISTINGS':
      return 0;
    default:
      return state;
  }
};

const favoriteListingsLoading = (state = false, action) => {
  switch (action.type) {
    case 'SEARCH_FAVORITE_LISTINGS_REQUEST':
    case 'REMOVE_FAVORITE_LISTING_REQUEST':
      return true;
    case 'SEARCH_FAVORITE_LISTINGS_FAILURE':
    case 'SEARCH_FAVORITE_LISTINGS_SUCCESS':
    case 'RESET_SEARCH_FAVORITE_LISTINGS':
      return false;
    default:
      return state;
  }
};

const purchaseErrors = (state = 0, action) => {
  switch (action.type) {
    case 'PURCHASE_MEMBERSHIP_SUCCESS':
      return 0;
    case 'PURCHASE_MEMBERSHIP_FAILURE':
      return state + 1;
    default:
      return state;
  }
};

const favoriteListingsLoaded = (state = false, action) => {
  switch (action.type) {
    case 'RESET_SEARCH_FAVORITE_LISTINGS':
    case 'SEARCH_FAVORITE_LISTINGS_REQUEST':
      return false;
    case 'SEARCH_FAVORITE_LISTINGS_FAILURE':
    case 'SEARCH_FAVORITE_LISTINGS_SUCCESS':
      return true;
    default:
      return state;
  }
};

const favoritePremiersLoaded = (state = false, action) => {
  switch (action.type) {
    case 'RESET_SEARCH_FAVORITE_PREMIERS':
    case 'SEARCH_FAVORITE_PREMIERS_REQUEST':
      return false;
    case 'SEARCH_FAVORITE_PREMIERS_FAILURE':
    case 'SEARCH_FAVORITE_PREMIERS_SUCCESS':
      return true;
    default:
      return state;
  }
};

const favoritePremiersTotal = (state = 0, action) => {
  switch (action.type) {
    case 'SEARCH_FAVORITE_PREMIERS_SUCCESS':
      return fromJS(action.json.total);
    case 'SEARCH_FAVORITE_PREMIERS_FAILURE':
    case 'RESET_SEARCH_FAVORITE_PREMIERS':
      return 0;
    default:
      return state;
  }
};

const favoritePremiersLoading = (state = false, action) => {
  switch (action.type) {
    case 'SEARCH_FAVORITE_PREMIERS_REQUEST':
    case 'REMOVE_FAVORITE_PREMIER_REQUEST':
      return true;
    case 'SEARCH_FAVORITE_PREMIERS_FAILURE':
    case 'SEARCH_FAVORITE_PREMIERS_SUCCESS':
    case 'RESET_SEARCH_FAVORITE_PREMIERS':
      return false;
    default:
      return state;
  }
};

const favoritePremiersResults = (state = List(), action) => {
  switch (action.type) {
    case 'SEARCH_FAVORITE_PREMIERS_SUCCESS':
      return fromJS(action.json.results);
    case 'SEARCH_FAVORITE_PREMIERS_FAILURE':
      return List();
    case 'CREATE_FAVORITE_PREMIER_REQUEST':
      return state.map(premier => (premier.get('id') === action.premier_id ? premier.set('favorite', true) : premier));
    case 'RESET_SEARCH_FAVORITE_PREMIERS':
      return List();
    default:
      return state;
  }
};

const favoritesRefresh = (state = fromJS({ shouldRefresh: false, nextPage: 0 }), action) => {
  switch (action.type) {
    case 'REMOVE_FAVORITE_LISTING_REQUEST':
    case 'REMOVE_FAVORITE_PREMIER_REQUEST':
      return state.merge({ shouldRefresh: false, nextPage: action.nextPage });
    case 'REMOVE_FAVORITE_LISTING_SUCCESS':
    case 'REMOVE_FAVORITE_PREMIER_SUCCESS':
      return state.merge({ shouldRefresh: true, nextPage: action.nextPage });
    case 'SEARCH_FAVORITE_LISTINGS_SUCCESS':
    case 'SEARCH_FAVORITE_PREMIERS_SUCCESS':
      return state.merge({ shouldRefresh: false });
    default:
      return state;
  }
};
const paymentMethods = (state = List(), action) => {
  switch (action.type) {
    case 'PAYMENT_METHODS_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const removedPaymentMethod = (state = false, action) => {
  switch (action.type) {
    case 'DELETE_PAYMENT_METHODS_REQUEST':
    case 'DELETE_PAYMENT_METHODS_FAILURE':
      return false;
    case 'DELETE_PAYMENT_METHODS_SUCCESS':
      return true;
    default:
      return state;
  }
};
export default combineReducers({
  membershipPlans,
  membershipPlansLoaded,
  purchaseErrors,
  favoriteListingsResults,
  favoriteListingsLoading,
  favoriteListingsTotal,
  favoriteListingsLoaded,
  favoritePremiersResults,
  favoritePremiersLoading,
  favoritePremiersLoaded,
  favoritePremiersTotal,
  payments,
  paymentsPagination,
  paymentsLoaded,
  paymentsLoading,
  paymentHistoryTotal,
  favoritesRefresh,
  paymentMethods,
  removedPaymentMethod,
});
