import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListHeader from './ListHeader';
import ButtonLink from '../../components/ButtonLink';

class BreedListHeader extends Component {
  static propTypes = {
    total: PropTypes.number,
    totalLabel: PropTypes.string,
  };

  static defaultProps = {
    totalLabel: 'found',
  };

  render() {
    const { total, totalLabel, ...restProps } = this.props;

    return (
      <ListHeader
        {...restProps}
        actions={[
          <ButtonLink buttonType="round" to="/find-a-puppy">
            Find a Puppy
          </ButtonLink>,
        ]}
      >
        <strong>{total ? total : '0'}</strong> {total === 1 ? 'breed' : 'breeds'} {totalLabel}
      </ListHeader>
    );
  }
}

export default BreedListHeader;
