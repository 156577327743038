import React from 'react';

function PhotoDelete() {
  /* eslint-disable max-len */
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.93875 7.91759L7.02116 5L9.93875 2.08241C9.97773 2.03229 10 1.97661 10 1.9098C10 1.84298 9.97773 1.79287 9.93875 1.74276L8.25724 0.0612472C8.20713 0.0222717 8.15702 0 8.0902 0C8.02339 0 7.96771 0.0222717 7.91759 0.0612472L5 2.97884L2.08241 0.0612472C2.03229 0.0222717 1.97661 0 1.9098 0C1.84298 0 1.79287 0.0222717 1.74276 0.0612472L0.0612472 1.74276C0.0222717 1.79287 0 1.84298 0 1.9098C0 1.97661 0.0222717 2.03229 0.0612472 2.08241L2.97884 5L0.0612472 7.91759C0.0222717 7.96771 0 8.02339 0 8.0902C0 8.15702 0.0222717 8.20713 0.0612472 8.25724L1.74276 9.93875C1.79287 9.97773 1.84298 10 1.9098 10C1.97661 10 2.03229 9.97773 2.08241 9.93875L5 7.02116L7.91759 9.93875C7.96771 9.97773 8.02339 10 8.0902 10C8.15702 10 8.20713 9.97773 8.25724 9.93875L9.93875 8.25724C9.97773 8.20713 10 8.15702 10 8.0902C10 8.02339 9.97773 7.96771 9.93875 7.91759Z"
        fill="#D0021B"
      />
    </svg>
  );
  /* eslint-enable */
}

export default PhotoDelete;
