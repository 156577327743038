import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContactModal from '../ContactModal';
import CircleAvatar from '../CircleAvatar';
import ContentCapitalized from '../ContentCapitalized';
import generateInitials from '../../utils/generateInitials';
import { connect as felaConnect } from 'react-fela';
import { Map } from 'immutable';

class ContactSellerModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    seller: PropTypes.instanceOf(Map),
    requestVerification: PropTypes.bool,
  };

  renderRecipientInfo() {
    const { seller, styles } = this.props;
    return (
      <div className={styles.sellerAvatar}>
        <CircleAvatar
          size={50}
          photo={seller.get('photo')}
          initials={generateInitials(seller.get('last_initial'), seller.get('first_name'))}
          alt={'message-recipient'}
          verified={seller.get('id_verified')}
        />
        <span>
          Conversation with <br />{' '}
          <strong>
            <ContentCapitalized>
              {seller.get('first_name')} {seller.get('last_initial')}
            </ContentCapitalized>
          </strong>
        </span>
      </div>
    );
  }

  render() {
    const { seller, isOpen, onClose, sendMessageToUser, requestVerification } = this.props;
    return (
      <ContactModal
        sendMessage={sendMessageToUser.bind(this, seller.get('id'))}
        isOpen={isOpen}
        onClose={onClose}
        recipientInfo={this.renderRecipientInfo()}
        recipientName={seller.get('first_name')}
        requestVerification={requestVerification}
        backTo="Profile"
      />
    );
  }
}

const styles = props => ({
  sellerAvatar: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'left',
    lineHeight: '19px',
    marginBottom: '40px',
    alignItems: 'center',
    fontSize: '14px',
    '> * + *': {
      marginLeft: '16px',
    },
  },
});

export default felaConnect(styles)(ContactSellerModal);
