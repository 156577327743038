import React from 'react';
import BoostIcon from '../icons/Boost';
import theme from '../../../theme';
const ChooseListing = function({
  styles,
  listings,
  setBoostSteps,
  boostedListings,
  setBoostedListings,
  setListingDays,
  listingDays,
  setShowModal,
  resetBoost,
}) {
  const renderIfBoosted = listing => {
    const boost = listing.get('boost');
    if (boost) {
      return <BoostIcon color={theme.colors.boostBlue} width="16" height="16" />;
    } else {
      return '';
    }
  };

  const onChangeHandler = event => {
    const idx = boostedListings.indexOf(event.target.value);

    if (idx > -1) {
      var newArr = [];
      for (let idx = 0; idx < boostedListings.length; idx++) {
        const element = boostedListings[idx];
        if (event.target.value !== element) {
          newArr.push(element);
        }
      }
      setBoostedListings(newArr);
    } else {
      setBoostedListings([event.target.value, ...boostedListings]);
    }
  };

  return (
    <>
      <div className={styles.header}>
        <div>
          <div className={styles.modalHeader}>Boost your listings!</div>
          <div className={styles.moreInfoLink}>
            <a href="https://puppies.com/blog/articles/5HVETj3EXHRdP5aDpH34lq" target="_blank" rel="noreferrer">
              More Info
            </a>
          </div>
        </div>
        <div className={styles.chooser}>
          <button type="button" onClick={() => setListingDays(3)} className={listingDays === 3 ? 'selected' : ''}>
            3 Days
          </button>
          <button type="button" onClick={() => setListingDays(7)} className={listingDays !== 3 ? 'selected' : ''}>
            7 Days
          </button>
        </div>
        <div>
          <div className={styles.listingDaysText}>
            ${listingDays === 7 ? listingDays - 1 : listingDays} per listing for {listingDays} days
          </div>
          Select listings to boost. When boosted, listings will appear at the front of default search results for a
          state or city search.
        </div>
      </div>
      <div>
        <div className={styles.listingsList}>
          {listings.map(listing => {
            const listingId = listing.get('id');
            return (
              <div key={listingId}>
                <label className={styles.boostListView}>
                  <input type="checkbox" name="checked" value={listingId} onChange={onChangeHandler} />
                  {listing.get('name')} {renderIfBoosted(listing)}
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.footer}>
        <div>
          Listings to be boosted: <strong>{boostedListings.length}</strong>
          <br />
          Boost Duration: <strong>{listingDays} Days</strong>
          <br /> Total Price:{' '}
          <strong>${(listingDays === 7 ? listingDays - 1 : listingDays) * boostedListings.length}</strong>
        </div>
        <div>
          <button
            className={styles.boostBtn}
            style={boostedListings.length === 0 ? { backgroundColor: '#0073fb91' } : { backgroundColor: '#006AE7' }}
            type="button"
            value="Boost"
            disabled={boostedListings.length === 0 ? true : false}
            onClick={() => {
              setBoostSteps(2);
            }}
          >
            <BoostIcon color="#fff" />
            BOOST
          </button>
          <button
            onClick={() => {
              resetBoost(1);
              setShowModal(false);
            }}
            className={styles.cancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default ChooseListing;
