import React from 'react';

function Send() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M23.996.551l-3 23c-.023.164-.105.305-.28.387-.141.082-.305.082-.446 0l-7.628-3.796-2.753 3.655c-.082.117-.223.2-.387.2-.058 0-.117 0-.175-.024-.188-.059-.328-.258-.328-.48V18.5c0-.117.023-.222.105-.304l10.92-14.06L5.835 16.391c-.117.082-.223.106-.34.106-.082 0-.164-.024-.222-.059L.28 13.943C.105 13.861 0 13.697 0 13.521c0-.187.082-.351.246-.468l23-12.994c.164-.082.363-.082.527.023.164.117.246.305.223.469z" />
    </svg>
  );
  /* eslint-enable */
}

export default Send;
