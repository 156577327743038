import React from 'react';

const YourCard = ({ userVM }) => {
  const lastFourUnknownBecauseUserIsMigrated = !userVM.get('last_4');
  return (
    <>
      {lastFourUnknownBecauseUserIsMigrated ? (
        'Your card'
      ) : (
        <>
          Your card ending in <strong>{userVM.get('last_4')}</strong>
        </>
      )}
    </>
  );
};

export default YourCard;
