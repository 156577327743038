import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import PinterestIcon from '../icons/Pinterest';

class PinterestButton extends Component {
  static propTypes = {
    url: PropTypes.string,
    image: PropTypes.string,
    description: PropTypes.string,
  };

  static defaultProps = {
    url: null,
  };

  render() {
    const { url, image, description, styles, location } = this.props;
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://pinterest.com/pin/create/button?url=${
          url ? url : `${config.frontendHost}${location.pathname}${location.search || ''}`
        }&media=${image}&description=${description}`}
        className={styles.button}
      >
        <PinterestIcon />
      </a>
    );
  }
}

const styles = props => ({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundImage: 'linear-gradient(-180deg, #E23539 0%, #CC2A2E 100%)',
    border: '1px solid #AF1112',
    borderRadius: '3px',
    padding: '4px 6px',
    ':hover': {
      backgroundImage: 'linear-gradient(-180deg, #CC2A2E 0%, #CC2A2E 100%)',
    },
  },
});

export default felaConnect(styles)(withRouter(PinterestButton));

// https://www.pinterest.com/pin/create/button/?url=https%3A%2F%2Fjane.com%2Fd%2F738853&media=https%3A%2F%2Fcloud.media-jane.com%2Fv1%2Fimg%2Fdeals%2F738853.jpg&description=Character%20Inspired%20Flowy%20Tanks
