import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import { PlainLink, Tooltip } from '../../../../common/components';

class ConversationHeaderDisabledLink extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
  };

  state = {
    isHovered: false,
  };

  render() {
    const { children, name, styles } = this.props;

    return (
      <div
        className={styles.tooltipTextWrapper}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
      >
        <PlainLink disabled variant="blue">
          {name}
        </PlainLink>
        <Tooltip show={this.state.isHovered}>
          <div className={styles.tooltipBody}>{children}</div>
        </Tooltip>
      </div>
    );
  }
}

const styles = props => ({
  tooltipTextWrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  tooltipBody: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    '> p': {
      fontSize: '14px',
    },
  },
});

export default connect(styles)(ConversationHeaderDisabledLink);
