import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InputError, Loading } from '../../common/components';

class VerifyEmail extends Component {
  state = {
    error: '',
  };
  static propTypes = {
    confirmEmailVerification: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  async componentDidMount() {
    const { confirmEmailVerification, match } = this.props;
    const action = await confirmEmailVerification(match.params.tokenId);
    if (!action.response.ok) {
      this.setState({ error: action.json.message });
    }
  }

  render() {
    const { loading } = this.props;
    const { error } = this.state;

    if (loading) {
      return <Loading dark center />;
    }

    if (error) {
      return (
        <div>
          There was a problem verifying your account
          <span>{error && <InputError>{error}</InputError>}</span>
        </div>
      );
    }

    return <Redirect to="/account/email-verified" />;
  }
}

export default VerifyEmail;
