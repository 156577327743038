import React, { Component } from 'react';
import { List } from 'immutable';
import { connect as felaConnect } from 'react-fela';
import { Link, withRouter } from 'react-router-dom';
import { PuppyGrid, ListHeader } from '../';
import PropTypes from 'prop-types';

class PuppyList extends Component {
  static propTypes = {
    listings: PropTypes.instanceOf(List),
    headerText: PropTypes.string,
    query: PropTypes.string,
    noAds: PropTypes.bool,
  };

  static defaultProps = {
    listings: List(),
    noAds: false,
  };

  render() {
    const { styles, headerText, listings, query, location, noAds } = this.props;
    if (!listings) {
      return null;
    }
    return (
      <div className={styles.root}>
        <PuppyGrid
          header={<ListHeader center>{headerText}</ListHeader>}
          listings={listings}
          total={listings?.size}
          location={location}
          noAds={noAds}
          hideBookmark
        />
        <div className={styles.buttonWrapper}>
          <Link to={`/find-a-puppy${query ? `?${query}` : ''}`} className={styles.button}>
            SEE MORE PUPPIES
          </Link>
        </div>
      </div>
    );
  }
}

const styles = props => ({
  root: {
    padding: '10px 0 30px',
    ...props.theme.globalBackgroundContainer,
  },
  buttonWrapper: {
    marginTop: '24px',
    textAlign: 'center',
    [props.theme.breakpoints.mobile]: {
      marginTop: '10px',
    },
  },
  button: {
    ...props.theme.linkStyles.largeRoundOrangeOutline,
  },
});

export default withRouter(felaConnect(styles)(PuppyList));
