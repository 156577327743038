import React from 'react';

function PencilOutline() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
      <path
        d="M64.72 30.216L23.682 71.25c-.194.194-.35.272-.544.35l-20.85 8.323C2.019 80 1.824 80 1.669 80c-.467 0-.856-.194-1.206-.467-.467-.466-.545-1.205-.39-1.828l8.325-20.847c.078-.194.155-.35.35-.544L49.783 15.28l14.936 14.935zm13.804-20.83c1.968 2.086 1.968 5.235 0 7.203l-8.58 8.58-15.113-15.113 8.58-8.58c1.968-1.968 5.117-1.968 7.202 0l7.911 7.91z"
        stroke="#1AB9C4"
        fill="none"
        fillRule="evenodd"
        strokeDasharray="3"
      />
    </svg>
  );
  /* eslint-enable */
}

export default PencilOutline;
