import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import { Icon } from '../';
import TweetIcon from '../icons/Tweet';

class TweetButton extends Component {
  static propTypes = {
    url: PropTypes.string,
  };

  static defaultProps = {
    url: null,
  };

  render() {
    const { url, styles, location } = this.props;
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://twitter.com/intent/tweet?url=${
          url ? url : `${config.frontendHost}${location.pathname}${location.search || ''}`
        }`}
        className={styles.button}
      >
        <Icon icon="Twitter" size={14} fill="#00ACEE" />
        <TweetIcon />
      </a>
    );
  }
}

const styles = props => ({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundImage: 'linear-gradient(-180deg, #FEFEFE 0%, #DFDFDF 100%)',
    border: '1px solid #CCCCCC',
    borderRadius: '2px',
    padding: '4px 3px',
    '> * + *': {
      marginLeft: '3px',
    },
    ':hover': {
      backgroundImage: 'linear-gradient(-180deg, #DFDFDF 0%, #DFDFDF 100%)',
    },
  },
});

export default felaConnect(styles)(withRouter(TweetButton));
