import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import theme from '../../../theme';

const LandingPageHero = isWebpSupported ? `/static/homePageImage.webp` : `/static/homePageImage.jpg`;

class Hero extends Component {
  static propTypes = {
    actions: PropTypes.array,
    calloutAction: PropTypes.node,
    backgroundImage: PropTypes.string,
    headline: PropTypes.string,
  };

  render() {
    const { headline, calloutAction, registered, styles } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div />
            <div className={styles.contentTitle}>
              <h1>
                {headline}
                {registered && <span className={styles.registered}>®</span>}
              </h1>
              <div className={styles.calloutAction}>{calloutAction}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = props => ({
  root: {
    backgroundImage: `url(${props.backgroundImage ? props.backgroundImage : LandingPageHero})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: 'auto',
    height: '100vh',
    position: 'relative',
    ':after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      // backgroundColor: 'rgba(138,109,91,.5)',
      height: '100%',
      width: '100%',
    },
    [props.theme.breakpoints.mobile]: {
      height: '650px',
    },
    '@media (max-width: 576px)': {
      backgroundPosition: '65% center',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    content: '" "',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 1,
    ...props.theme.globalPageWrapper,
    [props.theme.breakpoints.mobile]: {
      justifyContent: 'flex-start',
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginTop: '60px',
    width: '100%',
    margin: '0 auto',
  },
  contentTitle: {
    '> h1': {
      fontSize: '38px',
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansAltBold,
      fontStyle: 'italic',
      color: props.theme.colors.white,
      textAlign: 'center',
      marginBottom: '32px',

      [props.theme.breakpoints.mobile]: {
        fontSize: '45px',
        marginBottom: 0,
      },
    },
    [props.theme.breakpoints.mobile]: {
      justifyContent: 'flex-start',
    },
    // [props.theme.breakpoints.desktop]: {
    //   marginTop: '150px',
    // },
  },
  contentBottom: {
    textAlign: 'center',
  },
  calloutAction: {
    textAlign: 'center',
    [props.theme.breakpoints.mobile]: {
      marginTop: '50px',
    },
  },
  arrow: {
    marginTop: '32px',
    display: 'flex',
    justifyContent: 'center',
    [props.theme.breakpoints.mobile]: {
      display: 'none',
    },
  },
  registered: {
    fontSize: '24px',
    verticalAlign: 'super',
    marginLeft: '4px',
  },
  fkaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '80px',
    marginBottom: '18px',

    '> span': {
      fontFamily: theme.typography.sans,
      fontSize: '16px',
      color: theme.colors.white,
      marginBottom: '10px',
      textShadow: 'rgba(0, 0, 0, 0.2) 2px 1px',
    },

    '> span:nth-of-type(2)': {
      width: '200px',
      height: '30px',
      marginBottom: '-2px',
    },
    '> span > svg': {
      width: '150px',
      height: '22.5px',
    },
  },
});

export default felaConnect(styles)(Hero);
