import { createSelector } from 'reselect';
import isUnread from '../utils/is-unread';
import moment from 'moment';

const countUnreadMessages = (conversations, user, listing = null) => {
  let count = 0;
  if (!conversations || !user) {
    return count;
  }
  conversations.forEach(conversation => {
    if (conversation.get('conversations')) {
      count += countUnreadMessages(conversation.get('conversations'), user, conversation.get('listing'));
    } else {
      const conversationListing = listing || conversation.get('listing');
      conversation.get('messages').forEach(message => {
        if (isUnread(message, user, conversationListing)) {
          count += 1;
        }
      });
    }
  });
  return count;
};

const selectUnreadConversations = (conversations, user, listing = null) => {
  let conversationIds = [];
  if (!conversations || !user) {
    return conversationIds;
  }
  conversations.forEach(conversation => {
    if (conversation.get('conversations')) {
      if (conversation.get('conversations')) {
        conversationIds.push(
          ...selectUnreadConversations(conversation.get('conversations'), user, conversation.get('listing'))
        );
      }
    } else {
      if (conversationIds.indexOf(conversation.get('id')) < 0) {
        const conversationListing = listing || conversation.get('listing');
        const hasUnreadMessages = conversation.get('messages').filter(msg => isUnread(msg, user, conversationListing));

        if (hasUnreadMessages.size) {
          conversationIds.push(conversation.get('id'));
        }
      }
    }
  });
  return conversationIds;
};

export const conversations = createSelector(
  state => state.messages.conversations,
  conversations => {
    if (!conversations) {
      return conversations;
    }

    let sortedConversations = conversations;
    // sort conversations in listing groups
    for (let i = 0; i < sortedConversations.get('inbox').size; i++) {
      if (sortedConversations.getIn(['inbox', i, 'is_listing_group'])) {
        sortedConversations = sortedConversations.setIn(
          ['inbox', i, 'conversations'],
          sortedConversations.getIn(['inbox', i, 'conversations']).sort((conversationA, conversationB) => {
            const ACreatedAt = conversationA
              .get('messages')
              .last()
              .get('created_at');
            const BCreatedAt = conversationB
              .get('messages')
              .last()
              .get('created_at');
            if (moment(ACreatedAt).isBefore(BCreatedAt)) {
              return 1;
            } else if (moment(ACreatedAt).isAfter(BCreatedAt)) {
              return -1;
            } else {
              return 0;
            }
          })
        );
      }
    }
    return sortedConversations.set(
      'inbox',
      sortedConversations.get('inbox').sort((conversationGroupA, conversationGroupB) => {
        const ACreatedAt = conversationGroupA.get('is_listing_group')
          ? conversationGroupA
              .getIn(['conversations', 0, 'messages'])
              .last()
              .get('created_at')
          : conversationGroupA
              .get('messages')
              .last()
              .get('created_at');
        const BCreatedAt = conversationGroupB.get('is_listing_group')
          ? conversationGroupB
              .getIn(['conversations', 0, 'messages'])
              .last()
              .get('created_at')
          : conversationGroupB
              .get('messages')
              .last()
              .get('created_at');
        if (moment(ACreatedAt).isBefore(BCreatedAt)) {
          return 1;
        } else if (moment(ACreatedAt).isAfter(BCreatedAt)) {
          return -1;
        } else {
          return 0;
        }
      })
    );
  }
);

export const selectUnreadMessages = createSelector(
  state => state.messages.conversations.get('inbox'),
  state => state.user,
  countUnreadMessages
);

export const selectConversationsWithUnreadMessages = createSelector(
  state => state.messages.conversations.get('inbox'),
  state => state.user,
  selectUnreadConversations
);
