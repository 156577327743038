const systemMessagesTriggeredByBuyer = ['TESTIMONIAL_CREATED'];

export default function isUnread(message, user, listing) {
  return (
    message.get('read_at') === null &&
    ((message.get('author_id') && message.get('author_id') !== user.get('id')) ||
      (!message.get('author_id') && message.get('body') === 'RESTRICTED') ||
      // system message aimed at buyers
      (listing &&
        listing.get('seller') !== user.get('id') &&
        !message.get('author_id') &&
        systemMessagesTriggeredByBuyer.indexOf(message.get('body')) === -1) ||
      // system message aimed at sellers
      (listing &&
        listing.get('seller') === user.get('id') &&
        !message.get('author_id') &&
        systemMessagesTriggeredByBuyer.indexOf(message.get('body')) > -1))
  );
}
