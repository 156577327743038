import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateUser, setErrorBarMessage } from '../../redux/actions';
import { isLoggedInSelector } from '../../redux/selectors';
import ChangePassword from './ChangePassword';

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedInSelector(state),
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ updateUser, setErrorBarMessage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
