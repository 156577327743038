const nameSlugMap = {
  'Russkiy Toy': 'russkiy-toy',
  'Russian-European Laika': 'russianeuropean-laika',
  'East Siberian Laika': 'east-siberian-laika',
  'Serbian Hound': 'serbian-hound',
  'Schiller Hound': 'schiller-hound',
  Schapendoes: 'schapendoes',
  Sarplaninac: 'sarplaninac',
  'Saarloos Wolfdog': 'saarloos-wolfdog',
  'English Shepherd': 'english-shepherd',
  'English Pointer': 'english-pointer',
  'English Coonhound': 'english-coonhound',
  'English Bulldog': 'english-bulldog',
  'Dutch Smoushond': 'dutch-smoushond',
  Stabyhoun: 'stabyhoun',
  'Sporting Lucas Terrier': 'sporting-lucas-terrier',
  'American Eskimo': 'american-eskimo',
  'Bichon Frise': 'bichon-frise',
  'Boston Terrier': 'boston-terrier',
  'American Bulldog': 'american-bulldog',
  'Chinese Shar-Pei': 'chinese-shar-pei',
  'Chow Chow': 'chow-chow',
  Dalmatian: 'dalmatian',
  Eurasian: 'eurasian',
  'Finnish Spitz': 'finnish-spitz',
  'Spanish Water Dog': 'spanish-water-dog',
  'Spanish Mastiff': 'spanish-mastiff',
  'Pont-Audemer Spaniel': 'pont-audemer-spaniel',
  'French Brittany': 'french-brittany',
  'Picardy Spaniel': 'picardy spaniel',
  'French Spaniel': 'french-spaniel',
  'Blue Picardy Spaniel': 'blue-picardy-spaniel',
  'English Springer Spaniel': 'english-springer-spaniel',
  'German Rough-haired Pointer': 'german-roughhaired-pointer',
  'German Long-haired Pointer': 'german-longhaired-pointer',
  'Fresian Water Dog': 'fresian-water-dog',
  'Chien Francais Blanc et Orange': 'chien-francais-blanc-et-orange',
  'Finnish Hound': 'finnish-hound',
  'Estrela Mountain Dog': 'estrela-mountain-dog',
  'Spanish Hound': 'spanish-hound',
  'South Russian Shepherd Dog': 'south-russian-shepherd-dog',
  'Smooth Fox Terrier': 'smooth-fox-terrier',
  'Italian Hound': 'italian-hound',
  'Istrian Short-Haired Hound': 'istrian-shorthaired-hound',
  'Istrian Coarse-Haired Hound': 'istrian-coarsehaired-hound',
  'Icelandic Sheepdog': 'icelandic-sheepdog',
  'Hygen Hound': 'hygen-hound',
  'Hungarian Wirehaired Vizsla': 'hungarian-wirehaired-vizsla',
  'Hungarian Greyhound': 'hungarian-greyhound',
  Hovawart: 'hovawart',
  Hokkaido: 'hokkaido',
  'Hellenic Hound': 'hellenic-hound',
  'Hanoverian Scenthound': 'hanoverian-scenthound',
  Hamiltonstovare: 'hamiltonstovare',
  Affenpinscher: 'affenpinscher',
  'American Foxhound': 'american-foxhound',
  'Aidi (Atlas Mountain Dog)': 'aidi-atlas-mountain-dog',
  'Airedale Terrier': 'airedale-terrier',
  'French Bulldog': 'french-bulldog',
  Keeshond: 'keeshond',
  'Lhasa Apso': 'lhasa-apso',
  Lowchen: 'lowchen',
  Poodle: 'poodle',
  Schipperke: 'schipperke',
  'Scottish Terrier': 'scottish-terrier',
  'Shiba Inu': 'shiba-inu',
  'Tibetan Spaniel': 'tibetan-spaniel',
  'Tibetan Terrier': 'tibetan-terrier',
  Puli: 'puli',
  'Pyrenean Shepherd': 'pyrenean-shepherd',
  'Shetland Sheepdog': 'shetland-sheepdog',
  'Swedish Lapphund': 'swedish-lapphund',
  'Swedish Vallhund': 'swedish-vallhund',
  'American Staffordshire Terrier': 'american-staffordshire-terrier',
  'Australian Terrier': 'australian-terrier',
  'Bedlington Terrier': 'bedlington-terrier',
  'Border Terrier': 'border-terrier',
  'Bull Terrier': 'bull-terrier',
  'Cairn Terrier': 'cairn-terrier',
  'Dandie Dinmont Terrier': 'dandie-dinmont-terrier',
  'Fox Terrier': 'fox-terrier',
  'Glen of Imaal Terrier': 'glen-of-imaal-terrier',
  'Irish Terrier': 'irish-terrier',
  'Jack Russell Terrier': 'jack-russell-terrier',
  'Kerry Blue Terrier': 'kerry-blue-terrier',
  'Lakeland Terrier': 'lakeland-terrier',
  'Manchester Terrier': 'manchester-terrier',
  'Miniature Bull Terrier': 'miniature-bull-terrier',
  'Haldenstover (Halden Hound)': 'haldenstover-halden-hound',
  'Griffon Nivernais': 'griffon-nivernais',
  'Griffon Fauve de Bretagne': 'griffon-fauve-de-bretagne',
  'Griffon Bleu de Gascogne': 'griffon-bleu-de-gascogne',
  'Greenland Dog': 'greenland-dog',
  'Great Anglo-Francais White and Black Hound': 'great-anglo-francais-white-and-black-hound',
  'Great Anglo-Francais Tricolor Hound': 'great-anglo-francais-tricolor-hound',
  'Grand Griffon Vendeen': 'grand-griffon-vendeen',
  'Grand Gascon-Saintongeois': 'grand-gasconsaintongeois',
  'Grand Basset Griffon Vendeen': 'grand-basset-griffon-vendeen',
  'German Spitz': 'german-spitz',
  'Miniature Schnauzer': 'miniature-schnauzer',
  'Norfolk Terrier': 'norfolk-terrier',
  'Norwich Terrier': 'norwich-terrier',
  'Rat Terrier': 'rat-terrier',
  'Sealyham Terrier': 'sealyham-terrier',
  'Skye Terrier': 'skye-terrier',
  'Soft Coated Wheaten Terrier': 'soft-coated-wheaten-terrier',
  'Staffordshire Bull Terrier': 'staffordshire-bull-terrier',
  'Welsh Terrier': 'welsh-terrier',
  'West Highland White Terrier': 'west-highland-white-terrier',
  'Australian Cattle Dog': 'australian-cattle-dog',
  'Australian Kelpie': 'australian-kelpie',
  'Australian Shepherd': 'australian-shepherd',
  'Bearded Collie': 'bearded-collie',
  Beauceron: 'beauceron',
  'Belgian Laekenois': 'belgian-laekenois',
  'Belgian Malinois': 'belgian-malinois',
  'Belgian Sheepdog': 'belgian-sheepdog',
  'Belgian Tervuren': 'belgian-tervuren',
  Bergamasco: 'bergamasco',
  'Border Collie': 'border-collie',
  'Bouvier des Flandres': 'bouvier-des-flandres',
  Briard: 'briard',
  'Canaan Dog': 'canaan-dog',
  'Cardigan Welsh Corgi': 'cardigan-welsh-corgi',
  'Catahoula Leopard Dog': 'catahoula-leopard-dog',
  Collie: 'collie',
  'German Shepherd Dog': 'german-shepherd-dog',
  'Norwegian Buhund': 'norwegian-buhund',
  'Old English Sheepdog': 'old-english-sheepdog',
  'Pembroke Welsh Corgi': 'pembroke-welsh-corgi',
  'Polish Lowland Sheepdog': 'polish-lowland-sheepdog',
  Akbash: 'akbash',
  Akita: 'akita',
  'Alaskan Malamute': 'alaskan-malamute',
  'Anatolian Shepherd Dog': 'anatolian-shepherd-dog',
  'Bernese Mountain Dog': 'bernese-mountain-dog',
  'Black Russian Terrier': 'black-russian-terrier',
  Boxer: 'boxer',
  Bullmastiff: 'bullmastiff',
  'Caucasian Ovcharka': 'caucasian-ovcharka',
  Chinook: 'chinook',
  'Doberman Pinscher': 'doberman-pinscher',
  'Dogo Argentino': 'dogo-argentino',
  'Dogue de Bordeaux': 'dogue-de-bordeaux',
  'Fila Brasileiro': 'fila-brasileiro',
  'German Pinscher': 'german-pinscher',
  'Giant Schnauzer': 'giant-schnauzer',
  'Great Dane': 'great-dane',
  'Great Pyrenees': 'great-pyrenees',
  'Greater Swiss Mountain Dog': 'greater-swiss-mountain-dog',
  Komondor: 'komondor',
  Kuvasz: 'kuvasz',
  Mastiff: 'mastiff',
  'Neapolitan Mastiff': 'neapolitan-mastiff',
  Newfoundland: 'newfoundland',
  'Perro de Presa Canario': 'perro-de-presa-canario',
  'Portuguese Water Dog': 'portuguese-water-dog',
  Rottweiler: 'rottweiler',
  'Saint Bernard': 'saint-bernard',
  Samoyed: 'samoyed',
  'Siberian Husky': 'siberian-husky',
  'Standard Schnauzer': 'standard-schnauzer',
  'Tibetan Mastiff': 'tibetan-mastiff',
  'American Leopard Hound': 'american-leopard-hound',
  'American Bully': 'american-bully',
  'American Hairless Terrier': 'american-hairless-terrier',
  'American Pit Bull Terrier': 'american-pit-bull-terrier',
  'Anglo-Français de Petite Vénerie': 'anglo-francais-de-petite-venerie',
  Appenzeller: 'appenzeller',
  Ariegeois: 'ariegeois',
  'Austrian Black and Tan Hound': 'austrian-black-and-tan-hound',
  'Austrian Pinscher': 'austrian-pinscher',
  Barak: 'barak',
  Barbet: 'barbet',
  'Basset Artesien Normand': 'basset-artesien-normand',
  'Basset Bleu de Gascogne': 'basset-bleu-de-gascogne',
  'Basset Fauve de Bretagne': 'basset-fauve-de-bretagne',
  'Bavarian Mountain Hound': 'bavarian-mountain-hound',
  'Beagle Harrier': 'beagle-harrier',
  'Belgian Shepherd Dog': 'belgian-shepherd-dog',
  'Berger des Pyrenees': 'berger-des-pyrenees',
  'Berger Picard': 'berger-picard',
  Billy: 'billy',
  'Black Forest Hound': 'black-forest-hound',
  'Black Mouth Cur': 'black-mouth-cur',
  'Bouvier des Ardennes': 'bouvier-des-ardennes',
  'Bracco Italiano': 'bracco-italiano',
  "Braque d'Auvergne": 'braque-dauvergne',
  'Braque de Bourbonais': 'braque-de-bourbonais',
  "Braque de l'Ariege": 'braque-de-lariege',
  'Braque Francais': 'braque-francais',
  'Braque Saint-Germain': 'braque-saintgermain',
  'Briquet Griffon Vendeen': 'briquet-griffon-vendeen',
  'Majorca Shepherd': 'majorca-shepherd',
  'Canadian Eskimo Dog': 'canadian-eskimo-dog',
  'Cao de Castro Laboreiro': 'cao-de-castro-laboreiro',
  'Alapaha Blue Blood Bulldog': 'alapaha-blue-blood-bulldog',
  'Alaskan Husky': 'alaskan-husky',
  Aussiedoodle: 'aussiedoodle',
  Bassador: 'bassador',
  'Cane Corso': 'cane-corso',
  Cavapom: 'cavapom',
  Cavapoo: 'cavapoo',
  'Cesky Terrier': 'cesky-terrier',
  'Chart Polski': 'chart-polski',
  Chug: 'chug',
  Cockapoo: 'cockapoo',
  Doxiepoo: 'doxiepoo',
  'Dutch Shepherd': 'dutch-shepherd',
  'Entlebucher Mountain Dog': 'entlebucher-mountain-dog',
  'Finnish Lapphund': 'finnish-lapphund',
  Goldendoodle: 'goldendoodle',
  Labradoodle: 'labradoodle',
  'Large Munsterlander': 'large-munsterlander',
  Leonberger: 'leonberger',
  Lhasapoo: 'lhasapoo',
  Longdog: 'longdog',
  Lurcher: 'lurcher',
  Maltipoo: 'maltipoo',
  Peekapoo: 'peekapoo',
  Pomapoo: 'pomapoo',
  Pugapoo: 'pugapoo',
  Puggle: 'puggle',
  Schnoodle: 'schnoodle',
  Shihpoo: 'shihpoo',
  'Silken Windhound': 'silken-windhound',
  'Spanish Greyhound': 'spanish-greyhound',
  'Tosa Ken': 'tosa-ken',
  'Treeing Walker Coonhound': 'treeing-walker-coonhound',
  Xoloitzcuintli: 'xoloitzcuintli',
  "Chien d'Artois": 'chien-dartois',
  Yorkipoo: 'yorkipoo',
  'Lapinporokoira (Lapponian Herder)': 'lapinporokoira-lapponian-herder',
  'Lancashire Heeler': 'lancashire-heeler',
  'Lagotto Romagnolo': 'lagotto-romagnolo',
  Kromfohrlander: 'kromfohrlander',
  'Nederlandse Kooikerhondje': 'nederlandse-kooikerhondje',
  'Japanese Akita': 'japanese-akita',
  Jagdterrier: 'jagdterrier',
  'Norwegian Lundehund': 'norwegian-lundehund',
  'Louisiana Catahoula Leopard Dog': 'louisiana-catahoula-leopard-dog',
  'Kishu Ken': 'kishu-ken',
  'Karst Shepherd Dog': 'karst-shepherd-dog',
  'Karelian Bear Dog': 'karelian-bear-dog',
  'Kangal Dog': 'kangal-dog',
  'Kai Ken': 'kai-ken',
  Jindo: 'jindo',
  'Japanese Terrier': 'japanese-terrier',
  'Japanese Spitz': 'japanese-spitz',
  'Croatian Sheepdog': 'croatian-sheepdog',
  'Coton de Tulear': 'coton-de-tulear',
  'Romanian Mioritic Shepherd': 'romanian-mioritic-shepherd',
  'Chien Francais Blanc et Noir': 'chien-francais-blanc-et-noir',
  'Braque Francais (de Petite Taille)': 'braque-francais-de-petite-taille',
  'Braque Francais (de Grande Taille)': 'braque-francais-de-grande-taille',
  'Danish-Swedish Farmdog': 'danishswedish-farmdog',
  'Danish Broholmer': 'danish-broholmer',
  'Czechoslovakian Vlcak': 'czechoslovakian-vlcak',
  Dunker: 'dunker',
  Drever: 'drever',
  'Drentse Patrijshond': 'drentse-patrijshond',
  'Deutscher Wachtelhund': 'deutscher-wachtelhund',
  'Deutsche Bracke': 'deutsche-bracke',
  'Afghan Hound': 'afghan-hound',
  Azawakh: 'azawakh',
  Basenji: 'basenji',
  'Basset Hound': 'basset-hound',
  Beagle: 'beagle',
  'American Black and Tan Coonhound': 'american-black-and-tan-coonhound',
  Bloodhound: 'bloodhound',
  'Bluetick Coonhound': 'bluetick-coonhound',
  Borzoi: 'borzoi',
  "Cirneco dell'Etna": 'cirneco-delletna',
  Dachshund: 'dachshund',
  'English Foxhound': 'english-foxhound',
  Greyhound: 'greyhound',
  Harrier: 'harrier',
  'Ibizan Hound': 'ibizan-hound',
  'Irish Wolfhound': 'irish-wolfhound',
  'Norwegian Elkhound': 'norwegian-elkhound',
  Otterhound: 'otterhound',
  'Peruvian Inca Orchid': 'peruvian-inca-orchid',
  'Petit Basset Griffon Vendeen': 'petit-basset-griffon-vendeen',
  'Pharaoh Hound': 'pharaoh-hound',
  Plott: 'plott',
  'Portuguese Podengo': 'portuguese-podengo',
  'Redbone Coonhound': 'redbone-coonhound',
  'Rhodesian Ridgeback': 'rhodesian-ridgeback',
  Saluki: 'saluki',
  'Scottish Deerhound': 'scottish-deerhound',
  Sloughi: 'sloughi',
  'Thai Ridgeback': 'thai-ridgeback',
  Whippet: 'whippet',
  'Grand Bleu De Gascogne': 'grand-bleu-de-gascogne',
  'American Water Spaniel': 'american-water-spaniel',
  'Boykin Spaniel': 'boykin-spaniel',
  Brittany: 'brittany',
  'Chesapeake Bay Retriever': 'chesapeake-bay-retriever',
  'Clumber Spaniel': 'clumber-spaniel',
  'Cocker Spaniel': 'cocker-spaniel',
  'Curly-Coated Retriever': 'curly-coated-retriever',
  'English Cocker Spaniel': 'english-cocker-spaniel',
  'English Setter': 'english-setter',
  'White Shepherd': 'white-shepherd',
  'Field Spaniel': 'field-spaniel',
  'Flat Coated Retriever': 'flat-coated-retriever',
  'German Shorthaired Pointer': 'german-shorthaired-pointer',
  'German Wirehaired Pointer': 'german-wirehaired-pointer',
  'Golden Retriever': 'golden-retriever',
  'Gordon Setter': 'gordon-setter',
  'Irish Red and White Setter': 'irish-red-and-white-setter',
  'Irish Water Spaniel': 'irish-water-spaniel',
  'Labrador Retriever': 'labrador-retriever',
  'Nova Scotia Duck Tolling Retriever': 'nova-scotia-duck-tolling-retriever',
  Pointer: 'pointer',
  'Small Munsterlander Pointer': 'small-munsterlander-pointer',
  'Spinone Italiano': 'spinone-italiano',
  'Sussex Spaniel': 'sussex-spaniel',
  Vizsla: 'vizsla',
  Weimaraner: 'weimaraner',
  'Welsh Springer Spaniel': 'welsh-springer-spaniel',
  'Wirehaired Pointing Griffon': 'wirehaired-pointing-griffon',
  'Alentejo Mastiff': 'alentejo-mastiff',
  'Alpine Dachsbracke': 'alpine-drachsbracke',
  Bolognese: 'bolognese',
  'Carolina Dog': 'carolina-dog',
  'Catalonian Sheepdog': 'catalonian-sheepdog',
  'Cavalier King Charles Spaniel': 'cavalier-king-charles-spaniel',
  'Central Asian Shepherd': 'central-asian-shepherd',
  'Cesky Fousek': 'cesky-fousek',
  'Chien Francais Tricolore': 'chien-francais-tricolore',
  Chihuahua: 'chihuahua',
  'Chinese Crested': 'chinese-crested',
  'Cimarron Uruguayo': 'cimarron-uruguayo',
  'English Toy Spaniel': 'english-toy-spaniel',
  'Great Anglo-Francais White and Orange Hound': 'great-anglo-francais-white-and-orange-hound',
  Havanese: 'havanese',
  'Irish Setter': 'irish-setter',
  'Italian Greyhound': 'italian-greyhound',
  'Japanese Chin': 'japanese-chin',
  'Majorca Mastiff': 'majorca-mastiff',
  Maltese: 'maltese',
  'Maremma Sheepdog': 'maremma-sheepdog',
  'Miniature Pinscher': 'miniature-pinscher',
  'Montenegrin Mountain Hound': 'montenegrin-mountain-hound',
  'Mountain Cur': 'mountain-cur',
  Mudi: 'mudi',
  'Multi-Colored Poodle': 'multi-colored-poodle',
  'Multi-Colored Standard Poodle': 'mult-icolored-standard-poodle',
  Norrbottenspetz: 'norrbottenspetz',
  'Old Danish Pointing Dog': 'old-danish-pointing-dog',
  'Olde English Bulldogge': 'olde-english-bulldogge',
  Papillon: 'papillon',
  'Parson Russell Terrier': 'parson-russell-terrier',
  'Patterdale Terrier': 'patterdale-terrier',
  Pekingese: 'pekingese',
  'Spanish Pointer': 'spanish-pointer',
  'Petit Bleu de Gascogne': 'petit-bleu-de-gascogne',
  'Petit Gascon-Saintongeois': 'petit-gasconsaintongeois',
  'Podenco Canario': 'podenco-canario',
  'Posavac Hound': 'posavac-hound',
  Porcelaine: 'porcelaine',
  Poitevin: 'poitevin',
  'Polish Hound': 'polish-hound',
  'Tatra Shepherd': 'tatra-shepherd',
  Pomeranian: 'pomeranian',
  Pudelpointer: 'pudelpointer',
  Pug: 'pug',
  Pumi: 'pumi',
  'Pyrenean Mastiff': 'pyrenean-mastiff',
  'Serbian Tricolor Hound': 'serbian-tricolor-hound',
  'Shih Tzu': 'shih-tzu',
  Shikoku: 'shikoku',
  'Silky Terrier': 'silky-terrier',
  'Slovac Cuvac': 'slovac-cuvac',
  'Slovakian Wire-haired Pointing Dog': 'slovakian-wirehaired-pointing-dog',
  'Smaland Hound': 'smaland-hound',
  'Small Swiss Hound': 'small-swiss-hound',
  "Stephens' Cur": 'stephens-cur',
  'Stumpy Tail Cattle Dog': 'stumpy-tail-cattle-dog',
  'Styrian Coarse Haired Hound': 'styrian-coarse-haired-hound',
  'Swedish Elkhound': 'swedish-elkhound',
  'Swiss Hound': 'swiss-hound',
  'Teddy Roosevelt Terrier': 'teddy-roosevelt-terrier',
  Tornjak: 'tornjak',
  'Toy Fox Terrier': 'toy-fox-terrier',
  'Transylvanian Hound': 'transylvanian-hound',
  'Treeing Cur': 'treeing-cur',
  'Treeing Feist': 'treeing-feist',
  'Tyrolean Hound': 'tyrolean-hound',
  'Volpino Italiano': 'volpino-italiano',
  'Welsh Hound': 'welsh-hound',
  'West Siberian Laika': 'west-siberian-laika',
  'Westphalian Dachsbracke': 'westphalian-dachsbracke',
  'Wire Fox Terrier': 'wire-fox-terrier',
  'Yorkshire Terrier': 'yorkshire-terrier',
  'Alaskan Klee Kai': 'alaskan-klee-kai',
  'Portuguese Pointer': 'portuguese-pointer',
  'Portuguese Sheepdog': 'portuguese-sheepdog',
  'Toy Poodle': 'toy-poodle',
  'Brussels Griffon': 'brussels-griffon',
  'Miniature Poodle': 'miniature-poodle',
  Biewer: 'biewer',
  Boerboel: 'boerboel',
  'Miniature Australian Shepherd': 'miniature-australian-shepherd',
  'Miniature Dachshund': 'miniature-dachshund',
  Morkie: 'morkie',
  Bernedoodle: 'bernedoodle',
  Sheepadoodle: 'sheepadoodle',
  Shichon: 'shichon',
  Cavachon: 'cavachon',
  Pomsky: 'pomsky',
  'English Cream Golden Retriever': 'english-cream-golden-retriever',
  'Toy Australian Shepherd': 'toy-australian-shepherd',
  'Native American Indian Dog': 'native-american-indian-dog',
  'Standard Poodle': 'standard-poodle',
  'Miniature Shar-Pei': 'miniature-shar-pei',
  Frenchton: 'frenchton',
  'Mi-ki': 'miki',
  Havamalt: 'havamalt',
  Chorkie: 'chorkie',
  Shorkie: 'shorkie',
  Bichpoo: 'bichpoo',
  'King Shepherd': 'king-shepherd',
  'Golden Cocker Retriever': 'golden-cocker-retriever',
  Havapoo: 'havapoo',
  'Mal Shi': 'mal-shi',
  Shiranian: 'shiranian',
  Cockalier: 'cockalier',
  'Romanian Carpathian Shepherd': 'romanian-carpathian-shepherd',
  'Shorty Bull': 'shorty-bull',
  'McNab Shepherd': 'mcnab-shepherd',
  'English Mastiff': 'english-mastiff',
  'American Mastiff': 'american-mastiff',
  'Miniature American Shepherd': 'miniature-american-shepherd',
  'Miniature Bernedoodle': 'miniature-bernedoodle',
  'Golden Irish': 'golden-irish',
  'Miniature Golden Retriever': 'miniature-golden-retriever',
  'Saint Berdoodle': 'saint-berdoodle',
  'Irish Doodle': 'irish-doodle',
  Poochon: 'poochon',
  Tamaskan: 'tamaskan',
  Whoodle: 'whoodle',
  Poovanese: 'poovanese',
  'Springer Doodle': 'springer-doodle',
  'Ori Pei': 'ori-pei',
  Havaton: 'havaton',
  'Russian Tsvetnaya Bolonk': 'russian-tsvetnaya-bolonk',
  'Miniature Aussiedoodle': 'miniature-aussiedoodle',
  Pyredoodle: 'pyredoodle',
  Shepadoodle: 'shepadoodle',
  Chipoo: 'chipoo',
  Pomchi: 'pomchi',
  'Valley Bulldog': 'valley-bulldog',
  'Olde Boston Bulldogge': 'olde-boston-bulldogge',
  'Yakutian Laika': 'yakutian-aika',
  'Irish Wolfadoodle': 'irish-wolfadoodle',
  Bordoodle: 'bordoodle',
  'Golden Mountain Dog': 'golden-mountain-dog',
  'Australian Cobberdog': 'australian-cobberdog',
  'English Goldendoodle': 'english-goldendoodle',
  'Miniature Goldendoodle': 'miniature-goldendoodle',
  'Golden Mountain Doodle': 'golden-mountain-doodle',
  'Blue Lacy': 'blue-lacy',
  Mastador: 'mastador',
  'Bohemian Shepherd': 'bohemian-shepherd',
  'Basque Shepherd Dog': 'basque-shepherd-dog',
  'Chinese Chongqing': 'chinese-chongqing',
  'Miniature Labradoodle': 'miniature-labradoodle',
  Chiweenie: 'chiweenie',
  Beaglier: 'beaglier',
  Eskipoo: 'eskipoo',
  Goldador: 'goldador',
  'Double Doodle': 'double-doodle',
  Cavaton: 'cavaton',
  Goberian: 'goberian',
  Westiepoo: 'westiepoo',
  'Australian Labradoodle': 'australian-labradoodle',
  'Colombian Fino Hound': 'colombian-fino-hound',
  Malchi: 'malchi',
  Newfypoo: 'newfypoo',
  Aussalier: 'aussalier',
  Havachon: 'havachon',
  Huskydoodle: 'huskydoodle',
  Karakachan: 'karakachan ',
  Havashire: 'havashire',
  Pomimo: 'pomimo',
  'Golden Pyrenees': 'golden-pyrenees',
  Shepweiler: 'shepweiler',
  Maltipom: 'maltipom',
  'Peek-A-Pom': 'peek-a-pom',
  'Sprocker Spaniel': 'sprocker-spaniel',
  Bernefie: 'bernefie',
  Japug: 'japug',
  'Thai Bangkaew': 'thai-bangkaew',
  'Mini Yorkshire Aussie': 'mini-yorkshire-aussie',
};

export default function getSlugFromName(name) {
  return nameSlugMap[name];
}
