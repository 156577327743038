import React from 'react';

function Bookmark() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M3 1c-.552 0-1 .443-1 .995V22.17c0 .55.35.726.786.389l9.023-6.968 9.024 6.968c.434.335.786.164.786-.389V1.995c0-.55-.447-.995-1-.995H2.999z" />
    </svg>
  );
  /* eslint-enable */
}

export default Bookmark;
