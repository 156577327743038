import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonPlain,
  CircleAvatar,
  PlainLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  Icon,
  InputError,
  StarRating,
  Tooltip,
  ContentCapitalized,
} from '../';
import { Map } from 'immutable';
import generateInitials from '../../utils/generateInitials';
import { Field, withFormik } from 'formik';
import * as yup from 'yup';
import { connect as felaConnect } from 'react-fela';
import compose from 'lodash/flowRight';

class TestimonialModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    listing: PropTypes.instanceOf(Map),
    seller: PropTypes.instanceOf(Map),
    buyer: PropTypes.instanceOf(Map),
    testimonial: PropTypes.instanceOf(Map),
    isReply: PropTypes.bool,
  };

  state = {
    isHovered: false,
  };

  MAX_EDITS = 3;

  onClose = () => {
    this.props.resetForm();
    this.props.onClose();
  };

  renderStarRatingField() {
    const { errors, isReply, setFieldValue, styles, values } = this.props;

    if (isReply) {
      return null;
    }

    return (
      <Fragment>
        <div className={styles.overallRating}>
          <div>Overall Rating</div>
          <StarRating
            className={styles.starRating}
            name="rating"
            value={values.rating}
            onStarClick={value => {
              setFieldValue('rating', value);
            }}
          />
        </div>
        {errors.rating ? <InputError>{errors.rating}</InputError> : null}
      </Fragment>
    );
  }

  renderBuyerOrSeller() {
    const { styles, seller, buyer, isReply } = this.props;

    const user = isReply ? buyer : seller;

    return (
      <div className={styles.avatar}>
        <CircleAvatar
          size={54}
          photo={user ? user.get('photo') : null}
          initials={user ? generateInitials(user.get('last_initial'), user.get('first_name')) : 'AD'}
          alt={'user-avatar'}
        />
        <div className={styles.avatarInfo}>
          <span>{isReply ? 'Left by' : 'Sold by'}</span>
          <span>
            <strong>
              <ContentCapitalized>
                {user ? `${user.get('first_name')} ${user.get('last_initial')}` : 'Account Deleted'}
              </ContentCapitalized>
            </strong>
          </span>
        </div>
      </div>
    );
  }

  renderHeader() {
    const { isReply, testimonial } = this.props;
    let title;
    if (isReply) {
      title = 'Reply to Testimonial';
    } else if (testimonial) {
      title = 'Edit Testimonial';
    } else {
      title = 'Write Testimonial';
    }
    return <ModalHeader title={title} />;
  }

  renderEdits(edits) {
    const { styles, testimonial } = this.props;
    const { isHovered } = this.state;
    const MAX_EDITS = 3;
    const editsLeft = MAX_EDITS - edits;
    if (testimonial) {
      return (
        <div
          className={styles.hoverContainer}
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
        >
          <span className={styles.edits}>{editsLeft}</span>
          <span> {editsLeft === 1 ? 'edit' : 'edits'} left</span>
          {edits >= MAX_EDITS && (
            <div style={{ position: 'absolute', left: '50%' }}>
              <Tooltip show={isHovered}>
                <div>
                  <span>
                    You have exceeded your maximum number of edits. If you need assistance please reach out to customer
                    support.
                  </span>
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      );
    }
    return null;
  }

  renderForm() {
    const { isReply, values, listing, handleSubmit, status, styles, testimonial } = this.props;
    let edits;
    if (testimonial) {
      const testObj = testimonial.toJS();
      const type = isReply ? 'Reply' : 'Testimonial';
      edits = testObj.history ? testObj.history.filter(edit => edit.type === type).length : 0;
    }

    return (
      <form id="testimonial-listing-form" onSubmit={handleSubmit}>
        {this.renderHeader()}
        <ModalBody>
          {listing ? (
            <div className={styles.recipientInfo}>
              <input type="hidden" name="listing_id" value={listing.get('id')} />
              <div className={styles.avatar}>
                <CircleAvatar
                  size={54}
                  photo={listing.getIn(['photos', 0])}
                  placeholderIcon={listing ? 'PuppyCircle' : null}
                  alt={'puppy-photo'}
                />
                <div className={styles.avatarInfo}>
                  <PlainLink
                    variant="blue"
                    to={`/listings/${listing.get('id')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{listing.get('name')}</span>
                  </PlainLink>
                  <span className={styles.listingPrice}>
                    <strong>${listing.get('price')}</strong>
                  </span>
                </div>
              </div>
              {this.renderBuyerOrSeller()}
            </div>
          ) : (
            <div className={styles.singleRecipientInfo}>{this.renderBuyerOrSeller()}</div>
          )}
          <Field name="body" component={Textarea} rows={8} maxLength={2000} value={values.body} aria-label="review" />
          {this.renderStarRatingField()}
          {status && status.error ? <InputError>{status.error}</InputError> : null}
        </ModalBody>
        <ModalFooter
          actions={[
            <ButtonPlain type="button" variant="red" onClick={this.onClose}>
              Cancel
            </ButtonPlain>,
            <div className={styles.editsContainer}>
              {this.renderEdits(edits)}
              <Button data-testid="submit-testimonial-button" type="submit" buttonType="round" disabled={edits > 2}>
                Submit {isReply ? 'Reply' : 'Testimonial'}
              </Button>
            </div>,
          ]}
        />
      </form>
    );
  }

  renderSuccess() {
    const { isReply, status, styles } = this.props;

    return status && status.success ? (
      <ModalBody className={styles.successModalBody}>
        <Icon icon="SpeechBubble" size={55} />
        {isReply ? (
          <h1>
            <strong>Reply Submitted</strong>
            <br />
            Thanks!
          </h1>
        ) : (
          <h1>
            <strong>Thanks for your testimonial!</strong>
          </h1>
        )}
      </ModalBody>
    ) : null;
  }

  render() {
    const { isOpen, status } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={this.onClose} closeOnOutsideClick closeOnEscape>
        {status && status.success ? this.renderSuccess() : this.renderForm()}
      </Modal>
    );
  }
}

const styles = props => ({
  successModalBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: props.theme.colors.blue,
    padding: '120px 40px !important',
    color: props.theme.colors.white,
    '> h1': {
      fontWeight: props.theme.typography.sansBold,
      fontSize: '35px',
      lineHeight: '47px',
      marginBottom: '42px',
      marginTop: '20px',
    },
    height: '100%',
  },
  overallRating: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '20px',
    '> * + *': {
      marginTop: '10px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      '> * + *': {
        marginTop: 0,
        marginLeft: '30px',
      },
    },
  },
  recipientInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '40px',
    fontSize: '14px',
    lineHeight: '19px',
    textAlign: 'left',
    '> * + *': {
      marginTop: '32px',
    },
    [props.theme.breakpoints.mobileSmall]: {
      flexDirection: 'row',
      alignItems: 'center',
      '> * + *': {
        marginTop: 0,
        borderLeft: props.theme.globalDashedBorder,
        paddingLeft: '10px',
      },
    },
    [props.theme.breakpoints.tablet]: {
      '> * + *': {
        paddingLeft: '40px',
      },
    },
  },
  singleRecipientInfo: {
    marginBottom: '40px',
    fontSize: '14px',
    lineHeight: '19px',
    textAlign: 'left',
    '> * + *': {
      marginTop: '32px',
    },
    [props.theme.breakpoints.mobileSmall]: {
      flexDirection: 'row',
      alignItems: 'center',
      '> * + *': {
        marginTop: 0,
        borderLeft: props.theme.globalDashedBorder,
        paddingLeft: '10px',
      },
    },
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '16px',
    },
    [props.theme.breakpoints.mobileSmall]: {
      width: '50%',
      ':first-of-type': {
        paddingRight: '10px',
      },
    },
    [props.theme.breakpoints.tablet]: {
      ':first-of-type': {
        paddingLeft: '40px',
      },
    },
  },
  avatarInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    overflow: 'hidden',
    '> *': {
      width: '65%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  listingPrice: {
    color: props.theme.colors.green,
  },
  starRating: {
    '> .dv-star-rating-star': {
      marginLeft: '8px',
    },
  },
  edits: {
    fontWeight: 'bold',
  },
  hoverContainer: {
    marginRight: '16px',
    position: 'inherit',
  },
  editsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    '>div': {
      margin: '0 0 16px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',

      '>div': {
        margin: '0 16px',
      },
    },
  },
});

export default compose(
  withFormik({
    mapPropsToValues: props => {
      const values = {
        rating: props.testimonial ? props.testimonial.get('rating') : 0,
      };
      if (props.isReply) {
        values.body = props.testimonial.get('reply') || '';
      } else {
        values.body = props.testimonial ? props.testimonial.get('body') || '' : '';
      }
      if (props.listing) {
        values.listing_id = props.listing.get('id');
      }
      return values;
    },
    validationSchema: yup.object().shape({
      rating: yup
        .number()
        .min(1, 'Please rate the seller')
        .required(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: (values, { props, resetForm, setStatus, setSubmitting }) => {
      const submitMethod = determineSubmitMethod({ props, values });
      submitMethod().then(action => {
        if (action.response.ok) {
          resetForm();
          setStatus({ success: true });
        } else {
          setStatus({ error: 'Could not complete request at this time' });
          setSubmitting(false);
        }
      });
    },
  }),
  felaConnect(styles)
)(TestimonialModal);

function determineSubmitMethod({ props, values }) {
  const { isReply, testimonial, seller, listing } = props;

  if (isReply) {
    return props.replyToTestimonial.bind(this, testimonial.get('id'), values.body);
  }

  let seller_id;
  if (seller) {
    seller_id = seller.get('id');
  } else if (testimonial) {
    seller_id = testimonial.get('seller_id') ? testimonial.get('seller_id') : testimonial.getIn(['listing', 'seller']);
  } else if (listing) {
    seller_id = listing.get('seller');
  }
  return props.createOrUpdateTestimonial.bind(this, seller_id, values);
}
