import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import classNames from 'classnames';

class InputError extends Component {
  static propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    styles: PropTypes.object.isRequired,
    password: PropTypes.bool,
  };

  static defaultProps = { disabled: false, children: null };

  render() {
    const { children, disabled, styles, fixederror } = this.props;
    if (children && !disabled) {
      return <div className={classNames([styles.root, fixederror && styles.fixedError])}>{children}</div>;
    }
    return null;
  }
}

const styles = {
  root: props => ({
    textAlign: 'left',
    color: props.theme.colors.red,
    fontSize: '12px',
    marginTop: '6px',
    '::first-letter': {
      textTransform: 'uppercase',
    },
  }),
  fixedError: props => ({
    position: props.password ? 'relative' : 'absolute',
    whiteSpace: props.password ? 'normal' : 'pre',
  }),
};

export default connect(styles)(InputError);
