import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  searchFavoriteListings,
  resetSearchFavoriteListings,
  searchFavoritePremiers,
  resetSearchFavoritePremiers,
  removeFavoriteListing,
  removeFavoritePremier,
} from '../../redux/actions';

import Favorites from './Favorites';
import { getRandomTips } from '../../../../redux/actions';
import { favoriteListingsResultsSelector } from '../../redux/selectors';

const mapStateToProps = state => {
  return {
    listingCount: favoriteListingsResultsSelector(state).size,
    premierCount: state.account.favoritePremiersResults.size,
    loaded: state.account.favoriteListingsLoaded && state.account.favoritePremiersLoaded,
    favoritesRefresh: state.account.favoritesRefresh,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchFavoriteListings,
      resetSearchFavoriteListings,
      getRandomTips,
      searchFavoritePremiers,
      resetSearchFavoritePremiers,
      removeFavoriteListing,
      removeFavoritePremier,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
