import React from 'react';
import { Button, Icon } from '../../../../../common/components';
import formatPrice from '../../../../../common/utils/formatPrice';
import theme from '../../../../../theme';

const PremierCard = ({ styles, onSelectPlan, membershipPlans, userVM, hasRedeemedCoupon }) => {
  const [selectedPlan, setSelectedPlan] = React.useState(membershipPlans.get('premier'));
  const hasCanceledSubscription = userVM.get('isPremier') && !userVM.get('hasActiveSubscription');
  const price =
    selectedPlan.get('id') === membershipPlans.getIn(['premier', 'id'])
      ? userVM.get('premierPrice')
      : userVM.get('premierYearlyPrice');

  return (
    <div className={styles.premierCard}>
      <div className={styles.premierCardLeft} style={{ paddingTop: 0, paddingBottom: '16px' }}>
        {!hasRedeemedCoupon && (
          <div className={styles.savingsChip}>
            SAVE {selectedPlan.get('renewal_frequency') === 'monthly' ? '33%' : '44%'}
          </div>
        )}
        <div className={styles.premierPuppyIcons}>
          <Icon icon="Puppy" size={24} fill={theme.colors.premierBlue} className={styles.premierPuppyIcon} />
          <Icon icon="PuppyWithCrown" size={48} fill={theme.colors.premierBlue} />
          <Icon icon="Puppy" size={24} fill={theme.colors.premierBlue} className={styles.premierPuppyIcon} />
        </div>
        <h3 className={styles.membershipCardTitle}>Premier Membership</h3>
        <div className={styles.recurringText}>Recurring {selectedPlan.get('renewal_frequency')}, cancel anytime</div>
        <div className={styles.membershipCardPayment} style={{ color: theme.colors.premierBlue }}>
          <h4>{formatPrice(price)}</h4>
        </div>

        <div className={styles.premierPlans}>
          <Button
            className={[
              styles.premierPlanButtonPadding,
              selectedPlan.get('id') === membershipPlans.getIn(['premier', 'id']) && styles.currentPlan,
            ]}
            fontSize="11px"
            type="button"
            buttonType="brandBlue"
            onClick={() => setSelectedPlan(membershipPlans.get('premier'))}
          >
            Per month
          </Button>
          <Button
            className={[
              styles.premierPlanButtonPadding,
              selectedPlan.get('id') === membershipPlans.getIn(['premier_yearly', 'id']) && styles.currentPlan,
            ]}
            fontSize="11px"
            type="button"
            buttonType="brandBlue"
            onClick={() => setSelectedPlan(membershipPlans.get('premier_yearly'))}
          >
            Per year
          </Button>
        </div>
      </div>
      <div className={styles.premierCardRight}>
        <div className={styles.premierPlanInfoContainer}>
          <h4>All paid plan features, plus:</h4>
          <ul className={styles.planPerksList}>
            <li>Post up to 40 listings.</li>
            <li>Search engine optimized premier profile with custom Puppies.com URL.</li>
            <li>Public contact info on profile.</li>
            <li>Premier badge on listings.</li>
            <li>Breeder news updates on profile - keep viewers informed.</li>
          </ul>
        </div>
        <div className={styles.planActionContainer} style={{ background: '#0085F2', color: '#ffffff' }}>
          <h4>Get a Premier Membership!</h4>
          <p>
            {formatPrice(price)}, recurring {selectedPlan.get('renewal_frequency')}
          </p>
          <Button
            id="select-premier-membership"
            type="button"
            buttonType="round"
            fontSize="16px"
            fullWidth
            onClick={() => {
              onSelectPlan(selectedPlan.get('id'));
            }}
          >
            {hasCanceledSubscription ? 'RE-SUBSCRIBE' : 'SELECT PLAN'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PremierCard;
