import { handleFileUpload } from './handleFileUpload';

export default function handleMultipleUploads(files, uploadTokenFunc, pathPrefix) {
  return Promise.all(
    files.map(async (file, index) => {
      if (file.needsUpload || file.needsCroppedUpload) {
        file.sort_index = index;

        if (file.needsUpload) {
          const result = await handleFileUpload({ getUploadToken: uploadTokenFunc, file: file.value, key: pathPrefix });
          if (result instanceof Error) {
            return result;
          }
          file.key = result?.public_id;
          file.type = result?.type;
          delete file.needsUpload;
        }
        if (file.needsCroppedUpload) {
          const result = await handleFileUpload({
            getUploadToken: uploadTokenFunc,
            file: file.croppedImage,
            key: pathPrefix,
          });
          if (result instanceof Error) {
            return result;
          }
          file.croppedKey = result?.public_id;
          file.type = result?.type;
          delete file.needsCroppedUpload;
        }
      }
      return file;
    })
  );
}
