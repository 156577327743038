import styled from 'styled-components';

import theme from '../../../theme';

export const ErrorContainer = styled.div`
  border: 4px solid ${theme.colors.red};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 150px;
  height: 150px;
  color: ${theme.colors.red};

  span:last-child {
    margin-top: 15px;
    padding: 0 15px;
    font-size: 12px;
    text-align: center;
  }
`;

export const ErrorText = styled.div`
  margin-top: 25px;

  div {
    margin-top: 0;
  }
`;

export const Heading = styled.h3`
  color: ${theme.colors.orange};
  font-weight: bold;
  line-height: 1.25;
`;

export const PhotoGallery = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -25px -25px 0;
  width: 100%;

  > div {
    margin: 0 25px 25px 0;
  }
`;

export const Subheading = styled.p`
  margin: 20px 0 25px;
  opacity: 0.75;
  color: ${theme.colors.brown};
  font-size: 14px;
`;

export const UploadContainer = styled.div`
  border: 4px solid ${theme.colors.blue};
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 150px;
  height: 150px;
  color: ${theme.colors.blue};

  span:last-child {
    margin-top: 15px;
    font-weight: bold;
  }
`;
