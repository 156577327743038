import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BreedInformation from './BreedInformation';
import { getBreedInformation } from '../../redux/actions';
import { searchListings } from '../../views/SearchListings/redux/actions';
import { setActiveModal } from '../Modal/redux/actions';

const mapStateToProps = state => ({
  user: state.user,
  breedInformation: state.breedInformation,
  loading: state.breedInformationLoading,
  error: state.breedInformationErrors,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ getBreedInformation, searchListings, setActiveModal }, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(BreedInformation);
