import client from '../../../common/utils/client';

export const sendMessageToUser = (user_id, body, requestVerification) => ({
  promise: client.post(`conversations`, { body, user_id, requestVerification }),
  type: 'SEND_MESSAGE_TO_USER',
});

export const resetSendMessageToUser = () => ({
  type: 'SEND_MESSAGE_TO_USER_RESET',
});

export const sendMessageToListingOwner = (listing_id, body, requestVerification) => ({
  promise: client.post(`conversations`, { body, listing_id, requestVerification }),
  type: 'SEND_MESSAGE_TO_LISTING_OWNER',
});

export const resetSendMessageToListingOwner = () => ({
  type: 'SEND_MESSAGE_TO_LISTING_OWNER_RESET',
});

export const getConversations = userId => ({
  promise: client.get(`conversations`),
  type: 'GET_CONVERSATIONS',
});

export const getArchivedConversations = userId => ({
  promise: client.get(`conversations?archived=true`),
  type: 'GET_ARCHIVED_CONVERSATIONS',
});

export const getMoreConversations = (page, size = 40) => ({
  promise: client.get(`conversations?page=${page}&page_size=${size}`),
  type: 'GET_MORE_CONVERSATIONS',
});

export const getMoreArchivedConversations = (page, size = 40) => ({
  promise: client.get(`conversations?archived=true&page=${page}&page_size=${size}`),
  type: 'GET_MORE_ARCHIVED_CONVERSATIONS',
});

export const replyToConversation = (conversationId, body, listingId = null, photos) => ({
  promise: client.post(`conversations/${conversationId}/messages`, { body, photos }),
  type: 'REPLY_TO_CONVERSATION',
  listingId,
});

export const markConversationAsRead = conversationId => ({
  promise: client.put(`conversations/${conversationId}/mark-as-read`),
  type: 'MARK_CONVERSATION_AS_READ',
  conversationId,
});

export const archiveConversation = conversationId => ({
  promise: client.put(`conversations/${conversationId}/status`, { archived: true }),
  type: 'ARCHIVE_CONVERSATION',
  conversationId,
});

export const unarchiveConversation = conversationId => ({
  promise: client.put(`conversations/${conversationId}/status`, { archived: false }),
  type: 'UNARCHIVE_CONVERSATION',
});

export const getConversation = conversationId => ({
  promise: client.get(`conversations/${conversationId}`),
  type: 'GET_CONVERSATION',
  conversationId,
});

export const resetConversationLoaded = () => ({
  type: 'RESET_CONVERSATION_LOADED',
});
export const deleteMessagePhoto = (message_id, public_id) => ({
  promise: client.post(`messages/${message_id}/photos/delete`, { public_id }),
  type: 'DELETE_MESSAGE_PHOTO',
});
