import React, { Component } from 'react';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';
import PropTypes from 'prop-types';
import { Icon } from '../../common/components/';
import theme from '../../theme';

import { breedRatings } from '../../common/lib/breedGroups';

class RatingBar extends Component {
  static propTypes = {
    breedInformation: PropTypes.object.isRequired,
  };

  state = {
    isCollapsed: true,
  };

  toggleCollapsed = () => this.setState(state => ({ isCollapsed: !state.isCollapsed }));

  ratingDescriptor = (breedRatings, data, styles) => {
    return breedRatings.map(rating => {
      if (data.get(rating.value)) {
        return (
          <div className={styles.characteristic} key={rating.value}>
            <div className={styles.valueType}>{rating.description}</div>
            <div className={styles.valueBarBackground}>
              <div className={styles.valueBar} style={{ width: `${(data.get(rating.value) / rating.max) * 100}%` }} />
            </div>
          </div>
        );
      }
      return null;
    });
  };

  breedCharacteristics = this.ratingDescriptor(breedRatings, this.props.breedInformation, this.props.styles);

  render() {
    const { styles, isMobile, breedInformation } = this.props;
    const { isCollapsed } = this.state;
    const breedName = breedInformation.get('name');

    return (
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <h2>{breedName} Characteristics</h2>
          </div>
          <div className={styles.characteristics}>
            {isMobile && isCollapsed ? this.breedCharacteristics.slice(0, 9) : this.breedCharacteristics}
          </div>
          {isMobile && (
            <div className={styles.collapseToggleButton} onClick={this.toggleCollapsed}>
              {<Icon icon="CaretDown" fill={theme.colors.blue} rotate={!isCollapsed ? 180 : null} />}
              <span>{isCollapsed ? 'See more characteristics...' : 'See less characteristics...'}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.browser.lessThan.mobileLarge,
});

const styles = props => ({
  root: {
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
    ...props.theme.globalPadding,
  },
  wrapper: {
    backgroundColor: props.theme.colors.tan,
    borderRadius: '4px',
  },
  title: {
    ...props.theme.typography.titleText,
    backgroundColor: props.theme.colors.darkerTan,
    lineHeight: '34px',
    padding: '11px',
    textAlign: 'center',
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
  },
  characteristics: {
    padding: '20px 20px 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    ':after': {
      content: '" "',
      flex: '0 0 100%',
      [props.theme.breakpoints.mobile]: {
        flex: '0 0 31%',
      },
    },
  },
  characteristic: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '40px',
    flexBasis: '100%',
    '> * + *': {
      marginTop: '12px',
    },
    [props.theme.breakpoints.mobile]: {
      flexBasis: '31%',
    },
  },
  valueType: {
    fontSize: '14px',
    color: props.theme.colors.brown,
  },
  valueBarBackground: {
    width: '100%',
    height: '10px',
    borderRadius: '5px',
    backgroundColor: '#CAC1B6',
  },
  valueBar: {
    height: '10px',
    borderRadius: '5px',
    backgroundColor: props.theme.colors.blue,
  },
  collapseToggleButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${props.theme.colors.darkerTan}`,
    padding: '20px',
    fontSize: '14px',
    color: props.theme.colors.brown,
    fontWeight: props.theme.typography.sansBold,
    '> * + *': {
      marginLeft: '8px',
    },
  },
});

export default connect(mapStateToProps)(felaConnect(styles)(RatingBar));
