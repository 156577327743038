import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import theme from '../../../theme';
import Icon from '../icons/icon';
import { lockScroll, unlockScroll } from '../../utils/scroll-lock';

class MobileMenu extends Component {
  state = { showMenu: false, showExpandedMenu: false };

  toggleMenu = () => {
    const isOpen = !this.state.showMenu;
    this.setState({ showMenu: isOpen });

    if (isOpen) {
      lockScroll();
    } else {
      unlockScroll();
    }
  };

  toggleExpandedMenu = e => {
    e.preventDefault();
    this.setState(state => ({ showExpandedMenu: !state.showExpandedMenu }));
  };

  closeMenu = () => {
    this.setState({ showMenu: false });
  };

  handleLinkOnClick = callback => {
    this.toggleMenu();
    if (callback) {
      callback();
    }
  };

  handleDomClick = e => {
    if (!this.ref.contains(e.target) && this.state.showMenu) {
      this.closeMenu();
    }
  };

  renderMenuHeader = () => {
    const { iconLinks, isLoggedIn, styles } = this.props;
    if (!isLoggedIn) {
      return (
        <div className={classNames([styles.menuHeaderButtons, 'menu-header-tablet'])}>
          <Link className={styles.menuHeaderButton} to="/sign-up">
            <Icon icon="Puppy" size={14} />
            <span>Sign Up</span>
          </Link>

          <Link className={styles.menuHeaderButton} to="/sign-in">
            <Icon icon="Puppy" size={14} />
            <span>Sign In</span>
          </Link>
        </div>
      );
    }
    return iconLinks;
  };

  render() {
    const { styles, links, expandedMenuLinks, expandedMenuTitle, notifications } = this.props;
    const { showMenu, showExpandedMenu } = this.state;

    return (
      <div className={styles.menuHeaderButtonWrapper}>
        {this.renderMenuHeader()}
        <div
          ref={node => {
            this.ref = node;
          }}
          id="mobile-main-menu-tip"
        >
          <div className={styles.icon} onClick={this.toggleMenu}>
            {showMenu ? (
              <Icon icon="Close" fill={theme.colors.brown} size={25} />
            ) : (
              <Icon icon="Menu" notifications={notifications} fill={theme.colors.lightBrown} size={40} />
            )}
          </div>

          {showMenu && (
            <div className={styles.menuContainer} style={showMenu ? { top: '82px' } : { top: 'calc(-100vh)' }}>
              <div className={styles.menu}>
                <div className={styles.menuHeader} onClick={this.toggleMenu}>
                  {this.renderMenuHeader()}
                </div>
                <div className={styles.menuLinks}>
                  {links.map((link, i) => {
                    if (link.name === 'expandableMenuSlot') {
                      if (expandedMenuLinks) {
                        return (
                          <>
                            <a href="/" onClick={this.toggleExpandedMenu} className={styles.menuLinkIcon}>
                              <span>{expandedMenuTitle}</span>{' '}
                              <Icon size={28} icon="CaretDown" rotate={showExpandedMenu ? 180 : null} />
                            </a>
                            {showExpandedMenu ? (
                              <div className={styles.menuLinks + ' ' + styles.menuExpanded}>
                                {expandedMenuLinks.map((link, i) => {
                                  if (!link.onMobile) {
                                    return null;
                                  } else if (link.onClick) {
                                    return (
                                      <button key={i} onClick={() => this.handleLinkOnClick(link.onClick)}>
                                        {link.name}
                                      </button>
                                    );
                                  }
                                  return (
                                    <Link onClick={this.toggleMenu} key={i} to={link.to}>
                                      {link.name}
                                    </Link>
                                  );
                                })}
                              </div>
                            ) : null}
                          </>
                        );
                      }
                      return null;
                    } else {
                      if (link.onClick) {
                        return (
                          <button key={i} onClick={() => this.handleLinkOnClick(link.onClick)}>
                            {link.name}
                          </button>
                        );
                      }

                      if (link.to.indexOf('https') >= 0) {
                        return (
                          <a href={link.to} target="_blank" rel="noreferrer" className={styles.navLink}>
                            {link.name}
                          </a>
                        );
                      }

                      return (
                        <Link onClick={this.toggleMenu} key={i} to={link.to}>
                          {link.tip ? <span id={link.tip}>{link.name}</span> : link.name}
                        </Link>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

MobileMenu.propTypes = {
  children: PropTypes.any,
  links: PropTypes.array,
  expandedMenuLinks: PropTypes.array,
  expandedMenuTitle: PropTypes.string,
  iconLinks: PropTypes.node,
  notifications: PropTypes.number,
};

const styles = props => ({
  icon: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuContainer: {
    position: 'absolute',
    height: 'calc(100vh - 82px)',
    width: '100%',
    left: 0,
    transition: '0.2s',
    zIndex: '-1',
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    width: '100%',
    backgroundColor: props.theme.colors.tan,
    left: 0,
    zIndex: 9999,
    overflowY: 'scroll',
    height: 'calc(100vh - 82px)',
    transition: '0.2s',
  },
  menuExpanded: {
    backgroundColor: props.theme.colors.darkTan,
    position: 'static',
    boxShadow: 'none',
    borderBottom: 'none',
    padding: '0 20px !important',
  },
  menuHeaderButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
    '& > .menu-header-tablet': {
      '@media (max-width: 600px)': {
        display: 'none',
      },
    },
  },
  menuHeader: {
    display: 'none',
    justifyContent: 'space-around',
    '@media (max-width: 600px)': {
      display: 'flex ',
    },
  },
  menuLinks: {
    padding: '0 20px',
    '> a': {
      padding: '10px 0',
      display: 'flex',
      fontSize: '18px',
      color: props.theme.typography.bodyColor,
      textTransform: 'uppercase',
      fontFamily: props.theme.typography.sansAlt,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      '> * + *': {
        marginLeft: '10px',
      },
    },
    '> a:first-of-type': {
      marginTop: '20px',
    },
    '> a:last-of-type': {
      marginBottom: '20px',
    },

    '> button': {
      padding: '10px 0',
      display: 'flex',
      fontSize: '18px',
      color: props.theme.typography.bodyColor,
      textTransform: 'uppercase',
      fontFamily: props.theme.typography.sansAlt,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      '> * + *': {
        marginLeft: '10px',
      },
    },
  },
  menuLinkIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuHeaderButtons: {
    width: '100%',
    maxWidth: '320px',
    display: 'flex',
    alignItems: 'center',
    ...props.theme.globalPadding,
    marginTop: '0px',
    padding: '10px 16px 10px 10px',
    '> * + *': {
      marginLeft: '20px',
    },
    '@media (max-width: 600px)': {
      marginTop: '20px',
    },
  },
  menuHeaderButton: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    borderRadius: '40px',
    border: `2px solid ${props.theme.colors.blue}`,
    color: props.theme.colors.blue,
    fontSize: '12px',
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltMedium,
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    '> * + *': {
      marginLeft: '4px',
    },
  },
});

export default felaConnect(styles)(MobileMenu);
