import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';
import { List } from 'immutable';
import querystring from 'query-string';

import Article from '../common/Article';
import { fetchArticles, resetArticles } from '../redux/actions';
import { Pager, Loading, NoContentPlaceholder } from '../../../common/components';

const PAGE_SIZE = 3;

class ArticleList extends Component {
  static propTypes = {
    articles: PropTypes.instanceOf(List),
    total: PropTypes.number,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    articles: List(),
  };

  static fetchData(location, match, user) {
    let { page, page_size } = querystring.parse(location.search);

    page = page ? page : 0;
    page_size = page_size ? page_size : PAGE_SIZE;

    return [fetchArticles(false, false, page, page_size)];
  }

  componentDidMount() {
    const { loaded, match, location, user, dispatch } = this.props;

    if (!loaded) {
      ArticleList.fetchData(location, match, user).forEach(action => dispatch(action));
    }
  }

  componentDidUpdate(prevProps) {
    const { location, match, user, dispatch, loaded } = this.props;

    const locationChanged = prevProps.location !== location;
    const loadedStatusChanged = prevProps.loaded && !loaded;
    if (locationChanged || loadedStatusChanged) {
      ArticleList.fetchData(location, match, user).forEach(action => dispatch(action));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetArticles());
  }

  renderPager = () => {
    const { total } = this.props;

    if (total <= PAGE_SIZE) {
      return null;
    }

    return <Pager totalItems={total} pageSize={PAGE_SIZE} />;
  };

  render() {
    const { articles, styles, loading } = this.props;

    if (loading) {
      return <Loading center dark />;
    }

    if (!articles.size) {
      return <NoContentPlaceholder title="No results found." />;
    }

    return (
      <div style={{ width: '100%' }}>
        <div className={styles.articleList}>
          {articles.map(article => (
            <Article
              key={article.getIn(['sys', 'id'])}
              article={article.get('fields')}
              preview
              id={article.getIn(['sys', 'id'])}
            />
          ))}
        </div>
        {this.renderPager()}
      </div>
    );
  }
}

const styles = props => ({
  articleList: {
    marginBottom: '50px',
    '> * + *': {
      marginTop: '80px',
    },
  },
});

const mapStateToProps = state => ({
  loading: state.articles.loading,
  articles: state.articles.articleList.get('list'),
  total: state.articles.articleList.get('total'),
  loaded: state.articles.articleList.get('loaded'),
});

export default connect(mapStateToProps)(felaConnect(styles)(ArticleList));
