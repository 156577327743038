import ContactSellerModal from './ContactSellerModal';
import { connect } from 'react-redux';
import { sendMessageToUser } from '../../../views/Messages/redux/actions';
import { bindActionCreators } from 'redux';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({ sendMessageToUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactSellerModal);
