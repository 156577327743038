import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';

import FsAd from '../Freestar';

class AdSkyscraper extends Component {
  render() {
    const { styles } = this.props;
    return (
      <div className={styles.root}>
        <FsAd slotId={this.props.slotId} placementName={this.props.placementName} />
      </div>
    );
  }
}

const styles = props => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: props.theme.colors.tan,
      borderRadius: '4px',
      padding: '44px',
      width: '300px',
    },
  };
};

export default felaConnect(styles)(AdSkyscraper);
