import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import classNames from 'classnames';

import { InputScaffold } from '../';

import theme from '../../../theme';

class Textarea extends Component {
  static propTypes = {
    className: PropTypes.string,
    height: PropTypes.number,
    styles: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.string,
    type: PropTypes.string,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
    }),
    label: PropTypes.node,
    labelVariant: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
  };

  static defaultProps = { type: 'text', tabIndex: '0' };

  render() {
    const { field, form, className, styles, label, labelVariant, disabled, required, ...passableProps } = this.props;
    const error = form.touched[field.name] && form.errors[field.name];

    const inputClasses = {
      [styles.root]: true,
      [styles.error]: error,
      [styles.disabled]: disabled,
      [className]: className,
    };

    return (
      <InputScaffold label={label} labelVariant={labelVariant} error={error} disabled={disabled} required={required}>
        <textarea {...field} className={classNames(inputClasses)} disabled={disabled} {...passableProps} />
      </InputScaffold>
    );
  }
}

const styles = {
  root: props => ({
    width: '100%',
    display: 'block',
    color: props.theme.typography.bodyColor,
    fontFamily: 'inherit',
    fontSize: '14px',
    fontWeight: 'normal',
    backgroundColor: props.theme.colors.white,
    border: `1px solid ${props.theme.colors.disabled}`,
    borderRadius: props.theme.globalBorderRadius,
    padding: '12px 14px',
    appearance: 'none', // Reset default inputs for iOS/etc.
    boxShadow: 'rgb(160, 160, 161) 0px 8px 1px -7px inset',
    resize: 'none',
    height: '150px',

    '::placeholder': {
      color: '#9C9C9C',
    },

    ':focus': {
      outline: 'none', // Disable default focus glow
      borderColor: props.theme.colors.blue,
    },
    [theme.breakpoints.mobile]: {
      height: `${props.height}px` || 'initial',
    },
  }),
  error: props => ({
    color: props.theme.colors.red,
    border: `1px solid ${props.theme.colors.red} !important`,
    boxShadow: `0px 0px 0px 1px ${props.theme.colors.red} !important`,
    ':focus': {
      outline: 'none', // Disable default focus glow
      borderColor: `1px solid ${props.theme.colors.red} !important`,
    },
  }),
  disabled: props => ({
    color: props.theme.colors.disabledText,
    boxShadow: `0px 0px 0px 1px ${props.theme.colors.disabled}`,
    backgroundColor: props.theme.colors.disabled,
    cursor: 'not-allowed',
  }),
};

export default connect(styles)(Textarea);
