import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import YourCard from './YourCard';
import { Button, Icon } from '../../../../../common/components';
import theme from '../../../../../theme';
import PendingMembershipCard from './PendingMembershipCard';
import CancellationLink from './CancellationLink';
import config from '../../../../../common/config';

const MembershipStatus = ({
  styles,
  userVM,
  onSelectExtend,
  onSelectResubscribe,
  onCancelPendingMemberhip,
  cancelMembership,
}) => {
  function getClassnames(userVM) {
    const isTrial = userVM.get('isTrial');
    const isPremier = userVM.get('isPremier');
    const isMembershipExpired = userVM.get('isExpired');

    return classNames(
      styles.membershipLevel,
      isTrial && styles.trialMembershipWrapper,
      isPremier && styles.premierLevel,
      !isTrial && isMembershipExpired && styles.expiredMembershipWrapper
    );
  }

  if (userVM.get('isMember')) {
    return (
      <div className={styles.membershipCardWrapper}>
        <div className={getClassnames(userVM)}>
          <MembershipStatusCard userVM={userVM} styles={styles} />
          <UpgradeFromTrialPrompt userVM={userVM} styles={styles} />
          <MembershipActions
            styles={styles}
            userVM={userVM}
            onSelectExtend={onSelectExtend}
            onSelectResubscribe={onSelectResubscribe}
          />
        </div>
        <PendingMembershipCard styles={styles} userVM={userVM} onCancelPendingMemberhip={onCancelPendingMemberhip} />
        {userVM.get('isPremier') ? (
          <CancellationLink styles={styles} userVM={userVM} cancelMembership={cancelMembership} />
        ) : null}
      </div>
    );
  }

  return null;
};

const MembershipStatusCard = ({ userVM, styles }) => {
  function capitalizeFirstCharacter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const planName = capitalizeFirstCharacter(userVM.get('membershipDisplayName'));
  const isMembershipExpired = userVM.get('isExpired');

  if (isMembershipExpired) {
    return (
      <div
        className={classNames(styles.membershipLevelText, styles.membershipExpired)}
        aria-label={'Membership Expired'}
      >
        {userVM.get('isPastDue') ? (
          <h4>There was a problem processing your payment.</h4>
        ) : (
          <h4>
            Your <strong>{planName} Membership</strong> has <strong>expired</strong>
          </h4>
        )}
        <UpgradeFromStandardPrompt userVM={userVM} />
        <NextBillingDetails styles={styles} userVM={userVM} />
      </div>
    );
  }

  return (
    <div className={styles.membershipLevelText} aria-label={'Membership Status'}>
      <p>
        You have a <strong>{planName} Membership</strong>
      </p>
      <NextBillingDetails styles={styles} userVM={userVM} />
    </div>
  );
};

const UpgradeFromStandardPrompt = ({ userVM }) => {
  if (!userVM.get('isTrial') && !userVM.get('isPremier') && !userVM.get('isExpired')) {
    return <p>Add more days or upgrade to Premier today!</p>;
  }

  return null;
};

const NextBillingDetails = ({ userVM, styles }) => {
  const resubMessage = userVM.get('isPastDue')
    ? 'Please cancel by clicking "Modify Membership" below, and re-subscribe with a new card.'
    : 'Re-subscribe or choose another option below';
  if (userVM.get('isInGracePeriod')) {
    const gracePeriodRemaining = userVM.get('gracePeriodDaysRemaining');
    return (
      <>
        <p>{resubMessage}</p>
        <p className={styles.listingStatusParagraph} aria-label={'Grace period countdown'}>
          {gracePeriodRemaining < 1
            ? `Your listings are now inactive. Upgrade now to restore them and gain full access to all features!`
            : `Your listings will remain active for ${gracePeriodRemaining} days, but you won't be able to respond to new messages or add new listings until you buy a plan.`}
        </p>
      </>
    );
  }

  if (userVM.get('isExpired')) {
    return <p aria-label={'Re-subscribe'}>{resubMessage}</p>;
  }

  if (userVM.get('isPremier')) {
    if (userVM.get('hasActiveSubscription')) {
      return (
        <p>
          <YourCard userVM={userVM} />
          {' will be charged on '}
          <strong aria-label={'Next billing date'}>{formatDateWithTimezone(userVM.get('nextBillingDate'))}</strong>
        </p>
      );
    } else {
      return (
        <p>
          It is active until <strong>{formatDate(userVM.get('expirationDate'))}.</strong> You will not be rebilled.
        </p>
      );
    }
  }

  const expirationDate = moment(userVM.get('expirationDate'));
  const expiresInLessThanADay = expirationDate.diff(moment(), 'days') < 1;
  if (userVM.get('isTrial') && expiresInLessThanADay) {
    return <p className={styles.listingStatusParagraph}>Choose a plan below to keep all paid features!</p>;
  }

  if (userVM.get('benefitLevel') === 'standard') {
    return (
      <span aria-label={'Standard time remaining'}>
        It will expire on{' '}
        <strong className={styles.orange} aria-label="Expiration date">
          {formatDate(userVM.get('expirationDate'))}
        </strong>
      </span>
    );
  }

  return null;
};

const UpgradeFromTrialPrompt = ({ userVM, styles }) => {
  if (userVM.get('isTrial')) {
    return (
      <div className={styles.trialUpgradeText}>
        <strong>Pro Tip:</strong>
        <p>
          By signing up for a Premier Membership, you save 33% per month! You also get extra features, such as featured
          listings and a custom homepage name.
        </p>
      </div>
    );
  }
  return null;
};

const MembershipActions = ({ userVM, styles, onSelectExtend, onSelectResubscribe }) => {
  if (userVM.get('hasActiveSubscription')) {
    return (
      <div className={styles.membershipLevelActionsContainer}>
        <div className={styles.premierPuppyIcons}>
          <Icon icon="Puppy" size={24} fill={theme.colors.white} className={styles.premierPuppyIcon} />
          <Icon icon="PuppyWithCrown" size={48} fill={theme.colors.white} />
          <Icon icon="Puppy" size={24} fill={theme.colors.white} className={styles.premierPuppyIcon} />
        </div>
      </div>
    );
  }

  if (userVM.get('isPremier') && !userVM.get('hasPendingMembershipTransaction')) {
    return (
      <div className={styles.membershipLevelActionsContainer}>
        <Button type="button" buttonType="round" onClick={onSelectResubscribe} id="resubscribeButton">
          RE-SUBSCRIBE
        </Button>
      </div>
    );
  }

  if (
    userVM.get('benefitLevel') === 'standard' &&
    !userVM.get('hasPendingMembershipTransaction') &&
    !userVM.get('isTrial')
  ) {
    return (
      <div className={styles.membershipLevelActionsContainer}>
        <Button type="button" buttonType="round" onClick={onSelectExtend}>
          EXTEND MEMBERSHIP
        </Button>
      </div>
    );
  }

  return null;
};

export default MembershipStatus;

function formatDate(date) {
  return moment(date).format('MM/DD/YYYY');
}

function formatDateWithTimezone(date) {
  return new Date(date).toLocaleDateString('en-US', {
    timeZone: config.braintreeTimezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}
