import React from 'react';

function VideoChatCheck() {
  /* eslint-disable max-len */
  return (
    <svg viewBox="0 0 108 108" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-2" transform="translate(3.000000, 0.000000)">
          <path
            d="M85,37 L67.8415885,49.1766681 L67.8415885,40.7286268 C67.8415885,39.0167025 66.4639508,37.6291864 64.7642145,37.6291864 L18.077374,37.6291864 C16.3776377,37.6291864 15,39.0167025 15,40.7286268 L15,69.9005596 C15,71.6124839 16.3776377,73 18.077374,73 L64.7652403,73 C66.4649766,73 67.8426143,71.6124839 67.8426143,69.9005596 L67.8426143,62.0011192 L85,73 L85,37 Z"
            fill="#0085F2"
            fillRule="nonzero"
          />
          <circle stroke="#0085F2" strokeWidth="6" cx="50" cy="55" r="50" />
          <g transform="translate(63.000000, 0.000000)" fillRule="nonzero">
            <path
              d="M20.9975344,0 C9.40511991,0 0,9.40567209 0,21.0012328 C0,32.5967942 9.40511991,42 20.9975344,42 C32.5924144,42 42,32.5967942 42,21.0012328 C42,9.40567209 32.5924144,0 20.9975344,0 L20.9975344,0 Z"
              fill="#74CF3C"
            />
            <polygon
              fill="#FFFFFF"
              points="15.6503979 32 15.5888655 31.9479892 15.5324602 32 6 22.7916027 9.3894333 19.5198726 15.5888655 25.5184574 30.6131303 11 34 14.274207"
            />
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default VideoChatCheck;
