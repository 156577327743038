import React from 'react';
import { Button } from '../../../../../common/components';

function PremiumPlanBanner({ styles, price, onSelect, days, style, Icons }) {
  const testID = `add-${days}-days`;
  return (
    <div className={styles.extendCard} style={style}>
      <div className={styles.extendCardLeft}>
        {Icons}
        <span>{days} days</span>
      </div>
      <div className={styles.extendCardRight}>
        <span>{price}</span>
        <Button data-testid={testID} type="button" buttonType="white" onClick={onSelect}>
          SELECT
        </Button>
      </div>
    </div>
  );
}

export default PremiumPlanBanner;
