import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  sendPhoneVerification,
  confirmPhoneVerification,
  sendEmailVerification,
  clearErrorBar,
  setErrorBarMessage,
  connectFacebook,
  disconnectFacebook,
  resetDisconnectFacebookStatus,
} from '../../../../redux/actions';
import { getBraintreeToken, purchaseIdVerification, getVerificationStatus } from '../../redux/actions';
import { isLoggedInSelector } from '../../../../redux/selectors';
import Verification from './Verification';

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedInSelector(state),
    user: state.user,
    facebookDisconnectStatus: state.facebookDisconnectStatus,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendPhoneVerification,
      confirmPhoneVerification,
      sendEmailVerification,
      getBraintreeToken,
      purchaseIdVerification,
      getVerificationStatus,
      clearErrorBar,
      setErrorBarMessage,
      connectFacebook,
      disconnectFacebook,
      resetDisconnectFacebookStatus,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
