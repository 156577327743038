import ReportModal from './ReportModal';
import { connect } from 'react-redux';
import { reportUser, blockUser, getUser } from '../../../redux/actions';
import { bindActionCreators } from 'redux';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({ reportUser, blockUser, getUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReportModal);
