import cookies from 'js-cookie';
import Immutable from 'immutable';
import { fromHydrant } from 'fire-hydrant';
import { createPlugin } from '@synapsestudios/fetch-client-goalie';
import reducers from './reducers';
import createStore from '../common/utils/createStore';
import client from '../common/utils/client';

const adminToken = new URLSearchParams(window.location.search).get('adminToken');

if (adminToken) {
  cookies.set('id_token', adminToken);
}

const store = createStore(reducers, fromHydrant(window.__PRELOADED_STATE__ || {}, { Immutable }));

// This file isn't required on the server, so do clientside-only client modifications here
client.addPlugin(createPlugin('^0.35.0', store));
client.on('REQUEST_FAILURE', (request, response) => {
  if (response.status === 401) {
    cookies.remove('id_token');
    typeof window !== 'undefined' && window.location.reload(true);
  }
});

export default store;
