import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';

class ColorSquareGrid extends Component {
  static propTypes = {
    children: PropTypes.any,
    label: PropTypes.string,
  };

  static defaultProps = {
    label: 'Colors',
  };

  render() {
    const { children, styles, label } = this.props;
    return (
      <div>
        <label className={styles.label}>{label}</label>
        <div className={styles.children}>{children}</div>
      </div>
    );
  }
}

const styles = props => ({
  children: {
    marginTop: '10px',
    display: 'grid',
    gridGap: '5px',
    gridTemplateColumns: 'repeat(6, 1fr)',

    [props.theme.breakpoints.mobile]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(9, 1fr)',
      gridGap: '5px',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  label: {
    marginBottom: '8px',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    color: props.theme.colors.blue,
    fontWeight: props.theme.typography.sansBold,
    display: 'block',
    textTransform: 'uppercase',
  },
});

export default connect(styles)(ColorSquareGrid);
