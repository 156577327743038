import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import classNames from 'classnames';

import { InputScaffold } from '../';

class TextInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    styles: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.string,
    type: PropTypes.string,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
    }),
    label: PropTypes.node,
    labelVariant: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    fixederror: PropTypes.bool,
    prefix: PropTypes.node,
    prefixPadding: PropTypes.number,
    required: PropTypes.bool,
  };

  static defaultProps = { type: 'text', tabIndex: '0' };

  render() {
    const {
      field,
      form,
      className,
      styles,
      label,
      labelVariant,
      prefix,
      fixederror,
      disabled,
      required,
      labelLinkRight,
      labelLinkRightClick,
      prefixPadding,
      password,
      ...passableProps
    } = this.props;
    const error = form.touched[field.name] && form.errors[field.name];

    const inputClasses = {
      [styles.root]: true,
      [styles.error]: error,
      [styles.disabled]: disabled,
      [className]: className,
    };

    return (
      <InputScaffold
        label={label}
        labelVariant={labelVariant}
        error={error}
        fixederror={fixederror}
        disabled={disabled}
        required={required}
        labelLinkRight={labelLinkRight}
        labelLinkRightClick={labelLinkRightClick}
        password={password}
      >
        <input {...field} className={classNames(inputClasses)} disabled={disabled} {...passableProps} />
        {prefix && <span className={styles.prefix}>{prefix}</span>}
      </InputScaffold>
    );
  }
}

const styles = {
  root: props => ({
    width: '100%',
    height: '42px',
    display: 'block',
    color: props.theme.typography.bodyColor,
    fontFamily: 'inherit',
    fontSize: '14px',
    fontWeight: props.theme.typography.sansRegular,
    backgroundColor: props.theme.colors.white,
    border: `1px solid ${props.theme.colors.disabled}`,
    borderRadius: props.theme.globalBorderRadius,
    padding: props.prefixPadding ? `10px 8px 10px ${props.prefixPadding}px` : '10px 12px',
    appearance: 'none', // Reset default inputs for iOS/etc.
    boxShadow: 'rgb(160, 160, 161) 0px 8px 1px -7px inset',
    position: 'relative',
    '::placeholder': {
      fontSize: '14px',
      fontWeight: props.theme.typography.sansRegular,
      color: '#9C9C9C',
    },

    ':focus': {
      outline: 'none', // Disable default focus glow
      borderColor: props.theme.colors.blue,
    },
  }),
  error: props => ({
    color: props.theme.colors.red,
    border: `1px solid ${props.theme.colors.red} !important`,
    boxShadow: `0px 0px 0px 1px ${props.theme.colors.red} !important`,
    ':focus': {
      outline: 'none', // Disable default focus glow
      borderColor: `${props.theme.colors.red} !important`,
    },
  }),
  disabled: props => ({
    color: props.theme.colors.disabledText,
    boxShadow: `0px 0px 0px 1px ${props.theme.colors.disabled}`,
    backgroundColor: props.theme.colors.disabled,
    cursor: 'not-allowed',
  }),
  prefix: props => ({
    position: 'absolute',
    top: props.label ? '33px' : '13px',
    left: '12px',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: props.theme.typography.sansRegular,
    color: '#A9A9A9',
    textTransform: 'lowercase',
  }),
};

export default connect(styles)(TextInput);
