import { combineReducers } from 'redux';
import { fromJS } from 'immutable';

const activeProfile = (state = null, action) => {
  switch (action.type) {
    case 'GET_PROFILE_SUCCESS':
      return fromJS(action.json);
    case 'SEND_MESSAGE_TO_USER_SUCCESS':
      if (state && action.json.users.find(user => user.id === state.get('id'))) {
        return state.set('conversation', fromJS(action.json));
      }
      return state;
    case 'RESET_PROFILE':
      return null;
    default:
      return state;
  }
};

const profileError = (state = null, action) => {
  switch (action.type) {
    case 'GET_PROFILE_FAILURE':
      return fromJS(action.json);
    case 'RESET_PROFILE':
    case 'GET_PROFILE_REQUEST':
      return null;
    default:
      return state;
  }
};

const profileLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROFILE_REQUEST':
      return true;
    case 'GET_PROFILE_FAILURE':
    case 'GET_PROFILE_SUCCESS':
    case 'RESET_PROFILE':
      return false;
    default:
      return state;
  }
};

const listings = (state = null, action) => {
  switch (action.type) {
    case 'GET_PROFILE_LISTINGS_SUCCESS':
      return fromJS(action.json.results);
    case 'GET_PREMIER_SUCCESS':
      const listings = action.json.user?.listings ? action.json.user.listings : [];
      return fromJS(listings);
    case 'CREATE_FAVORITE_LISTING_REQUEST':
      if (state) {
        return state.map(listing =>
          listing.get('id') === action.listing_id ? listing.set('favorite', true) : listing
        );
      }
      return state;
    case 'REMOVE_FAVORITE_LISTING_REQUEST':
      if (state) {
        return state.map(listing =>
          listing.get('id') === action.listing_id ? listing.set('favorite', null) : listing
        );
      }
      return state;
    case 'RESET_PROFILE_LISTINGS':
      return null;
    default:
      return state;
  }
};

const listingsError = (state = null, action) => {
  switch (action.type) {
    case 'GET_PROFILE_LISTINGS_FAILURE':
      return fromJS(action.json);
    case 'RESET_PROFILE_LISTINGS':
    case 'GET_PROFILE_LISTINGS_REQUEST':
      return null;
    default:
      return state;
  }
};

const listingsTotal = (state = 0, action) => {
  switch (action.type) {
    case 'GET_PROFILE_LISTINGS_SUCCESS':
      return fromJS(action.json.total);
    case 'GET_PREMIER_SUCCESS':
      const total = action.json.user?.listings ? action.json.user?.listings?.length : 0;
      return total;
    case 'RESET_PROFILE_LISTINGS':
      return 0;
    default:
      return state;
  }
};

export default combineReducers({
  activeProfile,
  profileLoading,
  listings,
  listingsTotal,
  profileError,
  listingsError,
});
