import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';
import Cropper from 'react-cropper';
import Carousel from 'nuka-carousel';
import { Map } from 'immutable';

import Icon from '../icons/icon';
import Initials from '../InitialsIcon';
import theme from '../../../theme';
import { Modal, Button, ButtonPlain, Loading } from '../';
import ModalFooter from '../Modal/ModalFooter';
import TextInput from '../TextInput';
import StandAloneField from '../StandaloneField';
import Dots from '../Dots';
import { getThumbnail, getFullSize } from '../../utils/photo-helpers';
import { lockScroll, unlockScroll } from '../../utils/scroll-lock';

class CircleAvatar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cropperReady: false,
      hasCropped: false,
      openCropper: false,
      photoDeleteMode: false,
      photoDescription: '',
      slideIndex: 0,
    };
  }

  componentDidMount() {
    const { photoTitle } = this.props;
    this.setState({ photoDescription: photoTitle || '' });
  }

  setSlideIndex = i => {
    this.setState({ slideIndex: i });
  };

  closeSaveModal = () => {
    this.setState({ openCropper: false, photoDeleteMode: false });
    unlockScroll();
  };

  cropImage = () => {
    this.setState(state => {
      const dataURL = this.cropper?.cropper?.getCroppedCanvas()?.toDataURL() || '';

      this.props.handleCrop(dataURL, state.photoDescription, this.state.hasCropped);
      return { hasCropped: false, openCropper: false, photoDeleteMode: false, photoDescription: '' };
    });
  };

  handleDelete = () => {
    this.setState({ openCropper: false, photoDeleteMode: false });
    this.props.handleDelete(this.props.imageUrl);
  };

  getThumbnailUrl() {
    const { croppedImageUrl, imageUrl, photo } = this.props;

    if (!imageUrl && !photo) {
      return false;
    }

    if (croppedImageUrl) {
      return croppedImageUrl;
    }

    if (imageUrl) {
      return imageUrl;
    }

    return getThumbnail(photo);
  }

  getFullSizeOptimizedPhoto() {
    const { imageUrl, photo } = this.props;

    if (imageUrl) {
      return imageUrl;
    }

    return getFullSize(photo);
  }

  getCarouselThumbnails() {
    const { carouselImages } = this.props;
    return carouselImages ? carouselImages.map((photo, index) => getThumbnail(photo)) : [];
  }

  render() {
    const {
      badge,
      initials,
      styles,
      size,
      variant,
      placeholderIcon,
      isMobile,
      hasLightbox,
      verified,
      whiteBg,
    } = this.props;
    const formattedCarouselImages = this.getCarouselThumbnails();
    const thumbnailUrl = this.getThumbnailUrl();

    if (formattedCarouselImages.size > 1) {
      return (
        <div className={styles.root}>
          <Carousel
            autoplay
            wrapAround
            withoutControls
            disableKeyboardControls
            speed={250}
            swiping
            cellSpacing={38}
            dragging={false}
            slideIndex={this.state.slideIndex}
            afterSlide={slideIndex => this.setSlideIndex(slideIndex)}
            className={styles.carousel}
          >
            {formattedCarouselImages.map((image, index) => {
              return (
                <div
                  key={image}
                  alt={`Listing Photo`}
                  onClick={this.props.onClick}
                  className={styles.carouselImage}
                  style={{
                    backgroundImage: `url(${image})`,
                    height: size,
                    width: size,
                    borderRadius: '50%',
                  }}
                />
              );
            })}
          </Carousel>
          <div className={styles.dots}>
            <Dots
              size={8}
              margin={2}
              length={formattedCarouselImages.size}
              active={this.state.slideIndex}
              className={formattedCarouselImages.size < 5 ? styles.dotsCentered : null}
              setSlideIndex={this.setSlideIndex}
            />
          </div>
          {verified ? (
            <div className={styles.badge + ' ' + styles.verificationBadge}>
              <Icon icon="StarCircle" size={size >= 150 ? 48 : 16} fill={theme.colors.brighterBlue} />
            </div>
          ) : null}
          {hasLightbox && !isMobile ? (
            <div className={styles.imageOverlay} onClick={this.props.onClick}>
              <div className={styles.imageOverlayChild}>
                {this.props.hasMultipleImages ? 'View all photos' : 'View photo'}
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <div id={this.props.id} className={styles.root}>
        {thumbnailUrl ? (
          <div>
            {this.props.mainPhoto ? <span className={styles.mainPhotoOverlay}>Main Photo</span> : null}
            {thumbnailUrl && (
              <div className={styles.imageContainer} onClick={this.props.onClick}>
                <img className={styles.image} src={thumbnailUrl} alt="Listing or profile" />
              </div>
            )}
            {hasLightbox && !isMobile ? (
              <div className={styles.imageOverlay} onClick={this.props.onClick}>
                <div className={styles.imageOverlayChild}>
                  {this.props.hasMultipleImages ? 'View all photos' : 'View photo'}
                </div>
              </div>
            ) : null}
            {this.props.editMode ? (
              <div
                className={styles.imageOverlay}
                data-testid="circle-avatar-click-target"
                onClick={() => {
                  this.setState({ openCropper: true, cropperReady: false });
                  lockScroll();
                }}
              >
                <div className={styles.imageOverlayChild}>
                  <Icon icon="Pencil" size={32} />
                </div>
              </div>
            ) : null}
            <Modal isOpen={this.state.openCropper} onClose={this.closeSaveModal} closeOnEscape closeOnOutsideClick>
              <div className={styles.cropperWrapper}>
                <Cropper
                  style={{ height: 400, width: '100%', backgroundImage: 'none' }}
                  ref={cropper => {
                    this.cropper = cropper;
                  }}
                  initialAspectRatio={0}
                  zoomable={false}
                  viewMode={2}
                  guides={false}
                  autoCropArea={1}
                  src={this.getFullSizeOptimizedPhoto()}
                  crop={() => {
                    if (this.state.cropperReady) {
                      this.setState({ hasCropped: true });
                    }
                  }}
                  ready={() => {
                    this.setState({ cropperReady: true });
                  }}
                />
                {!this.state.cropperReady && <Loading className={styles.cropperLoading} />}
              </div>
              <div className={styles.standaloneFieldContainer}>
                <StandAloneField
                  component={TextInput}
                  name="photoDescription"
                  placeholder="Image Title"
                  value={this.state.photoDescription}
                  onChange={({ target: { value } }) => {
                    this.setState({ photoDescription: value.slice(0, 50) });
                  }}
                />
              </div>
              <ModalFooter
                noBorder
                actions={[
                  <ButtonPlain
                    onClick={() => {
                      this.closeSaveModal();
                    }}
                    variant="primary"
                  >
                    Cancel
                  </ButtonPlain>,
                  <div className={styles.buttonRow}>
                    {this.state.photoDeleteMode ? null : (
                      <ButtonPlain onClick={this.handleDelete} variant="red">
                        Delete
                      </ButtonPlain>
                    )}

                    <Button buttonType="blue" onClick={this.cropImage.bind(this)} disabled={!this.state.cropperReady}>
                      Done
                    </Button>
                  </div>,
                ]}
              />
            </Modal>
          </div>
        ) : placeholderIcon ? (
          <Icon
            icon={placeholderIcon}
            size={size}
            fill={variant === 'white' ? theme.colors.white : variant === 'red' ? theme.colors.red : theme.colors.blue}
          />
        ) : (
          <Initials
            initials={initials}
            onClick={this.props.onClick}
            size={size}
            fill={variant === 'white' ? theme.colors.white : variant === 'red' ? theme.colors.red : theme.colors.blue}
            whiteBg={whiteBg}
          />
        )}
        {verified ? (
          <div className={styles.badge + ' ' + styles.verificationBadge}>
            <Icon
              icon="StarCircle"
              size={size === 90 ? 28 : null || size >= 150 ? 48 : 16}
              fill={theme.colors.brighterBlue}
            />
          </div>
        ) : null}
        {badge && <div className={styles.badge}>{badge}</div>}
        {this.props.checked && <Icon className={styles.overlayIcon} icon="FatCheck" />}
      </div>
    );
  }
}

CircleAvatar.propTypes = {
  size: PropTypes.number,
  badge: PropTypes.node,
  photo: PropTypes.instanceOf(Map),
  imageUrl: PropTypes.string,
  croppedImageUrl: PropTypes.string,
  editMode: PropTypes.bool,
  handleCrop: PropTypes.func,
  handleDelete: PropTypes.func,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  placeholderIcon: PropTypes.string,
  initials: PropTypes.string,
  checked: PropTypes.bool,
  verified: PropTypes.bool,
};

CircleAvatar.defaultProps = {
  size: 30,
  editMode: false,
  onClick: () => {},
  placeholderIcon: '',
  initials: '',
  handleDelete: () => {},
};

const mapStateToProps = state => ({
  isMobile: state.browser.lessThan.tabletLarge,
});

const styles = props => ({
  root: {
    position: 'relative',
    marginBottom: props.hasMultipleImages ? '12px' : '0',
  },
  carousel: {
    overflow: 'hidden',
    width: `${props.size}px !important`,
    height: `${props.size}px !important`,
    borderRadius: '50%',
    backgroundColor: props.theme.colors.blue,
    border: `4px solid ${props.theme.colors.blue}`,
  },
  carouselImage: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  image: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    imageOrientation: 'from-image',
  },
  imageContainer: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%',
    borderWidth: `${props.size > 64 ? '4px' : props.size > 30 ? '3px' : '2px'}`,
    borderStyle: 'solid',
    width: `${props.size}px`,
    height: `${props.size}px`,
    borderColor:
      props.variant === 'white'
        ? props.theme.colors.white
        : props.variant === 'red'
        ? props.theme.colors.red
        : props.theme.colors.blue,
    overflow: 'hidden',
    WebkitMaskImage: '-webkit-radial-gradient(white, black)',
  },
  placeholder: {
    backgroundColor: props.theme.colors.gray,
    width: `${props.size}px`,
    height: `${props.size}px`,
    borderRadius: '50%',
  },
  badge: {
    position: 'absolute',
    top: props.size <= 40 ? '-8px' : '-2px',
    right: props.size <= 40 ? '-8px' : '-2px',
  },
  verificationBadge: {
    padding: '2px',
    backgroundColor: props.theme.colors.white,
    borderRadius: '50%',
    zIndex: 3,
  },
  overlayIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: `${props.size}px !important`,
    height: `${props.size}px !important`,
    color: props.theme.colors.blue,
    backgroundColor: props.theme.colors.white,
    borderRadius: '50%',
    borderWidth: `${props.size > 64 ? '4px' : props.size > 30 ? '3px' : '2px'}`,
    borderStyle: 'solid',
    borderColor: props.theme.colors.blue,
    '> svg': {
      width: '75%',
      height: '75%',
    },
  },
  imageOverlay: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    position: 'absolute',
    borderRadius: '50%',
    width: `${props.size}px`,
    height: `${props.size}px`,
    zIndex: 2,
    ':hover': {
      backgroundColor: `rgba(26, 185, 196, .75)`,
      '> div': {
        display: 'flex',
      },
    },
  },
  imageOverlayChild: {
    display: 'none',
    color: props.theme.colors.white,
    fontWeight: props.theme.typography.sansBold,
    textShadow: '1px 1px 4px rgba(0, 0, 0, .5)',
  },
  dots: {
    position: 'absolute',
    bottom: '-16px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  dotsCentered: {
    justifyContent: 'center',
  },
  buttonRow: {
    display: 'flex',
    height: '40px',
    '> * + *': {
      marginLeft: '32px',
    },
  },
  mainPhotoOverlay: {
    zIndex: 1,
    position: 'absolute',
    bottom: '30px',
    fontSize: '14px',
    color: props.theme.colors.white,
    fontWeight: props.theme.typography.sansBold,
    textAlign: 'center',
    width: '100%',
    textShadow: '2px 2px 4px rgba(0,0,0,.5)',
    cursor: 'pointer',
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: props.theme.colors.brown,
  },
  standaloneFieldContainer: {
    padding: '20px 40px 0',
  },
  cropperWrapper: {
    position: 'relative',
  },
  cropperLoading: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
  },
});

export default connect(mapStateToProps)(felaConnect(styles)(CircleAvatar));
