import buildUploadParams from './buildUploadParams';
import retryWithDelay from './retryWithDelay';

const MINIMUM_PART_SIZE = 1024 * 1024 * 5; // Minimum 5MB per chunk (except the last part) http://docs.aws.amazon.com/AmazonS3/latest/API/mpUploadComplete.html
const MAX_UPLOAD_ATTEMPTS = 10;
const RETRY_WAIT = 3000; // seconds

export async function handleFileUpload({
  getUploadToken = async () => {},
  file = {},
  key = '',
  handleProgress = () => {},
}) {
  const progress = (min, max) => {
    const value = Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    handleProgress(value, key);
  };

  progress(0, 1);

  const { response, json } = (await getUploadToken()) || {};
  progress(2, 4);

  if (!response?.ok || !json?.Token || !json?.Bucket) {
    throw new Error('Upload token retrieval error');
  }

  const params = buildUploadParams(file, key, json.Bucket);
  progress(5, 10);

  const { S3Client } = await import('@aws-sdk/client-s3');
  progress(11, 12);
  const { fromCognitoIdentity } = await import('@aws-sdk/credential-provider-cognito-identity');
  progress(13, 14);
  const { CognitoIdentityClient } = await import('@aws-sdk/client-cognito-identity');
  progress(15, 16);

  const s3Client = new S3Client({
    region: json.Region,
    credentials: fromCognitoIdentity({
      client: new CognitoIdentityClient({
        region: json.Region,
      }),
      identityId: json.IdentityId,
      logins: {
        'cognito-identity.amazonaws.com': json.Token,
      },
    }),
  });
  progress(17, 20);

  if (params.Body.length > MINIMUM_PART_SIZE) {
    return multipartUpload(params, s3Client, progress);
  }

  const uploadResponse = await upload(s3Client, params, progress);
  progress(100, 100);
  return uploadResponse;
}

async function upload(s3Client, params, progress = () => {}) {
  const { PutObjectCommand } = await import('@aws-sdk/client-s3');
  progress(21, 50);
  const uploadResponse = await retryWithDelay(
    function() {
      return s3Client.send(new PutObjectCommand(params));
    },
    MAX_UPLOAD_ATTEMPTS,
    RETRY_WAIT
  );
  if (uploadResponse?.$metadata?.httpStatusCode === 200) {
    return {
      response: uploadResponse.$metadata.httpStatusCode,
      ...params,
    };
  } else {
    throw new Error(`There was an error uploading your photo: ${uploadResponse?.message}`);
  }
}

async function multipartUpload(params, s3Client, progress = () => {}) {
  const { Body, Bucket, Key } = params;
  const uploadId = await initiateMultipartUpload(s3Client, params);
  progress(21, 30);
  const parts = createParts(Body, uploadId, Bucket, Key, progress);
  const partResponses = await uploadParts(parts, s3Client, progress);
  const completion = await completeMultipartUpload(parts, partResponses, s3Client, uploadId, Bucket, Key);
  progress(95, 99);
  return completion;
}

async function initiateMultipartUpload(s3Client, params) {
  const { CreateMultipartUploadCommand } = await import('@aws-sdk/client-s3');
  const response = await retryWithDelay(
    function() {
      return s3Client.send(new CreateMultipartUploadCommand(params));
    },
    MAX_UPLOAD_ATTEMPTS,
    RETRY_WAIT
  );
  if (response?.UploadId) {
    return response.UploadId;
  } else {
    throw new Error(`Could not initiate multipart upload`);
  }
}

function createParts(data, uploadId, bucket, fileKey, progress = () => {}) {
  const parts = [];
  var partNumber = 1;
  for (var rangeStart = 0; rangeStart < data.length; rangeStart += MINIMUM_PART_SIZE) {
    var end = Math.min(rangeStart + MINIMUM_PART_SIZE, data.length);
    const partParams = {
      Body: data.slice(rangeStart, end),
      Bucket: bucket,
      Key: fileKey,
      PartNumber: String(partNumber),
      UploadId: uploadId,
    };
    progress(30 + partNumber * 2, 35 + partNumber * 2);
    partNumber++;
    parts.push(partParams);
  }
  return parts;
}

async function uploadParts(parts, s3Client, progress = () => {}) {
  const { UploadPartCommand } = await import('@aws-sdk/client-s3');
  const completedParts = [];
  for (var i = 0; i < parts.length; i++) {
    const part = parts[i];
    const data = await retryWithDelay(
      function() {
        return s3Client.send(new UploadPartCommand(part));
      },
      MAX_UPLOAD_ATTEMPTS,
      RETRY_WAIT
    );
    progress(47 + i * 4, 53 + i * 4);
    if (data?.ETag) {
      completedParts.push({
        ETag: data.ETag,
        PartNumber: part.PartNumber,
      });
    }
  }

  return completedParts;
}

async function completeMultipartUpload(parts, completedParts, s3Client, uploadId, bucket, key) {
  const { CompleteMultipartUploadCommand } = await import('@aws-sdk/client-s3');
  if (parts.length < completedParts.length) {
    throw new Error(`Failed to upload ${parts.length - completedParts.length} parts`);
  }
  const completionParams = {
    Bucket: bucket,
    Key: key,
    MultipartUpload: {
      Parts: completedParts,
    },
    UploadId: uploadId,
  };
  const data = await retryWithDelay(
    function() {
      return s3Client.send(new CompleteMultipartUploadCommand(completionParams));
    },
    MAX_UPLOAD_ATTEMPTS,
    RETRY_WAIT
  );
  return data; // For unit tests.
}
