import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import UserAuthenticationLayout from '../../layouts/UserAuthenticationLayout';

import SignUpForm from './SignUpForm';

class SignUp extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    signUp: PropTypes.func.isRequired,
    setErrorBarMessage: PropTypes.func.isRequired,
    clearErrorBar: PropTypes.func.isRequired,
  };

  render() {
    const { isLoggedIn, signUp, setErrorBarMessage, clearErrorBar } = this.props;
    if (isLoggedIn) {
      return <Redirect to="/" />;
    }

    return (
      <UserAuthenticationLayout title="Sign up for free!" subTitle="Getting started only takes a few minutes." signUp>
        <SignUpForm setError={setErrorBarMessage} clearError={clearErrorBar} submitAction={signUp} />
      </UserAuthenticationLayout>
    );
  }
}

export default SignUp;
