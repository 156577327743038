import React, { useState } from 'react';
import { connect as felaConnect } from 'react-fela';
import { Button, Icon, ButtonPlain, Modal } from '../../common/components';
import Cookies from 'js-cookie';
const PhoneVerificationModal = function({ history, styles, to }) {
  const [phoneVerifierModalState, setPhoneVerifierModalState] = useState(true);

  // useEffect(() => {
  //   const _ppvs = Cookies.get('_ppvs');

  //   if (_ppvs) {
  //     const skipDate = new Date(parseInt(_ppvs));
  //     const today = new Date();
  //     if (skipDate < today) {
  //       setPhoneVerifierModalState(true);
  //     } else {
  //       setPhoneVerifierModalState(false);
  //     }
  //   }
  // }, []);

  const skipPhoneVerification = () => {
    const timestamp = new Date().getTime();
    const exp = timestamp + 60 * 60 * 24 * 1000 * 2;
    const cookieOptions = {
      expires: exp,
      domain: '.' + window.location.host,
      secure: true,
      sameSite: 'strict',
    };
    // _ppvs = puppies phone verification skip
    Cookies.set('_ppvs', exp, cookieOptions);
    setPhoneVerifierModalState(false);
  };

  return (
    <Modal
      isOpen={phoneVerifierModalState}
      onClose={() => setPhoneVerifierModalState(false)}
      closeOnOutsideClick
      closeOnEscape
    >
      <div className={styles.phoneModalContentContainer}>
        <div className={styles.iconContainer}>
          <Icon icon="PhoneVerificationInfo" size={99} />
        </div>
        <p className={styles.phoneModalHeader}>Verify your phone</p>
        <p className={styles.phoneModalContent}>
          In order to increase safety and reduce scams, we will require all users to have a verified phone number by{' '}
          <strong>06/01/2023</strong>
        </p>
        <div className={styles.verify}>
          <Button
            type="button"
            buttonType="roundOrange"
            onClick={() => {
              if (to) {
                history.push(`/verify-phone-number?to=${to}`);
              } else {
                history.push(`/verify-phone-number?to=/listings`);
              }
            }}
          >
            Verify
          </Button>
        </div>
        <div>
          <ButtonPlain onClick={() => skipPhoneVerification()}>Skip</ButtonPlain>
        </div>
      </div>
    </Modal>
  );
};

const styles = () => ({
  phoneModalContentContainer: {
    width: '342px',
    margin: '50px auto',
  },
  phoneModalHeader: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#232323',
    margin: '20px 0',
  },
  phoneModalContent: {
    color: '#232323',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  verify: {
    margin: '20px 0',
  },
});

export default felaConnect(styles)(PhoneVerificationModal);
