import { format } from 'date-fns';

const getSexPrefix = sex => {
  switch (sex) {
    case 'M':
      return 'Male, ';
    case 'F':
      return 'Female, ';
    default:
      return '';
  }
};

function formatDob(birthdate) {
  let parts = [];
  let dobString = '';
  if (typeof birthdate === 'string') {
    parts = birthdate.split('-');
  } else {
    parts = birthdate.toISOString().split('-');
  }

  if (parts && parts.length === 3) {
    const day = parts[2].slice(0, 2);
    dobString = `${parts[1]}/${day}/${parts[0]}`;
  } else {
    dobString = birthdate.toString();
  }

  return dobString;
}

function getHumanizedDuration(birthdate) {
  const formattedDob = formatDob(birthdate);
  const formattedToday = format(new Date(), 'MM/dd/yyyy');
  const birthday = new Date(formattedDob);
  const today = new Date(formattedToday);
  const isDue = birthday > today;

  const dayDuration = Math.abs(Math.round((birthday - today) / 1000 / 60 / 60 / 24));
  const weekDuration = Math.floor(dayDuration / 7);
  const monthDuration = Math.floor(dayDuration / 30);
  const yearDuration = Math.floor(dayDuration / 365.25);

  if (monthDuration > 23) {
    return isDue
      ? `Due on ${formattedDob} - in ${yearDuration} years`
      : `Born on ${formattedDob} - ${yearDuration} years old`;
  }

  if (monthDuration >= 6) {
    return isDue
      ? `Due on ${formattedDob} - in ${monthDuration} months`
      : `Born on ${formattedDob} - ${monthDuration} months old`;
  }

  if (dayDuration < 7) {
    const dayString = dayDuration === 1 ? 'day' : 'days';
    return isDue
      ? `Due on ${formattedDob} - in ${dayDuration} ${dayString}`
      : `Born on ${formattedDob} - ${dayDuration} ${dayString} old`;
  }

  const weekString = weekDuration === 1 ? 'week' : 'weeks';
  return isDue
    ? `Due on ${formattedDob} - in ${weekDuration} ${weekString}`
    : `Born on ${formattedDob} - ${weekDuration} ${weekString} old`;
}

export default function formatPuppyAge(sex, birthdate) {
  const isDue = new Date(birthdate) > new Date();
  const humanizedDuration = getHumanizedDuration(birthdate);

  if (isDue) {
    return humanizedDuration;
  }

  return `${getSexPrefix(sex)}${humanizedDuration}`;
}
