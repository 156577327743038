import client from '../../../common/utils/client';
import querystring from 'query-string';

export const getPremierBySlug = slug => ({
  promise: client.get(`premiers/${slug}`),
  type: 'GET_PREMIER',
});

export const resetPremier = () => ({
  type: 'RESET_PREMIER',
});

export const searchPremiers = query => {
  const action = {
    promise: client.get(`premiers?${querystring.stringify(query)}`),
    type: 'SEARCH_PREMIERS',
  };

  if (query.location) {
    const splitLocation = query.location.split(',');
    query.location = { formatted_location: query.formatted_location, lat: splitLocation[0], lng: splitLocation[1] };
  }

  return action;
};

export const resetPremiersSearch = () => ({
  type: 'RESET_PREMIERS_SEARCH',
});

export const createPremierUpdate = payload => ({
  promise: client.post(`premier-updates`, payload),
  type: 'CREATE_PREMIER_UPDATE',
});

export const deletePremierUpdate = premierUpdateId => ({
  promise: client.delete(`premier-updates/${premierUpdateId}`),
  type: 'DELETE_PREMIER_UPDATE',
});

export const trackPremierWebsiteView = premierProfileId => ({
  promise: client.post(`premiers/${premierProfileId}/website-views`),
  type: 'TRACK_PREMIER_WEBSITE_VIEW',
});

export const isPremierSlugAvailable = slug => ({
  promise: client.get(`premiers/${slug}/slug-available`),
  type: 'CHECK_SLUG_AVAILABLE',
});
