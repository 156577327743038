import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import { Icon } from '../../components/';
import theme from '../../../theme';

class SuspendedBar extends Component {
  render() {
    const { styles, message } = this.props;
    if (!message) {
      return null;
    }
    return (
      <div className={styles.root}>
        <div className={styles.message}>
          <Icon icon="Warning" size={16} fill={theme.colors.white} />
          <span>{message}</span>
        </div>
      </div>
    );
  }
}

SuspendedBar.propTypes = {
  message: PropTypes.node,
};

const styles = {
  root: props => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: props.theme.colors.orange,
    color: 'white',
    padding: '8px',
    zIndex: 2,
    top: '82px',
    position: 'sticky',
    textAlign: 'center',
  }),
  message: props => ({
    display: 'flex',
    alignItems: 'center',
    ...props.theme.globalPadding,
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
    color: props.theme.colors.white,
    fontWeight: props.theme.typography.sansBold,
    '> * + *': {
      marginLeft: '10px',
    },
  }),
};

export default connect(styles)(SuspendedBar);
