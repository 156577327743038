import { createSelector } from 'reselect';
import { breedsAutocompleteSelector } from '../../../redux/selectors';

export const selectBreedSlugsAutoComplete = createSelector(breedsAutocompleteSelector, breedGroups => {
  return breedGroups.toJS().reduce((acc, curr) => {
    acc.push({
      id: curr.slug,
      label: curr.name,
    });

    return acc;
  }, []);
});
