import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import moment from 'moment';
import Markdown from 'markdown-it';

import config from '../../../common/config';
import { TweetButton, PinterestButton, PlainLink } from '../../../common/components';

const Article = props => {
  const { article, id, styles } = props;
  const md = Markdown();

  if (!article || !article.size) {
    return null;
  }

  return (
    <div className={styles.root}>
      {article.get('image') ? (
        <Link to={`/blog/articles/${id}`}>
          <div
            className={styles.image}
            style={{ backgroundImage: `url(${article.getIn(['image', 'fields', 'file', 'url'])})` }}
          />
        </Link>
      ) : null}
      <div className={styles.content}>
        <Link to={`/blog/articles/${id}`}>
          <h1 className={styles.h1}>{article.get('title')}</h1>
        </Link>
        <div>
          <div className={styles.author}>
            By{' '}
            <PlainLink to={`/blog/author/${article.getIn(['author', 'sys', 'id'])}`}>
              {article.getIn(['author', 'fields', 'name'])}
            </PlainLink>
            , on {moment(article.get('publishDate')).format('MM/DD/YYYY')}
          </div>
          <div className={styles.tagsSocialWrapper}>
            <div className={styles.tags}>
              {article.get('tags') &&
                article.get('tags').map((tag, i) => (
                  <Fragment key={i}>
                    <PlainLink variant="blue" to={`/blog/tags/${tag}`} key={i}>
                      {tag}
                    </PlainLink>
                    {i === article.get('tags').size - 1 ? null : ', '}
                  </Fragment>
                ))}
            </div>
            <div className={styles.socialMedia}>
              <TweetButton url={`${config.frontendHost}/articles/${id}`} />
              <PinterestButton
                url={`${config.frontendHost}/articles/${id}`}
                image={article.getIn(['image', 'fields', 'file', 'url'])}
                description={article.get('title')}
              />
            </div>
          </div>
        </div>
        <div className={styles.articleBody}>
          <div dangerouslySetInnerHTML={{ __html: md.render(article.get('body') || '') }} />
        </div>
      </div>
    </div>
  );
};

Article.propTypes = {
  article: PropTypes.instanceOf(Map),
  preview: PropTypes.bool,
  id: PropTypes.string,
};

Article.defaultProps = {
  preview: false,
};

const styles = props => ({
  root: {
    marginBottom: '80px',
    [props.theme.breakpoints.mobileLarge]: {
      maxWidth: '700px',
    },
  },
  image: {
    height: '320px',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [props.theme.breakpoints.mobileLarge]: {
      borderRadius: '4px',
      marginTop: '30px',
    },
  },
  content: {
    ...props.theme.globalPadding,
    color: props.theme.colors.brown,
    marginTop: '12px',
    [props.theme.breakpoints.mobileLarge]: {
      padding: 0,
    },
  },
  h1: {
    fontSize: '35px',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.orange,
    lineHeight: '40px',
    transition: props.theme.globalTransition,
    ':hover': {
      color: props.theme.colors.orangeHover,
    },
  },
  author: {
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '14px',
    '> a': {
      fontStyle: 'italic',
    },
  },
  tagsSocialWrapper: {
    [props.theme.breakpoints.mobileLarge]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '6px',
      '> * + *': {
        marginLeft: '16px',
      },
    },
  },
  tags: {
    marginTop: '6px',
    color: props.theme.colors.blue,
    fontSize: '14px',
    fontStyle: 'italic',
    [props.theme.breakpoints.mobileLarge]: {
      marginTop: 0,
    },
  },
  socialMedia: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    '> * + *': {
      marginLeft: '20px',
    },
    [props.theme.breakpoints.mobileLarge]: {
      marginTop: 0,
    },
  },
  articleBody: {
    marginTop: '24px',
    lineHeight: '22px',
    '> div p': {
      marginTop: '24px',
    },
    '> div ul': {
      listStyle: 'disc outside none',
      paddingLeft: '20px',
      margin: '20px 0',
    },
    '> div ul li': {
      marginBottom: '8px',
    },
    '> div ol': {
      listStyle: 'decimal',
      paddingLeft: '20px',
      margin: '20px 0',
    },
    '> div ol li': {
      marginBottom: '8px',
    },
    '> div b': {
      fontWeight: 'bold',
    },
    '> div strong': {
      fontWeight: 'bold',
    },
    '> div em': {
      fontStyle: 'italic',
    },
    '> div p img': {
      maxWidth: '100%',
    },
    '> div a': {
      fontWeight: 'bold',
      color: props.theme.colors.blue,
      '&:hover': {
        color: props.theme.colors.darkerBlue,
      },
    },
  },
});

export default felaConnect(styles)(Article);
