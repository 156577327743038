import React from 'react';
import { connect as felaConnect } from 'react-fela';
import { Redirect } from 'react-router-dom';

import PremierProfileForm from './PremierProfileForm';
import PremierProfileHeader from './PremierProfileHeader';
import { Helmet } from 'react-helmet';
function PremierProfile({
  profile,
  styles,
  location,
  userVM,
  isPremierSlugAvailable,
  editPremierProfile,
  setErrorBarMessage,
  clearErrorBar,
  getBraintreeToken,
  purchaseErrors,
  purchaseMembership,
  user,
  checkPhoneBlacklist,
  createPremierProfile,
  deletePremierProfileImage,
  getUploadToken,
  getUser,
  membershipPlans,
  membershipPlansLoaded,
  getMembershipPlans,
  updateBreedsAutocomplete,
  resetBreedsAutocomplete,
  breeds,
}) {
  useFetchMembershipPlans({ membershipPlansLoaded, getMembershipPlans });
  useRedirectToMembershipIfNotPremier({ userVM, location });
  return (
    <div className={styles.container}>
      <Helmet>
        <title>Premier Profile - Puppies.com</title>
      </Helmet>
      <PremierProfileHeader hasProfile={Boolean(profile)} />
      <div className={styles.wrapper}>
        <div>
          <PremierProfileForm
            backTo={location.state && location.state.backTo}
            checkPhoneBlacklist={checkPhoneBlacklist}
            clearErrorBar={clearErrorBar}
            deletePremierProfileImage={deletePremierProfileImage}
            fromMembership={location.state && location.state.fromMembership}
            getBraintreeToken={getBraintreeToken}
            getUploadToken={getUploadToken}
            getUser={getUser}
            isPremierSlugAvailable={isPremierSlugAvailable}
            plan={location.state && location.state.plan}
            membershipPlans={membershipPlans}
            profile={profile}
            purchaseMembership={purchaseMembership}
            purchaseErrors={purchaseErrors}
            setErrorBarMessage={setErrorBarMessage}
            submitAction={Boolean(profile) ? editPremierProfile : createPremierProfile}
            user={user}
            userVM={userVM}
            updateBreedsAutocomplete={updateBreedsAutocomplete}
            resetBreedsAutocomplete={resetBreedsAutocomplete}
            breeds={breeds}
          />
        </div>
      </div>
    </div>
  );
}

function useFetchMembershipPlans({ membershipPlansLoaded, getMembershipPlans }) {
  React.useEffect(() => {
    if (!membershipPlansLoaded) {
      getMembershipPlans();
    }
  }, [membershipPlansLoaded, getMembershipPlans]);
}

function useRedirectToMembershipIfNotPremier({ userVM, location }) {
  const fromMembership = location.state?.fromMembership;
  if (!userVM.get('isPremier') && !fromMembership) {
    return <Redirect to="/account/membership" />;
  }
}

const styles = {
  container: props => props.theme.globalBackgroundContainer,
  wrapper: props => props.theme.globalPageWrapper,
};

export default felaConnect(styles)(PremierProfile);
