import React from 'react';

function StarCircle() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g>
        <circle cx="12" cy="12" r="12" />
        <path
          d="M18.919 10.749l-3.405 3.39.862 4.52c.013.115-.035.225-.13.293-.048.034-.117.048-.164.048-.048 0-.11-.014-.158-.048L12 16.422l-3.924 2.53c-.096.062-.226.062-.322 0-.095-.068-.143-.178-.13-.294l.862-4.518-3.405-3.391c-.082-.075-.096-.205-.068-.321.048-.116.164-.178.28-.178h4.471l1.963-5.059C11.774 5.082 11.884 5 12 5c.116 0 .226.082.273.191l1.963 5.059h4.47c.117 0 .233.062.281.178.028.116.014.246-.068.321z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default StarCircle;
