import React, { useState } from 'react';
import { connect as felaConnect } from 'react-fela';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import loadFile from '../../utils/loadFile';
import { Icon, InputError } from '../';

const MAX_FILE_SIZE = 32 * 1024 * 1024;

const FileUploader = props => {
  const { styles, variant, limit, allowMultiple, onFilesLoaded, innerText, accept, noStyles = false } = props;

  const [errors, setErrors] = useState(null);

  const onDrop = () => {
    setErrors(null);
  };

  const onDropAccepted = async acceptedFiles => {
    onFilesLoaded(await Promise.all(acceptedFiles.map(async file => ({ file, data: await loadFile(file) }))));
  };

  const onDropRejected = files => {
    const rejections = [];
    files.forEach(rejectedFile => {
      const { errors, file } = rejectedFile;
      const filteredErrors = errors.filter(error => error.code !== 'too-many-files');
      filteredErrors.forEach(error => {
        rejections.push(`Rejected: ${file.name}: ${error.message}`);
      });
    });

    if (files.length > limit) {
      rejections.unshift(`Too many files - cannot upload more than ${limit}`);
    }

    setErrors(rejections);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropAccepted,
    onDropRejected,
    multiple: allowMultiple,
    maxSize: MAX_FILE_SIZE,
    maxFiles: limit || 0,
    accept,
  });

  // noStyles is used to remove the default styles from the dropzone.
  // This is used in the ConversationDisplay.js component, which has its own styles.
  return (
    <>
      <div {...getRootProps({ className: noStyles ? null : styles.dropzone })}>
        <input {...getInputProps()} />
        {props.children}
        {variant === 'blank' ? null : <Icon icon={variant === 'puppy' ? 'Puppy' : 'Avatar'} size={98} fill="#E1E1E1" />}
        <div className={styles.uploadText}>{innerText}</div>
      </div>
      {errors &&
        errors.map((error, index) => (
          <div key={`error${index}`} className={styles.error}>
            <InputError fixederror>{error}</InputError>
          </div>
        ))}
    </>
  );
};

const styles = {
  dropzone: props => ({
    height: '150px',
    width: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: props.theme.globalDashedBorder,
    borderRadius: '50%',
    position: 'relative',
    cursor: 'pointer',
    ':hover': {
      cursor: 'pointer',
    },
  }),
  uploadText: props => ({
    color: props.theme.colors.blue,
    fontWeight: props.theme.typography.sansBold,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    textAlign: 'center',
    width: '115px',
    lineHeight: '24px',
  }),
  error: {
    display: 'block',
    width: '100%',
  },
};

FileUploader.propTypes = {
  allowMultiple: PropTypes.bool,
  onFilesLoaded: PropTypes.func.isRequired,
  innerText: PropTypes.string,
  limit: PropTypes.number,
  onError: PropTypes.func,
  variant: PropTypes.oneOf(['puppy', 'human', 'blank']),
};

FileUploader.defaultProps = {
  allowMultiple: false,
  innerText: 'Upload a profile picture',
  onError: () => {},
  variant: 'puppy',
};

export default felaConnect(styles)(FileUploader);
