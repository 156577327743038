import { connect } from 'react-redux';
import {
  getCoatPatterns,
  getUploadToken,
  getColors,
  deleteListingImage,
  getListingCount,
} from '../../../../redux/actions';
import { getListing, editListing, deleteListing, resetListing } from '../../redux/actions';
import { selectCoatPatterns } from '../../../../redux/selectors';
import EditListing from './EditListing';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state, props) => ({
  coatPatternsLoading: state.coatPatternsLoading,
  getListingLoading: state.listings.getListingLoading,
  coatPatterns: selectCoatPatterns(state),
  currentListing: state.listings.currentListing,
  user: state.user,
  userVM: state.userVM,
  colorsLoading: state.colorsLoading,
  colors: state.colors,
  deleteListingLoading: state.listings.deleteListingLoading,
  listingCount: state.listingCount,
  getListingLoaded: state.listings.getListingLoaded,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCoatPatterns,
      editListing,
      getListing,
      deleteListing,
      getUploadToken,
      getColors,
      deleteListingImage,
      getListingCount,
      resetListing,
    },
    dispatch
  );

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    getListing: () => dispatchProps.getListing(ownProps.match.params.listingId),
    editListing: values => dispatchProps.editListing(values, ownProps.match.params.listingId),
    deleteListing: () => dispatchProps.deleteListing(ownProps.match.params.listingId),
    deleteImage: values => dispatchProps.deleteListingImage(values, ownProps.match.params.listingId),
  });
}
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditListing);
