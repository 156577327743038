import React, { Component } from 'react';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';

import {
  CircleAvatar,
  HistoryLink,
  Loading,
  Pager,
  Table,
  Tbody,
  Thead,
  Td,
  Tr,
  Th,
  PremierListHeader,
  TextLink,
  NoContentPlaceholder,
} from '../../../../../common/components';
import InteractiveBookmark from '../../../../../common/components/icons/InteractiveBookmark';
import theme from '../../../../../theme';
import generateInitials from '../../../../../common/utils/generateInitials';
import hasTouch from '../../../../../common/utils/hasTouch';
import { AnimatePresence, motion } from 'framer-motion';

const tableRowVariants = {
  enter: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
};

class FavoritePremiers extends Component {
  renderTotal = () => {
    const { results, loaded, total } = this.props;
    if (results && loaded && total) {
      return <PremierListHeader total={total} totalLabel="favorited" />;
    }
  };

  renderPager = () => {
    return <Pager pageSize={16} totalItems={this.props.total} />;
  };

  renderRows = () => {
    const { results, styles, isMobile } = this.props;
    return results.map(premier => (
      <motion.tr
        key={premier.get('premier_profile_id')}
        className={styles.tableRow}
        data-breederid={premier.get('id')}
        initial={tableRowVariants.exit}
        animate={tableRowVariants.enter}
        exit={tableRowVariants.exit}
        inherit={false}
      >
        <Td style={{ width: '100%' }}>
          <div className={styles.nameColumn}>
            <HistoryLink
              key={`link-${premier.get('premier_profile_id')}`}
              to={`/premier/${premier.get('slug')}`}
              backTo="Favorites"
            >
              <CircleAvatar
                size={64}
                photo={premier.getIn(['photos', 0])}
                initials={generateInitials(premier.get('name'))}
                verified={premier.getIn(['user', 'id_verified'])}
              />
            </HistoryLink>
            {this.renderPremierNameAndLocation(premier)}
          </div>
        </Td>
        {isMobile ? null : <Td>{this.renderBreedsAvailable(premier.get('breeds'))}</Td>}
        <Td className={styles.favoriteTd}>{this.renderFavoriteTag(premier)}</Td>
      </motion.tr>
    ));
  };

  renderBreedsAvailable = breeds => {
    const { styles } = this.props;
    return (
      <div className={styles.breedsAvailable}>
        {breeds.map(breed => (
          <HistoryLink key={breed.get('id')} to={`/breeds/${breed.get('slug')}`} backTo="Favorite Breeders">
            <TextLink>{breed.get('name')}</TextLink>
          </HistoryLink>
        ))}
      </div>
    );
  };

  renderPremierNameAndLocation = premier => {
    const { styles } = this.props;
    return (
      <div>
        <HistoryLink to={`/premier/${premier.get('slug')}`} backTo="Favorite Breeders">
          <TextLink className={styles.premierName}>{premier.get('name')}</TextLink>
        </HistoryLink>
        <div className={styles.premierLocation}>{premier.getIn(['user', 'formatted_location'])}</div>
      </div>
    );
  };

  renderTable = () => {
    const { total, styles, isMobile } = this.props;
    if (!total) {
      return (
        <NoContentPlaceholder
          title="No breeders added to favorites"
          description="When you find a breeder you want to save, favorite them by clicking the heart icon on their profile. They’ll show up here."
          to="/find-a-puppy"
          buttonText="Find a Puppy"
          topMargin
          globalPadding
        />
      );
    }
    return (
      <Table>
        {isMobile ? null : (
          <Thead>
            <Tr className={styles.favoritePremierHeadRow} transparent>
              <Th className={styles.nameColumnTh}>Name and Location</Th>
              <Th>Breeds Available</Th>
              <Th> </Th>
            </Tr>
          </Thead>
        )}
        <Tbody>
          <AnimatePresence>{this.renderRows()}</AnimatePresence>
        </Tbody>
      </Table>
    );
  };

  renderFavoriteTag = premier => {
    const { removeFavoritePremier, styles } = this.props;
    return (
      <div className={styles.cardBookmarkWrapper}>
        <div onClick={() => removeFavoritePremier(premier.get('premier_profile_id'))} className={styles.cardBookmark}>
          <InteractiveBookmark fill={theme.colors.orange} />
        </div>
      </div>
    );
  };

  render() {
    const { loaded, loading, results } = this.props;
    if (!loaded || loading) {
      return <Loading center dark />;
    }
    return (
      <div>
        {loaded && this.renderTotal()}
        {results && this.renderTable()}
        {results && this.renderPager()}
      </div>
    );
  }
}

const styles = {
  premierLocation: {
    fontSize: '12px',
    marginTop: '8px',
  },
  breedsAvailable: {
    fontSize: '12px',
    height: '82px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '400px',
    marginLeft: '-10px',
    '> *': {
      display: 'inline-flex',
      padding: '3px 10px',
    },
  },
  favoriteTd: {
    padding: '0 !important',
    verticalAlign: 'baseline !important',
    overflow: 'hidden',
  },
  cardBookmarkWrapper: props => ({
    padding: '10px 10px 0 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  cardBookmark: props => ({
    cursor: 'pointer',
    position: 'relative',
    top: '-2px',
    left: '2px',
    ':hover': {
      '> svg > path': {
        fill: hasTouch() ? null : '#FB9166',
      },
    },
  }),
  nameColumn: props => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    '> * + *': {
      marginLeft: '12px',
    },
    [props.theme.breakpoints.mobileLarge]: {
      fontSize: '16px',
      '> * + *': {
        marginLeft: '20px',
      },
    },
  }),
  favoritePremierHeadRow: {
    position: 'relative',
  },
  nameColumnTh: {
    position: 'absolute',
    top: '0',
    left: '86px',
  },
  tableRow: props => ({
    width: '100%',
    backgroundColor: props.theme.colors.tan,
    '&:nth-child(odd)': {
      backgroundColor: props.theme.colors.darkerTan,
    },
  }),
  premierName: {
    maxWidth: '160px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const mapStateToProps = state => ({
  isMobile: state.browser.lessThan.tablet,
});

export default connect(mapStateToProps)(felaConnect(styles)(FavoritePremiers));
