import React from 'react';
import PropTypes from 'prop-types';

import MembershipExtendPrompt from './MembershipExtendPrompt';
import MembershipStatus from './MembershipStatus';
import PremierCard from './PremierCard';
import StandardCard from './StandardCard';
import UpgradeToPremierCard from './UpgradeToPremierCard';

const MembershipLevel = ({
  hasRedeemedCoupon,
  membershipPlans,
  onSelectPlan,
  onCancelPendingMemberhip,
  styles,
  userVM,
  cancelMembership,
}) => {
  const [showExtendModal, setShowExtendModal] = React.useState(false);
  return (
    <div>
      <div>
        <MembershipLevelTitle styles={styles} isInactive={!userVM.get('isActive')} />
        <MembershipStatus
          styles={styles}
          userVM={userVM}
          onSelectExtend={() => setShowExtendModal(true)}
          onSelectResubscribe={() => onSelectPlan('premier')}
          onCancelPendingMemberhip={onCancelPendingMemberhip}
          cancelMembership={cancelMembership}
        />
        <MembershipExtendPrompt
          styles={styles}
          userVM={userVM}
          isOpen={showExtendModal}
          onRequestClose={() => setShowExtendModal(false)}
          membershipPlans={membershipPlans}
          onSelectPlan={onSelectPlan}
        />
        <MembershipUpgradePrompt styles={styles} userVM={userVM} onSelectUpgrade={() => onSelectPlan('premier')} />
        <MembershipPurchaseOptions
          styles={styles}
          userVM={userVM}
          membershipPlans={membershipPlans}
          onSelectPlan={onSelectPlan}
          hasRedeemedCoupon={hasRedeemedCoupon}
        />
      </div>
    </div>
  );
};

MembershipLevel.propTypes = {
  hasRedeemedCoupon: PropTypes.bool,
  membershipPlans: PropTypes.object,
  onSelectPlan: PropTypes.func.isRequired,
  onCancelPendingMemberhip: PropTypes.func,
  userVM: PropTypes.object.isRequired,
};

const MembershipLevelTitle = ({ styles, isInactive = false }) => {
  return (
    <div className={styles.titleWrapper}>
      <h1 className={styles.title}>Seller Membership</h1>
      <div className={styles.titleSeparator} />
      <p className={styles.sellerMembershipIntro}>
        {isInactive ? (
          <>
            Finding a puppy is completely free.
            <br />
            If you’re interested in becoming a seller, check out our options here.
          </>
        ) : (
          <>Manage your seller membership here</>
        )}
      </p>
    </div>
  );
};

const MembershipUpgradePrompt = ({ styles, userVM, onSelectUpgrade }) => {
  const isStandard = userVM.get('benefitLevel') === 'standard';
  const isActive = userVM.get('isActive');
  const isTrial = userVM.get('isTrial');
  const isLegacyOneTimeMembership = userVM.get('isLegacyOneTimeMembership');

  if (isActive && isStandard && !isTrial) {
    return (
      <UpgradeToPremierCard
        styles={styles}
        onSelectUpgrade={onSelectUpgrade}
        price={userVM.get('premierPrice')}
        supportContactRequired={isLegacyOneTimeMembership}
      />
    );
  }

  return null;
};

const MembershipPurchaseOptions = ({ styles, userVM, membershipPlans, onSelectPlan, hasRedeemedCoupon }) => {
  const hasNeverBeenAMember = !userVM.get('isMember');
  const hasExpiredMembership = userVM.get('isExpired');
  const hasCanceledSubscription =
    userVM.get('isPremier') && !userVM.get('hasActiveSubscription') && !userVM.get('hasPendingMembershipTransaction');
  const hasTrialMembership = userVM.get('isTrial');
  if (hasNeverBeenAMember || hasExpiredMembership || hasCanceledSubscription || hasTrialMembership) {
    return (
      <div className={styles.membershipCards}>
        <StandardCard
          planId={'thirty_day'}
          membershipPlans={membershipPlans}
          styles={styles}
          onSelectPlan={onSelectPlan}
          price={userVM.get('thirtyDayPrice')}
        />
        <PremierCard
          membershipPlans={membershipPlans}
          styles={styles}
          onSelectPlan={onSelectPlan}
          userVM={userVM}
          hasRedeemedCoupon={hasRedeemedCoupon}
        />
        <StandardCard
          planId={'ninety_day'}
          membershipPlans={membershipPlans}
          styles={styles}
          onSelectPlan={onSelectPlan}
          price={userVM.get('ninetyDayPrice')}
        />
      </div>
    );
  }

  return null;
};

export default MembershipLevel;
