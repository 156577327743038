import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { connect as felaConnect } from 'react-fela';
import compose from 'lodash/flowRight';
import theme from '../../../../theme';
import { Button, Icon, ButtonPlain } from '../../../../common/components';

class PuppyFindMigrationComplete extends Component {
  render() {
    const { styles, user } = this.props;
    return (
      <div>
        <div className={styles.goldBar}>
          <span>Thanks for being a member!</span>
        </div>
        <div className={styles.successIcon}>
          <Icon size={120} fill={theme.colors.green} icon="CheckCircle" />
        </div>
        <div className={styles.successText}>
          <h3>You're all set.</h3>
          <div className={styles.notBold}>We've got all the information we need from you. Thanks for confirming!</div>
        </div>
        <hr className={styles.hr} />
        <div className={styles.profileButtonWrapper}>
          <div className={styles.successButtonWrapper}>
            <Button
              buttonType="roundOrange"
              type="button"
              onClick={async () => {
                const action = await this.props.completeMigration(user.get('id'));
                if (action.response.ok) {
                  this.props.history.push('/');
                }
              }}
            >
              GO TO THE NEW PUPPIES.COM!
            </Button>
            <ButtonPlain
              type="button"
              style={{ fontSize: '14px', textDecorationLine: 'none' }}
              onClick={async () => {
                const action = await this.props.restartMigration(user.get('id'));
                if (action.response.ok) {
                  this.props.history.push('/account/puppyfind-user');
                }
              }}
            >
              Start Over
            </ButtonPlain>
          </div>
        </div>
      </div>
    );
  }
}

const styles = (props, touched, errors) => ({
  profileTop: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginBottom: '40px',
    '& > * + *': {
      marginLeft: '64px',
    },
    '@media (max-width: 900px)': {
      flexDirection: 'column',
      padding: '0 20px 32px',
      '& > * + *': {
        marginLeft: '0',
        marginTop: '24px',
      },
    },
  },
  profileTopFields: {
    width: '100%',
    maxWidth: '520px',
    '& > * + *': {
      marginTop: '16px',
    },
  },
  profileBottom: {
    '& > * + *': {
      marginTop: '24px',
    },
    '@media (max-width: 552px)': {
      padding: '0 16px',
    },
  },
  locationField: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '48px',
    '> div': {
      width: '250px',
      '@media (max-width: 552px)': {
        width: '100%',
      },
    },
    '> div > label > div > div > input': {
      border:
        touched.location && errors.location
          ? `1px solid ${props.theme.colors.red}`
          : `1px solid ${props.theme.colors.disabled}`,
      '&::placeholder': {
        fontWeight: props.theme.typography.sansBold,
        color: '#CDCDCD',
      },
    },
  },
  hr: {
    border: 'none',
    borderTop: props.theme.globalDashedBorder,
    margin: '48px auto',
    width: '100%',
    maxWidth: '520px',
  },
  profileButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '48px',
    '> * + *': {
      marginTop: '32px',
    },
    '> button': {
      borderRadius: '100px',
      minWidth: 'unset',
    },
    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  innerButton: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: '12px',
    },
  },
  modalChildren: {
    padding: '30px',
    backgroundColor: '#F5F5F5',
    borderRadius: '6px',
    '>p': {
      lineHeight: '16px',
      fontSize: '14px',
      marginBottom: '30px',
    },
  },
  modalTitle: {
    fontSize: '28px',
    lineHeight: '32px',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.brown,
    textAlign: 'center',
    margin: '30px 0 20px',
    borderRadius: '3px',
  },
  modalButtonGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '> * + *': {
      marginTop: '20px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      justifyContent: 'center',
      '> *': {
        width: '143px',
      },
      '> * + *': {
        marginTop: 0,
        marginLeft: '20px',
      },
    },
  },
  paymentModal: {
    borderRadius: '6px !important',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: '#D0021B',
    '>h1': {
      fontSize: '16px',
      color: props.theme.colors.textDark,
      marginBottom: '32px',
      fontWeight: props.theme.typography.sansBold,
    },
    '>p': {
      color: props.theme.colors.brown,
      fontSize: '16px',
      lineHeight: '1.5',
      width: '100%',
    },
  },
  modalFooter: {
    width: '100%',
    backgroundColor: props.theme.colors.darkerTan,
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formWrapper: {
    backgroundColor: props.theme.colors.tan,
  },
  goldBar: {
    backgroundColor: props.theme.colors.yellow,
    padding: '16px',
    textAlign: 'center',
    fontWeight: props.theme.typography.sansBold,
    marginBottom: '32px',
  },
  puppyCrownLogoWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  puppyLogoText: {
    width: '175px !important',
    height: '35px !important',
    '@media (max-width: 480px)': {
      width: '150px !important',
      height: '26px !important',
    },
  },
  logoToLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px 16px',
    backgroundColor: props.theme.colors.tan,
  },
  puppyFindLogo: {
    width: '222px',
    height: '32px',
    '@media (max-width: 480px)': {
      width: '148px',
      height: '21px',
    },
    '@media (max-width: 360px)': {
      width: '111px',
      height: '16px',
    },
  },
  thickArrow: {
    width: '40px',
    height: '40px',
    fill: props.theme.colors.green,
    '@media (max-width: 480px)': {
      width: '24px',
      height: '24px',
    },
  },
  aboutYouHeader: {
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.orange,
    textAlign: 'center',
    marginBottom: '32px',
  },
  fieldsInline: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '24px',
    '> * + *': {
      marginLeft: '24px',
    },
    '> div': {
      width: '100%',
    },
    '> div > label > div > input': {
      '&::placeholder': {
        fontWeight: props.theme.typography.sansBold,
        color: '#CDCDCD',
      },
    },
    '@media (max-width: 480px)': {
      flexDirection: 'column',
      '> * + *': {
        marginLeft: '0',
        marginTop: '16px',
      },
    },
  },
  profileTopWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 16px',
    marginBottom: '24px',
  },
  whyWeNeedThis: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '48px',
    '> button': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      '> * + *': {
        marginLeft: '4px',
      },
    },
  },
  puppyLogoOnly: {
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
    padding: '24px 16px',
    backgroundColor: props.theme.colors.tan,
  },
  successText: {
    width: '100%',
    maxWidth: '520px',
    margin: '0 auto',
    padding: '0 16px',
    textAlign: 'center',
    color: props.theme.colors.textDark,
    fontSize: '16px',
    lineHeight: '1.5',
    '> h3': {
      fontWeight: props.theme.typography.sansBold,
    },
  },
  successIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
    marginBottom: '48px',
  },
  successButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '> * + *': {
      marginTop: '72px',
    },
  },
  reminder: {
    fontSize: '16px',
    color: 'black',
    fontWeight: props.theme.typography.sansBold,
    textAlign: 'center',
  },
});

export default compose(felaConnect(styles), connect(), withRouter)(PuppyFindMigrationComplete);
