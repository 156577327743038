import React from 'react';

function Discover() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="16" viewBox="0 0 24 16" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <linearGradient x1="0%" y1="28.467%" x2="100%" y2="71.533%" id="a">
          <stop stopColor="#EDF1F3" offset="0%" />
          <stop stopColor="#D3DADF" offset="100%" />
        </linearGradient>
        <linearGradient x1="75.877%" y1="89.009%" x2="17.697%" y2="18.91%" id="d">
          <stop stopColor="#FFA700" offset="0%" />
          <stop stopColor="#E75A10" offset="100%" />
        </linearGradient>
        <filter x="-2.1%" y="-3.2%" width="104.2%" height="106.3%" filterUnits="objectBoundingBox" id="c">
          <feMorphology radius="1" in="SourceAlpha" result="shadowSpreadInner1" />
          <feOffset in="shadowSpreadInner1" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" in="shadowInnerInner1" />
        </filter>
        <filter x="-18.2%" y="-18.1%" width="136.5%" height="136.3%" filterUnits="objectBoundingBox" id="f">
          <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0.314067815 0 0 0 0 0.00146227912 0 0 0 0 0.00146227912 0 0 0 0.5 0"
            in="shadowInnerInner1"
          />
        </filter>
        <rect id="b" x="0" y="0" width="24" height="15.75" rx="1.125" />
        <path
          d="M9.878 1.385c0 .761-.614 1.379-1.37 1.379-.757 0-1.371-.618-1.371-1.38 0-.761.614-1.378 1.37-1.378.757 0 1.371.617 1.371 1.379"
          id="e"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <rect fill="url(#a)" width="24" height="15.75" rx="1.125" />
          <use fillOpacity="0" fill="#E5E5E5" xlinkHref="#b" />
          <use fill="#000" filter="url(#c)" xlinkHref="#b" />
        </g>
        <path
          d="M5.262 8.5c-.158.143-.363.206-.687.206H4.44V6.992h.135c.324 0 .521.058.687.21a.875.875 0 01.279.645.892.892 0 01-.279.653m-.587-1.948h-.737v2.593h.733c.39 0 .672-.093.92-.3.293-.244.467-.613.467-.995 0-.765-.568-1.298-1.383-1.298M6.29 9.145h.502V6.552h-.503zM8.02 7.547c-.3-.112-.39-.186-.39-.326 0-.164.159-.288.375-.288.15 0 .274.063.405.21l.263-.346a1.123 1.123 0 00-.757-.287c-.456 0-.803.318-.803.742 0 .357.162.54.634.711.196.07.296.117.347.148.1.066.15.16.15.268 0 .21-.165.366-.39.366a.594.594 0 01-.548-.346l-.325.315c.232.342.51.493.892.493.523 0 .889-.35.889-.851 0-.412-.17-.598-.741-.809M8.92 7.85c0 .762.595 1.353 1.36 1.353.217 0 .402-.043.63-.15v-.596c-.2.202-.378.284-.606.284-.506 0-.866-.37-.866-.894 0-.498.37-.89.842-.89.24 0 .421.085.63.29v-.594a1.277 1.277 0 00-.618-.16c-.761 0-1.372.604-1.372 1.357M14.895 8.294l-.687-1.742h-.55l1.094 2.66h.27l1.114-2.66h-.545zM16.363 9.145h1.425v-.44h-.923v-.699h.89v-.439h-.89v-.575h.923v-.44h-1.425zM18.77 7.746h-.147V6.96h.155c.313 0 .483.132.483.384 0 .26-.17.4-.491.4m1.008-.427c0-.486-.332-.766-.912-.766h-.745v2.593h.502V8.103h.066l.695 1.042h.618l-.811-1.092c.379-.078.587-.338.587-.735"
          fill="#212D34"
        />
        <g transform="translate(3.938 6.473)">
          <use fill="url(#d)" xlinkHref="#e" />
          <use fill="#000" filter="url(#f)" xlinkHref="#e" />
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default Discover;
