import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BreedMatchmaker from './BreedMatchmaker';
import { getMatchmakerResults } from '../../redux/actions';
import { searchListings } from '../../views/SearchListings/redux/actions';

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getMatchmakerResults, searchListings }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BreedMatchmaker);
