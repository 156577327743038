import React from 'react';
import { Modal, Icon } from '../../../../../common/components';
import formatPrice from '../../../../../common/utils/formatPrice';
import PremiumPlanBanner from '../components/PremiumPlanBanner';

const MembershipExtendPrompt = ({ styles, membershipPlans, isOpen, onRequestClose, onSelectPlan, userVM }) => {
  const thirtyDayPlan = membershipPlans.get('thirty_day');
  const ninetyDayPlan = membershipPlans.get('ninety_day');
  const thirtyDayPlanPrice = userVM.get('thirtyDayPrice');
  const ninetyDayPlanPrice = userVM.get('ninetyDayPrice');
  return (
    <Modal closeOnEscape closeOnOutsideClick onClose={onRequestClose} isOpen={isOpen}>
      <div className={styles.extendModalWrapper}>
        <div className={styles.extendHeader}>
          <h1>Extend your membership</h1>
          <p>Choose an option below</p>
        </div>
        <PremiumPlanBanner
          styles={styles}
          price={formatPrice(thirtyDayPlanPrice)}
          onSelect={() => onSelectPlan(thirtyDayPlan.get('id'))}
          days={thirtyDayPlan.get('duration')}
          style={{ background: 'linear-gradient(205deg, #FFC232 0%, #FF8F32 95%)' }}
          Icons={<Icon icon="PuppyWithCrown" size={47} />}
        />
        <PremiumPlanBanner
          styles={styles}
          price={formatPrice(ninetyDayPlanPrice)}
          onSelect={() => onSelectPlan(ninetyDayPlan.get('id'))}
          days={ninetyDayPlan.get('duration')}
          style={{ background: 'linear-gradient(205deg, #9BCF3C 0%, #64B334 100%)' }}
          Icons={
            <div className={styles.premierPuppyIcons}>
              <Icon icon="Puppy" size={24} className={styles.premierPuppyIcon} />
              <Icon icon="PuppyWithCrown" size={47} />
              <Icon icon="Puppy" size={24} className={styles.premierPuppyIcon} />
            </div>
          }
        />
      </div>
    </Modal>
  );
};

export default MembershipExtendPrompt;
