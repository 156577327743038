import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';

class ModalHeader extends Component {
  static propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    to: PropTypes.string,
    linkText: PropTypes.string,
    variant: PropTypes.oneOf(['warning', '']),
  };

  render() {
    const { title, subTitle, link, linkText, registered, styles } = this.props;
    return (
      <div className={styles.root}>
        <h1 className={styles.modalTitle}>{title}</h1>
        {subTitle && (
          <p className={styles.modalSubtitle}>
            {subTitle}
            {registered && <span className={styles.registered}>®</span>}
          </p>
        )}
        {link && (
          <a href={link} target="_blank" className={styles.linkText} rel="noopener noreferrer">
            {linkText}
          </a>
        )}
      </div>
    );
  }
}

const styles = {
  root: props => ({
    width: '100%',
    backgroundColor:
      props.variant === 'warning'
        ? props.theme.colors.red
        : props.variant === 'premierBlue'
        ? props.theme.colors.premierBlue
        : props.theme.colors.blue,
    padding: '30px 40px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    color: props.theme.colors.white,
    textAlign: 'center',
  }),
  modalTitle: props => ({
    fontWeight: props.theme.typography.sansBold,
    fontSize: '28px',
    lineHeight: '34px',
  }),
  modalSubtitle: props => ({
    fontSize: '14px',
    lineHeight: '1.25',
    marginTop: '14px',
  }),
  linkText: props => ({
    color: '#ffffff',
    textDecoration: 'underline',
    margin: '5px 0',
    fontWeight: 'bold',
  }),
  registered: props => ({
    fontSize: '12px',
    verticalAlign: 'super',
    marginLeft: '2px',
  }),
};

export default connect(styles)(ModalHeader);
