import React from 'react';

function Trash() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M22.504 0c.281 0 .504.223.504.504v1.992c0 .281-.223.504-.504.504h-21C1.223 3 1 2.777 1 2.496V.504C1 .223 1.223 0 1.504 0h21zm-19.5 5.004h18l-1.5 18.55c-.024.247-.246.446-.504.446H5.008c-.258 0-.48-.2-.504-.445l-1.5-18.551z" />
    </svg>
  );
  /* eslint-enable */
}

export default Trash;
