import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import classNames from 'classnames';

import { InputScaffold } from '..';

class RadioInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    styles: PropTypes.object.isRequired,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
    }),
    label: PropTypes.node,
    disabled: PropTypes.bool,
  };

  render() {
    const {
      field,
      form,
      className,
      styles,
      label,
      labelVariant,
      labelIcon,
      disabled,
      labelWrapperClass,
      report,
      ...passableProps
    } = this.props;

    const labelWrapperClasses = {
      [styles.labelWrapper]: true,
      [labelWrapperClass]: labelWrapperClass,
    };

    return (
      <InputScaffold labelVariant={labelVariant} disabled={disabled}>
        <label
          className={[styles.root, report && styles.radioBlue]}
          ref={l => {
            this.label = l;
          }}
          onKeyDown={e => {
            if (e.keyCode === 32 || e.keyCode === 13) {
              e.preventDefault();
              this.label.click();
            }
          }}
          onClick={() => this.label.blur()}
        >
          <input
            {...field}
            type="radio"
            checked={field.value}
            disabled={disabled}
            {...passableProps}
            style={{ display: 'inline-block' }}
          />
          <div className={classNames(labelWrapperClasses)}>
            {labelIcon ? labelIcon : null}
            <span className={[styles.label, report && styles.reportReasons]}>{label}</span>
          </div>
        </label>
      </InputScaffold>
    );
  }
}

const styles = {
  root: props => ({
    display: 'inline-flex',
    alignItems: 'center',
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    opacity: props.disabled ? '0.65' : '1',

    // https://github.com/rofrischmann/fela/blob/master/docs/basics/Rules.md#6-other-selectors
    '& svg': {
      fill: props.field.value ? props.theme.colors.blue : props.theme.colors.gray400,
    },
    ':active': {
      outline: 'none',
    },
    '> input': {
      marginTop: '0',
    },
  }),
  toggle: props => ({
    position: 'relative',
    display: 'inline-block',
    width: props.noPadding ? '21px' : '41px',
    height: props.noPadding ? '21px' : '41px',
    borderRadius: '4px',
    padding: props.noPadding ? 0 : '10px',
    flexShrink: '0',
    overflow: 'hidden',
  }),
  labelWrapper: props => ({
    display: 'flex',
    flexDirection: 'row',
    color: props.theme.colors.textDark,
    '> * + *': {
      marginLeft: '4px',
    },
  }),
  label: props => ({
    fontSize: '14px',
    fontWeight: props.report ? 'normal' : '700',
    textTransform: 'capitalize', // force first letter to capitalize
    paddingLeft: props.report ? '6px' : '12px',
    paddingRight: '12px', // extra hitbox padding
    userSelect: 'none',
    marginTop: props.report ? '4px' : null,
    marginBottom: props.report ? '4px' : null,
  }),
};

export default connect(styles)(RadioInput);
