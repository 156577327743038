import React from 'react';
import { Link } from 'react-router-dom';

const NoLongerAvailable = ({ styles, isSeller, listingName }) => {
  if (isSeller) {
    return <div className={styles.systemMessageText}>You Sold {listingName}</div>;
  }

  return (
    <div className={styles.systemMessageText}>
      <span>
        <p>
          This puppy is no longer available. <Link to="/find-a-puppy">Find another puppy.</Link>
        </p>
      </span>
    </div>
  );
};

export default NoLongerAvailable;
