import React from 'react';
import { connect } from 'react-redux';
import { isLoggedInSelector } from '../redux/selectors';
import { showAuthenticationModal } from '../views/Modal/redux/actions';

export const DoAfterLoginContext = React.createContext();

export function _DoAfterLoginProvider({ isLoggedIn, hasToken, showAuthenticationModal, children }) {
  const [thingToDo, setThingToDo] = React.useState(null);
  function doAfterLogin(thunk) {
    if (isLoggedIn) {
      thunk();
    } else {
      setThingToDo({ callback: thunk });
      if (!hasToken) {
        showAuthenticationModal();
      }
    }
  }

  React.useEffect(() => {
    if (isLoggedIn && thingToDo) {
      thingToDo.callback();
      setThingToDo(null);
    }
  }, [isLoggedIn, thingToDo]);

  return <DoAfterLoginContext.Provider value={{ doAfterLogin }}>{children}</DoAfterLoginContext.Provider>;
}

export function useDoAfterLogin() {
  const context = React.useContext(DoAfterLoginContext);

  if (!context) {
    throw new Error('useDoAfterLogin must be used within a DoAfterLoginProvider');
  }

  return context.doAfterLogin;
}

function mapStateToProps(state) {
  return {
    isLoggedIn: isLoggedInSelector(state),
    hasToken: Boolean(state.token),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAuthenticationModal: () => dispatch(showAuthenticationModal()),
  };
}

export const DoAfterLoginProvider = connect(mapStateToProps, mapDispatchToProps)(_DoAfterLoginProvider);
