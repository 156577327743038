export default function toKebabCase(str = '', splitAt = ', ') {
  if (!str || str === '' || str.trim().length === 0) {
    return str;
  }
  try {
    return str
      .toLowerCase()
      .split(splitAt)
      .join('-');
  } catch (err) {
    console.error(err);
    console.error(`Error: ${err}. Tried to lower case non-string value ${str} at index ${splitAt}`);
    return String(str)
      .toLowerCase()
      .split(splitAt)
      .join('-');
  }
}
