import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../../../theme';

const License = styled.span`
  background-color: ${theme.colors.blue};
  border-radius: 100px;
  display: inline-block;
  margin-left: ${props => (props.inline ? '10px' : '0')};
  padding: 6px 18px;
  width: fit-content;
  color: ${theme.colors.white};
  font-size: 14px;
  text-align: center;
`;

const NoLicense = styled(License)`
  background-color: ${theme.colors.extraLightBrown};
`;

const LICENSE_TYPE_MAP = {
  federal: {
    id: 'federal_license_id',
    label: 'USDA',
    name: 'is_federally_licensed',
  },
  state: {
    id: 'state_license_id',
    label: 'State',
    name: 'is_state_licensed',
  },
};

export const LicenseBadge = ({ inline = false, type = 'federal', user }) => {
  const userLicenses = user?.get('userLicenses')?.toJS() ?? {};
  const licenseMap = LICENSE_TYPE_MAP[type];

  if (!licenseMap) {
    return null;
  }

  const licenseId = `license-${type}`;
  const licensingAnswer = userLicenses[licenseMap.name];

  if (licensingAnswer === 'no') {
    return (
      <NoLicense inline={inline} data-testid={licenseId}>
        <strong>No {licenseMap.label}</strong>
      </NoLicense>
    );
  }

  if (licensingAnswer === 'not_required') {
    return (
      <NoLicense inline={inline} data-testid={licenseId}>
        {licenseMap.label} Not Required
      </NoLicense>
    );
  }

  if (licensingAnswer === 'yes') {
    if (userLicenses[licenseMap.id]) {
      return (
        <License inline={inline} data-testid={licenseId}>
          <strong>
            {licenseMap.label}: {userLicenses[licenseMap.id]}
          </strong>
        </License>
      );
    }

    return (
      <License inline={inline} data-testid={licenseId}>
        <strong>{licenseMap.label}</strong>
      </License>
    );
  }

  return null;
};

LicenseBadge.propTypes = {
  inline: PropTypes.bool,
  type: PropTypes.oneOf(['federal', 'state']),
  user: PropTypes.object,
};

export default LicenseBadge;
