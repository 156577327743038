import React from 'react';

function FacebookLetter() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M12.5 10.667H10V14h2.5v10h4.167V14h3.035L20 10.667h-3.333v-1.39c0-.795.16-1.11.929-1.11H20V4h-3.173C13.83 4 12.5 5.32 12.5 7.846v2.82z" />
    </svg>
  );
  /* eslint-enable */
}

export default FacebookLetter;
