import React from 'react';

function FatCheck() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M21.876 7.67L9.804 19.882c-.097.069-.185.118-.302.118-.117 0-.205-.05-.303-.118L2.124 12.72a.426.426 0 0 1 0-.602l2.918-2.95a.433.433 0 0 1 .595 0l3.865 3.927 8.86-8.977a.433.433 0 0 1 .596 0l2.918 2.95a.426.426 0 0 1 0 .602z"
        fillRule="evenodd"
      />
    </svg>
  );
  /* eslint-enable */
}

export default FatCheck;
