import React from 'react';

function Caret() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M8.006 0c.14 0 .246.059.352.14L19.86 11.633a.54.54 0 0 1 0 .727L8.358 23.85c-.188.199-.527.199-.715 0l-3.502-3.503a.524.524 0 0 1 0-.715l7.637-7.637-7.637-7.638a.524.524 0 0 1 0-.715L7.643.141A.584.584 0 0 1 8.006 0z" />
    </svg>
  );
  /* eslint-enable */
}

export default Caret;
