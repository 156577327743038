import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';

import { ButtonPlain, Modal, ModalBody, ModalHeader } from '../';
import Immutable from 'immutable';
import { sendEmailVerification } from '../../../redux/actions';

import Icon from '../../../common/components/icons/icon';
import theme from '../../../theme';

class VerifyEmailModal extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(Immutable.Map).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    sent: false,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.sendEmailVerificationLoading &&
      !this.props.sendEmailVerificationLoading &&
      this.props.sendEmailVerificationLoaded
    ) {
      this.setState({ sent: true });
    }
  }

  resendConfirmation = () => {
    const { dispatch } = this.props;

    dispatch(sendEmailVerification())
      .then(action => {
        if (action.response.ok) {
          this.setState({ sent: true });
        }
      })
      .catch(error => {});
  };

  onClose = () => {
    this.setState({ sent: false });
    this.props.onClose();
  };

  renderModalContentsAfterSent() {
    const { user, styles } = this.props;

    return (
      <React.Fragment>
        <ModalHeader title={`Verification email sent`} />
        <ModalBody>
          <div className={styles.iconContainer}>
            <div>
              <Icon icon="Envelope" fill={theme.colors.blue} size={60} />
            </div>
          </div>
          <p>
            Verification email sent. Check your <strong>{user.get('email')}</strong> inbox. If you don't see it, it
            might be in your spam folder. Click the link in the email and you'll be verified!
          </p>
        </ModalBody>
      </React.Fragment>
    );
  }

  renderModalContentsBeforeSent() {
    const { user, sendEmailVerificationLoading } = this.props;

    return (
      <React.Fragment>
        <ModalHeader title={`Verify your email address`} />
        <ModalBody>
          <p>
            You'll need to verify your email address before contacting others. Verification email was sent to{' '}
            <strong>{user.get('email')}</strong>
          </p>
          <p>Once this is done, please reload this page to continue.</p>
          <ButtonPlain onClick={this.resendConfirmation} disabled={sendEmailVerificationLoading}>
            Resend Confirmation Email
          </ButtonPlain>
        </ModalBody>
      </React.Fragment>
    );
  }

  render() {
    const { user, isOpen } = this.props;

    if (!user) {
      return null;
    }

    return (
      <Modal isOpen={isOpen} onClose={this.onClose} closeOnOutsideClick closeOnEscape>
        {this.state.sent ? this.renderModalContentsAfterSent() : this.renderModalContentsBeforeSent()}
      </Modal>
    );
  }
}

const styles = props => ({
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '8px 0 24px',
    '>div': {
      borderWidth: '8px',
      borderStyle: 'solid',
      borderColor: props.theme.colors.blue,
      borderRadius: '100%',
      padding: '14px',
    },
  },
});

export default connect(state => ({
  sendEmailVerificationLoading: state.sendEmailVerificationLoading,
  sendEmailVerificationLoaded: state.sendEmailVerificationLoaded,
}))(felaConnect(styles)(VerifyEmailModal));
