import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import { withRouter } from 'react-router-dom';
import { SubNav, ButtonPlain, ButtonLink, NoContentPlaceholder } from '../../../../common/components';
import moment from 'moment';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
const SearchBoxVariants = {
  enter: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
};

class SavedSearch extends Component {
  navigateToSearch = query => {
    const { history } = this.props;
    history.push(`/find-a-puppy/?${query}&saved_search=true`);
  };
  onDelete = async id => {
    await this.props.removeSavedSearch(id);
  };
  renderSavedSearchBox = search => {
    const { styles } = this.props;
    return (
      <motion.div
        key={search.get('id')}
        className={styles.searchBox}
        initial={SearchBoxVariants.exit}
        animate={SearchBoxVariants.enter}
        exit={SearchBoxVariants.exit}
        inherit={false}
      >
        <div className={styles.searchBoxHeader}>
          <span className={styles.searchBoxTitle}>{search.get('name')}</span>
          <span className={styles.searchBoxDate}>{moment(search.get('created_at')).format('MM/DD/YYYY')}</span>
        </div>
        <div className={styles.buttonRow}>
          <ButtonLink buttonType="roundSmall" to={`/find-a-puppy/?${search.get('query')}&saved_search=true`}>
            View Search
          </ButtonLink>
          <ButtonPlain variant="red" onClick={() => this.onDelete(search.get('id'))}>
            delete
          </ButtonPlain>
        </div>
      </motion.div>
    );
  };
  mapSavedSearches = () => this.props.user.get('savedSearches').map(search => this.renderSavedSearchBox(search));
  render() {
    const { user, styles } = this.props;
    return (
      <div className={styles.root}>
        <Helmet>
          <title>Saved Searches - Puppies.com</title>
        </Helmet>
        <SubNav titleText="Saved Searches" />
        <div className={styles.wrapper}>
          {(user.get('savedSearches').size && (
            <div className={styles.savedSearches}>
              <AnimatePresence>{this.mapSavedSearches()}</AnimatePresence>
            </div>
          )) || <NoContentPlaceholder title="You have no saved searches" />}
        </div>
      </div>
    );
  }
}

const styles = props => ({
  root: {
    ...props.theme.globalBackgroundContainer,
  },
  wrapper: {
    ...props.theme.globalPageWrapper,
  },
  title: {
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.orange,
    fontSize: '16px',
    marginBottom: '16px',
  },
  description: {
    fontSize: '14px',
  },
  divider: {
    width: '100%',
    borderTop: props.theme.globalDashedBorder,
    margin: '30px 0',
    [props.theme.browsers.ie11]: {
      margin: '30px 0 10px',
    },
  },
  savedSearches: {
    display: 'grid',
    gridGap: '20px',
    gridTemplateColumns: 'repeat(auto-fit, minMax(300px, 1fr))',
    [props.theme.breakpoints.desktop]: {
      gridTemplateColumns: 'repeat(auto-fit, 339px)',
    },
    [props.theme.browsers.ie11]: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: '-20px',
      marginRight: '-20px',
      '> *': {
        marginTop: '20px',
        marginLeft: '20px',
        width: '31%',
      },
    },
  },
  searchBox: {
    minHeight: '114px',
    height: '100%',
    padding: '20px',
    border: `1px solid #979797`,
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  searchBoxHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: props.theme.typography.sansBold,
    fontSize: '14px',
    '> * + *': {
      marginLeft: '16px',
    },
  },
  searchBoxTitle: {
    color: props.theme.colors.orange,
  },
  buttonRow: {
    marginTop: '10px',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default withRouter(felaConnect(styles)(SavedSearch));
