import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isLoggedInSelector } from '../../redux/selectors';
import { signUp, setErrorBarMessage, clearErrorBar } from '../../redux/actions';
import SignUp from './SignUp';

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedInSelector(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ signUp, setErrorBarMessage, clearErrorBar }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
