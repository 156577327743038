import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect as felaConnect } from 'react-fela';
import querystring from 'query-string';
import { Button, Checkbox, InputError, Icon } from '../../common/components';

import { withFormik, Field } from 'formik';
import theme from '../../theme';

class Unsubscribe extends Component {
  render() {
    const { isSubmitting, submitForm, status, styles } = this.props;

    const buttonCopy = isSubmitting ? 'SAVING...' : 'SAVE CHANGES';

    return (
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.checkboxes}>
            <h1>Notify me by email when...</h1>
            <Field
              label="Someone sends me a new message"
              name="message_notifications"
              component={Checkbox}
              labelWrapperClass={styles.labelWrapper}
            />
            <Field
              label="New listings match my saved searches"
              name="saved_search_notifications"
              component={Checkbox}
              labelWrapperClass={styles.labelWrapper}
            />
            <Field
              label="I have new Weekly Seller Stats"
              name="seller_stats_notifications"
              component={Checkbox}
              labelWrapperClass={styles.labelWrapper}
            />
          </div>
          <div className={styles.buttonRow}>
            {status && status.error && <InputError>{status.error}</InputError>}
            {status && status.success ? (
              <div className={styles.submitButtonWrapper}>
                <Button
                  style={{ backgroundColor: theme.colors.green }}
                  buttonType="roundOrange"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => submitForm()}
                >
                  CHANGES SAVED
                </Button>
                <Icon icon="FatCheck" fill={theme.colors.green} />
              </div>
            ) : (
              <Button
                type="button"
                disabled={isSubmitting}
                isSubmitting={isSubmitting}
                buttonType="roundOrange"
                onClick={() => submitForm()}
              >
                {buttonCopy}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const FormikUnsubscribe = withFormik({
  mapPropsToValues: props => {
    if (!isEmpty(props.formValues)) {
      return {
        ...props.formValues,
      };
    }

    return {
      message_notifications: true,
      verification_notifications: true,
      saved_search_notifications: true,
      seller_stats_notifications: true,
    };
  },
  enableReinitialize: true,

  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const { location } = props;
    const parsedLocation = querystring.parse(location.search);
    setSubmitting(true);
    setStatus({ success: false });

    if (parsedLocation.token) {
      const action = await props.updateNotificationsViaToken(parsedLocation.token, values);
      setSubmitting(false);
      if (!action.response.ok) {
        setStatus({
          error: action.json ? action.json.message : 'An error occurred',
        });
      } else {
        setStatus({ success: 'CHANGES SAVED' });
      }
    } else {
      setStatus({
        error: 'An error occurred',
      });
      setSubmitting(false);
    }
  },
})(Unsubscribe);

const styles = props => ({
  root: {
    ...props.theme.globalBackgroundContainer,
  },
  wrapper: {
    ...props.theme.globalPageWrapper,
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'column',
    '> h1': {
      fontWeight: props.theme.typography.sansBold,
      color: props.theme.colors.orange,
      marginBottom: '24px',
    },
    '> div': {
      display: 'inline-flex',
      marginLeft: '-8px',
    },
  },
  buttonRow: {
    marginTop: '50px',
    paddingTop: '30px',
    borderTop: props.theme.globalDashedBorder,
  },
  labelWrapper: {
    '> span': {
      fontSize: '14px',
      color: props.theme.colors.brown,
      textTransform: 'none',
    },
  },
  submitButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: '16px',
    },
  },
});

export default felaConnect(styles)(FormikUnsubscribe);
