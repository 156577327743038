import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import { Icon } from '../../components/';

class Chip extends Component {
  handleRemoval = e => {
    e.currentTarget.blur();
    this.props.onRemove(this.props.id);
  };

  render() {
    const { children, onRemove, styles } = this.props;
    return (
      <div className={styles.root}>
        {onRemove && (
          <button className={styles.button} onClick={this.handleRemoval} type="button">
            <Icon icon="Close" size={10} />
          </button>
        )}
        <div className={styles.children} data-testid={this.props['data-testid']}>
          {children}
        </div>
      </div>
    );
  }
}

Chip.propTypes = {
  onRemove: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

Chip.defaultProps = {
  variant: 'primary',
};

const styles = {
  root: props => ({
    display: 'inline-flex',
    alignItems: 'center',
    padding: props.variant === 'primary' ? '4px 12px' : '8px 11px',
    maxWidth: 'max-content',
    background: props.variant === 'primary' ? props.theme.colors.white : props.theme.colors.blue,
    fontSize: props.variant === 'primary' ? '10px' : '12px',
    fontWeight: props.theme.typography.sansBold,
    color: props.variant === 'primary' ? props.theme.colors.orange : props.theme.colors.white,
    borderRadius: '20px',
    lineHeight: '14px',
  }),
  button: props => ({
    fontWeight: props.theme.typography.sansBold,
    color: props.variant === 'primary' ? props.theme.colors.orange : props.theme.colors.white,
    fontSize: '10px',
    marginRight: '8px',
    transition: props.theme.globalTransition,
    backgroundColor: 'transparent',
    padding: '3px',
    borderRadius: '12px',
    ':hover': {
      backgroundColor: '#0D6E75',
      color: props.variant === 'primary' ? props.theme.colors.orangeHover : props.theme.colors.white,
    },
    ':active': {
      outline: 'none',
    },
  }),
  children: props => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
};

export default connect(styles)(Chip);
