import { connect } from 'react-redux';

import PrimaryLayout from './PrimaryLayout';
import { bindActionCreators } from 'redux';
import { isLoggedInSelector } from '../../redux/selectors';
import { selectUnreadMessages } from '../../views/Messages/redux/selectors';
import { logout } from '../../redux/actions';

const mapStateToProps = state => ({
  isLoggedIn: isLoggedInSelector(state),
  errorBarError: state.errorBarError,
  user: state.user,
  userVM: state.userVM,
  unreadMessages: selectUnreadMessages(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryLayout);
