import React from 'react';

function PaperAirplane() {
  /* eslint-disable max-len */
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.8262 0.132074C21.7661 0.0728496 21.6906 0.0313083 21.6081 0.01207C21.5256 -0.00716832 21.4394 -0.00334948 21.3589 0.0231018L0.285289 8.65005C0.195599 8.68626 0.119795 8.74971 0.0687633 8.83129C0.0177312 8.91287 -0.0059036 9.00838 0.00125284 9.1041C0.00364226 9.1945 0.032679 9.28222 0.0847808 9.35645C0.136883 9.43068 0.20977 9.48817 0.294451 9.52183L6.09428 11.7467C6.16458 11.7734 6.24025 11.7832 6.3151 11.7753C6.38996 11.7674 6.46186 11.742 6.52491 11.7013L14.2214 6.25267C14.3149 6.1756 14.4355 6.13852 14.5567 6.14959C14.6778 6.16066 14.7896 6.21897 14.8673 6.31169C14.9451 6.40442 14.9825 6.52396 14.9713 6.64402C14.9602 6.76409 14.9013 6.87484 14.8078 6.9519L8.39405 13.0816C8.30635 13.1682 8.25692 13.2858 8.25662 13.4085V19.5473C8.25561 19.6471 8.28849 19.7443 8.34997 19.8234C8.41146 19.9024 8.49798 19.9587 8.59563 19.9831C8.6924 20.0103 8.79557 20.0045 8.88861 19.9666C8.98165 19.9288 9.05917 19.861 9.10872 19.7743L12.0132 14.8524C12.0422 14.8017 12.0898 14.7639 12.1461 14.747C12.2024 14.7301 12.2631 14.7353 12.3156 14.7616L17.6573 17.6766C17.7197 17.7107 17.7892 17.73 17.8604 17.7328C17.9317 17.7356 18.0025 17.7219 18.0675 17.6928C18.1324 17.6638 18.1896 17.62 18.2345 17.5652C18.2794 17.5103 18.3108 17.4459 18.3261 17.3769L21.9911 0.577043C22.0077 0.495014 22.001 0.410053 21.9719 0.331533C21.9428 0.253014 21.8924 0.183989 21.8262 0.132074Z"
        fill="white"
      />
    </svg>
  );
  /* eslint-enable */
}

export default PaperAirplane;
