import React from 'react';

function QuestionCircle() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M12 0c6.61 0 12 5.39 12 12 0 6.645-5.39 12-12 12-6.645 0-12-5.355-12-12C0 5.39 5.355 0 12 0zm0 21c1.111 0 2-.891 2-2.006C14 17.891 13.111 17 12 17s-2 .891-2 1.994C10 20.11 10.889 21 12 21zm1.814-6.493c0-1.5 4.186-1.5 4.186-5.511C18 5.994 15.41 4 12.242 4 9.1 4 7 6.498 7 6.498l2.1 2.005s1.57-1.5 3.142-1.5c1.572 0 2.615.996 2.615 1.993 0 2.005-4.186 2.005-4.186 5.007V15h3.143v-.493z" />
    </svg>
  );
  /* eslint-enable */
}

export default QuestionCircle;
