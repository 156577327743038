import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonPlain,
  CircleAvatar,
  Modal,
  ModalBody,
  ModalFooter,
  RadioButton,
  InputError,
  ContentCapitalized,
  PlainLink,
} from '..';
import { Map } from 'immutable';
import generateInitials from '../../utils/generateInitials';
import { Field, withFormik } from 'formik';
import Icon from '../icons/icon';
import theme from '../../../theme';
import { LISTING, TESTIMONIAL, TESTIMONIAL_REPLY } from '../../constants/report-types';

import * as yup from 'yup';
import { connect as felaConnect } from 'react-fela';
import compose from 'lodash/flowRight';
import Textarea from '../Textarea';

class ReportModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(Map).isRequired,
    listing: PropTypes.instanceOf(Map),
    reasons: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    entity_id: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    block: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      blockingConfirmation: false,
      blockingError: false,
    };
  }

  onClose = () => {
    const { resetForm, onClose } = this.props;
    resetForm();
    onClose();
    this.setState({ blockingConfirmation: false, blockingError: false });
  };

  renderReasons() {
    const { block, values, reasons, styles, type } = this.props;
    const isReply = type === TESTIMONIAL_REPLY;

    const reasonList = Object.keys(reasons).filter(k => {
      if (isReply) {
        if (k === 'duplicate' || k === 'incorrect' || k === 'false') {
          return null;
        }
      }
      if (!block) {
        if (k === 'just_stop') {
          return null;
        }
      }

      return reasons[k];
    });

    return (
      <div className={styles.reportOptions}>
        {reasonList.map(key => (
          <Field
            key={`reason-${key}`}
            name="reason"
            component={RadioButton}
            value={key}
            label={reasons[key]}
            checked={values.reason === key}
            report
          />
        ))}
        {values.reason === 'other' ? (
          <div className={styles.textSm}>
            <Field key={`reason-other-text`} name="user_defined_reason" component={Textarea} report />
            <div className={styles.lightText}>{values.user_defined_reason.length}/200 Max</div>
          </div>
        ) : null}
      </div>
    );
  }

  renderReportForm() {
    const { user, styles, touched, errors, status, photo, title, buttonText, type } = this.props;
    const error = touched['reason'] && errors['reason'];

    const isReply = type === TESTIMONIAL_REPLY;

    return (
      <div>
        <div className={styles.reportTitle}>{title}</div>
        {[TESTIMONIAL, TESTIMONIAL_REPLY].indexOf(type) > -1 ? (
          <div className={styles.reportTestimonialContainer}>
            <div className={styles.reportTestimonialTitle}>Report This {isReply ? 'Reply' : 'Testimonial'}?</div>
            <div className={styles.reportTestimonialText}>
              Is something wrong? Does this {isReply ? 'reply' : 'testimonial'} violate our{' '}
              <PlainLink
                to="https://help.puppies.com/article/125-puppies-com-community-standards"
                target="new"
                variant="blue"
              >
                community standards
              </PlainLink>
              ? Let us know, and we'll look into it.
            </div>
          </div>
        ) : (
          <strong>Please provide a reason below</strong>
        )}
        <ModalBody className={styles.modalContentContainer}>
          <div className={styles.recipientInfo}>
            <div className={styles.avatar}>
              <CircleAvatar
                size={120}
                photo={photo}
                placeholderIcon={type === LISTING ? 'PuppyCircle' : null}
                initials={type === LISTING ? null : generateInitials(user.get('last_initial'), user.get('first_name'))}
                alt={'message-recipient'}
                verified={user.get('id_verified')}
                variant="red"
              />
              <div className={styles.warningIcon}>
                <Icon icon="Warning" size={16} fill={theme.colors.red} />
              </div>
            </div>
          </div>
          {this.renderReasons()}
        </ModalBody>
        <div className={styles.errorContainer}>
          {error ? <InputError>{error}</InputError> : null}
          {status && status.error ? <InputError>{status.error}</InputError> : null}
        </div>
        <ModalFooter
          actions={[
            <ButtonPlain type="button" variant="red" onClick={this.onClose}>
              Cancel
            </ButtonPlain>,
            <Button type="submit" buttonType="round">
              {buttonText}
            </Button>,
          ]}
        />
      </div>
    );
  }

  successText = reason => {
    const { styles } = this.props;
    if (reason === 'other') {
      return (
        <div className={styles.otherReasonTextStyles}>
          <p>
            Puppies.com will undertake a review of the seller based on your complaint. If you have additional
            information that you would like to submit as part of your complaint, please contact{' '}
            <Link to="/contact">Puppies.com Support</Link>. If you have concerns about animal cruelty or neglect, we
            recommend that you contact the humane law enforcement or animal control agency in the seller's location, or,
            if neither of these exists, the sheriff's department, police department, and/or state attorney general's
            office in the seller's location. If you have concerns that the seller is unlawfully selling without a
            license, we recommend that you contact the state department of agriculture in the seller's state and/or the
            U.S. Department of Agriculture ("USDA"){' '}
            <Link to="https://www.aphis.usda.gov/aphis/ourfocus/animalwelfare/complaint-form">here</Link>.
          </p>
        </div>
      );
    }
    return (
      <div>
        Thanks for letting us know! We're here to help you have a positive experience, and will take a look at the
        situation.
      </div>
    );
  };

  renderSuccess() {
    const { block, user, styles, listing, status } = this.props;

    return (
      <div data-testid="user-reported-confirmation">
        <div className={styles.reportTitle}>
          {listing ? (
            listing.get('name')
          ) : (
            <ContentCapitalized>
              {user.get('first_name')} {user.get('last_initial')}
            </ContentCapitalized>
          )}{' '}
          was {block ? 'blocked' : 'reported'}
        </div>
        <ModalBody className={styles.successModalBody}>
          <strong>Sorry you had that experience.</strong>
          {this.successText(status.reason_submitted)}
        </ModalBody>
        <ModalFooter
          actions={[
            <Button type="button" buttonType="round" onClick={this.onClose}>
              Close
            </Button>,
          ]}
        />
      </div>
    );
  }

  renderBlockForm() {
    const { user, styles, status, photo, title, validateForm } = this.props;
    const { blockingError } = this.state;

    return (
      <div>
        <div className={styles.reportTitle}>{title}</div>
        <strong>Please provide a reason below</strong>
        <ModalBody className={styles.modalContentContainer}>
          <div className={styles.recipientInfo}>
            <div className={styles.avatar}>
              <CircleAvatar
                size={120}
                photo={photo}
                initials={generateInitials(user.get('last_initial'), user.get('first_name'))}
                alt={'message-recipient'}
                verified={user.get('id_verified')}
                variant="red"
              />
              <div className={styles.warningIcon}>
                <Icon icon="Block" size={16} fill={theme.colors.red} />
              </div>
            </div>
          </div>
          {this.renderReasons()}
        </ModalBody>
        <div className={styles.errorContainer}>
          {blockingError ? <InputError>{blockingError}</InputError> : null}
          {status && status.error ? <InputError>{status.error}</InputError> : null}
        </div>
        <ModalFooter
          actions={[
            <ButtonPlain type="button" variant="red" onClick={this.onClose}>
              Cancel
            </ButtonPlain>,
            <Button
              type="button"
              buttonType="round"
              onClick={() => {
                validateForm().then(result => {
                  if (!result['reason']) {
                    this.setState({ blockingConfirmation: true });
                  } else {
                    this.setState({ blockingError: result['reason'] });
                  }
                });
              }}
            >
              Continue
            </Button>,
          ]}
        />
      </div>
    );
  }

  renderBlockConfirm() {
    const { photo, user, styles } = this.props;
    return (
      <div>
        <div className={styles.reportTitle}>Are you sure?</div>
        <ModalBody className={styles.modalContentContainer}>
          <div className={styles.recipientInfo}>
            <div className={styles.avatar}>
              <CircleAvatar
                size={120}
                photo={photo}
                initials={generateInitials(user.get('last_initial'), user.get('first_name'))}
                alt={'message-recipient'}
                verified={user.get('id_verified')}
                variant="red"
              />
              <div className={styles.warningIcon}>
                <Icon icon="Block" size={16} fill={theme.colors.red} />
              </div>
            </div>
          </div>
          <div className={styles.reportOptions}>
            <strong>
              By Blocking <ContentCapitalized>{user.get('first_name')}</ContentCapitalized>:
            </strong>
            <ul className={styles.blockList}>
              <li>Your conversation(s) will be archived</li>
              <li>They cannot message you</li>
              <li>You cannot message them</li>
            </ul>
          </div>
        </ModalBody>
        <div className={styles.warningText}>Blocking a user cannot be undone. Make sure that you want to do this.</div>
        <ModalFooter
          actions={[
            <ButtonPlain type="button" variant="red" onClick={this.onClose}>
              Cancel
            </ButtonPlain>,
            <Button type="submit" buttonType="round">
              Confirm
            </Button>,
          ]}
        />
      </div>
    );
  }

  render() {
    const { isOpen, status, block, handleSubmit } = this.props;
    const { blockingConfirmation } = this.state;
    let modalToRender = this.renderReportForm();
    if (status && status.success) {
      modalToRender = this.renderSuccess();
    } else if (block && !blockingConfirmation) {
      modalToRender = this.renderBlockForm();
    } else if (block && blockingConfirmation) {
      modalToRender = this.renderBlockConfirm();
    }

    return (
      <Modal isOpen={isOpen} onClose={this.onClose} closeOnOutsideClick closeOnEscape>
        <form id="report-form" onSubmit={handleSubmit} data-testid="report-modal">
          {modalToRender}
        </form>
      </Modal>
    );
  }
}

const styles = props => ({
  textSm: {
    fontSize: '12px',
  },
  textErr: {
    color: props.theme.colors.red,
  },
  otherReasonTextStyles: {
    '> p': {
      marginBottom: '10px',
      '> a': {
        color: props.theme.colors.blue,
        textDecoration: 'underline',
      },
    },
  },
  lightText: {
    color: props.theme.colors.gray,
  },
  successModalBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 0',
  },
  blockUserContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  recipientInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontSize: '14px',
    lineHeight: '19px',
    '> * + *': {
      marginTop: '32px',
    },
    [props.theme.breakpoints.mobileSmall]: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      '> * + *': {
        marginTop: 0,
        borderLeft: props.theme.globalDashedBorder,
        paddingLeft: '10px',
      },
    },
    [props.theme.breakpoints.tablet]: {
      '> * + *': {
        paddingLeft: '40px',
      },
    },
  },
  avatar: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '125px',
    '> * + *': {
      marginLeft: '16px',
    },
    [props.theme.breakpoints.mobileSmall]: {
      width: '100%',

      ':first-of-type': {
        paddingRight: '10px',
      },
    },
    [props.theme.breakpoints.tablet]: {
      ':first-of-type': {
        width: 'auto',
        paddingRight: '0',
      },
    },
  },
  avatarInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    overflow: 'hidden',
    '> *': {
      width: '65%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  warningIcon: {
    position: 'absolute',
    margin: '0',
    right: '5px',
    top: '0',
    backgroundColor: props.theme.colors.white,
    borderRadius: '100%',
    padding: '6px',
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: props.theme.colors.red,
  },
  errorContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    fontWeight: props.theme.typography.medium,
    margin: '10px 30px',
  },
  reportOptions: {
    display: 'flex',
    flex: 1.5,
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
  },
  modalContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '40px 0',
  },
  reportTitle: {
    maxWidth: '80%',
    color: props.theme.colors.red,
    fontSize: '26px',
    fontWeight: 'bold',
    margin: '40px auto 12px',
    wordBreak: 'break-word',
  },
  blockList: {
    listStyleType: 'disc',
    marginTop: '12px',
    marginLeft: '20px',
  },
  warningText: {
    backgroundColor: props.theme.colors.red,
    color: props.theme.colors.white,
    padding: '15px 30px',
  },
  reportTestimonialContainer: {
    padding: '0 40px 10px',
  },
  reportTestimonialTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: props.theme.colors.red,
    marginBottom: '15px',
  },
  reportTestimonialText: {
    fontWeight: 'bold',
    '> a': {
      color: props.theme.colors.blue,
      textDecoration: 'underline',
    },
  },
});

export default compose(
  withFormik({
    mapPropsToValues: props => {
      return {
        reason: '',
        user_defined_reason: '',
      };
    },
    validationSchema: yup.object().shape({
      reason: yup.string().required('Please select a reason'),
      user_defined_reason: yup.string().when('reason', {
        is: 'other',
        then: yup
          .string()
          .required('Please fill out other reason')
          .max(200, "You've exceeded the max character limit."),
      }),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: (values, { props, resetForm, setStatus, setSubmitting }) => {
      let submitMethod = props.reportUser.bind(
        this,
        props.user.get('id'),
        props.type,
        props.entity_id,
        values.reason,
        values.user_defined_reason
      );
      if (props.block) {
        submitMethod = props.blockUser.bind(this, props.user.get('id'), props.entity_id, values.reason);
      }
      submitMethod().then(action => {
        if (action.response.ok) {
          resetForm();
          setStatus({ success: true, reason_submitted: values.reason });
          if (props.block) {
            props.getUser();
          }
        } else {
          setStatus({ error: action.json ? action.json.message : 'Could not complete request at this time' });
          setSubmitting(false);
        }
      });
    },
  }),
  felaConnect(styles)
)(ReportModal);
