import { combineReducers } from 'redux';
import { fromJS, Map } from 'immutable';
import omit from 'lodash/omit';

const findListingGroupIndex = (mailbox, listingId) => {
  return mailbox.findIndex(conversationOrGroup => {
    return conversationOrGroup.get('is_listing_group') && conversationOrGroup.get('listing_id') === listingId;
  });
};

const findPathToConversation = (mailbox, conversation) => {
  if (conversation.listing_id) {
    const listingGroupIndex = findListingGroupIndex(mailbox, conversation.listing_id);
    if (listingGroupIndex > -1) {
      let convoInGroupIndex = mailbox
        .getIn([listingGroupIndex, 'conversations'])
        .findIndex(convo => convo.get('id') === conversation.id);
      if (convoInGroupIndex > -1) {
        return [listingGroupIndex, 'conversations', convoInGroupIndex];
      }
    }
  }

  const convoIndex = mailbox.findIndex(convo => convo.get('id') === conversation.id);
  if (convoIndex > -1) {
    return [convoIndex];
  }
};

const moveConversation = (state, from, to, conversation) => {
  const fromPath = findPathToConversation(state.get(from), conversation);

  if (fromPath) {
    const convoToMove = state.getIn([from].concat(fromPath));

    if (conversation.listing_id) {
      const pathToListingGroup = fromPath.slice(0, 1);
      let fromListingGroup = state.getIn([from].concat(pathToListingGroup));
      // Finding target listing group to move conversation into
      const listingGroupIndex = findListingGroupIndex(state.get(to), conversation.listing_id);
      if (listingGroupIndex > -1) {
        state = state
          .setIn(
            [to, listingGroupIndex, 'conversations'],
            state.getIn([to, listingGroupIndex, 'conversations']).push(convoToMove)
          )
          .sortBy(convo => convo.get('created_at') || convo.getIn(['conversations', 0, 'created_at']));
      } else {
        if (fromListingGroup.get('conversations')) {
          // Listing group does not exist in target mailbox, so move the group too
          let listingGroupWithOtherConversationsFilteredOut = fromListingGroup.set(
            'conversations',
            fromListingGroup.get('conversations').filter(convo => convo.get('id') === conversation.id)
          );
          state = state.set(to, state.get(to).push(listingGroupWithOtherConversationsFilteredOut));
        } else {
          // Listing groups for buyers only contains the one conversation, so we can copy over the whole group
          state = state.set(to, state.get(to).push(fromListingGroup));
        }
      }
      // If no more conversations in the group, delete it from the source mailbox
      if (fromListingGroup.get('conversations') && fromListingGroup.get('conversations').size === 1) {
        return state.deleteIn([from].concat(pathToListingGroup));
      }
    } else {
      state = state.set(to, state.get(to).push(convoToMove));
    }
  }
  return state.deleteIn([from].concat(fromPath));
};

const updateConversation = (mailbox, conversation) => {
  if (!mailbox) {
    return mailbox;
  }

  const pathToConversation = findPathToConversation(mailbox, conversation);

  if (pathToConversation) {
    return mailbox.setIn(pathToConversation.concat('messages'), fromJS(conversation.messages));
  } else {
    return mailbox;
  }
};

const addTestimonial = (mailbox, testimonial) => {
  return (
    mailbox &&
    mailbox.map(convoOrGroup => {
      if (convoOrGroup.get('listing_id') === testimonial.listing_id) {
        return convoOrGroup.setIn(['listing', 'testimonial'], fromJS(testimonial));
      }
      return convoOrGroup;
    })
  );
};

const editListing = (mailbox, listing) => {
  if (mailbox) {
    const listingIndex = mailbox.findIndex(convo => convo.get('listing_id') === listing.id);
    if (listingIndex > -1) {
      return mailbox.mergeIn([listingIndex, 'listing'], fromJS(listing));
    }
  }
  return mailbox;
};

const deleteListingGroup = (mailbox, listingId) => {
  return mailbox && mailbox.filter(convo => convo.get('listing_id') !== listingId);
};

const conversations = (state = fromJS({ inbox: [], archive: [] }), action) => {
  switch (action.type) {
    case 'GET_CONVERSATIONS_SUCCESS':
      return state.set('inbox', fromJS(action.json?.conversations));
    case 'GET_ARCHIVED_CONVERSATIONS_SUCCESS':
      return state.set('archive', fromJS(action.json?.conversations));
    case 'GET_MORE_CONVERSATIONS_SUCCESS':
      return state.set('inbox', state.get('inbox').concat(fromJS(action.json.conversations)));
    case 'GET_MORE_ARCHIVED_CONVERSATIONS_SUCCESS':
      return state.set('archive', state.get('archive').concat(fromJS(action.json.conversations)));
    case 'GET_CONVERSATION_SUCCESS':
      return state
        .set('inbox', updateConversation(state.get('inbox'), action.json))
        .set('archive', updateConversation(state.get('archive'), action.json));
    case 'MARK_CONVERSATION_AS_READ_SUCCESS':
      if (!action.json) {
        return state;
      }
      return state.set(
        'inbox',
        state.get('inbox').map(conversation => {
          if (conversation.get('conversations')) {
            const markedConvoIndex = conversation
              .get('conversations')
              .findIndex(convo => convo.get('id') === action.conversationId);
            if (markedConvoIndex > -1) {
              const markedConvoPath = ['conversations', markedConvoIndex, 'messages'];
              return conversation.setIn(markedConvoPath, fromJS(action.json.messages));
            }
          } else {
            if (conversation.get('id') === action.conversationId) {
              return conversation.set('messages', fromJS(action.json.messages));
            }
          }
          return conversation;
        })
      );
    case 'SEND_MESSAGE_TO_LISTING_OWNER_SUCCESS':
      return state.set('inbox', state.get('inbox').unshift(fromJS(action.json)));
    case 'SEND_MESSAGE_TO_USER_SUCCESS':
    case 'WS_CONVERSATION_STARTED':
      if (action.json.listing_id) {
        const listingGroupIndex = state.get('inbox').findIndex(conversationOrGroup => {
          return (
            conversationOrGroup.get('is_listing_group') &&
            conversationOrGroup.get('listing_id') === action.json.listing_id
          );
        });
        if (listingGroupIndex > -1) {
          return state.setIn(
            ['inbox', listingGroupIndex, 'conversations'],
            state.getIn(['inbox', listingGroupIndex, 'conversations']).unshift(fromJS(omit(action.json, 'listing')))
          );
        } else {
          return state.set(
            'inbox',
            state.get('inbox').unshift(
              fromJS({
                is_listing_group: true,
                listing_id: action.json.listing_id,
                listing: action.json.listing,
                conversations: [omit(action.json, 'listing')],
              })
            )
          );
        }
      } else {
        return state.set('inbox', state.get('inbox').unshift(fromJS(action.json)));
      }
    case 'WS_REPLY_RECEIVED':
    case 'REPLY_TO_CONVERSATION_SUCCESS':
    case 'WS_SYSTEM_MESSAGE_RECEIVED':
      const replyConvoId = action.json.conversation_id;
      const pathToMessages = [];
      const listingId = action.listingId || action.json.listingId;
      if (listingId) {
        const listingIndex = state.get('inbox').findIndex(convo => convo.get('listing_id') === listingId);
        if (listingIndex === -1) {
          return state;
        }
        pathToMessages.push('inbox', listingIndex);
        if (state.getIn(pathToMessages).get('conversations')) {
          pathToMessages.push('conversations');
          const listingConvoIndex = state.getIn(pathToMessages).findIndex(convo => convo.get('id') === replyConvoId);
          if (listingConvoIndex === -1) {
            return state;
          }
          pathToMessages.push(listingConvoIndex);
        }
      } else {
        const convoIndex = state.get('inbox').findIndex(convo => convo.get('id') === replyConvoId);
        if (convoIndex === -1) {
          return state;
        }
        pathToMessages.push('inbox', convoIndex);
      }
      return state.setIn(
        pathToMessages,
        state.getIn(pathToMessages).set(
          'messages',
          state
            .getIn(pathToMessages)
            .get('messages')
            .push(fromJS(action.json))
        )
      );
    case 'EDIT_LISTING_SUCCESS':
      return state
        .set('inbox', editListing(state.get('inbox'), action.json))
        .set('archive', editListing(state.get('archive'), action.json));
    case 'DELETE_LISTING_SUCCESS':
      return state
        .set('inbox', deleteListingGroup(state.get('inbox'), action.listingId))
        .set('archive', deleteListingGroup(state.get('archive'), action.listingId));
    case 'ARCHIVE_CONVERSATION_SUCCESS':
      return moveConversation(state, 'inbox', 'archive', action.json);
    case 'UNARCHIVE_CONVERSATION_SUCCESS':
      return moveConversation(state, 'archive', 'inbox', action.json);
    case 'CREATE_OR_UPDATE_TESTIMONIAL_SUCCESS':
      return state
        .set('inbox', addTestimonial(state.get('inbox'), action.json))
        .set('archive', addTestimonial(state.get('archive'), action.json));
    case 'WS_CONVERSATION_REVIVED':
      const path = findPathToConversation(state.get('archive'), action.json);
      if (path) {
        return state.deleteIn(['archive'].concat(path));
      }
      return state;
    default:
      return state;
  }
};

const conversationLoading = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_CONVERSATION_REQUEST':
      return state.set(action.conversationId, true);
    case 'GET_CONVERSATION_FAILURE':
    case 'GET_CONVERSATION_ERROR':
    case 'GET_CONVERSATION_SUCCESS':
      return state.set(action.conversationId, false);
    default:
      return state;
  }
};

const conversationLoaded = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_CONVERSATION_SUCCESS':
      return state.set(action.conversationId, true);
    case 'ARCHIVE_CONVERSATION_SUCCESS':
      return state.set(action.conversationId, false);
    case 'RESET_CONVERSATION_LOADED':
    case 'GET_CONVERSATIONS_SUCCESS':
      return Map();
    default:
      return state;
  }
};

const conversationsLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_CONVERSATIONS_REQUEST':
    case 'GET_CONVERSATIONS_ERROR':
      return false;
    case 'GET_CONVERSATIONS_SUCCESS':
      return true;
    default:
      return state;
  }
};

const archivedConversationsLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_ARCHIVED_CONVERSATIONS_SUCCESS':
      return true;
    default:
      return state;
  }
};

const sendMessageLoaded = (state = false, action) => {
  switch (action.type) {
    case 'SEND_MESSAGE_TO_LISTING_OWNER_SUCCESS':
    case 'SEND_MESSAGE_TO_USER_SUCCESS':
      return true;
    case 'SEND_MESSAGE_TO_USER_RESET':
    case 'SEND_MESSAGE_TO_USER_REQUEST':
    case 'SEND_MESSAGE_TO_LISTING_OWNER_RESET':
    case 'SEND_MESSAGE_TO_LISTING_OWNER_REQUEST':
      return false;
    default:
      return state;
  }
};

const conversationPageAndTotal = (
  state = { nextInboxPage: 0, nextArchivePage: 0, inboxTotal: 0, archiveTotal: 0 },
  action
) => {
  switch (action.type) {
    case 'GET_CONVERSATIONS_REQUEST':
    case 'GET_MORE_ARCHIVED_CONVERSATIONS_REQUEST':
      return { nextInboxPage: 0, inboxTotal: 0, nextArchivePage: 0, archiveTotal: 0 };
    case 'GET_MORE_CONVERSATIONS_SUCCESS':
    case 'GET_CONVERSATIONS_SUCCESS':
      return { nextInboxPage: action.json.nextInboxPage, inboxTotal: action.json.inboxTotal };
    case 'GET_ARCHIVED_CONVERSATIONS_SUCCESS':
    case 'GET_MORE_ARCHIVED_CONVERSATIONS_SUCCESS':
      return { nextArchivePage: action.json.nextArchivePage, archiveTotal: action.json.archiveTotal };
    case 'GET_CONVERSATIONS_ERROR':
    case 'GET_MORE_ARCHIVED_CONVERSATIONS_ERROR':
      return { nextInboxPage: null, inboxTotal: 0, nextArchivePage: null, archiveTotal: 0 };
    default:
      return state;
  }
};

export default combineReducers({
  archivedConversationsLoaded,
  conversationsLoaded,
  conversations,
  conversationLoading,
  conversationLoaded,
  conversationPageAndTotal,
  sendMessageLoaded,
});
