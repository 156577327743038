import React from 'react';

function CheckRounded() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M23.85 7.356L9.366 21.853c-.117.082-.222.14-.363.14-.14 0-.246-.058-.363-.14L.15 13.352c-.199-.188-.199-.527 0-.715l3.502-3.5c.187-.188.527-.188.714 0l4.637 4.66L19.635 3.14c.187-.188.527-.188.714 0l3.502 3.5c.199.188.199.528 0 .715z" />
    </svg>
  );
  /* eslint-enable */
}

export default CheckRounded;
