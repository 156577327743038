import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../../../../common/config';

export default function ListingMeta({ listing, seller }) {
  const imagesArray = [];
  let breedString = '';
  listing.get('photos').forEach(photo => {
    imagesArray.push(`${config.s3UrlBase}${photo.get('key')}`);
  });
  listing.get('breeds').forEach((breed, index) => {
    if (index !== 0) {
      breedString += ' ';
    }
    breedString += breed.get('name');
  });
  const sellerName =
    listing.hasIn(['user', 'premierProfile']) && listing.getIn(['user', 'isActivePremier'])
      ? seller.getIn(['premierProfile', 'name'])
      : seller.get('last_initial');
  const [firstImageUrl] = imagesArray;
  const canonicalUrl = `${config.frontendHost}/listings/${listing.get('id')}`;
  const breedText = listing
    .get('breeds')
    .map(b => b.get('name'))
    .join(' and ');
  const location = listing.get('user')?.get('formatted_location') || '';
  let title = `${listing.get('name')} is a ${breedText} puppy for sale in ${location}`;
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org/',
          '@type': 'Organization',
          name: listing.get('name'),
          image: imagesArray,
          description: listing.get('description'),
          brand: {
            '@type': 'Thing',
            name: breedString,
          },
          offers: {
            '@type': 'Offer',
            url: canonicalUrl,
            priceCurrency: 'USD',
            price: listing.get('price'),
            itemCondition: 'https://schema.org/UsedCondition',
            availability: 'https://schema.org/InStock',
            seller: {
              '@type': 'Organization',
              name: sellerName,
            },
          },
        })}
      </script>
      <link rel="canonical" href={canonicalUrl} />
      <title>{title}</title>
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={listing.get('description')} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={firstImageUrl || ''} />
      <meta name="title" content={title} />
      <meta name="description" content={listing.get('description')} />
    </Helmet>
  );
}
