import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function getFormattedPhoneNumber(number) {
  if (!number) {
    return null;
  }

  if (number.toString()[0] !== '+') {
    number = `+${number.length < 11 ? `1${number}` : number}`;
  }

  const phone = parsePhoneNumberFromString(number);

  return phone ? `+${phone.countryCallingCode} ${phone.formatNational()}` : null;
}
export function isValidPhoneNumber(number) {
  if (!number) {
    return true;
  }
  const phone = parsePhoneNumberFromString(number);
  return phone && phone.isValid();
}
