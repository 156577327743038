import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';

import { Button, InputError, TextInput, Icon } from '../../common/components';
import equalTo from '../../common/utils/yupEqualTo';
import theme from '../../theme';

yup.addMethod(yup.string, 'equalTo', equalTo);

class ChangePasswordForm extends Component {
  state = {
    showMigrationInfoModal: false,
  };

  render() {
    const { status, handleSubmit, isSubmitting, styles, isMigration } = this.props;

    return (
      <div className={styles.wrapper}>
        <form id="change-password-form" onSubmit={handleSubmit}>
          <div className={styles.inputs}>
            <div className={styles.inputWrapper}>
              <Field label="Current Password" name="current_password" type="password" component={TextInput} />
            </div>
            <div className={styles.inputWrapper}>
              <Field
                label="New Password"
                name="password"
                type="password"
                autoComplete="new-password"
                component={TextInput}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Field
                label="Confirm New Password"
                name="password_confirmation"
                type="password"
                autoComplete="new-password"
                component={TextInput}
              />
            </div>
          </div>
          <div>
            {isMigration && (
              <div className={styles.isMigrationBottom}>
                <p>New password must be at least 8 characters long.</p>
              </div>
            )}
            {status && status.error && <InputError>{status.error}</InputError>}
            <div className={styles.buttonWrapper} style={{ justifyContent: isMigration && 'center' }}>
              <Button
                type="submit"
                buttonType="roundOrange"
                style={status && status.success ? { backgroundColor: theme.colors.green } : null}
                disabled={isSubmitting}
              >
                {status && status.success ? `PASSWORD CHANGED` : `SAVE CHANGES`}
              </Button>
              {status && status.success ? <Icon icon="FatCheck" fill={theme.colors.green} /> : null}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const FormikChangePasswordForm = withFormik({
  mapPropsToValues: () => ({ current_password: '', password: '', password_confirmation: '' }),
  validationSchema: yup.object().shape({
    current_password: yup.string().required('Current password is required'),
    password: yup
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .max(255, 'Password may not exceed 255 characters')
      .required('New password is required'),
    password_confirmation: yup
      .string()
      .equalTo(yup.ref('password'), 'Passwords do not match')
      .required('Confirm your new password'),
  }),
  handleSubmit: async (values, { props, setSubmitting, setStatus, resetForm }) => {
    setStatus({ success: false });
    const action = await props.submitAction(values);
    setSubmitting(false);
    if (!action.response.ok) {
      props.setErrorBarMessage(action.json.message);
    } else {
      if (props.location.prevLocation) {
        props.history.replace(props.location.prevLocation);
      }
      resetForm();
      setStatus({ success: true });
    }
  },
})(ChangePasswordForm);

const styles = props => ({
  wrapper: {
    marginTop: '24px',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    '> * + *': {
      marginTop: '24px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      '> * + *': {
        marginTop: 0,
        marginLeft: '20px',
      },
    },
  },
  inputWrapper: {
    [props.theme.breakpoints.mobile]: {
      width: '182px',
    },
  },
  buttonWrapper: {
    borderTop: props.theme.globalDashedBorder,
    paddingTop: '28px',
    margin: '50px 0',
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: '12px',
    },
  },
  isMigrationBottom: {
    marginTop: '40px',
    '> p': {
      width: '100%',
      maxWidth: '350px',
      fontSize: '14px',
      margin: '0 auto',
      lineHeight: '1.5',
    },
  },
  whyWeNeedThis: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '48px',
    marginTop: '60px',
    '> button': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      '> * + *': {
        marginLeft: '4px',
      },
    },
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: '#D0021B',
    '>h1': {
      fontSize: '16px',
      color: props.theme.colors.textDark,
      marginBottom: '32px',
      fontWeight: props.theme.typography.sansBold,
    },
    '>p': {
      color: props.theme.colors.brown,
      fontSize: '16px',
      lineHeight: '1.5',
      width: '100%',
    },
    '>h4': {
      color: props.theme.colors.textDark,
      fontSize: '18px',
      fontWeight: props.theme.typography.sansBold,
      margin: '12px 0',
    },
  },
  modalFooter: {
    width: '100%',
    backgroundColor: props.theme.colors.darkerTan,
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
});

export default connect()(felaConnect(styles)(FormikChangePasswordForm));
