import React, { createContext, useReducer } from 'react';
import { connect } from 'react-redux';

import { handleFileUpload } from '../../utils/uploads/handleFileUpload';
import { getUploadToken } from '../../../redux/actions';
import createFileKey from '../../utils/uploads/createFileKey';

export const PhotoGalleryContext = createContext();

export const usePhotoGallery = () => {
  const context = React.useContext(PhotoGalleryContext);

  if (!context) {
    throw new Error('usePhotoGallery must be used within a PhotoGalleryProvider');
  }

  return context;
};

const initialState = {
  onEdit: () => {},
  onUpload: () => {},
  path: '',
  photoUploads: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'progress': {
      const updatedPhotos = state.photoUploads.map(p => {
        if (p?.key === action.key) {
          return {
            ...p,
            percentage: action.progress,
          };
        }

        return p;
      });

      return {
        ...state,
        photoUploads: updatedPhotos,
      };
    }

    case 'remove': {
      const updatedPhotos = state.photoUploads.filter(p => p.key !== action.key);

      return {
        ...state,
        photoUploads: updatedPhotos,
      };
    }

    case 'update': {
      const updatedPhotos = state.photoUploads.map(p => {
        if (p.key === action.photo.key) {
          return action.photo;
        }

        return p;
      });

      return {
        ...state,
        photoUploads: updatedPhotos,
      };
    }

    case 'upload': {
      return {
        ...state,
        photoUploads: action.photos,
      };
    }

    default:
      return state;
  }
};

export const PhotoGalleryProvider = ({ children, getUploadToken = () => {}, ...restProps }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...restProps });

  const handleProgress = (progress, key) => {
    dispatch({ type: 'progress', key, progress });
  };

  const removePhotoUpload = key => {
    dispatch({ type: 'remove', key });
  };

  const setPhotoUploads = photos => {
    dispatch({ type: 'upload', photos });
  };

  const updatePhotoUpload = photo => {
    dispatch({ type: 'update', photo });
  };

  const editPhoto = async ({ hasCropped, image = '', setError = () => {}, sortIndex = 0, title = '' }) => {
    const { onEdit, path } = state;

    if (hasCropped) {
      const handleEditProgress = (progress = {}) => {
        onEdit(
          {
            percentage: Math.floor((progress.loaded / progress.total) * 100),
          },
          sortIndex
        );
      };

      onEdit(
        {
          loading: true,
          percentage: 0,
        },
        sortIndex
      );

      const key = createFileKey(path);
      try {
        await handleFileUpload({ getUploadToken, file: image, key, handleProgress: handleEditProgress });

        onEdit(
          {
            croppedKey: key,
            loading: false,
            title,
            value: image,
          },
          sortIndex
        );
      } catch (e) {
        setError('There was an error editing your photo. Please try again. You can still save your listing.');
      }
    } else {
      onEdit(
        {
          title,
        },
        sortIndex
      );
    }
  };

  const removePhoto = ({ photo = {}, setError = () => {} }) => {
    const { photoUploads } = state;

    const hasPhotoErrors = photoUploads.filter(p => p.key !== photo.key).find(p => p.error);

    removePhotoUpload(photo.key);

    if (!hasPhotoErrors) {
      setError('');
    }
  };

  const uploadPhotos = ({ images = [], setError = () => {} }) => {
    const { onUpload, path } = state;

    const photosToUpload = images.map(photo => {
      return { ...photo, error: false, key: createFileKey(path), loading: true, percentage: 0 };
    });

    setPhotoUploads(photosToUpload);

    photosToUpload.forEach(async photo => {
      try {
        await handleFileUpload({ getUploadToken, file: photo.data, key: photo.key, handleProgress });

        const payload = {
          key: photo.key,
          title: '',
          value: photo.data,
        };

        onUpload(payload);
        removePhotoUpload(photo.key);
      } catch (e) {
        console.error(e);
        setError('There was an error uploading one or more photos. Please try again. You can still save your listing.');
        updatePhotoUpload({
          ...photo,
          error: true,
          loading: false,
        });
      }
    });
  };

  return (
    <PhotoGalleryContext.Provider value={{ ...state, editPhoto, removePhoto, uploadPhotos }}>
      {children}
    </PhotoGalleryContext.Provider>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getUploadToken: () => dispatch(getUploadToken()),
  };
};

export const PhotoGalleryContextProvider = connect(null, mapDispatchToProps)(PhotoGalleryProvider);
