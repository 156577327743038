import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';

import { TopNavLoggedOut, Button, Icon } from '../';
import config from '../../config';

class BreedHeader extends Component {
  static propTypes = {
    breedInformation: PropTypes.instanceOf(Map),
    buttonLeft: PropTypes.node,
    buttonRight: PropTypes.node,
    title: PropTypes.string,
    puppiesButtonText: PropTypes.string,
    puppiesButtonQuery: PropTypes.string,
    subTitle: PropTypes.string,
  };

  render() {
    const {
      breedInformation,
      buttonLeft,
      buttonRight,
      styles,
      title,
      puppiesButtonText,
      puppiesButtonQuery,
      subTitle,
    } = this.props;
    return (
      <div
        style={{
          backgroundImage: `url('${config.breedPhotoBucketURL}${
            breedInformation.get('photo') ? breedInformation.get('photo') : 'Placeholder.png'
          }')`,
          backgroundPosition: `${breedInformation.get('photo_position')}`,
        }}
        className={styles.breedHeaderWrapper}
      >
        <div
          className={classNames(
            styles.breedHeader,
            !breedInformation.get('photo') && styles.breedHeaderNoPhoto,
            styles.breedHeaderLoggedOut
          )}
        >
          <TopNavLoggedOut buttonLeft={buttonLeft} buttonRight={buttonRight} />

          <div className={styles.breedHeaderContent}>
            <h2 className={styles.breedHeaderTitle}>{title}</h2>
            {subTitle && <h1 className={styles.breedHeaderTitle + ' ' + styles.breedHeaderSubTitle}>{subTitle}</h1>}
            <h3 className={styles.breedGroup}>
              Breed Group: <strong>{breedInformation.getIn(['breedGroup', 'title'])}</strong>
            </h3>
            <p className={styles.breedHeadline}>"{breedInformation.get('headline')}"</p>
            {breedInformation.get('is_hypoallergenic') ? (
              <div className={styles.hypoallergenic}>
                <Icon icon="AddCircle" size={16} /> Hypoallergenic
              </div>
            ) : null}

            <div className={styles.viewPuppiesBtn}>
              <Link to={`/find-a-puppy?${puppiesButtonQuery}`}>
                <Button buttonType="largeRoundOrangeOutline">{puppiesButtonText}</Button>
              </Link>
            </div>

            {breedInformation.get('attribution')}
          </div>
        </div>
      </div>
    );
  }
}

const styles = props => ({
  breedHeaderWrapper: {
    minHeight: '50vw',
    maxHeight: '700px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    color: props.theme.colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    ':after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: 'rgba(138,109,91,0.5)',
      height: '100%',
      width: '100%',
    },
    [props.theme.breakpoints.tabletLarge]: {
      minHeight: 'unset',
      height: '45vw',
    },
  },
  breedHeader: {
    ...props.theme.globalPageWrapper,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    position: 'relative',
    zIndex: '1',
    [props.theme.breakpoints.tabletLarge]: {
      paddingLeft: '20px !important',
      paddingRight: '20px !important',
    },
  },
  breedHeaderNoPhoto: {
    color: props.theme.colors.brown,
    textShadow: 'none',
  },
  breedHeaderLoggedOut: {
    justifyContent: 'flex-start',
  },
  breedHeaderContent: {
    textShadow: '1px 1px 4px rgba(0,0,0,.5)',
    [props.theme.breakpoints.tabletLarge]: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  breedHeaderTitle: {
    marginTop: !props.user ? '50px' : null,
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltBold,
    fontStyle: 'italic',
    fontSize: '28px',
    wordBreak: 'break-word',
    [props.theme.breakpoints.mobile]: {
      fontSize: '45px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      marginTop: 0,
    },
  },
  breedHeaderSubTitle: {
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: `${props.theme.typography.sansAltLight} !important`,
    fontStyle: 'italic',
    fontSize: '28px',
    [props.theme.breakpoints.mobile]: {
      fontSize: '45px',
    },
  },
  breedGroup: {
    fontSize: '16px',
    marginTop: '20px',
    [props.theme.breakpoints.mobile]: {
      fontSize: '20px',
    },
  },
  breedHeadline: {
    fontSize: '16px',
    fontStyle: 'italic',
    marginTop: '6px',
    [props.theme.breakpoints.mobile]: {
      fontSize: '20px',
    },
  },
  viewPuppiesBtn: {
    textShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
    '> * + *': {
      fontStyle: 'italic',
      marginTop: '20px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      alignItems: 'center',
      flexDirection: 'row',
      '> * + *': {
        marginTop: 0,
        marginLeft: '20px',
      },
    },
  },
  relatedBreeds: {
    display: 'none',
    [props.theme.breakpoints.tabletLarge]: {
      textAlign: 'center',
      display: 'flex',
      maxWidth: props.theme.globalMaxWidth,
      margin: '0 auto 32px',
      ...props.theme.globalPadding,
    },
    '> * + *': {
      paddingLeft: '10px',
      borderLeft: '2px solid white',
    },
  },
  relatedBreed: {
    display: 'inline=block',
    marginLeft: '10px',
    '> a': {
      textDecoration: 'underline',
      fontWeight: props.theme.typography.sansBold,
      display: 'inline-block',
    },
  },
  traitsWrapper: {
    ...props.theme.globalPageWrapper,
    color: props.theme.colors.brown,
    lineHeight: '20px',
    fontSize: '14px',
  },
  traitsHeader: {
    '> h2': {
      ...props.theme.typography.titleText,
    },
    '> p': {
      margin: '20px 0',
    },
  },
  traits: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  trait: {
    marginTop: '30px',
    flexBasis: '45%',
    '> h3': {
      fontWeight: props.theme.typography.sansBold,
    },
    '> p': {
      marginTop: '2px',
    },
  },
  puppyGridWrapper: {
    marginBottom: '32px',
  },
  puppyGridButton: {
    textAlign: 'center',
  },
  hypoallergenic: {
    display: 'inline-flex',
    textShadow: 'none',
    width: 'fit-content',
    alignItems: 'center',
    backgroundColor: props.theme.colors.green,
    borderRadius: '40px',
    padding: '4px 12px 4px 4px',
    marginTop: '12px',
    fontSize: '12px',
    lineHeight: '17px',
    fontWeight: props.theme.typography.sansBold,
    '> span': {
      marginRight: '8px',
    },
  },
});

export default felaConnect(styles)(BreedHeader);
