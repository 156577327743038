import { createSelector } from 'reselect';
import humanizeDate from '../../../common/utils/humanizeDate';

export const activeProfileSelector = state => state.profile.activeProfile;
export const userSelector = state => state.user;

export const accountAgeSelector = createSelector(activeProfileSelector, profile => {
  if (profile) {
    const accountAge = humanizeDate(profile.get('created_at'), 'en-profiles');
    return accountAge;
  }
});

export const isUserSelector = createSelector(activeProfileSelector, userSelector, (user, profile) => {
  if (user && profile && user.get('id') === profile.get('id')) {
    return true;
  }
  return false;
});
