import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import classNames from 'classnames';

import { Label, InputError } from '../';

class InputScaffold extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    label: PropTypes.node,
    labelVariant: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    fixederror: PropTypes.bool,
    error: PropTypes.node,
    insetLabel: PropTypes.bool,
    required: PropTypes.bool,
    className: PropTypes.string,
  };

  render() {
    const {
      children,
      label,
      labelVariant,
      labelLinkRight,
      labelLinkRightClick,
      error,
      disabled,
      styles,
      fixederror,
      required,
      className,
      password,
    } = this.props;

    return (
      <div className={classNames([styles.inputScaffoldWrapper, className && className])}>
        <Label variant={labelVariant} disabled={disabled}>
          {label && (
            <div className={styles.label}>
              {required ? `${label}*` : label}
              {labelLinkRight && labelLinkRightClick ? (
                <span className={styles.clickable} onClick={labelLinkRightClick}>
                  {labelLinkRight}
                </span>
              ) : null}
              {labelLinkRight && !labelLinkRightClick ? <span>{labelLinkRight}</span> : null}
            </div>
          )}
          <div className={styles.children}>{children}</div>
        </Label>
        {error && (
          <InputError fixederror={fixederror} disabled={disabled} password={password}>
            {error}
          </InputError>
        )}
      </div>
    );
  }
}

const styles = props => ({
  inputScaffoldWrapper: {
    position: 'relative',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: props.insetLabel ? '8px 0 0 13px' : '0 0 6px',
    position: props.insetLabel ? 'absolute' : 'relative',
    fontSize: props.insetLabel ? '9px' : '12px',
    zIndex: props.insetLabel ? 1 : 0,
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  span: {
    display: 'flex',
    justifyContent: 'end',
  },
  clickable: {
    cursor: 'pointer',
  },
});

export default connect(styles)(InputScaffold);
