import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Thead extends Component {
  static propTypes = {
    styles: PropTypes.object,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;
    return <thead>{children}</thead>;
  }
}

export default Thead;
