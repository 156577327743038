import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { CircleAvatar } from '../../../../common/components';
import config from '../../../../common/config';
import { motion } from 'framer-motion';

const BreedBoxVariants = {
  visible: { opacity: 1, transition: { duration: 0.3 } },
  hidden: { opacity: 0 },
};

class BreedHover extends Component {
  state = { show: false };

  static propTypes = {
    breed: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (!this.props.isMobile) {
      document.addEventListener('mousedown', this.checkIfLink);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.checkIfLink);
  }

  checkIfLink = e => {
    if (this.ref.contains(e.target)) {
      return e.stopPropagation();
    }
    this.toggleShowOff();
  };

  setRef = node => {
    if (node) {
      this.ref = node;
    }
  };

  toggleShowOn = () => this.setState({ show: true });
  toggleShowOff = () => this.setState({ show: false });

  getBreedLink() {
    const { breed, styles } = this.props;
    return (
      <Link
        className={styles.breedName}
        to={{
          pathname: `/find-a-puppy/${breed.get('slug')}`,
          state: { prevLocation: this.props.location, backTo: 'Listing' },
        }}
      >
        {breed.get('name')}
      </Link>
    );
  }

  renderLink = () => {
    const { breed, styles, isMobile } = this.props;
    const { show } = this.state;
    if (isMobile) {
      return this.getBreedLink();
    }
    return (
      <div className={styles.breedHoverContainer}>
        <span className={styles.breedName}>
          <span className={styles.breedName} onMouseOver={this.toggleShowOn} onMouseLeave={this.toggleShowOff}>
            {this.getBreedLink()}
          </span>
        </span>
        <motion.div
          onMouseLeave={this.toggleShowOff}
          className={styles.breedHover}
          style={{ display: !isMobile ? (show ? null : 'none') : 'none' }}
          variants={BreedBoxVariants}
          animate={this.state.show ? 'visible' : 'hidden'}
        >
          <CircleAvatar
            size={100}
            variant="white"
            placeholderIcon="PuppyCircle"
            imageUrl={breed.get('photo') && encodeURI(config.breedPhotoBucketURL + 'thumbs/' + breed.get('photo'))}
          />
          <div onClick={this.checkIfLink} ref={this.setRef} className={styles.headline}>
            <div className={styles.headlineText}>{breed.get('headline')}</div>
          </div>
        </motion.div>
      </div>
    );
  };
  render() {
    return this.renderLink();
  }
}

const styles = {
  breedHoverContainer: props => ({
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      cursor: 'text',
    },
  }),
  breedName: props => ({
    fontWeight: props.theme.typography.sansBold,
    fontSize: '18px',
    color: props.theme.colors.blue,
    textDecoration: 'underline',
    transition: props.theme.globalTransition,
    ':hover': {
      color: props.theme.colors.darkerBlue,
    },
  }),
  breedHover: props => ({
    position: 'absolute',
    width: '382px',
    backgroundColor: props.theme.colors.blue,
    boxShadow: '0 3px 6px rgba(0,0,0,.3)',
    borderRadius: '4px',
    padding: '24px',
    color: props.theme.colors.white,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    top: '36px',
    left: '50%',
    transform: 'translateX(-50%)',
    '& > * + *': {
      marginLeft: '24px',
    },
    ':after': {
      bottom: '100%',
      left: '50%',
      border: 'solid transparent',
      content: '" "',
      height: '0',
      width: '0',
      position: 'absolute',
      pointerEvents: 'none',
      borderColor: 'transparent',
      borderBottomColor: `${props.theme.colors.blue} !important`,
      borderWidth: '14px',
      marginLeft: '-14px',
    },
  }),
  headline: props => ({
    '> a': {
      fontWeight: props.theme.typography.sansBold,
      textDecoration: 'underline',
    },
  }),
  headlineText: props => ({
    fontSize: '16px',
    fontStyle: 'italic',
    lineHeight: '1.25',
  }),
};

const mapStateToProps = state => ({
  isMobile: state.browser.lessThan.tablet,
});

export default compose(felaConnect(styles), connect(mapStateToProps))(BreedHover);
