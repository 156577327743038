import React, { Component } from 'react';
import classNames from 'classnames';

class Loading extends Component {
  render() {
    const { center, className } = this.props;

    return (
      <svg
        className={classNames('ld-container', className)}
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        style={{ background: 'none', margin: center && '50px auto', width: '100%' }}
        data-testid="loading-indicator"
      >
        <svg
          version="1.1"
          id="圖層_1"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          style={{ transformOrigin: '50px 50px 0px' }}
          xmlSpace="preserve"
        >
          <g style={{ transformOrigin: '50px 50px 0px' }}>
            <g style={{ transformOrigin: '50px 50px 0px', transform: 'scale(0.5)' }}>
              <g
                style={{
                  transformOrigin: '50px 50px 0px',
                  animationDuration: '1.3s',
                  animationDelay: '-1.3s',
                  animationDirection: 'normal',
                }}
              >
                <g>
                  <g
                    className="ld ld-fade"
                    style={{
                      transformOrigin: '50px 50px 0px',
                      animationDuration: '1.3s',
                      animationDelay: '-1.21333s',
                      animationDirection: 'normal',
                    }}
                  >
                    <path
                      className="st37"
                      d="M56.172,82.307c-4.115-1.131-8.23-1.131-12.345,0c-2.888,0.794-5.99,0.724-8.669-0.615 c-6.565-3.283-9.41-11.579-4.715-18.064l8.561-11.824c5.35-7.39,16.343-7.438,21.759-0.097l8.727,11.832 c4.78,6.479,1.958,14.836-4.629,18.144C62.179,83.03,59.068,83.103,56.172,82.307z"
                      fill="#1ab9c4"
                      stroke="#1ab9c4"
                      style={{ fill: 'rgb(26, 185, 196)', stroke: 'rgb(26, 185, 196)' }}
                    />
                  </g>
                  <g
                    className="ld ld-fade"
                    style={{
                      transformOrigin: '50px 50px 0px',
                      animationDuration: '1.3s',
                      animationDelay: '-1.12667s',
                      animationDirection: 'normal',
                    }}
                  >
                    <path
                      className="st37"
                      d="M46.696,28.451c0.892,7.819-2.119,12.705-8.015,13.378s-9.931-3.409-10.823-11.228 c-0.892-7.819,3.614-12.876,8.015-13.378S45.803,20.632,46.696,28.451z"
                      fill="#1ab9c4"
                      stroke="#1ab9c4"
                      style={{ fill: 'rgb(26, 185, 196)', stroke: 'rgb(26, 185, 196)' }}
                    />
                  </g>
                  <g
                    className="ld ld-fade"
                    style={{
                      transformOrigin: '50px 50px 0px',
                      animationDuration: '1.3s',
                      animationDelay: '-1.04s',
                      animationDirection: 'normal',
                    }}
                  >
                    <path
                      className="st37"
                      d="M26.288,44.147c4.044,5.721,3.785,10.824-0.529,13.874c-4.314,3.049-9.211,1.591-13.255-4.13 s-2.692-11.597,0.529-13.874S22.244,38.426,26.288,44.147z"
                      fill="#1ab9c4"
                      stroke="#1ab9c4"
                      style={{ fill: 'rgb(26, 185, 196)', stroke: 'rgb(26, 185, 196)' }}
                    />
                  </g>
                  <g
                    className="ld ld-fade"
                    style={{
                      transformOrigin: '50px 50px 0px',
                      animationDuration: '1.3s',
                      animationDelay: '-0.953333s',
                      animationDirection: 'normal',
                    }}
                  >
                    <path
                      className="st37"
                      d="M73.712,44.147c-4.044,5.721-3.785,10.824,0.529,13.874c4.314,3.049,9.211,1.591,13.255-4.13 s2.692-11.597-0.529-13.874S77.756,38.426,73.712,44.147z"
                      fill="#1ab9c4"
                      stroke="#1ab9c4"
                      style={{ fill: 'rgb(26, 185, 196)', stroke: 'rgb(26, 185, 196)' }}
                    />
                  </g>
                  <g
                    className="ld ld-fade"
                    style={{
                      transformOrigin: '50px 50px 0px',
                      animationDuration: '1.3s',
                      animationDelay: '-0.866667s',
                      animationDirection: 'normal',
                    }}
                  >
                    <path
                      className="st37"
                      d="M53.304,28.451c-0.892,7.819,2.119,12.705,8.015,13.378s9.931-3.409,10.823-11.228s-3.614-12.876-8.015-13.378 C59.726,16.721,54.197,20.632,53.304,28.451z"
                      fill="#1ab9c4"
                      stroke="#1ab9c4"
                      style={{ fill: 'rgb(26, 185, 196)', stroke: 'rgb(26, 185, 196)' }}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </svg>
    );
  }
}

export default Loading;
