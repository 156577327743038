import React from 'react';

function SpeechBubble() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M24 10.75c0 5.39-5.355 9.75-12 9.75-.691 0-1.395-.059-2.027-.14-1.254 1.253-3.586 3.14-6.47 3.14-.198 0-.398-.105-.48-.305-.046-.199-.023-.422.118-.55.023-.036 1.464-1.5 1.804-4.008C1.945 16.855 0 13.997 0 10.75 0 5.36 5.355 1 12 1s12 4.36 12 9.75z" />
    </svg>
  );
  /* eslint-enable */
}

export default SpeechBubble;
