import React from 'react';
import classNames from 'classnames';
import { CircleAvatar, Icon, ListingStatusForm, ContentCapitalized } from '../../../../../common/components';
import generateInitials from '../../../../../common/utils/generateInitials';
import humanizeDate from '../../../../../common/utils/humanizeDate';
import ConversationHeaderTooltipLink from '../ConversationHeaderTooltipLink';
import ConversationHeaderDisabledLink from '../ConversationHeaderDisabledLink';
import formatPuppyBiology from '../../../../../common/utils/formatPuppyBiology';

const Header = ({
  isArchive,
  styles,
  listingGroup,
  currentUser,
  isMobile,
  goBack,
  userVM,
  otherUser,
  listing,
  location,
}) => {
  if (listing) {
    const showListingStatusForm =
      !isArchive && listing.get('seller') === currentUser.get('id') && userVM.get('isActive');
    return (
      <div
        className={classNames([
          styles.conversationDisplayHeaderContainer,
          isMobile && styles.mobileConversationDisplayHeaderContainer,
        ])}
      >
        <div className={styles.conversationDisplayHeaderWrapper}>
          {isMobile ? (
            <button onClick={() => goBack()} className={styles.goBackButton}>
              <Icon icon="Caret" rotate={180} size={isMobile ? 16 : 24} />
            </button>
          ) : null}
          <div className={styles.conversationDisplayHeader}>
            <div className={styles.conversationInfo}>
              {isMobile ? null : (
                <div className={styles.avatars}>
                  <CircleAvatar
                    size={50}
                    photo={otherUser ? otherUser.get('photo') : null}
                    initials={
                      otherUser ? generateInitials(otherUser.get('last_initial'), otherUser.get('first_name')) : 'AD'
                    }
                    alt={'user-avatar'}
                    verified={otherUser ? otherUser.get('id_verified') : null}
                  />
                  <CircleAvatar
                    size={50}
                    photo={listing.getIn(['photos', 0])}
                    placeholderIcon={'PuppyCircle'}
                    alt={'puppy-photo'}
                  />
                </div>
              )}
              <div className={styles.conversation}>
                {isMobile ? null : 'Conversation with '}
                {!otherUser || !otherUser.get('isMembershipActive') ? (
                  <strong>
                    <ContentCapitalized>
                      {otherUser
                        ? `${otherUser.get('first_name')} ${otherUser.get('last_initial')}`
                        : '[Account Deleted]'}
                    </ContentCapitalized>
                  </strong>
                ) : (
                  <ConversationHeaderTooltipLink
                    name={`${otherUser.get('first_name')} ${otherUser.get('last_initial')}`}
                    location={location}
                    linkTo={`/profile/${otherUser.get('id')}`}
                  >
                    <h3>
                      <ContentCapitalized>
                        {otherUser.get('first_name')} {otherUser.get('last_initial')}
                      </ContentCapitalized>
                    </h3>
                    <p>A member for {humanizeDate(otherUser.get('created_at'))}</p>
                  </ConversationHeaderTooltipLink>
                )}
                {otherUser.get('id_verified') ? (
                  <span className={styles.idVerified}>ID Verified</span>
                ) : (
                  <span className={styles.idNotVerified}>ID Not Verified</span>
                )}
                <br />
                about{' '}
                {listing.get('deleted_at') ? (
                  <ConversationHeaderDisabledLink name={listing.get('name')}>
                    <p>This listing has been removed</p>
                  </ConversationHeaderDisabledLink>
                ) : (
                  <ConversationHeaderTooltipLink
                    name={listing.get('name')}
                    location={location}
                    linkTo={`/listings/${listing.get('id')}`}
                    CircleAvatarProps={{
                      photo: listing.getIn(['photos', 0]),
                      placeholderIcon: listing ? 'PuppyCircle' : null,
                    }}
                  >
                    <h3>{listing.get('name')}</h3>
                    <p>{formatPuppyBiology(listing.get('sex'), listing.get('birthdate'))}</p>
                  </ConversationHeaderTooltipLink>
                )}
                <span className={styles.price}>${listing.get('price').toLocaleString()}</span>
              </div>
            </div>
            {!isMobile && showListingStatusForm && listingGroup ? (
              <div className={styles.listingStatusFormWrapper}>
                <ListingStatusForm
                  listing={listing}
                  type="button"
                  conversations={listingGroup.get('conversations')}
                  userVM={userVM}
                />
              </div>
            ) : null}
          </div>
          {isMobile && !showListingStatusForm ? <div style={{ width: '24px' }} /> : null}
          {isMobile && showListingStatusForm && listingGroup ? (
            <ListingStatusForm listing={listing} conversations={listingGroup.get('conversations')} userVM={userVM} />
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames([
        styles.conversationDisplayHeaderContainer,
        isMobile && styles.mobileConversationDisplayHeaderContainer,
      ])}
    >
      <div className={styles.conversationDisplayHeaderWrapper}>
        {isMobile ? (
          <button onClick={() => goBack()} className={styles.goBackButton}>
            <Icon icon="Caret" rotate={180} />
          </button>
        ) : null}
        <div className={styles.conversationDisplayHeader}>
          <div className={styles.conversationInfo}>
            {isMobile ? null : (
              <div className={styles.avatars}>
                <CircleAvatar
                  size={50}
                  photo={otherUser ? otherUser.get('photo') : null}
                  initials={
                    otherUser ? generateInitials(otherUser.get('last_initial'), otherUser.get('first_name')) : 'AD'
                  }
                  alt={'user-avatar'}
                  verified={otherUser ? otherUser.get('id_verified') : null}
                />
              </div>
            )}
            <div className={styles.conversation}>
              Conversation with&nbsp;
              {!otherUser || !otherUser.get('isMembershipActive') ? (
                <strong>
                  <ContentCapitalized>
                    {otherUser
                      ? `${otherUser.get('first_name')} ${otherUser.get('last_initial')}`
                      : '[Account Deleted]'}
                  </ContentCapitalized>
                </strong>
              ) : (
                <ConversationHeaderTooltipLink
                  name={`${otherUser.get('first_name')} ${otherUser.get('last_initial')}`}
                  location={location}
                  linkTo={`/profile/${otherUser.get('id')}`}
                  CircleAvatarProps={{
                    photo: otherUser.get('photo'),
                    initials: generateInitials(otherUser.get('last_initial'), otherUser.get('first_name')),
                    verified: otherUser.get('id_verified'),
                  }}
                >
                  <h3>
                    <ContentCapitalized>
                      {otherUser.get('first_name')} {otherUser.get('last_initial')}
                    </ContentCapitalized>
                  </h3>
                  <p>A member for {humanizeDate(otherUser.get('created_at'))}</p>
                </ConversationHeaderTooltipLink>
              )}
            </div>
          </div>
        </div>
        {isMobile && <div style={{ width: '24px' }} />}
      </div>
    </div>
  );
};

export default Header;
