import AutoLogin from './AutoLogin';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { autoLogin } from '../../redux/actions';

const mapStateToProps = state => {
  return {
    token: state.token,
    prevLocation: state.prevLocation,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ autoLogin }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AutoLogin);
