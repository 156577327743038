import hasTouch from '../../../../common/utils/hasTouch';
import theme from '../../../../theme';

const styles = props => ({
  container: {
    width: '100%',
    backgroundColor: props.theme.colors.white,
    color: props.theme.colors.brown,
  },
  carousel: {
    overflow: 'hidden',
    width: `${props.size}px !important`,
    height: `${props.size}px !important`,
    backgroundColor: props.theme.colors.blue,
    border: `4px solid ${props.theme.colors.blue}`,
  },
  listingCarousel: {
    overflow: 'hidden',
  },
  carouselImage: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  listingCarouselImage: {
    width: '100%',
    height: '320px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  listingWrapper: {
    width: '100%',
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
  },
  profileWrapper: {
    [props.theme.breakpoints.mobile]: {
      ...props.theme.globalPadding,
    },
  },
  listingTop: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '20px',
    padding: 0,

    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      padding: '40px 20px 0',
      marginBottom: '24px',
    },
  },
  listingBottom: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    ...props.theme.globalPadding,
    '& > * + *': {
      marginLeft: '24px',
    },
    '@media (max-width: 864px)': {
      padding: 0,
      flexDirection: 'column',
      '& > * + *': {
        marginLeft: '0',
      },
    },
  },
  aboutMe: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 20px 10px',
    '> h2': {
      fontSize: '28px',
      color: props.theme.colors.orange,
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansAltLight,
    },
    '> p': {
      fontSize: '14px',
      lineHeight: '23px',
      marginTop: '10px',
      marginBottom: '20px',
      color: props.theme.colors.brown,
      wordBreak: 'break-word',
      whiteSpace: 'pre-line',
    },
    [props.theme.breakpoints.tablet]: {
      padding: '24px 0 10px',
    },
  },
  puppyDetails: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  puppyDetailsTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    padding: '24px 0',
    borderBottom: '1px solid #E2E2E2',
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0 0 24px',
    },
  },
  puppyDetailsText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: '0',
    width: '100%',
    minWidth: 0,
    [props.theme.breakpoints.tablet]: {
      alignItems: 'center',
      marginTop: 0,
    },
  },
  noPhotoPlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '20em',
    marginBottom: '24px',
    backgroundColor: props.theme.colors.darkerTan,
    borderRadius: '4px',
    [props.theme.breakpoints.tablet]: {
      height: '298px',
    },
    '> span': {
      marginBottom: '16px',
    },
    '> p': {
      color: props.theme.colors.brown,
    },
  },
  galleryContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginBottom: 0,
    '> div': {
      ':hover': {
        cursor: 'pointer',
      },
    },
    [props.theme.breakpoints.tablet]: {
      maxHeight: '300px',
      flexDirection: 'row',
      marginBottom: '24px',
    },
  },
  primaryPhoto: {
    display: 'flex',
    flex: 3,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '4px',
    marginBottom: 0,
    backgroundColor: props.theme.colors.disabled,
    height: '300px',
    maxHeight: '100%',
    '> img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '4px',
    },
  },
  secondaryPhotosContainer: {
    display: 'none',
    [props.theme.breakpoints.tablet]: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      maxHeight: 'none',
    },
  },
  secondaryPhoto: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    borderRadius: '4px',
    maxHeight: '120px',
    backgroundColor: props.theme.colors.disabled,
    [props.theme.breakpoints.tablet]: {
      maxHeight: 'unset',
    },
    '> img': {
      width: '100%',
      height: 'auto',
      minHeight: '100%',
      minWidth: '100%',
      objectFit: 'cover',
    },
    ':nth-of-type(2)': {
      marginLeft: '16px',
      [props.theme.breakpoints.tablet]: {
        marginTop: '16px',
        marginLeft: 0,
      },
    },
  },
  secondaryPhotoOverlay: {
    ':before': {
      content: '" "',
      width: '100%',
      height: '100%',
      color: props.theme.colors.white,
      background: props.theme.colors.darkerBlue,
      position: 'absolute',
      opacity: '0.33',
    },
  },
  photosRemainingOverlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(33,33,33,0.3)',
    fontSize: '36px',
    fontFamily: props.theme.typography.sans,
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.white,
  },
  priceBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: props.theme.colors.yellow,
    padding: '31px 24px',

    [props.theme.breakpoints.mobile]: {
      borderRadius: '4px 4px 0 0',
    },
  },
  priceBoxInner: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '@media (max-width: 575px)': {
      justifyContent: 'space-around',
      '> *': {
        width: '50%',
        textAlign: 'center',
      },
    },
    '@media (max-width: 375px)': {
      '> *': {
        width: 'auto',
      },
    },
  },
  priceBoxContainer: {
    alignSelf: 'center',
    flex: 1,
  },
  priceBoxOnHold: {
    backgroundColor: '#EF7373',
    color: props.theme.colors.white,
  },
  priceBoxSold: {
    backgroundColor: '#7ED321',
    color: props.theme.colors.white,
  },
  contactBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contactBoxInner: {
    backgroundColor: props.theme.colors.tan,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '30px 24px',
    borderRadius: '0 0 4px 4px',
  },
  contactBoxUser: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '16px',
  },
  offeredByLabel: {
    fontFamily: props.theme.typography.sans,
    fontSize: '14px',
    fontWeight: props.theme.typography.sansRegular,
  },
  contactBoxName: {
    width: '100%',
    fontFamily: props.theme.typography.sans,
    fontWeight: props.theme.typography.sansBold,
    fontSize: '14px',
    marginLeft: '8px',
    textAlign: 'left',
  },
  contactActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1em',
    '> button': {
      flex: 1,
      width: '100%',
    },
    '> * + *': {
      marginLeft: '0',
    },
  },
  listingStatusWrapper: {
    position: 'relative',
  },
  listingStatus: {
    color: props.theme.colors.white,
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltBold,
    fontSize: '35px',
    letterSpacing: '0.75px',
    lineHeight: '42px',
    textAlign: 'center',
  },
  listingStatusSubText: {
    textAlign: 'center',
    fontSize: '13px',
    marginTop: '6px',
    color: props.theme.colors.white,
  },
  verticalRule: {
    width: '1px !important',
    height: '95px',
    backgroundColor: props.theme.colors.white,
    margin: '0 24px',
    flexShrink: 0,
  },
  listingPrice: {
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltBold,
    fontSize: '35px',
    textAlign: 'center',
    '@media (max-width: 400px)': {
      fontSize: '28px',
    },
  },
  buyerTipsUrl: {
    marginTop: '3px',
    '> span': {
      fontSize: '12px',
    },
  },
  priceBoxContactSection: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '50%',
    '& > * + *': {
      marginTop: '11px',
    },
  },
  priceBoxAvailability: {
    display: 'flex',
    flex: 0.66,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  availabilityContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  listingRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    [props.theme.breakpoints.tablet]: {
      width: '350px',
      maxWidth: '350px',
      marginLeft: '24px',
    },
  },
  listingContactBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '24px',

    [props.theme.breakpoints.tablet]: {
      maxWidth: '350px',
      marginTop: 0,
    },
  },
  shippingInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontSize: '12px',
    textAlign: 'left',
    lineHeight: '1.25',

    '> div': {
      marginTop: '3px',
    },
  },
  priceBoxUser: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: '8px',
    },
  },
  priceBoxUserDetails: {
    minWidth: 0,
    overflow: 'hidden',
    fontSize: '11px',
    '& > * + *': {
      marginTop: '4px',
    },
  },
  priceBoxName: {
    overflow: 'hidden',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: props.theme.typography.sansBold,
  },
  locationLink: {
    color: props.theme.colors.blue,
    textDecoration: 'underline',
  },
  pupInfoWrapper: {
    minWidth: 0,
    color: props.theme.colors.navyBlue,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px 24px',
    borderBottom: 'none',
    '& > * + *': {
      marginTop: '4px',
    },
    [props.theme.breakpoints.tablet]: {
      padding: 0,
    },
  },
  pupNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '8px',
    whiteSpace: 'nowrap',
    minWidth: 0,
    maxWidth: '100%',
    '> * + *': {
      flexShrink: 0,
    },
  },
  pupName: {
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansBold,
    fontSize: '35px',
    color: props.theme.colors.orange,
    lineHeight: '47px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '470px',
    minWidth: 0,
    display: 'flex',
    alignItems: 'center',
  },
  breedTypesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    lineHeight: 1.3,
    marginBottom: '4px',
    marginTop: '8px',
  },
  whyImSpecial: {
    display: 'flex',
    minWidth: '75%',
    flexDirection: 'column',
    padding: '0 24px',
    marginTop: '32px',
    '> h2': {
      fontSize: '28px',
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansAltLight,
      color: props.theme.colors.orange,
    },
    [props.theme.breakpoints.tablet]: {
      padding: '0 20px',
    },
  },
  specialList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: '16px',
    [props.theme.breakpoints.mobile]: {
      marginTop: '8px',
      borderTop: '1px solid #E2E2E2',
      flexWrap: 'wrap',
    },
    [props.theme.breakpoints.tablet]: {
      maxHeight: 'inherit',
      flexWrap: 'nowrap',
    },
    '&:first-of-type': {
      borderTop: 'none',
    },
  },
  attribute: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: '12px 0',
    fontWeight: props.theme.typography.sansBold,
    fontSize: '14px',
    '& > * + *': {
      marginLeft: '8px',
    },
    [props.theme.breakpoints.tablet]: {
      width: '100%',
      borderBottom: '1px solid #E2E2E2',
      padding: '20px 0',
    },
  },
  sellerProfileWrapper: {
    [props.theme.breakpoints.mobile]: {
      ...props.theme.globalPadding,
    },
  },
  sellerProfile: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: props.theme.colors.tan,
    padding: '40px 24px',
    marginTop: '120px',

    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      padding: '40px 48px',
      marginTop: 0,
    },
  },
  sellerDetailsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '& > * + *': {
      marginLeft: '24px',
    },
    '@media (max-width: 864px)': {
      marginBottom: '24px',
    },
    '@media (max-width: 575px)': {
      flexDirection: 'column',
      '& > * + *': {
        marginLeft: '0',
      },
    },
  },
  sellerProfileAvatar: {
    position: 'absolute',
    top: '-90px',
    marginRight: 0,

    '> span': {
      backgroundColor: props.theme.colors.white,
    },
    [props.theme.breakpoints.mobileLarge]: {
      top: '-45px',
    },
    [props.theme.breakpoints.tablet]: {
      position: 'relative',
      top: '0 !important',
      marginRight: '24px',
    },
  },
  sellerDetails: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '75px',
    '> h1': {
      justifyContent: 'center',
      color: props.theme.colors.orange,
      fontSize: '28px',
      lineHeight: '42px',
      marginBottom: '10px',
    },
    [props.theme.breakpoints.mobileLarge]: {
      marginTop: '24px',
    },
    [props.theme.breakpoints.tablet]: {
      alignItems: 'flex-start',
      marginTop: '0 !important',
    },
  },
  sellerDetailsTitle: {
    justifyContent: 'center',
    color: props.theme.colors.orange,
    fontSize: '28px',
    lineHeight: '42px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '> strong': {
      marginLeft: '8px',
    },
  },
  sellerDetailsVideoChat: {
    flexBasis: '100%',
    marginLeft: '8px',

    [props.theme.breakpoints.mobile]: {
      justifyContent: 'flex-start',
      flexBasis: 'inherit',
      fontSize: '12px',
      '> * + *': {
        marginLeft: '4px',
      },
    },
  },
  sellerProfileButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > * + *': {
      marginTop: '16px',
    },
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      '& > * + *': {
        marginTop: '0',
        marginLeft: '16px',
      },
    },
  },
  favoriteIcon: {
    position: 'absolute',
    top: '14px',
    right: '14px',
    width: '32px',
    height: '32px',
    display: 'inline-flex',
    cursor: 'pointer',
    '> svg': {
      height: '100%',
      width: '100%',
    },
    [props.theme.breakpoints.mobile]: {
      position: 'static',
    },
    ':hover': {
      '> svg > path': {
        fill:
          !hasTouch() && !props.listing.get('favorite')
            ? 'rgba(250, 97, 37, .2)'
            : !hasTouch() && !!props.listing.get('favorite')
            ? 'rgba(250, 97, 37, .6)'
            : null,
      },
    },
  },
  premierProfileWrapper: {
    [props.theme.breakpoints.mobile]: {
      ...props.theme.globalPadding,
    },
  },
  premierProfile: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '180px',
    backgroundColor: props.theme.colors.tan,
    padding: '120px 24px 40px',
    [props.theme.breakpoints.mobile]: {
      borderRadius: '6px',
      marginTop: '120px',
      padding: '80px 24px 40px',
    },
  },
  premierAvatar: {
    position: 'absolute',
    top: '-90px',
    [props.theme.breakpoints.mobile]: {
      top: '-75px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  offeredBy: {
    textAlign: 'center',
    color: props.theme.colors.orange,
    lineHeight: '34px',
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansLight,
    fontSize: '18px',
    maxWidth: '75%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '>strong': {
      fontFamily: props.theme.typography.sans,
      fontWeight: props.theme.typography.sansBold,
      fontSize: '28px',
    },
    [props.theme.breakpoints.mobile]: {
      fontSize: '28px',
      '>strong': {
        fontSize: '32px',
      },
    },
  },
  offeredByText: {
    display: 'inline-flex',
    marginBottom: '4px',
    marginRight: '6px',
  },
  videoChatCheckContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '8px',
  },
  accountAge: {
    display: 'inline-block',
    padding: '6px 18px',
    backgroundColor: '#7ED321',
    fontSize: '14px',
    color: props.theme.colors.white,
    borderRadius: '100px',
    textAlign: 'center',
    zIndex: 1,
  },
  accountMetaContainer: {
    display: 'flex',
    flexDirection: 'row',

    '@media (max-width: 575px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '> button': {
        marginTop: '16px',
        marginLeft: 0,
      },
      '> span': {
        marginLeft: 0,
        marginTop: '10px',
      },
    },

    '>button': {
      marginLeft: '20px',
    },
  },
  verificationTags: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '30px 30px 0',

    [props.theme.breakpoints.mobile]: {
      padding: '30px 24px 0',
      borderBottom: 'none',
      '> * + *': {
        marginLeft: '20px',
      },
    },
  },
  sellerVerificationTags: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    [props.theme.breakpoints.mobile]: {
      '> * + *': {
        marginLeft: '20px',
      },
    },
  },
  verificationTag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '100%',
    color: '#4B4E52',
    marginBottom: '20px',
    '> * + *': {
      marginLeft: '16px',
    },
    [props.theme.breakpoints.mobile]: {
      justifyContent: 'flex-start',
      flexBasis: 'inherit',
      marginBottom: '8px',
      fontSize: '12px',
      '> * + *': {
        marginLeft: '4px',
      },
    },
  },
  premierContact: {
    position: 'relative',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '> * + *': {
      marginTop: '50px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      '> * + *': {
        marginTop: 0,
        marginLeft: '40px',
      },
      ':before': {
        height: '1px',
        width: '100%',
      },
    },
  },
  licenseContainer: {
    marginTop: '20px',
  },
  lightBoxModal: {
    width: '100%',
  },
  openLinkIcon: {
    marginLeft: '6px',
    bottom: '-2px',
  },
  premierWebsite: {
    fontSize: '18px',
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    width: '100%',
    '> span': {
      display: 'flex',
      flex: '1 1 0',
      justifyContent: 'center',
      color: props.theme.colors.blue,
      fontWeight: 'bold',
    },
    '> a': {
      display: 'flex',
      flex: '1 1 0',
    },
  },
  premierSiteUrl: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },
  profilesWrapper: {
    marginBottom: '20px',
  },
  sellerVerificationButton: {
    marginTop: '6px',
  },
  verificationButton: {
    marginTop: '12px',
  },
  facebookAndViews: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...props.theme.globalPadding,
    marginTop: '20px',
    marginBottom: '20px',
    [props.theme.breakpoints.mobileLarge]: {
      flexDirection: 'row',
    },
    '> div': {
      margin: '8px 0',
      '> iframe': {
        flexShrink: 0,
      },
      '> p': {
        textAlign: 'center',
        margin: '0 10px',
        fontSize: '12px',
        fontWeight: props.theme.typography.sansBold,
        '> i': {
          color: props.theme.colors.orange,
        },
        '> span > i': {
          color: props.theme.colors.orange,
        },
      },
    },
  },
  profileFooterLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subnavChildren: {
    paddingBottom: '10px',
    textAlign: 'center',
    [props.theme.breakpoints.mobileLarge]: {
      paddingBottom: 0,
      textAlign: 'left',
    },
  },
  testimonialLink: {
    textDecoration: 'underline',
    color: theme.colors.blue,
    whiteSpace: 'no-wrap',
  },
  midAdContainer: {
    margin: '2em auto !important',
  },
  standOutTip: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    width: 'fit-content',
    position: 'relative',
    color: props.theme.colors.blue,
  },
  standOutHover: {
    display: 'flex',

    ':hover': {
      cursor: 'pointer',
    },
  },
  standOutTooltip: {
    position: 'relative',
    left: '25%',
    width: '400px !important',
  },
  standOutTipContainer: {
    position: 'absolute',
    left: '50%',
    wordBreak: 'break-word',
    top: '5px',
    [props.theme.breakpoints.mobile]: {
      left: '25%',
    },
  },
  standOutTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  standOutTitle: {
    display: 'flex',
    width: 'fit-content',
    marginRight: '20px',
    fontWeight: '300',
    color: props.theme.colors.orange,
    fontSize: '28px',
  },
  buyerTipsLink: {
    margin: 'auto',
    textAlign: 'center',
    display: 'block',
    backgroundColor: props.theme.colors.blue,
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltBlack,
    padding: '10px 24px',
    hoverBackgroundColor: props.theme.colors.orangeHover,
    color: props.theme.colors.white,
    hoverColor: props.theme.colors.white,
    borderColor: 'transparent',
    borderRadius: '40px',
    boxShadow: props.theme.globalBoxShadow,
    fontSize: '12px',
    letterSpacing: '1px',
    whiteSpace: 'nowrap',
    ':hover': {
      opacity: 0.8,
    },
  },
});

export default styles;
