import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import theme from '../../../theme';

class NotificationBadge extends Component {
  static propTypes = {
    color: PropTypes.string,
    content: PropTypes.node,
  };

  static defaultProps = {
    color: theme.colors.blue,
    content: '',
  };

  render() {
    const { content, styles } = this.props;
    return <div className={styles.root}>{content || <span>&nbsp;</span>}</div>;
  }
}

const styles = props => ({
  root: {
    backgroundColor: props.color,
    color: 'white',
    height: '16px',
    minWidth: '16px',
    borderRadius: '8px',
    padding: '0 4px',
    fontSize: '11px',
    fontWeight: props.theme.typography.sansBold,
    lineHeight: '16px',
    textAlign: 'center',
  },
});

export default felaConnect(styles)(NotificationBadge);
