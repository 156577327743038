export function getSortOptions(withLocation) {
  const sortOptions = [
    {
      value: 'default',
      label: 'Default Sorting',
      query: {
        sort_by: 'default',
        sort_dir: 'desc',
      },
    },
    {
      value: 'newest',
      label: 'Newest',
      query: {
        sort_by: 'created_at',
        sort_dir: 'desc',
      },
    },
    {
      value: 'youngest',
      label: 'Youngest',
      query: {
        sort_by: 'birthdate',
        sort_dir: 'desc',
      },
    },
    {
      value: 'most_popular',
      label: 'Top Dog',
      query: {
        sort_by: 'views',
        sort_dir: 'desc',
      },
    },
    {
      value: 'least_popular',
      label: 'Underdog',
      query: {
        sort_by: 'views',
        sort_dir: 'asc',
      },
    },
    {
      value: 'verified',
      label: 'ID Verified First',
      query: {
        sort_by: 'verified',
        sort_dir: 'desc',
      },
    },
  ];

  if (withLocation) {
    sortOptions.unshift({
      value: 'closest',
      label: 'Closest',
      query: {
        sort_by: 'location',
        sort_dir: 'asc',
      },
    });
  }

  return sortOptions;
}
