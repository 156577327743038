import React from 'react';

function Puppy() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M5.27 20.174c-.021-.701.131-1.483.245-2.197.45-2.831 1.745-5.34 3.686-6.814-.948-.51-1.638-1.53-2.131-3.024-.955-2.898.124-5.818 3.014-6.776 2.652-.88 4.703-.167 5.761 2.195.154-.023.316-.04.49-.049a.737.737 0 1 1 1.378.52c.953 1.08 1.757 3.712-1.733 6.358 1.818 1.086 2.198 3.652 3.968 7.82 1.515 3.564 2.286 4.208.703 4.229-1.208.016-3.548-4.993-3.548-4.993.177 3.19-2.274 3.506-2.274 3.506 3.83-.043 3.216 1.673 3.216 1.673-1.517.274-3.167.409-4.89.372-1.926-.04-4.451-.35-5.921-.7-1.74.094-5.27-.175-5.234-3.932 0 0 1.621 2.058 3.27 1.812" />
    </svg>
  );
  /* eslint-enable */
}

export default Puppy;
