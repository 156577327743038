import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import config from '../../config';
import { Map } from 'immutable';

import { PlainLink } from '../../../common/components';

class BreedLayoutImage extends Component {
  static propTypes = {
    breed: PropTypes.instanceOf(Map).isRequired,
  };

  state = {
    imageLoaded: false,
  };

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.breed !== this.props.breed) {
      this.loadImage();
    }
  }

  componentWillUnmount() {
    this._image.onload = null;
  }

  getImageURL() {
    const breed = this.props.breed;
    return config.breedPhotoBucketURL + escape(breed.get('photo'));
  }

  loadImage() {
    const imageURL = this.getImageURL();
    this.setState({ imageLoaded: false });
    this._image = new Image();
    this._image.onload = () => this.setState({ imageLoaded: true });
    this._image.src = imageURL;
  }

  render() {
    const { styles, breed } = this.props;

    if (!this.state.imageLoaded) {
      return <div className={styles.root} />;
    }

    return (
      <div className={styles.root} style={{ backgroundImage: `url(${this.getImageURL()})` }}>
        <div className={styles.breedInfo}>
          <div className={styles.breed}>
            <PlainLink noUnderline to={`/breeds/${breed.get('slug')}`} key={breed.get('id')} target="_blank">
              {breed.get('name')}
            </PlainLink>
          </div>
          <div className={styles.breedGroup}>
            Breed Group: <strong>{breed.getIn(['breedGroup', 'title'])}</strong>
          </div>
          <div className={styles.description}>"{breed.get('headline')}"</div>
        </div>
      </div>
    );
  }
}

const styles = props => ({
  root: {
    height: '100%',
    flexBasis: '60%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: '80px',
    position: 'relative',
    ':after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: 'rgba(138,109,91,.5)',
      height: '100%',
      width: '100%',
    },
  },
  breedInfo: {
    position: 'relative',
    zIndex: 1,
    maxWidth: '400px',
    color: props.theme.colors.white,
    fontSize: '20px',
    textShadow: '1px 1px 4px rgba(0,0,0,.5)',
  },
  breed: {
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansBold,
    fontStyle: 'italic',
    fontSize: '45px',
  },
  breedGroup: {
    marginTop: '16px',
  },
  description: {
    marginTop: '8px',
    fontStyle: 'italic',
  },
});

export default felaConnect(styles)(BreedLayoutImage);
