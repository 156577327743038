import React, { Component } from 'react';
import { Modal, ModalBody, ButtonPlain, PlainLink, ModalHeader } from '../../common/components';
import LoginForm from '../Login/LoginForm';

import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';
import { bindActionCreators } from 'redux';
import { isLoggedInSelector } from '../../redux/selectors';
import { login, setErrorBarMessage, clearErrorBar } from '../../redux/actions';
import { setActiveModal } from '../Modal/redux/actions';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';

class SignInModal extends Component {
  state = {
    error: null,
  };

  renderError() {
    const { styles } = this.props;
    if (!this.state.error) {
      return null;
    }

    return <div className={styles.error}>{this.state.error}</div>;
  }

  render() {
    const { setActiveModal, login, showModal, styles, location } = this.props;

    return (
      <Modal
        isOpen={showModal}
        onClose={() => {
          setActiveModal(null);
        }}
        closeOnOutsideClick
        closeOnEscape
      >
        <ModalHeader title="Sign in to your account." subTitle="Welcome back." />
        <ModalBody>
          {this.renderError()}
          <div className={styles.loginForm}>
            <LoginForm
              setError={error =>
                this.setState({
                  error,
                })
              }
              clearError={() => this.setState({ error: null })}
              submitAction={login}
            />
          </div>
          <div className={styles.formFooter}>
            <div className={styles.formFooterLink}>
              <span>Need an account?</span>
              <ButtonPlain
                onClick={() => {
                  location.prevLocation = location;
                  setActiveModal('signup');
                }}
              >
                Sign up now for free
              </ButtonPlain>
            </div>
            <div className={styles.formFooterLink}>
              <span>Forgot your password?</span>
              <PlainLink variant="blue" to="/forgot-password">
                Reset it here
              </PlainLink>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedInSelector(state),
    showModal: state.modal.activeModal === 'signin',
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setActiveModal, login, setErrorBarMessage, clearErrorBar }, dispatch);

const styles = props => ({
  header: {
    backgroundColor: props.theme.colors.blue,
    padding: '20px',
    color: props.theme.colors.white,
    fontSize: '14px',
    '> h1': {
      fontSize: '30px',
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansAltBold,
      lineHeight: '37px',
    },
  },
  footer: {
    width: '100%',
    borderTop: props.theme.globalDashedBorder,
    padding: '25px',
    lineHeight: '19px',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    '> p': {
      maxWidth: '340px',
    },
  },
  loginForm: {
    marginTop: '24px',
  },
  formFooterLink: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  error: {
    marginTop: '20px',
    color: props.theme.colors.red,
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(felaConnect(styles)(SignInModal));
