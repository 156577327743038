import React from 'react';
import { Link } from 'react-router-dom';

const ApprovalNecessary = ({ styles }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.approvalContainer}>
        <h1 className={styles.title}>Account approval pending</h1>
        <p style={{ marginTop: '10px' }}>
          Once you{' '}
          <Link to="/listings/create" className={styles.blueLink}>
            create a listing
          </Link>
          , your account will be reviewed by our staff. We'll let you know when it's approved, and your 3-day free trial
          will begin.
        </p>
      </div>
    </div>
  );
};

export default ApprovalNecessary;
