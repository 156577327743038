import React from 'react';
import { SubNav, TabGroup } from '../../../../common/components';

export default function PremierProfileHeader({ hasProfile }) {
  if (!hasProfile) {
    return <SubNav titleText="Please Complete Your Premier Profile." />;
  }

  return (
    <SubNav
      titleText="Edit Profile"
      headerRightChildren={
        <TabGroup
          linkArray={[
            { route: '/account/my-profile', text: 'Basic Profile' },
            { route: '/account/premier-profile', text: 'Premier Profile' },
          ]}
        />
      }
    />
  );
}
