export function getLoadingReducer(prefix) {
  return (state = null, action) => {
    switch (action.type) {
      case `${prefix}_REQUEST`:
        return true;
      case `${prefix}_SUCCESS`:
      case `${prefix}_FAILURE`:
      case `${prefix}_ERROR`:
        return false;
      default:
        return state;
    }
  };
}

export function getLoadedReducer(prefix) {
  return (state = null, action) => {
    switch (action.type) {
      case `${prefix}_REQUEST`:
      case `${prefix}_FAILURE`:
      case `${prefix}_ERROR`:
        return false;
      case `${prefix}_SUCCESS`:
        return true;
      default:
        return state;
    }
  };
}
