import React from 'react';

function CaretLeft() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M14.745 22a.443.443 0 0 1-.285-.117l-9.346-9.577a.458.458 0 0 1 0-.605l9.346-9.577a.4.4 0 0 1 .58 0l2.846 2.92a.445.445 0 0 1 0 .595l-6.224 6.365 6.224 6.365a.445.445 0 0 1 0 .595l-2.846 2.919a.467.467 0 0 1-.295.117z"
        fillRule="evenodd"
      />
    </svg>
  );
  /* eslint-enable */
}

export default CaretLeft;
