import React, { Component } from 'react';
import { Loading } from '../../../../common/components';
import ConversationSidebar from './ConversationSidebar';
import ConversationDisplay from './ConversationDisplay';
import { connect as felaConnect } from 'react-fela';
import classNames from 'classnames';
import includes from 'lodash/includes';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet';
moment.relativeTimeThreshold('s', 59);
moment.relativeTimeThreshold('m', 59);
moment.relativeTimeThreshold('h', 23);
moment.relativeTimeThreshold('d', 28);
moment.relativeTimeThreshold('M', 120);

class MessageCenter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeListing: null,
      showConversationMobile: false,
      showListingGroup: false,
      failedToLoadConversation: false,
    };
  }

  componentDidMount() {
    document.title = 'Message Center - Puppies.com';
    const {
      match,
      getConversation,
      archivedConversationsLoaded,
      conversationLoaded,
      isMobile,
      conversationsLoaded,
    } = this.props;
    const conversations = this.getConversations();

    if (conversations.size === 0 || !conversationsLoaded) {
      this.fetchConversations();
    }
    if (
      match.params.conversationId &&
      conversations.findIndex(i => i.get('id') === match.params.conversationId) === -1
    ) {
      this.fetchConversations();
    } else if (match.params.conversationId && !conversationLoaded.get(match.params.conversationId)) {
      getConversation(match.params.conversationId);
    } else if (match.params.tab === 'archive' && !archivedConversationsLoaded) {
      this.fetchConversations();
    }

    if (match.params.conversationId && isMobile && conversationLoaded.get(match.params.conversationId)) {
      this.setState({ showConversationMobile: true });
    }

    const footer = document.getElementById('fs-sticky-footer');
    if (footer) {
      footer.style.display = 'none';
    }
  }

  componentDidUpdate(prevProps) {
    const {
      markConversationAsRead,
      match,
      getConversation,
      archivedConversationsLoaded,
      conversationLoaded,
      conversationsLoaded,
      isMobile,
    } = this.props;

    if (prevProps.match.params.conversationId !== match.params.conversationId) {
      this.setStateBasedOnPathParams();
      if (match.params.conversationId && !conversationLoaded.get(match.params.conversationId)) {
        getConversation(match.params.conversationId);
      }
    }

    if (
      match.params.conversationId &&
      !prevProps.conversationLoaded.get(match.params.conversationId) &&
      conversationLoaded.get(match.params.conversationId) &&
      isMobile
    ) {
      this.setState({ showConversationMobile: true });
    }

    if (
      match.params.conversationId &&
      !prevProps.conversationsLoaded &&
      conversationsLoaded &&
      !conversationLoaded.get(match.params.conversationId)
    ) {
      getConversation(match.params.conversationId);
    }

    if (
      match.params.conversationId &&
      this.currentConversationHasUnread() &&
      conversationLoaded.get(match.params.conversationId)
    ) {
      markConversationAsRead(match.params.conversationId);
    }

    if (
      match.params.tab === 'archive' &&
      prevProps.match.params.tab !== match.params.tab &&
      !archivedConversationsLoaded
    ) {
      this.fetchConversations();
    }
  }

  componentWillUnmount() {
    this.props.resetConversationLoaded();
  }

  inboxActive() {
    return this.props.match.params.tab !== 'archive';
  }

  fetchConversations() {
    const { history, markConversationAsRead, match, getConversations, getArchivedConversations } = this.props;
    const fetchMethod = this.inboxActive() ? getConversations : getArchivedConversations;
    this.setState({ failedToLoadConversation: false });
    fetchMethod().then(action => {
      if (action?.response.ok && action?.json.length && !match.params.conversationId) {
        history.push(`/messages/${this.inboxActive() ? 'inbox' : 'archive'}`);
      } else if (action?.response.ok) {
        this.setStateBasedOnPathParams();
        if (this.currentConversationHasUnread()) {
          markConversationAsRead(match.params.conversationId);
        }
      } else {
        this.setState({ failedToLoadConversation: true });
      }
    });
  }

  getConversations() {
    const { conversations, match } = this.props;
    return match.params.tab === 'archive' ? conversations.get('archive') : conversations.get('inbox');
  }

  currentConversationHasUnread() {
    const { conversationsWithUnreadMessages, match, userVM } = this.props;
    const activeConversation = this.getActiveConversation();

    return (
      includes(conversationsWithUnreadMessages, match.params.conversationId) &&
      activeConversation &&
      (!userVM.get('isExpired') || activeConversation.get('created_at') < userVM.get('expirationDate'))
    );
  }

  setStateBasedOnPathParams() {
    const { match } = this.props;
    const conversations = this.getConversations();

    if (!match.params.conversationId) {
      this.setState({ activeListing: null, showListingGroup: false, showConversationMobile: false });
    } else if (conversations) {
      const listingGroup = conversations.find(
        conversation =>
          conversation.get('conversations') &&
          conversation.get('conversations').find(convo => convo.get('id') === match.params.conversationId)
      );
      this.setState({
        activeListing: listingGroup ? listingGroup.get('listing_id') : null,
      });
    }
  }

  onSelectConversation = conversation => {
    const { match, location } = this.props;
    const { showConversationMobile, activeListing } = this.state;

    if (showConversationMobile && activeListing && conversation.get('is_listing_group')) {
      this.setState({ showListingGroup: false, showConversationMobile: false });
    }

    if (conversation.get('id')) {
      this.setState({ showConversationMobile: true });
      if (this.state.showListingGroup) {
        this.setState({ showListingGroup: false });
      }
      this.props.history.push(`/messages/${match.params.tab}/${conversation.get('id')}`, {
        prevLocation: location.state && location.state.prevLocation,
        backTo: location.state && location.state.backTo,
      });
    } else {
      this.props.history.push(`/messages/${match.params.tab}`, {
        prevLocation: location.state && location.state.prevLocation,
        backTo: location.state && location.state.backTo,
      });
    }
  };

  goBack = () => {
    const { match, isMobile } = this.props;
    this.setState({ showConversationMobile: false });
    if (this.state.activeListing) {
      this.setState({ showListingGroup: true, showConversationMobile: isMobile });
    } else {
      this.props.history.push(`/messages/${match.params.tab}`);
    }
  };

  getActiveConversation() {
    const { match } = this.props;
    if (!match.params.conversationId) {
      return null;
    }

    let conversations = this.getConversations();
    if (this.state.activeListing) {
      const foundListingGroup = conversations.find(
        conversation => conversation.get('listing_id') === this.state.activeListing
      );
      conversations = foundListingGroup ? foundListingGroup.get('conversations') : this.props.conversations;
    }

    return conversations.find(convo => convo.get('id') === match.params.conversationId);
  }

  getActiveListingGroup() {
    if (!this.props.match.params.conversationId || !this.state.activeListing) {
      return null;
    }
    const conversations = this.getConversations();

    const listingGroup = conversations.find(
      conversation => conversation.get('listing_id') === this.state.activeListing
    );

    return listingGroup;
  }

  onChangeArchiveStatus(archive, conversationId) {
    const { match, history } = this.props;

    const promise = archive
      ? this.props.archiveConversation(conversationId)
      : this.props.unarchiveConversation(conversationId);

    return promise.then(action => {
      if (action.response.ok) {
        history.replace(`/messages/${match.params.tab}`);
      }
    });
  }

  renderTab(name, label) {
    const { match, styles, location } = this.props;

    const classes = {
      [styles.sidebarTab]: true,
      [styles.sidebarTabActive]: match.params.tab === name,
    };

    return (
      <Link className={classNames(classes)} to={{ pathname: `/messages/${name}`, state: { ...location.state } }}>
        {label}
      </Link>
    );
  }

  renderConversationSidebar = () => {
    const {
      match,
      user,
      isMobile,
      getMoreConversations,
      getMoreArchivedConversations,
      conversationPageAndTotal,
    } = this.props;
    const { nextInboxPage, nextArchivePage } = conversationPageAndTotal;
    const fetchMoreConversations = this.inboxActive() ? getMoreConversations : getMoreArchivedConversations;
    const nextPage = this.inboxActive() ? nextInboxPage : nextArchivePage;
    const conversations = this.getConversations();
    return (
      <ConversationSidebar
        isArchive={match.params.tab === 'archive'}
        conversations={conversations}
        currentUser={user}
        activeConversation={match.params.conversationId}
        activeListing={this.state.activeListing}
        onClickConversation={this.onSelectConversation}
        onClickListingGroup={id => this.setState({ activeListing: id, showListingGroup: true })}
        isMobile={isMobile}
        fetchMoreConversations={fetchMoreConversations}
        nextPage={nextPage}
      />
    );
  };

  renderConversationDisplay = refLocation => {
    const {
      match,
      user,
      isMobile,
      replyToConversation,
      location,
      getConversations,
      userVM,
      getUploadToken,
      deleteMessagePhoto,
    } = this.props;
    const conversation = this.getActiveConversation();
    return (
      <ConversationDisplay
        isArchive={match.params.tab === 'archive'}
        location={location}
        userVM={userVM}
        loading={conversation && this.props.conversationLoading.get(conversation.get('id'))}
        conversation={conversation}
        listingGroup={this.getActiveListingGroup()}
        currentUser={user}
        replyToConversation={replyToConversation}
        onArchive={this.onChangeArchiveStatus.bind(this, true)}
        onUnarchive={this.onChangeArchiveStatus.bind(this, false)}
        goBack={this.goBack}
        isMobile={isMobile}
        refLocation={refLocation}
        getConversations={getConversations.bind(this, user.get('id'))}
        getUploadToken={getUploadToken}
        deleteMessagePhoto={deleteMessagePhoto}
      />
    );
  };

  renderTitle = () => {
    return (
      <Helmet>
        <title>Message Center - Puppies.com</title>
      </Helmet>
    );
  };

  renderDesktopView() {
    const { styles } = this.props;
    return (
      <div className={styles.root}>
        {this.renderTitle()}
        <div className={styles.container}>
          <div className={styles.sidebar}>
            <div className={styles.sidebarHeader}>
              {this.renderTab('inbox', 'Inbox')}
              {this.renderTab('archive', 'Archive')}
            </div>
            {this.renderConversationSidebar()}
          </div>
          {this.renderConversationDisplay('desktop')}
        </div>
      </div>
    );
  }

  renderMobileView() {
    const { styles } = this.props;

    const { showConversationMobile, showListingGroup } = this.state;

    const showConversation = showConversationMobile && !showListingGroup;

    return (
      <div className={styles.root}>
        {this.renderTitle()}
        <div className={styles.container}>
          <div className={classNames(styles.animationWrapper, showConversation && styles.animationWrapperHidden)}>
            <div className={styles.sidebar}>
              <div className={styles.sidebarHeader}>
                {this.renderTab('inbox', 'Inbox')}
                {this.renderTab('archive', 'Archive')}
              </div>
              {this.renderConversationSidebar()}
            </div>
          </div>
          <div className={classNames(styles.animationWrapper, !showConversation && styles.animationWrapperHidden)}>
            {showConversation ? this.renderConversationDisplay('mobile') : null}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { match, archivedConversationsLoaded, conversationsLoaded } = this.props;

    if (
      (match.params.tab === 'inbox' && !conversationsLoaded) ||
      (match.params.tab === 'archive' && !archivedConversationsLoaded)
    ) {
      return <Loading dark center />;
    }

    return this.props.isMobile ? this.renderMobileView() : this.renderDesktopView();
  }
}

const style = props => ({
  root: {
    height: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  container: {
    display: 'flex',
    minHeight: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    [props.theme.breakpoints.desktop]: {
      maxWidth: '1070px',
      // maxHeight is set to account for header + footer
      maxHeight: 'calc(100vh - 150px)',
      height: '100%',
    },
  },
  animationWrapper: {
    width: '100vw',
    opacity: 1,
  },
  animationWrapperHidden: {
    width: '0vw',
    opacity: 0,
  },
  sidebar: {
    borderLeft: `1px solid ${props.theme.colors.darkerTan}`,
    borderRight: `1px solid ${props.theme.colors.darkerTan}`,
    width: props.isMobile ? '100%' : '342px',
    flexShrink: 0,
    // maxHeight is set to account for header + footer
    minHeight: 'calc(100vh - 150px)',
    height: 'auto',
  },
  sidebarHeader: {
    display: 'flex',
    borderBottom: `1px solid ${props.theme.colors.darkerTan}`,
    '> * + *': {
      borderLeft: `1px solid ${props.theme.colors.darkerTan}`,
    },
  },
  sidebarTab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '50%',
    padding: '10px',
    fontSize: '14px',
    cursor: 'pointer',
    color: '#4B4E52',
    transition: props.theme.globalTransition,
    ':hover': {
      backgroundColor: props.theme.colors.tan,
    },
  },
  sidebarTabActive: {
    color: `${props.theme.colors.blue} !important`,
    fontWeight: props.theme.typography.sansBold,
  },
});

export default felaConnect(style)(MessageCenter);
