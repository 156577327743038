import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ForgotPassword from './ForgotPassword';
import { forgotPassword } from '../../redux/actions';
import { isLoggedInSelector } from '../../redux/selectors';

const mapStateToProps = state => ({
  isLoggedIn: isLoggedInSelector(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({ forgotPassword }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
