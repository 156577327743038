import React from 'react';
import { connect as felaConnect } from 'react-fela';
import Square from '../Square';

function TipSquare({ header, body, backgroundColor, styles }) {
  const hasText = header && body;
  return (
    <Square className={styles.root} style={{ backgroundColor, color: `#49372C` }}>
      <div data-testid="tipCard">{header}</div>
      {hasText && <div className={styles.divider} />}
      <p>{body}</p>
    </Square>
  );
}

const styles = props => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: props.theme.colors.brown,
    padding: '24px',
    '> div': {
      fontWeight: props.theme.typography.sansBold,
      letterSpacing: 0.47,
      marginBottom: '8px',
      fontSize: '28px',
    },
    '> p': {
      width: '100%',
      marginTop: '12px',
      textAlign: 'left',
      fontSize: '14px',
      lineHeight: 1.4,
    },
  },
  divider: {
    width: '24px',
    height: '3px',
    background: props.theme.colors.brown,
  },
});

export default felaConnect(styles)(TipSquare);
