import client from '../common/utils/client';
import localstorage from 'store2';
import querystring from 'query-string';
import cookies from 'js-cookie';

export const browseBreeds = params => {
  const queryParams = params ? '?' + querystring.stringify(params) : '';
  return {
    promise: client.get(`breeds${queryParams}`),
    type: 'GET_BREEDS',
  };
};

export const checkPhoneBlacklist = phone => ({
  promise: client.get(`blacklist/phone/${phone}`),
  type: 'GET_BLACKLIST_PHONE',
});

export const resetBreeds = () => ({
  type: 'RESET_BREEDS',
});

export const getBreedGroups = () => ({
  promise: client.get('breed-groups'),
  type: 'GET_BREED_GROUPS',
});

export const resetBreedGroups = () => ({
  type: 'RESET_BREED_GROUPS',
});

export const login = (email, password) => {
  localstorage.remove('location');
  return {
    promise: client.post('auth/token', { email, password }),
    type: 'GET_TOKEN',
  };
};

export const autoLogin = key => ({
  promise: client.post('auth/auto-login', { key }),
  type: 'GET_TOKEN',
});

export const signUp = values => ({
  promise: client.post('users', values),
  type: 'SIGN_UP',
});

export const forgotPassword = values => ({
  promise: client.post('users/me/forgot-password', values),
  type: 'FORGOT_PASSWORD',
});

export const resetPassword = (tokenId, values) => ({
  promise: client.post(`users/me/reset-password/${tokenId}`, values),
  type: 'RESET_PASSWORD',
});

export const updateUser = values => ({
  promise: client.patch(`users/me`, values),
  type: 'EDIT_USER',
});

export const getUser = () => ({
  promise: client.get('users/me'),
  type: 'GET_USER',
});

export const getLegacyUser = legacy_acct_id => ({
  promise: client.get(`users/legacy/${legacy_acct_id}`),
  type: 'GET_LEGACY_USER',
});

export const updateNotificationSettings = notificationSettings => ({
  promise: client.patch('users/me/update-notifications', notificationSettings),
  type: 'UPDATE_NOTIFICATIONS',
});

export const getBreedInformation = (breed, query) => ({
  promise: client.get(`breeds/${breed}`, query),
  type: 'GET_BREED',
});

export const getLocationInformation = (city = '', state = '') => {
  const formattedCity = city.toLowerCase();
  const formattedState = state.toLowerCase();
  return {
    promise: client.get(`location`, { city: formattedCity, state: formattedState }),
    type: 'GET_LOCATION_INFORMATION',
    key: `${formattedCity}${formattedState}`,
  };
};

export const resetBreed = breed => ({
  type: 'RESET_BREED',
});

export const logout = () => {
  cookies.remove('id_token');
  return {
    type: 'LOG_OUT',
  };
};
export const getCoatPatterns = () => ({
  promise: client.get('breeds/coat-patterns'),
  type: 'GET_COAT_PATTERNS',
});
export const getColors = () => ({
  promise: client.get('breeds/colors'),
  type: 'GET_COLORS',
});

export const sendPhoneVerification = (phone_number, phone_country_code, type) => ({
  promise: client.post(`users/me/phone-verification/${phone_number}`, { phone_country_code, type }),
  type: 'SEND_PHONE_VERIFICATION',
});

export const confirmPhoneVerification = (phone_number, phone_country_code, verification_code) => ({
  promise: client.post(`users/me/phone-verification/${phone_number}`, { verification_code, phone_country_code }),
  type: 'CONFIRM_PHONE_VERIFICATION',
});

export const sendEmailVerification = email => ({
  promise: client.post('users/me/email-verification'),
  type: 'SEND_EMAIL_VERIFICATION',
});

export const confirmEmailVerification = token_id => ({
  promise: client.post(`users/me/email-verification/${token_id}`),
  type: 'CONFIRM_EMAIL_VERIFICATION',
});

export const updateBreedsAutocomplete = query => ({
  promise: client.get(`breeds?autocomplete=true&name=${query}`),
  type: 'UPDATE_BREEDS_AUTOCOMPLETE',
});

export const updateBreedsAutocompleteListing = query => ({
  promise: client.get(`breeds-search?autocomplete=true&name=${query}`),
  type: 'UPDATE_BREEDS_AUTOCOMPLETE',
});

export const resetBreedsAutocomplete = () => ({
  type: 'RESET_BREEDS_AUTOCOMPLETE',
});

export const updatePremiersAutocomplete = query => ({
  promise: client.get(`premiers?autocomplete=true&name=${query}`),
  type: 'UPDATE_PREMIERS_AUTOCOMPLETE',
});

export const resetPremiersAutocomplete = () => ({
  type: 'RESET_PREMIERS_AUTOCOMPLETE',
});

export const getMyListings = (status, page) => ({
  promise: client.get(`listings?${querystring.stringify({ status, page })}`),
  type: 'GET_MY_LISTINGS',
});

export const resetMyListings = () => ({
  type: 'RESET_MY_LISTINGS',
});

export const clearErrorBar = () => {
  return {
    type: 'CLEAR_ERROR_BAR',
  };
};

export const setErrorBarMessage = message => {
  return {
    type: 'SET_ERROR_BAR_MESSAGE',
    message,
  };
};

export const getUploadToken = () => {
  return {
    promise: client.get('photos/upload-token'),
    type: 'GET_UPLOAD_TOKEN',
  };
};

export const deleteListingImage = (public_id, listing_id) => {
  return {
    promise: client.post(`listings/${listing_id}/photos/delete`, { public_id }),
    type: 'DELETE_LISTING_PHOTO',
  };
};

export const deletePremierProfileImage = (public_id, premier_profile_id) => {
  return {
    promise: client.post(`premiers/${premier_profile_id}/photos/delete`, { public_id }),
    type: 'DELETE_PREMIER_PROFILE_PHOTO',
  };
};

export const deleteUserImage = public_id => {
  return {
    promise: client.post(`users/me/photos/delete`, { public_id }),
    type: 'DELETE_USER_PHOTO',
  };
};

export const addSavedSearch = payload => ({
  promise: client.post(`searches`, payload),
  type: 'CREATE_SAVED_SEARCH',
});

export const removeSavedSearch = id => ({
  promise: client.delete(`searches/${id}`),
  type: 'REMOVE_SAVED_SEARCH',
  id,
});

export const getMatchmakerResults = payload => ({
  promise: client.post(`breeds/matchmaker`, payload),
  TYPE: 'GET_MATCHMAKER_RESULTS',
});

export const fetchRandomBreed = () => ({
  promise: client.get('breeds/random'),
  type: 'GET_RANDOM_BREED',
});

export const resetRandomBreed = () => ({
  type: 'RESET_RANDOM_BREED',
});

export const getListingCount = () => ({
  promise: client.get('listings/count'),
  type: 'GET_LISTING_COUNT',
});

export const getRandomTips = () => ({
  promise: client.get(`tips/random`),
  type: 'GET_RANDOM_TIPS',
});

export const connectFacebook = accessToken => ({
  promise: client.post('facebook-connect', { accessToken }),
  type: 'CONNECT_FACEBOOK',
});

export const disconnectFacebook = () => ({
  promise: client.post(`facebook-disconnect`),
  type: 'DISCONNECT_FACEBOOK',
});

export const resetDisconnectFacebookStatus = () => ({
  type: 'DISCONNECT_FACEBOOK_RESET',
});

export const reportUser = (user_id, type, entity_id, reason, user_defined_reason) => {
  return {
    promise: client.post(`users/${user_id}/report`, { type, entity_id, reason, user_defined_reason }),
    type: 'REPORT_USER',
  };
};

export const blockUser = (user_id, entity_id, reason) => {
  return {
    promise: client.post(`users/${user_id}/block`, { entity_id, reason }),
    type: 'BLOCK_USER',
  };
};

export const contactSupport = payload => {
  return {
    promise: client.post('contact/support', payload),
    type: 'CONTACT_SUPPORT',
  };
};

export const contactSupportReset = () => {
  return {
    type: 'CONTACT_SUPPORT_RESET',
  };
};

export const restartMigration = user_id => {
  return {
    promise: client.patch(`users/restart-migration/${user_id}`),
    type: 'RESTART_MIGRATION',
  };
};

export const completeMigration = user_id => {
  return {
    promise: client.patch(`users/complete-migration/${user_id}`),
    type: 'COMPLETE_MIGRATION',
  };
};

export const getUserViewModel = user_id => {
  return {
    promise: client.get(`user/${user_id}/userViewModel`),
    type: 'GET_MEMBERSHIP_VIEW_MODEL',
  };
};

export const refreshUserViewModel = () => {
  return {
    type: 'REFRESH_MEMBERSHIP_VIEW_MODEL',
  };
};
