import React, { useCallback, useEffect, useState } from 'react';
import { connect as felaConnect } from 'react-fela';
import { List } from 'immutable';
import { Select, MarkAsSoldModal } from '..';
import ConfirmOnHoldModal from './ConfirmOnHoldModal';

const options = [
  { value: 'for_sale', label: 'For Sale' },
  { value: 'on_hold', label: 'On Hold' },
  { value: 'sold', label: 'Sold' },
];

const ListingStatusForm = ({
  user,
  userVM,
  styles,
  listing,
  updateListingStatus,
  conversations = List(),
  onStatusUpdated = () => {},
}) => {
  const possibleBuyers = usePossibleBuyers(user, conversations);
  const [contactedByListingRadioValue, setContactedByListingRadioValue] = useState(null);
  const currentStatus = useCurrentStatus(listing, updateListingStatus, onStatusUpdated, contactedByListingRadioValue);
  const desiredStatus = useDesiredStatus(currentStatus.value);
  useUpdateToForSaleIfDesired(desiredStatus.value, currentStatus.set);

  if (currentStatus.value === 'sold') {
    return (
      <div className={styles.fullWidth}>
        <div className={styles.sold}>Sold</div>
      </div>
    );
  }

  return (
    <div>
      <form data-testid="listing-status-dropdown" className={styles.form}>
        <Select
          onChange={option => desiredStatus.set(option ? option.value : currentStatus.value)}
          selectedOption={options.find(option => option.value === currentStatus.value)}
          options={options}
        />
        <MarkAsSoldModal
          isOpen={desiredStatus.value === 'sold'}
          onClose={desiredStatus.revert}
          isSubmitting={currentStatus.isUpdating}
          error={currentStatus.error}
          possibleBuyers={possibleBuyers}
          onMarkOnHold={() => desiredStatus.set('on_hold')}
          onSubmit={desiredBuyerId => currentStatus.set(desiredStatus.value, desiredBuyerId)}
          listing={listing}
          setError={error => currentStatus.setError(error)}
          userVM={userVM}
          setContactedByListingRadioValue={setContactedByListingRadioValue}
          contactedByListingRadioValue={contactedByListingRadioValue}
        />
        <ConfirmOnHoldModal
          visible={desiredStatus.value === 'on_hold'}
          onRequestClose={desiredStatus.revert}
          onConfirmOnHold={() => currentStatus.set('on_hold')}
          userVM={userVM}
        />
      </form>
    </div>
  );
};

const usePossibleBuyers = (user, conversations) =>
  React.useMemo(() => {
    const uniqueUsers = [];
    conversations.forEach(conversation => {
      conversation &&
        conversation.get('users').forEach(convoUser => {
          if (convoUser.get('id') !== user.get('id') && !uniqueUsers.find(u => u.get('id') === convoUser.get('id'))) {
            uniqueUsers.push(convoUser);
          }
        });
    });
    return uniqueUsers;
  }, [user, conversations]);

const useDesiredStatus = currentStatus => {
  const [value, setValue] = React.useState(null);
  const revert = useCallback(() => setValue(null), []);
  const set = useCallback(desiredStatus => setValue(desiredStatus === currentStatus ? null : desiredStatus), [
    currentStatus,
  ]);

  const currentStatusIsDesiredStatus = currentStatus === value;
  React.useEffect(() => {
    if (currentStatusIsDesiredStatus) {
      revert();
    }
  }, [currentStatusIsDesiredStatus, revert]);

  return {
    value,
    set,
    revert,
  };
};

const useCurrentStatus = (listing, updateListingStatus, onStatusUpdated, contactedByListingRadioValue) => {
  const [isStatusUpdating, setIsStatusUpdating] = React.useState(false);
  const [error, setError] = React.useState('');

  return {
    error,
    setError,
    isUpdating: isStatusUpdating,
    value: listing.get('status'),
    set: React.useCallback(
      async (desiredStatus, buyerId) => {
        setIsStatusUpdating(true);
        const { response } = await updateListingStatus(
          { status: desiredStatus, buyer_id: buyerId, contacted_through_listing: contactedByListingRadioValue },
          listing.get('id')
        );
        setIsStatusUpdating(false);
        if (response.ok) {
          onStatusUpdated(desiredStatus);
          setError('');
        } else {
          setError('Could not change listing status at this time');
        }
      },
      [updateListingStatus, onStatusUpdated, listing, contactedByListingRadioValue]
    ),
  };
};

const useUpdateToForSaleIfDesired = (desiredStatus, setCurrentStatus) => {
  useEffect(() => {
    if (desiredStatus === 'for_sale') {
      setCurrentStatus(desiredStatus);
    }
  }, [desiredStatus, setCurrentStatus]);
};

export default felaConnect(props => ({
  form: {
    fontWeight: 'bold',
  },
  fullWidth: {
    height: '38px',
  },
  sold: {
    display: 'flex',
    justifyContent: 'center',
    background: '#FF4D4D',
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltBold,
    fontSize: '20px',
    padding: '4px 14px',
    marginBottom: '-20px',
    color: props.theme.colors.white,
    letterSpacing: '0.43px',
    textAlign: 'center',
    [props.theme.breakpoints.desktop]: {
      borderRadius: '100px',
      border: `2px solid ${props.theme.colors.white}`,
    },
  },
}))(ListingStatusForm);
