import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect as felaConnect } from 'react-fela';
import { List } from 'immutable';

import { Icon, PlainLink, ButtonLink } from '../../../common/components';
import AdSkyscraper from '../../../common/components/AdSkyscraper';

class Sidebar extends Component {
  static propTypes = {
    featured: PropTypes.instanceOf(List),
    recent: PropTypes.instanceOf(List),
  };

  render() {
    const { recent, featured, styles } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.callout}>
          <Icon icon="PuppyWithCrown" size={55} />
          <h3>FIND THE RIGHT BREED</h3>
          <h4>Your new best friend is waiting!</h4>
          <ButtonLink buttonType="round" to="/breeds">
            BREED DIRECTORY
          </ButtonLink>
        </div>
        <div className={styles.articleList}>
          <div className={styles.articleSection}>
            <h3>Featured</h3>
            {featured &&
              featured.map(article => (
                <PlainLink
                  to={`/blog/articles/${article.getIn(['sys', 'id'])}`}
                  key={article.getIn(['sys', 'id'])}
                  variant="blue"
                >
                  {article.getIn(['fields', 'title'])}
                </PlainLink>
              ))}
          </div>
          <div className={styles.articleSection}>
            <h3>Most Recent</h3>
            {recent &&
              recent.map(article => (
                <PlainLink
                  to={`/blog/articles/${article.getIn(['sys', 'id'])}`}
                  key={article.getIn(['sys', 'id'])}
                  variant="blue"
                >
                  {article.getIn(['fields', 'title'])}
                </PlainLink>
              ))}
          </div>
        </div>
        <div className={styles.AdSkyscraper}>
          <AdSkyscraper placementName="puppies_learning_right11" slotId="learning-center" />
        </div>
      </div>
    );
  }
}

const styles = props => ({
  root: {
    width: '300px',
    flexShrink: 0,
  },
  callout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: props.theme.colors.blue,
    borderRadius: '4px',
    padding: '20px',
    color: props.theme.colors.white,
    textAlign: 'center',

    [props.theme.breakpoints.mobileLarge]: {
      height: '320px',
    },
    '> h3': {
      fontSize: '25px',
      letterSpacing: '2px',
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansAltBold,
      marginTop: '20px',
      maxWidth: '230px',
    },
    '> h4': {
      fontWeight: props.theme.typography.sansBold,
      fontSize: '14px',
      color: '#123742',
      margin: '13px 0',
      maxWidth: '170px',
    },
    '> a': {
      width: '100%',
      marginTop: '10px',
    },
  },
  articleList: {
    marginTop: '30px',
    background: props.theme.colors.tan,
    borderRadius: '4px',
    padding: '20px',
    color: props.theme.colors.blue,
    fontSize: '14px',
    '> * + *': {
      marginTop: '50px',
    },
  },
  articleSection: {
    '> h3': {
      ...props.theme.typography.titleText,
    },
    '> a': {
      display: 'block',
      marginTop: '22px',
    },
  },
  AdSkyscraper: {
    margin: '30px 0',
    display: 'flex',
    justifyContent: 'center',
  },
});

export default withRouter(felaConnect(styles)(Sidebar));
