import Icon from '../icons/icon';
import theme from '../../../theme';

const ToolTipBody = ({
  currentTip,
  currentTipIndex,
  tipsJson,
  dimensions,
  arrowPosition,
  styles,
  setCurrentTipIndex,
  setDone,
}) => {
  return (
    <div
      data-tip={currentTipIndex}
      className={styles.toolTip}
      style={dimensions}
      id={`tool-tip-${currentTip.elementId}`}
    >
      <div className={styles.toolTipTriangle} style={arrowPosition}></div>
      <div className={styles.flex}>
        {currentTip.icon && (
          <div className={styles.iconContainer}>
            <Icon icon={currentTip.icon} size={18} fill={theme.colors.blue} />
          </div>
        )}
        <div className={styles.toolTipTitle}>{currentTip.title}</div>
      </div>
      <div className={styles.toolTipMessage}>{currentTip.tip}</div>
      <div className={styles.toolTipLinks}>
        <div
          className={styles.next}
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            if (currentTipIndex < tipsJson.length - 1) {
              setCurrentTipIndex(currentTipIndex + 1);
            } else {
              setDone(true);
            }
          }}
        >
          {currentTipIndex < tipsJson.length - 1 ? 'Next' : 'Done'}
        </div>
        <div>
          {currentTipIndex + 1} of {tipsJson.length}
        </div>
        {currentTipIndex < tipsJson.length - 1 && (
          <div
            className={styles.skip}
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              setDone(true);
            }}
          >
            Skip All
          </div>
        )}
      </div>
    </div>
  );
};

export default ToolTipBody;
