import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonPlain, Button, Modal, ModalFooter, ConfirmationModalBody } from '../';
import theme from '../../../theme';

class ConfirmationModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    confirmButtonText: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    closeOnOutsideClick: PropTypes.bool,
  };

  static defaultProps = {
    title: 'Are you sure?',
    subTitle: null,
    confirmButtonText: 'Yes',
  };

  state = {
    confirming: false,
    error: false,
  };

  onConfirm = () => {
    const { onConfirm } = this.props;

    const returnValue = onConfirm();
    if (returnValue instanceof Promise) {
      this.setState({ confirming: true });
      returnValue
        .then(action => {
          if (action.response.ok) {
            this.onClose();
          } else {
            this.setState({ confirming: false, error: true });
          }
        })
        .catch(() => this.setState({ error: true }));
    } else {
      this.onClose();
    }
  };

  onClose() {
    this.setState({ confirming: false, error: false });
    this.props.onClose();
  }

  render() {
    const { isOpen, onClose, title, subTitle, confirmButtonText, onCancel, closeOnOutsideClick } = this.props;
    const { confirming, error } = this.state;

    return (
      <Modal isOpen={isOpen} onClose={onClose} closeOnOutsideClick={closeOnOutsideClick}>
        <ConfirmationModalBody
          icon="Warning"
          iconFill={theme.colors.red}
          title={title}
          subTitle={subTitle}
          inputError={error ? 'Could not complete request at this time' : null}
        />
        <ModalFooter
          actions={[
            <ButtonPlain onClick={onCancel ? onCancel : onClose} disabled={confirming}>
              Cancel
            </ButtonPlain>,
            <Button type="button" buttonType="round" disabled={confirming} onClick={this.onConfirm}>
              {confirmButtonText}
            </Button>,
          ]}
        />
      </Modal>
    );
  }
}

export default ConfirmationModal;
