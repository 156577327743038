import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import config from '../../common/config';
import RatingBar from './RatingBar';
import {
  Icon,
  Loading,
  PuppyGrid,
  SubNav,
  Button,
  ButtonLink,
  PuppyListHeader,
  LandingPageFooter,
  StartTrialModal,
  TopNavLoggedOut,
  Footer,
  PlainLink,
  ContentNotFound,
} from '../../common/components';
import { getBreedInformation, resetBreed } from '../../redux/actions';
import PrimaryLayout from '../../layouts/PrimaryLayout';
import NoContentPlaceholder from '../../common/components/NoContentPlaceholder';
import { getPathFromBreedAndLocation } from '../../common/utils/search';

class BreedInformation extends Component {
  static propTypes = {
    breedInformation: PropTypes.object,
  };

  static fetchData(location, match, user) {
    const query = {};

    if (match.params.state && match.params.city && match.params.state.length === 2) {
      query.city = match.params.city;
      query.state = match.params.state;
    } else if (match.params.state && match.params.state.length === 2) {
      query.state = match.params.state;
    }

    return [getBreedInformation(match.params.slug, query)];
  }

  state = {
    showTrialModal: false,
  };

  componentDidMount() {
    const { breedInformation, match, location, user, dispatch } = this.props;
    if (!breedInformation || match.params.slug !== breedInformation.get('slug')) {
      BreedInformation.fetchData(location, match, user).forEach(action => dispatch(action));
    }
  }

  componentDidUpdate(prevProps) {
    const { match, location, user, dispatch } = this.props;
    if (prevProps.match.url !== match.url) {
      BreedInformation.fetchData(location, match, user).forEach(action => dispatch(action));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetBreed());
  }

  handleTrialStart = () => {
    this.props.history.push({ pathname: '/sign-up', state: { purchaseType: 'trial' } });
  };

  relatedBreeds = () => {
    const { styles, breedInformation, match } = this.props;

    const relatedList = breedInformation.get('breedRelations');

    if (!relatedList || relatedList.size === 0) {
      return null;
    }

    return (
      <div className={styles.relatedBreeds}>
        Related Breeds:
        {relatedList.map(relation => {
          let to = `/breeds/${relation.getIn(['slug'])}`;
          if (match.params.state) {
            to += `/${match.params.state}`;
          }
          if (match.params.city) {
            to += `/${match.params.city}`;
          }
          return (
            <span className={styles.relatedBreed} key={relation.getIn(['slug'])}>
              <Link to={to}>{relation.getIn(['name'])}</Link>
            </span>
          );
        })}
      </div>
    );
  };

  traitDescriptor = (headline, value, styles) => {
    if (value) {
      return (
        <div className={styles.trait}>
          <h3>{headline}</h3>
          <p>{value}</p>
        </div>
      );
    }
    return null;
  };

  renderSubNav = () => <SubNav />;

  renderTraits = () => {
    const { breedInformation, styles } = this.props;
    const overview = breedInformation.get('short_overview');

    return (
      <div className={styles.traitsWrapper}>
        <div className={styles.traitsHeader}>
          <h2>Breed Profile</h2>
          <p className={styles.allowLineBreaks}>
            {overview ? overview : 'More information about this breed is coming soon!'}
          </p>
        </div>
        <div className={styles.traits}>
          {this.traitDescriptor('Behavior', breedInformation.get('behavior'), styles)}
          {this.traitDescriptor('Coat', breedInformation.get('coat'), styles)}
          {this.traitDescriptor('Lifestyle', breedInformation.get('lifestyle'), styles)}
          {this.traitDescriptor('Activity', breedInformation.get('activity'), styles)}
        </div>
        {breedInformation.get('attribution') && (
          <div className={styles.attribution}>{breedInformation.get('attribution')}</div>
        )}
      </div>
    );
  };

  renderBreedHeader = () => {
    const { breedInformation, styles, user, setActiveModal, location, match } = this.props;

    const isBreedLocationLanding = match.params.state && match.params.state.length === 2;

    const path = getPathFromBreedAndLocation(
      breedInformation.get('name'),
      breedInformation.getIn(['matchedPlace', 'formattedLocation'])
    );

    const buttonLink = (
      <div className={styles.viewPuppiesBtn}>
        <ButtonLink buttonType="largeRoundOrangeOutline" to={path} style={{ lineHeight: '24px' }}>
          {isBreedLocationLanding
            ? `SHOW ME ${breedInformation.get('name')} PUPPIES!`
            : `VIEW ${breedInformation.get('name')} PUPPIES`}
        </ButtonLink>
        <PlainLink to="/breeds">Browse All Breeds</PlainLink>
      </div>
    );
    const title = `${breedInformation.get('name')} - Dog Breed Information`;
    const image = `url('${config.breedPhotoBucketURL}${
      breedInformation.get('photo') ? escape(breedInformation.get('photo')) : 'Placeholder.png'
    }')`;
    const description = `Interested in a new puppy? Uncover ${breedInformation.get(
      'name'
    )} dog breed details at Puppies.com to decide if it's the right choice for your home.`;
    const canonical = `${config.frontendHost}/find-a-puppy/${breedInformation.get('slug')}`;
    return (
      <div
        style={{
          backgroundImage: image,
          backgroundPosition: `${breedInformation.get('photo_position')}`,
        }}
        className={styles.breedHeaderWrapper}
        data-testid="breed-header"
      >
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={canonical} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={canonical} />
          <meta property="og:image" content={image} />
          <meta name="title" content={title} />
          <meta name="description" content={description} />
        </Helmet>
        <div
          className={classNames(
            styles.breedHeader,
            !breedInformation.get('photo') && styles.breedHeaderNoPhoto,
            !user && styles.breedHeaderLoggedOut
          )}
        >
          {!user && (
            <TopNavLoggedOut
              buttonLeft={
                <Button
                  buttonType="roundWhite"
                  iconLeft={<Icon size={14} icon="FatAdd" />}
                  onClick={() => this.setState({ showTrialModal: true })}
                >
                  LIST A PUPPY
                </Button>
              }
              buttonRight={
                <Button
                  buttonType="roundWhite"
                  iconLeft={<Icon size={14} icon="Avatar" />}
                  onClick={() => {
                    location.prevLocation = location;
                    setActiveModal('signin');
                  }}
                >
                  SIGN IN
                </Button>
              }
            />
          )}
          <div className={styles.breedHeaderContent}>
            <h1 className={styles.breedHeaderTitle}>
              {breedInformation.get('name')} {isBreedLocationLanding && 'Puppies'}
            </h1>
            {isBreedLocationLanding && (
              <h1 className={styles.breedHeaderSubTitle}>
                Near {breedInformation.getIn(['matchedPlace', 'city'])},{' '}
                {breedInformation.getIn(['matchedPlace', 'state'])}
              </h1>
            )}
            <h2 className={styles.breedGroup}>
              Breed Group: <strong>{breedInformation.getIn(['breedGroup', 'title'])}</strong>
            </h2>
            {breedInformation.get('headline') && (
              <p className={styles.breedHeadline}>"{breedInformation.get('headline')}"</p>
            )}
            {breedInformation.get('is_hypoallergenic') ? (
              <div className={styles.hypoallergenic}>
                <Icon icon="AddCircle" size={16} /> Hypoallergenic
              </div>
            ) : null}
            {buttonLink}
          </div>
        </div>
        {this.relatedBreeds()}
      </div>
    );
  };

  renderLocalListings = () => {
    const { breedInformation, location, styles } = this.props;

    return (
      <div className={styles.puppyGridWrapper}>
        <PuppyGrid
          noContent={
            <NoContentPlaceholder
              icon="PuppyOutline"
              globalPadding
              title={`Sorry, no ${breedInformation.get('name')} puppies are available at this time.`}
            />
          }
          total={breedInformation.getIn(['matchedListings', 'total'])}
          header={
            <PuppyListHeader
              total={breedInformation.getIn(['matchedListings', 'total'])}
              totalLabel={
                breedInformation.getIn(['matchedPlace', 'city'])
                  ? `available near ${breedInformation.getIn(['matchedPlace', 'city'])}, ${breedInformation.getIn([
                      'matchedPlace',
                      'state',
                    ])}`
                  : 'available'
              }
              breedType={breedInformation.get('name')}
            />
          }
          listings={breedInformation.getIn(['matchedListings', 'results'])}
          location={location}
          noAds
          hideBookmark
        />
        {breedInformation.getIn(['matchedListings', 'total']) > 7 ? (
          <div className={styles.puppyGridButton}>
            <ButtonLink
              buttonType="round"
              to={getPathFromBreedAndLocation(
                breedInformation.get('name'),
                breedInformation.getIn(['matchedPlace', 'formattedLocation'])
              )}
            >
              View All
            </ButtonLink>
          </div>
        ) : null}
      </div>
    );
  };

  renderBreedInformation = () => {
    const { breedInformation } = this.props;
    if (!breedInformation || !breedInformation.get('short_overview')) {
      return null;
    }
    return <RatingBar breedInformation={breedInformation} breedName={breedInformation.get('name')} />;
  };

  renderLandingPageFooter = () => {
    if (this.props.user) {
      return null;
    }
    return (
      <Fragment>
        <LandingPageFooter />
        <StartTrialModal
          isOpen={this.state.showTrialModal}
          onClose={() => {
            this.setState({ showTrialModal: false });
          }}
          onSubmit={this.handleTrialStart}
          closeOnEscape
          closeOnOutsideClick
        />
      </Fragment>
    );
  };

  render404() {
    return (
      <PrimaryLayout>
        <ContentNotFound
          icon="PuppyOutline"
          to="/breeds"
          buttonText="Browse All Breeds"
          title="Sorry, we couldn't find the page you were looking for."
        />
      </PrimaryLayout>
    );
  }

  render() {
    const { breedInformation, loading, user, match, error } = this.props;

    if (loading || (breedInformation && match.params.slug !== breedInformation.get('slug') && !error)) {
      return <Loading dark center />;
    }

    if (error || !breedInformation) {
      return this.render404();
    }

    if (user) {
      return (
        <PrimaryLayout>
          <div>
            {this.renderSubNav()}
            {this.renderBreedHeader()}
            {this.renderTraits()}
            {this.renderBreedInformation()}
            {this.renderLocalListings()}
            {this.renderLandingPageFooter()}
          </div>
        </PrimaryLayout>
      );
    }

    return (
      <div>
        {this.renderBreedHeader()}
        {this.renderTraits()}
        {this.renderBreedInformation()}
        {this.renderLocalListings()}
        {this.renderLandingPageFooter()}
        <Footer isLoggedIn={false} />
      </div>
    );
  }
}

const styles = props => ({
  breedHeaderWrapper: {
    minHeight: '50vw',
    maxHeight: '700px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    color: props.theme.colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    ':after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: 'rgba(138,109,91,0.5)',
      height: '100%',
      width: '100%',
    },
    [props.theme.breakpoints.tabletLarge]: {
      minHeight: 'unset',
      height: '45vw',
    },
  },
  breedHeader: {
    ...props.theme.globalPageWrapper,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    position: 'relative',
    zIndex: '1',
    [props.theme.breakpoints.tabletLarge]: {
      paddingLeft: '20px !important',
      paddingRight: '20px !important',
    },
  },
  breedHeaderNoPhoto: {
    color: props.theme.colors.brown,
    textShadow: 'none',
  },
  breedHeaderLoggedOut: {
    justifyContent: 'flex-start',
  },
  breedHeaderContent: {
    textShadow: '1px 1px 4px rgba(0,0,0,.5)',
    [props.theme.breakpoints.tabletLarge]: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  breedHeaderTitle: {
    marginTop: !props.user ? '50px' : null,
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltBold,
    fontStyle: 'italic',
    fontSize: '28px',
    wordBreak: 'break-word',
    [props.theme.breakpoints.mobile]: {
      fontSize: '45px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      marginTop: 0,
    },
  },
  breedHeaderSubTitle: {
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: `${props.theme.typography.sansAltLight} !important`,
    fontStyle: 'italic',
    fontSize: '28px',
    [props.theme.breakpoints.mobile]: {
      fontSize: '45px',
    },
  },
  breedGroup: {
    fontSize: '16px',
    marginTop: '20px',
    [props.theme.breakpoints.mobile]: {
      fontSize: '20px',
    },
  },
  breedHeadline: {
    fontSize: '16px',
    fontStyle: 'italic',
    marginTop: '6px',
    [props.theme.breakpoints.mobile]: {
      fontSize: '20px',
    },
  },
  viewPuppiesBtn: {
    textShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '30px',
    '> * + *': {
      fontStyle: 'italic',
      marginTop: '20px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      alignItems: 'center',
      flexDirection: 'row',
      '> * + *': {
        marginTop: 0,
        marginLeft: '20px',
      },
    },
  },
  relatedBreeds: {
    display: 'none',
    zIndex: 1,
    [props.theme.breakpoints.tabletLarge]: {
      textAlign: 'center',
      display: 'flex',
      maxWidth: props.theme.globalMaxWidth,
      margin: '0 auto 32px',
      ...props.theme.globalPadding,
    },
    '> * + *': {
      paddingLeft: '10px',
      borderLeft: '2px solid white',
    },
  },
  relatedBreed: {
    display: 'inline=block',
    marginLeft: '10px',
    '> a': {
      textDecoration: 'underline',
      fontWeight: props.theme.typography.sansBold,
      display: 'inline-block',
    },
  },
  traitsWrapper: {
    width: '100%',
    maxWidth: props.theme.globalMaxWidth,
    margin: '24px auto',
    ...props.theme.globalPadding,
    color: props.theme.colors.brown,
    lineHeight: '20px',
    fontSize: '14px',
  },
  traitsHeader: {
    '> h2': {
      ...props.theme.typography.titleText,
    },
    '> p': {
      margin: '20px 0',
    },
  },
  traits: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  trait: {
    marginTop: '30px',
    flexBasis: '45%',
    '> h3': {
      fontWeight: props.theme.typography.sansBold,
    },
    '> p': {
      marginTop: '2px',
    },
  },
  puppyGridWrapper: {
    marginBottom: '32px',
  },
  puppyGridButton: {
    textAlign: 'center',
    marginTop: '24px',
    [props.theme.breakpoints.mobile]: {
      marginTop: '10px',
    },
  },
  hypoallergenic: {
    textShadow: 'none',
    display: 'inline-flex',
    width: 'fit-content',
    alignItems: 'center',
    backgroundColor: props.theme.colors.green,
    borderRadius: '40px',
    padding: '4px 12px 4px 4px',
    marginTop: '12px',
    fontSize: '12px',
    lineHeight: '17px',
    fontWeight: props.theme.typography.sansBold,
    '> span': {
      marginRight: '8px',
    },
  },
  attribution: {
    marginTop: '12px',
    fontSize: '12px',
  },
  allowLineBreaks: {
    'white-space': 'pre-line',
  },
});
export default felaConnect(styles)(BreedInformation);
