import React, { useState, useEffect } from 'react';
import client from '../../utils/client';
import PopupToolTip from './PopupToolTip';

const ShowPopupToolTip = function({ popupName, tipsFor, styles }) {
  const hidePopUp = useHidePopup({ popupName });

  if (hidePopUp) {
    return null;
  }

  return <PopupToolTip popupName={popupName} tipsFor={tipsFor} styles={styles} />;
};

const getHasViewedPopup = async popupName => {
  const res = await client.get(`popup-viewed/${popupName}`);
  const body = await res.json();
  return body.popup_viewed;
};

const useHidePopup = ({ popupName }) => {
  const [hidePopup, setHidePopup] = useState(true);

  useEffect(() => {
    getHasViewedPopup(popupName).then(hasViewed => {
      setHidePopup(hasViewed);
    });
  }, [popupName]);

  return hidePopup;
};

export default ShowPopupToolTip;
