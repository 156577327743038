import { connect } from 'react-redux';
import { getUploadToken, setErrorBarMessage } from '../../../../redux/actions';
import { getPremierBySlug } from '../../../Premiers/redux/actions';
import { createPremierProfile, editPremierProfile } from '../../redux/actions';
import PuppyFindMigrationPremiers from './PuppyFindMigrationPremiers';
import { selectBreedsAutocomplete } from '../../../../redux/selectors';

const mapStateToProps = state => {
  return {
    user: state.user,
    profile: state.user.get('premierProfile'),
    breeds: selectBreedsAutocomplete(state),
  };
};

const mapDispatchToProps = {
  createPremierProfile,
  editPremierProfile,
  getUploadToken,
  getPremierBySlug,
  setErrorBarMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PuppyFindMigrationPremiers);
