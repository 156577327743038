import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';

import FsAd from '../Freestar';
import Square from '../Square';

class AdSquare extends Component {
  render() {
    const { styles } = this.props;
    return (
      <Square className={styles.square} adMargin={true}>
        <FsAd slotId={this.props.slotId} placementName={this.props.placementName} />
      </Square>
    );
  }
}

const styles = props => {
  return {
    square: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: props.theme.colors.tan,
      borderRadius: '0',
      width: '100%',
      height: '70px !important',
      overFlow: 'hidden',
      marginBottom: '0px',
      [props.theme.breakpoints.mobile]: {
        borderRadius: '6px',
        height: '280px !important',
      },
    },
  };
};

export default felaConnect(styles)(AdSquare);
