import { connect } from 'react-redux';

import BreedsList from './BreedsList';
import { selectBreedGroupsForDropdown } from '../../redux/selectors';
import { selectBreedSlugsAutoComplete } from './redux/selectors';

const mapStateToProps = state => ({
  breedsList: state.breedsList,
  total: state.breedsListTotal,
  loading: state.breedsListLoading,
  breedGroupOptions: selectBreedGroupsForDropdown(state),
  breeds: selectBreedSlugsAutoComplete(state),
});

export default connect(mapStateToProps)(BreedsList);
