import React from 'react';
import PropTypes from 'prop-types';

import { ModalPaymentHeader } from '../../../../../common/components';

import formatPrice from '../../../../../common/utils/formatPrice';

const StandardModalPaymentHeader = ({ planId, planName, price }) => {
  return (
    <ModalPaymentHeader
      planId={planId}
      title="Complete your purchase"
      subTitle={
        <span data-testid="standard-subtitle">
          You'll be charged <strong>{formatPrice(price)}</strong> for a {planName}. This is a <strong>one-time</strong>{' '}
          charge.
        </span>
      }
    />
  );
};

StandardModalPaymentHeader.propTypes = {
  planId: PropTypes.string,
  planName: PropTypes.string,
  price: PropTypes.number,
};

export default StandardModalPaymentHeader;
