import React from 'react';

function IDVerificationCard(verified) {
  /* eslint-disable max-len */
  return (
    <svg width="63" height="37" viewBox="0 0 63 37" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 2.002C0 .897.89 0 2.007 0h58.986C62.1 0 63 .896 63 2.002v32.996C63 36.103 62.11 37 60.993 37H2.007C.9 37 0 36.104 0 34.998V2.002z"
          fill={verified ? '#04C5FF' : '#DDD'}
        />
        <path
          fill={verified ? '#FFF' : '#BAA995'}
          d="M33 17h19v3H33zM33 10h19v3H33zM33 24h19v3H33zM27.65 25.834c0-3.888-6.994-3.105-6.994-6.22 0 0 2.331-2.33 2.331-6.601 0-2.569-2.094-4.663-4.662-4.663s-4.663 2.094-4.663 4.663c0 4.27 2.332 6.602 2.332 6.602 0 3.114-6.994 2.33-6.994 6.22v.773c0 .219.173.392.392.392h17.866c.219 0 .392-.173.392-.392v-.774z"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default IDVerificationCard;
