import React, { Component, Fragment } from 'react';
import { connect as felaConnect } from 'react-fela';
import PropTypes from 'prop-types';
import querystring from 'query-string';
import { Link, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import moment from 'moment/moment';
import { AnimatePresence, motion } from 'framer-motion';
import { ErrorBar } from '../../../../common/components';
import {
  Button,
  ButtonLink,
  HistoryLink,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  CircleAvatar,
  Pager,
  Icon,
  Loading,
  SubNavListings,
  TabGroup,
  ButtonPlain,
  ListingStatusForm,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ExpiredAccountUpgradeModal,
  NoContentPlaceholder,
} from '../../../../common/components';
import Stats from './Stats';
import theme from '../../../../theme';
import PremierUpdateModal from '../../../../common/components/PremierUpdateModal';
import getListingLimit from '../../../../common/utils/listingLimits';
import { getMyListings, getListingCount, resetMyListings } from '../../../../redux/actions';
import Boost from '../../../../common/components/Boost';
import BoostIcon from '../../../../common/components/icons/Boost';
import { Helmet } from 'react-helmet';

import PhoneVerificationModal from '../../../VerifyPhone/PhoneVerificationModal';
const TableRowVariants = {
  enter: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.1,
      ease: 'easeOut',
    },
  },
};

class MyListings extends Component {
  static propTypes = {
    listings: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    listingCount: PropTypes.number,
  };

  state = {
    stats: null,
    showPostPremierUpdateModal: false,
    showListingLimitModal: false,
    showExpiredMembershipModal: this.props.userVM.get('isInGracePeriod') || !this.props.userVM.get('isActive'),
    redirectToMembership: this.props.userVM.get('isPassedCutOffPeriod'),
    isVisible: true,
  };

  componentDidMount() {
    const { location, match, dispatch, loaded, getUser, user } = this.props;

    if (!loaded) {
      const search = querystring.parse(location.search);
      [
        getMyListings(match.params.status === 'hold' ? 'on_hold' : match.params.status || 'for_sale', search.page || 0),
        getListingCount(),
      ].forEach(action => dispatch(action));
    }

    if (!user.get('is_approved_seller')) {
      getUser();
    }

    const footer = document.getElementById('fs-sticky-footer');
    if (footer) {
      footer.style.display = 'none';
    }

    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;

    this.props.dispatch(resetMyListings());
  }

  componentDidUpdate(prevProps) {
    const { location, match, dispatch } = this.props;

    if (prevProps.location !== location) {
      const search = querystring.parse(location.search);
      [
        getMyListings(match.params.status === 'hold' ? 'on_hold' : match.params.status || 'for_sale', search.page || 0),
        getListingCount(),
      ].forEach(action => dispatch(action));
    }
  }

  renderGracePeriodModal = () => {
    const { history, userVM } = this.props;
    const { showExpiredMembershipModal } = this.state;

    if (!showExpiredMembershipModal) {
      return null;
    }

    return (
      <ExpiredAccountUpgradeModal
        isOpen={showExpiredMembershipModal}
        onClose={() => this.setState({ showExpiredMembershipModal: false })}
        onUpgrade={() => history.push('/account/membership')}
        daysRemaining={userVM.get('gracePeriodDaysRemaining')}
      />
    );
  };

  updateSection = status => {
    const { history } = this.props;

    switch (status) {
      case 'on_hold':
        history.push('/listings/hold');
        break;
      case 'sold':
        history.push('/listings/sold');
        break;
      default:
        history.push('/listings');
        break;
    }
  };

  navigateToCreateListing = () => {
    const { history, location, userVM } = this.props;

    if (userVM.get('isExpired')) {
      this.setState({ showExpiredMembershipModal: true });
    } else {
      history.push({
        pathname: '/listings/create',
        state: {
          fromPath: location.pathname,
        },
      });
    }
  };

  toggleStatsForId = id => {
    this.setState(state => {
      if (state.stats !== id) {
        return {
          stats: state.stats === id ? null : id,
        };
      }
    });
  };

  closeStats = () => {
    this.setState(state => ({
      stats: null,
    }));
  };

  renderSubNav = () => {
    const { breedType, total, userVM, listings, user } = this.props;

    const linkArray = [
      { route: '/listings', text: 'For Sale' },
      { route: '/listings/hold', text: 'On Hold' },
      { route: '/listings/sold', text: 'Sold' },
    ];

    const userIsPremier = userVM.get('isPremier');

    return (
      <>
        <Boost listings={listings} user={user} />
        <SubNavListings
          setIsVisible={this.setIsVisible}
          onPostAnUpdateClick={() => this.setState({ showPostPremierUpdateModal: true })}
          showPostUpdateLink={userIsPremier}
          titleText={
            total ? (
              <span>
                <strong>{total.toLocaleString()}</strong>{' '}
                {total === 1
                  ? breedType
                    ? `${breedType} puppy`
                    : 'puppy'
                  : breedType
                  ? `${breedType} puppies`
                  : 'puppies'}{' '}
                {'listed'}
              </span>
            ) : (
              'No listings'
            )
          }
          headerRightChildren={<TabGroup buttonwidth={90} linkArray={linkArray} exact={true} />}
        />
      </>
    );
  };

  renderEmailValidationWarning = () => {
    return (
      <ErrorBar
        message="Your listing will not be public until you have confirmed your"
        linkText="email address."
        link="/account/verifications"
      />
    );
  };

  renderMembershipExpiredWarning = () => {
    return <ErrorBar message="You do not have an active membership. Your listings cannot be viewed by other users." />;
  };

  setIsVisible = () => {
    this.setState({ isVisible: !this.isVisible });
  };

  boosted = boost => {
    if (!boost) {
      return null;
    }
    const { styles } = this.props;
    const boostDate = Date.parse(boost);
    const now = Date.now();
    if (boostDate > now) {
      return (
        <div className={styles.boostDiv}>
          <BoostIcon color="#0085F2" />
          {new Date(boost).toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit',
          })}
        </div>
      );
    }
  };

  reLoadListingsAfterBoosting = () => {
    const { location, dispatch } = this.props;

    const search = querystring.parse(location.search);
    dispatch(getMyListings('for_sale', search.page));
  };

  renderListingsTable = () => {
    const { listings, styles, user, userVM, listingMessages, isMobile, location, match, total } = this.props;
    const { stats } = this.state;
    const options = { for_sale: 'For Sale', on_hold: 'On Hold', sold: 'Sold' };
    return (
      <div>
        <Helmet>
          <title>My Listings - Puppies.com</title>
        </Helmet>
        {total && !user.get('email_verified') > 0 ? <div>{this.renderEmailValidationWarning()}</div> : null}
        {!userVM.get('isActive') ? <div>{this.renderMembershipExpiredWarning()}</div> : null}
        <Table>
          {isMobile ? null : (
            <Thead>
              <Tr transparent>
                <Th className={styles.nameHeader}>Name</Th>
                {match.params.status === 'sold' ? <Th>Sold to</Th> : null}
                {match.params.status === 'sold' ? <Th>Sold on</Th> : null}
                <Th>Selling Price</Th>
                <Th>Total Views</Th>
                <Th>New Messages</Th>
                <Th>Boost Status</Th>
                <Th>&nbsp;</Th>
                <Th className={styles.listingStatus}>Listing Status</Th>
              </Tr>
            </Thead>
          )}
          <Tbody>
            <AnimatePresence>
              {listings
                .filter(listing => !listing.get('deleted_at'))
                .map((listing, i) => {
                  let messagesForListing = listingMessages.find(
                    message => message.get('listing_id') === listing.get('id')
                  );
                  if (!messagesForListing) {
                    messagesForListing = fromJS({ messages: [], newestConversationId: null });
                  }
                  const messageCount = messagesForListing.get('messages').size;
                  const photo = listing.getIn(['photos', 0]);
                  return (
                    <motion.tr
                      data-testid="listing-row"
                      key={listing.get('id')}
                      className={styles.tableRow}
                      initial={TableRowVariants.exit}
                      animate={TableRowVariants.enter}
                      exit={TableRowVariants.exit}
                      inherit={false}
                    >
                      {isMobile ? (
                        <Td className={`${styles.flexTd} ${styles.tableDataMobile}`}>
                          <div className={classNames([styles.listingName, styles.listingNameMobile])}>
                            <HistoryLink
                              to={`/listings/${listing.get('id')}`}
                              backTo="Listings"
                              className={styles.historyLinkMobile}
                            >
                              <CircleAvatar size={50} photo={photo} alt={'profile'} placeholderIcon="PuppyCircle" />
                            </HistoryLink>
                            <div className={styles.listingDetailsMobile}>
                              <HistoryLink
                                to={`/listings/${listing.get('id')}`}
                                backTo="Listings"
                                className={styles.historyLinkMobile}
                              >
                                <ButtonPlain className={styles.nameLink}>{listing.get('name')}</ButtonPlain>
                              </HistoryLink>
                              <div className={styles.pendingContainer}>
                                {user.get('is_approved_seller')
                                  ? `$${listing.get('price').toLocaleString()}`
                                  : '(Pending approval)'}
                              </div>
                              {listing.get('sold_at') ? (
                                <span style={{ fontWeight: 'normal' }}>
                                  Sold on: {moment(listing.get('sold_at')).format('MM/DD/YYYY')}
                                </span>
                              ) : null}
                            </div>
                            {listing.get('status') !== 'sold' ? (
                              <div className={styles.editButton}>
                                <ButtonLink
                                  buttonType="round"
                                  iconLeft={<Icon icon="Pencil" size={16} fill={theme.colors.blue} />}
                                  minwidth="71px"
                                  padding="2px 10px"
                                  to={{
                                    pathname: `/listings/${listing.get('id')}/edit`,
                                    state: {
                                      fromPath: location.pathname,
                                    },
                                  }}
                                >
                                  Edit
                                </ButtonLink>
                              </div>
                            ) : null}
                          </div>
                          <div className={styles.mobileListingRowActions}>
                            <Td className={styles.tableData}>
                              <div className={styles.iconButtons}>
                                <div>
                                  <Stats
                                    isPremier={userVM.get('isPremier')}
                                    views={listing.getIn(['stats', 'views'] || 0)}
                                    clicks={listing.getIn(['stats', 'website_clicks'] || 0)}
                                    isOpen={this.state.stats === listing.get('id')}
                                    onClose={() => this.closeStats(listing.get('id'))}
                                    index={i}
                                    currentStats={this.state.stats}
                                    currentId={listing.get('id')}
                                  />
                                  <div
                                    id={listing.get('id')}
                                    onClick={() => this.toggleStatsForId(listing.get('id'))}
                                    className={classNames([
                                      styles.statToggle,
                                      this.state.stats === listing.get('id') && styles.statToggleActive,
                                      stats === listing.get('id') ? styles.statToggleArrow : null,
                                    ])}
                                  >
                                    <Icon icon="Stats" fill="#49372C" className={styles.iconEndMargin} />
                                    {listing.getIn(['stats', 'views']) || 0}
                                  </div>
                                </div>
                                {messagesForListing.get('newestConversationId') ? (
                                  <Link
                                    to={{
                                      pathname: `/messages/inbox/${messagesForListing.get('newestConversationId')}`,
                                      state: { prevLocation: location, backTo: 'My Listings' },
                                    }}
                                    className={styles.envelope}
                                  >
                                    <Icon
                                      icon="Envelope"
                                      className={styles.iconEndMargin}
                                      fill={
                                        messageCount || messagesForListing.get('newestConversationId')
                                          ? '#49372C'
                                          : theme.colors.extraLightBrown
                                      }
                                    />
                                    {messageCount}
                                  </Link>
                                ) : (
                                  <span className={styles.envelope}>
                                    <Icon
                                      className={styles.iconEndMargin}
                                      icon="Envelope"
                                      fill={theme.colors.extraLightBrown}
                                    />
                                    {messageCount}
                                  </span>
                                )}
                                <div className={styles.boostText}>{this.boosted(listing.get('boost'))}</div>
                              </div>
                            </Td>
                            <div className={styles.alignToEnd}>
                              {userVM.get('isNewSeller') ? (
                                options[listing.get('status')]
                              ) : (
                                <ListingStatusForm
                                  listing={listing}
                                  conversations={listing.get('conversations')}
                                  onStatusUpdated={this.updateSection}
                                  userVM={userVM}
                                />
                              )}
                            </div>
                          </div>
                        </Td>
                      ) : (
                        <Fragment>
                          <Td className={styles.tableData}>
                            <div className={styles.listingName}>
                              <HistoryLink to={`/listings/${listing.get('id')}`} backTo="Listings">
                                <CircleAvatar size={64} photo={photo} alt={'profile'} placeholderIcon="PuppyCircle" />
                              </HistoryLink>
                              <HistoryLink
                                to={`/listings/${listing.get('id')}`}
                                backTo="Listings"
                                className={styles.nameLinkWrapper}
                              >
                                <ButtonPlain className={styles.nameLink}>{listing.get('name')}</ButtonPlain>
                              </HistoryLink>
                              <div className={styles.pendingContainer}>
                                {user.get('is_approved_seller') ? null : '(Pending approval)'}
                              </div>
                            </div>
                          </Td>
                          {listing.get('sold_at') ? (
                            <Td className={styles.soldToTable}>
                              {listing.getIn(['buyer', 'first_name'])} {listing.getIn(['buyer', 'last_initial'])}
                            </Td>
                          ) : null}
                          {listing.get('sold_at') ? (
                            <Td>{moment(listing.get('sold_at')).format('MM/DD/YYYY')}</Td>
                          ) : null}
                          <Td className={styles.tableData}>{`$${listing.get('price').toLocaleString()}`}</Td>
                          <Td className={styles.tableData}>
                            <div className={styles.iconButtons}>
                              <div>
                                <Stats
                                  isPremier={userVM.get('isPremier')}
                                  views={listing.getIn(['stats', 'views'] || 0)}
                                  clicks={listing.getIn(['stats', 'website_clicks'] || 0)}
                                  inquiries={listing.getIn(['stats', 'inquiries'] || 0)}
                                  isOpen={this.state.stats === listing.get('id')}
                                  onClose={() => this.closeStats(listing.get('id'))}
                                  index={i}
                                  currentStats={this.state.stats}
                                  currentId={listing.get('id')}
                                />
                                <div
                                  id={listing.get('id')}
                                  onClick={() => this.toggleStatsForId(listing.get('id'))}
                                  className={classNames([
                                    styles.statToggle,
                                    this.state.stats === listing.get('id') && styles.statToggleActive,
                                    stats === listing.get('id') ? styles.statToggleArrow : null,
                                  ])}
                                >
                                  <Icon className={styles.iconEndMargin} icon="Stats" fill="#49372C" />
                                  {listing.getIn(['stats', 'views']) || 0}
                                </div>
                              </div>
                            </div>
                          </Td>
                          <Td className={styles.tableData}>
                            <div className={styles.iconButtons}>
                              {messagesForListing.get('newestConversationId') ? (
                                <Link
                                  to={{
                                    pathname: messagesForListing.get('newestConversationId')
                                      ? `/messages/inbox/${messagesForListing.get('newestConversationId')}`
                                      : '',
                                    state: { prevLocation: location, backTo: 'My Listings' },
                                  }}
                                  className={styles.envelope}
                                >
                                  <Icon
                                    icon="Envelope"
                                    className={styles.iconEndMargin}
                                    fill={
                                      messageCount || messagesForListing.get('newestConversationId')
                                        ? '#49372C'
                                        : theme.colors.extraLightBrown
                                    }
                                  />
                                  {messageCount}
                                </Link>
                              ) : (
                                <span className={styles.envelope}>
                                  <Icon
                                    className={styles.iconEndMargin}
                                    icon="Envelope"
                                    fill={theme.colors.extraLightBrown}
                                  />
                                  {messageCount}
                                </span>
                              )}
                            </div>
                          </Td>
                          <Td className={styles.tableData}>
                            <div className={styles.iconButtons}>
                              <div className={styles.boostText}>{this.boosted(listing.get('boost'))}</div>
                            </div>
                          </Td>
                          <Td className={styles.tableData}>
                            {listing.get('status') !== 'sold' ? (
                              <div className={styles.editButton}>
                                <ButtonLink
                                  buttonType="round"
                                  iconLeft={<Icon icon="Pencil" size={16} fill={theme.colors.blue} />}
                                  minwidth="71px"
                                  padding="2px 10px"
                                  to={{
                                    pathname: `/listings/${listing.get('id')}/edit`,
                                    state: {
                                      fromPath: location.pathname,
                                    },
                                  }}
                                >
                                  Edit
                                </ButtonLink>
                              </div>
                            ) : null}
                          </Td>
                          <Td className={styles.tableData}>
                            {userVM.get('isNewSeller') ? (
                              options[listing.get('status')]
                            ) : (
                              <ListingStatusForm
                                listing={listing}
                                conversations={listing.get('conversations')}
                                onStatusUpdated={this.updateSection}
                                userVM={userVM}
                              />
                            )}
                          </Td>
                        </Fragment>
                      )}
                    </motion.tr>
                  );
                })}
            </AnimatePresence>
          </Tbody>
        </Table>
      </div>
    );
  };

  renderList() {
    const { total, styles, loading, location, listingCount, loaded, userVM } = this.props;
    const atListingLimit = listingCount > getListingLimit(userVM) - 1;
    const actions = [];

    if (!loaded || loading) {
      return <Loading dark center />;
    }

    if (!total) {
      return this.renderNoContentPlaceholder();
    }

    if (userVM.get('isExpired')) {
      actions.push(
        <ButtonLink buttonType="round" to="/account/membership" minwidth="auto">
          Upgrade Now
        </ButtonLink>
      );
    } else if (atListingLimit) {
      actions.push(
        <Button buttonType="roundOrange" onClick={() => this.setState({ showListingLimitModal: true })} minwidth="auto">
          + List New
        </Button>
      );
    } else {
      actions.push(
        <ButtonLink
          buttonType="roundOrange"
          to={{
            pathname: '/listings/create',
            state: {
              fromPath: location.pathname,
            },
          }}
        >
          <span>
            <span style={{ fontSize: '14px' }}>+</span> List New
          </span>
        </ButtonLink>
      );
    }

    return (
      <Fragment>
        <div className={styles.listingsTableWrapper}>{this.renderListingsTable()}</div>
        <Pager pageSize={40} totalItems={total} />
      </Fragment>
    );
  }

  renderNoContentPlaceholder() {
    const {
      match: {
        params: { status },
      },
    } = this.props;
    let placeholderTitle = null;
    switch (status) {
      case 'hold':
        placeholderTitle = 'You have no listings on hold.';
        break;
      case 'sold':
        placeholderTitle = 'You have no sold listings.';
        break;
      default:
        placeholderTitle = 'You have no listings for sale.';
    }
    return (
      <NoContentPlaceholder
        title={placeholderTitle}
        buttonText="List a puppy"
        onClick={this.navigateToCreateListing}
        topMargin
        globalPadding
      />
    );
  }

  trialBanner() {
    const { user, styles } = this.props;
    const trialExpiring = user.get('trial_expires_at') && moment().isBefore(user.get('trial_expires_at'));
    const trialDaysLeft = trialExpiring && moment(user.get('trial_expires_at')).diff(moment(), 'days');
    return (
      <div className={styles.trialBannerContainer}>
        <div className={styles.trialBannerContentContainer}>
          {trialDaysLeft < 1
            ? 'Your trial expires today.'
            : `You have ${trialDaysLeft + 1} days left in your seller trial.`}{' '}
          <Link to="/account/membership" style={{ textDecoration: 'underline' }}>
            Upgrade now
          </Link>{' '}
          to keep all features!
        </div>
      </div>
    );
  }

  render() {
    const { user, history } = this.props;
    const { redirectToMembership } = this.state;

    if (redirectToMembership) {
      return <Redirect to="/account/membership" />;
    }
    return (
      <div>
        {user.get('trial_expires_at') && moment().isBefore(user.get('trial_expires_at')) ? this.trialBanner() : null}
        {!user.get('phone_number_verified') && user.get('email_verified') ? (
          <PhoneVerificationModal history={history} to="/listings" />
        ) : null}
        {this.renderSubNav()}
        {this.renderList()}
        {this.renderGracePeriodModal()}
        <PremierUpdateModal
          isOpen={this.state.showPostPremierUpdateModal}
          onClose={() => this.setState({ showPostPremierUpdateModal: false })}
          premierSlug={user.getIn(['premierProfile', 'slug'])}
          currentUpdate={
            user.getIn(['premierProfile', 'latestUpdate', 'deleted_at'])
              ? null
              : user.getIn(['premierProfile', 'latestUpdate'])
          }
        />
        <Modal
          isOpen={this.state.showListingLimitModal}
          onClose={() => this.setState({ showListingLimitModal: false })}
          closeOnOutsideClick
          closeOnEscape
        >
          <ModalHeader title="Listing Limit Reached" />
          <ModalBody>You have reached the listing limit. Sell or delete listings to create more.</ModalBody>
          <ModalFooter
            actions={[
              <ButtonPlain onClick={() => this.setState({ showListingLimitModal: false })}>Close</ButtonPlain>,
              <Button type="button" buttonType="round" onClick={() => history.push('/account/membership')}>
                LEARN MORE
              </Button>,
            ]}
          />
        </Modal>
      </div>
    );
  }
}

const styles = props => ({
  flexTd: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100% !important',
  },
  alignToEnd: {
    alignSelf: 'flex-end',
  },
  mobileListingRowActions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    borderBottom: '1px solid #dbd9d2',
    '& > td': {
      padding: 0,
    },
    '& > * + *': {
      marginLeft: '10px',
    },
  },
  headerWrapper: {
    marginTop: '16px',
    [props.theme.breakpoints.mobile]: {
      marginTop: '24px',
    },
  },
  iconButtons: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  iconEndMargin: {
    marginRight: '4px',
  },
  statToggle: {
    cursor: 'pointer',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    ':hover': {
      '> span': {
        fill: '#49372C',
      },
    },
  },
  statToggleActive: {
    '> span': {
      fill: '#49372C',
    },
  },
  statToggleArrow: {
    ':before': {
      cursor: 'initial',
      content: '" "',
      borderColor: `transparent transparent ${props.theme.colors.blue}`,
      borderWidth: '0px 18px 22px',
      borderStyle: 'solid',
      height: 0,
      width: 0,
      top: '31px',
      right: '-6px',
      position: 'absolute',
      zIndex: 1,
    },
  },
  envelope: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    alignItems: 'center',
    ':hover': {
      '> span': {
        fill: '#49372C',
      },
    },
  },
  go: {
    ':hover': {
      '> span': {
        fill: props.theme.colors.darkerBlueHover,
      },
    },
  },
  listingsTableWrapper: {
    marginBottom: '50px',
  },
  editButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
  },
  listingStatus: {
    width: '140px',
  },
  listingName: {
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-all',
    '> * + *': {
      marginLeft: '10px',
    },
    [props.theme.breakpoints.mobileLarge]: {
      '> * + *': {
        marginLeft: '24px',
      },
    },
    [props.theme.browsers.ie11]: {
      maxWidth: '216px',
    },
  },
  listingNameMobile: {
    padding: '10px',
  },
  nameHeader: {
    paddingLeft: '110px !important',
  },
  tableData: {
    '*': {
      fontSize: '14px',
    },
    // width: '20%',
    // ':first-of-type': {
    //   width: '25%',
    // },
    // ':last-of-type': {
    //   width: '15%',
    // },
  },
  tableDataMobile: {
    padding: 0,
    flexDirection: 'column',
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
    },
  },
  tableRow: {
    backgroundColor: props.theme.colors.white,
    '&:nth-child(odd)': {
      backgroundColor: props.theme.colors.lightTan,
    },
  },
  historyLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
  },
  historyLinkMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0',
    fontSize: '14px',
  },
  listingDetailsMobile: {
    fontSize: '12px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '> * + *': {
      marginTop: '4px',
    },
  },
  nameLinkWrapper: {
    width: '100%',
  },
  nameLink: {
    maxWidth: '100%',
    minWidth: '120px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pendingContainer: {
    whiteSpace: 'nowrap',
    fontSize: '14px',
  },
  soldToTable: {
    minWidth: '160px',
  },
  trialBannerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: props.theme.colors.brown,
    padding: '12px 0',
    zIndex: 2,
  },
  trialBannerContentContainer: {
    maxWidth: props.theme.globalMaxWidth,
    width: '100%',
    padding: '0 10px',
    fontSize: '14px',
    color: props.theme.colors.white,
    fontWeight: props.theme.typography.sansBold,
    textAlign: 'center',

    '> a': {
      color: props.theme.colors.blue,
    },
    [props.theme.breakpoints.mobile]: {
      padding: '0 20px',
      textAlign: 'left',
    },
  },
  boostText: {
    color: props.theme.colors.boostBlue,
  },
  boostDiv: {
    display: 'flex',
    gap: '5px;',
  },
  phoneModalContentContainer: {
    width: '342px',
    margin: '50px auto',
  },
  phoneModalHeader: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#232323',
    margin: '20px 0',
  },
  phoneModalContent: {
    color: '#232323',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  verify: {
    margin: '20px 0',
  },
});

export default felaConnect(styles)(MyListings);
