import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { Loading } from '../../common/components';

export default function requireAuth(
  FallbackComponent,
  options = {
    customVerify: () => true,
  }
) {
  return function(ComposedComponent) {
    class Authenticated extends Component {
      static propTypes = {
        location: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
      };

      render() {
        const { state, ...passthruProps } = this.props;
        const hasToken = Boolean(state.token);

        if (hasToken) {
          const userDataNotYetLoaded = !state.user || state.userVM.isEmpty();
          if (userDataNotYetLoaded) {
            return <Loading />;
          }

          const isPassingCustomVerification = options.customVerify(state);
          if (isPassingCustomVerification) {
            return <ComposedComponent {...passthruProps} user={state.user} />;
          }
        }

        if (FallbackComponent) {
          return <FallbackComponent {...passthruProps} user={state.user} />;
        }

        return (
          <Redirect
            to={{
              pathname: '/sign-in',
              search: `?from=${this.props.location.pathname + this.props.location.search}`,
              prevLocation: this.props.location.pathname,
              state: this.props.location.state || null,
            }}
          />
        );
      }
    }

    hoistNonReactStatic(Authenticated, ComposedComponent);

    return connect(state => ({ state }))(Authenticated);
  };
}
