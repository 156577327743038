import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect as felaConnect } from 'react-fela';
import { Button, Checkbox, InputError, Icon, SubNav } from '../../../../common/components/';

import { withFormik, Field } from 'formik';
import theme from '../../../../theme';
import { Helmet } from 'react-helmet';
class NotificationSettings extends Component {
  render() {
    const { isSubmitting, submitForm, status, styles, userVM } = this.props;

    const buttonCopy = isSubmitting ? 'SAVING...' : 'SAVE CHANGES';
    const isNonMember = !userVM.get('isMember');
    return (
      <div className={styles.root}>
        <Helmet>
          <title>Notification Settings - Puppies.com</title>
        </Helmet>
        <SubNav titleText="Notification Settings" />
        <div className={styles.wrapper}>
          <div className={styles.checkboxes}>
            <h1>Notify me by email when...</h1>
            <Field
              label="Someone sends me a new message"
              name="message_notifications"
              component={Checkbox}
              labelWrapperClass={styles.labelWrapper}
            />
            <Field
              label="New listings match my saved searches"
              name="saved_search_notifications"
              component={Checkbox}
              labelWrapperClass={styles.labelWrapper}
            />
            <Field
              label="Receive emails about new site features and general updates"
              name="marketing_notifications"
              component={Checkbox}
              labelWrapperClass={styles.labelWrapper}
            />
            {!isNonMember ? (
              <Field
                label="I have new Weekly Seller Stats"
                name="seller_stats_notifications"
                component={Checkbox}
                labelWrapperClass={styles.labelWrapper}
              />
            ) : null}
          </div>
          <div className={styles.buttonRow}>
            {status && status.error && <InputError>{status.error}</InputError>}
            {status && status.success ? (
              <div className={styles.submitButtonWrapper}>
                <Button
                  style={{ backgroundColor: theme.colors.green }}
                  buttonType="roundOrange"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => submitForm()}
                >
                  CHANGES SAVED
                </Button>
                <Icon icon="FatCheck" fill={theme.colors.green} />
              </div>
            ) : (
              <Button
                type="button"
                disabled={isSubmitting}
                isSubmitting={isSubmitting}
                buttonType="roundOrange"
                onClick={() => submitForm()}
              >
                {buttonCopy}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const FormikNotificationSettings = withFormik({
  mapPropsToValues: props => {
    const user = props.user;

    if (!isEmpty(props.formValues)) {
      return {
        ...props.formValues,
      };
    }

    return {
      message_notifications:
        user && user.get('emailPreferences') ? user.getIn(['emailPreferences', 'message_notifications']) : true,
      verification_notifications:
        user && user.get('emailPreferences') ? user.getIn(['emailPreferences', 'verification_notifications']) : true,
      saved_search_notifications:
        user && user.get('emailPreferences') ? user.getIn(['emailPreferences', 'saved_search_notifications']) : true,
      seller_stats_notifications:
        user && user.get('emailPreferences') ? user.getIn(['emailPreferences', 'seller_stats_notifications']) : true,
      marketing_notifications:
        user && user.get('emailPreferences') ? user.getIn(['emailPreferences', 'marketing_notifications']) : true,
    };
  },
  enableReinitialize: true,

  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    setSubmitting(true);
    setStatus({ success: false });

    const action = await props.updateNotificationSettings(values);
    setSubmitting(false);
    if (!action.response.ok) {
      setStatus({
        error: action.json ? action.json.message : 'An unknown error occurred',
      });
    } else {
      setStatus({ success: 'CHANGES SAVED' });
    }
  },
})(NotificationSettings);

const styles = props => ({
  root: {
    ...props.theme.globalBackgroundContainer,
  },
  wrapper: {
    ...props.theme.globalPageWrapper,
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'column',
    '> h1': {
      fontWeight: props.theme.typography.sansBold,
      color: props.theme.colors.orange,
      marginBottom: '24px',
    },
    '> div': {
      display: 'inline-flex',
      marginLeft: '-8px',
    },
  },
  buttonRow: {
    marginTop: '50px',
    paddingTop: '30px',
    borderTop: props.theme.globalDashedBorder,
  },
  labelWrapper: {
    '> span': {
      fontSize: '14px',
      color: props.theme.colors.brown,
      textTransform: 'none',
    },
  },
  submitButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: '16px',
    },
  },
});

export default felaConnect(styles)(FormikNotificationSettings);
