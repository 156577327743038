import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import VerifyPhone from './VerifyPhone';
import { connect as felaConnect } from 'react-fela';
import compose from 'lodash/flowRight';
import styles from './styles';
import { sendPhoneVerification, confirmPhoneVerification } from '../../redux/actions';

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendPhoneVerification,
      confirmPhoneVerification,
    },
    dispatch
  );
export default compose(felaConnect(styles), connect(mapStateToProps, mapDispatchToProps), withRouter)(VerifyPhone);
