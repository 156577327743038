import React from 'react';

const MessageTimestamp = ({ styles, message }) => {
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const timeOptions = { hour: 'numeric', minute: 'numeric' };
  const messageTime = new Date(message.get('created_at'));
  const date = new Intl.DateTimeFormat('en-US', dateOptions).format(messageTime);
  const time = new Intl.DateTimeFormat('en-US', timeOptions).format(messageTime);
  return (
    <div className={styles.timestamp}>
      {date}
      {' - '}
      {time}
    </div>
  );
};

export default MessageTimestamp;
