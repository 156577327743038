import React from 'react';
import classNames from 'classnames';
import MessageTimestamp from './MessageTimestamp';
import MessagePhotos from './MessagePhotos';
const OwnMessage = ({ message, styles, deleteMessagePhoto }) => {
  return (
    <div
      key={message.get('id')}
      className={classNames(styles.messageWrapper, styles.yourMessage)}
      aria-label="Your message"
      role="log"
    >
      {message.get('body') !== 'NO_CONTENT' ? (
        <div className={styles.innerMessageWrapper}>
          <div className={classNames(styles.message, styles.yourSentMessage)}>
            <pre style={{ whiteSpace: 'pre-line' }}>{message.get('body')}</pre>
          </div>
        </div>
      ) : null}
      <div className={styles.messagePhotosContainer}>
        {message.get('photos') ? (
          <MessagePhotos
            styles={styles}
            messageId={message.get('id')}
            photos={message.get('photos')}
            deleteMessagePhoto={deleteMessagePhoto}
            isOwner={true}
            canDelete={false}
          />
        ) : null}
      </div>
      <MessageTimestamp styles={styles} message={message} />
    </div>
  );
};

export default OwnMessage;
