import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import hoistNonReactStatic from 'hoist-non-react-statics';

export default function redirectAuth(redirectLocation) {
  return function(ComposedComponent) {
    class RedirectAuthenticated extends Component {
      static propTypes = {
        isAuthenticated: PropTypes.bool,
      };

      render() {
        const { isAuthenticated } = this.props;
        return isAuthenticated ? <Redirect to={redirectLocation} /> : <ComposedComponent {...this.props} />;
      }
    }

    const mapStateToProps = state => ({
      isAuthenticated: !!state.user,
    });

    hoistNonReactStatic(RedirectAuthenticated, ComposedComponent);

    return connect(mapStateToProps)(RedirectAuthenticated);
  };
}
