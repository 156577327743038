import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import * as yup from 'yup';
import { withFormik, Field } from 'formik';
import debounce from 'lodash/debounce';
import compose from 'lodash/flowRight';
import cloneDeep from 'lodash/cloneDeep';
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';
import { phoneNumberSchema, formatPhoneNumber } from '../../../../common/components/PhoneValidation';
import { updateBreedsAutocomplete, resetBreedsAutocomplete } from '../../../../redux/actions';
import {
  Autocomplete,
  Chip,
  Button,
  InputError,
  StandaloneField,
  Textarea,
  TextInput,
  Modal,
  ModalBody,
  PhotoGalleryUploader,
  Icon,
  FormErrors,
} from '../../../../common/components';
import theme from '../../../../theme';
import handleMultipleUploads from '../../../../common/utils/uploads/handle-multi-uploads';

const prependHttp = url => {
  const re = new RegExp('^(http|https)://', 'i');
  return re.test(url) ? url : 'http://' + url;
};

const MAX_BREEDS = 4;

const photoGalleryHeadingText = (
  <span>
    <strong>Facility photos.</strong> Show your facilities and grounds, let people see how well your puppies were
    raised!
  </span>
);

class PuppyFindMigrationPremiers extends Component {
  state = {
    showPaymentModal: false,
    showMigrationInfoModal: true,
  };

  componentDidMount() {
    const { user } = this.props;
    if (!user.getIn(['historicalUserRequirements', 'needs_premier_profile'])) {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetBreedsAutocomplete());
  }

  updateAutocomplete = debounce(value => {
    if (value) {
      this.props.dispatch(updateBreedsAutocomplete(value));
    } else {
      this.props.dispatch(resetBreedsAutocomplete());
    }
  }, 250);

  handleBreedSelect = breed => {
    const { setFieldValue, values, dispatch } = this.props;
    setFieldValue('breeds_available', values.breeds_available.concat(breed.id));
    setFieldValue('formatted_breeds', values.formatted_breeds.concat(breed.label));
    dispatch(resetBreedsAutocomplete());
  };

  handleInputChange = value => {
    this.updateAutocomplete(value);
  };

  handleBreedRemove = id => {
    const { setFieldValue, values } = this.props;

    const index = values.breeds_available.findIndex(breed => breed === id);

    const remove = (arr, index) => {
      return arr.slice(0, index).concat(arr.slice(index + 1));
    };

    setFieldValue('breeds_available', remove(values.breeds_available, index));
    setFieldValue('formatted_breeds', remove(values.formatted_breeds, index));
  };

  renderBreedChips = () => {
    const { values } = this.props;
    return values.formatted_breeds.map((breed, i) => (
      <Chip id={values.breeds_available[i]} onRemove={this.handleBreedRemove} key={breed} variant="secondary">
        {breed}
      </Chip>
    ));
  };

  renderMigrationInfoModal = () => {
    const { styles } = this.props;
    return (
      <Modal
        closeOnOutsideClick
        isOpen={this.state.showMigrationInfoModal}
        onClose={() => this.setState({ showMigrationInfoModal: false })}
      >
        <ModalBody>
          <div className={styles.modalBody}>
            <div className={styles.modalLogoWrapper}>
              <Icon icon="PuppyLogoWithText" className={styles.puppyLogoText} />
            </div>
            <h3 className={styles.modalHeader}>You've got a Premier Membership!</h3>
            <p>
              As a monthly PuppyFind subscriber, you've been upgraded to a Premier Membership on Puppies.com. You now
              have more features for the <strong>same price</strong>. As a Premier member, you'll have a dedicated
              homepage, so we'll need you to confirm a few things on this page. Your payment information has been
              securely transferred and will not need to be updated.
            </p>
          </div>
        </ModalBody>
        <div className={styles.modalFooter}>
          <Button buttonType="round" onClick={() => this.setState({ showMigrationInfoModal: false })}>
            Close
          </Button>
        </div>
      </Modal>
    );
  };

  handleChangeSlug = e => {
    const { setFieldValue } = this.props;
    setFieldValue('slug', e.target.value.toLowerCase());
  };

  handleSubmitInteraction = async (values, setSubmitting, setFieldError) => {
    const slugTaken =
      values.slug !== this.props.initialValues.slug &&
      (await this.props.getPremierBySlug(values.slug).then(action => action.response.status === 200));
    if (slugTaken) {
      setSubmitting(false);
      return setFieldError('slug', 'Homepage name is already in use');
    }

    this.props.submitForm();
  };

  render() {
    const {
      handleBlur,
      handleSubmit,
      isSubmitting,
      status,
      values,
      breeds,
      styles,
      touched,
      errors,
      setFieldError,
      setFieldValue,
      setSubmitting,
      profile,
    } = this.props;

    let buttonCopy = '';

    if (isSubmitting) {
      buttonCopy = 'SAVING...';
    } else if (status && status.success) {
      buttonCopy = 'CHANGES SAVED';
    } else if (!this.props.fromMembership) {
      buttonCopy = 'SAVE CHANGES';
    } else {
      buttonCopy = 'CONTINUE';
    }

    return (
      <form className={styles.migrationPremiersForm} onSubmit={handleSubmit}>
        <div className={styles.goldBar}>
          <span>Enter your Premier Profile (kennel) information below.</span>
        </div>
        <div className={styles.formInner}>
          <h1 className={styles.title}>Premier Profile Information</h1>
          <div className={styles.formWrapper}>
            <div className={styles.formTopRow}>
              <div className={styles.businessName}>
                <Field
                  label="Kennel/Breeder Name"
                  name="name"
                  component={TextInput}
                  placeholder="Example: West Valley Greyhounds"
                  required
                />
              </div>
              <div className={styles.businessWebsite}>
                <Field label="Website" name="website" component={TextInput} />
              </div>
            </div>
            <div className={styles.formTopRow}>
              <div className={styles.businessPhone}>
                <Field
                  label="Phone"
                  name="phone_formatted"
                  component={TextInput}
                  className={touched.phone_formatted && errors.phone ? styles.inputError : null}
                  onChange={e => {
                    setFieldValue('phone_formatted', formatPhoneNumber(e.target.value));
                    setFieldValue('phone', formatPhoneNumber(e.target.value).replace(/-/g, ''));
                  }}
                />
                <InputError>{touched.phone_formatted && errors.phone}</InputError>
              </div>
            </div>
            <div className={styles.businessURL}>
              <div>
                <StandaloneField
                  label="Puppies.com Homepage Name"
                  name="slug"
                  component={TextInput}
                  onChange={this.handleChangeSlug}
                  value={values.slug}
                  prefix="puppies.com/premier/"
                  prefixPadding={158}
                  onBlur={handleBlur}
                  className={errors && touched.slug && errors.slug ? styles.inputError : null}
                />
                <InputError>{errors && touched.slug && errors.slug}</InputError>
              </div>
              <div
                className={styles.noSpaces}
                style={{ marginBottom: errors && touched.slug && errors.slug ? '28px' : '8px' }}
              >
                No spaces or punctuation
              </div>
            </div>
            <div className={styles.breedsAvailable}>
              <div className={styles.breedsAvailableInput}>
                <Field
                  name="breeds_available"
                  render={fieldProps => {
                    return (
                      <Autocomplete
                        key={values.breeds_available.join(',')}
                        label="Breeds Available"
                        disabled={values.formatted_breeds.length >= MAX_BREEDS}
                        placeholder={
                          values.formatted_breeds.length >= MAX_BREEDS ? 'Up to four breeds allowed' : 'Type a breed'
                        }
                        items={breeds.filter(breed => !values.breeds_available.includes(breed.id))}
                        onInputChange={this.handleInputChange}
                        onSelect={this.handleBreedSelect}
                        {...fieldProps}
                      />
                    );
                  }}
                />
              </div>
              <div className={styles.breedChips}>{this.renderBreedChips()}</div>
            </div>
            <div className={styles.facilityDescription}>
              <div className={styles.facilityDescriptionInput}>
                <Field
                  label="Facility Description"
                  name="facility_description"
                  component={Textarea}
                  className={styles.textarea}
                  placeholder={'Describe your facilities, health standards, play areas, etc...'}
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.galleryUploaderWrapper}>
          <div className={styles.galleryUploaderInner}>
            <PhotoGalleryUploader
              status={status}
              setSubmitting={setSubmitting}
              setFieldValue={setFieldValue}
              photos={profile ? profile.get('photos') : List()}
              userPhotos={values.photos}
              imagesToDelete={values.imagesToDelete}
              headingText={photoGalleryHeadingText}
            />
          </div>
        </div>
        <div className={styles.submitButtonWrapper}>
          <div className={styles.submitButtonWrapperInner}>
            <FormErrors errors={errors} />
            {status && status.error && <InputError>{status.error}</InputError>}
            {this.renderMigrationInfoModal()}

            <div className={styles.buttonWithIndicator}>
              <Button
                style={{ backgroundColor: status && status.success ? theme.colors.green : null }}
                type="button"
                disabled={isSubmitting}
                isSubmitting={isSubmitting}
                buttonType="roundOrange"
                onClick={this.handleSubmitInteraction.bind(this, values, setSubmitting, setFieldError)}
              >
                {buttonCopy}
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const FormikPuppyFindMigrationPremiers = withFormik({
  mapPropsToValues: props => {
    const { profile, user } = props;

    return {
      id: profile ? profile.get('id') : undefined,
      name: profile ? profile.get('name') : '',
      website: profile ? profile.get('website') : '',
      phone: profile ? profile.get('phone') : '',
      phone_country_code: profile ? profile.get('phone_country_code') || '+1' : '+1',
      phone_formatted: profile ? formatPhoneNumber(profile.get('phone')) : '',
      slug: profile ? profile.get('slug') : '',
      facility_description: profile?.get('facility_description') || user?.get('bio') || '',
      photos: profile
        ? profile
            .get('photos')
            .map(photo => ({ id: photo.get('id') }))
            .toJS()
        : [],
      imagesToDelete: [],
      breeds_available: profile
        ? profile
            .get('breeds')
            .map(breed => breed.get('id'))
            .toJS()
        : [],
      formatted_breeds: profile
        ? profile
            .get('breeds')
            .map(breed => breed.get('name'))
            .toJS()
        : [],
    };
  },

  enableReinitialize: true,

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(60, 'Name must be 60 characters or less')
      .required('Kennel/Breeder name is required'),
    breeds_available: yup.array().max(MAX_BREEDS),
    website: yup
      .string()
      .transform((currentValue, originalValue) => {
        return prependHttp(originalValue);
      })
      .url('Must be a valid URL')
      .trim()
      .max('200', 'Must be 200 characters or less'),
    phone: phoneNumberSchema,
    slug: yup
      .string()
      .matches(/^[a-z0-9-_]+$/, {
        message: 'Invalid Characters',
      })
      .trim()
      .min(2, 'Homepage name must be at least 2 characters')
      .max(50, 'Homepage name must be less than 50 characters'),
    facility_description: yup
      .string()
      .max(5000, 'Description must be less than 5000 characters')
      .required('Facility description is required'),
    imagesToDelete: yup.array(),
  }),

  handleSubmit: async (values, { props, setFieldError, setSubmitting, setStatus, setFieldValue, setErrors }) => {
    // eslint-disable-next-line
    const { formatted_breeds, ...restValues } = values;

    const submitValues = cloneDeep(restValues);

    delete submitValues.phone_formatted;

    if (submitValues.slug === '') {
      submitValues.slug = props.user.get('id');
    }

    if (submitValues.website) {
      const match = /([^.;+_]+)$/.exec(submitValues.website),
        result = match && match[1];
      if (!result) {
        setSubmitting(false);
        return setFieldError('website', 'This URL is invalid');
      }
      const tld =
        (result.includes('?') && result.substr(0, result.lastIndexOf('?'))) ||
        (result.includes('/') && result.substr(0, result.lastIndexOf('/'))) ||
        result;
      if (tld.length < 2) {
        setSubmitting(false);
        return setFieldError('website', 'This URL is invalid');
      }

      const re = new RegExp('^(http|https)://', 'i');
      submitValues.website = re.test(submitValues.website) ? submitValues.website : 'http://' + submitValues.website;
      setFieldValue('website', submitValues.website);
    }

    await handleMultipleUploads(submitValues.photos, props.getUploadToken, `${props.user.get('id')}/facility`);

    submitValues.photos = submitValues.photos.map(({ type, ...rest }) => rest); // Rm type property

    if (values.imagesToDelete) {
      values.imagesToDelete.map(photo => {
        return props.deletePremierProfileImage(photo, submitValues.id);
      });

      delete submitValues.imagesToDelete;
      setFieldValue('imagesToDelete', []);
    }

    setStatus({});
    const actionFunction = submitValues.id ? props.editPremierProfile : props.createPremierProfile;
    const action = await actionFunction(submitValues);
    setSubmitting(false);
    if (!action.response.ok) {
      if (action?.json?.validationErrors) {
        const errors = action.json.validationErrors.reduce((c, e) => {
          return {
            ...c,
            [e.key]: e.message,
          };
        }, {});

        setErrors(errors);
      } else {
        props.setErrorBarMessage(action.json ? action.json.message : 'An unknown error occurred');
      }
    } else {
      setStatus({ success: true });
      props.history.push(`/account/puppyfind-complete`);
    }
  },
})(PuppyFindMigrationPremiers);

const styles = (props, touched, errors) => ({
  title: {
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.orange,
    marginBottom: '28px',
    textAlign: 'center',
  },
  formWrapper: {
    width: '100%',
    maxWidth: '884px',
    margin: '0 auto',
    '> * + *': {
      marginTop: '32px',
    },
  },
  breedChips: {
    '> *': {
      margin: '8px 8px 0 0',
    },
    [props.theme.breakpoints.mobile]: {
      marginLeft: '18px',
      marginTop: '10px',
      display: 'inline-flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  },
  formTopRow: {
    display: 'flex',
    flexDirection: 'column',
    '> * + *': {
      marginTop: '34px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      '> * + *': {
        marginTop: 0,
        marginLeft: '20px',
      },
    },
  },
  businessName: {
    [props.theme.breakpoints.mobile]: {
      width: '420px',
    },
  },
  businessPhone: {
    [props.theme.breakpoints.mobile]: {
      width: '160px',
    },
  },
  businessWebsite: {
    [props.theme.breakpoints.mobile]: {
      width: '250px',
    },
  },
  businessURL: {
    width: '100%',
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    '@media (max-width: 540px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  noSpaces: {
    color: props.theme.colors.brown,
    opacity: 0.75,
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginLeft: '16px',
    '@media (max-width: 540px)': {
      whiteSpace: 'normal',
      margin: '16px 0 0 !important',
    },
  },
  breedsAvailable: {
    [props.theme.breakpoints.mobile]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  breedsAvailableInput: {
    [props.theme.breakpoints.mobile]: {
      width: '250px',
      flexShrink: 0,
    },
  },
  facilityDescription: {
    [props.theme.breakpoints.mobile]: {
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  facilityDescriptionInput: {
    width: '100%',
  },
  facilityDescriptionInfo: {
    fontSize: '14px',
    margin: '20px 0',
    '> * + *': {
      marginTop: '20px',
    },
    '> ul': {
      '> * + *': {
        marginTop: '5px',
      },
    },
    [props.theme.breakpoints.mobile]: {
      marginLeft: '20px',
      marginBottom: '20px',
      margin: '0 0 20px 20px',
    },
  },
  textarea: {
    height: '160px',
  },
  inputError: {
    color: props.theme.colors.red,
    border: `1px solid ${props.theme.colors.red} !important`,
    boxShadow: `0px 0px 0px 1px ${props.theme.colors.red} !important`,
    ':focus': {
      outline: 'none', // Disable default focus glow
      borderColor: `${props.theme.colors.red} !important`,
    },
  },
  submitButtonWrapper: {
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    '& > * + *': {
      marginLeft: '16px',
    },
  },
  submitButtonWrapperInner: {
    width: '100%',
    maxWidth: '884px',
    margin: '0 auto',
    padding: '72px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: props.theme.globalDashedBorder,
  },
  paymentModal: {
    borderRadius: '6px !important',
  },
  profileLink: {
    marginLeft: '10px',
  },
  buttonWithIndicator: {
    display: 'flex',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '16px',
    },
  },
  formInner: {
    width: '100%',
    padding: '0 16px',
  },
  migrationPremiersForm: {
    width: '100%',
    backgroundColor: props.theme.colors.tan,
  },
  goldBar: {
    backgroundColor: props.theme.colors.yellow,
    padding: '10px 16px 16px',
    textAlign: 'center',
    fontWeight: props.theme.typography.sansBold,
    marginBottom: '32px',
  },
  galleryUploaderWrapper: {
    width: '100%',
    padding: '0 16px',
  },
  galleryUploaderInner: {
    width: '100%',
    maxWidth: '884px',
    margin: '0 auto',
  },
  whyWeNeedThis: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '48px',
    '> button': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      '> * + *': {
        marginLeft: '4px',
      },
    },
  },
  modalLogoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '175px',
    margin: '0 auto 32px',
    paddingBottom: '32px',
    borderBottom: `6px solid ${props.theme.colors.yellow}`,
  },
  puppyLogoText: {
    width: '224px !important',
    height: '44px !important',
  },
  modalBody: {
    fontSize: '16px',
  },
  modalHeader: {
    fontSize: '18px',
    fontWeight: props.theme.typography.sansBold,
    marginBottom: '16px',
  },
  modalFooter: {
    borderRadius: '0 0 6px 6px',
    width: '100%',
    backgroundColor: props.theme.colors.darkerTan,
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default compose(felaConnect(styles), withRouter)(FormikPuppyFindMigrationPremiers);
