import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import hasTouch from '../../utils/hasTouch';

import { InputScaffold } from '../';

class ColorSquare extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    hex: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    styles: PropTypes.object.isRequired,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
      setFieldValue: PropTypes.func.isRequired,
    }),
    label: PropTypes.node,
    labelVariant: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    multiselect: PropTypes.bool,
    maxSelections: PropTypes.number,
  };

  static defaultProps = {
    multiselect: false,
  };

  handleClick = e => {
    const { field, form, value, multiselect, maxSelections } = this.props;
    e.currentTarget.blur();
    if (multiselect) {
      const newValue = field.value.includes(value)
        ? form.values[field.name].filter(arrValue => arrValue !== value)
        : !maxSelections || field.value.length < maxSelections
        ? field.value.concat(value)
        : field.value;
      form.setFieldValue(field.name, newValue);
    } else {
      form.values[field.name] === value ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, value);
    }
  };

  render() {
    const {
      multiselect,
      field,
      form,
      styles,
      disabled,
      label,
      labelVariant,
      maxSelections, // included here to exclude it from passableProps
      className,
      ...passableProps
    } = this.props;

    return (
      <InputScaffold label={label} labelVariant={labelVariant} disabled={disabled}>
        <button
          className={styles.square + ' ' + className}
          onClick={this.handleClick}
          onBlur={field.onBlur}
          disabled={disabled}
          name={field.name}
          type="button"
          {...passableProps}
        />
      </InputScaffold>
    );
  }
}

const styles = props => {
  const border = () => {
    if (props.multiselect) {
      return props.form.values[props.field.name].includes(props.value)
        ? `1px solid ${props.theme.colors.blue}`
        : '1px solid #cac1b6';
    } else {
      return props.form.values[props.field.name] === props.value
        ? `1px solid ${props.theme.colors.blue}`
        : '1px solid #cac1b6';
    }
  };
  const shadow = () => {
    if (props.multiselect) {
      return props.form.values[props.field.name].includes(props.value) ? `0 0 0 3px ${props.theme.colors.blue}` : null;
    } else {
      return props.form.values[props.field.name] === props.value ? `0 0 0 3px ${props.theme.colors.blue}` : null;
    }
  };
  return {
    square: {
      width: props.width ? `${props.width}px` : '100%',
      height: `${props.height}px`,
      backgroundColor: `#${props.hex}`,
      border: border(),
      boxShadow: shadow(),
      transition: props.theme.globalTransition,
      borderRadius: props.theme.globalBorderRadius,
      ':hover': {
        border: hasTouch() ? null : `1px solid ${props.theme.colors.blue}`,
        boxShadow: hasTouch() ? null : `0 0 0 3px ${props.theme.colors.blue}`,
      },
      ':active': {
        outline: 'none',
      },
    },
  };
};

export default connect(styles)(ColorSquare);
