import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';

import { InputError, Label } from '../';
import classNames from 'classnames';

class TwoButtonToggle extends Component {
  state = {
    isPrimaryHovered: false,
    isSecondaryHovered: false,
  };
  static propTypes = {
    primaryText: PropTypes.node.isRequired,
    secondaryText: PropTypes.node.isRequired,
    styles: PropTypes.object.isRequired,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
      setFieldValue: PropTypes.func.isRequired,
    }),
    label: PropTypes.node,
    labelVariant: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    primaryValue: PropTypes.any.isRequired,
    secondaryValue: PropTypes.any.isRequired,
  };

  handleClick = (val, e) => {
    const { field, form } = this.props;
    e.preventDefault();
    e.target.blur();
    form.setFieldValue(field.name, val);
  };

  render() {
    const {
      field,
      form,
      styles,
      disabled,
      label,
      labelVariant,
      primaryText,
      secondaryText,
      primaryValue,
      secondaryValue,
      primaryOnClick,
      secondaryOnClick,
      fixederror,
      required,
      ...passableProps
    } = this.props;

    const error = form.touched[field.name] && form.errors[field.name];

    return (
      <div>
        <div className={styles.label}>
          <Label variant={labelVariant} disabled={disabled}>
            {required ? label + ' *' : label}
          </Label>
        </div>
        <div className={styles.buttonWrapper}>
          <button
            className={classNames(styles.button, styles.primaryButton)}
            onClick={primaryOnClick || this.handleClick.bind(this, primaryValue)}
            onBlur={field.onBlur}
            disabled={disabled}
            name={field.name}
            type="button"
            {...passableProps}
          >
            {primaryText}
          </button>
          <button
            onClick={secondaryOnClick || this.handleClick.bind(this, secondaryValue)}
            className={classNames(styles.button, styles.secondaryButton)}
            onBlur={field.onBlur}
            disabled={disabled}
            name={field.name}
            type="button"
            {...passableProps}
          >
            {secondaryText}
          </button>
        </div>
        <InputError fixederror={fixederror}>{error}</InputError>
      </div>
    );
  }
}

const styles = props => ({
  button: {
    display: 'inline-block',
    padding: '8px 16px',
    minWidth: '80px',
    height: '42px',
    borderRadius: '2px',
    opacity: props.disabled ? 0.5 : 1,
    fontFamily: props.theme.typography.sans,
    fontWeight: props.theme.typography.sansBold,
    fontSize: 12,
    textTransform: 'uppercase',
    cursor: props.disabled ? 'not-allowed' : null,
    transition: props.theme.globalTransition,
    width: '50%',
    [props.theme.breakpoints.mobile]: {
      width: 'initial',
    },
    ':active': {
      outline: 'none',
    },
    ':hover': {
      backgroundColor: props.theme.colors.green,
      color: props.theme.colors.white,
    },
    ':disabled:hover': {
      backgroundColor: props.theme.colors.white,
      color: props.theme.colors.blue,
    },
  },
  primaryButton: {
    color: props.field.value === props.primaryValue ? props.theme.colors.white : props.theme.colors.blue,
    backgroundColor: props.field.value === props.primaryValue ? props.theme.colors.green : props.theme.colors.white,
    boxShadow:
      props.field.value === props.primaryValue
        ? 'inset 0 1px 4px 0 rgba(0,0,0,0.4), 0 1px 3px 0 rgba(0,0,0,0.3)'
        : `0 2px 0 0 ${props.theme.colors.disabledText}`,
  },
  secondaryButton: {
    color: props.field.value === props.secondaryValue ? props.theme.colors.white : props.theme.colors.blue,
    backgroundColor: props.field.value === props.secondaryValue ? props.theme.colors.green : props.theme.colors.white,
    boxShadow:
      props.field.value === props.secondaryValue
        ? 'inset 0 1px 4px 0 rgba(0,0,0,0.4), 0 1px 3px 0 rgba(0,0,0,0.3)'
        : `0 2px 0 0 ${props.theme.colors.disabledText}`,
    marginLeft: '12px',
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 0 6px',
    position: 'relative',
    fontSize: '12px',
    zIndex: 0,
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
});

export default connect(styles)(TwoButtonToggle);
