import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import classNames from 'classnames';
import VerifyButton from '../../common/components/VerifyButton';
// import { loadStripe } from '@stripe/stripe-js/pure';
// import config from '../../../../common/config';
import FacebookLogin from '@greatsumini/react-facebook-login';

import {
  CircleAvatar,
  Loading,
  Icon,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  PaymentForm,
  ModalFooter,
  ConfirmationModalBody,
  ButtonPlain,
  SubNav,
} from '../../../../common/components';
import IDVerificationCardIcon from '../../../../common/components/icons/IDVerificationCard';

import PhoneVerificationForm from './PhoneVerificationForm';
import EmailVerificationForm from './EmailVerificationForm';
import generateInitials from '../../../../common/utils/generateInitials';
import theme from '../../../../theme';
import { Helmet } from 'react-helmet';
class Verification extends Component {
  static propTypes = {
    sendPhoneVerification: PropTypes.func.isRequired,
    confirmPhoneVerification: PropTypes.func.isRequired,
  };

  state = {
    paymentModalVisible: false,
    token: null,
    error: false,
    paymentSuccessful: false,
    lastVerificationAttemptModalVisible: false,
    confirmingFacebookDisconnect: false,
  };

  componentDidMount() {
    const { getVerificationStatus } = this.props;
    getVerificationStatus();
  }

  componentWillUnmount() {
    const { clearErrorBar } = this.props;

    clearErrorBar();
  }

  handleSubmitPayment = async nonce => {
    const { purchaseIdVerification, getVerificationStatus, values } = this.props;
    const action = await purchaseIdVerification(nonce, values);
    const ok = action?.response?.ok || false;
    this.setState({ error: !ok, paymentSuccessful: ok }, () => getVerificationStatus());
  };

  closeConfirmFacebookDisconnectModal = () => {
    this.setState({ confirmingFacebookDisconnect: false });
    this.props.resetDisconnectFacebookStatus();
  };

  renderPhoneVerificationForm = () => {
    const { user, sendPhoneVerification, confirmPhoneVerification } = this.props;
    return (
      <PhoneVerificationForm
        user={user}
        sendPhoneVerification={sendPhoneVerification}
        confirmPhoneVerification={confirmPhoneVerification}
      />
    );
  };

  renderPhoneVerificationHeader = () => {
    const { user, styles } = this.props;
    if (user.get('phone_number_verified')) {
      return (
        <div className={styles.sectionTitle}>
          <span>
            <strong>Phone Verification: </strong>
            Confirmed
          </span>
        </div>
      );
    }
    return (
      <div className={styles.sectionTitle}>
        <span>
          <strong>Phone Verification: </strong> Enter the code we text you
        </span>
      </div>
    );
  };

  renderEmailVerificationForm = () => {
    const { user, sendEmailVerification, verificationEmailSent, styles } = this.props;
    if (user.get('email_verified')) {
      return <div className={styles.confirmed}>{user.get('email')}</div>;
    }
    return (
      <EmailVerificationForm
        user={user}
        sendEmailVerification={sendEmailVerification}
        verificationEmailSent={verificationEmailSent}
      />
    );
  };

  renderVerificationStatus = () => {
    const { user } = this.props;
    const status = user.getIn(['verificationStatus', 'status']);

    if (user.get('id_verified')) {
      return 'Confirmed';
    }

    if (status) {
      if (status === 'invalid') {
        return 'Verification Failed';
      } else if (status === 'processing') {
        return 'Verification Processing';
      }
    }

    if (!user.getIn(['verificationStatus', 'attemptsRemaining'])) {
      return 'Verification Failed';
    }

    if (user.getIn(['verificationStatus', 'feeWaived'])) {
      return 'Complete your ID verification for free';
    }

    return user.getIn(['verificationStatus', 'status']) === 'paid'
      ? 'Purchased'
      : 'Purchase lifetime ID verification for $5';
  };

  renderErrorMessage = () => {
    const { user } = this.props;
    const status = user.getIn(['verificationStatus', 'status']);
    const error = user.getIn(['verificationStatus', 'error_reason']);
    if (status && error && status === 'invalid') {
      return error;
    }
  };

  renderIDVerification = () => {
    const { getBraintreeToken, styles, user } = this.props;
    const remainingAttempts = user.getIn(['verificationStatus', 'attemptsRemaining']);
    const status = user.getIn(['verificationStatus', 'status']);
    const sessionId = user.getIn(['verificationStatus', 'sessionId']);
    const onContinue = () => {
      getBraintreeToken('identity_verification').then(res => {
        if (res.response.ok) {
          this.setState(state => ({ token: res.json.clientToken }));
          this.setState({ paymentModalVisible: true });
        } else {
          this.setState({ error: true });
        }
      });
    };

    return (
      <Fragment>
        <div className={styles.sectionTitle}>
          <span>
            <strong>ID Verification: </strong> {this.renderVerificationStatus()}
          </span>
        </div>
        {!user.get('id_verified') ? (
          <div className={styles.idVerification}>
            {status === 'invalid' && remainingAttempts > 0 ? (
              <div>You have {remainingAttempts} ID verification attempts remaining.</div>
            ) : (
              ''
            )}
            <IDVerificationCardIcon />
            {status === 'processing' ? (
              <div>
                Our verification partner is taking a look at your credentials. When it's finished, we'll let you know
                the results.
              </div>
            ) : (
              ''
            )}
            {(status === null && !user.getIn(['verificationStatus', 'feeWaived'])) ||
            (status === 'invalid' && remainingAttempts === 0) ? (
              <Button buttonType="roundSmall" type="button" onClick={onContinue}>
                Continue
              </Button>
            ) : (
              ''
            )}
            {remainingAttempts > 0 &&
            status !== 'processing' &&
            (status !== null || user.getIn(['verificationStatus', 'feeWaived'])) ? (
              <VerifyButton sessionId={sessionId} />
            ) : (
              ''
            )}
            {remainingAttempts === 0 && status !== 'processing' ? (
              <div>You have no ID verification attempts remaining. You may purchase more for $5.</div>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className={styles.idVerification}>
            <IDVerificationCardIcon verified />
            <div>Your identity has been verified.</div>
          </div>
        )}
      </Fragment>
    );
  };

  responseFacebook = async fb => {
    if (fb.status === 'loginCancelled') {
      return;
    }
    const { setErrorBarMessage, connectFacebook } = this.props;
    const action = await connectFacebook(fb.accessToken);
    this.setState({ confirmingFacebookDisconnect: false });
    if (!action.response.ok) {
      setErrorBarMessage(action.json.message);
    }
  };

  renderFacebookVerification = () => {
    const { styles, user } = this.props;
    const redirectUri = window.location.origin + '/account/verifications';

    return (
      <Fragment>
        <div className={styles.sectionTitle}>
          <span>
            <strong>Facebook Verification: </strong> {user.get('facebook_id') ? 'Connected' : 'Connect your profile'}
          </span>
        </div>
        <div className={styles.buttonRow}>
          {user.get('facebook_id') ? (
            <ButtonPlain variant="red" onClick={() => this.setState({ confirmingFacebookDisconnect: true })}>
              Disconnect
            </ButtonPlain>
          ) : (
            <FacebookLogin
              appId="201417147080479"
              autoLoad={false}
              fields="name,email,picture"
              dialogParams={redirectUri}
              onSuccess={this.responseFacebook}
              onFail={this.responseFacebook}
              style={{
                backgroundColor: '#4267b2',
                color: '#fff',
                fontSize: '16px',
                padding: '12px 24px',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            />
          )}
        </div>
      </Fragment>
    );
  };

  renderLastFreeVerificationAttemptModal = () => {
    const { user } = this.props;
    const { lastVerificationAttemptModalVisible } = this.state;
    const sessionId = user.getIn(['verificationStatus', 'sessionId']);
    const closeModal = () => {
      this.setState({ error: false, paymentModalVisible: false });
    };

    if (!lastVerificationAttemptModalVisible) {
      return null;
    }

    return (
      <Modal
        isOpen={true}
        onClose={() => {
          this.setState({ lastVerificationAttemptModalVisible: false });
        }}
        closeOnOutsideClick
        closeOnEscape
      >
        <ConfirmationModalBody
          title="Last Attempt"
          subTitle="This is your last attempt to verify your ID. Please make sure you enter your information correctly."
          actions={[<VerifyButton sessionId={sessionId} closeModal={closeModal} modalVisible={true} />]}
        />
      </Modal>
    );
  };

  renderPaymentModal = () => {
    const { history } = this.props;
    const { token, paymentSuccessful, error, paymentModalVisible } = this.state;
    const closeModal = () => {
      this.setState({ error: false, paymentModalVisible: false });
    };

    if (!paymentModalVisible) {
      return null;
    }

    if (paymentSuccessful) {
      return (
        <Modal
          isOpen={true}
          onClose={() => history.push('/account/verifications/id')}
          closeOnOutsideClick
          closeOnEscape
        >
          <ConfirmationModalBody
            icon="CheckRounded"
            iconFill={theme.colors.green}
            title="Payment Complete!"
            subTitle="Your payment for ID Verification has been processed"
            actions={[
              <Button type="button" buttonType="round" onClick={closeModal}>
                Payment Completed
              </Button>,
            ]}
          />
        </Modal>
      );
    }

    if (error) {
      return (
        <Modal isOpen={true} onClose={closeModal} closeOnOutsideClick closeOnEscape>
          <ModalHeader title="Payment Failed" />
          <ModalBody>Please check your information and try again.</ModalBody>
          <ModalFooter
            actions={[
              <Button type="button" buttonType="round" onClick={closeModal}>
                DONE
              </Button>,
            ]}
          />
        </Modal>
      );
    }
    return (
      <Modal isOpen={true} onClose={closeModal} closeOnOutsideClick closeOnEscape>
        <ModalHeader
          title="Begin ID Verification"
          subTitle="A one-time charge of $5.00 will be applied for ID verification processing."
        />
        <PaymentForm onCancel={closeModal} onSubmit={this.handleSubmitPayment} token={token} type={'verification'} />
      </Modal>
    );
  };

  renderConfirmFacebookDisconnectConfirmation() {
    const { disconnectFacebook, facebookDisconnectStatus } = this.props;
    return (
      <React.Fragment>
        <ModalHeader
          title="Are you sure?"
          subTitle="Your Puppies account will no longer be connected with Facebook. You can re-connect at any time."
        />
        {facebookDisconnectStatus.get('error') ? <ModalBody>Could not complete request at this time.</ModalBody> : null}
        <ModalFooter
          actions={[
            <ButtonPlain
              type="button"
              disabled={facebookDisconnectStatus.get('submitting')}
              onClick={this.closeConfirmFacebookDisconnectModal}
            >
              Nevermind, go back
            </ButtonPlain>,
            <Button
              type="button"
              disabled={facebookDisconnectStatus.get('submitting')}
              buttonType="round"
              onClick={disconnectFacebook}
            >
              Confirm
            </Button>,
          ]}
        />
      </React.Fragment>
    );
  }

  renderConfirmFacebookDisconnectSuccess() {
    return (
      <React.Fragment>
        <ModalHeader title="Success!" subTitle="Your Facebook account has been disconnected" />
        <ModalFooter
          actions={[
            <Button type="button" buttonType="round" onClick={this.closeConfirmFacebookDisconnectModal}>
              Close
            </Button>,
          ]}
        />
      </React.Fragment>
    );
  }

  renderConfirmFacebookDisconnectModal() {
    const { facebookDisconnectStatus } = this.props;
    return (
      <Modal
        isOpen={this.state.confirmingFacebookDisconnect}
        onClose={this.closeConfirmFacebookDisconnectModal}
        closeOnEscape
        closeOnOutsideClick
      >
        {facebookDisconnectStatus.get('success')
          ? this.renderConfirmFacebookDisconnectSuccess()
          : this.renderConfirmFacebookDisconnectConfirmation()}
      </Modal>
    );
  }
  renderSubNav() {
    return <SubNav titleText={'Verifications'} />;
  }

  render() {
    const { user, styles } = this.props;
    if (!user || !user.get('verificationStatus')) {
      return <Loading center dark />;
    }

    return (
      <div className={styles.container}>
        <Helmet>
          <title>Verifications - Puppies.com</title>
        </Helmet>
        {this.renderSubNav()}
        <div className={styles.wrapper}>
          <div>
            {
              <div className={styles.topTitle}>
                <span className={styles.topTitleIcon}>
                  <Icon icon="StarCircle" size={15} fill={theme.colors.brighterBlue} />
                </span>{' '}
                Complete ID verification to prove your identity and get a badge on your profile
              </div>
            }
            <div className={styles.verificationsHeader}>
              <CircleAvatar
                size={50}
                photo={user.get('photo')}
                alt={'profile'}
                initials={generateInitials(user.get('last_initial'), user.get('first_name'))}
                verified={user.get('id_verified')}
              />
              <div className={styles.verifications}>
                {
                  <div
                    className={classNames([styles.verification, user.get('id_verified') ? styles.IDVerified : null])}
                  >
                    <Icon icon="StarCircle" size={36} />
                    <div>{user.get('id_verified') ? 'ID Verified' : 'ID Not Verified'}</div>
                  </div>
                }
                <div className={classNames([styles.verification, user.get('email_verified') ? styles.verified : null])}>
                  <Icon icon="CheckCircle" size={36} />
                  <div>{user.get('email_verified') ? 'Email Verified' : 'Email Not Verified'}</div>
                </div>
                <div
                  className={classNames([
                    styles.verification,
                    user.get('phone_number_verified') ? styles.verified : null,
                  ])}
                >
                  <Icon icon="CheckCircle" size={36} />
                  <div>{user.get('phone_number_verified') ? 'Phone Number Verified' : 'Phone Number Not Verified'}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.sectionsWrapper}>
            <div className={styles.section}>
              <div className={styles.sectionTitle}>
                <span>
                  <strong>Email Verification: </strong>{' '}
                  {user.get('email_verified') ? 'Confirmed' : 'Confirm your email address'}
                </span>
              </div>
              {this.renderEmailVerificationForm()}
            </div>
            <div className={styles.section}>
              {this.renderPhoneVerificationHeader()}
              {this.renderPhoneVerificationForm()}
            </div>
          </div>
          <div className={styles.sectionsWrapper}>
            {<div className={styles.section}>{this.renderIDVerification()}</div>}
          </div>
        </div>
        {this.renderPaymentModal()}
        {this.renderLastFreeVerificationAttemptModal()}
      </div>
    );
  }
}

const styles = {
  container: props => ({
    width: '100%',
    backgroundColor: props.theme.colors.tan,
  }),
  wrapper: props => ({
    ...props.theme.globalPageWrapper,
  }),
  topTitle: props => ({
    display: 'flex',
    alignItems: 'center',
    color: props.theme.colors.orange,
    marginBottom: '32px',
  }),
  topTitleIcon: props => ({
    display: 'inline-block',
    color: props.theme.colors.brighterBlue,
    marginRight: '4px',
    verticalAlign: 'middle',
    backgroundColor: props.theme.colors.white,
    borderRadius: '50%',
  }),
  sectionTitle: props => ({
    display: 'flex',
    alignItems: 'center',
    color: props.theme.colors.orange,
    marginBottom: '32px',
  }),
  verificationsHeader: props => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingBottom: '30px',
    borderBottom: props.theme.globalDashedBorder,
    '> * + *': {
      marginTop: '30px',
    },
    [props.theme.breakpoints.tablet]: {
      alignItems: 'center',
      flexDirection: 'row',
      '> * + *': {
        marginTop: 0,
        marginLeft: '50px',
      },
    },
  }),
  verifications: props => ({
    display: 'flex',
    flexDirection: 'column',
    '> * + *': {
      marginTop: '40px',
    },
    [props.theme.breakpoints.tablet]: {
      alignItems: 'center',
      flexDirection: 'row',
      '> * + *': {
        marginTop: 0,
        marginLeft: '40px',
      },
    },
  }),
  verification: {
    display: 'flex',
    alignItems: 'center',
    color: '#9F9F9F',
    '>span': {
      color: '#DDD',
      marginRight: '16px',
    },
  },
  verificationError: {
    color: '#900',
  },
  verified: props => ({
    '>span': {
      color: props.theme.colors.green,
    },
    '>div': {
      color: props.theme.colors.textDark,
    },
  }),
  IDVerified: props => ({
    '>span': {
      color: props.theme.colors.brighterBlue,
    },
    '>div': {
      color: props.theme.colors.textDark,
    },
  }),
  sectionsWrapper: props => ({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      '>:nth-child(odd)': {
        borderRight: props.theme.globalDashedBorder,
      },
      '>:nth-child(even)': {
        paddingLeft: '50px',
      },
    },
  }),
  section: props => ({
    padding: '30px 30px 30px 0',
    borderBottom: props.theme.globalDashedBorder,
    [props.theme.breakpoints.tablet]: {
      width: '50%',
      display: 'inline-block',
    },
  }),
  confirmed: props => ({
    color: props.theme.colors.brown,
    fontWeight: props.theme.typography.sansBold,
    fontSize: '14px',
  }),
  idVerification: {
    display: 'flex',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '20px',
    },
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
};

export default felaConnect(styles)(Verification);
