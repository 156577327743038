import React from 'react';

function CircleX() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0C5.37225 0 0 5.373 0 12.0008C0 18.6278 5.37225 24 12 24C18.6278 24 24 18.6278 24 12.0008C24 5.373 18.6278 0 12 0V0ZM12 21.75C6.615 21.75 2.25 17.385 2.25 12.0008C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12.0008C21.75 17.385 17.385 21.75 12 21.75V21.75Z"
        fill="#595959"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3334 7.05854C17.5937 7.31889 17.5937 7.741 17.3334 8.00135L12.9428 12.3919L16.9428 16.3919C17.2032 16.6522 17.2032 17.0743 16.9428 17.3347C16.6825 17.595 16.2604 17.595 16 17.3347L12 13.3347L8.00005 17.3347C7.7397 17.595 7.31759 17.595 7.05724 17.3347C6.79689 17.0743 6.79689 16.6522 7.05724 16.3919L11.0572 12.3919L6.6667 8.00135C6.40635 7.741 6.40635 7.31889 6.6667 7.05854C6.92705 6.7982 7.34916 6.7982 7.60951 7.05854L12 11.4491L16.3906 7.05854C16.6509 6.79819 17.073 6.79819 17.3334 7.05854Z"
        fill="#595959"
      />
    </svg>
  );
  /* eslint-enable */
}

export default CircleX;
