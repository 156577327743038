import React from 'react';

function CheckCircle() {
  /* eslint-disable max-len */
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        d="M12.5376 5.17106C12.1551 4.94269 11.6663 5.07825 11.4458 5.47538L8.42794 10.8956L6.74719 8.85038C6.52669 8.45381 6.03788 8.31769 5.65594 8.54663C5.27344 8.77556 5.14237 9.28238 5.36287 9.67894L7.70175 12.5258C7.86825 12.825 8.18719 12.9752 8.49769 12.9324C8.90494 12.9465 9.18056 12.8599 9.36619 12.5258L12.8301 6.30394C13.0511 5.90794 12.9201 5.40056 12.5376 5.17106ZM9 0C4.02919 0 0 4.02975 0 9.00056C0 13.9708 4.02919 18 9 18C13.9708 18 18 13.9708 18 9.00056C18 4.02975 13.9708 0 9 0ZM9 16.3125C4.96125 16.3125 1.6875 13.0387 1.6875 9.00056C1.6875 4.96125 4.96125 1.6875 9 1.6875C13.0387 1.6875 16.3125 4.96125 16.3125 9.00056C16.3125 13.0387 13.0387 16.3125 9 16.3125Z"
        fill="#7ED321"
      />
    </svg>
  );
  /* eslint-enable */
}

export default CheckCircle;
