import { connect as felaConnect } from 'react-fela';
import React, { Component } from 'react';
import { Button } from '../../common/components';
import { Helmet } from 'react-helmet';
const AdoptionCenterPlaceholder = `/static/adoptionCenterPlaceholder.jpg`;

class AdoptionPlaceholder extends Component {
  render() {
    const { styles } = this.props;
    return (
      <div className={styles.root} style={{ backgroundImage: `url(${AdoptionCenterPlaceholder})` }}>
        <Helmet>
          <title>Adoption - Puppies.com</title>
        </Helmet>
        <div className={styles.container}>
          <h1>Coming Soon!</h1>
          <hr />
          <p>
            We believe that dogs of all breeds, shapes, and sizes deserve loving homes. Our adoption center is coming
            soon, but in the meantime, we're donating a portion of profits to charity. If you'd like to help out, you
            can donate here!
          </p>
          <a href="https://puppiescares.org" target="_blank" rel="noopener noreferrer">
            <Button type="button" buttonType="round">
              Visit Puppies.com Cares
            </Button>
          </a>
        </div>
      </div>
    );
  }
}

const styles = props => ({
  root: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
  },
  container: {
    ...props.theme.globalPageWrapper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: props.theme.colors.white,
    '> h1': {
      fontSize: '45px',
      lineHeight: '46px',
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansAltBold,
      fontStyle: 'italic',
    },
    '> hr': {
      border: 'none',
      borderTop: `solid 4px ${props.theme.colors.orange}`,
      width: '140px',
      marginTop: '16px',
    },
    '> p': {
      margin: '10px 0 16px',
      lineHeight: '25px',
      maxWidth: '420px',
      textAlign: 'center',
    },
  },
});

export default felaConnect(styles)(AdoptionPlaceholder);
