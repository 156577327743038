import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect as felaConnect } from 'react-fela';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CircleAvatar,
  ConfirmationModalBody,
  ContentCapitalized,
} from '..';
import { Map } from 'immutable';
import generateInitials from '../../utils/generateInitials';
import ButtonPlain from '../ButtonPlain';
import Button from '../Button';
import InputError from '../InputError';
import classNames from 'classnames';
import theme from '../../../theme';

class MarkAsSoldModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    possibleBuyers: PropTypes.arrayOf(PropTypes.instanceOf(Map)).isRequired,
    error: PropTypes.string,
    success: PropTypes.bool,
  };

  state = {
    confirmed: false,
    showSelectBuyer: false,
    selectedBuyer: null,
  };

  getSelectedBuyer() {
    if (this.state.selectedBuyer === 'other' || !this.state.selectedBuyer) {
      return null;
    }

    return this.props.possibleBuyers.find(user => user.get('id') === this.state.selectedBuyer);
  }

  renderPossibleBuyers() {
    const { possibleBuyers, styles } = this.props;

    return (
      <div className={styles.modalBody}>
        <div>Who bought this puppy?</div>
        <div className={styles.buyersList}>{possibleBuyers.map(this.renderBuyerButton)}</div>
      </div>
    );
  }

  renderBuyerButton = user => {
    const { styles } = this.props;
    const { selectedBuyer } = this.state;
    return (
      <button
        key={user.get('id')}
        onClick={() => this.setState({ selectedBuyer: user.get('id') })}
        className={classNames([
          styles.buyerButton,
          selectedBuyer === user.get('id') ? styles.buyerButtonSelected : null,
        ])}
      >
        <CircleAvatar
          size={40}
          photo={user.get('photo')}
          initials={generateInitials(user.get('last_initial'), user.get('first_name'))}
          checked={selectedBuyer === user.get('id')}
          alt={'user-avatar'}
          verified={user.get('id_verified')}
        />
        <p>
          <ContentCapitalized>
            {user.get('first_name')} {user.get('last_initial')}
          </ContentCapitalized>
        </p>
      </button>
    );
  };

  renderSuccess() {
    const { onClose } = this.props;
    const buyer = this.getSelectedBuyer();
    const buyerName = buyer ? `${buyer.get('first_name')} ${buyer.get('last_initial')}` : null;
    return (
      <ConfirmationModalBody
        icon="FatCheck"
        iconFill={theme.colors.green}
        title="Marked As Sold"
        subTitle={
          `Congratulations on your sale!` + (buyerName ? ` A testimonial request has been sent to the buyer.` : '')
        }
        actions={[
          <Button buttonType="round" onClick={onClose}>
            Close
          </Button>,
        ]}
      />
    );
  }

  renderChooseBuyerForm() {
    const { onSubmit, isSubmitting, error, styles } = this.props;
    return (
      <Fragment>
        <ModalHeader
          title="Who bought your dog?"
          subTitle="Congratulations on helping someone find their perfect puppy."
        />
        <ModalBody className={styles.possibleBuyers}>
          {this.renderPossibleBuyers()}
          {error ? <InputError>{error}</InputError> : null}
        </ModalBody>
        <ModalFooter
          actions={[
            <ButtonPlain fontSize={12} type="button" onClick={() => onSubmit(null)} disabled={isSubmitting}>
              Someone else
            </ButtonPlain>,
            <Button
              type="button"
              buttonType="round"
              disabled={isSubmitting || !this.state.selectedBuyer}
              onClick={() => onSubmit(this.state.selectedBuyer === 'other' ? null : this.state.selectedBuyer)}
            >
              Submit
            </Button>,
          ]}
        />
      </Fragment>
    );
  }

  confirmChangeFragment() {
    const {
      possibleBuyers,
      onSubmit,
      onClose,
      isSubmitting,
      styles,
      setContactedByListingRadioValue,
      contactedByListingRadioValue,
    } = this.props;
    return (
      <Fragment>
        <ModalHeader title={'Mark as Sold?'} variant="blue" />
        <ModalBody>
          Congratulations on the sale! Only mark a listing as sold when you've completed the transaction with the buyer.
          If you're waiting on payment from a potential buyer and you’ve agreed to sell to them, select{' '}
          <button onClick={this.props.onMarkOnHold} className={styles.onHoldLink}>
            on hold
          </button>{' '}
          instead. <strong>Marking as sold cannot be undone</strong>.
          <div className={styles.flex}>
            <div className={styles.flexItem}>
              <strong>
                I sold this listing to someone who
                <br />
                contacted me via my Puppies.com listing
              </strong>
            </div>
            <div className={styles.flexItem}>
              <label>
                <input
                  name="sold-to-puppies-user"
                  type="radio"
                  value={true}
                  onChange={e => setContactedByListingRadioValue(e.target.value)}
                  defaultChecked={contactedByListingRadioValue === 'true'}
                />{' '}
                Yes
              </label>
              <label>
                <input
                  name="sold-to-puppies-user"
                  type="radio"
                  value={false}
                  onChange={e => setContactedByListingRadioValue(e.target.value)}
                  defaultChecked={contactedByListingRadioValue === 'false'}
                />{' '}
                No
              </label>
            </div>
          </div>
        </ModalBody>
        <ModalFooter
          actions={
            contactedByListingRadioValue === null
              ? [
                  <ButtonPlain type="button" disabled={isSubmitting} variant="red" onClick={onClose}>
                    Cancel
                  </ButtonPlain>,
                  <Button type="button" buttonType="round" disabled={true}>
                    Mark as Sold
                  </Button>,
                ]
              : [
                  <ButtonPlain type="button" disabled={isSubmitting} variant="red" onClick={onClose}>
                    Cancel
                  </ButtonPlain>,
                  <Button
                    type="button"
                    buttonType="round"
                    disabled={isSubmitting}
                    onClick={() => {
                      if (possibleBuyers.length) {
                        this.setState({ showSelectBuyer: true });
                      } else {
                        onSubmit(null);
                      }
                    }}
                  >
                    Mark as Sold
                  </Button>,
                ]
          }
        />
      </Fragment>
    );
  }

  newSellerFragment() {
    // This scenario shouldn't happen, but just in case..
    const { onClose, isSubmitting } = this.props;
    return (
      <Fragment>
        <ModalHeader title={'Welcome to Puppies.com'} variant="blue" />
        <ModalBody>
          Your account is being reviewed by our staff. You may not change the listing status during this period.
        </ModalBody>
        <ModalFooter
          actions={[
            <ButtonPlain type="button" disabled={isSubmitting} variant="red" onClick={onClose}>
              Ok
            </ButtonPlain>,
          ]}
        />
      </Fragment>
    );
  }

  expiredSellerFragment() {
    const { onClose, isSubmitting } = this.props;
    return (
      <Fragment>
        <ModalHeader title={'Membership Expired'} variant="blue" />
        <ModalBody>
          You must have an active paid membership to change your listing status. Please re-subscribe or purchase a new
          membership to continue.
        </ModalBody>
        <ModalFooter
          actions={[
            <ButtonPlain type="button" disabled={isSubmitting} variant="red" onClick={onClose}>
              Cancel
            </ButtonPlain>,
            <Button
              type="button"
              buttonType="round"
              disabled={isSubmitting}
              onClick={() => {
                window.location.href = '/account/membership';
              }}
            >
              Upgrade Now
            </Button>,
          ]}
        />
      </Fragment>
    );
  }

  membershipRequiredFragment() {
    const { userVM } = this.props;
    const isNewSeller = userVM && userVM.get('isNewSeller');
    return isNewSeller ? this.newSellerFragment() : this.expiredSellerFragment();
  }

  renderConfirmationModal() {
    const { userVM } = this.props;
    const hasActiveMembership = userVM && userVM.get('isActive') && !userVM.get('isNewSeller');

    return hasActiveMembership ? this.confirmChangeFragment() : this.membershipRequiredFragment();
  }

  render() {
    const { isOpen, onClose, success, styles } = this.props;
    const { showSelectBuyer } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOutsideClick
        closeOnEscape
        className={showSelectBuyer ? styles.modal : null}
      >
        {success
          ? this.renderSuccess()
          : showSelectBuyer
          ? this.renderChooseBuyerForm()
          : this.renderConfirmationModal()}
      </Modal>
    );
  }
}

const styles = props => ({
  possibleBuyers: {
    padding: '0 !important',
    overflowY: 'auto',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '12px',
    fontWeight: props.theme.typography.sansBold,
    padding: '20px 40px',
  },
  buyersList: {
    width: '100%',
    maxHeight: '260px',
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    '> *': {
      marginBottom: '24px',
      [props.theme.breakpoints.mobileSmall]: {
        width: '50%',
      },
    },
  },
  buyerButton: {
    display: 'flex',
    alignItems: 'center',
    '> p': {
      fontWeight: props.theme.typography.sansBold,
      color: props.theme.colors.brown,
      fontSize: '12px',
      marginLeft: '8px',
      textAlign: 'left',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '140px',
    },
    ':nth-child(odd)': {
      paddingRight: '20px',
    },
    ':focus': {
      outline: 'none',
    },
  },
  buyerButtonSelected: {
    '> p': {
      color: props.theme.colors.blue,
    },
  },
  warningIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  onHoldLink: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '22px',
    textDecoration: 'underline',
    color: props.theme.colors.blue,
  },
  flex: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  flexItem: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default felaConnect(styles)(MarkAsSoldModal);
