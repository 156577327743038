import React from 'react';
import querystring from 'query-string';
import styled from 'styled-components';

import { ButtonLink, PlainLink } from '../../common/components';

import theme from '../../theme';
import { mapFormValuesToQuery } from './common/search-config';

const Container = styled.div`
  background-color: #ff6984;
  padding: 28px 0;
`;

const ContentContainer = styled.div`
  ${theme.globalPadding}
  max-width: ${theme.globalMaxWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${theme.colors.white};
  font-size: 20px;

  ${theme.breakpoints.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Matches = styled.div`
  text-align: center;
  line-height: 28px;

  & > a {
    padding-right: 4px;
  }

  ${theme.breakpoints.tablet} {
    text-align: left;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;

  & > * + * {
    margin-top: 20px;
  }

  ${theme.breakpoints.mobile} {
    flex-direction: row;

    & > * + * {
      margin-top: 0;
      margin-left: 20px;
    }
  }

  ${theme.breakpoints.tablet} {
    margin-top: 0;
  }
`;

export const formatQueryForURL = (query = {}) => {
  return querystring.stringify(mapFormValuesToQuery({ ...query, advanced: true, show_matchmaker: false }));
};

const MatchmakerHeader = ({ query = {} }) => {
  return (
    <Container aria-label="matches banner">
      <ContentContainer>
        <Matches>
          Hey! Looks like your best matches are
          <br />
          {query?.slug?.map((slugname, index) => {
            if (index === query.slug.length - 1) {
              return (
                <div style={{ display: 'inline-block' }} key={index}>
                  <span> and </span>
                  <PlainLink to={`/breeds/${slugname}`}>{query.formatted_breed[index]}</PlainLink>
                </div>
              );
            }
            return (
              <PlainLink key={index} to={`/breeds/${slugname}`}>
                {`${query.formatted_breed[index]},`}
              </PlainLink>
            );
          })}
        </Matches>
        <ButtonContainer>
          <ButtonLink buttonType="round" to="/breed-matchmaker">
            Retake Quiz
          </ButtonLink>
          <ButtonLink
            buttonType="round"
            to={{
              pathname: '/breed-matchmaker',
              search: formatQueryForURL(query),
            }}
          >
            Refine Results
          </ButtonLink>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
};

export default MatchmakerHeader;
