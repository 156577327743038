import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'lodash/flowRight';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';
import { Button } from '../';
import BackButton from '../BackButton';

class SubNavListings extends Component {
  static propTypes = {
    styles: PropTypes.object,
    titleText: PropTypes.any,
    headerRightChildren: PropTypes.node,
    profile: PropTypes.bool,
  };

  navigateToEditListing = () => {
    const { history, listing, location } = this.props;

    history.push({
      pathname: `/listings/${listing.get('id')}/edit`,
      state: {
        prevLocation: location,
        backTo: 'Listing',
      },
    });
  };

  render() {
    const { styles, titleText, location, headerRightChildren, isMobile, history, showPostUpdateLink } = this.props;
    return (
      <div className={styles.flex}>
        <div className={styles.wrapper}>
          <div className={styles.root}>
            {titleText && (
              <div className={styles.titleContainer}>
                <div className={styles.title}>
                  <h2>{titleText}</h2>
                  {showPostUpdateLink ? (
                    <div className={styles.puppyUpdateContainer}>
                      <div className={styles.divider} />
                      <div className={styles.puppyUpdateLink}>
                        <button
                          className={styles.blue}
                          onClick={e => {
                            e.preventDefault();
                            this.props.onPostAnUpdateClick();
                          }}
                        >
                          Post an update
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
                {isMobile && (
                  <div className={styles.listNewMobile}>
                    <Button
                      buttonType="roundOrange"
                      onClick={() => {
                        history.push('/listings/create');
                      }}
                    >
                      <span>
                        <span style={{ fontSize: '14px' }}>+</span> New
                      </span>
                    </Button>
                  </div>
                )}
              </div>
            )}
            <div className={styles.headerRight}>
              <BackButton location={location} className={styles.backButton} />
              {headerRightChildren && <div>{headerRightChildren}</div>}
              {!isMobile && (
                <div className={styles.listNew}>
                  <Button
                    buttonType="roundOrange"
                    onClick={() => {
                      history.push('/listings/create');
                    }}
                  >
                    <span>
                      <span style={{ fontSize: '14px' }}>+</span> List New
                    </span>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.browser.lessThan.tablet,
});

const styles = props => ({
  flex: {
    display: 'flex',
  },
  wrapper: {
    width: '100%',
    backgroundColor: props.theme.colors.darkerTan,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (min-width: 740px)': {
      marginBottom: '16px',
    },
    '@media (min-width: 810px)': {
      marginBottom: '0 !important',
    },
  },
  root: {
    width: '100%',
    maxWidth: props.theme.globalMaxWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    '@media (min-width: 740px)': {
      flexDirection: 'row',
      padding: '24px 20px',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '15px 12px 0',
    marginBottom: '16px',
    '@media (min-width: 740px)': {
      padding: 0,
      margin: 0,
    },
  },
  title: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    width: '100%',

    '> h2': {
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansLight,
      color: props.theme.colors.orange,
    },
    '@media (min-width: 740px)': {
      fontSize: '28px',
    },
    [props.theme.breakpoints.mobile]: {
      fontSize: '20px',
      padding: 0,
      margin: 0,
    },
  },
  headerRight: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    padding: '10px 12px',
    backgroundColor: props.theme.colors.white,

    '> *': {
      flex: 1,
    },
    '@media (min-width: 740px)': {
      backgroundColor: 'unset',
      padding: 0,
      '> *': {
        flex: 'unset',
      },
    },
    [props.theme.breakpoints.mobile]: {},
    '> * + *': {
      marginLeft: '16px',
    },
    '> button': {
      backgroundColor: 'white',
      color: props.theme.colors.blue,
      padding: '12px',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      boxShadow: props.theme.globalBoxShadow,
      transition: '0.2s',
      '> div': {
        '> span': {
          fontFamily: props.theme.typography.sansAlt,
        },
      },
      ':hover': {
        borderColor: props.theme.colors.orange,
        borderWidth: '2px',
        borderStyle: 'solid',
      },
    },
    '> a': {
      backgroundColor: 'white',
      color: props.theme.colors.blue,
      padding: '12px',
      textTransform: 'uppercase',
      letterSpacing: '0.5px',
      boxShadow: props.theme.globalBoxShadow,
      transition: '0.2s',
      '> div': {
        '> span': {
          '> span': {
            marginRight: '6px',
            ':last-of-type': {
              margin: 0,
            },
          },
        },
      },
      ':hover': {
        color: props.theme.colors.blue,
        backgroundColor: props.theme.colors.white,
        opacity: 0.8,
      },
      ':active': {
        backgroundColor: `${props.theme.colors.green} !important`,
        color: `${props.theme.colors.white} !important`,
        boxShadow: 'inset 0 2px 1px 0 #417505',
        borderColor: 'transparent',
        outline: 'none',
      },
    },
  },
  listNew: {
    '> button': {
      backgroundColor: props.theme.colors.orange,
      minWidth: 'unset',
    },
  },
  listNewMobile: {
    display: 'flex',
    marginLeft: '10px',
    '> button': {
      backgroundColor: props.theme.colors.orange,
      minWidth: 'unset',
      padding: '10px 12px',
    },
    '@media (min-width: 740px)': {
      marginRight: '8px',
    },
  },
  divider: {
    height: '16px',
    width: '2px',
    backgroundColor: props.theme.colors.brown,
    margin: '0 12px',

    [props.theme.breakpoints.mobile]: {
      height: '32px',
      margin: '0 16px',
    },
  },
  puppyUpdateContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  puppyUpdateLink: {
    fontSize: '14px',
    color: props.theme.colors.blue,
  },
  blue: {
    fontWeight: props.theme.typography.sansBold,
    textDecoration: 'underline',
    color: `${props.theme.colors.blue}`,
    opacity: 1,

    ':hover': {
      color: `${props.theme.colors.darkerBlue} !important`,
      opacity: 1,
    },
  },
});

export default connect(mapStateToProps)(compose(felaConnect(styles), withRouter)(SubNavListings));
