import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';
import { renderRoutes } from 'react-router-config';

import TopNav from '../../common/components/TopNav';
import Footer from '../../common/components/Footer';
import Button from '../../common/components/Button';
import ContentCapitalized from '../../common/components/ContentCapitalized';
import config from '../../common/config';
import SuspendedBar from '../../common/components/SuspendedBar';

function returnToAdmin(logout, user) {
  const adminUrl = `${config.adminUrl}/users/${user.get('id')}`;

  logout();
  if (typeof window !== 'undefined') {
    window.location = adminUrl;
  }
}

function PrimaryLayout({
  children,
  isLoggedIn,
  user,
  userVM,
  logout,
  errorBarError,
  styles,
  unreadMessages,
  route,
  isMobile,
}) {
  const atInboxOrArchive =
    typeof window !== 'undefined' &&
    (/^\/messages\/inbox\/.*/.test(window.location.pathname) ||
      /^\/messages\/archive\/.*/.test(window.location.pathname));

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {user && user.get('impersonated_by') && (
          <div className={styles.impersonate} data-testid="impersonation-banner">
            <span>
              You are currently viewing the site as{' '}
              <ContentCapitalized>{`${user.get('first_name')} ${user.get('last_name')}`}</ContentCapitalized>
            </span>
            <Button buttonType="roundSmall" onClick={() => returnToAdmin(logout, user)}>
              Return to Admin
            </Button>
          </div>
        )}
        <TopNav
          isSeller={user && user.get('listings').size > 0}
          user={user}
          userVM={userVM}
          isLoggedIn={isLoggedIn}
          errorBarError={errorBarError}
          messageCount={unreadMessages}
        />
        {user && user.get('account_status') === 'Suspended' && (
          <SuspendedBar message={'Your account is currently suspended, please contact customer service.'} />
        )}
        <div className={styles.children}>
          {children}
          {route && renderRoutes(route.routes)}
        </div>
        {atInboxOrArchive && isMobile ? null : <Footer isLoggedIn={isLoggedIn} />}
      </div>
    </div>
  );
}

PrimaryLayout.propTypes = {
  children: PropTypes.node,
  isLoggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object,
  logout: PropTypes.func,
  errorBarError: PropTypes.node,
  styles: PropTypes.object,
  unreadMessages: PropTypes.number,
  route: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    isMobile: state.browser.lessThan.tabletLarge,
    userVM: state.userVM,
  };
};

const styles = props => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    height: 'auto',
  },
  children: {
    position: 'relative',
    display: 'flex',
    flex: 'auto',
    marginTop: '82px',
    '> *': {
      width: '100%',
    },
  },
  impersonate: {
    position: 'sticky',
    top: 0,
    background: props.theme.colors.blue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `2px solid ${props.theme.colors.yellow}`,
    padding: '12px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'white',
    zIndex: 1000,
    '> * + *': {
      marginLeft: '24px',
    },
  },
});

export default connect(mapStateToProps)(felaConnect(styles)(PrimaryLayout));
