import { combineReducers } from 'redux';
import { fromJS, List, Map } from 'immutable';

const articleList = (state = Map({ list: List(), total: null, loading: true, loaded: false }), action) => {
  switch (action.type) {
    case 'FETCH_ARTICLES_SUCCESS':
      return state.set('list', fromJS(action.json.items)).merge({
        total: action.json.total,
        loaded: true,
        loading: false,
      });
    case 'RESET_ARTICLES':
      return Map({ list: List(), total: null, loading: true, loaded: false });
    default:
      return state;
  }
};

const currentArticle = (state = Map({ loaded: false }), action) => {
  switch (action.type) {
    case 'GET_ARTICLE_SUCCESS':
      return fromJS(action.json.fields).merge({ loaded: true, updatedAt: action.json.sys.updatedAt });
    case 'RESET_ARTICLES':
      return Map({ loaded: false });
    default:
      return state;
  }
};

const featured = (state = null, action) => {
  switch (action.type) {
    case 'GET_FEATURED_ARTICLES_SUCCESS':
      return fromJS(action.json.items);
    default:
      return state;
  }
};

const recent = (state = null, action) => {
  switch (action.type) {
    case 'GET_RECENT_ARTICLES_SUCCESS':
      return fromJS(action.json.items);
    default:
      return state;
  }
};

const getError = (state = null, action) => {
  switch (action.type) {
    case 'GET_ARTICLE_REQUEST':
      return null;
    case 'GET_ARTICLE_FAILURE':
      return action.response;
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ARTICLES_REQUEST':
    case 'GET_ARTICLE_REQUEST':
      return true;

    case 'FETCH_ARTICLES_SUCCESS':
    case 'FETCH_ARTICLES_FAILURE':
    case 'GET_ARTICLE_SUCCESS':
    case 'GET_ARTICLE_FAILURE':
      return false;

    default:
      return state;
  }
};

export default combineReducers({
  articleList,
  currentArticle,
  featured,
  recent,
  loading,
  getError,
});
