const breedRatings = [
  {
    description: 'Size',
    value: 'size',
    min: 0,
    max: 175,
  },
  {
    description: 'Space Needs',
    value: 'space_rating',
    min: 0,
    max: 5,
  },
  {
    description: 'Activity Needs',
    value: 'exercise_rating',
    min: 0,
    max: 5,
  },
  {
    description: 'Lifespan',
    value: 'max_lifespan',
    min: 0,
    max: 18,
  },
  {
    description: 'Intelligence',
    value: 'intelligence_rating',
    min: 0,
    max: 5,
  },
  {
    description: 'Playfulness',
    value: 'playfulness_rating',
    min: 0,
    max: 5,
  },
  {
    description: 'Grooming Needs',
    value: 'grooming_rating',
    min: 0,
    max: 5,
  },
  {
    description: 'Trainability',
    value: 'trainability_rating',
    min: 0,
    max: 5,
  },
  {
    description: 'Watchdog Ability',
    value: 'watchdog_rating',
    min: 0,
    max: 5,
  },
  {
    description: 'Affection',
    value: 'affection_rating',
    min: 0,
    max: 5,
  },
  {
    description: 'Barking',
    value: 'barking_rating',
    min: 0,
    max: 5,
  },
  {
    description: 'Cat-friendliness',
    value: 'cat_rating',
    min: 0,
    max: 5,
  },
  {
    description: 'Dog-friendliness',
    value: 'dog_rating',
    min: 0,
    max: 5,
  },
  {
    description: 'Child-friendliness',
    value: 'child_rating',
    min: 0,
    max: 5,
  },
];

module.exports = { breedRatings };
