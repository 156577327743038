import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';

import FsAd from '../Freestar';

class AdBanner extends Component {
  render() {
    const { styles } = this.props;
    return (
      <div className={styles.root}>
        <FsAd slotId={this.props.slotId} placementName={this.props.placementName} />
      </div>
    );
  }
}

const styles = () => {
  return {
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '60px',
    },
  };
};

export default felaConnect(styles)(AdBanner);
