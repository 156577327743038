import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import { PlainLink, Tooltip } from '../../../../common/components';

class ConversationHeaderTooltipLink extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    linkTo: PropTypes.string.isRequired,
  };

  state = {
    isHovered: false,
  };

  render() {
    const { children, name, linkTo, styles } = this.props;

    return (
      <div
        className={styles.tooltipTextWrapper}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
      >
        <PlainLink variant="blue" to={linkTo}>
          {name}
        </PlainLink>
        <Tooltip show={this.state.isHovered}>
          <div className={styles.tooltipHeader}>
            <div className={styles.tooltipName}>{children}</div>
          </div>
        </Tooltip>
      </div>
    );
  }
}

const styles = props => ({
  tooltipTextWrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  tooltipHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipName: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    '> h3': {
      fontSize: '15px',
      fontWeight: props.theme.typography.sansBold,
      marginBottom: '8px',
      maxWidth: '164px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '> p': {
      fontSize: '14px',
    },
  },
});

export default connect(styles)(ConversationHeaderTooltipLink);
