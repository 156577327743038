import React from 'react';

function QuestionOutline() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
      <path
        d="M40 0c22.031 0 40 17.969 40 40 0 22.148-17.969 40-40 40C17.852 80 0 62.148 0 40 0 17.969 17.852 0 40 0zm.435 69.565c3.623 0 6.522-2.907 6.522-6.54 0-3.596-2.899-6.503-6.522-6.503s-6.522 2.907-6.522 6.502c0 3.634 2.899 6.541 6.522 6.541zm4.588-23.374c0-4.984 13.238-4.984 13.238-18.3 0-9.968-8.191-16.587-18.207-16.587-9.938 0-16.576 8.294-16.576 8.294l6.638 6.658s4.97-4.984 9.938-4.984c4.97 0 8.269 3.31 8.269 6.619 0 6.658-13.238 6.658-13.238 16.626v3.31h9.938V46.19z"
        stroke="#1AB9C4"
        fill="none"
        fillRule="evenodd"
        strokeDasharray="3"
      />
    </svg>
  );
  /* eslint-enable */
}

export default QuestionOutline;
