import React from 'react';

function MasterCard() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="16" viewBox="0 0 24 16" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <filter x="-2.1%" y="-3.2%" width="104.2%" height="106.3%" filterUnits="objectBoundingBox" id="d">
          <feMorphology radius="1" in="SourceAlpha" result="shadowSpreadInner1" />
          <feOffset in="shadowSpreadInner1" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" in="shadowInnerInner1" />
        </filter>
        <rect id="c" x="0" y="0" width="24" height="15.75" rx="1.125" />
        <path
          d="M1.501.194A4.117 4.117 0 013 3.375c0 1.28-.583 2.425-1.499 3.181A4.11 4.11 0 010 3.375C0 2.132.55 1.018 1.419.262z"
          id="a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect fill="#F4F6F7" width="24" height="15.75" rx="1.125" />
        <g transform="translate(5.25 3.75)">
          <circle fill="#D8232A" cx="4.125" cy="4.125" r="4.125" />
          <path
            d="M9.375 0a4.125 4.125 0 11-2.626 7.307A4.114 4.114 0 008.25 4.125c0-1.28-.583-2.425-1.499-3.181A4.099 4.099 0 019.375 0z"
            fill="#ED9F2D"
          />
          <g transform="translate(5.25 .75)">
            <mask id="b" fill="#fff">
              <use xlinkHref="#a" />
            </mask>
            <use fill="#D8232A" xlinkHref="#a" />
            <path
              d="M3.75 6.75v.75h-4.5v-.75h4.5zm0-1.5V6h-4.5v-.75h4.5zm0-1.5v.75h-4.5v-.75h4.5zm0-1.5V3h-4.5v-.75h4.5zm0-1.5v.75h-4.5V.75h4.5z"
              fill="#ED9F2D"
              mask="url(#b)"
            />
          </g>
        </g>
        <use fillOpacity="0" fill="#E5E5E5" xlinkHref="#c" />
        <use fill="#000" filter="url(#d)" xlinkHref="#c" />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default MasterCard;
