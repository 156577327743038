import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Button, Modal, CircleAvatar, ConfirmationModalBody } from '../';

const PuppyPhoto = `/static/expiredAccountPuppyPhoto.jpg`;

class ExpiredAccountUpgradeModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpgrade: PropTypes.func.isRequired,
    daysRemaining: PropTypes.number.isRequired,
    allowClose: PropTypes.bool,
  };

  render() {
    const { isOpen, onClose, onUpgrade, daysRemaining, allowClose } = this.props;
    const closeProps = {
      closeOnEscape: allowClose || daysRemaining > 0,
      closeOnOutsideClick: allowClose || daysRemaining > 0,
      hasCloseButton: allowClose || daysRemaining > 0,
    };
    return (
      <Modal isOpen={isOpen} onClose={onClose} {...closeProps}>
        <ConfirmationModalBody
          image={<CircleAvatar size={150} imageUrl={PuppyPhoto} placeholderIcon="PuppyCircle" />}
          title="Membership Expired"
          subTitle={
            daysRemaining < 1
              ? 'Your listings are now inactive. Upgrade now to restore them and gain full access to all features!'
              : `Your listings will remain active until ${moment()
                  .add(daysRemaining, 'days')
                  .format(
                    'L'
                  )}, but you won't be able to respond to new messages or add new listings until you upgrade.`
          }
          actions={[
            <Button type="button" buttonType="round" onClick={onUpgrade}>
              UPGRADE NOW!
            </Button>,
            <Button type="button" buttonType="round" onClick={onClose}>
              MAYBE LATER
            </Button>,
          ]}
        />
      </Modal>
    );
  }
}

export default ExpiredAccountUpgradeModal;
