import MessageCenter from './MessageCenter';
import { connect } from 'react-redux';
import {
  getMoreArchivedConversations,
  getArchivedConversations,
  getMoreConversations,
  getConversations,
  getConversation,
  replyToConversation,
  markConversationAsRead,
  archiveConversation,
  unarchiveConversation,
  resetConversationLoaded,
  deleteMessagePhoto,
} from '../../redux/actions';
import { getUploadToken } from '../../../../redux/actions';
import { bindActionCreators } from 'redux';
import { selectConversationsWithUnreadMessages, conversations } from '../../redux/selectors';

const mapStateToProps = state => ({
  user: state.user,
  userVM: state.userVM,
  conversations: conversations(state),
  isMobile: state.browser.lessThan.tabletLarge,
  archivedConversationsLoaded: state.messages.archivedConversationsLoaded,
  conversationsLoaded: state.messages.conversationsLoaded,
  conversationLoading: state.messages.conversationLoading,
  conversationLoaded: state.messages.conversationLoaded,
  conversationPageAndTotal: state.messages.conversationPageAndTotal,
  conversationsWithUnreadMessages: selectConversationsWithUnreadMessages(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMoreArchivedConversations,
      getArchivedConversations,
      getMoreConversations,
      getConversations,
      getConversation,
      replyToConversation,
      markConversationAsRead,
      archiveConversation,
      unarchiveConversation,
      resetConversationLoaded,
      getUploadToken,
      deleteMessagePhoto,
    },
    dispatch
  );

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    getConversations: () => dispatchProps.getConversations(stateProps.user.get('id')),
    getArchivedConversations: () => dispatchProps.getArchivedConversations(stateProps.user.get('id')),
  });

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MessageCenter);
