import React from 'react';
import { connect } from 'react-redux';

const BrowserContext = React.createContext();

export function useBrowser() {
  const context = React.useContext(BrowserContext);

  if (!context) {
    throw new Error('useBrowser must be used within a BrowserProvider');
  }

  return context;
}

function Provider({ children, browser }) {
  return <BrowserContext.Provider value={browser}>{children}</BrowserContext.Provider>;
}

function mapStateToProps(state) {
  return {
    browser: state.browser,
  };
}

export const BrowserContextProvider = connect(mapStateToProps)(Provider);
