import client from '../../../common/utils/client';
import querystring from 'query-string';

export const searchListings = query => {
  let locationData = null;

  if (query.location) {
    let lat;
    let lng;
    if (typeof query.location === 'string') {
      const splitLocation = query.location.split(',');
      lat = splitLocation[0];
      lng = splitLocation[1];
    } else if (typeof query.location === 'object') {
      lat = query.location.lat;
      lng = query.location.lng;
    }
    locationData = { formatted_location: query.formatted_location, lat: lat, lng: lng };
  }

  return {
    promise: client.get(`listings/search?${querystring.stringify(query)}`),
    type: 'SEARCH_LISTINGS',
    location: locationData,
  };
};

export const resetListingsSearch = () => ({
  type: 'RESET_LISTINGS_SEARCH',
});

export const sendBrowserFingerprint = browser_fingerprint => ({
  promise: client.post('user/browser-check', { browser_fingerprint }),
  type: 'SEND_BROWSER_FINGERPRINT',
});
