import React, { useState } from 'react';
import { connect as felaConnect } from 'react-fela';
import Modal from '../Modal';
import BoostIcon from '../icons/Boost';
import theme from '../../../theme';
import ChooseListing from './ChooseListing';
import PurchaseBoosts from './PurchaseBoosts';
import BoostSuccess from './BoostSuccess';
import BoostError from './BoostError';

const Boost = function({ styles, listings, user, getBraintreeToken, purchaseBoostingAction, getMyListings }) {
  const [showModal, setShowModal] = useState(false);
  const [boostSteps, setBoostSteps] = useState(1);
  const [listingDays, setListingDays] = useState(3);
  const [boostedListings, setBoostedListings] = useState([]);

  const boostWizard = () => {
    if (boostSteps === 1) {
      return (
        <ChooseListing
          styles={styles}
          listings={listings}
          setBoostSteps={setBoostSteps}
          boostedListings={boostedListings}
          setBoostedListings={setBoostedListings}
          setListingDays={setListingDays}
          listingDays={listingDays}
          setShowModal={setShowModal}
          resetBoost={resetBoost}
        />
      );
    }
    if (boostSteps === 2) {
      return (
        <PurchaseBoosts
          listingDays={listingDays}
          boostedListings={boostedListings}
          user={user}
          styles={styles}
          onSubmit={onSubmit}
          getBraintreeToken={getBraintreeToken}
          resetBoost={resetBoost}
        />
      );
    }
    if (boostSteps === 3) {
      return (
        <BoostSuccess
          listingDays={listingDays}
          boostedListings={boostedListings}
          styles={styles}
          setBoostedListings={setBoostedListings}
          setShowModal={setShowModal}
          setBoostSteps={setBoostSteps}
        />
      );
    }
    if (boostSteps === 4) {
      return (
        <BoostError
          setBoostSteps={setBoostSteps}
          styles={styles}
          setBoostedListings={setBoostedListings}
          setShowModal={setShowModal}
        />
      );
    }
  };

  const onSubmit = async nonce => {
    const res = await purchaseBoostingAction({
      listingIds: boostedListings,
      duration: listingDays,
      payment_token: nonce,
      paymentProcessor: 'braintree',
    });
    if (!res) {
      resetBoost(4);
    } else {
      if (res.response.ok) {
        setBoostSteps(3);
        getMyListings('for_sale', 0);
      } else {
        resetBoost(4);
      }
    }
  };
  const resetBoost = step => {
    setShowModal(false);
    setBoostSteps(step);
    setBoostedListings([]);
  };

  // I hate you synapse, you created this mess.
  // Once a user is approved, their trial membership should start,
  // so we shouldn't have a scenario where boost would be shown to
  // an unapproved seller. But just in case..
  const plan = user.get('membership').get('plan');
  const canBoost =
    user.get('is_approved_seller') &&
    user.get('account_status') === 'Active' &&
    user.get('membership').get('status') === 'Active' &&
    ['trial', 'unverified_seller'].indexOf(plan) === -1;

  return (
    <>
      {canBoost ? (
        <div className={styles.container}>
          <div className={styles.cta}>
            <div>
              <strong>Rise to the top!</strong>
              <br />
              Boost your listings, starting at <strong>$3!</strong>
            </div>
            <button
              className={styles.boostButton}
              onClick={() => {
                setShowModal(true);
              }}
            >
              <BoostIcon color="#0085F2" />
              <span>BOOST</span>
            </button>
          </div>
        </div>
      ) : null}
      <Modal isOpen={showModal} onClose={() => resetBoost(1)} closeOnOutsideClick={true} closeOnEscape={true}>
        <div>{boostWizard()}</div>
      </Modal>
    </>
  );
};
const styles = props => ({
  container: {
    backgroundColor: theme.colors.boostBlue,
    color: 'white',
  },
  cta: {
    maxWidth: props.theme.globalMaxWidth,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    padding: '10px 10px',
    [props.theme.breakpoints.mobile]: {
      padding: '10px 20px',
    },
    fontSize: '14px',
    lineHeight: '20px',
  },
  header: {
    textAlign: 'center',
    padding: '0 40px 0px',
    '> div': {
      margin: '30px auto',
    },
  },
  moreInfoLink: {
    marginTop: '5px',
    fontWeight: 'bold',
    color: props.theme.colors.blue,
    textDecoration: 'underline',
  },
  footer: {
    padding: '0 40px',
    '> div': {
      margin: '30px',
    },
  },
  boostButton: {
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltBlack,
    backgroundColor: props.theme.colors.white,
    padding: '10px 18px',
    color: props.theme.colors.boostBlue,
    hoverColor: props.theme.colors.white,
    borderColor: 'transparent',
    borderRadius: '40px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },

  chooser: {
    overflow: 'hidden',
    maxWidth: '300px',
    height: '35px',
    margin: 'auto',
    display: 'flex',
    borderRadius: '1rem',
    border: `1px solid ${theme.colors.blue}`,
    textTransform: 'uppercase',
    '> button': {
      padding: '0.25rem',
      textAlign: 'center',
      width: '100%',
      fontWeight: 'bold',
      color: theme.colors.blue,
      '&.selected': {
        backgroundColor: theme.colors.blue,
        color: 'white',
      },
    },
  },
  modalHeader: {
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '30px',
    color: theme.colors.boostBlue,
  },

  listingDaysText: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '6px',
  },
  listingsList: {
    padding: '20px 40px',
    backgroundColor: 'white',
  },
  boostBtn: {
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltBlack,
    backgroundColor: props.theme.colors.boostBlue,
    padding: '5px 18px',
    color: props.theme.colors.white,
    hoverColor: props.theme.colors.white,
    borderColor: 'transparent',
    borderRadius: '40px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    margin: 'auto',
    height: '35px',
  },
  totalsContainer: {
    display: 'block',
    [props.theme.breakpoints.mobileSmall]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'self-end',
    },
  },
  rocketContainer: {
    marginTop: '30px',
  },
  successContainer: {
    margin: '30px',
    '> div': {
      margin: '30px auto',
    },
  },
  boostText: {
    maxWidth: '273px',
    margin: 'auto',
  },
  closeBtn: {
    fontWeight: props.theme.typography.sansAltBold,
    backgroundColor: props.theme.colors.boostBlue,
    padding: '10px 12px',
    color: props.theme.colors.white,
    hoverColor: props.theme.colors.white,
    borderColor: 'transparent',
    borderRadius: '40px',
    width: '165px',
  },
  boostListView: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  cancel: {
    marginTop: '10px',
    textDecoration: 'underline',
    color: props.theme.colors.red,
  },
});

export default felaConnect(styles)(Boost);
