import React from 'react';
import { Redirect } from 'react-router-dom';
import { Modal } from '../../../../../common/components';
import PurchaseWizard from '../../../common/components/PurchaseWizard';

const PurchaseFlow = ({
  purchaseMembership,
  getBraintreeToken,
  purchaseErrors,
  styles,
  planId,
  membershipPlans,
  user,
  userVM,
  onRequestCancel,
  checkForRecentTransaction,
}) => {
  const selectedPlan = membershipPlans.get(planId);
  if (!selectedPlan) {
    return null;
  }

  const userHasStandard = userVM.get('benefitLevel') === 'standard';
  const isBuyingPremier = selectedPlan.get('benefits') === 'premier';
  const isUpgrading = userHasStandard && isBuyingPremier;

  if (isBuyingPremier) {
    const needsUserProfile = !user.get('formatted_location');
    if (needsUserProfile) {
      return (
        <Redirect
          to={{
            pathname: '/account/my-profile',
            state: { plan: selectedPlan.get('id') },
          }}
        />
      );
    }

    const needsPremierProfile = !user.get('premierProfile');
    if (needsPremierProfile) {
      return (
        <Redirect
          to={{
            pathname: '/account/premier-profile',
            state: { fromMembership: true, plan: selectedPlan.get('id') },
          }}
        />
      );
    }

    return (
      <Modal closeOnEscape closeOnOutsideClick onClose={onRequestCancel} isOpen className={styles.paymentModal}>
        <PurchaseWizard
          onSubmit={({ nonce }) =>
            purchaseMembership({
              plan: selectedPlan.get('id'),
              payment_processor: 'braintree',
              payment_token: nonce,
            })
          }
          userVM={userVM}
          membershipPlans={membershipPlans}
          getBraintreeToken={getBraintreeToken}
          onCancel={onRequestCancel}
          plan={selectedPlan.get('id')}
          errors={purchaseErrors}
          user={user}
          checkForRecentTransaction={checkForRecentTransaction}
        />
      </Modal>
    );
  }

  const isBuyingStandard = selectedPlan.get('benefits') === 'standard';
  if (isBuyingStandard) {
    if (isUpgrading || userHasStandard) {
      return (
        <Modal closeOnEscape closeOnOutsideClick onClose={onRequestCancel} isOpen className={styles.paymentModal}>
          <PurchaseWizard
            onSubmit={({ nonce }) =>
              purchaseMembership({
                plan: selectedPlan.get('id'),
                payment_processor: 'braintree',
                payment_token: nonce,
              })
            }
            userVM={userVM}
            membershipPlans={membershipPlans}
            getBraintreeToken={getBraintreeToken}
            onCancel={onRequestCancel}
            plan={selectedPlan.get('id')}
            errors={purchaseErrors}
            user={user}
            checkForRecentTransaction={checkForRecentTransaction}
          />
        </Modal>
      );
    }
    return <Redirect to={{ pathname: '/account/my-profile', state: { plan: selectedPlan.get('id') } }} />;
  }

  return null;
};

export default PurchaseFlow;
