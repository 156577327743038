import React from 'react';
import { connect } from 'react-fela';

import MyProfileForm from './MyProfileForm';
import MyProfileHeader from './MyProfileHeader';
import MyProfileSubNav from './MyProfileSubNav';
import { Helmet } from 'react-helmet';
function MyProfile({
  updateUser,
  user,
  userVM,
  styles,
  location,
  purchaseMembership,
  setErrorBarMessage,
  membershipPlans,
  getUploadToken,
  membershipPlansLoaded,
  paymentHistoryLoaded,
  getMembershipPlans,
  getPayments,
  purchaseErrors,
  getBraintreeToken,
  deleteUserImage,
}) {
  useGetMembershipPlans({ membershipPlansLoaded, getMembershipPlans });
  useGetPaymentHistory({ braintreeCustomerId: user?.get('braintree_customer_id'), paymentHistoryLoaded, getPayments });
  return (
    <div className={styles.container}>
      <Helmet>
        <title>My Profile - Puppies.com</title>
      </Helmet>
      <MyProfileSubNav
        isPremier={userVM.get('isPremier')}
        isFirstTimeFillingOutProfile={location.state?.backTo === '/listings/create' || location.state?.purchaseType}
      />
      <div className={styles.wrapper}>
        <MyProfileHeader userId={user.get('id')} showLinkToPublicProfile={userVM.get('isActive')} />
        <MyProfileForm
          membershipPlans={membershipPlans}
          setErrorBarMessage={setErrorBarMessage}
          getUploadToken={getUploadToken}
          submitAction={updateUser}
          backTo={location.state?.backTo}
          purchaseType={location.state?.purchaseType}
          plan={location.state?.plan}
          purchaseMembership={purchaseMembership}
          purchaseErrors={purchaseErrors}
          getBraintreeToken={getBraintreeToken}
          user={user}
          userVM={userVM}
          deleteUserImage={deleteUserImage}
        />
      </div>
    </div>
  );
}

function useGetMembershipPlans({ membershipPlansLoaded, getMembershipPlans }) {
  React.useEffect(() => {
    if (!membershipPlansLoaded) {
      getMembershipPlans();
    }
  }, [membershipPlansLoaded, getMembershipPlans]);
}

function useGetPaymentHistory({ braintreeCustomerId, paymentHistoryLoaded, getPayments }) {
  React.useEffect(() => {
    if (!paymentHistoryLoaded && braintreeCustomerId) {
      getPayments({ page: 0, page_size: 6 });
    }
  }, [braintreeCustomerId, paymentHistoryLoaded, getPayments]);
}

const styles = {
  container: props => ({
    width: '100%',
    backgroundColor: props.theme.colors.tan,
    color: props.theme.colors.brown,
  }),
  wrapper: props => ({
    ...props.theme.globalPageWrapper,
  }),
};

export default connect(styles)(MyProfile);
