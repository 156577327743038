import { useCallback, useEffect, useRef, useState } from 'react';
import querystring from 'query-string';

import { defaults, mapFormValuesToQuery, mapQueryToFormValues } from './search-config';
import { getIsQueryFiltered, getQueryDifferences, getRedirectUrl, sanitizeQuery } from './search-utils';

import { getBreedFromUrl, getLocationFromUrl } from '../../../common/utils/search';

export const useManageSearchQuery = ({ history, isAdvancedSearch, match, path, searchValues }) => {
  const [isFiltered, setIsFiltered] = useState(false);

  const matchLocation = getLocationFromUrl(match);
  const city = matchLocation?.city?.toLowerCase();
  const formattedLocation = matchLocation?.formatted_location;
  const state = matchLocation?.short?.toLowerCase();
  const slug = match?.params?.breedOrState;

  useEffect(() => {
    const breed = getBreedFromUrl(slug);
    const comparison = mapQueryToFormValues(defaults);

    if (breed) {
      comparison.breed = [breed.id];
      comparison.formatted_breed = [breed.name];
    }

    if (city && state) {
      comparison.distance = 100;
      comparison.formatted_location = formattedLocation;

      if (searchValues.formatted_location) {
        comparison.formatted_location = searchValues.formatted_location;
      }
    }

    const query = mapFormValuesToQuery({ ...searchValues, advanced: isAdvancedSearch });
    const diff = getQueryDifferences(query, mapFormValuesToQuery(comparison));
    const redirectUrl = getRedirectUrl(searchValues);
    const method = path === redirectUrl ? history?.replace : history?.push;

    let showSearchButton = false;
    if (searchValues.breed.length || searchValues.state || searchValues.city) {
      showSearchButton = true;
    }

    setIsFiltered(getIsQueryFiltered(diff) || showSearchButton);

    method?.({
      pathname: redirectUrl,
      search: querystring.stringify(diff),
    });
  }, [city, formattedLocation, history, isAdvancedSearch, path, searchValues, slug, state]);

  return isFiltered;
};

export const useSearchListings = ({ loaded, path, searchListings, searchValues }) => {
  const shouldBeRedirected = path !== getRedirectUrl(searchValues);
  const skipInitialSearch = useRef(loaded && typeof window === 'undefined');

  useEffect(() => {
    if (skipInitialSearch.current) {
      return (skipInitialSearch.current = false);
    }

    if (!shouldBeRedirected) {
      const query = mapFormValuesToQuery(searchValues);
      searchListings(sanitizeQuery(query));
    }
  }, [searchListings, searchValues, shouldBeRedirected]);
};

export const useSearchValues = ({ initialValues, user }) => {
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(initialValues.advanced);
  const [searchValues, _setSearchValues] = useState(mapQueryToFormValues(initialValues, user));

  const setSearchValues = useCallback(
    values => {
      _setSearchValues(currentValues => {
        const userSetLocation = !values.formatted_location;
        return mapQueryToFormValues({ ...currentValues, advanced: isAdvancedSearch, ...values }, user, userSetLocation);
      });
    },
    [isAdvancedSearch, user]
  );

  const toggleAdvancedSearch = useCallback(() => setIsAdvancedSearch(currentValue => !currentValue), []);

  return { isAdvancedSearch, searchValues, setSearchValues, toggleAdvancedSearch };
};
