import React, { useState } from 'react';
import { Icon, LoadingCircle } from '../../common/components';
import { Link } from 'react-router-dom';
import parsePhoneNumber from 'libphonenumber-js';

export default function VerifyPhone({ styles, sendPhoneVerification, confirmPhoneVerification, ...props }) {
  document.title = 'Verify your phone | Puppies.com';
  const [error, setError] = useState('');
  const [state, setState] = useState(1);
  const [method, setMethod] = useState('sms');
  const [phoneNumber, setPhoneNumber] = useState();
  const [countryCode, setCountryCode] = useState('+1');
  const [isValid, setIsValid] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [code, setCode] = useState();
  const [loading, setLoading] = useState(false);

  // Step 1: Enter phone number
  const handleSubmitPhoneNumber = e => {
    e.preventDefault();
    if (isValid) {
      setError('');
      sendPhoneInfo();
    } else {
      setError('Please enter a valid phone number');
    }
  };

  const sendPhoneInfo = () => {
    setLoading(true);
    sendPhoneVerification(phoneNumber, countryCode, method).then(res => {
      if (res.response.ok) {
        setState(2);
        setLoading(false);
      } else {
        setLoading(false);
        setError(res.json.message);
      }
    });
  };

  const handlePhoneChange = e => {
    const phoneNum = parsePhoneNumber(e.target.value, 'US');
    if (phoneNum) {
      setPhoneNumber(phoneNum.nationalNumber);
      setCountryCode(`+${phoneNum.countryCallingCode}`);
      setIsValid(phoneNum.isValid());
    }
  };

  const renderPhoneFrom = () => {
    return (
      <>
        <div className={styles.iconContainer}>
          <Icon icon="PhoneChat" size={99} />
        </div>
        <h1 className={styles.header}>Get a verification code</h1>
        <p className={styles.subText}>
          Standard SMS rates may apply,
          <br />
          you can also get a phone call with a code.
        </p>

        <form id="begin-phone-verification-form" onSubmit={handleSubmitPhoneNumber} className={styles.form}>
          <div className={styles.flex}>
            <span>+1</span>
            <input
              className={styles.input}
              data-testid="phone-input"
              type="tel"
              id="phone"
              name="phone"
              required
              onChange={handlePhoneChange}
            />
          </div>
          {error.length ? <div className={styles.error}>{error}</div> : null}
          <p className={styles.CTA}>
            We require all users to verify their phone number.
            <br />
            This helps increase trust between all members.
            <br />
            Your verified number will <strong>never</strong> be visible to the public
            <br />
            You must use your own phone number for this process.
          </p>
          {loading ? (
            <div className={styles.processing}>
              <LoadingCircle /> Processing...
            </div>
          ) : (
            <div className={styles.submitContainer}>
              <button
                disabled={!isValid}
                type="submit"
                onClick={() => {
                  setMethod('call');
                }}
                className={!isValid ? styles.disabled : styles.btn}
              >
                RECEIVE A CALL
              </button>
              <button
                disabled={!isValid}
                type="submit"
                onClick={() => {
                  setMethod('sms');
                }}
                className={!isValid ? styles.disabled : styles.btn}
              >
                TEXT ME A CODE
              </button>
            </div>
          )}
        </form>
      </>
    );
  };

  // Step 2: Enter code
  const handleSubmitCode = e => {
    e.preventDefault();
    setLoading(true);
    if (code.length === 6) {
      confirmPhoneVerification(phoneNumber, countryCode, code).then(res => {
        if (res.response.ok) {
          setState(3);
          setLoading(false);
        } else {
          setLoading(false);
          setError('Error confirming code, please try again later');
        }
      });
    }
  };

  const handleCodeChange = e => {
    const code = e.target.value;
    if (code.length === 6) {
      setCode(code);
      setIsCodeValid(true);
    } else {
      setIsCodeValid(false);
    }
  };

  const renderConfirmationForm = () => {
    return (
      <>
        <div className={styles.iconContainer}>
          <Icon icon="PhoneVerification" size={99} />
        </div>
        <h1 className={styles.header}>Enter the code we texted you</h1>
        <p className={styles.subText}>
          You should receive the code within a<br />
          minute or so, enter it below. If you don’t
          <br />
          receive it, click Resend.
        </p>
        <form id="finish-phone-verification-form" onSubmit={handleSubmitCode} className={styles.form}>
          <div className={styles.flex}>
            <input
              className={styles.input}
              onChange={handleCodeChange}
              type="text"
              data-testid="code-input"
              name="code"
              required
            />
          </div>
          <p className={styles.CTA}>
            <strong>Never</strong> share your code with anyone. Puppies.com will never ask you for this code.
          </p>
          {loading ? (
            <div className={styles.processing}>
              <LoadingCircle /> Processing...
            </div>
          ) : (
            <button disabled={!isCodeValid} type="submit" className={!isCodeValid ? styles.disabled : styles.btn}>
              SUBMIT CODE
            </button>
          )}
        </form>
        <button className={styles.link} onClick={sendPhoneInfo}>
          Resend code
        </button>
      </>
    );
  };

  const setRedirectPath = () => {
    const params = new URLSearchParams(props.location?.search);
    if (params && params.get('to')) {
      return params.get('to');
    }
    return '/listings';
  };

  // Step 3: Success
  const renderSuccess = () => {
    return (
      <>
        <div className={styles.iconContainer}>
          <Icon icon="CircleCheck" size={71} />
        </div>
        <h1 className={styles.header}>Thanks for verifying your phone</h1>
        <p className={styles.subText}>
          Doing this helps increase safety for all
          <br />
          members of our community.
        </p>
        <p className={styles.CTA}>
          Your verified number is <br />
          <strong>{phoneNumber}</strong>
        </p>
        <div>
          <Link to={setRedirectPath()} className={styles.btn}>
            CONTINUE
          </Link>
        </div>
      </>
    );
  };

  // Render based on state
  const renderFromBasedOnState = () => {
    switch (state) {
      case 2:
        return renderConfirmationForm();
      case 3:
        return renderSuccess();
      default:
        return renderPhoneFrom();
    }
  };

  return <div className={styles.container}>{renderFromBasedOnState()}</div>;
}
