import React from 'react';

import FreestarAdSlot from '@freestar/pubfig-adslot-react-component';

const FsAd = props => {
  const publisher = 'puppies-com';
  const placementName = props.placementName; //must be a valid value from the list of placements FS has created
  const slotId = props.slotId ? props.slotId : undefined; //this is optional

  return <FreestarAdSlot publisher={publisher} placementName={placementName} slotId={slotId} />;
};

export default FsAd;
