import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import classNames from 'classnames';

class ListHeader extends Component {
  static propTypes = {
    styles: PropTypes.object,
    actions: PropTypes.array,
    center: PropTypes.bool,
  };

  render() {
    const { styles, horizontalActions, actions } = this.props;

    return (
      <div className={classNames([styles.header, actions && actions.length > 1 ? styles.headerStacked : null])}>
        <h1 className={styles.count}>
          <span>{this.props.children}</span>
        </h1>
        {actions ? (
          <div className={classNames([styles.actions, horizontalActions && styles.horizontalActions])}>
            {actions.map((action, i) => {
              return (
                <div className={styles.action} key={i}>
                  {action?.component || action}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

const styles = props => ({
  header: {
    display: 'flex',
    maxWidth: props.theme.globalMaxWidth,
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '20px auto',
    ...props.theme.globalPadding,
    [props.theme.breakpoints.mobileLarge]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  count: {
    ...props.theme.typography.titleText,
    textAlign: 'left',
    '@media (max-width: 480px)': {
      fontSize: '24px',
      textAlign: 'center',
    },
    '@media (max-width: 808px': {
      marginBottom: '10px',
    },
    [props.theme.breakpoints.mobileLarge]: {
      display: 'flex',
    },
    whiteSpace: 'no-wrap',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    alignItems: 'center',
    [props.theme.breakpoints.mobileLarge]: {
      flexDirection: 'row',
    },
    '@media (max-width: 809px)': {
      '> div': {
        width: '100%',
      },
    },
    '> div > button': {
      width: '100%',
    },
  },
});

export default connect(styles)(ListHeader);
