import React from 'react';

function Documents() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M20.82 5.871c.115.122.18.283.18.45v10.822C21 18.167 20.145 19 19.096 19h-1.7v-1.324h1.7c.301 0 .547-.238.547-.533V7.146H15.66c-.175 0-.317-.138-.317-.309V2.324H8.694c-.302 0-.547.239-.547.533v.852H6.789v-.852C6.79 1.833 7.644 1 8.694 1h7.215c.189 0 .369.077.497.213L20.82 5.87zm-4.843-3.16v3.817h3.618L15.977 2.71zm-3.362 2.41l4.412 4.606c.116.121.18.28.18.444v10.701c0 1.013-.854 1.836-1.903 1.836H4.903c-1.05 0-1.903-.823-1.903-1.836V6.747c0-1.012.853-1.835 1.903-1.835h7.214c.189 0 .37.076.498.21zM15.304 21.4c.3 0 .546-.237.546-.528V10.747H12.2c-.174 0-.316-.137-.316-.306V6.287l-.064-.066H4.903c-.302 0-.546.235-.546.526v14.125c0 .291.244.528.546.528h10.401zm.268-11.264l-3.054-3.188v3.188h3.054z" />
    </svg>
  );
  /* eslint-enable */
}

export default Documents;
