import React, { Component } from 'react';

import { Modal, Button, ConfirmationModalBody, CircleAvatar } from '../';

const TrialModalPhoto = `/static/trial-modal-photo.jpg`;

class StartTrialModal extends Component {
  render() {
    const { isOpen, onClose, onSubmit } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onClose} closeOnEscape closeOnOutsideClick>
        <ConfirmationModalBody
          image={<CircleAvatar size={150} imageUrl={TrialModalPhoto} placeholderIcon="PuppyCircle" />}
          title="Ready to list a puppy?"
          subTitle="Become part of a trusted community. Getting started is free, and only takes a few minutes."
          actions={[
            <Button type="button" buttonType="round" onClick={onSubmit}>
              GET STARTED!
            </Button>,
          ]}
        />
      </Modal>
    );
  }
}

export default StartTrialModal;
