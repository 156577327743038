import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { accountAgeSelector, isUserSelector } from './redux/selectors';
import { showAuthenticationModal } from '../Modal/redux/actions';
import Profile from './Profile';

const mapStateToProps = state => {
  return {
    userVM: state.userVM,
    profile: state.profile.activeProfile,
    loading: state.profile.profileLoading,
    listings: state.profile.listings,
    testimonials: state.profile.testimonials,
    total: state.profile.listingsTotal,
    accountAge: accountAgeSelector(state),
    user: state.user,
    token: state.token,
    isUser: isUserSelector(state),
    error: state.profile.profileError,
    sendMessageLoaded: state.messages.sendMessageLoaded,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      showAuthenticationModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
