import { compose, createStore, applyMiddleware } from 'redux';
import { createResponsiveStoreEnhancer } from 'redux-responsive';
import thunk from 'redux-thunk';
import apiRequestMiddleware from './apiRequestMiddleware';

// Apply Redux middleware
let storeEnhancer = compose(
  createResponsiveStoreEnhancer({ calculateInitialState: false }),
  applyMiddleware(apiRequestMiddleware, thunk)
);

const _createStore = (reducers, initialState) => createStore(reducers, initialState, storeEnhancer);

export default _createStore;
