import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import { Link } from 'react-router-dom';

class LandingPageFooter extends Component {
  render() {
    const { styles, query } = this.props;
    return (
      <div className={styles.root}>
        <Link to={`/find-a-puppy${query ? `?${query}` : ''}`} className={styles.button}>
          FIND A PUPPY NOW!
        </Link>
      </div>
    );
  }
}

const styles = props => ({
  root: {
    ...props.theme.globalBackgroundContainer,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px 0',
    '> * + *': {
      marginTop: '12px',
    },
  },
  button: {
    ...props.theme.linkStyles.largeRoundOrangeOutline,
  },
});

export default felaConnect(styles)(LandingPageFooter);
