import React from 'react';
import styled from 'styled-components';

import { Icon, ModalBody } from '../';

import theme from '../../../theme';

const ModalBodyStyled = styled(ModalBody)`
  background-color: ${theme.colors.blue};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 120px 40px !important;
  color: ${theme.colors.white};

  h1 {
    margin: 20px 0 42px;
    font-size: 35px;
    font-weight: ${theme.typography.sansBold};
    line-height: 47px;
  }
`;

const TestimonialModalSellerSuccess = ({ isReply = false }) => {
  return (
    <>
      <ModalBodyStyled>
        <Icon icon="SpeechBubble" size={55} />
        {isReply ? (
          <h1>
            <strong>Reply Submitted</strong>
            <br />
            Thanks!
          </h1>
        ) : (
          <h1>
            <strong>Thanks for your testimonial!</strong>
          </h1>
        )}
      </ModalBodyStyled>
    </>
  );
};

export default TestimonialModalSellerSuccess;
