import React, { Component, Fragment } from 'react';
import * as yup from 'yup';
import { withRouter } from 'react-router-dom';
import { withFormik, Field } from 'formik';
import { connect as felaConnect } from 'react-fela';
import querystring from 'query-string';
import moment from 'moment';

import { Button, ButtonLink, InputError, TextInput, Icon, ModalFooter, ButtonPlain } from '../../../common/components';
import theme from '../../../theme';

class SaveSearch extends Component {
  navigateToSavedSearches = () => {
    const { history, location } = this.props;
    history.push('/saved-searches', {
      prevLocation: location,
      backTo: 'Search',
    });
  };

  renderSuccessScreen = () => {
    const { styles, location } = this.props;
    return (
      <Fragment>
        <div className={styles.modalChildren}>
          <Icon icon="FatCheck" size={50} fill={theme.colors.green} />
          <div className={styles.modalTitle}>Search Saved</div>
          <div className={styles.modalDescription}>
            You can view your saved search results in the saved searches area of your account settings.
          </div>
        </div>
        <ModalFooter
          actions={[
            <ButtonPlain variant="red" type="button" onClick={this.props.closeModal}>
              Close
            </ButtonPlain>,
            <ButtonLink
              buttonType="round"
              to={{
                pathname: '/saved-searches',
                state: { prevLocation: location, backTo: 'Search' },
              }}
            >
              View Searches
            </ButtonLink>,
          ]}
        />
      </Fragment>
    );
  };

  renderForm = () => {
    const { isSubmitting, status, styles } = this.props;
    return (
      <Fragment>
        <div className={styles.modalChildren}>
          <div className={styles.modalTitle}>Save this search</div>
          <div className={styles.modalDescription}>
            Give this search a name so you remember what you were looking for. (We'll notify you when new puppies are
            available.)
          </div>
          <div className={styles.input}>
            <Field label="Search Title" name="name" component={TextInput} />
          </div>
          {status && status.error && <InputError>{status.error}</InputError>}
        </div>
        <ModalFooter
          actions={[
            <ButtonPlain variant="red" type="button" onClick={this.props.closeModal}>
              Cancel
            </ButtonPlain>,
            <Button type="submit" buttonType="round" disabled={isSubmitting}>
              Save Search
            </Button>,
          ]}
        />
      </Fragment>
    );
  };

  render() {
    const { status, handleSubmit } = this.props;

    return (
      <form id="save-search-form" onSubmit={handleSubmit}>
        {status && status.success ? this.renderSuccessScreen() : this.renderForm()}
      </form>
    );
  }
}

const FormikSaveSearchForm = withFormik({
  mapPropsToValues: props => ({
    name: (props.isBreedMatchmaker ? 'Breed Matchmaker ' : '') + moment().format('MMMM Do, YYYY [at] h:mm a'),
  }),
  validationSchema: yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(60, 'Title must be 60 characters or less')
      .required('Please enter a name for the search'),
  }),
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const parsedQuery = querystring.parse(props.location.search);
    const modifiedQuery = {
      ...parsedQuery,
      breed: props.breed,
      formatted_breed: props.formatted_breed,
      city: props.city,
      state: props.state,
    };

    if (!modifiedQuery.distance) {
      modifiedQuery.distance = '100mi';
    }

    // remove page and unwanted state modifiers from search query
    delete modifiedQuery.page;
    delete modifiedQuery.saved_search;
    delete modifiedQuery.save;
    delete modifiedQuery.seed;
    const submitValues = {
      name: values.name,
      ...modifiedQuery,
    };
    const action = await props.submitFunction(submitValues);
    setSubmitting(false);
    if (!action.response.ok) {
      setStatus({
        error: action.json ? action.json.message : 'An unknown error occurred',
      });
    } else {
      setStatus({ success: true });
    }
  },
})(SaveSearch);

const styles = props => ({
  modalChildren: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
    backgroundColor: '#F5F5F5',
    borderRadius: '6px',
  },
  modalTitle: {
    fontSize: '28px',
    lineHeight: '32px',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.blue,
    textAlign: 'center',
    margin: '10px 0 30px',
    borderRadius: '3px',
  },
  modalDescription: {
    fontSize: '14px',
    width: '100%',
  },
  input: {
    margin: '24px 0',
    width: '100%',
  },
  button: {
    marginTop: '70px',
  },
});

export default withRouter(felaConnect(styles)(FormikSaveSearchForm));
