export default function buildUploadParams(file, key, s3Bucket) {
  try {
    const typeData = file
      .split(':')
      .pop()
      .split(';')[0];

    const base64Data = Buffer(file.replace(/^data:\w+\/\w+;base64/, ''), 'base64');
    const params = {
      Key: key,
      Body: base64Data,
      ContentEncoding: 'base64',
      ContentType: typeData,
      Bucket: s3Bucket,
    };

    return params;
  } catch (e) {
    throw new Error(`Could not read file: ${e.message}`);
  }
}
