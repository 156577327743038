import React from 'react';
import { connect as felaConnect } from 'react-fela';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import querystring from 'query-string';
import Icon from '../icons/icon';
import theme from '../../../theme';

const Pager = props => {
  const { handlePaginate, history, location, styles, pageSize, totalItems } = props;

  const searchQuery = querystring.parse(location.search);
  const initialPage = searchQuery.page ? parseInt(searchQuery.page, 10) : 0;

  const onPageChange = page => {
    const pageSettings = {
      page: page.selected,
      page_size: pageSize,
    };

    if (handlePaginate) {
      return handlePaginate(pageSettings);
    } else {
      const newSearch = querystring.stringify({ ...searchQuery, ...pageSettings });
      return history.push({ pathame: location.pathName, search: newSearch });
    }
  };

  const totalPages = Math.ceil((totalItems || 0) / (pageSize || 1));

  if (totalPages <= 1) {
    return null;
  }

  return (
    <div className={styles.root}>
      <ReactPaginate
        disableInitialCallback
        onPageChange={onPageChange}
        initialPage={initialPage}
        pageCount={totalPages}
        pageRangeDisplayed={2}
        marginPagesDisplayed={5}
        previousLabel={
          <div className={styles.labelIcon}>
            <Icon icon="CaretLeft" size={12} />
            <div>Previous Page</div>
          </div>
        }
        nextLabel={
          <div className={styles.labelIcon}>
            Next Page
            <Icon icon="CaretLeft" size={12} rotate={180} />
          </div>
        }
        containerClassName={styles.container}
        nextClassName={styles.next}
        previousClassName={styles.previous}
        pageClassName={styles.page}
        pageLinkClassName={styles.pageLink}
        breakClassName={styles.break}
        activeClassName={styles.active}
        disabledClassName={styles.disabled}
      />
    </div>
  );
};

const styles = {
  root: props => ({
    margin: '0 auto',
    maxWidth: props.theme.globalMaxWidth,
    ...props.theme.globalPadding,
    position: 'relative',
    '> ul li a:hover': {
      cursor: 'pointer',
    },
    '> ul li:first-of-type a:hover, ul li:last-of-type a:hover': {
      backgroundColor: theme.colors.blue,
    },
    '> ul li:first-of-type a, ul li:last-of-type a': {
      fontFamily: theme.typography.sans,
      fontWeight: theme.typography.sansBold,
      backgroundColor: theme.colors.darkerBlue,
      padding: '12px',
      color: theme.colors.white,
      hoverColor: theme.colors.white,
      borderColor: 'transparent',
      minWidth: '128px',
      borderRadius: theme.globalBorderRadius,
      boxShadow: '0 2px 0 0 #167178;',
      fontSize: '12px',
      display: 'block',
    },
  }),
  labelIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  container: props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '15px',
    marginBottom: '15px',
    position: 'relative',
    height: '29px;',
    [props.theme.breakpoints.mobileLarge]: {
      marginTop: 0,
      marginBottom: '30px',
    },
    '> li:nth-last-of-type(2)': {
      ':after': {
        backgroundColor: 'transparent',
      },
    },
  }),
  pagerButton: {
    fontSize: '14px',
    height: '40px',
  },
  next: {
    position: 'absolute',
    right: 0,
    top: '-5px',
  },
  previous: {
    position: 'absolute',
    left: 0,
    top: '-5px',
  },
  page: props => ({
    lineHeight: '1.2',
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltLight,
    color: props.theme.colors.blue,
    display: 'none',
    position: 'relative',
    transition: props.theme.globalTransition,
    ':after': {
      content: '""',
      height: '100%',
      width: '1px',
      position: 'absolute',
      right: 0,
      backgroundColor: props.theme.colors.blue,
    },
    [props.theme.breakpoints.mobileLarge]: {
      display: 'flex',
    },
    ':hover': {
      textDecoration: 'underline',
    },
  }),
  active: props => ({
    fontWeight: `${props.theme.typography.sansAltBlack} !important`,
    color: `${props.theme.colors.orange} !important`,
  }),
  pageLink: props => ({
    justifyContent: 'center',
    display: 'flex',
    width: 'auto',
    minWidth: '32px',
    padding: '0 5px',
    alignItems: 'center',
    cursor: 'pointer',
    ':active': {
      outline: 'none',
    },
  }),
  break: props => ({
    lineHeight: '1.2',
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    width: '34px',
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltLight,
    color: props.theme.colors.blue,
    [props.theme.breakpoints.mobileLarge]: {
      display: 'flex',
    },
  }),
  disabled: {
    display: 'none',
  },
};

Pager.propTypes = {
  pageSize: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  handlePaginate: PropTypes.func,
};

export default withRouter(felaConnect(styles)(Pager));
