import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SlidingPane from 'react-sliding-pane';
import { Button, ButtonPlain, RadioButton, StandaloneField, Checkbox, Icon } from '../../common/components';
import { withFormik, Field, Form } from 'formik';
import { connect as felaConnect } from 'react-fela';

import theme from '../../theme';

class MobileSortFilterPane extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    sortOptions: PropTypes.array.isRequired,
    query: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  onClose = () => {
    const { resetForm, onClose } = this.props;

    resetForm();
    onClose();
  };

  render() {
    const { isOpen, onReset, sortOptions, values, setFieldValue, styles } = this.props;

    return (
      <SlidingPane isOpen={isOpen} onRequestClose={this.onClose} from="bottom" hideHeader width="100%">
        <div className={styles.slidingPaneTop}>
          <h1 className={styles.title}>Sort by:</h1>
          <button type="button" onClick={this.onClose}>
            <Icon icon="Close" size={20} fill={theme.colors.blue} />
          </button>
        </div>
        <div className={styles.slidingPaneMid}>
          <Form>
            {sortOptions.map(sortOption => {
              return (
                <Field
                  key={`sort-by-${sortOption.value}`}
                  name="sort"
                  component={RadioButton}
                  value={sortOption.value}
                  label={sortOption.label}
                  checked={values.sort === sortOption.value}
                />
              );
            })}
            <br />
            <h1 className={styles.title}>Filter by:</h1>
            <StandaloneField
              label="Featured Listings"
              labelIcon={<Icon icon="PremierBadge" size={16} />}
              name="premiers_only"
              component={Checkbox}
              value={values.premiers_only === 'true'}
              noLeftPadding
              onChange={e => {
                setFieldValue('premiers_only', e.currentTarget.value === 'false' ? 'true' : 'false');
              }}
            />
            <div className={styles.advancedSearchActions}>
              <div className={styles.slidingPaneReset}>
                <ButtonPlain variant="red" type="button" onClick={onReset} style={{ textAlign: 'center' }}>
                  Reset
                </ButtonPlain>
              </div>
              <div className={styles.button}>
                <Button type="submit" buttonType="primary">
                  Apply
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </SlidingPane>
    );
  }
}

const styles = props => ({
  slidingPaneTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '20px',
    padding: '0 10px',
  },
  title: {
    fontSize: '20px',
    lineHeight: '24px',
    color: props.theme.colors.orange,
  },
  slidingPaneMid: {
    padding: '0 10px',
    '> form > div > label': {
      margin: '8px 0',
    },
    ' span': {
      fontSize: '14px',
    },
  },
  test: {
    backgroundColor: 'red',
  },
  slidingPaneReset: {
    width: '100%',
    padding: '0 10px',
    marginTop: '12px',
    '> button': {
      width: '100%',
      textTransform: 'uppercase',
      fontSize: '14px',
      margin: '8px 0',
    },
  },
  button: {
    width: '100%',
    marginTop: '24px',
    '> button': {
      width: '100%',
    },
  },
  advancedSearchActions: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
    backgroundColor: props.theme.colors.tan,
    padding: '12px 16px',

    '> div': {
      display: 'flex',
      alignItems: 'center',
      marginTop: 0,
      '&:first-of-type': {
        flex: 1,
      },
      '&:last-of-type': {
        flex: 3,
      },
    },
  },
});

export default withFormik({
  mapPropsToValues: props => ({
    sort: props.query.sort,
    premiers_only: props.query.premiers_only,
  }),
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
    props.onClose();
  },
})(felaConnect(styles)(MobileSortFilterPane));
