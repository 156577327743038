/**
 * Asynchronously reads a file as a data URL
 * @param {File} file The file to load
 * @returns {Promise<string>} A promise resolving with a data URL representing the loaded file
 */
const loadFile = file =>
  new Promise(resolve => {
    const fr = new FileReader();
    fr.onload = ev => resolve(ev.target.result);
    fr.readAsDataURL(file);
  });

export default loadFile;
