import React from 'react';
import { Modal, LightBox, Icon } from '../../../../common/components';
import Carousel from 'nuka-carousel';
import { getFullSize, getThumbnail } from '../../../../common/utils/photo-helpers';
import theme from '../../../../theme';

const ListingGallery = ({ styles, photos, isMobile }) => {
  const [showLightbox, setShowLightbox] = React.useState(false);
  const [initialPhotoIndex, setInitialPhotoIndex] = React.useState(0);
  const listingPhotos = photos.toArray();

  if (listingPhotos.length === 0) {
    return (
      <div className={styles.noPhotoPlaceholder}>
        <Icon icon="Puppy" size={64} fill={theme.colors.extraLightBrown} />
        <p>No photos yet</p>
      </div>
    );
  }

  const [primaryPhoto, secondPhoto, thirdPhoto, ...remaining] = listingPhotos;

  return (
    <>
      <div className={styles.galleryContainer}>
        <div className={styles.primaryPhoto} style={{ marginRight: listingPhotos.length > 1 && !isMobile && '16px' }}>
          {isMobile ? (
            <MobileCarousel
              photos={photos}
              onClick={i => {
                setInitialPhotoIndex(i);
                setShowLightbox(true);
              }}
              styles={styles}
            />
          ) : (
            <img
              src={getFullSize(primaryPhoto)}
              alt="Preview"
              onClick={() => {
                setInitialPhotoIndex(0);
                setShowLightbox(true);
              }}
            />
          )}
        </div>
        {listingPhotos.length > 1 && (
          <div className={styles.secondaryPhotosContainer}>
            {secondPhoto && (
              <div
                className={styles.secondaryPhoto}
                onClick={() => {
                  setInitialPhotoIndex(1);
                  setShowLightbox(true);
                }}
              >
                <img src={getThumbnail(secondPhoto)} alt="Preview" />
              </div>
            )}
            {thirdPhoto && (
              <div
                className={styles.secondaryPhoto}
                style={{ position: 'relative' }}
                onClick={() => {
                  setInitialPhotoIndex(2);
                  setShowLightbox(true);
                }}
              >
                {remaining.length > 0 && <div className={styles.photosRemainingOverlay}>{`+${remaining.length}`}</div>}
                <img src={getThumbnail(thirdPhoto)} alt="Preview" />
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        closeOnEscape
        closeOnOutsideClick
        isOpen={showLightbox}
        transparent
        onClose={() => setShowLightbox(false)}
        className={styles.lightBoxModal}
        listingGallery
        fullLightbox
      >
        <LightBox
          photos={photos}
          initialImage={initialPhotoIndex}
          onClose={() => setShowLightbox(false)}
          fullLightbox
        />
      </Modal>
    </>
  );
};

const MobileCarousel = ({ photos, onClick, styles }) => {
  const [slideIndex, setSlideIndex] = React.useState(0);
  const carouselImages = photos.toArray().map(photo => getFullSize(photo));
  return (
    <Carousel
      autoplay
      wrapAround
      withoutControls
      disableKeyboardControls
      speed={250}
      swiping
      cellSpacing={38}
      dragging={false}
      slideIndex={slideIndex}
      afterSlide={slideIndex => setSlideIndex(slideIndex)}
      className={styles.listingCarousel}
    >
      {carouselImages.map((image, i) => {
        return (
          <div
            key={image}
            alt={`Listing Photo`}
            onClick={() => {
              onClick(i);
            }}
            className={styles.listingCarouselImage}
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        );
      })}
    </Carousel>
  );
};

export default ListingGallery;
