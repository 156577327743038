import { connect } from 'react-redux';
import { updateUser, getUploadToken, setErrorBarMessage, completeMigration } from '../../../../redux/actions';
import PuppyFindMigration from './PuppyFindMigration';

const mapStateToProps = state => {
  return {
    user: state.user,
    userVM: state.userVM,
  };
};

const mapDispatchToProps = {
  updateUser,
  getUploadToken,
  setErrorBarMessage,
  completeMigration,
};

export default connect(mapStateToProps, mapDispatchToProps)(PuppyFindMigration);
