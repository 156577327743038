import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import { Icon, CircleAvatar, NotificationBadge, ContentCapitalized, Button } from '../../../../common/components';
import PuppyCircleFill from '../../../../common/components/icons/PuppyCircleFill';
import EnvelopeOutline from '../../../../common/components/icons/EnvelopeOutline';
import generateInitials from '../../../../common/utils/generateInitials';
import { List, Map } from 'immutable';
import { connect as felaConnect } from 'react-fela';
import classNames from 'classnames';
import theme from '../../../../theme';
import isUnread from '../../utils/is-unread';
import getSystemMessageText from '../../utils/get-system-message-text';
import { motion } from 'framer-motion';

const ConversationsWrapperVariants = {
  visible: { x: '0', transition: { type: 'spring', stiffness: 400, damping: 30 } },
  hidden: { x: '-100%', transition: { type: 'spring', stiffness: 400, damping: 30 } },
};

const ListingConversationVariants = {
  visible: { x: '0', transition: { type: 'spring', stiffness: 400, damping: 30 } },
  hidden: { x: '100%', transition: { type: 'spring', stiffness: 400, damping: 30 } },
};

const MESSAGE_PREVIEW_LENGTH = 35;

class ConversationSidebar extends Component {
  static propTypes = {
    conversations: PropTypes.instanceOf(List).isRequired,
    currentUser: PropTypes.instanceOf(Map).isRequired,
    activeConversation: PropTypes.string,
    activeListing: PropTypes.string,
    onClickConversation: PropTypes.func.isRequired,
  };

  state = {
    inboxScrollPosition: 0,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isArchive !== this.props.isArchive) {
      this.setState({ inboxScrollPosition: 0 });
      this._wrapperRef.scrollTop = 0;
    }
  }

  isActiveConversation(conversation) {
    const { activeConversation } = this.props;
    return activeConversation && conversation.get('id') === activeConversation;
  }

  onClickListingGroup(conversation) {
    const { activeListing, onClickListingGroup, onClickConversation } = this.props;

    if (activeListing) {
      onClickListingGroup(conversation.get(null));
      onClickConversation(this.props.conversations.first());
    } else {
      onClickListingGroup(conversation.get('listing_id'));
      onClickConversation(conversation.getIn(['conversations', 0]));
    }
  }

  getOtherUser(conversation) {
    const { currentUser } = this.props;
    if (conversation.get('is_listing_group')) {
      return null;
    }
    const otherUser = conversation.get('users').find(user => user.get('id') !== currentUser.get('id'));
    if (!otherUser) {
      return null;
    }
    return otherUser;
  }

  getActiveListingGroup() {
    const { activeListing, conversations } = this.props;
    if (activeListing) {
      return conversations.find(conversation => conversation.get('listing_id') === activeListing);
    }
    return null;
  }

  renderMessagePreview(conversation) {
    const { currentUser } = this.props;
    const activeListing = this.getActiveListingGroup();
    const listing = conversation.get('listing') || (activeListing && activeListing.get('listing'));
    let messageText = null;
    let message = null;
    for (let i = 0; i < conversation.get('messages').size; i += 1) {
      message = conversation.getIn(['messages', conversation.get('messages').size - 1 - i]);

      if (!message.get('author_id') && message.get('body') === 'RESTRICTED') {
        return 'Membership expired. You need to upgrade to view this message.';
      }

      messageText = message.get('author_id')
        ? message.get('body')
        : getSystemMessageText(message, currentUser, listing);
      if (messageText) {
        break;
      }
    }
    if (!messageText) {
      return null;
    }
    if (messageText === 'NO_CONTENT') {
      return 'Attachment: image';
    }
    const suffix = messageText.length <= MESSAGE_PREVIEW_LENGTH ? '' : '...';
    return messageText.substr(0, MESSAGE_PREVIEW_LENGTH) + suffix;
  }

  renderNewMessageIndicator(conversation) {
    const { currentUser } = this.props;
    let hasUnread;
    const unreadColor = '#FF4E77';
    if (conversation.get('is_listing_group')) {
      const convoCount = conversation.get('conversations').size;
      hasUnread = conversation.get('conversations').reduce((acc, convo) => {
        if (acc) {
          return true;
        }
        return (
          acc || !!convo.get('messages').find(message => isUnread(message, currentUser, conversation.get('listing')))
        );
      }, false);
      const badgeProps = { content: convoCount };
      if (hasUnread) {
        badgeProps.color = unreadColor;
      }
      return <NotificationBadge {...badgeProps} />;
    }
    hasUnread = !!conversation
      .get('messages')
      .find(message => isUnread(message, currentUser, conversation.get('listing')));

    return hasUnread ? <NotificationBadge color={unreadColor} /> : null;
  }

  renderConversations(conversations) {
    return conversations.map(this.renderConversation);
  }

  renderConversation = conversation => {
    const { isArchive, styles, onClickConversation, isMobile, location } = this.props;

    const listing = conversation.get('listing');
    const activeListing = this.getActiveListingGroup();
    const otherUser = this.getOtherUser(conversation);
    const firstConversation = conversation.get('is_listing_group')
      ? conversation.getIn(['conversations', 0])
      : conversation;

    let photoToRender;
    if (listing) {
      photoToRender = listing.getIn(['photos', 0]);
    } else if (otherUser) {
      photoToRender = otherUser.get('photo');
    } else {
      photoToRender = null;
    }

    let nameToRender;
    if (listing) {
      nameToRender = listing.get('name');
    } else if (otherUser) {
      nameToRender = (
        <ContentCapitalized>
          {otherUser.get('first_name')} {otherUser.get('last_initial')}
        </ContentCapitalized>
      );
    } else {
      nameToRender = 'Account Deleted';
    }

    const classes = {
      [styles.conversation]: true,
      [styles.activeConversation]: !isMobile && this.isActiveConversation(conversation),
      [styles.conversationIndented]: activeListing && !listing,
    };

    if (!firstConversation) {
      return null;
    }

    const prefix = isArchive ? 'archive-' : '';
    const suffix = conversation.get('is_listing_group') ? conversation.get('listing_id') : conversation.get('id');
    return (
      <Link
        key={prefix + suffix}
        className={classNames(classes)}
        to={
          conversation.get('is_listing_group')
            ? location
            : {
                pathname: `/messages/${isArchive ? 'archive' : 'inbox'}/${conversation.get('id')}`,
                state: {
                  ...location.state,
                },
              }
        }
        onClick={e => {
          e.preventDefault();
          if (conversation.get('is_listing_group')) {
            if (!activeListing) {
              this.setState({ inboxScrollPosition: this._wrapperRef.scrollTop });
              this._wrapperRef.scrollTop = 0;
            } else {
              typeof window !== 'undefined' &&
                window.setTimeout(() => {
                  this._wrapperRef.scrollTop = this.state.inboxScrollPosition;
                }, 0);
            }
            this.onClickListingGroup(conversation);
          } else {
            onClickConversation(conversation);
          }
        }}
        data-testid="conversation"
      >
        {listing && listing.get('id') === this.props.activeListing ? (
          <div className={styles.caretLeft}>
            <Icon icon="Caret" rotate={180} size={14} fill={theme.colors.blue} />
          </div>
        ) : null}
        <div className={styles.conversationInner}>
          <div className={styles.conversationContentWrapper}>
            <CircleAvatar
              size={activeListing && !listing ? 40 : 55}
              badge={listing ? <PuppyCircleFill strokeFill="#fff" size={20} /> : ''}
              photo={photoToRender}
              placeholderIcon={listing ? 'PuppyCircle' : null}
              initials={otherUser ? generateInitials(otherUser.get('last_initial'), otherUser.get('first_name')) : 'NA'}
              verified={otherUser ? otherUser.get('id_verified') : false}
              alt={'conversation'}
              variant={this.isActiveConversation(conversation) && !isMobile ? 'white' : null}
            />
            <div className={styles.conversationContent}>
              <strong>{nameToRender}</strong>
              {conversation.get('is_listing_group') ? null : (
                <div className={styles.conversationPreview}>{this.renderMessagePreview(firstConversation)}</div>
              )}
              <div>
                {moment(
                  firstConversation
                    .get('messages')
                    .last()
                    .get('created_at')
                )
                  .locale('en-messaging')
                  .fromNow()}
              </div>
            </div>
          </div>
          <div className={styles.conversationEnd}>
            {conversation.get('is_listing_group') && !this.props.activeListing ? (
              <div className={styles.listingsLabel}>Your Listing</div>
            ) : null}
            <div className={styles.conversationEndInner}>
              {this.renderNewMessageIndicator(conversation)}
              {conversation.get('is_listing_group') && !this.props.activeListing ? (
                <Icon icon="Caret" size={14} fill={theme.colors.blue} />
              ) : null}
            </div>
          </div>
        </div>
      </Link>
    );
  };

  renderNoContentPlaceholder() {
    const { styles } = this.props;
    return (
      <div className={styles.noContentPlaceholder}>
        <EnvelopeOutline />
        <h3>No messages</h3>
      </div>
    );
  }

  renderListingConversation() {
    const { styles } = this.props;
    const activeListing = this.getActiveListingGroup();

    return activeListing ? (
      <div className={styles.conversationsWrapper}>
        {[this.renderConversation(activeListing), this.renderConversations(activeListing?.get('conversations'))]}
      </div>
    ) : null;
  }

  render() {
    const { conversations, styles, fetchMoreConversations, nextPage } = this.props;

    const activeListing = this.getActiveListingGroup();

    return (
      <div className={styles.conversationSidebar} ref={ref => (this._wrapperRef = ref)}>
        {conversations.size === 0 ? (
          this.renderNoContentPlaceholder()
        ) : (
          <motion.div
            variants={ConversationsWrapperVariants}
            animate={activeListing ? 'hidden' : 'visible'}
            className={classNames(
              styles.conversationAnimationWrapper,
              activeListing && styles.conversationAnimationWrapperHidden
            )}
          >
            <div id="conversationWrapper" className={styles.conversationsWrapper}>
              {this.renderConversations(conversations)}
              {nextPage ? (
                <Button id="load-more-conversations-btn" onClick={() => fetchMoreConversations(nextPage)}>
                  Load Older Conversations
                </Button>
              ) : null}
            </div>
          </motion.div>
        )}
        <motion.div
          variants={ListingConversationVariants}
          animate={activeListing ? 'visible' : 'hidden'}
          className={classNames(
            styles.conversationAnimationWrapper,
            !activeListing && styles.conversationAnimationWrapperHidden
          )}
        >
          {this.renderListingConversation()}
        </motion.div>
      </div>
    );
  }
}

const styles = props => ({
  conversationSidebar: {
    display: 'flex',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    overflowX: 'hidden',
    // maxHeight is set to account for sidebar header + actual header + footer
    maxHeight: 'calc(100vh - 188px)',
    width: props.isMobile ? '100%' : '342px',
  },
  conversationAnimationWrapper: {
    width: '100%',
    opacity: 1,
  },
  conversationAnimationWrapperHidden: {
    opacity: 0,
    width: 0,
    display: 'none',
  },
  conversationsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    WebkitOverflowScrolling: 'touch',
  },
  conversation: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '16px',
    borderBottom: `1px solid ${props.theme.colors.darkerTan}`,
    color: '#4B4E52',
    transition: `background-color 100ms ease-in-out`,
    width: props.isMobile ? '100%' : '340px',
    ':hover': {
      backgroundColor: props.theme.colors.tan,
    },
  },
  conversationIndented: {
    paddingLeft: '30px',
  },
  conversationInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  conversationContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '80%',
    '> * + *': {
      marginLeft: '20px',
    },
  },
  activeConversation: {
    backgroundColor: props.theme.colors.blue,
    color: `${props.theme.colors.white} !important`,
    ':hover': {
      backgroundColor: props.theme.colors.blue,
      color: `${props.theme.colors.white} !important`,
    },
  },
  conversationContent: {
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '> * + *': {
      paddingTop: '4px',
    },
  },
  conversationEnd: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '> * + *': {
      marginTop: '8px',
    },
  },
  conversationEndInner: {
    display: 'flex',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '4px',
    },
  },
  listingsLabel: {
    fontSize: '12px',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.green,
    backgroundColor: 'rgba(116, 207, 60, 0.25)',
    padding: '4px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  conversationPreview: {
    fontStyle: 'italic',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  caretLeft: {
    marginRight: '20px',
  },
  noContentPlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: props.theme.colors.tan,
    padding: '50px 34px 34px',
    width: props.isMobile ? '100%' : '340px',
    '> h3': {
      ...props.theme.typography.titleText,
      marginTop: '30px',
    },
  },
});

export default withRouter(felaConnect(styles)(ConversationSidebar));
