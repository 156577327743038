const styles = props => ({
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '24px',
  },
  innerWrapper: {
    maxWidth: '320px',
    margin: '0 auto',
    padding: '32px 0',
    '>h1': {
      marginTop: '10px',
      fontSize: '28px',
      lineHeight: '38px',
      fontWeight: props.theme.typography.sansBold,
    },
    '>p': {
      marginTop: '16px',
      color: props.theme.colors.brown,
    },
  },
  cancelModalBody: {
    padding: '64px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: '#D0021B',
    '>h1': {
      marginTop: '10px',
      fontSize: '28px',
      lineHeight: '38px',
      fontWeight: props.theme.typography.sansBold,
    },
    '>p': {
      marginTop: '16px',
      color: props.theme.colors.brown,
      width: '100%',
    },
  },
});

export default styles;
