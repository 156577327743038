import React, { Component, Fragment } from 'react';
import cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import * as Sentry from '@sentry/browser';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import isEqual from 'lodash/isEqual';
import TagManager from 'react-gtm-module';

import { getUser, autoLogin, clearErrorBar, getUserViewModel } from './redux/actions';
import './common/utils/momentLocales';

import { ForceReloadPrompt, GenericMeta } from './common/components';
import { setActiveModal } from './views/Modal/redux/actions';
import { getConversations } from './views/Messages/redux/actions.js';
import { addFavoritePremier, addFavoriteListing } from './views/Account/redux/actions';
import config from './common/config';
import PusherEvents from './contexts/PusherEvents';
import SignUpModal from './views/Modal/SignUpModal';
import SignInModal from './views/Modal/SignInModal';

class App extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     userLoaded: false,
  //   };
  // }
  static propTypes = {
    shouldUpdate: PropTypes.bool.isRequired,
    token: PropTypes.string,
    user: PropTypes.object,
  };

  componentDidMount() {
    const { getUser, token, user, getConversations, location } = this.props;
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML =
      "var _mtm = window._mtm = window._mtm || []; _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'}); (function() { var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0]; g.async=true; g.src='https://a.puppies.com/js/container_wu0BFuJv.js'; s.parentNode.insertBefore(g,s); })();";
    document.getElementsByTagName('head')[0].appendChild(s);

    if (token) {
      cookies.set('id_token', token, { expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7) });
    }

    if (token && !user) {
      getUser();
    }

    if (user) {
      getConversations(user.get('id'));
    }

    if (!location.prevLocation) {
      location.prevLocation = {
        hash: location.hash,
        pathname: location.pathname,
        search: location.search,
        state: location.state,
      };
    }

    if (config.googleTagManager) {
      TagManager.initialize(config.googleTagManager);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      clearErrorBar,
      setActiveModal,
      addFavoriteListing,
      history,
      getUser,
      token,
      location,
      user,
      getUserViewModel,
      userVMLoaded,
      userVMLoading,
      // userVM,
    } = this.props;

    if (!isEqual(prevProps.location, location)) {
      setActiveModal(null);
      clearErrorBar();
      location.prevLocation = {
        hash: prevProps.location.hash,
        pathname: prevProps.location.pathname,
        search: prevProps.location.search,
        state: prevProps.location.state,
      };
      typeof window !== 'undefined' && window.scrollTo(0, 0);
    }

    if (token && prevProps.token !== token) {
      getUser();
    }

    if (user) {
      document.body.dataset.loggedin = 'true';
      cookies.set('_mtm_id', user.get('email_lower'), { expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) });

      window?.freestar?.identity?.setIdentity({
        email: user.get('email_lower'),
      });

      if (window?.freestar) {
        window.freestar.config.disabledProducts = {
          video: true,
        };
      }
    }

    if (!prevProps.user && user) {
      getConversations(user.get('id'));
      getUserViewModel(user.get('id'));
    }

    if (user && !userVMLoaded && !userVMLoading) {
      getUserViewModel(user.get('id'));
    }

    const prevQuery = queryString.parse(prevProps.location.search);
    const query = queryString.parse(location.search);
    if (query.favoriteListing && query.favoriteListing !== prevQuery.favoriteListing) {
      addFavoriteListing(query.favoriteListing);
    }

    if (query.favoritePremier && query.favoritePremier !== prevQuery.favoritePremier) {
      addFavoritePremier(query.favoritePremier);
    }

    // TODO: BELOW MIGHT BE CAUSING INFINITE RERENDERS
    // Reroute historical Puppyfind.com users to migration path
    // if (userVM?.get('isMigratedUser')) {
    //   const reqs = user.get('historicalUserRequirements');
    //   if (reqs.get('needs_user_profile')) {
    //     if (location.pathname !== '/account/puppyfind-user') {
    //       history.push(`/account/puppyfind-user`);
    //     }
    //   } else if (reqs.get('needs_premier_profile')) {
    //     if (location.pathname !== '/account/puppyfind-premier') {
    //       history.push(`/account/puppyfind-premier`);
    //     }
    //   } else if (location.pathname !== '/account/puppyfind-complete' && userVM.get('hasCompletedMigration') !== true) {
    //     history.replace(`/account/puppyfind-complete`);
    //   }
    // }
    // TODO: Future developer enforcement of phone number verification below
    // // Phone number verification redirect
    // // Users must verify their phone number before they can use the site
    if (process.env.NODE_ENV !== 'development' && user && !user.get('phone_number_verified')) {
      if (location.pathname !== '/verify-phone-number') {
        history.push('/verify-phone-number');
      }
    }
  }

  handleReload = () => {
    typeof window !== 'undefined' && window.location.reload(true);
  };

  render() {
    const { shouldUpdate, user, route } = this.props;

    if (shouldUpdate) {
      return <ForceReloadPrompt onClick={this.handleReload} />;
    }

    return (
      route && (
        <Fragment>
          <GenericMeta user={user} />
          <PusherEvents
            enabled={config?.pusher?.enabled && Boolean(user)}
            channelName={`private-user-${user?.get('id')}`}
            events={[
              {
                eventName: 'reply-received',
                handler: this.props.wsReplyReceived,
              },
              {
                eventName: 'conversation-started',
                handler: this.props.wsConversationStarted,
              },
              {
                eventName: 'system-message',
                handler: this.props.wsSystemMessageReceived,
              },
              {
                eventName: 'reply-to-archived-conversation-received',
                handler: data => {
                  this.props.getConversations();
                  this.props.wsConversationRevived(data);
                },
              },
            ]}
          />
          <SignUpModal />
          <SignInModal />
          {renderRoutes(route.routes)}
        </Fragment>
      )
    );
  }
}

const mapStateToProps = state => {
  return {
    shouldUpdate: state.shouldUpdate,
    token: state.token,
    user: state.user,
    userVM: state.userVM,
    userVMLoaded: state.userVMLoaded,
    userVMLoading: state.userVMLoading,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser,
      getUserViewModel,
      autoLogin,
      clearErrorBar,
      setActiveModal,
      getConversations,
      addFavoritePremier,
      addFavoriteListing,
      wsReplyReceived: data => ({ type: 'WS_REPLY_RECEIVED', json: data.message, listingId: data.listing_id }),
      wsConversationStarted: data => ({ type: 'WS_CONVERSATION_STARTED', json: data }),
      wsConversationRevived: data => ({ type: 'WS_CONVERSATION_REVIVED', json: data }),
      wsSystemMessageReceived: data => ({ type: 'WS_SYSTEM_MESSAGE_RECEIVED', json: data }),
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
