import { withRouter } from 'react-router-dom';

import React, { Component } from 'react';
import { ContentNotFound } from '../../common/components';

class PageNotFound extends Component {
  render() {
    const { to, buttonText } = this.props.route.notFoundButton;

    return (
      <ContentNotFound
        icon="PuppyOutline"
        to={to}
        buttonText={buttonText}
        title="Sorry, we couldn't find the page you were looking for."
      />
    );
  }
}

export default withRouter(PageNotFound);
