import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import classNames from 'classnames';

class Tr extends Component {
  static propTypes = {
    styles: PropTypes.object,
    children: PropTypes.node.isRequired,
    transparent: PropTypes.bool,
  };

  render() {
    const { styles, children, className, transparent, ...other } = this.props;
    return (
      <tr className={classNames([transparent ? styles.transparent : styles.root, className && className])} {...other}>
        {children}
      </tr>
    );
  }
}

const styles = {
  root: props => ({
    backgroundColor: props.theme.colors.tan,
    '&:nth-child(odd)': {
      backgroundColor: props.theme.colors.darkerTan,
    },
  }),
  transparent: {
    backgroundColor: 'transparent',
  },
};

export default connect(styles)(Tr);
