import React from 'react';

function AddCircle() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm1.747-3.6c.197 0 .353-.156.353-.353V14.1h5.947c.197 0 .353-.156.353-.353v-3.494c0-.197-.156-.353-.353-.353H14.1V3.953c0-.197-.156-.353-.353-.353h-3.494c-.197 0-.353.156-.353.353V9.9H3.953c-.197 0-.353.156-.353.353v3.494c0 .197.156.353.353.353H9.9v5.947c0 .197.156.353.353.353h3.494z" />
    </svg>
  );
  /* eslint-enable */
}

export default AddCircle;
