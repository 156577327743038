import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import classNames from 'classnames';

class Td extends Component {
  static propTypes = {
    className: PropTypes.string,
    styles: PropTypes.object,
    children: PropTypes.node,
    regular: PropTypes.bool,
    fontSize: PropTypes.number,
    width: PropTypes.number,
    colSpan: PropTypes.number,
  };

  static defaultProps = {
    children: null,
  };

  render() {
    const { styles, children, width, className, colSpan } = this.props;
    return (
      <td width={width} className={classNames([styles.root, className])} colSpan={colSpan}>
        {children}
      </td>
    );
  }
}

const styles = {
  root: props => ({
    padding: '20px',
    fontWeight: props.regular ? props.theme.typography.sansRegular : props.theme.typography.sansBold,
    fontSize: props.fontSize ? `${props.fontSize}px` : '14px',
    verticalAlign: 'middle',
    '& > ul > li': {
      marginBottom: '8px',
    },
    [props.theme.breakpoints.mobileLarge]: {
      padding: '10px',
    },
  }),
};

export default connect(styles)(Td);
