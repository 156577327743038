import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { removeSavedSearch } from '../../../../redux/actions';
import SavedSearch from './SavedSearch';

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ removeSavedSearch }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SavedSearch);
