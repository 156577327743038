import React from 'react';
import { getThumbnail } from '../../../../../../common/utils/photo-helpers';
import { Modal, LightBox } from '../../../../../../common/components';
import { Icon } from '../../../../../../common/components';
const MessagePhotos = ({ messageId, styles, photos, deleteMessagePhoto, isOwner = false, canDelete = true }) => {
  const [currentPhotos, setCurrentPhotos] = React.useState(photos);
  const [showLightbox, setShowLightbox] = React.useState(false);
  const [initialPhotoIndex, setInitialPhotoIndex] = React.useState(0);

  const removeImage = (index, photo) => {
    deleteMessagePhoto(messageId, photo.get('key'));
    const newArray = currentPhotos.splice(index, 1);
    setCurrentPhotos(newArray);
  };

  return (
    <div className={styles.messagePhotosContainer}>
      {currentPhotos.map((photo, idx) => {
        return (
          <div className={styles.messagePhotoContainer} key={idx}>
            {isOwner && canDelete ? (
              <span className={styles.removeImage} onClick={() => removeImage(idx, photo)}>
                <Icon icon="PhotoDelete" size={12} />
              </span>
            ) : null}
            <img
              src={getThumbnail(photo)}
              alt="puppy"
              onClick={i => {
                setInitialPhotoIndex(idx);
                setShowLightbox(true);
              }}
            />
          </div>
        );
      })}
      <Modal
        closeOnEscape
        closeOnOutsideClick
        isOpen={showLightbox}
        transparent
        onClose={() => setShowLightbox(false)}
        className={styles.lightBoxModal}
        listingGallery
        fullLightbox
      >
        <LightBox
          photos={photos}
          initialImage={initialPhotoIndex}
          onClose={() => setShowLightbox(false)}
          // fullLightbox
        />
      </Modal>
    </div>
  );
};
export default MessagePhotos;
