import { connect } from 'react-redux';
import { getCoatPatterns, getColors, getUploadToken, getListingCount } from '../../../../redux/actions';
import { createListing } from '../../redux/actions';
import { selectCoatPatterns } from '../../../../redux/selectors';
import AddListing from './AddListing';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state, props) => ({
  coatPatternsLoading: state.coatPatternsLoading,
  coatPatterns: selectCoatPatterns(state),
  colorsLoading: state.colorsLoading,
  colors: state.colors,
  user: state.user,
  userVM: state.userVM,
  listingCount: state.listingCount,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getCoatPatterns, createListing, getUploadToken, getColors, getListingCount }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddListing);
