import React from 'react';

function Images() {
  /* eslint-disable max-len */
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9333 13.7143V14.2857C17.9333 15.2325 17.1573 16 16.2 16H2.33334C1.37603 16 0.600006 15.2325 0.600006 14.2857V5.14286C0.600006 4.19607 1.37603 3.42857 2.33334 3.42857H2.91112V10.8571C2.91112 12.4326 4.20707 13.7143 5.80001 13.7143H17.9333ZM21.4 10.8571V1.71429C21.4 0.7675 20.624 0 19.6667 0H5.80001C4.8427 0 4.06667 0.7675 4.06667 1.71429V10.8571C4.06667 11.8039 4.8427 12.5714 5.80001 12.5714H19.6667C20.624 12.5714 21.4 11.8039 21.4 10.8571ZM9.84445 3.42857C9.84445 4.37536 9.06842 5.14286 8.11112 5.14286C7.15381 5.14286 6.37778 4.37536 6.37778 3.42857C6.37778 2.48179 7.15381 1.71429 8.11112 1.71429C9.06842 1.71429 9.84445 2.48179 9.84445 3.42857ZM6.37778 8.57143L8.38249 6.58875C8.55171 6.42139 8.82608 6.42139 8.99533 6.58875L10.4222 8L15.3158 3.16018C15.485 2.99282 15.7594 2.99282 15.9287 3.16018L19.0889 6.28571V10.2857H6.37778V8.57143Z"
        fill="white"
      />
    </svg>
  );
  /* eslint-enable */
}

export default Images;
