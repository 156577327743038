import React from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import { Field } from 'formik';
import TextInput from '../TextInput';

import LicensingOptions from './LicensingOptions';
import PlainLink from '../PlainLink';

export const LicensingRadioGroup = ({ styles, user, values }) => {
  return (
    <div className={styles.licensedFieldsContainer}>
      <div className={styles.bioFieldLicensed} data-testid="federally-licensed">
        <span className={styles.licensedLabel}>
          I have a federal license
          <PlainLink
            className={styles.learnMoreLink}
            to="https://efile.aphis.usda.gov/LRAssistant/s/"
            variant="blue"
            target="new"
          >
            Learn More
          </PlainLink>
        </span>
        <LicensingOptions radioName="is_federally_licensed" styles={styles} user={user} />
        {values?.is_federally_licensed === 'yes' ? (
          <span className={styles.licenseId}>
            <Field
              name="federal_license_id"
              maxLength={20}
              component={TextInput}
              placeholder="Enter license number (optional)"
              data-testid="federal_license_id"
            />
          </span>
        ) : null}
      </div>
      <div className={styles.bioFieldLicensed} data-testid="state-licensed">
        <span className={styles.licensedLabel}>
          I have a state license
          <PlainLink
            className={styles.learnMoreLink}
            to="https://www.animallaw.info/topic/table-state-commercial-pet-breeders-laws"
            variant="blue"
            target="new"
          >
            Learn More
          </PlainLink>
        </span>
        <LicensingOptions radioName="is_state_licensed" styles={styles} user={user} />
        {values?.is_state_licensed === 'yes' ? (
          <span className={styles.licenseId}>
            <Field
              name="state_license_id"
              maxLength={15}
              component={TextInput}
              placeholder="Enter license number (optional)"
              data-testid="state_license_id"
            />
          </span>
        ) : null}
      </div>
    </div>
  );
};

LicensingRadioGroup.propTypes = {
  user: PropTypes.object,
};

const styles = props => ({
  licensedFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '32px',
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
    },
  },
  bioFieldLicensed: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    border: props.theme.globalDashedBorder,
    ':first-of-type': {
      marginBottom: '16px',
    },
    [props.theme.breakpoints.mobile]: {
      ':first-of-type': {
        marginRight: '20px',
        marginBottom: 0,
      },
    },
  },
  licensedLabel: {
    marginBottom: '16px',
  },
  bioFieldRadioContainer: {
    display: 'flex',
    flexDirection: 'column',
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
    },
  },
  bioFieldRadio: {
    marginBottom: '10px',
    marginRight: '16px',
    '> input:checked + label:before': {
      backgroundColor: props.theme.colors.blue,
      boxShadow: `inset 0 0 0 4px ${props.theme.colors.tan}`,
      border: `1px solid ${props.theme.colors.blue}`,
    },
    '> input:focus + label:before': {
      backgroundColor: props.theme.colors.blue,
      boxShadow: `inset 0 0 0 4px ${props.theme.colors.tan}`,
      border: `1px solid ${props.theme.colors.blue}`,
    },
    [props.theme.breakpoints.mobile]: {
      marginBottom: 0,
    },
  },
  bioRadioInput: {
    position: 'absolute',
    opacity: 0,
  },
  bioFieldRadioLabel: {
    ':before': {
      content: '" "',
      background: 'transparent',
      borderRadius: '100%',
      border: '1px solid black',
      display: 'inline-block',
      width: '24px',
      height: '24px',
      position: 'relative',
      top: '-0.2em',
      marginRight: '8px',
      verticalAlign: 'top',
      cursor: 'pointer',
      textAlign: 'center',
      transition: 'all 250ms ease',
    },
  },
  bioFieldLicensedOptions: {
    display: 'flex',
    flexDirection: 'row',
  },
  learnMoreLink: {
    marginLeft: '10px',
  },
  licenseId: {
    marginTop: '16px',
  },
});

export default felaConnect(styles)(LicensingRadioGroup);
