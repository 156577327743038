import React from 'react';
import { connect as felaConnect } from 'react-fela';
import { Icon } from '../../common/components';
import theme from '../../theme';

class ContactSuccess extends React.Component {
  render() {
    const { styles } = this.props;

    return (
      <div className={styles.contactSuccessContainer}>
        <div className={styles.contactSuccessIcon}>
          <Icon icon="Check" fill={theme.colors.white} size={64} />
        </div>
        <p className={styles.contactSuccessSubtitle}>Message Sent</p>
        <p>You'll hear back from one of our team members soon.</p>
      </div>
    );
  }
}

const styles = props => ({
  contactSuccessContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  contactSuccessIcon: {
    backgroundColor: props.theme.colors.green,
    borderRadius: '100%',
    padding: '14px',
    marginBottom: '30px',
  },
  contactSuccessSubtitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
});

export default felaConnect(styles)(ContactSuccess);
