import client from '../../../common/utils/client';
import querystring from 'query-string';

export const searchListings = query => {
  let locationData = null;
  if (query.location) {
    const splitLocation = query.location.split(',');
    locationData = { formatted_location: query.formatted_location, lat: splitLocation[0], lng: splitLocation[1] };
  }
  return {
    promise: client.get(`listings/search?${querystring.stringify(query)}`),
    type: 'LANDING_SEARCH_LISTINGS',
    location: locationData,
  };
};

export const resetListingsSearch = () => ({
  type: 'RESET_LANDING_SEARCH_LISTINGS',
});
