import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';
import { withFormik, Field, Form } from 'formik';
import { List, Map } from 'immutable';
import { renderRoutes } from 'react-router-config';
import isEqual from 'lodash/isEqual';
import querystring from 'query-string';
import { withRouter, Link } from 'react-router-dom';

import { TextInput, Icon, PlainLink } from '../../common/components/';
import theme from '../../theme';
import Sidebar from './common/Sidebar';
import { getRecentArticles, getFeaturedArticles, resetArticles } from './redux/actions';
import { Helmet } from 'react-helmet';
class LearningCenter extends Component {
  state = { showMenu: false };

  static propTypes = {
    articles: PropTypes.instanceOf(List),
    total: PropTypes.number,
    currentArticle: PropTypes.instanceOf(Map),
    featured: PropTypes.instanceOf(List),
    recent: PropTypes.instanceOf(List),
  };

  static fetchData(location, match, user) {
    return [getRecentArticles(), getFeaturedArticles()];
  }

  toggleMenu = () => this.setState(state => ({ showMenu: !state.showMenu }));

  componentDidMount() {
    const { recent, featured, location, match, user, dispatch } = this.props;

    if (!recent || !featured) {
      LearningCenter.fetchData(location, match, user).forEach(action => dispatch(action));
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (!isEqual(prevProps.location, location)) {
      this.setState({ showMenu: false });
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetArticles());
  }

  renderSearchField = () => {
    const { styles } = this.props;
    return (
      <Form>
        <div className={styles.searchWrapper}>
          <div className={styles.searchField}>
            <Field
              name="search"
              type="search"
              placeholder="Search the blog"
              component={TextInput}
              prefixPadding={38}
              prefix={<Icon icon="Search" size={17} fill={theme.colors.blue} />}
            />
          </div>
        </div>
      </Form>
    );
  };

  render() {
    const { styles, featured, recent, route, isMobile } = this.props;
    const { showMenu } = this.state;
    const url = window.location.href;
    const title = 'Blog | Puppies.com';
    const description =
      'Follow the Puppies.com blog for tips and information on buying or selling a puppy, care, training and more!';
    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
          <meta name="title" content={title} />
          <meta name="description" content={description} />
          <link rel="canonical" href={url} />
        </Helmet>
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            <div className={styles.titleText}>
              <Link to="/blog">Blog</Link>
            </div>
            <div className={styles.mobileView}>
              <button onClick={this.toggleMenu} className={styles.toggleButton}>
                <Icon icon="Caret" rotate={showMenu ? 270 : 90} fill={theme.colors.blue} size={20} />
              </button>
            </div>
            <div className={styles.desktopView}>{this.renderSearchField()}</div>
          </div>
          {showMenu && isMobile ? (
            <div>
              {this.renderSearchField()}
              <div className={styles.articleList}>
                <div className={styles.articleSection}>
                  <h1>Featured</h1>
                  {featured &&
                    featured.map(article => (
                      <PlainLink
                        to={`/blog/articles/${article.getIn(['sys', 'id'])}`}
                        key={article.getIn(['sys', 'id'])}
                        variant="blue"
                      >
                        {article.getIn(['fields', 'title'])}
                      </PlainLink>
                    ))}
                </div>
                <div className={styles.articleSection}>
                  <h1>Most Recent</h1>
                  {recent &&
                    recent.map(article => (
                      <PlainLink
                        to={`/blog/articles/${article.getIn(['sys', 'id'])}`}
                        key={article.getIn(['sys', 'id'])}
                        variant="blue"
                      >
                        {article.getIn(['fields', 'title'])}
                      </PlainLink>
                    ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className={styles.root}>
          {route && renderRoutes(route.routes)}
          <div className={styles.desktopView}>
            <Sidebar featured={featured} recent={recent} />
          </div>
        </div>
      </div>
    );
  }
}

const FormikLearningCenterSearch = withFormik({
  mapPropsToValues: props => ({
    search: '',
  }),
  handleSubmit: ({ search }, { props, resetForm }) => {
    if (search) {
      resetForm();
      props.history.push(`/blog/search?${querystring.stringify({ search, page: 0, page_size: 3 })}`);
    }
  },
})(withRouter(LearningCenter));

const mapStateToProps = state => ({
  isMobile: state.browser.lessThan.tablet,
});

const styles = props => ({
  root: {
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
    [props.theme.breakpoints.mobileLarge]: {
      padding: '0 20px',
      display: 'flex',
      justifyContent: 'space-between',
      '> * + *': {
        marginLeft: '100px',
        marginTop: '30px',
      },
    },
  },
  headerWrapper: {
    backgroundColor: props.theme.colors.darkerTan,
  },
  header: {
    ...props.theme.globalPadding,
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
    paddingRight: '0 !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [props.theme.breakpoints.mobileLarge]: {
      paddingTop: '24px !important',
      paddingBottom: '24px !important',
      paddingRight: '20px !important',
    },
  },
  titleText: props => ({
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: 300,
    fontSize: '28px',
    color: props.theme.colors.orange,
    lineHeight: '34px',
  }),
  toggleButton: props => ({
    height: '48px',
    width: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
    ':focus': {
      outline: 'none',
    },
  }),
  searchWrapper: {
    ...props.theme.globalPadding,
    marginBottom: '22px',
    display: 'flex',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '20px',
    },
    [props.theme.breakpoints.mobileLarge]: {
      marginBottom: 0,
      padding: '0 !important',
    },
  },
  searchField: {
    width: '100%',
    [props.theme.breakpoints.mobileLarge]: {
      width: '250px',
    },
  },
  articleList: {
    ...props.theme.globalPadding,
    paddingBottom: '32px !important',
    color: props.theme.colors.blue,
    fontSize: '14px',
    '> * + *': {
      marginTop: '30px',
    },
  },
  articleSection: {
    '> h1': {
      ...props.theme.typography.titleText,
    },
    '> a': {
      display: 'block',
      marginTop: '22px',
    },
  },
  mobileView: props => ({
    [props.theme.breakpoints.mobileLarge]: {
      display: 'none',
    },
  }),
  desktopView: props => ({
    display: 'none',
    [props.theme.breakpoints.mobileLarge]: {
      display: 'block',
    },
  }),
});

export default connect(mapStateToProps)(felaConnect(styles)(FormikLearningCenterSearch));
