import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { connect } from 'react-fela';
import classNames from 'classnames';
import theme from '../../../theme';
import { Icon } from '../';
import { AnimatePresence, motion } from 'framer-motion';

const modalVariants = {
  enter: {
    y: 0,
    opacity: 1,
    delay: 0.15,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 25 },
      default: { duration: 0.15 },
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 0.15 },
  },
};

const shadeVariants = {
  enter: { opacity: 1, transition: { duration: 0.15 } },
  exit: { opacity: 0 },
};

class Modal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    closeOnOutsideClick: PropTypes.bool,
    closeOnEscape: PropTypes.bool,
    hasCloseButton: PropTypes.bool,
    transparent: PropTypes.bool,
    styles: PropTypes.object.isRequired,
    fullLightbox: PropTypes.bool,
  };

  static defaultProps = {
    closeOnOutsideClick: false,
    closeOnEscape: false,
  };

  addMouseEvent = () => document.addEventListener('mousedown', this.handleDomClick);
  addEscapeEvent = () => document.addEventListener('keydown', this.handleOnEscape);
  removeMouseEvent = () => document.removeEventListener('mousedown', this.handleDomClick);
  removeEscapeEvent = () => document.removeEventListener('keydown', this.handleOnEscape);

  componentDidMount() {
    if (this.props.isOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.position = 'fixed';
    }
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.position = 'fixed';
    } else if (prevProps.isOpen && !this.props.isOpen) {
      document.body.style.overflowY = 'auto';
      document.body.style.position = 'static';
    }
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'auto';
    document.body.style.position = 'static';
  }

  isTopmostModal = () => {
    const { styles = {} } = this.props;
    const modalSelector = `.${styles.modal?.split(' ').join('.')}`;
    const modalPortals = document.querySelectorAll(modalSelector);
    return (
      this.portalRoot &&
      this.portalRoot.defaultNode &&
      this.portalRoot.defaultNode.contains(modalPortals[modalPortals.length - 1])
    );
  };

  isNodeInAnyModal = targetNode => {
    const { styles = {} } = this.props;
    const modalSelector = `.${styles.modal?.split(' ').join('.')}`;
    return [...document.querySelectorAll(modalSelector)].some(node => node.contains(targetNode));
  };

  handleDomClick = e => {
    const { isOpen, closeOnOutsideClick } = this.props;
    if (this.isTopmostModal() && !this.isNodeInAnyModal(e.target) && isOpen && closeOnOutsideClick) {
      this.props.onClose();
    }
  };

  handleOnEscape = e => {
    const { isOpen, closeOnEscape } = this.props;
    if (e.keyCode === 27 && isOpen && closeOnEscape) {
      this.props.onClose();
    }
  };

  setRef = node => {
    const { closeOnOutsideClick, closeOnEscape } = this.props;
    this.portalRoot = node;
    if (node) {
      if (closeOnEscape) {
        this.addEscapeEvent();
      }
      if (closeOnOutsideClick) {
        this.addMouseEvent();
      }
    } else {
      this.removeEscapeEvent();
      this.removeMouseEvent();
    }
  };

  render() {
    const {
      isOpen,
      children,
      styles,
      className,
      hasCloseButton,
      hasMobileCloseButton,
      listingGallery,
      fullLightbox,
    } = this.props;

    if (isOpen) {
      return (
        <Portal ref={this.setRef}>
          <AnimatePresence>
            <motion.div
              key="shade"
              className={styles.overlay}
              initial={shadeVariants.exit}
              animate={shadeVariants.enter}
              exit={shadeVariants.exit}
              inherit={false}
            >
              <motion.div
                key="modal"
                className={classNames([styles.modal, styles.ieModal, className])}
                style={{ display: '-webkit-flex', top: `${listingGallery}` ? '0' : '10px' }}
                initial={modalVariants.exit}
                animate={modalVariants.enter}
                exit={modalVariants.exit}
                inherit={false}
              >
                {hasCloseButton || hasMobileCloseButton ? (
                  <div
                    className={classNames(
                      styles.header,
                      hasMobileCloseButton && styles.hasMobileCloseButton,
                      fullLightbox && styles.fullLightbox
                    )}
                  >
                    <button onClick={() => this.props.onClose()} className={styles.iconClose} aria-label="close">
                      <Icon icon="Close" size={30} fill={theme.colors.white} />
                    </button>
                  </div>
                ) : null}
                <div className={styles.modalChildren}>{children}</div>
              </motion.div>
            </motion.div>
          </AnimatePresence>
        </Portal>
      );
    }
    return null;
  }
}

const styles = {
  overlay: props => ({
    height: '100%',
    width: '100%',
    backgroundColor: `${props.fullLightbox ? 'rgba(0,0,0,1)' : 'rgba(0, 0, 0, 0.6)'}`,
    padding: props.fullLightbox ? 0 : '10px',
    position: 'fixed',
    overflow: 'auto',
    top: 0,
    left: 0,
    zIndex: 9998,
    WebkitOverflowScrolling: 'touch',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',

    [props.theme.breakpoints.tablet]: {
      justifyContent: 'center',
    },
  }),
  modal: props => ({
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: props.fullLightbox ? '100%' : '540px',
    overflow: 'visible',

    top: 0,
    position: 'relative',

    [props.theme.breakpoints.tabletLarge]: {
      margin: '0 auto',
    },
  }),
  ieModal: props => ({
    display: '-ms-flexbox',
  }),
  modalChildren: props => ({
    height: '100%',
    borderRadius: '6px',
    fontSize: '14px',
    lineHeight: '22px',
    color: props.theme.typography.bodyColor,
    textAlign: 'center',
    backgroundColor: props.transparent ? 'none' : props.theme.colors.tan,
    overflow: 'visible',
  }),
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '50px',
  },
  hasMobileCloseButton: props => ({
    display: 'flex',
    [props.theme.breakpoints.tabletLarge]: {
      display: 'none',
    },
  }),
  iconClose: {
    backgroundColor: 'transparent',
  },
  modalTitle: {
    lineHeight: '32px',
  },
};

export default connect(styles)(Modal);
