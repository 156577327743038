import React from 'react';

function EnvelopeOutline() {
  /* eslint-disable max-len */
  return (
    <svg width="82" height="62" viewBox="0 0 82 62" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41 36L1 6c0-2.773 2.227-5 5-5h70c2.773 0 5 2.227 5 5L41 36zm0 8.984l40-30V56c0 2.773-2.227 5-5 5H6c-2.773 0-5-2.227-5-5V14.984l40 30z"
        stroke="#1AB9C4"
        fill="none"
        fillRule="evenodd"
        strokeDasharray="3"
      />
    </svg>
  );
  /* eslint-enable */
}

export default EnvelopeOutline;
