import { createSelector } from 'reselect';

export const resultsSelector = createSelector(
  state => state.search.results,
  results => {
    if (!results) {
      return null;
    }
    const sortedResults = results.sortBy(results.photos, photo => photo.get('sort_index'));
    return sortedResults;
  }
);
