export const setActiveModal = modal => {
  return {
    type: 'SET_ACTIVE_MODAL',
    modal,
  };
};

export const showAuthenticationModal = () => ({
  type: 'SHOW_AUTHENTICATION_MODAL',
});
