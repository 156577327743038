import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import { List } from 'immutable';
import querystring from 'query-string';
import { connect } from 'react-redux';

import Article from '../common/Article';
import { fetchArticles, resetArticles } from '../redux/actions';
import { Pager, Loading, NoContentPlaceholder } from '../../../common/components';

const PAGE_SIZE = 6;

class SearchResults extends Component {
  static propTypes = {
    articles: PropTypes.instanceOf(List),
    total: PropTypes.number,
    fetchArticles: PropTypes.func,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    articles: List(),
  };

  static fetchData(location, match, user) {
    const { search, page, page_size } = querystring.parse(location.search);

    return [fetchArticles('search', search, page, page_size)];
  }

  componentDidMount() {
    const { location, match, user, dispatch, loaded } = this.props;

    if (!loaded) {
      SearchResults.fetchData(location, match, user).forEach(action => dispatch(action));
    }
  }

  componentDidUpdate(prevProps) {
    const { location, match, user, dispatch, loaded } = this.props;

    const locationChanged = prevProps.location !== location;
    const loadedStatusChanged = prevProps.loaded && !loaded;
    if (locationChanged || loadedStatusChanged) {
      SearchResults.fetchData(location, match, user).forEach(action => dispatch(action));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetArticles());
  }

  renderPager = () => {
    const { total } = this.props;

    if (total <= PAGE_SIZE) {
      return null;
    }

    return <Pager totalItems={total} pageSize={PAGE_SIZE} />;
  };

  render() {
    const { articles, location, loading, styles } = this.props;
    const { search } = querystring.parse(location.search);

    if (loading) {
      return <Loading center dark />;
    }

    if (!articles.size) {
      return (
        <NoContentPlaceholder title="No results found." description="Try searching for something less specific." />
      );
    }

    return (
      <div>
        <h1 className={styles.h1}>Showing results for "{search}"</h1>
        {articles.map(article => (
          <Article
            key={article.getIn(['sys', 'id'])}
            article={article.get('fields')}
            preview
            id={article.getIn(['sys', 'id'])}
          />
        ))}
        {this.renderPager()}
      </div>
    );
  }
}

const styles = props => ({
  h1: {
    padding: '0 10px',
    margin: '30px 0',
    fontSize: '18px',
    color: props.theme.colors.brown,
    opacity: '0.5',
    [props.theme.breakpoints.mobileLarge]: {
      padding: '0',
    },
  },
});

const mapStateToProps = state => ({
  loading: state.articles.loading,
  articles: state.articles.articleList.get('list'),
  total: state.articles.articleList.get('total'),
  loaded: state.articles.articleList.get('loaded'),
});

export default connect(mapStateToProps)(felaConnect(styles)(SearchResults));
