import React from 'react';

function PuppyCircleFill({ size, strokeWidth, strokeFill, fill, iconFill }) {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size || 24} height={size || 24} viewBox="0 0 24 24">
      <g transform="translate(2 2)" fill="none" fillRule="evenodd">
        <circle
          stroke={strokeFill || '#1AB9C4'}
          strokeWidth={strokeWidth || 2}
          fill={fill || '#1AB9C4'}
          cx="10"
          cy="10"
          r="10"
        />
        <path
          d="M5.891 14.087c-.012-.405.076-.857.141-1.27.26-1.637 1.01-3.088 2.132-3.94-.548-.295-.947-.885-1.232-1.749-.553-1.676.071-3.364 1.742-3.918 1.534-.509 2.72-.096 3.332 1.27.089-.014.183-.024.283-.029.062-.166.221-.283.408-.28.236.006.423.201.418.438 0 .05-.012.098-.029.142.551.625 1.016 2.147-1.002 3.677 1.051.628 1.27 2.112 2.294 4.521.876 2.062 1.322 2.434.407 2.446-.699.01-2.052-2.887-2.052-2.887.103 1.845-1.314 2.027-1.314 2.027 2.215-.024 1.86.968 1.86.968-.878.159-1.832.236-2.83.215-1.112-.023-2.572-.203-3.423-.405-1.006.055-3.047-.101-3.026-2.274 0 0 .938 1.19 1.891 1.048"
          fill={iconFill || '#FFF'}
        />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default PuppyCircleFill;
