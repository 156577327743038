import React from 'react';

function CaretDown() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M2 9.255c0-.114.049-.21.117-.295l2.919-2.846a.445.445 0 0 1 .595 0l6.365 6.224 6.365-6.224a.445.445 0 0 1 .596 0l2.919 2.846a.4.4 0 0 1 0 .58l-9.577 9.346a.458.458 0 0 1-.605 0L2.117 9.54A.443.443 0 0 1 2 9.255z"
        fillRule="evenodd"
      />
    </svg>
  );
  /* eslint-enable */
}

export default CaretDown;
