import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';
import classNames from 'classnames';
import moment from 'moment';

import { Label, InputError } from '../';
import CalendarIcon from '../icons/Calendar';

class DatePickerInput extends Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
      setFieldValue: PropTypes.func.isRequired,
    }),
    label: PropTypes.node,
    labelVariant: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    max: PropTypes.number,
  };

  handleChange = date => {
    const {
      form: { setFieldValue },
      field: { name },
    } = this.props;

    setFieldValue(name, moment(date));
  };

  blurInput = e => {
    e.target.blur();
  };

  render() {
    const {
      field,
      form,
      className,
      styles,
      label,
      labelVariant,
      disabled,
      required,
      isMobile,
      maxDays,
      ...passableProps
    } = this.props;
    const error = form.touched[field.name] && form.errors[field.name];

    return (
      <div>
        <Label variant={labelVariant} disabled={disabled}>
          <label className={styles.label}>
            {label}
            {required ? '*' : null}
          </label>
        </Label>
        <div className={styles.datePickerContainer}>
          <DatePicker
            name={field.name}
            onBlur={field.onBlur}
            onChange={this.handleChange}
            withPortal={isMobile}
            disabled={disabled}
            selected={field.value ? field.value.toDate() : null}
            className={classNames([styles.root, error ? styles.error : null])}
            calendarClassName={styles.calendar}
            placeholderText="MM/DD/YYYY"
            autoComplete="off"
            onFocus={isMobile ? this.blurInput : () => {}}
            minDate={moment().subtract(30, 'years')}
            maxDate={maxDays ? moment().add(maxDays, 'days') : null}
            showYearDropdown={isMobile}
            {...passableProps}
          />
          <CalendarIcon />
        </div>
        <InputError>{error}</InputError>
      </div>
    );
  }
}

const styles = props => ({
  datePickerContainer: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    '> svg': {
      fill: props.theme.colors.blue,
      position: 'absolute',
      top: '13px',
      right: '8px',
      width: '16px',
      height: '16px',
      pointerEvents: 'none',
    },
    '@media (max-width: 400px)': {
      width: '100%',
      '> div > .react-datepicker-wrapper': {
        width: '100%',
        '> .react-datepicker__input-container': {
          width: '100%',
        },
      },
    },
  },
  root: {
    width: '100%',
    display: 'block',
    color: props.theme.typography.bodyColor,
    fontFamily: 'inherit',
    fontSize: '14px',
    height: '42px',
    fontWeight: 'normal',
    backgroundColor: props.theme.colors.white,
    border: `1px solid ${props.theme.colors.disabled}`,
    borderRadius: props.theme.globalBorderRadius,
    padding: '10px 8px',
    appearance: 'none', // Reset default inputs for iOS/etc.
    boxShadow: 'rgb(160, 160, 161) 0px 8px 1px -7px inset',
    ':focus': {
      outline: 'none', // Disable default focus glow
      borderColor: props.theme.colors.blue,
    },
  },
  error: {
    color: props.theme.colors.red,
    border: `1px solid ${props.theme.colors.red}`,
    boxShadow: `0px 0px 0px 1px ${props.theme.colors.red} !important`,
    ':focus': {
      outline: 'none', // Disable default focus glow
      borderColor: props.theme.colors.red,
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: props.insetLabel ? '8px 0 0 13px' : '0 0 6px',
    position: props.insetLabel ? 'absolute' : 'relative',
    fontSize: props.insetLabel ? '9px' : '12px',
    zIndex: props.insetLabel ? 1 : 0,
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
});

const mapStateToProps = state => ({
  isMobile: state.browser.lessThan.tablet,
});

export default connect(mapStateToProps)(felaConnect(styles)(DatePickerInput));
