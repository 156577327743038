import React from 'react';

function Tweet() {
  /* eslint-disable max-len */
  return (
    <svg width="32" height="8" viewBox="0 0 32 8" xmlns="http://www.w3.org/2000/svg">
      <title>Slice</title>
      <defs>
        <path
          d="M2.354 1.452H0V0h6.435v1.452H4.081v6.402H2.354V1.452zm11.319 6.402h-1.606l-1.012-3.817h-.022l-.968 3.817H8.448L6.644 2.167h1.65l1.045 3.861h.022l.946-3.861h1.518l.968 3.85h.022l1.045-3.85h1.606l-1.793 5.687zm3.74-2.464c.022.506.15.873.385 1.1.235.227.572.341 1.012.341.315 0 .587-.079.814-.237.227-.157.367-.324.418-.5h1.375c-.22.682-.557 1.17-1.012 1.463-.455.293-1.005.44-1.65.44-.447 0-.85-.071-1.21-.215-.36-.143-.664-.346-.913-.61-.25-.264-.442-.58-.578-.946-.135-.367-.203-.77-.203-1.21 0-.425.07-.821.209-1.188.14-.367.337-.684.594-.951.257-.268.563-.479.919-.633.355-.154.75-.231 1.182-.231.484 0 .906.093 1.265.28.36.188.654.439.886.754.23.315.397.675.5 1.078.103.403.14.825.11 1.265h-4.103zm6.314 0c.022.506.15.873.385 1.1.235.227.572.341 1.012.341.315 0 .587-.079.814-.237.227-.157.367-.324.418-.5h1.375c-.22.682-.557 1.17-1.012 1.463-.455.293-1.005.44-1.65.44-.447 0-.85-.071-1.21-.215-.36-.143-.664-.346-.913-.61-.25-.264-.442-.58-.578-.946-.135-.367-.203-.77-.203-1.21 0-.425.07-.821.209-1.188.14-.367.337-.684.594-.951.257-.268.563-.479.919-.633.355-.154.75-.231 1.182-.231.484 0 .906.093 1.265.28.36.188.654.439.886.754.23.315.397.675.5 1.078.103.403.14.825.11 1.265h-4.103zm-3.773-.99c-.073-.396-.203-.697-.39-.902-.188-.205-.472-.308-.853-.308-.25 0-.456.042-.621.127-.166.084-.297.188-.396.313-.1.125-.17.257-.21.396-.04.14-.064.264-.071.374h2.541zm6.314 0c-.073-.396-.203-.697-.39-.902-.188-.205-.472-.308-.853-.308-.25 0-.456.042-.621.127-.166.084-.297.188-.396.313-.1.125-.17.257-.21.396-.04.14-.064.264-.071.374h2.541zm4.466-2.233h1.144v1.045h-1.144v2.816c0 .264.044.44.132.528.088.088.264.132.528.132.088 0 .172-.004.253-.011.08-.007.158-.018.231-.033v1.21c-.132.022-.279.037-.44.044-.161.007-.319.011-.473.011-.242 0-.471-.016-.688-.05-.216-.033-.406-.097-.572-.192-.165-.095-.295-.231-.39-.407-.095-.176-.143-.407-.143-.693V3.212h-.946V2.167h.946V.462h1.562v1.705z"
          id="b"
        />
        <filter x="-1.6%" y="-6.3%" width="103.1%" height="125%" filterUnits="objectBoundingBox" id="a">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" in="shadowOffsetOuter1" />
        </filter>
      </defs>
      <g fillRule="nonzero" fill="none">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#333" xlinkHref="#b" />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default Tweet;
