import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';

class TextLink extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { children, styles, className } = this.props;
    return <div className={styles.root + ' ' + className}>{children}</div>;
  }
}

const styles = {
  root: props => ({
    color: props.theme.colors.blue,
    textDecoration: 'underline',
    transition: props.theme.globalTransition,
    ':hover': {
      color: props.theme.colors.darkerBlue,
    },
  }),
};

export default connect(styles)(TextLink);
