const moment = require('moment');

module.exports = function humanizeDate(date, locale = 'en') {
  moment.relativeTimeThreshold('M', 12);
  moment.relativeTimeThreshold('h', 24);
  const inWeeks = moment.duration(moment().diff(moment(date))).asWeeks();

  if (inWeeks >= 1 && inWeeks < 5) {
    let weekString;
    if (inWeeks < 2) {
      weekString = '1 week';
    } else {
      weekString = `${Math.floor(inWeeks)} weeks`;
    }
    return weekString;
  }

  return moment
    .duration(moment(date).diff(moment()))
    .locale(locale)
    .humanize();
};
