import { connect } from 'react-redux';
import EmailVerified from './EmailVerified';

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(EmailVerified);
