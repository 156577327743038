import React from 'react';
import { connect as felaConnect } from 'react-fela';
import classNames from 'classnames';
import { Icon } from '..';

const IconButton = ({ styles, title, icon, variant, size = 14, children, ...passThru }) => {
  const classes = {
    [styles.root]: true,
    [styles.red]: variant === 'red',
    [styles.blue]: variant === 'blue',
    [styles.orange]: variant === 'orange',
    [styles.disabled]: variant === 'disabled',
  };

  return (
    <button type="button" className={classNames(classes)} {...passThru}>
      <Icon icon={icon} size={size} />
      {title && <span className={styles.title}>{title}</span>}
    </button>
  );
};

export default felaConnect(props => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '14px',
    textDecoration: 'underline',
    transition: props.theme.globalTransition,
    color: props.theme.colors.darkerGray,
    ':hover': {
      color: '#404040',
    },
    ':focus': {
      outline: 'none',
    },
  },
  title: {
    marginLeft: '4px',
  },
  blue: {
    color: `${props.theme.colors.blue} !important`,
    ':hover': {
      color: `${props.theme.colors.blue} !important`,
    },
  },
  red: {
    color: `${props.theme.colors.red} !important`,
    ':hover': {
      color: props.theme.colors.darkerRed,
    },
  },
  orange: {
    color: `${props.theme.colors.orange} !important`,
    ':hover': {
      color: props.theme.colors.darkerRed,
    },
  },
  disabled: {
    color: `${props.theme.colors.disabledText} !important`,
    ':hover': {
      color: `${props.theme.colors.disabledText} !important`,
      cursor: 'default',
    },
  },
}))(IconButton);
