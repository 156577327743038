import React from 'react';

function Lock() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M19.125 10.625A1.37 1.37 0 0 1 20.5 12v9.625A1.37 1.37 0 0 1 19.125 23H5.375A1.37 1.37 0 0 1 4 21.625V12a1.37 1.37 0 0 1 1.375-1.375H6.75V6.5c0-3.03 2.47-5.5 5.5-5.5s5.5 2.47 5.5 5.5v4.125h1.375zM9.5 6.5v4.125H15V6.5a2.742 2.742 0 0 0-2.75-2.75A2.742 2.742 0 0 0 9.5 6.5z" />
    </svg>
  );
  /* eslint-enable */
}

export default Lock;
