import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { PaymentForm } from '../../../../../common/components';

import PremierModalPaymentHeader from './PremierModalPaymentHeader';
import StandardModalPaymentHeader from './StandardModalPaymentHeader';

const PaymentStep = ({ onCancel = () => {}, onSubmit = () => {}, price = 0, selectedPlan, token, userVM, user }) => {
  return (
    <>
      {selectedPlan.get('benefits') === 'premier' ? (
        <PremierModalPaymentHeader price={price} selectedPlan={selectedPlan} userVM={userVM} />
      ) : (
        <StandardModalPaymentHeader planId={selectedPlan.get('id')} planName={selectedPlan.get('name')} price={price} />
      )}
      <PaymentForm onCancel={onCancel} onSubmit={nonce => onSubmit(nonce)} token={token} />
      <input type="hidden" data-for="google" id="payment_amount" value={price / 100} />
      <input type="hidden" data-for="google" id="user_email" value={user.get('email')} />
    </>
  );
};

PaymentStep.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  price: PropTypes.number,
  selectedPlan: PropTypes.instanceOf(Map).isRequired,
  token: PropTypes.string,
  userVM: PropTypes.instanceOf(Map),
};

export default PaymentStep;
