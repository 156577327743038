import React from 'react';

function CheckCircleFill() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M24,12 C24,18.62744 18.62744,24 12,24 C5.37256,24 0,18.62744 0,12 C0,5.37256 5.37256,0 12,0 C18.62744,0 24,5.37256 24,12 Z M10.61196,18.3539 L19.5152,9.45068 C19.81752,9.14836 19.81752,8.65814 19.5152,8.35582 L18.42034,7.26096 C18.11802,6.9586 17.6278,6.9586 17.32544,7.26096 L10.06452,14.52184 L6.67456,11.13188 C6.37224,10.82956 5.88204,10.82956 5.57966,11.13188 L4.4848,12.22674 C4.18248,12.52906 4.18248,13.01928 4.4848,13.3216 L9.51706,18.35386 C9.81944,18.65622 10.3096,18.65622 10.61196,18.3539 L10.61196,18.3539 Z" />
    </svg>
  );
  /* eslint-enable */
}

export default CheckCircleFill;
