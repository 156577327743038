import React from 'react';

function Heart() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M20.665 14.526l-8.304 8.328c-.117.082-.223.14-.363.14-.14 0-.281-.058-.363-.14L3.33 14.526C-.64 10.556-.886 6.445 1.668 3.915c2.53-2.518 6.64-2.518 9.159 0 .48.48.866 1.007 1.17 1.558.305-.55.692-1.078 1.172-1.558 2.518-2.553 6.63-2.553 9.182 0 2.53 2.53 2.284 6.64-1.686 10.611z" />
    </svg>
  );
  /* eslint-enable */
}

export default Heart;
