import React from 'react';
import AccountCancellationWizard from '../../../common/components/AccountCancellationWizard';
import { Modal, ButtonPlain } from '../../../../../common/components';

const CancellationLink = ({ user, styles, userVM, cancelMembership }) => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <div className={styles.cancelRow}>
      {userVM.get('hasActiveSubscription') && (
        <ButtonPlain variant="red" onClick={() => setShowModal(true)} id="membershipCancelationButton">
          Modify Membership
        </ButtonPlain>
      )}
      <Modal closeOnEscape closeOnOutsideClick onClose={() => setShowModal(false)} isOpen={showModal}>
        <AccountCancellationWizard
          onCancel={() => setShowModal(false)}
          errors={0}
          cancelMembership={cancelMembership}
          userVM={userVM}
        />
      </Modal>
    </div>
  );
};

export default CancellationLink;
