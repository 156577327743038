import React from 'react';

function Star() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M23.858 9.856l-5.836 5.812 1.477 7.746c.023.2-.059.387-.223.504-.082.059-.2.082-.281.082-.082 0-.188-.023-.27-.082L12 19.582l-6.727 4.336c-.164.106-.387.106-.55 0-.165-.117-.247-.304-.223-.504l1.476-7.746L.14 9.856c-.14-.13-.164-.352-.117-.551.082-.2.281-.305.48-.305h7.665L11.53.328c.082-.187.27-.328.469-.328.199 0 .386.14.468.328L15.831 9h7.664c.2 0 .398.106.48.305.047.199.024.422-.117.55z" />
    </svg>
  );
  /* eslint-enable */
}

export default Star;
