import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

class Status extends Component {
  static propTypes = {
    children: PropTypes.node,
    code: PropTypes.number,
  };

  render() {
    const { children, code } = this.props;

    return (
      <Route
        render={({ staticContext }) => {
          if (staticContext) {
            staticContext.status = code;
          }
          return children;
        }}
      />
    );
  }
}

export default Status;
