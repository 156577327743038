module.exports = {
  feedbackManager: {
    tips: [
      {
        icon: '',
        title: 'Feedback Received',
        tip: 'This is where you can see and manage testimonials left about you.',
        elementId: 'feedback-received-tip',
      },
    ],
  },
  newUser: {
    tips: [
      {
        icon: 'Envelope',
        title: 'Message Center',
        tip: 'This is where you’ll communicate buyers or sellers about puppies.',
        elementId: 'messages-tip',
      },
      {
        icon: 'Heart',
        title: 'Favorites',
        tip: "This is where you'll find puppies or breeders you have favorited.",
        elementId: 'listings-tip',
      },
      {
        icon: 'AvatarCircle',
        title: 'Main Menu',
        tip: 'This is where you’ll find your account settings, testimonials, membership, and more.',
        elementId: 'main-dropdown-menu',
      },
    ],
  },
  newUserMobile: {
    tips: [
      {
        icon: 'AvatarCircle',
        title: 'Main Menu',
        tip:
          'This is where you’ll access things like Messaging, account settings, and your testimonials. Tap to open it',
        elementId: 'mobile-main-menu-tip',
      },
    ],
  },
};
