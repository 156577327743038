import client from '../../../common/utils/client';

export const createListing = values => ({
  promise: client.post('listings', values),
  type: 'CREATE_LISTING',
});

export const getListing = listingId => ({
  promise: client.get(`listings/${listingId}`),
  type: 'GET_LISTING',
});

export const editListing = (values, listingId) => ({
  promise: client.patch(`listings/${listingId}`, values),
  type: 'EDIT_LISTING',
});

export const deleteListing = listingId => ({
  promise: client.delete(`listings/${listingId}`),
  type: 'DELETE_LISTING',
  listingId,
});

export const updateListingStatus = (payload, listingId) => ({
  promise: client.put(`listings/${listingId}/status`, payload),
  type: 'EDIT_LISTING',
});

export const trackListingWebsiteClick = listingId => ({
  promise: client.put(`listings/${listingId}/website-click`),
  type: 'TRACK_LISTING_WEBSITE_CLICK',
});

export const resetListing = () => ({
  type: 'RESET_LISTING',
});
