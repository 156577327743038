import React from 'react';

function InteractiveBookmark({ stroke, fill }) {
  /* eslint-disable max-len */
  return (
    <svg width="42" height="37" viewBox="0 0 42 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.5194 9.46133C39.9982 7.47629 38.9738 5.66701 37.5497 4.21626C36.1255 2.76551 34.3521 1.72468 32.4085 1.1989C30.4224 0.827336 28.3766 0.978049 26.4631 1.63687C24.5497 2.2957 22.8316 3.441 21.4701 4.96516C21.3381 5.09034 21.1647 5.15989 20.9846 5.15989C20.8045 5.15989 20.6311 5.09034 20.4991 4.96516C19.1376 3.441 17.4195 2.2957 15.5061 1.63687C13.5927 0.978049 11.5468 0.827336 9.56076 1.1989C7.61715 1.72468 5.8437 2.76551 4.41955 4.21626C2.9954 5.66701 1.971 7.47629 1.44983 9.46133C0.884452 11.4329 0.851411 13.524 1.35421 15.5132C1.857 17.5024 2.8769 19.3154 4.30579 20.7601L20.0707 35.6208C20.3377 35.865 20.6835 36 21.0417 36C21.4 36 21.7457 35.865 22.0128 35.6208L37.8062 20.7017C39.2082 19.2525 40.2005 17.4426 40.6781 15.4638C41.1558 13.4849 41.1009 11.4109 40.5194 9.46133Z"
        stroke={stroke || '#FFF'}
        strokeWidth="2"
        fill={fill || 'rgba(255,255,255,0.5)'}
        fillRule="evenodd"
      />
    </svg>
  );
  /* eslint-enable */
}

export default InteractiveBookmark;
