import React from 'react';
import { connect as felaConnect } from 'react-fela';
import { withFormik } from 'formik';

import { Button, InputError } from '../../../../common/components';

const EmailVerificationForm = ({ user, values, errors, handleSubmit, isSubmitting, status, styles }) => (
  <form id="email-verification-form" onSubmit={handleSubmit}>
    <span className={styles.email}>{values.user.get('email')}</span>
    <Button buttonType="roundSmall" type="submit" disabled={isSubmitting}>
      {status && status.notifyResend ? 'Resend' : 'Send Link'}
    </Button>
    {status && status.notifyResend ? <div className={styles.sent}>SENT. AWAITING CONFIRMATION</div> : null}
    {status && status.error && <InputError>{status.error}</InputError>}
  </form>
);

const FormikEmailVerificationForm = withFormik({
  handleSubmit: async (values, { props, setSubmitting, setStatus, setErrors }) => {
    setStatus({});
    const action = await props.sendEmailVerification(props.user.get('email'));
    setSubmitting(false);
    if (!action.response.ok) {
      setStatus({
        error: action.json ? action.json.message : 'An unknown error occurred',
      });
    }
    setStatus({ notifyResend: true });
  },
})(EmailVerificationForm);

const styles = {
  email: props => ({
    color: props.theme.colors.brown,
    fontWeight: props.theme.typography.sansBold,
    fontSize: '14px',
    marginRight: '16px',
    wordBreak: 'break-all',
  }),
  sent: props => ({
    fontSize: '12px',
    color: props.theme.colors.green,
    fontWeight: props.theme.typography.sansBold,
    margin: '20px 0',
  }),
};

export default felaConnect(styles)(FormikEmailVerificationForm);
