import React from 'react';

function Rocket() {
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.75 7.5V15" stroke="#FA6125" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M37.5 11.25H30" stroke="#FA6125" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M60 78.75V86.25" stroke="#FA6125" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M63.75 82.5H56.25" stroke="#FA6125" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M31.575 41.6625L48.975 59.0625"
        stroke="#1AB9C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.7875 78.75C19.2 84.3375 8.47499 82.5 8.47499 82.5C8.47499 82.5 6.48749 71.775 12.225 66.1875C12.9853 65.2762 13.9436 64.5503 15.0268 64.0653C16.11 63.5803 17.2896 63.3489 18.4758 63.3887C19.662 63.4284 20.8234 63.7384 21.8717 64.2949C22.92 64.8514 23.8275 65.6398 24.525 66.6C25.4428 67.3153 26.1899 68.226 26.7119 69.266C27.2339 70.306 27.5178 71.4492 27.543 72.6126C27.5681 73.776 27.3339 74.9304 26.8572 75.992C26.3806 77.0536 25.6736 77.9957 24.7875 78.75V78.75Z"
        stroke="#FA6125"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.45 12.1875C46.65 8.99999 37.2 31.4625 25.95 51.9375L38.6625 64.65C59.175 53.3625 81.6375 43.9875 78.45 12.1875Z"
        stroke="#1AB9C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.85 39.45L27.9375 37.8C26.2689 37.3479 24.5099 37.3494 22.8421 37.8043C21.1742 38.2591 19.658 39.1509 18.45 40.3875L13.1625 45.675C12.8601 45.9801 12.6397 46.3566 12.5217 46.7696C12.4037 47.1826 12.3919 47.6187 12.4875 48.0375C12.6054 48.4279 12.8289 48.778 13.1333 49.0494C13.4377 49.3207 13.8112 49.5026 14.2125 49.575L25.9875 51.9375"
        stroke="#1AB9C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.3001 57.75L52.8376 62.7C53.2896 64.3686 53.2882 66.1276 52.8333 67.7954C52.3784 69.4633 51.4867 70.9795 50.2501 72.1875L45.0001 77.475C44.7071 77.7835 44.3374 78.0085 43.9289 78.1272C43.5204 78.2458 43.0876 78.2536 42.6751 78.15C42.2756 78.0421 41.9153 77.8224 41.6363 77.5169C41.3574 77.2113 41.1713 76.8326 41.1 76.425L38.7375 64.65"
        stroke="#1AB9C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.3625 38.025C63.0905 38.025 66.1125 35.0029 66.1125 31.275C66.1125 27.5471 63.0905 24.525 59.3625 24.525C55.6346 24.525 52.6125 27.5471 52.6125 31.275C52.6125 35.0029 55.6346 38.025 59.3625 38.025Z"
        stroke="#1AB9C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Rocket;
