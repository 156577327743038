import React from 'react';
import PropTypes from 'prop-types';

import { Button, ModalBody, ModalFooter, ModalHeader } from '../../../../../common/components';

const PaymentError = ({ errors = 0, errorMessage = null, onCancel = () => {} }) => {
  return (
    <>
      <ModalHeader title="Payment Failed" />
      <ModalBody>
        <div>
          {`There was an issue processing your payment.
        ${
          errors < 3
            ? 'Please check your information and try again.'
            : 'Please contact your card provider for assistance.'
        }`}
        </div>

        {errorMessage && <div style={{ marginTop: 10, color: 'red' }}>Error: {errorMessage}</div>}
      </ModalBody>
      <ModalFooter
        actions={[
          <Button buttonType="round" onClick={onCancel} type="button">
            DONE
          </Button>,
        ]}
      />
    </>
  );
};

PaymentError.propTypes = {
  errors: PropTypes.number,
  onCancel: PropTypes.func,
};

export default PaymentError;
