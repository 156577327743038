import React, { Component } from 'react';
import { connect } from 'react-fela';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LoadingCircle } from '../';
import classNames from 'classnames';

class ButtonLink extends Component {
  static propTypes = {
    fullWidth: PropTypes.bool,
    buttonType: PropTypes.oneOf([
      'primary',
      'primaryLarge',
      'round',
      'roundSmall',
      'blue',
      'icon',
      'white',
      'roundOrange',
      'roundWhite',
      'largeRoundOrangeOutline',
    ]).isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    iconLeft: PropTypes.node,
    iconRight: PropTypes.node,
    minwidth: PropTypes.string,
    padding: PropTypes.string,
    isSubmitting: PropTypes.bool,
  };

  static defaultProps = {
    buttonType: 'primary',
    disabled: false,
  };

  render() {
    const {
      children,
      styles,
      buttonType,
      fullWidth,
      iconLeft,
      iconRight,
      isSubmitting,
      className,
      ...passthrough
    } = this.props;
    return (
      <Link className={classNames([styles.root, className && className])} {...passthrough}>
        <div className={styles.buttonChildren}>
          {iconLeft && <div className={styles.iconLeft}>{iconLeft}</div>}
          <span className={styles.child}>{children}</span>
          {isSubmitting ? (
            <div className={styles.loadingCircle}>
              <LoadingCircle />
            </div>
          ) : null}
          {iconRight && <div className={styles.iconRight}>{iconRight}</div>}
        </div>
      </Link>
    );
  }
}

const styles = props => ({
  root: {
    display: 'inline-block',
    lineHeight: '14px',
    padding: props.padding || props.theme.buttonTypes[props.buttonType].padding,
    minWidth: props.minwidth || props.theme.buttonTypes[props.buttonType].minWidth,
    width: props.buttonType !== 'icon' ? (props.fullWidth ? '100%' : 'initial') : '40px',
    height: props.buttonType === 'icon' ? '40px' : 'initial',
    backgroundColor: props.theme.buttonTypes[props.buttonType].backgroundColor,
    borderRadius: props.theme.buttonTypes[props.buttonType].borderRadius,
    color: props.theme.buttonTypes[props.buttonType].color,
    boxShadow: props.theme.buttonTypes[props.buttonType].boxShadow,
    border:
      props.buttonType === 'round' ||
      props.buttonType === 'roundSmall' ||
      props.buttonType === 'roundWhite' ||
      props.buttonType === 'largeRoundOrangeOutline'
        ? `${props.theme.buttonTypes[props.buttonType].borderWidth} solid ${
            props.theme.buttonTypes[props.buttonType].borderColor
          }`
        : null,
    cursor: props.disabled ? 'auto' : 'pointer',
    transition: props.buttonType === 'icon' ? 'none' : props.theme.globalTransition,
    position: 'relative',
    flexShrink: 0,
    ':hover': {
      backgroundColor: !props.disabled ? props.theme.buttonTypes[props.buttonType].hoverBackgroundColor : null,
      color: !props.disabled && props.theme.buttonTypes[props.buttonType].hoverColor,
      borderColor: !props.disabled && props.theme.buttonTypes[props.buttonType].hoverBorderColor,
    },
    ':disabled': {
      backgroundColor: props.theme.colors.disabled,
      boxShadow: 'none',
      color: props.theme.colors.disabledText,
    },
    ':active': {
      backgroundColor: !props.disabled && props.theme.colors.green,
      color: !props.disabled && props.theme.colors.white,
      boxShadow: !props.disabled && 'inset 0 2px 1px 0 #417505',
      borderColor: !props.disabled && 'transparent',
      outline: 'none',
    },
  },
  buttonChildren: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: props.theme.buttonTypes[props.buttonType].fontFamily,
    fontWeight: props.theme.buttonTypes[props.buttonType].fontWeight,
    letterSpacing: props.theme.buttonTypes[props.buttonType].letterSpacing,
    textTransform: props.theme.buttonTypes[props.buttonType].textTransform,
    fontSize: props.fontsize || props.theme.buttonTypes[props.buttonType].fontSize,
    '> a': {
      color: 'currentColor',
    },
  },
  iconLeft: {
    marginRight: '6px',
  },
  iconRight: {
    marginLeft: '6px',
  },
  child: {
    marginRight: props.isSubmitting ? '16px' : null,
    display: 'flex',
    alignItems: 'center',
  },
  loadingCircle: {
    position: 'absolute',
    right: '-12px',
  },
});

export default connect(styles)(ButtonLink);
