/* eslint-disable */
/**
 * TODO: Fix useEffect dependency linting errors
 */
import React, { useEffect, useState } from 'react';
import { withFormik } from 'formik';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';

import AdvancedSearchListingsForm from './AdvancedSearchListingsForm';
import SearchListingsForm from './SearchListingsForm';
import SearchListingsFormMobile from './SearchListingsFormMobile';

import { resetBreedsAutocomplete, updateBreedsAutocomplete } from '../../../redux/actions';
import { selectBreedsAutocomplete } from '../../../redux/selectors';

import { defaults } from '../common/search-config';

function ListingSearchFormikMasterContainer({
  breeds,
  dispatch,
  handleSubmit,
  initialLocationInformation,
  isMobile,
  isSearchAdvanced,
  isSubmitting,
  setFieldValue,
  status,
  values,
  ...props
}) {
  const [tempBreedArray, setTempBreedArray] = useState([]);
  const [tempFormattedBreedArray, setTempFormattedBreedArray] = useState([]);

  useEffect(() => {
    return () => dispatch(resetBreedsAutocomplete());
  }, [isSearchAdvanced]);

  useEffect(() => {
    if (!isSearchAdvanced) {
      if (
        Array.isArray(values.breed) &&
        Array.isArray(values.formatted_breed) &&
        values.breed.length > 0 &&
        values.formatted_breed.length > 0
      ) {
        setTempBreedArray(values.breed);
        setTempFormattedBreedArray(values.formatted_breed);
        setFieldValue('breed', values.breed[0]);
        setFieldValue('formatted_breed', values.formatted_breed[0]);
      }
    } else {
      if (values.breed && !Array.isArray(values.breed) && values.breed !== defaults.breed) {
        if (tempBreedArray.length > 0) {
          setFieldValue('breed', tempBreedArray);
        } else {
          setFieldValue('breed', [values.breed]);
        }
      }
      if (
        values.formatted_breed &&
        !Array.isArray(values.formatted_breed) &&
        values.formatted_breed !== defaults.formatted_breed
      ) {
        if (tempFormattedBreedArray.length > 0) {
          setFieldValue('formatted_breed', tempFormattedBreedArray);
        } else {
          setFieldValue('formatted_breed', [values.formatted_breed]);
        }
      }
    }
  }, [isSearchAdvanced, values.breed]);

  useEffect(() => {
    if (values.location && values.location.label) {
      const [city, state] = values.location.label.split(', ');
      setFieldValue('city', city);
      setFieldValue('formatted_location', values.location.label);
      setFieldValue('state', state);
    }
  }, [values.location]);

  const updateAutocomplete = debounce(value => {
    if (value && value.length > 1) {
      dispatch(updateBreedsAutocomplete(value));
    } else {
      dispatch(resetBreedsAutocomplete());
    }
  }, 250);

  const handleInputChange = (value, isSearchAdvanced) => {
    if (!isSearchAdvanced) {
      if (!value) {
        setFieldValue('breed', []);
      }
      setFieldValue('formatted_breed', value ? value.label : []);
    }
    updateAutocomplete(value);
  };

  const handleBreedSelect = (breed, setFieldValue, values, dispatch, isSearchAdvanced) => {
    if (isSearchAdvanced) {
      setFieldValue('breed', (values.breed || []).concat(breed.id));
      setFieldValue('formatted_breed', (values.formatted_breed || []).concat(breed.label));
    } else {
      setFieldValue('breed', breed.id);
      setFieldValue('formatted_breed', breed.label);
      setTempBreedArray([]);
      setTempFormattedBreedArray([]);
    }

    dispatch(resetBreedsAutocomplete());
  };

  const handleBreedPlaceholder = values => {
    if (values.sort_breed === 'mixed' && values.breed.length >= 2) {
      return '2 breeds max for designer';
    } else if (values.breed.length > 0) {
      return 'Type a breed';
    } else {
      return 'Showing all breeds (type a breed to change)';
    }
  };

  const clearForm = async (setFieldValue, dispatch) => {
    setFieldValue('city', null);
    setFieldValue('location', null);
    setFieldValue('formatted_location', null);
    setFieldValue('distance', 1000);
    setFieldValue('price', { min: 100, max: 5000 });
    setFieldValue('breed', []);
    setFieldValue('formatted_breed', []);
    setFieldValue('sex', 'M,F,N');
    setFieldValue('age', { min: 0, max: 18 });
    setFieldValue('sort_breed', null);
    setFieldValue('state', null);
    dispatch(resetBreedsAutocomplete());
  };

  const handleBreedRemove = (id, setFieldValue, values) => {
    const index = values.breed.findIndex(breed => breed === id);

    const remove = (arr, index) => {
      return arr.slice(0, index).concat(arr.slice(index + 1));
    };

    setFieldValue('breed', remove(values.breed, index));
    setFieldValue('formatted_breed', remove(values.formatted_breed, index));
  };

  const resetAutocomplete = () => {
    dispatch(resetBreedsAutocomplete());
  };

  let WrappedComponent = SearchListingsForm;

  if (isMobile) {
    WrappedComponent = SearchListingsFormMobile;
  } else if (isSearchAdvanced) {
    WrappedComponent = AdvancedSearchListingsForm;
  }

  return (
    <WrappedComponent
      values={values}
      handleInputChange={value => handleInputChange(value, isSearchAdvanced)}
      handleBreedSelect={breed => handleBreedSelect(breed, setFieldValue, values, dispatch, isSearchAdvanced)}
      handleBreedPlaceholder={handleBreedPlaceholder(values)}
      handleBreedRemove={breedId => handleBreedRemove(breedId, setFieldValue, values)}
      clearForm={() => clearForm(setFieldValue, dispatch)}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      breeds={breeds}
      resetAutocomplete={resetAutocomplete}
      status={status}
      {...props}
    />
  );
}

const mapStateToProps = state => ({
  breeds: selectBreedsAutocomplete(state),
});

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: ({ query = {} }) => {
        let breed = [];
        let formatted_breed = [];

        if (Array.isArray(query.breed) && Array.isArray(query.formatted_breed)) {
          breed = query.breed;
          formatted_breed = query.formatted_breed;
        } else if (query.breed && query.formatted_breed) {
          breed.push(query.breed);
          formatted_breed.push(query.formatted_breed);
        }

        return {
          age: query.age,
          breed: breed,
          city: query.city,
          distance: query.distance,
          formatted_breed: formatted_breed,
          formatted_location: query.formatted_location,
          location: query.formatted_location,
          price: query.price,
          search_by: query.search_by,
          sex: query.sex,
          sort_breed: query.sort_breed,
          state: query.state,
        };
      },
      validateOnChange: false,
      validationSchema: yup.object().shape({
        location: yup.mixed(),
      }),
      handleSubmit: async (values, { props, setSubmitting }) => {
        setSubmitting(false);
        const search = { ...values };

        if (!props.isSearchAdvanced) {
          values.sex = defaults.sex;
          values.age = defaults.age;
          values.coat_pattern = defaults.coat_pattern;
          values.colors = defaults.colors;
        }

        if (values.search_by === 'everywhere') {
          search.city = '';
          search.formatted_location = null;
          search.state = '';
        }

        if (values.search_by === 'state') {
          search.city = '';
          search.formatted_location = null;
        }

        props.onSubmit(search);
      },
    })(ListingSearchFormikMasterContainer)
  )
);
