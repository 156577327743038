import React from 'react';

function Megaphone() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M4.25 6.073c-.414 0-.75.335-.75.747v.747H1.25c-.414 0-.75.335-.75.747v4.482c0 .412.336.747.75.747H3.5v.747c0 .412.336.747.75.747h.45l3.015 8.004c.226.579.785.96 1.409.959.396-.001.775-.163 1.05-.448l1.8-1.78c.456-.456.567-1.153.275-1.728l-2.51-5.007h1.991c.26.012.513.052.77.076V6c-.26.025-.516.064-.777.076L4.25 6.073zM23.383.363C23.369.34 23.354.32 23.339.3c-.077-.107-.18-.19-.299-.24l-.013-.017h-.041c-.065-.024-.132-.039-.2-.043-.049.005-.097.015-.143.03-.027.004-.054.01-.08.016-.12.04-.229.114-.313.213 0 0-.014 0-.02.014-2.223 2.815-5.309 4.732-8.73 5.423v9.594c3.423.692 6.511 2.612 8.734 5.43 0 .007.016.01.023.02.051.059.11.11.177.15.02.015.04.03.06.043.09.042.187.065.286.067.082 0 .164-.015.241-.043l.012-.01c.118-.05.222-.132.298-.24.017-.022.032-.045.046-.07.078-.118.12-.257.123-.4V.75c-.003-.138-.044-.273-.117-.387z" />
    </svg>
  );
  /* eslint-enable */
}

export default Megaphone;
