import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  purchaseMembership,
  getMembershipPlans,
  createPremierProfile,
  editPremierProfile,
  getBraintreeToken,
} from '../../redux/actions';
import {
  setErrorBarMessage,
  clearErrorBar,
  getUploadToken,
  deletePremierProfileImage,
  checkPhoneBlacklist,
  getUser,
} from '../../../../redux/actions';
import { isPremierSlugAvailable } from '../../../Premiers/redux/actions';
import { isLoggedInSelector } from '../../../../redux/selectors';
import PremierProfile from './PremierProfile';
import { selectBreedsAutocomplete } from '../../../../redux/selectors';
import { updateBreedsAutocomplete, resetBreedsAutocomplete } from '../../../../redux/actions';

const mapStateToProps = state => {
  return {
    user: state.user,
    userVM: state.userVM,
    isLoggedInSelector: isLoggedInSelector(state),
    profile: state.user.get('premierProfile'),
    purchaseErrors: state.account.purchaseErrors,
    membershipPlans: state.account.membershipPlans,
    membershipPlansLoaded: state.account.membershipPlansLoaded,
    breeds: selectBreedsAutocomplete(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      purchaseMembership,
      getMembershipPlans,
      createPremierProfile,
      editPremierProfile,
      setErrorBarMessage,
      clearErrorBar,
      getUploadToken,
      getBraintreeToken,
      deletePremierProfileImage,
      isPremierSlugAvailable,
      checkPhoneBlacklist,
      getUser,
      updateBreedsAutocomplete,
      resetBreedsAutocomplete,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PremierProfile);
