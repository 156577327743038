import React from 'react';

function Envelope() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M12 13.375L1 5.125A1.37 1.37 0 0 1 2.375 3.75h19.25A1.37 1.37 0 0 1 23 5.125l-11 8.25zm0 2.47l11-8.25v11.28a1.37 1.37 0 0 1-1.375 1.375H2.375A1.37 1.37 0 0 1 1 18.875V7.595l11 8.25z" />
    </svg>
  );
  /* eslint-enable */
}

export default Envelope;
