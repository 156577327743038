import React from 'react';

function StarsOutline() {
  /* eslint-disable max-len */
  return (
    <svg width="156" height="70" viewBox="0 0 156 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M81.0327 2.62657L86.8593 17.9809H98.1951C98.7675 17.958 99.333 18.1125 99.814 18.4235C100.295 18.7344 100.668 19.1865 100.882 19.7178C101.097 20.2491 101.141 20.8336 101.01 21.3912C100.879 21.9488 100.579 22.4523 100.151 22.8326L90.3168 29.7135L95.7636 42.2339C96.0023 42.8073 96.0551 43.4411 95.9148 44.0462C95.7744 44.6512 95.4479 45.197 94.9812 45.6068C94.5144 46.0166 93.931 46.2697 93.3129 46.3307C92.6947 46.3916 92.0731 46.2572 91.5354 45.9463L78.3575 38.5327L65.1909 45.9463C64.6532 46.2572 64.0316 46.3916 63.4134 46.3307C62.7953 46.2697 62.2119 46.0166 61.7452 45.6068C61.2784 45.197 60.9519 44.6512 60.8116 44.0462C60.6712 43.4411 60.7241 42.8073 60.9627 42.2339L66.4095 29.7135L56.5757 22.8553C56.1393 22.4776 55.8314 21.9734 55.6948 21.4126C55.5582 20.8519 55.5997 20.2625 55.8135 19.7264C56.0273 19.1904 56.4029 18.7342 56.8879 18.4214C57.3729 18.1086 57.9433 17.9546 58.5198 17.9809H69.8556L75.6936 2.62657C75.9464 2.13655 76.3292 1.72558 76.8001 1.43867C77.271 1.15177 77.8118 1 78.3632 1C78.9146 1 79.4553 1.15177 79.9262 1.43867C80.3971 1.72558 80.7799 2.13655 81.0327 2.62657V2.62657Z"
        stroke="#1AB9C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2 4"
      />
      <path
        d="M27.2586 25.2753L33.0852 40.6297H44.421C44.9934 40.6067 45.5588 40.7613 46.0399 41.0722C46.521 41.3832 46.8942 41.8352 47.1083 42.3665C47.3224 42.8978 47.367 43.4823 47.236 44.04C47.105 44.5976 46.8048 45.1011 46.3764 45.4814L36.5426 52.3623L41.9895 64.8826C42.2281 65.4561 42.281 66.0899 42.1406 66.6949C42.0003 67.3 41.6738 67.8458 41.207 68.2556C40.7403 68.6654 40.1568 68.9185 39.5387 68.9794C38.9206 69.0403 38.299 68.9059 37.7612 68.5951L24.5833 61.1815L11.4168 68.5951C10.8791 68.9059 10.2574 69.0403 9.63931 68.9794C9.0212 68.9185 8.43774 68.6654 7.97102 68.2556C7.50429 67.8458 7.17777 67.3 7.03742 66.6949C6.89706 66.0899 6.94992 65.4561 7.18856 64.8826L12.6354 52.3623L2.8016 45.5041C2.3652 45.1264 2.0573 44.6221 1.92071 44.0614C1.78411 43.5007 1.8256 42.9113 2.0394 42.3752C2.25321 41.8391 2.62871 41.383 3.11372 41.0702C3.59873 40.7573 4.16915 40.6034 4.74569 40.6297H16.0815L21.9194 25.2753C22.1723 24.7853 22.5551 24.3744 23.026 24.0874C23.4969 23.8005 24.0376 23.6488 24.589 23.6488C25.1404 23.6488 25.6812 23.8005 26.1521 24.0874C26.6229 24.3744 27.0058 24.7853 27.2586 25.2753V25.2753Z"
        stroke="#1AB9C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2 4"
      />
      <path
        d="M134.949 25.2753L140.776 40.6297H152.111C152.684 40.6067 153.249 40.7613 153.73 41.0722C154.211 41.3832 154.585 41.8352 154.799 42.3665C155.013 42.8978 155.057 43.4823 154.926 44.04C154.795 44.5976 154.495 45.1011 154.067 45.4814L144.233 52.3623L149.68 64.8826C149.918 65.4561 149.971 66.0899 149.831 66.6949C149.691 67.3 149.364 67.8458 148.897 68.2556C148.431 68.6654 147.847 68.9185 147.229 68.9794C146.611 69.0403 145.989 68.9059 145.452 68.5951L132.274 61.1815L119.107 68.5951C118.569 68.9059 117.948 69.0403 117.33 68.9794C116.712 68.9185 116.128 68.6654 115.661 68.2556C115.195 67.8458 114.868 67.3 114.728 66.6949C114.587 66.0899 114.64 65.4561 114.879 64.8826L120.326 52.3623L110.492 45.5041C110.056 45.1264 109.748 44.6221 109.611 44.0614C109.474 43.5007 109.516 42.9113 109.73 42.3752C109.944 41.8391 110.319 41.383 110.804 41.0702C111.289 40.7573 111.86 40.6034 112.436 40.6297H123.772L129.61 25.2753C129.863 24.7853 130.245 24.3744 130.716 24.0874C131.187 23.8005 131.728 23.6488 132.279 23.6488C132.831 23.6488 133.372 23.8005 133.842 24.0874C134.313 24.3744 134.696 24.7853 134.949 25.2753V25.2753Z"
        stroke="#1AB9C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2 4"
      />
    </svg>
  );
  /* eslint-enable */
}

export default StarsOutline;
