import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const licensedOptions = [
  { value: 'yes', display: 'Yes' },
  { value: 'no', display: 'No' },
  { value: 'not_required', display: 'Not Required' },
];

const LicensingOptions = ({ radioName, styles, user }) => {
  return (
    <div className={styles.bioFieldRadioContainer}>
      {licensedOptions.map(option => {
        const optionId = `radio-${radioName}-${option.value}`;
        const checked = isDefaultChecked({ user, radioName, value: option.value });
        return (
          <div key={option.value} className={styles.bioFieldRadio}>
            <Field
              id={optionId}
              name={radioName}
              type="radio"
              value={option.value}
              className={styles.bioRadioInput}
              defaultChecked={checked}
            />
            <label htmlFor={optionId} className={styles.bioFieldRadioLabel}>
              {option.display}
            </label>
          </div>
        );
      })}
    </div>
  );
};

function isDefaultChecked({ user, radioName, value }) {
  const userValue = user.getIn(['userLicenses', radioName]);
  if (userValue === value) {
    return true;
  }
  if (!userValue && value === 'no') {
    return true;
  }
  return false;
}

LicensingOptions.propTypes = {
  radioName: PropTypes.string,
  user: PropTypes.object.isRequired,
};

export default LicensingOptions;
