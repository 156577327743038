import 'whatwg-fetch';

// Import all CSS here so the server doesn't attempt to transpile them into JS
import './styles/reset.css';
import './styles/index.css';
import './styles/loading.css';
import './styles/loading-circle.css';
import 'cropperjs/dist/cropper.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-slider/assets/index.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './styles/overrides.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { calculateResponsiveState } from 'redux-responsive';
import { BrowserRouter } from 'react-router-dom';
import './urlsearchparams';
import { createRenderer } from 'fela';
import { RendererProvider, ThemeProvider } from 'react-fela';
import prefixer from 'fela-plugin-prefixer';
import fallbackValue from 'fela-plugin-fallback-value';
import typescript from 'fela-plugin-typescript';
// import * as Sentry from '@sentry/browser';
// import { version } from '../package.json';
import { renderRoutes } from 'react-router-config';
import { Provider } from 'react-redux';

import routes from './routes';
import reduxStore from './redux/store';
import theme from './theme';
import config from './common/config';
import { BrowserContextProvider } from './contexts/BrowserContext';
import { DoAfterLoginProvider } from './contexts/DoAfterLogin';

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

if (config.datadog) {
  if (config.datadog.enableRUM) {
    datadogRum.init({
      ...{
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
      },
      ...config.datadog,
    });
    datadogRum.startSessionReplayRecording();
  }

  datadogLogs.init({
    ...{
      forwardErrorsToLogs: true,
      sampleRate: 100,
    },
    ...config.datadog,
  });
}

// Sentry.init({
//   dsn: config.sentryDSN,
//   environment: config.appEnv,
//   release: version,
// });

const renderer = createRenderer({
  plugins: [prefixer(), fallbackValue(), typescript()],
});

// Bootstrap the React app
ReactDOM.hydrate(
  <Provider store={reduxStore}>
    <BrowserContextProvider>
      <RendererProvider renderer={renderer}>
        <ThemeProvider theme={theme}>
          <DoAfterLoginProvider>
            <BrowserRouter>
              <div>{renderRoutes(routes)}</div>
            </BrowserRouter>
          </DoAfterLoginProvider>
        </ThemeProvider>
      </RendererProvider>
    </BrowserContextProvider>
  </Provider>,
  document.getElementById('root')
);

// calculate the initial state
reduxStore.dispatch(calculateResponsiveState(typeof window !== 'undefined' ? window : null));
