import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Status, NoContentPlaceholder } from '../';

class ContentNotFound extends Component {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    statusCode: PropTypes.number,
  };

  render() {
    const { icon, to, buttonText, title, statusCode } = this.props;
    return (
      <div>
        <Status code={statusCode ? statusCode : 404} />
        <NoContentPlaceholder icon={icon} to={to} buttonText={buttonText} title={title} topMargin />
      </div>
    );
  }
}

export default ContentNotFound;
