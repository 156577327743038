import { combineReducers } from 'redux';
import { fromJS, List, Map } from 'immutable';

const searchResults = (state = List(), action) => {
  switch (action.type) {
    case 'SEARCH_PREMIERS_SUCCESS':
      return fromJS(action.json.results);
    case 'CREATE_FAVORITE_PREMIER_REQUEST':
      return state.map(premier =>
        premier.get('premier_profile_id') === action.premier_id ? premier.set('favorite', true) : premier
      );
    case 'REMOVE_FAVORITE_PREMIER_REQUEST':
      return state.map(premier =>
        premier.get('premier_profile_id') === action.premier_id ? premier.set('favorite', null) : premier
      );
    case 'RESET_PREMIERS_SEARCH':
    case 'SEARCH_PREMIERS_FAILURE':
      return List();
    default:
      return state;
  }
};

const matchedPlace = (state = null, action) => {
  switch (action.type) {
    case 'SEARCH_PREMIERS_SUCCESS':
      return fromJS(action.json.matchedPlace) || null;
    case 'SEARCH_PREMIERS_FAILURE':
      return null;
    case 'RESET_PREMIERS_SEARCH':
      return null;
    default:
      return state;
  }
};

const searchTotal = (state = 0, action) => {
  switch (action.type) {
    case 'SEARCH_PREMIERS_SUCCESS':
      return action.json.total;
    case 'RESET_PREMIERS_SEARCH':
      return 0;
    default:
      return state;
  }
};

const searchLoading = (state = false, action) => {
  switch (action.type) {
    case 'SEARCH_PREMIERS_REQUEST':
      return true;
    case 'SEARCH_PREMIERS_FAILURE':
    case 'SEARCH_PREMIERS_SUCCESS':
      return false;
    default:
      return state;
  }
};

const searchLoaded = (state = false, action) => {
  switch (action.type) {
    case 'SEARCH_PREMIERS_SUCCESS':
      return true;
    case 'SEARCH_PREMIERS_REQUEST':
    case 'SEARCH_PREMIERS_FAILURE':
      return false;
    default:
      return state;
  }
};

const currentPremierLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_PREMIER_REQUEST':
      return true;
    case 'GET_PREMIER_FAILURE':
    case 'GET_PREMIER_SUCCESS':
    case 'RESET_PREMIER':
      return false;
    default:
      return state;
  }
};

const currentPremierLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PREMIER_REQUEST':
    case 'RESET_PREMIER':
      return false;
    case 'GET_PREMIER_FAILURE':
    case 'GET_PREMIER_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const currentPremier = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_PREMIER_SUCCESS':
    case 'EDIT_PREMIER_PROFILE_SUCCESS':
    case 'CREATE_PREMIER_PROFILE_SUCCESS':
      return fromJS(action.json);
    case 'CREATE_PREMIER_UPDATE_SUCCESS':
      return state.set('latestUpdate', fromJS(action.json));
    case 'DELETE_PREMIER_UPDATE_SUCCESS':
      return state.set('latestUpdate', null);
    case 'CREATE_FAVORITE_PREMIER_REQUEST':
      return state.set('favorite', true);
    case 'REMOVE_FAVORITE_PREMIER_REQUEST':
      return state.set('favorite', null);
    case 'SEND_MESSAGE_TO_USER_SUCCESS':
      if (state && action.json.users.find(user => user.id === state.get('user_id'))) {
        return state.set('conversation', fromJS(action.json));
      }
      return state;
    case 'RESET_PREMIER':
      return Map();
    default:
      return state;
  }
};

const testimonials = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_PROFILE_SUCCESS':
      const userId = action.userId ? action.userId : action.json.userId;
      return state.set(userId, fromJS(action.json.testimonialsForMe));
    case 'GET_TESTIMONIALS_FOR_PREMIER_SUCCESS':
    case 'GET_PREMIER_SUCCESS':
      return state.set(action.json.userId, fromJS(action.json.testimonials));
    default:
      return state;
  }
};

export default combineReducers({
  currentPremierLoading,
  currentPremierLoaded,
  currentPremier,
  matchedPlace,
  searchResults,
  searchTotal,
  searchLoading,
  searchLoaded,
  testimonials,
});
