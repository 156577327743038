import { combineReducers } from 'redux';
import { fromJS, List } from 'immutable';

const results = (state = List(), action) => {
  switch (action.type) {
    case 'LANDING_SEARCH_LISTINGS_SUCCESS':
      return fromJS(action.json.results);
    case 'LANDING_SEARCH_LISTINGS_FAILURE':
      return List();
    case 'CREATE_FAVORITE_LISTING_REQUEST':
      if (state) {
        return state.map(listing =>
          listing.get('id') === action.listing_id ? listing.set('favorite', true) : listing
        );
      }
      return state;
    case 'REMOVE_FAVORITE_LISTING_REQUEST':
      if (state) {
        return state.map(listing =>
          listing.get('id') === action.listing_id ? listing.set('favorite', null) : listing
        );
      }
      return state;
    case 'RESET_LANDING_SEARCH_LISTINGS':
      return null;
    default:
      return state;
  }
};

const matchedPlace = (state = null, action) => {
  switch (action.type) {
    case 'LANDING_SEARCH_LISTINGS_SUCCESS':
      return fromJS(action.json.matchedPlace) || null;
    case 'LANDING_SEARCH_LISTINGS_FAILURE':
      return null;
    case 'RESET_LANDING_SEARCH_LISTINGS':
      return null;
    default:
      return state;
  }
};

const total = (state = 0, action) => {
  switch (action.type) {
    case 'LANDING_SEARCH_LISTINGS_SUCCESS':
      return fromJS(action.json.total);
    case 'LANDING_SEARCH_LISTINGS_FAILURE':
    case 'RESET_LANDING_SEARCH_LISTINGS':
      return 0;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'LANDING_SEARCH_LISTINGS_SUCCESS':
      return false;
    case 'LANDING_SEARCH_LISTINGS_FAILURE':
      return fromJS(action.json || {});
    case 'RESET_LANDING_SEARCH_LISTINGS':
      return false;
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'LANDING_SEARCH_LISTINGS_REQUEST':
      return true;
    case 'LANDING_SEARCH_LISTINGS_FAILURE':
    case 'LANDING_SEARCH_LISTINGS_SUCCESS':
    case 'RESET_LANDING_SEARCH_LISTINGS':
      return false;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case 'RESET_LANDING_SEARCH_LISTINGS':
    case 'LANDING_SEARCH_LISTINGS_REQUEST':
      return false;
    case 'LANDING_SEARCH_LISTINGS_FAILURE':
    case 'LANDING_SEARCH_LISTINGS_SUCCESS':
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  results,
  total,
  loading,
  loaded,
  matchedPlace,
  error,
});
