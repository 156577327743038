import React from 'react';
import { List } from 'immutable';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import Photo from './Photo';
import { ErrorContainer, PhotoGallery, UploadContainer } from './styled';

import { Icon, FileUploader, LoadingCircle } from '../../../common/components';
import { usePhotoGallery } from './PhotoGalleryContext';

const PHOTO_COUNT_MAX = 10;

const SortableListItem = SortableElement(({ element }) => {
  return element;
});

const SortableList = SortableContainer(
  ({
    handleDelete = () => {},
    handleEdit = () => {},
    handleRemove = () => {},
    handleUpload = () => {},
    photoLimit = PHOTO_COUNT_MAX,
    photos = List(),
    userPhotos = [],
  }) => {
    const { photoUploads } = usePhotoGallery();

    return (
      <PhotoGallery>
        {userPhotos.map((photo, index) => {
          const photoMap = photos.find(p => p?.get('id') === photo.id);

          if (photo.loading) {
            return (
              <UploadContainer key={index} title={photo?.file?.name} data-testid="uploading-photo">
                <LoadingCircle />
                <span>{photo.percentage}%</span>
              </UploadContainer>
            );
          }

          return (
            <SortableListItem
              disabled={userPhotos.filter(p => p.loading).length > 0}
              key={index}
              index={index}
              element={
                <Photo
                  index={index}
                  photo={photo}
                  photoMap={photoMap}
                  sortIndex={index}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                />
              }
            />
          );
        })}
        {photoUploads.map((photo, index) => {
          if (photo.error) {
            return (
              <ErrorContainer
                key={index}
                aria-label={`Click to remove image ${photo?.file?.name} and try again`}
                data-testid="error-photo"
                title={photo?.file?.name}
                onClick={() => handleRemove(photo)}
              >
                <Icon icon="Warning" />
                <span>Click to remove image and try again</span>
              </ErrorContainer>
            );
          }

          if (photo.loading) {
            return (
              <UploadContainer key={index} data-testid="uploading-photo" title={photo?.file?.name}>
                <LoadingCircle />
                <span>{photo.percentage}%</span>
              </UploadContainer>
            );
          }

          return null;
        })}
        {userPhotos.length + photoUploads.length < PHOTO_COUNT_MAX && (
          <FileUploader
            allowMultiple
            innerText={`Click to upload up to ${photoLimit} photo${photoLimit !== 1 ? 's' : ''}`}
            limit={photoLimit}
            variant="puppy"
            onFilesLoaded={images => {
              handleUpload(images);
            }}
            accept={{
              'image/png': ['.png'],
              'image/jpeg': ['.jpeg', '.jpg'],
            }}
          />
        )}
      </PhotoGallery>
    );
  }
);

export default SortableList;
