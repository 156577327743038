import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';

import ChangePasswordForm from './ChangePasswordForm';
import { Loading, Icon, SubNav } from '../../common/components';
import { Helmet } from 'react-helmet';
class ChangePassword extends Component {
  render() {
    const { history, updateUser, user, setErrorBarMessage, styles, location } = this.props;

    if (!user) {
      return <Loading dark center />;
    }

    const isMigration = user.getIn(['historicalUserRequirements', 'needs_password_update']);

    return (
      <div className={styles.container}>
        <Helmet>
          <title>Change Password - Puppies.com</title>
        </Helmet>
        <SubNav titleText="Change Password" />
        {isMigration && (
          <div>
            <div className={styles.logoToLogo}>
              <Icon icon="PuppyFindLogo" className={styles.puppyFindLogo} />
              <Icon icon="ThickArrowRight" className={styles.thickArrow} />
              <div className={styles.puppyCrownLogoWrapper}>
                <Icon icon="PuppyLogoWithText" className={styles.puppyLogoText} />
              </div>
            </div>
            <div className={styles.goldBar}>
              <span>We've updated our password requirements.</span>
            </div>
          </div>
        )}
        <div
          className={styles.wrapper}
          style={{ display: isMigration ? 'flex' : 'block', textAlign: isMigration ? 'center' : 'left' }}
        >
          <ChangePasswordForm
            setErrorBarMessage={setErrorBarMessage}
            history={history}
            submitAction={updateUser}
            user={user}
            isMigration={isMigration}
            location={location}
          />
        </div>
      </div>
    );
  }
}

const styles = props => ({
  container: {
    ...props.theme.globalBackgroundContainer,
  },
  wrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '>h1': {
      color: props.theme.colors.orange,
      fontWeight: props.theme.typography.sansBold,
    },
    ...props.theme.globalPageWrapper,
  },
  puppyCrownLogoWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  puppyLogoText: {
    width: '175px !important',
    height: '35px !important',
    '@media (max-width: 480px)': {
      width: '150px !important',
      height: '26px !important',
    },
  },
  logoToLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px 16px',
    backgroundColor: props.theme.colors.tan,
  },
  puppyFindLogo: {
    width: '222px',
    height: '32px',
    '@media (max-width: 480px)': {
      width: '148px',
      height: '21px',
    },
    '@media (max-width: 360px)': {
      width: '111px',
      height: '16px',
    },
  },
  thickArrow: {
    width: '40px',
    height: '40px',
    fill: props.theme.colors.green,
    '@media (max-width: 480px)': {
      width: '24px',
      height: '24px',
    },
  },
  goldBar: {
    backgroundColor: props.theme.colors.yellow,
    padding: '16px',
    textAlign: 'center',
    fontWeight: props.theme.typography.sansBold,
    marginBottom: '40px',
  },
});

export default felaConnect(styles)(ChangePassword);
