import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field, Form, withFormik } from 'formik';
import { connect as felaConnect } from 'react-fela';
import * as yup from 'yup';

import Button from '../../common/components/Button';
import InputError from '../../common/components/InputError';
import Textarea from '../../common/components/Textarea';
import TextInput from '../../common/components/TextInput';

function ContactForm({ errors, status, values }) {
  return (
    <Form>
      <Field component={TextInput} label="Email Address" name="email" required value={values.email} />
      <Field component={Textarea} label="Message" maxLength={3000} name="message" required value={values.message} />
      {status && status.error && <InputError>{status.error}</InputError>}
      <Button
        disabled={!values.email || !values.message || Object.values(errors).length > 0}
        type="submit"
        buttonType="roundOrange"
      >
        Send
      </Button>
    </Form>
  );
}

function validationSchema() {
  return yup.object().shape({
    email: yup
      .string()
      .trim()
      .email()
      .required('Email Address Required'),
    message: yup
      .string()
      .trim()
      .max(3000, '3000 characters max')
      .required('Message Required'),
  });
}

function mapPropsToValues() {
  return {
    email: '',
    message: '',
  };
}

function styles() {
  return {};
}

ContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default compose(
  withFormik({
    enableReinitialize: true,
    handleSubmit: (values, { props, setStatus }) =>
      props
        .onSubmit({
          email: values.email,
          message: values.message,
        })
        .then(action => {
          if (!action.response.ok) {
            setStatus({ error: action.json.message });
          }
        }),
    mapPropsToValues,
    validationSchema,
  }),
  felaConnect(styles)
)(ContactForm);
