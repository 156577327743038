import MarkAsSoldModal from './MarkAsSoldModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getListingLimit from '../../utils/listingLimits';
import { updateListingStatus } from '../../../views/Listings/redux/actions';

const mapStateToProps = state => ({
  user: state.user,
  atListingLimit: state.listingCount === getListingLimit(state.userVM),
});

const mapDispatchToProps = dispatch => bindActionCreators({ updateListingStatus }, dispatch);

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    updateListingStatus: status => dispatchProps.updateListingStatus({ status }, ownProps.listing.get('id')),
  });

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MarkAsSoldModal);
