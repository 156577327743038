import styled from 'styled-components';

import { Button, IconButton } from '../../../common/components';

import theme from '../../../theme';

export const FieldContainer = styled.div`
  ${props => (props.hasOwnProperty('flex') ? `flex: ${props.flex};` : null)}

  ${props => (props.hasOwnProperty('minWidth') ? `min-width: ${props.minWidth};` : null)}
         flex-wrap: wrap;
  padding-left: ${props => (props.sidePadding ? `${props.sidePadding}px` : '0')};
  padding-right: ${props => (props.sidePadding ? `${props.sidePadding}px` : '0')};
`;

export const BreedContainer = styled(FieldContainer)`
  border-left: 1px dashed #979797;
  margin-left: 32px;
  padding-left: 16px;
  & > div {
    margin: 0 5px 5px 0;
  }
`;

export const BreedContainerRow = styled(BreedContainer)`
  border: none;
  margin-left: 0;
  padding-left: 0;
`;

export const FieldLabel = styled.label`
  display: inline-block;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
`;

export const FormContainer = styled.div`
  ${theme.globalPadding}
  display: flex;
  margin: 10px auto;
  max-width: ${theme.globalMaxWidth};
  ${theme.globalPadding}
  transition: boxShadow 300ms ease-in;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  ${props => (props.hasOwnProperty('justify') ? `justify-content: ${props.justify};` : null)}

  margin-top: 0;
  @media (max-width: 864px) {
    margin-top: 1em;
  }
  width: 100%;
  gap: 0.5em;
`;

export const HelpText = styled.span`
  display: block;
  margin-bottom: 10px;
  color: ${theme.colors.textDark};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const InputContainer = styled.div`
  margin-bottom: ${props => (props.hasOwnProperty('margin') ? `${props.margin}px;` : '20px;')}
  &:last-child {
    margin-bottom: 0;
  }
`;

export const MobileFieldContainer = styled(FieldContainer)`
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    flex: 1;
  }

  input {
    border: 0;
    box-shadow: none;
  }
`;

export const MobileFilterButton = styled(IconButton)`
  background-color: ${theme.colors.darkerBlue};
  border-radius: 4px;
  height: 30px;
  margin: 6px;
  padding: 0 6px;
  color: #fff;
`;

export const MobileSearchButton = styled(IconButton)`
  background-color: ${theme.colors.orange};
  border-radius: 4px;
  padding: 8px;
  color: white;
`;

export const MobileUpdateButton = styled(Button)`
  width: 100%;
`;
export const RefineSearchButtonContainer = styled.div`
  margin: 0 20px;
`;

export const SearchContainer = styled.div`
  background-color: ${theme.colors.darkerTan};
  max-height: ${props => (props.isAdvanced ? '1000px !important' : '260px')};
  margin: ${props => (props.sideMargin ? `0 ${props.sideMargin}px` : '0')};
  padding: 16px 0px;

  ${theme.breakpoints.tabletLarge} {
    max-height: 95px;
  }
`;

export const SlidingPaneTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 10px;

  ${theme.breakpoints.mobile} {
    padding: 0 20px;
  }

  h2 {
    color: ${theme.colors.orange};
    font-size: 20px;
    line-height: 24px;
  }
`;

export const DesignerError = styled.div`
  font-size: 12px;
  color: red;
  margin-top: 10px;
`;
