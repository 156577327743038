import FeedbackManager from './FeedbackManager';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { replyToTestimonial } from './redux/actions';

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({ replyToTestimonial }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackManager);
