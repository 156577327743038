import React from 'react';
import classNames from 'classnames';
import MessageTimestamp from './MessageTimestamp';

const RedactedMessage = ({ message, history, styles, newFromId }) => {
  return (
    <div
      key={message.get('id')}
      className={classNames(
        styles.messageWrapper,
        styles.messageWrapperExpired,
        newFromId === message.get('id') ? styles.messageWrapperNew : null
      )}
    >
      {newFromId === message.get('id') ? <div className={styles.newMessageBorder}>New</div> : null}
      <div className={styles.author}>Removed</div>
      <div className={styles.innerMessageWrapper}>
        <div className={styles.message}>
          The message sender's account has been removed due to violating our Terms of Service.
        </div>
      </div>
      <MessageTimestamp styles={styles} message={message} />
    </div>
  );
};

export default RedactedMessage;
