import React, { useEffect, useState } from 'react';
import { List } from 'immutable';
import styled from 'styled-components';
import uuid from 'uuid/v4';

import { AdSquare, NoContentPlaceholder, PuppySquare, TipSquare } from '../';

import { useBrowser } from '../../../contexts/BrowserContext';

import theme from '../../../theme';

const Container = styled.div`
  margin: 0 auto 20px;
  max-width: 1108px;
  width: 100%;
`;

const ListingGrid = styled.div`
  padding: 0;
  display: grid;

  ${theme.breakpoints.mobileLarge} {
    padding: 0 20px;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  ${theme.breakpoints.tabletLarge} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);)
  }
`;

const backgroundColors = ['#1ab9c4', '#74cf3c', '#ffc232'];

const columns = {
  infinity: 4,
  mobile: 1,
  mobileLarge: 2,
  mobileSmall: 1,
  tablet: 3,
  tabletLarge: 3,
};

const calculateGrid = (columnCount, gridListLength) => {
  const rowCount = Math.ceil(gridListLength / columnCount);
  const maxCount = columnCount * rowCount;

  return { maxCount, rowCount };
};

const canPushAdToLastRow = ({ columnCount, grid, length, noAds }) => {
  if (noAds) {
    return false;
  }

  if (grid?.rowCount < 3) {
    return false;
  }

  if (columnCount === 2 && length < 9) {
    return false;
  }

  if (columnCount === 3 && length < 9) {
    return false;
  }

  return true;
};

const shuffle = arr => {
  return arr.sort(() => Math.random() - 0.5);
};

const PuppyGrid = (
  { hideBookmark, listings = List([]), location, noAds, noContent, removeFavoriteListing, tips = [], isPremier } = this
    .props
) => {
  const browser = useBrowser();

  const addTips = (columnCount, gridList, customLength) => {
    // add tips to the end
    const length = isNaN(customLength) ? gridList.length : customLength;
    const grid = calculateGrid(columnCount, length);
    const tipList = shuffle([...tips]);
    const tipCount = grid.maxCount - length;

    if (columnCount > 1 && tipCount > 0) {
      const colors = shuffle([...backgroundColors]).splice(0, tipCount);
      const includedTips = tipList.splice(0, tipCount).map((tip, index) => {
        return (
          <TipSquare key={uuid()} backgroundColor={colors[index]} body={tip?.get('body')} header={tip?.get('header')} />
        );
      });

      gridList.splice(gridList.length, 0, ...includedTips);
    }
  };

  const calculateListings = () => {
    const gridList = [...listings].map(listing => {
      return (
        <PuppySquare
          key={listing?.get('id')}
          to={{
            pathname: `/listings/${listing?.get('id')}`,
            state: { backTo: 'Search', prevLocation: location },
          }}
          hideBookmark={hideBookmark}
          listing={listing}
          removeFavoriteListing={removeFavoriteListing}
          showDogInfo={true}
          isPremier={isPremier}
        />
      );
    });

    const columnCount = columns[browser.mediaType];

    if (columnCount > 1) {
      // add ad to last column of first row (if not a single row)
      if (!noAds) {
        let middleAdRow = 0;
        const gridBeforeAd = calculateGrid(columnCount, gridList.length + 1);
        if (gridBeforeAd.rowCount > 5) {
          middleAdRow = Math.floor(gridBeforeAd.rowCount * 0.5) - 1;
        }

        if (gridBeforeAd.rowCount === 1) {
          addTips(columnCount, gridList, gridList.length + 1);
        }

        if (middleAdRow > 0) {
          const position = Math.abs(Math.round(Math.random() * columnCount) - 1);
          gridList.splice(
            middleAdRow * columnCount + position,
            1,
            <AdSquare placementName="puppies_search_small_rec_atf2" slotId={uuid.v4()} key={uuid.v4()} />
          );
        }

        gridList.splice(
          columnCount - 1,
          0,
          <AdSquare placementName="puppies_search_small_rec_atf1" slotId={uuid.v4()} key={uuid.v4()} />
        );
      }

      const grid = calculateGrid(columnCount, gridList.length);
      const pushAdToLastRow = canPushAdToLastRow({ columnCount, grid, length: gridList.length });

      // add ad to last row
      if (pushAdToLastRow) {
        const remaining = grid.maxCount - gridList.length;
        const shift = remaining - columnCount;
        gridList.splice(
          grid.maxCount - remaining + shift,
          0,
          <AdSquare placementName="puppies_search_small_rec_btf1" slotId={uuid.v4()} key={uuid.v4()} />
        );
      }

      // add tips to the end
      addTips(columnCount, gridList);
    } else {
      if (!noAds) {
        if (gridList.length > 7) {
          gridList.splice(
            3,
            0,
            <AdSquare placementName="puppies_search_small_rec_atf2" slotId={uuid.v4()} key={uuid.v4()} />
          );
        }

        if (gridList.length > 19) {
          const middle = Math.floor(gridList.length * 0.5);
          gridList.splice(
            middle,
            0,
            <AdSquare placementName="puppies_search_small_rec_btf1" slotId={uuid.v4()} key={uuid.v4()} />
          );
        }

        gridList.splice(
          gridList.length,
          0,
          <AdSquare placementName="puppies_search_small_rec_btf1" slotId={uuid.v4()} key={uuid.v4()} />
        );
      }
    }

    return gridList;
  };

  const [results, setResults] = useState(typeof window === 'undefined' ? calculateListings() : []);

  useEffect(() => {
    if (listings?.size > 0) {
      setResults(calculateListings());
    }
  }, [listings]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasListings = results.length > 0;

  return (
    <Container>
      {!hasListings && (noContent || <NoContentPlaceholder icon="PuppyOutline" globalPadding />)}
      {hasListings && (
        <ListingGrid data-testid="listing-grid" noAds={noAds}>
          {results.map(listing => {
            return listing;
          })}
        </ListingGrid>
      )}
    </Container>
  );
};

export default PuppyGrid;
