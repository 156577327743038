import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import PropTypes from 'prop-types';
import StarRatingComponent from './StarRatingComponent';
import theme from '../../../theme';
import { Icon } from '../';
import omit from 'lodash/omit';
import classNames from 'classnames';

class StarRating extends Component {
  state = {
    starHoverIndex: null,
  };

  static propTypes = {
    className: PropTypes.string,
    starSize: PropTypes.number,
  };

  static defaultProps = {
    starSize: 30,
  };

  render() {
    const { styles, value, className, starSize } = this.props;

    return (
      <StarRatingComponent
        className={classNames([styles.starRating, className])}
        starCount={5}
        starColor={theme.colors.blue}
        emptyStarColor={theme.colors.tan}
        renderStarIcon={() => <Icon icon="StarOutline" size={starSize} />}
        onStarHover={i => this.setState({ starHoverIndex: i })}
        onStarHoverOut={() => this.setState({ starHoverIndex: null })}
        value={this.state.starHoverIndex === null ? value : this.state.starHoverIndex}
        {...omit(this.props, 'value')}
      />
    );
  }
}

const styles = props => ({
  starRating: {
    userSelect: 'none',
    '> .dv-star-rating-star > span': {
      transition: props.theme.globalTransition,
    },
  },
});

export default felaConnect(styles)(StarRating);
