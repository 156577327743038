import React, { Component } from 'react';
import querystring from 'query-string';
import cloneDeep from 'lodash/cloneDeep';

import FavoriteListings from './FavoriteListings';
import FavoritePremiers from './FavoritePremiers';

import { getRandomTips } from '../../../../redux/actions';
import { searchFavoriteListings, searchFavoritePremiers } from '../../redux/actions';
import { SubNav, TabGroup } from '../../../../common/components';
import { getSortOptions } from './sort-util';
import { Helmet } from 'react-helmet';
const PAGE_SIZE = 24;

class Favorites extends Component {
  static fetchData(location, match, user) {
    const actions = [getRandomTips()];

    if (user) {
      const favoriteListingsQuery = querystring.parse(location.search);
      favoriteListingsQuery.favorited_by = user.get('id');
      delete favoriteListingsQuery.location;

      const favoritePremiersQuery = cloneDeep(favoriteListingsQuery);
      delete favoritePremiersQuery.sort_by;
      delete favoritePremiersQuery.sort_dir;

      // if (!favoriteListingsQuery.location && user.get('lat') && user.get('lng')) {
      //   favoriteListingsQuery.location = `${user.get('lat')},${user.get('lng')}`;
      //   favoriteListingsQuery.sort_by = 'location';
      //   favoriteListingsQuery.sort_dir = 'asc';

      //   favoritePremiersQuery.location = favoriteListingsQuery.location;
      // }

      actions.push(searchFavoriteListings(favoriteListingsQuery), searchFavoritePremiers(favoritePremiersQuery));
    }

    return actions;
  }

  constructor(props) {
    super(props);

    this.state = {
      currentView: 'listings',
      sortBy: getSortOptions(!!props.user.get('lat'))[0].value,
    };
  }

  componentDidMount() {
    const { user, location, match, dispatch } = this.props;
    Favorites.fetchData(location, match, user).forEach(action => dispatch(action));

    if (location.pathname === '/favorites/breeders') {
      this.setState({ currentView: 'premiers' });
    }
  }

  componentDidUpdate(prevProps) {
    const { location, history, searchFavoriteListings, searchFavoritePremiers, user, favoritesRefresh } = this.props;
    const { currentView } = this.state;

    const query = querystring.parse(location.search);
    let shouldSearch = false;

    if (prevProps.location.search !== location.search) {
      shouldSearch = true;
    } else if (!prevProps.favoritesRefresh.get('shouldRefresh') && favoritesRefresh.get('shouldRefresh')) {
      if (favoritesRefresh.get('nextPage') !== query.page) {
        query.page -= 1;
        history.push({
          pathname: '/favorites',
          search: querystring.stringify(query),
        });
      } else {
        shouldSearch = true;
      }
    }

    if (shouldSearch) {
      const query = querystring.parse(location.search);
      query.favorited_by = user.get('id');

      if (!query.sort_by && !query.location && user.get('lat') && user.get('lng')) {
        query.location = `${user.get('lat')},${user.get('lng')}`;
        query.sort_by = 'location';
        query.sort_dir = 'asc';
      }

      currentView === 'listings' ? searchFavoriteListings(query) : searchFavoritePremiers(query);
    }
  }

  componentWillUnmount() {
    this.props.resetSearchFavoriteListings();
    this.props.resetSearchFavoritePremiers();
  }

  updateView = view => {
    this.props.history.replace(`/favorites/${view}`);
    this.setState({ currentView: view });
  };

  handleSortChange = value => {
    const { user, location, history } = this.props;
    this.setState({ sortBy: value });
    const selectedOption = getSortOptions(!!user.get('lat')).find(option => option.value === value);

    const query = {
      favorited_by: user.get('id'),
      ...querystring.parse(location.search),
      ...selectedOption.query,
      page: 0,
    };

    if (!!user.get('lat')) {
      query.location = `${user.get('lat')},${user.get('lng')}`;
    }

    history.push({
      pathname: location.pathname,
      search: querystring.stringify(query),
    });
  };

  handleRemoveFavoriteListing = listingId => {
    const { removeFavoriteListing, listingCount, location } = this.props;
    const query = querystring.parse(location.search);
    const nextPage = listingCount > 1 ? query.page : query.page - 1;

    removeFavoriteListing(listingId, nextPage);
  };

  handleRemoveFavoritePremier = premierId => {
    const { removeFavoritePremier, premierCount, location } = this.props;
    const query = querystring.parse(location.search);
    const nextPage = premierCount > 1 ? query.page : query.page - 1;

    removeFavoritePremier(premierId, nextPage);
  };

  renderSubNav = () => {
    const { currentView } = this.state;
    const textArray = [
      {
        onClick: () => this.updateView('listings'),
        text: 'Favorite Puppies',
        active: currentView === 'listings',
      },
      {
        onClick: () => this.updateView('breeders'),
        text: 'Favorite Breeders',
        active: currentView === 'premiers',
      },
    ];
    return <SubNav titleText="My Favorites" headerRightChildren={<TabGroup textArray={textArray} />} />;
  };

  renderFavorites() {
    const { currentView, sortBy } = this.state;

    if (currentView === 'premiers') {
      return <FavoritePremiers removeFavoritePremier={this.handleRemoveFavoritePremier} />;
    }

    return (
      <FavoriteListings
        pageSize={PAGE_SIZE}
        handleSortChange={this.handleSortChange}
        sortOptions={getSortOptions(!!this.props.user.get('lat'))}
        sortBy={sortBy}
        location={this.props.location}
        removeFavoriteListing={this.handleRemoveFavoriteListing}
      />
    );
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>My Favorites - Puppies.com</title>
        </Helmet>
        {this.renderSubNav()}
        {this.renderFavorites()}
      </div>
    );
  }
}

export default Favorites;
