import React from 'react';

function Avatar() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M24 22.5v.996c0 .281-.223.504-.504.504H.504C.223 24 0 23.777 0 23.496V22.5c0-5.004 9-3.996 9-8.004 0 0-3-3-3-8.496 0-3.305 2.695-6 6-6s6 2.695 6 6c0 5.496-3 8.496-3 8.496 0 4.008 9 3 9 8.004z" />
    </svg>
  );
  /* eslint-enable */
}

export default Avatar;
