export function trimAndSetNullFalsyValues(obj, ignoreKeys) {
  const newObj = {};

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'string') {
      const trimmedValue = obj[key].trim();

      if (trimmedValue || (ignoreKeys && ignoreKeys.indexOf(key) !== -1)) {
        newObj[key] = trimmedValue;
      } else {
        newObj[key] = null;
      }
    } else {
      newObj[key] = obj[key];
    }
  });

  return newObj;
}
