import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import { connect as felaConnect } from 'react-fela';
import * as yup from 'yup';
import { connect } from 'react-redux';

import { Button, InputError, TextInput } from '../../../../common/components';
import { formatPhoneNumber, phoneNumberSchema } from '../../../../common/components/PhoneValidation';
import { getFormattedPhoneNumber } from '../../../../common/utils/format-phone';

class PhoneVerificationForm extends Component {
  state = {
    showVerifyField: false,
    bannedNumber: false,
    showPhoneNumberChangedModal: false,
    requestedVerificationType: null,
  };

  handlePhoneSubmit = type => {
    const { values } = this.props;
    this.setState({ showVerifyField: false, bannedNumber: false });
    const phone_number = values.phone_number.replace(/[^0-9]/g, '');

    this.requestVerificationCode(phone_number, values.phone_country_code, type);
  };

  requestVerificationCode(phone_number, phone_country_code, type) {
    const { sendPhoneVerification, setFieldError } = this.props;

    sendPhoneVerification(phone_number, phone_country_code, type).then(action => {
      if (action.response.ok) {
        this.setState({
          showVerifyField: true,
          showPhoneNumberChangedModal: false,
          requestedVerificationType: type,
        });
      } else {
        this.setState({ bannedNumber: true, showPhoneNumberChangedModal: false });
        setFieldError('phone_number', action.json.message);
      }
    });
  }

  submitVerificationCode = event => {
    const { submitForm, user, values } = this.props;
    event.preventDefault();
    const phone_number = values.phone_number.replace(/[^0-9]/g, '');

    if (
      user.getIn(['premierProfile', 'phone']) &&
      (user.getIn(['premierProfile', 'phone']) !== phone_number ||
        user.getIn(['premierProfile', 'phone_country_code']) !== values.phone_country_code)
    ) {
      this.setState({
        showPhoneNumberChangedModal: true,
      });
    } else {
      submitForm();
    }
  };

  render() {
    const { user, isSubmitting, status, styles, errors, values, setFieldValue } = this.props;
    const { bannedNumber, showVerifyField } = this.state;

    if (user.getIn(['phoneVerifications', 0, 'verified']) || user.get('phone_number_verified')) {
      const phoneNumber = user.getIn(['phoneVerifications', 0, 'phone', 'phone_number'])
        ? user.getIn(['phoneVerifications', 0, 'phone', 'phone_number'])
        : user.get('phone_number');
      return <div className={styles.confirmed}>{getFormattedPhoneNumber(`+1${phoneNumber}`)}</div>;
    }
    return (
      <form id="phone-verification-form" onSubmit={this.submitVerificationCode}>
        <div className={styles.formLower}>
          <div className={styles.formLowerGroupColumn}>
            <div className={styles.phoneInput}>
              <span className={styles.countryCode}>+1</span>
              <Field
                name="phone_number"
                type="tel"
                data-testid="phone-input"
                autoComplete="phone-number"
                component={TextInput}
                value={formatPhoneNumber(values.phone_number)}
                onChange={e => {
                  setFieldValue('phone_number', e.target.value);
                  this.setState({ bannedNumber: false });
                }}
                fixederror
              />
            </div>
            <div className={styles.formLowerGroupActions}>
              <Button
                buttonType="roundOrange"
                type="button"
                disabled={!!(isSubmitting || (errors.phone_number && !bannedNumber) || values.phone_number === '')}
                onClick={this.handlePhoneSubmit.bind(this, 'sms')}
              >
                {this.state.requestedVerificationType === 'sms' ? 'Resend' : 'Text a code'}
              </Button>
              <Button
                buttonType="roundOrange"
                type="button"
                disabled={!!(isSubmitting || (errors.phone_number && !bannedNumber) || values.phone_number === '')}
                onClick={this.handlePhoneSubmit.bind(this, 'call')}
              >
                {this.state.requestedVerificationType === 'call' ? 'Call Again' : 'Receive a Call'}
              </Button>
            </div>
          </div>
          {showVerifyField ? (
            <div className={styles.codesent}>
              {this.state.requestedVerificationType === 'sms' ? 'CODE SENT' : 'CALL MADE'}
            </div>
          ) : null}
          {showVerifyField ? (
            <div className={styles.formLowerGroup}>
              <div className={styles.codeInput}>
                <Field
                  label="Verification Code"
                  name="verification_code"
                  type="text"
                  component={TextInput}
                  fixederror
                />
              </div>
              <Button buttonType="roundSmall" type="submit" disabled={isSubmitting}>
                Confirm
              </Button>
            </div>
          ) : null}
        </div>
        {status && status.error && <InputError>{status.error}</InputError>}
      </form>
    );
  }
}

const FormikPhoneVerificationForm = withFormik({
  mapPropsToValues: props => {
    const { user } = props;
    return {
      user,
      phone_number: user.get('phone_number') || '',
      phone_country_code: '+1',
      verification_code: '',
    };
  },
  validationSchema: yup.object().shape({
    phone_number: phoneNumberSchema,
    verification_code: yup
      .string()
      .min(6, 'Verification code must be 6 digits')
      .max(6, 'Verification code must be 6 digits')
      .required('Please enter your verification code'),
  }),
  handleSubmit: async (values, { props, setSubmitting, setFieldError }) => {
    values.phone_number = values.phone_number.replace(/[^0-9]/g, '');

    if (values.verification_code) {
      const action = await props.confirmPhoneVerification(
        values.phone_number,
        values.phone_country_code,
        values.verification_code
      );
      if (!action.response.ok) {
        setFieldError('verification_code', action.json.message);
      }
    }
    setSubmitting(false);
  },
})(PhoneVerificationForm);

const styles = {
  formLower: props => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '24px',
    '> * + *': {
      marginTop: '24px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      '> * + *': {
        marginTop: '24px',
      },
    },
  }),
  formLowerGroup: props => ({
    display: 'flex',
    alignItems: 'flex-end',
    position: 'relative',
    width: 'fit-content',
    '>button': {
      position: 'relative',
      bottom: '6px',
    },
  }),
  formLowerGroupColumn: props => ({
    display: 'block',
  }),
  formLowerGroupActions: props => ({
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    '> button:first-child': {
      marginRight: '20px',
    },
  }),
  countryCode: {
    width: '30px',
  },
  phoneInput: props => ({
    margin: '0 0 20px',
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    marginBottom: '20px',
    '> input': {
      padding: '10px 18px',
    },
  }),
  codeInput: props => ({
    marginRight: '20px',
    [props.theme.breakpoints.tablet]: {
      width: '76px',
    },
  }),
  codesent: props => ({
    fontSize: '14px',
    color: props.theme.colors.green,
    fontWeight: props.theme.typography.sansBold,
    position: 'relative',
    margin: '8px 0 !important',
  }),
  confirmed: props => ({
    color: props.theme.colors.brown,
    fontWeight: props.theme.typography.sansBold,
    fontSize: '14px',
  }),
  countryCodeDropdown: {
    maxWidth: '355px',
  },
};

export default connect()(felaConnect(styles)(FormikPhoneVerificationForm));
