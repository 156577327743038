import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { connect as felaConnect } from 'react-fela';

import ContactHeadline from './ContactHeadline';
import ContactForm from './ContactForm';
import ContactSuccess from './ContactSuccess';
import { contactSupport, contactSupportReset } from '../../redux/actions';

class Contact extends React.Component {
  componentDidMount() {
    this.props.dispatchContactSupportReset();
  }

  render() {
    const { contactSupport, dispatchContactSupport, styles } = this.props;

    return (
      <div className={styles.contactPage}>
        <div className={styles.contactContainer}>
          <ContactHeadline />
          <div className={styles.contactForm}>
            {contactSupport.loaded ? <ContactSuccess /> : <ContactForm onSubmit={dispatchContactSupport} />}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    contactSupport: state.contactSupport,
  };
}

const mapDispatchToProps = {
  dispatchContactSupport: contactSupport,
  dispatchContactSupportReset: contactSupportReset,
};

Contact.propTypes = {
  contactSupport: PropTypes.object.isRequired,
  dispatchContactSupport: PropTypes.func.isRequired,
  dispatchContactSupportReset: PropTypes.func.isRequired,
};

const styles = props => ({
  contactPage: {
    backgroundColor: props.theme.colors.tan,
  },
  contactContainer: {
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
    '@media (max-width: 900px)': {
      padding: '20px 20px 32px',
    },
  },
  contactForm: {
    maxWidth: '600px',
    margin: '0 auto',

    '> form > div': {
      marginBottom: '24px',
    },
    '> form > button': {
      display: 'block',
      margin: '0 auto',
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(felaConnect(styles)(Contact));
