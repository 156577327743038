import React, { useContext, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import HistoryLink from '../HistoryLink';
import ViewConversationButton from './ViewConversationButton';
import DefaultContactButton from './DefaultContactButton';
import { DoAfterLoginContext } from '../../../contexts/DoAfterLogin';

function ContactButton({
  conversation,
  user,
  isSelf,
  location,
  backTo,
  contactText = 'CONTACT ME',
  smallOrange = false,
  userLoading,
  onOpenVerify,
  onStartContact,
}) {
  const context = useContext(DoAfterLoginContext);
  const userRef = useRef(user);

  useEffect(() => {
    userRef.current = user;
  }, [userRef, user]);

  const onClick = () => {
    context.doAfterLogin(() => {
      if (userRef.current?.get('email_verified')) {
        onStartContact(false);
      } else {
        onOpenVerify();
      }
    });
  };

  const renderButton = () => {
    if (isSelf) {
      return (
        <HistoryLink to="/messages/inbox" backTo={backTo}>
          <DefaultContactButton userLoading={userLoading} smallOrange={smallOrange} contactText="MESSAGES" />
        </HistoryLink>
      );
    }

    if (conversation) {
      return <ViewConversationButton conversation={conversation} user={user} location={location} backTo={backTo} />;
    }

    return (
      <DefaultContactButton
        userLoading={userLoading}
        onClick={onClick}
        smallOrange={smallOrange}
        contactText={contactText}
      />
    );
  };

  return <React.Fragment>{renderButton()}</React.Fragment>;
}

export default connect(state => ({
  userLoading: state.userLoading,
  userLoaded: state.userLoaded,
}))(ContactButton);
