// From https://secure.helpscout.net/settings/beacons/319d4f69-6369-42d0-bb88-d72742060941/embed/web

const DECLARE = `!(function(e, n) {
  ;(window.Beacon = n = function(e, n, d) {
    window.Beacon.readyQueue.push({method: e, options: n, data: d})
  }),
    (n.readyQueue = [])
  var t = e.getElementsByTagName('script')[0],
    o = e.createElement('script')
  ;(o.type = 'text/javascript'),
    (o.async = !0),
    (o.src = 'https://beacon-v2.helpscout.net'),
    t.parentNode.insertBefore(o, t)
})(document, window.Beacon || function() {})`;

const INIT = `window.Beacon('init', '319d4f69-6369-42d0-bb88-d72742060941')`;

const CONFIG = `window.Beacon('config', {
  display: {
    style: 'manual',
  },
})`;

const helpers = {
  config: CONFIG,
  declare: DECLARE,
  init: INIT,
};

export default helpers;
