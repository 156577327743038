import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';

class Table extends Component {
  static propTypes = {
    styles: PropTypes.object,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { styles, children } = this.props;
    return <table className={styles.root}>{children}</table>;
  }
}

const styles = {
  root: props => ({
    width: '100%',
    fontFamily: props.theme.typography.sans,
    fontWeight: props.theme.typography.sansBold,
    fontSize: '14px',
    color: props.theme.colors.brown,
    maxWidth: `calc(${props.theme.globalMaxWidth} - 48px)`,
    margin: '0 auto 50px',
    borderRadius: '4px',
    position: 'relative',
  }),
};

export default connect(styles)(Table);
