import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import hasTouch from '../../utils/hasTouch';

import { InputScaffold } from '../';

class ToggleButton extends Component {
  static propTypes = {
    selectedText: PropTypes.node,
    unselectedText: PropTypes.node,
    styles: PropTypes.object.isRequired,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
      setFieldValue: PropTypes.func.isRequired,
    }),
    value: PropTypes.any,
    primaryText: PropTypes.string,
    label: PropTypes.node,
    labelVariant: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    toggleType: PropTypes.oneOf(['primary', 'pill']),
    labelUpperCase: PropTypes.bool,
  };

  static defaultProps = {
    selectedText: 'Selected',
    unselectedText: 'Unselected',
    toggleType: 'primary',
  };

  handleClick = e => {
    const { field, form, value } = this.props;
    e.currentTarget.blur();
    if (value && value !== field.value) {
      form.setFieldValue(field.name, value);
    } else {
      form.setFieldValue(field.name, !field.value);
    }
  };

  render() {
    const {
      field,
      form,
      styles,
      disabled,
      label,
      labelVariant,
      selectedText,
      unselectedText,
      primaryText,
      toggleType,
      ...passableProps
    } = this.props;

    let textToDisplay;
    if (primaryText) {
      textToDisplay = primaryText;
    } else {
      textToDisplay = this.props.field.value === this.props.value ? selectedText : unselectedText;
    }

    const error = form.touched[field.name] && form.errors[field.name];

    return (
      <InputScaffold label={label} labelVariant={labelVariant} disabled={disabled} error={error}>
        <button
          className={styles.button}
          onClick={this.handleClick}
          onBlur={field.onBlur}
          disabled={disabled}
          name={field.name}
          type="button"
          {...passableProps}
        >
          <div className={styles.buttonChildren}>{textToDisplay}</div>
        </button>
      </InputScaffold>
    );
  }
}

const styles = {
  button: props => ({
    display: 'inline-block',
    padding: '8px 16px',
    transition: props.theme.globalTransition,
    backgroundColor:
      props.toggleType === 'pill'
        ? props.field.value
          ? props.theme.colors.green
          : '#CAC1B6'
        : props.field.value
        ? props.theme.colors.green
        : props.theme.colors.white,
    minWidth: '80px',
    width: '100%',
    height: '42px',
    borderRadius: props.toggleType === 'pill' ? '100px' : '2px',
    boxShadow:
      props.toggleType === 'primary'
        ? props.field.value
          ? 'inset 0 1px 4px 0 rgba(0,0,0,0.4), 0 1px 3px 0 rgba(0,0,0,0.3)'
          : `0 2px 0 0 ${props.theme.colors.disabledText}`
        : 'unset',
    opacity: props.disabled ? 0.5 : 1,
    ':hover': {
      backgroundColor: hasTouch() ? null : props.theme.colors.green,
    },
    ':active': {
      backgroundColor: '#66B535',
      outline: 'none',
    },
  }),
  buttonChildren: props => ({
    fontFamily: props.theme.typography.sans,
    fontWeight: props.theme.typography.sansBold,
    fontSize: 12,
    textTransform: props.labelUpperCase ? 'uppercase' : 'none',
    color:
      props.toggleType === 'pill'
        ? props.theme.colors.white
        : props.field.value
        ? props.theme.colors.white
        : props.theme.colors.blue,
  }),
};

export default connect(styles)(ToggleButton);
