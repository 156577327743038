import React from 'react';

function Visa() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="16" viewBox="0 0 24 16" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <filter x="-2.1%" y="-3.2%" width="104.2%" height="106.3%" filterUnits="objectBoundingBox" id="b">
          <feMorphology radius="1" in="SourceAlpha" result="shadowSpreadInner1" />
          <feOffset in="shadowSpreadInner1" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" in="shadowInnerInner1" />
        </filter>
        <rect id="a" x="0" y="0" width="24" height="15.75" rx="1.125" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect fill="#F4F6F7" width="24" height="15.75" rx="1.125" />
        <path d="M0 1.125C0 .504.501 0 1.125 0h21.75C23.496 0 24 .506 24 1.125V3.75H0V1.125z" fill="#4095DB" />
        <path d="M0 12h24v2.625c0 .621-.501 1.125-1.125 1.125H1.125A1.127 1.127 0 010 14.625V12z" fill="#F4B662" />
        <path
          d="M12.877 10.5c1.241 0 2.048-.682 2.057-1.737.004-.58-.31-1.02-.992-1.382-.413-.236-.666-.392-.663-.63 0-.212.214-.439.676-.439.387-.006.666.092.884.196l.106.058.16-1.103a2.668 2.668 0 00-1.058-.213c-1.168 0-1.99.69-1.996 1.679-.008.73.586 1.138 1.034 1.381.46.25.615.409.613.631-.003.341-.367.497-.707.497-.473 0-.724-.077-1.112-.267l-.152-.08-.166 1.138c.276.142.786.265 1.316.271zm-2.056-.075l.74-5.086h-1.182l-.739 5.086h1.181zm6.837-1.804c-.046-.254-.273-1.468-.273-1.468l-.08-.438c-.06.19-.163.498-.157.485 0 0-.372 1.13-.47 1.421zm-10.867 1.8l1.25-.002 1.86-5.076H8.649L7.493 8.81l-.123-.705-.414-2.336c-.072-.323-.28-.418-.536-.43H4.516l-.016.106c.464.132.879.322 1.242.559l1.05 4.416zm9.299.005l.021-.066c.054-.165.19-.588.227-.698h.147l1.366.002c.035.178.144.762.144.762h1.096l-.956-5.082h-.913c-.283 0-.495.09-.619.421l-1.754 4.661h1.24z"
          fill="#0071CE"
        />
        <use fillOpacity="0" fill="#E5E5E5" xlinkHref="#a" />
        <use fill="#000" filter="url(#b)" xlinkHref="#a" />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default Visa;
