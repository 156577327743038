import TestimonialList from './TestimonialList';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getTestimonialsForUser,
  getTestimonialsByUser,
  getTestimonialsForAndByUser,
  getTestimonialsByUserAsUser,
  getTestimonialsForAndByUserAsUser,
  getFinalRejectedByUser,
  updateTestimonialVisibility,
  updateReplyVisibility,
} from '../../../views/Testimonials/redux/actions';

const mapStateToProps = (state, ownProps) => {
  const props = {
    user: state.user,
    total: state.testimonials.total.get(ownProps.userId),
  };
  if (!props.testimonials) {
    switch (ownProps.include) {
      case 'for':
        props.testimonials = state.testimonials.testimonialsForUser.get(ownProps.userId);
        props.finalRejected = state.testimonials.finalRejected;
        break;
      case 'by':
        props.testimonials = state.testimonials.testimonialsByUser.get(ownProps.userId);
        props.finalRejected = state.testimonials.finalRejected;
        break;
      case 'all':
        props.testimonials = state.testimonials.testimonialsForAndByUser.get(ownProps.userId);
        break;
      default:
        throw new Error(`Invalid prop value for 'include': ${ownProps.include}`);
    }
  }
  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let getTestimonials;
  let getFinalRejected;
  switch (ownProps.include) {
    case 'for':
      getTestimonials = getTestimonialsForUser;
      getFinalRejected = getFinalRejectedByUser;
      break;
    case 'by':
      getTestimonials = ownProps.viewerId ? getTestimonialsByUserAsUser : getTestimonialsByUser;
      getFinalRejected = getFinalRejectedByUser;
      break;
    case 'all':
      getTestimonials = ownProps.viewerId ? getTestimonialsForAndByUserAsUser : getTestimonialsForAndByUser;
      break;
    default:
      throw new Error(`Invalid prop value for 'include': ${ownProps.include}`);
  }
  return bindActionCreators(
    { getTestimonials, getFinalRejected, updateTestimonialVisibility, updateReplyVisibility },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialList);
