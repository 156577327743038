import { createSelector } from 'reselect';

export const isLoggedInSelector = state => !!state.token && !!state.user && !state.userVM.isEmpty();
const coatPatternsSelector = state => state.coatPatterns;
export const breedsGroupsSelector = state => state.breedGroups;
export const breedsAutocompleteSelector = state => state.breedsAutocomplete;
export const premiersAutocompleteSelector = state => state.premiersAutocomplete;
export const userSelector = state => state.user;

export const selectCoatPatterns = createSelector(coatPatternsSelector, patterns =>
  patterns
    .map(pattern => {
      return { value: pattern.get('id'), label: pattern.get('name') };
    })
    .toJS()
);

export const selectBreedGroupsForDropdown = createSelector(breedsGroupsSelector, breedGroups =>
  breedGroups.map(breedGroup => ({ value: breedGroup.get('id'), label: breedGroup.get('title') })).toJS()
);

export const selectBreedsAutocomplete = createSelector(breedsAutocompleteSelector, breedGroups => {
  return breedGroups?.toJS().reduce((acc, curr) => {
    acc.push({
      id: curr.id,
      label: curr.name,
    });

    return acc;
  }, []);
});

export const selectPremiersAutocomplete = createSelector(premiersAutocompleteSelector, premiers => {
  return premiers?.toJS().reduce((acc, curr) => {
    acc.push({
      id: curr.id,
      label: curr.name,
    });
    return acc;
  }, []);
});
