import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MyListings from './MyListings';

import { getUser } from '../../../../redux/actions';
import { listingNewMessagesSelector } from '../../redux/selectors';

const mapStateToProps = (state, props) => ({
  user: state.user,
  userVM: state.userVM,
  listings: state.myListings,
  total: state.myListingsTotal,
  loading: state.myListingsLoading,
  listingMessages: listingNewMessagesSelector(state),
  isMobile: state.browser.lessThan.tabletLarge,
  listingCount: state.listingCount,
  loaded: state.myListingsLoaded,
});

const mapDispatchToProps = dispatch => bindActionCreators({ getUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyListings);
