import React, { useState, useEffect } from 'react';
import breedsNameSlug from '../../common/utils/breedsNameSlug';
import { connect as felaConnect } from 'react-fela';

import config from '../../common/config';
import RatingBar from '../BreedInformation/RatingBar';

function SearchBreedTab({ breedName, getBreedInformation, breedInformation, styles }) {
  const [breed, setBreed] = useState(null);

  useEffect(() => {
    const slug = breedsNameSlug(breedName);
    getBreedInformation(slug);
  }, [breedName, getBreedInformation]);

  useEffect(() => {
    if (breedInformation?.get('name') === breedName && breedInformation.get('name') !== breed?.get('name')) {
      setBreed(breedInformation);
    }
  }, [breed, breedName, breedInformation]);

  const traitDescriptor = (headline, value, styles) => {
    if (value) {
      return (
        <div className={styles.trait}>
          <h3>{headline}</h3>
          <p>{value}</p>
        </div>
      );
    }
    return null;
  };
  const renderTraits = () => {
    const overview = breed.get('short_overview');

    return (
      <div className={styles.traitsWrapper}>
        <div className={styles.traitsHeader}>
          <div className={styles.breedInfoContainer}>
            <div className={styles.imgContainer}>
              <img
                src={`${config.breedPhotoBucketURL}${
                  breed.get('photo') ? escape(breed.get('photo')) : 'Placeholder.png'
                }`}
                alt={breed.get('name')}
              />
            </div>
            <div>
              <h2 className={styles.breedHeaderTitle}>{breed.get('name')}</h2>
              <h3 className={styles.breedGroup}>
                Breed Group: <strong>{breed.getIn(['breedGroup', 'title'])}</strong>
              </h3>
              <p className={styles.breedProfile}>
                {overview ? overview : 'More information about this breed is coming soon!'}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.traits}>
          {traitDescriptor('Behavior', breed.get('behavior'), styles)}
          {traitDescriptor('Coat', breed.get('coat'), styles)}
          {traitDescriptor('Lifestyle', breed.get('lifestyle'), styles)}
          {traitDescriptor('Activity', breed.get('activity'), styles)}
        </div>
        {breed.get('attribution') && <div className={styles.attribution}>{breed.get('attribution')}</div>}
      </div>
    );
  };

  return (
    <div id={breedName}>
      {breed != null ? (
        <div className={styles.container}>
          {renderTraits()}
          <RatingBar breedInformation={breed} />
          {/* <div className={styles.backContainer}>
            <button type="button" className={styles.backButton} onClick={() => setActive('Search')}>
              <a href="#Search">Back to Search</a>
            </button>
          </div> */}
        </div>
      ) : null}
    </div>
  );
}

const styles = props => ({
  container: {
    margin: '0 auto 20px',
    padding: '20px 0',
    maxWidth: '1108px',
    width: '100%',
  },
  breedInfoContainer: {
    [props.theme.breakpoints.tabletLarge]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      gap: '1em',
      alignItems: 'center',
    },
  },
  imgContainer: {
    marginRight: '3em',
    width: '100%',
    height: '300px',
    borderRadius: '1%',
    overflow: 'hidden',
    '> img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
  },

  breedHeaderWrapper: {
    minHeight: '50vw',
    maxHeight: '700px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    color: props.theme.colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    ':after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: 'rgba(138,109,91,0.5)',
      height: '100%',
      width: '100%',
    },
    [props.theme.breakpoints.tabletLarge]: {
      minHeight: 'unset',
      height: '45vw',
    },
  },
  breedHeader: {
    ...props.theme.globalPageWrapper,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    position: 'relative',
    zIndex: '1',
    [props.theme.breakpoints.tabletLarge]: {
      paddingLeft: '20px !important',
      paddingRight: '20px !important',
    },
  },
  breedHeaderNoPhoto: {
    color: props.theme.colors.brown,
    textShadow: 'none',
  },
  breedHeaderLoggedOut: {
    justifyContent: 'flex-start',
  },
  breedHeaderContent: {
    textShadow: '1px 1px 4px rgba(0,0,0,.5)',
    [props.theme.breakpoints.tabletLarge]: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  breedHeaderTitle: {
    marginTop: !props.user ? '50px' : null,
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltBold,
    fontStyle: 'italic',
    fontSize: '28px',
    wordBreak: 'break-word',
    [props.theme.breakpoints.mobile]: {
      fontSize: '45px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      marginTop: 0,
    },
  },
  breedHeaderSubTitle: {
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: `${props.theme.typography.sansAltLight} !important`,
    fontStyle: 'italic',
    fontSize: '28px',
    [props.theme.breakpoints.mobile]: {
      fontSize: '45px',
    },
  },
  breedGroup: {
    fontSize: '16px',
    marginTop: '20px',
    [props.theme.breakpoints.mobile]: {
      fontSize: '20px',
    },
  },
  breedHeadline: {
    fontSize: '16px',
    fontStyle: 'italic',
    marginTop: '6px',
    [props.theme.breakpoints.mobile]: {
      fontSize: '20px',
    },
  },

  traitsWrapper: {
    width: '100%',
    maxWidth: props.theme.globalMaxWidth,
    margin: '24px auto',
    ...props.theme.globalPadding,
    color: props.theme.colors.brown,
    lineHeight: '20px',
    fontSize: '14px',
  },
  traitsHeader: {
    '> h2': {
      ...props.theme.typography.titleText,
    },
    '> p': {
      margin: '20px 0',
    },
  },
  traits: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  breedProfile: {
    marginTop: '10px',
  },
  trait: {
    marginTop: '30px',
    flexBasis: '45%',
    '> h2': {
      fontWeight: props.theme.typography.sansBold,
    },
    '> p': {
      marginTop: '2px',
    },
  },

  hypoallergenic: {
    textShadow: 'none',
    display: 'inline-flex',
    width: 'fit-content',
    alignItems: 'center',
    backgroundColor: props.theme.colors.green,
    borderRadius: '40px',
    padding: '4px 12px 4px 4px',
    marginTop: '12px',
    fontSize: '12px',
    lineHeight: '17px',
    fontWeight: props.theme.typography.sansBold,
    '> span': {
      marginRight: '8px',
    },
  },
  attribution: {
    marginTop: '12px',
    fontSize: '12px',
  },
  allowLineBreaks: {
    'white-space': 'pre-line',
  },
  backContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem',
  },
  backButton: {
    color: props.theme.colors.blue,
    borderRadius: '1rem',
    fontWeight: props.theme.typography.sansSemi,
    textTransform: 'uppercase',
    boxShadow: props.theme.globalBoxShadow,
    padding: '0.5rem 2rem',
    ':hover': {
      background: props.theme.colors.blue,
      color: props.theme.colors.white,
    },
  },
});
export default felaConnect(styles)(SearchBreedTab);
