import React from 'react';
import { ExpiredAccountUpgradeModal } from '../../../../../common/components';

const UpgradeModal = ({ history, status, setStatus, userVM }) => {
  if (!status || !status.showUpgradeModal) {
    return null;
  }
  return (
    <ExpiredAccountUpgradeModal
      isOpen={status.showUpgradeModal}
      daysRemaining={userVM.get('gracePeriodDaysRemaining')}
      onClose={() => setStatus({ showUpgradeModal: false })}
      onUpgrade={() => history.push('/account/membership')}
    />
  );
};

export default UpgradeModal;
