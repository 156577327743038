import React from 'react';
import OwnMessage from './OwnMessage';
import OtherUserMessage from './OtherUserMessage';

const UserMessage = ({ message, currentUser, styles, newFromId, author, deleteMessagePhoto }) => {
  const isOwnMessage = author && currentUser.get('id') === author.get('id');

  if (isOwnMessage) {
    return <OwnMessage message={message} styles={styles} deleteMessagePhoto={deleteMessagePhoto} />;
  }

  return <OtherUserMessage message={message} styles={styles} newFromId={newFromId} />;
};

export default UserMessage;
