import React, { Component, Fragment } from 'react';
import { connect as felaConnect } from 'react-fela';
import PropTypes from 'prop-types';
import querystring from 'query-string';

import {
  HistoryLink,
  Pager,
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Th,
  Loading,
  NoContentPlaceholder,
  BreedListHeader,
} from '../../common/components';
import SearchForm from './common/SearchForm';
import config from '../../common/config';
import { browseBreeds, getBreedGroups, resetBreedGroups, resetBreeds } from '../../redux/actions';
import AdBanner from '../../common/components/AdBanner';
import { Helmet } from 'react-helmet';
class BreedsList extends Component {
  static propTypes = {
    breedGroupOptions: PropTypes.array,
    breedsList: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
  };

  static fetchData(location, match, user) {
    const search = querystring.parse(location.search);

    return [getBreedGroups(), browseBreeds(search)];
  }

  componentDidMount() {
    const { breedsList, match, location, user, dispatch } = this.props;

    if (!breedsList) {
      BreedsList.fetchData(location, match, user).forEach(action => dispatch(action));
    }
  }

  componentDidUpdate(prevProps) {
    const { location, dispatch } = this.props;

    if (prevProps.location.search !== location.search) {
      const search = querystring.parse(location.search);
      dispatch(browseBreeds(search));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(resetBreedGroups());
    dispatch(resetBreeds());
  }

  renderBreedsFound = () => {
    const { total } = this.props;
    return <BreedListHeader total={total} />;
  };

  renderBreedRow = breed => {
    const { styles } = this.props;
    return (
      <Tr data-testid="breed" key={breed.get('id')}>
        <Td width={64} className={styles.breedPhotoTD}>
          <HistoryLink to={`/breeds/${breed.get('slug')}`} backTo="Breeds" rel="noreferrer noopener">
            <div
              className={styles.breedPhoto}
              style={{
                backgroundImage: `url('${config.breedPhotoBucketURL}thumbs/${
                  breed.get('photo') ? escape(breed.get('photo')) : 'Placeholder.png'
                }')`,
              }}
            />
          </HistoryLink>
        </Td>
        <Td>
          <div className={styles.breedNameColumn}>
            <HistoryLink to={`/breeds/${breed.get('slug')}`} backTo="Breeds" rel="noopener">
              {breed.get('name')}
            </HistoryLink>
            <span className={styles.mobileView}>{breed.getIn(['breedGroup', 'title'])}</span>
          </div>
        </Td>
        <Fragment>
          <Td className={styles.desktopView}>{breed.getIn(['breedGroup', 'title'])}</Td>
          <Td className={styles.desktopView}>
            <p className={styles.breedSummary}>"{breed.get('headline')}"</p>
          </Td>
        </Fragment>
      </Tr>
    );
  };

  renderBreedsTable = () => {
    const { breedsList, styles } = this.props;
    const beforeAd = breedsList.slice(0, 3);
    const afterAd = breedsList.slice(3);
    return (
      <div className={styles.tableWrapper}>
        <Table>
          <Thead>
            <Tr transparent className={styles.trDesktopView}>
              <Th />
              <Th>Breed Name</Th>
              <Th>Breed Group</Th>
              <Th>Breed Summary</Th>
            </Tr>
          </Thead>
          <Tbody>
            {beforeAd.map(this.renderBreedRow)}
            <Tr key={'fs-ad'}>
              <Td colSpan={1000}>
                <AdBanner placementName="puppies_breeds_incontent11" slotId="breeds-list" />
              </Td>
            </Tr>
            {afterAd.map(this.renderBreedRow)}
          </Tbody>
        </Table>
      </div>
    );
  };

  submitAction = values => {
    const { history, location } = this.props;
    const { group_id, breed } = values;
    const locationObj = querystring.parse(location.search);
    locationObj.page = 0;
    if (group_id) {
      locationObj.group_id = group_id;
    } else {
      delete locationObj.group_id;
    }
    if (breed) {
      locationObj.name = breed;
    } else {
      delete locationObj.name;
    }
    const newQuery = querystring.stringify(locationObj);
    history.push({ pathname: location.pathName, search: newQuery });
  };

  renderPager = () => {
    const { total } = this.props;
    const pageSize = 16;
    if (total > pageSize) {
      return <Pager pageSize={pageSize} totalItems={total} />;
    }
    return null;
  };

  renderSearchForms = () => {
    const { breedGroupOptions, location, styles } = this.props;
    const locationObj = querystring.parse(location.search);
    return (
      <div className={styles.searchWrapper}>
        <div className={styles.search}>
          <SearchForm
            locationObj={locationObj}
            submitAction={this.submitAction}
            breedGroupOptions={breedGroupOptions}
            breeds={this.props.breeds}
          />
        </div>
      </div>
    );
  };

  renderResults = () => {
    const { breedsList, loading, styles } = this.props;

    if (loading || !breedsList) {
      return <Loading dark center />;
    }

    if (!breedsList.size) {
      return <NoContentPlaceholder icon="PuppyOutline" />;
    }

    return (
      <div className={styles.resultsWrapper}>
        <div>
          {this.renderBreedsFound()}
          {this.renderBreedsTable()}
        </div>
        {this.renderPager()}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Breeds - Puppies.com</title>
        </Helmet>
        {this.renderSearchForms()}
        {this.renderResults()}
      </div>
    );
  }
}

const styles = {
  searchWrapper: props => ({
    backgroundColor: props.theme.colors.darkerTan,
    padding: '24px 0',
  }),
  search: props => ({
    display: 'flex',
    maxWidth: props.theme.globalMaxWidth,
    ...props.theme.globalPadding,
    margin: '0 auto',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '12px',
    },
    fontSize: '14px',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.brown,
    letterSpacing: '0.25px',
  }),
  tableWrapper: props => ({
    marginBottom: '50px',
  }),
  breedSummary: props => ({
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: props.theme.typography.sansRegular,
  }),
  breedNameColumn: props => ({
    '> a': { color: props.theme.colors.blue },
    '> a:hover': { color: props.theme.colors.darkerBlue },
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    '> * + *': {
      marginTop: '4px',
    },
    [props.theme.breakpoints.mobileLarge]: {
      fontSize: '16px',
    },
  }),
  resultsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 240px)',
    height: 'auto',
    justifyContent: 'space-between',
    '> *': {
      width: '100%',
    },
  },
  mobileView: props => ({
    [props.theme.breakpoints.mobileLarge]: {
      display: 'none',
    },
  }),
  desktopView: props => ({
    display: 'none',
    [props.theme.breakpoints.mobileLarge]: {
      display: 'table-cell',
    },
  }),
  trDesktopView: props => ({
    display: 'none',
    [props.theme.breakpoints.mobileLarge]: {
      display: 'table-row',
    },
  }),
  breedPhotoTD: {
    padding: '16px 0 16px 24px !important',
  },
  breedPhoto: props => ({
    height: '62px',
    width: '62px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '50%',
    border: `3px solid ${props.theme.colors.blue}`,
  }),
};

export default felaConnect(styles)(BreedsList);
