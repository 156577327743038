import React from 'react';

function Menu() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M11.937 23.383c-6.203 0-11.23-5.147-11.23-11.491C.707 5.547 5.734.4 11.937.4c6.204 0 11.23 5.146 11.23 11.49 0 6.345-5.026 11.492-11.23 11.492zm-5.924-16.5c0-.326.27-.59.587-.59h10.674c.324 0 .587.259.587.59v.685c0 .326-.27.59-.587.59H6.6a.586.586 0 0 1-.587-.59v-.685zm0 4.666c0-.326.27-.59.587-.59h10.674c.324 0 .587.259.587.59v.685c0 .326-.27.59-.587.59H6.6a.586.586 0 0 1-.587-.59v-.685zm0 4.666c0-.326.27-.59.587-.59h10.674c.324 0 .587.259.587.59v.685c0 .326-.27.59-.587.59H6.6a.586.586 0 0 1-.587-.59v-.685zm5.924 6.582c5.877 0 10.645-4.88 10.645-10.905 0-6.025-4.768-10.906-10.645-10.906S1.293 5.867 1.293 11.892c0 6.024 4.767 10.905 10.644 10.905z" />
    </svg>
  );
  /* eslint-enable */
}

export default Menu;
