import React, { Component } from 'react';
import * as yup from 'yup';
import { withFormik, Field } from 'formik';
import { Link, withRouter } from 'react-router-dom';
import { connect as felaConnect } from 'react-fela';
import compose from 'lodash/flowRight';
import theme from '../../../../theme';
import { handleFileUpload } from '../../../../common/utils/uploads/handleFileUpload';
import {
  Button,
  GeoComplete,
  InputError,
  Textarea,
  TextInput,
  Icon,
  FileUploader,
  CircleAvatar,
  Modal,
  IconTopButton,
  ModalFooter,
  ModalBody,
  ButtonPlain,
  LicensingRadioGroup,
  FormErrors,
} from '../../../../common/components';
import PurchaseWizard from '../../common/components/PurchaseWizard';

class MyProfileForm extends Component {
  state = {
    profilePhoto: '',
    showLocationConfirmationModal: false,
    confirmedLocationChange: false,
    successfulPayment: false,
  };

  componentDidUpdate(prevProps) {
    const { status } = this.props;
    if (prevProps.status && status && prevProps.status.showPremiumPayment && !status.showPremiumPayment) {
      this.props.history.push('/account/membership');
    }
  }

  handlePhotoChange = ([{ data }]) => this.props.setFieldValue('photo', data);

  handleDelete = isSaved => {
    const { user } = this.props;
    if (isSaved) {
      this.props.setFieldValue('photoToDelete', user.getIn(['photo', 'key']));
    }
    this.props.setFieldValue('existingPhoto', null);
    this.props.setFieldValue('photo', null);
    this.props.setFieldValue('croppedPhoto', null);
  };

  handleCloseModal = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    this.props.setStatus({ success: true, showPremiumPayment: false });
    if (this.state.successfulPayment) {
      this.props.submitForm();
    }
  };

  setAsSuccessfulPayment = () => {
    this.setState({ successfulPayment: true });
  };

  handleCrop = image => {
    this.props.setFieldValue('croppedPhoto', image);
  };

  renderPaymentModal = () => {
    const {
      purchaseErrors,
      purchaseMembership,
      getBraintreeToken,
      membershipPlans,
      styles,
      user,
      plan,
      history,
      userVM,
    } = this.props;
    return (
      <Modal closeOnEscape closeOnOutsideClick onClose={this.handleCloseModal} isOpen className={styles.paymentModal}>
        <PurchaseWizard
          membershipPlans={membershipPlans}
          onSubmit={({ nonce, plan }) => purchaseMembership({ payment_token: nonce, plan })}
          getBraintreeToken={getBraintreeToken}
          onCancel={() => history.push('/account/membership')}
          plan={plan}
          errors={purchaseErrors}
          user={user}
          userVM={userVM}
          setAsSuccessfulPayment={this.setAsSuccessfulPayment}
        />
      </Modal>
    );
  };

  renderTrialSuccessModal = () => {
    const { history, setStatus, styles } = this.props;
    return (
      <Modal
        closeOnEscape
        closeOnOutsideClick
        isOpen
        onClose={() => {
          setStatus({ success: true, showTrialSuccess: false });
          // #7646 This history.push is necessary to reload the component without a purchaseType prop, otherwise canceling out of the modal after updating a profile, which in this case also starts a trial, and hitting submit again tries to buy a second trial. This whole component should get refactored to not have to depend on purchasing a membership when that's not its job.
          history.push('/account/my-profile');
        }}
      >
        <div className={styles.modalChildren}>
          <div className={styles.modalTitle}>Profile Complete!</div>
          <p>
            You've completed your seller profile. Once you create your first listing and your account is reviewed and
            approved, your
            {` `}
            <strong>3-day free trial</strong>
            {` `}
            will begin. We’ll notify you when the review process is complete.
          </p>
          <div className={styles.modalButtonGroup}>
            <IconTopButton
              icon="Puppy"
              onClick={() => {
                history.push('/listings/create');
              }}
            >
              List my first puppy
            </IconTopButton>
          </div>
        </div>
      </Modal>
    );
  };

  renderConfirmLocationChangeModal = submitForm => {
    const { styles } = this.props;
    return (
      <Modal
        closeOnOutsideClick
        isOpen={this.state.showLocationConfirmationModal}
        onClose={() => this.setState({ showLocationConfirmationModal: false })}
      >
        <ModalBody>
          <div className={styles.modalBody}>
            <Icon icon="Warning" size={40} />
            <h1>Sure about that?</h1>
            <p>Changing the location on your profile will update the location on all of your active listings</p>
          </div>
        </ModalBody>
        <ModalFooter
          actions={[
            <ButtonPlain onClick={() => this.setState({ showLocationConfirmationModal: false })}>
              Never mind
            </ButtonPlain>,
            <Button
              buttonType="round"
              onClick={() => {
                this.setState({ locationChangeConfirmed: true, showLocationConfirmationModal: false });
                submitForm();
              }}
            >
              CONFIRM
            </Button>,
          ]}
        />
      </Modal>
    );
  };

  onSubmit = event => {
    const { user, values, submitForm } = this.props;
    event.preventDefault();
    if (
      !this.state.locationChangeConfirmed &&
      user.get('formatted_location') &&
      values.location &&
      values.location.label !== user.get('formatted_location')
    ) {
      this.setState({ showLocationConfirmationModal: true });
      return;
    } else {
      submitForm();
    }
  };

  render() {
    const {
      isSubmitting,
      status,
      submitForm,
      user,
      styles,
      backTo,
      purchaseType,
      values,
      touched,
      errors,
      userVM,
      plan,
    } = this.props;

    const buttonCopy =
      (backTo && backTo === '/listings/create') || purchaseType
        ? 'Confirm'
        : isSubmitting
        ? 'SAVING...'
        : 'SAVE CHANGES';

    const isNonMember = !userVM.get('isMember');

    return (
      <form id="my-profile-form" onSubmit={this.onSubmit}>
        <div className={styles.profileTop}>
          <div>
            {values.photo || values.existingPhoto ? (
              <CircleAvatar
                size={150}
                photo={values.existingPhoto}
                imageUrl={values.photo}
                croppedImageUrl={values.croppedPhoto}
                editMode={true}
                handleCrop={this.handleCrop}
                handleDelete={this.handleDelete.bind(this, !!values.existingPhoto)}
              />
            ) : (
              <FileUploader
                variant="human"
                onFilesLoaded={this.handlePhotoChange}
                accept={{
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpeg', '.jpg'],
                }}
              />
            )}
          </div>

          <div className={styles.profileTopFields}>
            <div className={styles.profileNameFields}>
              <Field label="First Name" name="first_name" maxLength={40} component={TextInput} required />
              <Field label="Last Name" name="last_name" maxLength={40} component={TextInput} required />
            </div>
            <Field
              label="Email (not publicly displayed)"
              name="email"
              autoComplete="change-email"
              component={TextInput}
              required
            />
          </div>
        </div>
        <div className={styles.profileBottom}>
          <div className={styles.locationField}>
            <Field
              label="Location"
              name="location"
              placeholder="City and State, or ZIP"
              component={GeoComplete}
              initialValue={user.get('formatted_location') || ''}
              required
            />
            {touched.location && errors.location && <InputError>{errors.location}</InputError>}
          </div>

          <div className={styles.bioWrapper}>
            <div className={styles.bioFieldsContainer}>
              <div className={styles.bioField}>
                <Field
                  label="About Me"
                  name="bio"
                  component={Textarea}
                  placeholder="If you're a breeder, this is a good place to talk about yourself and what makes you a responsible breeder."
                />
              </div>
              {!!purchaseType || !isNonMember ? (
                <div className={styles.bioFieldHelp}>
                  <span>
                    WARNING: Posting contact information here will make it publicly available.{' '}
                    <Link to="/blog/articles/lsptJkxc8l0pDk6X9KFRd" target="_blank">
                      Click here
                    </Link>{' '}
                    to learn about how to avoid scams and fraud.
                  </span>
                </div>
              ) : null}
            </div>
            {(user.get('membership') || !!purchaseType || !isNonMember || plan) && (
              <LicensingRadioGroup user={user} values={values} />
            )}
          </div>
        </div>
        <div className={styles.profileButtonWrapper}>
          <FormErrors errors={errors} />
          {status && status.photoError && <InputError>{status.photoError}</InputError>}
          {status && status.error && <InputError>{status.error}</InputError>}

          <div className={styles.innerButton}>
            {status && status.success ? (
              <span>
                {status.showPremiumPayment ? this.renderPaymentModal() : null}
                {status.showTrialSuccess ? this.renderTrialSuccessModal() : null}
                <Button
                  buttonType="roundOrange"
                  type="button"
                  style={{ backgroundColor: theme.colors.green }}
                  disabled={isSubmitting}
                  onClick={this.onSubmit}
                >
                  CHANGES SAVED
                </Button>
              </span>
            ) : (
              <Button
                id="my-profile-form-submit"
                buttonType="roundOrange"
                type="submit"
                disabled={isSubmitting}
                isSubmitting={isSubmitting}
              >
                {buttonCopy}
              </Button>
            )}

            {status && status.success ? <Icon icon="FatCheck" fill={theme.colors.green} /> : null}
          </div>
        </div>
        {this.renderConfirmLocationChangeModal(submitForm)}
      </form>
    );
  }
}

const countNumbersInString = input => {
  const numbers = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'cero',
    'uno',
    'dos',
    'tres',
    'cuatro',
    'cinco',
    'seis',
    'siete',
    'ocho',
    'nueve',
    'diez',
    'null',
    'eins',
    'zwei',
    'drei',
    'vier',
    'funf',
    'sechs',
    'sieben',
    'acht',
    'neun',
    'zehn',
    'zéro',
    'un',
    'deux',
    'trois',
    'quatre',
    'cinq',
    'sept',
    'huit',
    'neuf',
    'dix',
  ];
  var count = input.match(/\d/g) ? input.match(/\d/g).length : 0;
  for (let i = 0; i < numbers.length; i++) {
    if (input.toLowerCase().includes(numbers[i])) {
      count += 1;
    }
  }
  return count;
};

const FormikMyProfileForm = withFormik({
  mapPropsToValues: props => {
    const { user } = props;
    const locObj = user.get('formatted_location')
      ? {
          label: user.get('formatted_location'),
          location: {
            lat: user.get('lat'),
            lng: user.get('lng'),
          },
        }
      : null;
    return {
      first_name: user.get('first_name') || '',
      last_name: user.get('last_name') || '',
      email: user.get('email') || '',
      bio: user.get('bio') || '',
      location: locObj || '',
      photo: null,
      existingPhoto: user.get('photo'),
      video_chat: user.get('video_chat') || false,
      is_federally_licensed: user.getIn(['userLicenses', 'is_federally_licensed']) || 'no',
      is_state_licensed: user.getIn(['userLicenses', 'is_state_licensed']) || 'no',
      federal_license_id: user.getIn(['userLicenses', 'federal_license_id']) || '',
      state_license_id: user.getIn(['userLicenses', 'state_license_id']) || '',
    };
  },

  validationSchema: yup.object().shape({
    first_name: yup
      .string()
      .trim()
      .max(40, 'First name must be 40 characters or less')
      .required('First name is required'),
    last_name: yup
      .string()
      .trim()
      .max(40, 'Last name must be 40 characters or less')
      .required('Last name is required'),
    email: yup
      .string()
      .trim()
      .email()
      .max(64, 'Email must be 64 characters or less')
      .required('Email address is required'),
    bio: yup
      .string()
      .trim()
      .max(5000, 'About Me must be 5000 characters or less'),
    location: yup.mixed().required('Location is required'),
    photoToDelete: yup.string().trim(),
    federal_license_id: yup
      .string()
      .trim()
      .max(20, 'License ID must be 20 characters or less'),
    state_license_id: yup
      .string()
      .trim()
      .max(20, 'License ID must be 20 characters or less'),
  }),

  handleSubmit: async (values, { props, setSubmitting, setStatus, setFieldValue, resetForm, setErrors }) => {
    const { location, ...restValues } = values;
    const userId = props.user.get('id');
    const { first_name, last_name } = values;

    if (countNumbersInString(first_name) > 2) {
      setErrors({ first_name: 'First name contains too many numbers' });
      setSubmitting(false);
      return;
    }

    if (countNumbersInString(last_name) > 2) {
      setErrors({ last_name: 'Last name contains too many numbers' });
      setSubmitting(false);
      return;
    }

    if (restValues.photo && restValues.photo !== props.user.getIn(['photo', 'key'])) {
      const results = await handleFileUpload({
        getUploadToken: props.getUploadToken,
        file: restValues.photo,
        key: `${userId}/profile`,
      });

      restValues.photo = { key: results.Key };
    } else if (restValues.photo !== null) {
      delete restValues.photo;
    }

    if (restValues.croppedPhoto) {
      const croppedResult = await handleFileUpload({
        getUploadToken: props.getUploadToken,
        file: restValues.croppedPhoto,
        key: `${userId}/profile`,
      });
      restValues.croppedPhotoKey = croppedResult.Key;
    }

    delete restValues.croppedPhoto;

    if (restValues.photoToDelete) {
      await props.deleteUserImage(restValues.photoToDelete);
    }

    delete restValues.photoToDelete;
    setFieldValue('photoToDelete', '');

    if (!restValues.photo && restValues.existingPhoto) {
      delete restValues.photo;
    }
    delete restValues.existingPhoto;

    let successfullyStartedTrial = false;
    if (props.purchaseType && props.purchaseType === 'trial') {
      const trialResult = await props.purchaseMembership({
        plan: 'unverified_seller',
        payment_token: 'none',
        payment_processor: 'none',
      });
      successfullyStartedTrial = trialResult.response.ok;
    }
    const newValues = Object.assign({}, restValues, {
      formatted_location: location.label,
      lat: location.location.lat,
      lng: location.location.lng,
    });

    setStatus({});
    const action = await props.submitAction(newValues);
    setSubmitting(false);

    if (!action.response.ok) {
      if (action?.json?.validationErrors) {
        const errors = action.json.validationErrors.reduce((c, e) => {
          return {
            ...c,
            [e.key]: e.message,
          };
        }, {});

        setErrors(errors);
      } else {
        setStatus({
          error: action.json ? action.json.message : 'An unknown error occurred',
        });
      }
    } else {
      if (props.user.get('email') !== values.email) {
        props.setErrorBarMessage(
          <div>
            Email Address Changed, please re-verify in{' '}
            <Link style={{ textDecoration: 'underline' }} to="/account/verifications">
              verification settings
            </Link>
          </div>
        );
      }
      resetForm(); // Can't submit form again without this
      setStatus({ success: true });

      if (successfullyStartedTrial) {
        return setStatus({ success: true, showTrialSuccess: true });
      }

      const membershipPlan = props.membershipPlans.get(props.plan);
      if (membershipPlan) {
        if (membershipPlan.get('benefits') === 'standard') {
          return setStatus({ success: true, showPremiumPayment: true });
        }

        if (membershipPlan.get('benefits') === 'premier') {
          return props.history.push({
            pathname: '/account/premier-profile',
            state: { fromMembership: true, plan: props.plan },
          });
        }
      }

      if (props.backTo) {
        const backToRoute = props.backTo === 'Profile' ? `/profile/${props.user.get('id')}` : '/';
        return props.history.push(backToRoute);
      }

      return props.history.push('/');
    }
  },
})(MyProfileForm);

const styles = props => ({
  profileTop: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingBottom: '48px',
    marginTop: '32px',
    marginBottom: '32px',
    borderBottom: props.theme.globalDashedBorder,
    '& > * + *': {
      marginLeft: '36px',
    },
    '@media (max-width: 900px)': {
      flexDirection: 'column',
      padding: '0 20px 32px',
      '& > * + *': {
        marginLeft: '0',
        marginTop: '24px',
      },
    },
  },
  profileTopFields: {
    width: '100%',
    maxWidth: '540px',
    '& > * + *': {
      marginTop: '20px',
    },
  },
  profileNameFields: {
    display: 'flex',
    flexDirection: 'row',
    '> div': {
      flex: 1,

      '&:first-of-type': {
        marginRight: '8px',
      },
      '&:last-of-type': {
        marginLeft: '8px',
      },
    },
  },
  profileBottom: {
    '& > * + *': {
      marginTop: '24px',
    },
    '@media (max-width: 900px)': {
      padding: '0 20px',
    },
  },
  videoChatField: {
    padding: '0 20px 35px 20px',

    [props.theme.breakpoints.tablet]: {
      padding: '0 0 35px 0',
    },
  },
  locationField: {
    [props.theme.breakpoints.mobile]: {
      width: '250px',
    },
  },
  bioWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  bioFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '40px',
    '& > * + *': {
      margin: '15px',
      [props.theme.breakpoints.tabletMid]: {
        marginLeft: '30px',
      },
    },
    [props.theme.breakpoints.tabletMid]: {
      flexDirection: 'row',
    },
  },
  bioField: {
    flex: '0 1 100%',
    width: '100%',
    '> div > label > div > textarea': {
      resize: 'none',
      width: '100%',
      height: '162px',
    },
    '@media (max-width: 900px)': {
      '> div > label > div > textarea': {
        width: '100%',
      },
    },
  },
  bioFieldHelp: {
    fontFamily: props.theme.typography.sans,
    color: '#414042',
    fontSize: '14px',
    width: '100%',
    [props.theme.breakpoints.tabletMid]: {
      width: '35%',
    },
    '> span': {
      display: 'block',
      fontWeight: props.theme.typography.sansBold,
      marginBottom: '24px',
      '> a': {
        color: 'rgb(26, 185, 196)',
      },
    },
    '> ul': {
      '> li': {
        lineHeight: '1.5',
      },
      '& > * + *': {
        marginTop: '4px',
      },
    },
  },
  bioIntro: {
    fontSize: '14px',
    lineHeight: '20px',
    color: props.theme.colors.textDark,
  },
  profileButtonWrapper: {
    padding: '20px 0 0',
    borderTop: props.theme.globalDashedBorder,
    '> button': {
      borderRadius: '100px',
      minWidth: 'unset',
    },
    '@media (max-width: 900px)': {
      flexDirection: 'column',
      padding: '20px 20px 32px',
    },
  },
  innerButton: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '20px',
    '& > * + *': {
      marginLeft: '12px',
    },
  },
  modalChildren: {
    padding: '30px',
    backgroundColor: '#F5F5F5',
    borderRadius: '6px',
    '>p': {
      lineHeight: '16px',
      fontSize: '14px',
      marginBottom: '30px',
    },
  },
  modalTitle: {
    fontSize: '28px',
    lineHeight: '32px !important',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.brown,
    textAlign: 'center',
    margin: '30px 0 20px',
    borderRadius: '3px',
  },
  modalButtonGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '> * + *': {
      marginTop: '20px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      justifyContent: 'center',
      '> *': {
        width: '143px',
      },
      '> * + *': {
        marginTop: 0,
        marginLeft: '20px',
      },
    },
  },
  paymentModal: {
    borderRadius: '6px !important',
  },
  modalBody: {
    padding: '64px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: '#D0021B',
    '>h1': {
      marginTop: '10px',
      fontSize: '28px',
      lineHeight: '38px',
      fontWeight: props.theme.typography.sansBold,
    },
    '>p': {
      marginTop: '16px',
      color: props.theme.colors.brown,
      width: '100%',
    },
  },
});

export default compose(felaConnect(styles), withRouter)(FormikMyProfileForm);
