import React from 'react';
import { connect as felaConnect } from 'react-fela';
import { Icon } from '..';

const IconTopButton = ({ icon, styles, onClick, children, iconSize = 22, disabled = false }) => {
  return (
    <button className={styles.root} disabled={disabled} onClick={onClick}>
      <Icon icon={icon} size={iconSize} />
      <div className={styles.text}>{children}</div>
    </button>
  );
};

export default felaConnect(props => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '12px 6px 6px',
    width: '100%',
    minHeight: '79px',
    border: '2px solid transparent',
    boxShadow: props.theme.globalBoxShadow,
    color: props.theme.colors.blue,
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltBlack,
    fontSize: '12px',
    borderRadius: '3px',
    lineHeight: '14px',
    letterSpacing: '1px',
    transition: props.theme.globalTransition,
    '> * + *': {
      marginTop: '8px',
    },
    ':hover': {
      borderColor: props.theme.colors.orange,
    },
    ':active': {
      backgroundColor: props.theme.colors.green,
      borderColor: props.theme.colors.green,
      '> * + *': {
        color: 'white',
      },
      '> span > svg': {
        fill: 'white',
      },
    },
  },
  text: {
    width: '100%',
  },
}))(IconTopButton);
