import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';

import ResetPasswordForm from './ResetPasswordForm';
import { ButtonPlain } from '../../common/components';

class ResetPassword extends Component {
  static propTypes = {
    resetPassword: PropTypes.func.isRequired,
  };
  render() {
    const { isLoggedIn, match, resetPassword, styles } = this.props;

    if (isLoggedIn) {
      return <Redirect to="/" />;
    }

    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1>Please Reset Your Password</h1>
          <ResetPasswordForm submitAction={resetPassword} tokenId={match.params.tokenId} />
          <div className={styles.link}>
            <ButtonPlain>
              <Link to="/sign-in">Just need to log in?</Link>
            </ButtonPlain>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  container: props => ({
    ...props.theme.globalBackgroundContainer,
  }),
  wrapper: props => ({
    ...props.theme.globalPageWrapper,
    '>h1': {
      color: props.theme.colors.orange,
      fontWeight: props.theme.typography.sansBold,
    },
  }),
  link: {
    fontSize: '14px',
  },
};

export default felaConnect(styles)(ResetPassword);
