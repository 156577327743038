import { combineReducers } from 'redux';
import { fromJS, Map } from 'immutable';

function getUserIdFromJson({ action, reducerType }) {
  switch (action.type) {
    case 'UPDATE_TESTIMONIAL_VISIBILITY_SUCCESS':
    case 'UPDATE_REPLY_VISIBILITY_SUCCESS':
    case 'CREATE_OR_UPDATE_TESTIMONIAL_SUCCESS':
      return action.json.user_id;
    case 'CREATE_OR_UPDATE_SELLER_TESTIMONIAL_SUCCESS':
    case 'REPLY_TO_SELLER_TESTIMONIAL_SUCCESS':
    case 'UPDATE_LEGACY_TESTIMONIAL_SUCCESS':
      if (reducerType === 'byUser') {
        return action.json.buyer_id;
      }
      return action.json.seller_id;
    case 'REPLY_TO_LEGACY_TESTIMONIAL_SUCCESS':
      return action.json.seller_id;
    default:
      return action.json.seller_id;
  }
}

const total = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_TESTIMONIALS_FOR_USER_SUCCESS':
      return state.set(action.userId, fromJS(action.json.total));
    case 'GET_TESTIMONIALS_FOR_PREMIER_SUCCESS':
      return state.set(action.userId, fromJS(action.json.total));
    default:
      return state;
  }
};

const testimonialsForUser = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_TESTIMONIALS_FOR_USER_SUCCESS':
      return state.set(action.userId, fromJS(action.json.results));
    case 'GET_TESTIMONIALS_FOR_PREMIER_SUCCESS':
      return state.set(action.json.userId, fromJS(action.json.testimonials));
    case 'CREATE_OR_UPDATE_TESTIMONIAL_SUCCESS':
    case 'CREATE_OR_UPDATE_SELLER_TESTIMONIAL_SUCCESS':
    case 'REPLY_TO_SELLER_TESTIMONIAL_SUCCESS':
    case 'REPLY_TO_LEGACY_TESTIMONIAL_SUCCESS':
    case 'REPLY_TO_TESTIMONIAL_SUCCESS':
    case 'UPDATE_LEGACY_TESTIMONIAL_SUCCESS':
    case 'UPDATE_TESTIMONIAL_VISIBILITY_SUCCESS':
    case 'UPDATE_REPLY_VISIBILITY_SUCCESS':
      const userId = getUserIdFromJson({ action, reducerType: 'forUser' });
      const pathToResults = [userId];
      if (state.getIn(pathToResults)) {
        const updatedTestimonialIndex = state
          .getIn(pathToResults)
          .findIndex(testimonial => testimonial.get('id') === action.json.id);
        if (updatedTestimonialIndex > -1) {
          return state.mergeDeepIn(pathToResults.concat([updatedTestimonialIndex]), fromJS(action.json));
        } else {
          return state.updateIn(pathToResults, arr => arr.unshift(fromJS(action.json)));
        }
      }
      return state;
    default:
      return state;
  }
};

const testimonialsByUser = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_TESTIMONIALS_BY_USER_SUCCESS':
      return state.set(action.userId, fromJS(action.json.results));
    case 'CREATE_OR_UPDATE_TESTIMONIAL_SUCCESS':
    case 'CREATE_OR_UPDATE_SELLER_TESTIMONIAL_SUCCESS':
    case 'REPLY_TO_LEGACY_TESTIMONIAL_SUCCESS':
    case 'REPLY_TO_TESTIMONIAL_SUCCESS':
    case 'UPDATE_LEGACY_TESTIMONIAL_SUCCESS':
    case 'UPDATE_TESTIMONIAL_VISIBILITY_SUCCESS':
    case 'UPDATE_REPLY_VISIBILITY_SUCCESS':
      const userId = getUserIdFromJson({ action, reducerType: 'byUser' });
      const pathToResults = [userId];
      if (state.getIn(pathToResults)) {
        const updatedTestimonialIndex = state
          .getIn(pathToResults)
          .findIndex(testimonial => testimonial.get('id') === action.json.id);
        if (updatedTestimonialIndex > -1) {
          return state.mergeDeepIn(pathToResults.concat([updatedTestimonialIndex]), fromJS(action.json));
        } else {
          return state.updateIn(pathToResults, arr => arr.unshift(fromJS(action.json)));
        }
      }
      return state;
    default:
      return state;
  }
};

const testimonialsForAndByUser = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_TESTIMONIALS_FOR_AND_BY_USER_SUCCESS':
      return state.set(action.userId, fromJS(action.json));
    case 'CREATE_OR_UPDATE_TESTIMONIAL_SUCCESS':
    case 'CREATE_OR_UPDATE_SELLER_TESTIMONIAL_SUCCESS':
    case 'REPLY_TO_LEGACY_TESTIMONIAL_SUCCESS':
    case 'REPLY_TO_TESTIMONIAL_SUCCESS':
    case 'UPDATE_LEGACY_TESTIMONIAL_SUCCESS':
    case 'UPDATE_TESTIMONIAL_VISIBILITY_SUCCESS':
    case 'UPDATE_REPLY_VISIBILITY_SUCCESS':
      const userId = getUserIdFromJson({ action });
      const pathToResults = [userId];
      if (state.getIn(pathToResults)) {
        const updatedTestimonialIndex = state
          .getIn(pathToResults)
          .findIndex(testimonial => testimonial.get('id') === action.json.id);
        if (updatedTestimonialIndex > -1) {
          return state.mergeDeepIn(pathToResults.concat([updatedTestimonialIndex]), fromJS(action.json));
        }
      }
      return state;
    default:
      return state;
  }
};

const listingsForBuyer = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_LISTINGS_FOR_BUYER_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const finalRejected = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_FINAL_REJECTED_BY_USER_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

export default combineReducers({
  testimonialsForUser,
  testimonialsByUser,
  testimonialsForAndByUser,
  listingsForBuyer,
  finalRejected,
  total,
});
