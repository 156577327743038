import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as felaConnect } from 'react-fela';
import { Link, withRouter } from 'react-router-dom';
import PuppyLogoWithText from '../icons/PuppyLogoWithText';

class TopNavLoggedOut extends Component {
  static propTypes = {
    buttonLeft: PropTypes.node,
    buttonRight: PropTypes.node,
  };

  renderLogo = () =>
    this.props.location.pathname === '/' ? (
      <PuppyLogoWithText fill="#FFF" />
    ) : (
      <Link to="/">
        <PuppyLogoWithText fill="#FFF" />
      </Link>
    );

  render() {
    const { styles, buttonLeft, buttonRight } = this.props;

    return (
      <div>
        <div className={styles.actionsMobile}>
          {this.renderLogo()}
          <div className={styles.actionsMobileBtns}>
            {buttonLeft}
            {buttonRight}
          </div>
        </div>
        <div id="top-nav-desktop-actions" className={styles.actions}>
          {buttonLeft}
          {this.renderLogo()}
          {buttonRight}
        </div>
      </div>
    );
  }
}

const styles = props => ({
  actions: {
    display: 'none',
    [props.theme.breakpoints.tabletLarge]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  actionsMobile: {
    display: 'block',
    textAlign: 'center',
    [props.theme.breakpoints.tabletLarge]: {
      display: 'none',
    },
  },
  actionsMobileBtns: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '> * + *': {
      marginTop: '18px',
    },
    [props.theme.breakpoints.mobileSmall]: {
      display: 'block',
      '> * + *': {
        marginLeft: '18px',
        marginTop: 0,
      },
    },
  },
});

export default withRouter(felaConnect(styles)(TopNavLoggedOut));
