import React from 'react';

function CaretUp() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M2.5 15.245c0-.114.049-.2.117-.285l9.577-9.346a.458.458 0 0 1 .605 0l9.577 9.346a.4.4 0 0 1 0 .58l-2.92 2.846a.445.445 0 0 1-.595 0l-6.365-6.224-6.365 6.224a.445.445 0 0 1-.595 0L2.617 15.54a.467.467 0 0 1-.117-.295z"
        fillRule="evenodd"
      />
    </svg>
  );
  /* eslint-enable */
}

export default CaretUp;
