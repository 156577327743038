import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';

import {
  Dropdown,
  PuppyGrid,
  Pager,
  StandaloneField,
  Loading,
  PuppyListHeader,
  NoContentPlaceholder,
} from '../../../../../common/components';

class FavoriteListings extends Component {
  renderPager = () => {
    const { total, pageSize } = this.props;
    return <Pager pageSize={pageSize} totalItems={total} />;
  };

  renderPuppyList = () => {
    const {
      listings,
      total,
      styles,
      randomTips,
      pageSize,
      handleSortChange,
      sortOptions,
      sortBy,
      location,
      removeFavoriteListing,
    } = this.props;
    if (!total) {
      return (
        <NoContentPlaceholder
          title="No puppies added to favorites"
          description="When you find a puppy you like, favorite them by clicking the heart icon on the listing. They’ll show up here."
          to="/find-a-puppy"
          buttonText="Find a Puppy"
          topMargin
          globalPadding
        />
      );
    }
    return (
      <PuppyGrid
        total={total}
        tips={randomTips}
        pageSize={pageSize}
        listings={listings}
        location={location}
        removeFavoriteListing={removeFavoriteListing}
        noAds
        header={
          <PuppyListHeader
            total={total}
            totalLabel="favorited"
            actions={
              listings && [
                <div className={styles.sortBy}>
                  <StandaloneField
                    component={Dropdown}
                    variant="primary"
                    options={sortOptions}
                    name="sort"
                    value={sortBy}
                    onChange={handleSortChange}
                  />
                </div>,
              ]
            }
          />
        }
      />
    );
  };

  render() {
    const { loaded, loading } = this.props;
    if (!loaded || loading) {
      return <Loading center dark />;
    }
    return (
      <div>
        {this.renderPuppyList()}
        {this.renderPager()}
      </div>
    );
  }
}

const styles = {
  sortBy: {
    minWidth: '150px',
  },
};

export default felaConnect(styles)(FavoriteListings);
