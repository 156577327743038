import theme from '../../../theme';

const styles = props => ({
  formRow: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& > * + *': {
      marginTop: '16px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      flexDirection: 'row',
      '& > * + *': {
        marginTop: 0,
        marginLeft: '32px',
      },
    },
  },
  formRowWider: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '& > *': {
      marginBottom: '28px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      '& > * + *': {
        marginTop: '0',
      },
    },
    [props.theme.breakpoints.tablet]: {
      justifyContent: 'flex-start',
      '& > * + *': {
        marginLeft: '87px',
      },
    },
  },
  nameBirthdayWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',

    [props.theme.breakpoints.mobileSmall]: {
      flexDirection: 'row',
      '& > * + *': {
        marginLeft: '32px',
      },
    },
    [props.theme.breakpoints.tabletLarge]: {
      width: 'auto',
    },
  },
  puppyNameInput: {
    width: '100%',
    [props.theme.breakpoints.tabletLarge]: {
      width: 'auto',
    },
  },
  puppyBirthDate: {
    width: '100%',
    [props.theme.breakpoints.tabletLarge]: {
      width: 'auto',
    },
  },
  breedField: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    [props.theme.breakpoints.tabletLarge]: {
      flexDirection: 'row',
      width: 'auto',
    },
  },
  singleBreedPlus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    '> div': {
      width: '100%',
    },
  },
  mixedBreedMinus: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: '16px',
    '> div': {
      width: '100%',
    },
    [props.theme.breakpoints.tabletLarge]: {
      marginTop: 0,
      marginLeft: '30px',
    },
  },
  priceGenerCoatRowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexShrink: 0,

    flexDirection: 'column',
    '> * + *': {
      marginTop: '32px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      flexDirection: 'row',
      '> * + * ': {
        marginTop: 0,
        marginLeft: '32px',
      },
    },
  },
  priceGenderCoatFormRow: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexShrink: 0,
    '& > * + *': {
      marginTop: '16px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& > * + *': {
        marginLeft: '32px',
        marginTop: 0,
      },
    },
    [props.theme.breakpoints.tabletLarge]: {
      justifyContent: 'flex-start',
    },
  },
  priceField: {
    width: '100%',
    [props.theme.breakpoints.mobile]: {
      flexBasis: '20%',
    },
    [props.theme.breakpoints.tabletLarge]: {
      flexBasis: 'auto',
      maxWidth: '73px',
    },
  },
  genderToggle: {
    width: '100%',
    [props.theme.breakpoints.mobile]: {
      flexBasis: '20%',
    },
    [props.theme.breakpoints.tabletLarge]: {
      flexBasis: 'auto',
      width: 'inherit',
    },
  },
  coatPattern: {
    width: '100%',
    [props.theme.breakpoints.mobile]: {
      flexBasis: '20%',
    },
    [props.theme.breakpoints.tabletLarge]: {
      flexBasis: 'auto',
      width: 'inherit',
    },
  },
  listingDetails: {
    marginBottom: '32px',
    '& > * + *': {
      marginTop: '16px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      '& > * + *': {
        marginTop: '40px',
      },
    },
  },
  listingBio: {
    '& > * + *': {
      marginTop: '16px',
    },
  },
  characteristics: {
    marginTop: '40px',
    [props.theme.breakpoints.tabletLarge]: {
      '& > * + *': {
        marginTop: '30px !important',
      },
    },
  },
  characteristicsLabel: {
    fontWeight: props.theme.typography.sansBold,
    fontSize: '12px',
    color: props.theme.colors.blue,
    marginBottom: '16px',
  },
  characteristicsListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: '20px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      '& > * + *': {
        marginTop: 0,
        marginLeft: '20px',
      },
    },
    [props.theme.breakpoints.tabletLarge]: {
      flexDirection: 'column !important',
      '& > * + *': {
        marginTop: '20px !important',
        marginLeft: '0 !important',
      },
    },
  },
  characteristicsList: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexBasis: '50%',
    '> div': {
      width: '100%',
    },
    '& > * + *': {
      marginLeft: '0',
      marginTop: '16px',
    },
    '> div > label > div > button': {
      fontSize: '12px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      flexBasis: 'inherit',
      '& > * + *': {
        marginTop: '0',
        marginLeft: '3.5%',
      },
      '> div > label > div > button': {
        maxWidth: '182px',
      },
    },
  },
  rule: {
    border: '0.5px dashed #979797',
    margin: '32px 0',
  },
  ruleBottom: {
    border: '0.5px dashed #979797',
    margin: '10px 0 32px',
  },
  bioWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& > * + *': {
      margin: '15px',
      [props.theme.breakpoints.tabletMid]: {
        marginLeft: '30px',
      },
    },
    [props.theme.breakpoints.tabletMid]: {
      flexDirection: 'row',
    },
  },
  bioField: {
    flex: '0 1 100%',
    width: '100%',
    '> div > label > div > textarea': {
      width: '100%',
      height: '160px',
      resize: 'none',
    },
    '@media (max-width: 760px)': {
      '> div > label > div > textarea': {
        width: '100%',
      },
    },
  },
  bioFieldHelp: {
    color: '#414042',
    fontSize: '14px',
    width: '100%',
    [props.theme.breakpoints.tabletMid]: {
      width: '35%',
    },
    '> span': {
      display: 'block',
      fontWeight: props.theme.typography.sansBold,
      marginBottom: '24px',
      '> a': {
        color: 'rgb(26, 185, 196)',
      },
    },
    '> ul': {
      '> li': {
        lineHeight: '1.5',
      },
      '& > * + *': {
        marginTop: '4px',
      },
    },
  },
  listingFormButtonWrapper: {
    width: '100%',
    '> * + *': {
      marginTop: '16px',
    },
    '>button': {
      width: '100%',
    },
    [props.theme.breakpoints.tablet]: {
      width: 'inherit',
      '>button': {
        width: 'inherit',
      },
    },
    marginTop: '32px',
    '> button': {
      marginTop: '20px',
      borderRadius: '100px',
    },
  },
  modalChildren: {
    padding: '30px',
    backgroundColor: '#F5F5F5',
    borderRadius: '6px',
  },
  modalTitle: {
    fontSize: '28px',
    lineHeight: '32px !important',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.brown,
    textAlign: 'center',
    margin: '30px 0',
    borderRadius: '3px',
  },
  newSellerMessage: {
    marginBottom: '30px',
  },
  modalButtonGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px',
    '> *': {
      width: '100%',
    },
    '> * + *': {
      marginTop: '20px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      justifyContent: 'center',
      '> *': {
        width: '143px',
      },
      '> * + *': {
        marginTop: 0,
        marginLeft: '20px',
      },
    },
  },
  modalButtonGroupTrial: {
    marginTop: '20px',
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
  cancelModalBody: {
    padding: '64px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: '#D0021B',
    '>h1': {
      marginTop: '10px',
      fontSize: '28px',
      lineHeight: '38px',
      fontWeight: props.theme.typography.sansBold,
    },
    '>p': {
      marginTop: '16px',
      color: props.theme.colors.brown,
    },
  },
  sectionTitle: {
    fontWeight: props.theme.typography.sansLight,
    color: props.theme.colors.orange,
    lineHeight: '1.25',
  },
  buttonWithIndicator: {
    display: 'flex',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '16px',
    },
  },
  colorSquare: {
    height: '50px',
    borderRadius: '100%',
    [props.theme.breakpoints.tabletMid]: {
      width: '90px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      height: '40px',
      width: '40px !important',
      marginBottom: '20px',
      marginRight: '20px',
    },
  },
  shipper: {
    marginTop: '1rem',
    display: 'inline-block',
    border: '1px solid #1B64E1',
    backgroundColor: 'white',
    padding: '1em',
    fontSize: '14px',
    width: '100%',
    [props.theme.breakpoints.tabletLarge]: {
      maxWidth: '47.25rem',
    },
    '> div': {
      margin: '5px 0',
    },
  },
  divWithSvg: {
    [props.theme.breakpoints.mobile]: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },
    '> svg': {
      margin: '10px 0 5px',
    },
  },
  blueBar: {
    display: 'none',
    [props.theme.breakpoints.mobile]: {
      display: 'block',
    },
    width: '47px',
    height: '4px',
    backgroundColor: '#1B64E1',
    marginBottom: '12px !important',
  },
  labelContainer: {
    '> div': {
      marginLeft: '-10px',
    },
    '> div > label > div > label > div > span': {
      textTransform: 'none',
    },
  },
  learnMore: {
    color: theme.colors.blue,
    fontWeight: 'bold',
  },
  individualPuppyWarning: {
    marginTop: '20px',
    backgroundColor: 'rgba(26, 185, 196, 0.17)',
    color: '#212121',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    padding: '10px',
    '> span': {
      marginRight: '8px',
      color: theme.colors.blue,
    },
  },
});

export default styles;
