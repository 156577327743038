import React from 'react';

function ContentCapitalized({ children }) {
  return (
    <span style={{ textTransform: 'capitalize' }}>
      {children.includes('redacted') ? 'Former Puppies Member' : children}
    </span>
  );
}

export default ContentCapitalized;
