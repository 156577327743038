import React from 'react';

function ThickArrowRight() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M4,15V9H12V4.16L19.84,12L12,19.84V15H4Z" />
    </svg>
  );
  /* eslint-enable */
}

export default ThickArrowRight;
