import React from 'react';

function Facebook() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M16.12 22.914v-8.486h2.85l.426-3.307H16.12V9.009c0-.957.265-1.61 1.639-1.61h1.751V4.44a23.41 23.41 0 0 0-2.552-.13c-2.525 0-4.254 1.541-4.254 4.372v2.439H9.85v3.307h2.856v8.486H2.21A1.21 1.21 0 0 1 1 21.705V2.209C1 1.541 1.541 1 2.21 1h19.495c.668 0 1.21.541 1.21 1.21v19.495a1.21 1.21 0 0 1-1.21 1.21H16.12z" />
    </svg>
  );
  /* eslint-enable */
}

export default Facebook;
