import React, { Component } from 'react';
import { connect } from 'react-fela';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class PlainLink extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    variant: PropTypes.oneOf(['primary', 'blue']),
    to: PropTypes.any,
    noUnderline: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    variant: 'primary',
  };

  render() {
    const { children, className, styles, variant, to, noUnderline, ...passthrough } = this.props;

    const classes = {
      [styles.root]: true,
      [styles.blue]: variant === 'blue',
      [styles.red]: variant === 'red',
      [className]: className,
    };

    return (
      <a className={classNames(classes)} href={to} {...passthrough}>
        {children}
      </a>
    );
  }
}

const styles = props => ({
  root: {
    display: 'inline-block',
    color: 'inherit',
    cursor: props.disabled ? 'auto' : 'pointer',
    transition: props.theme.globalTransition,
    flexShrink: 0,
    fontWeight: props.theme.typography.sansBold,
    textDecoration: props.noUnderline ? 'none' : 'underline',
    fontSize: 'inherit',
    textAlign: 'left',
    ':hover': {
      opacity: 0.66,
    },
    ':disabled': {
      color: props.theme.colors.disabledText,
    },
  },
  blue: {
    color: `${props.theme.colors.blue} !important`,
    opacity: 1,

    ':hover': {
      color: `${props.theme.colors.darkerBlue} !important`,
      opacity: 1,
    },
  },
  red: {
    color: `${props.theme.colors.red} !important`,
    ':hover': {
      color: `${props.theme.colors.darkerRed} !important`,
      opacity: 1,
    },
  },
});

export default connect(styles)(PlainLink);
