import React from 'react';

import ButtonPlain from '../ButtonPlain';
import InputError from '../InputError';

export const DEFAULT_OFFSET = 122;

const getElement = key => {
  const foundType = ['input', 'textarea', 'button'].find(t => document.querySelector(`${t}[name="${key}"]`));
  return foundType ? document.querySelector(`${foundType}[name="${key}"]`) : null;
};

const FormErrors = ({ errors = {}, offset = DEFAULT_OFFSET }) => {
  const errorList = Object.keys(errors).map(k => {
    return { key: k, error: errors[k] };
  });

  if (!errorList.length) {
    return null;
  }

  return (
    <div data-testid="form-errors">
      {errorList.map(e => {
        const element = getElement(e.key);
        return (
          <InputError key={e.key}>
            {e.error}{' '}
            {element && (
              <ButtonPlain
                onClick={() => {
                  element.scrollIntoView();
                  window.scrollBy(0, -offset); // take into account the header
                }}
              >
                view field
              </ButtonPlain>
            )}
          </InputError>
        );
      })}
    </div>
  );
};

export default FormErrors;
