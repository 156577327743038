import { combineReducers } from 'redux';

const activeModal = (state = null, action) => {
  if (action.type === 'SET_ACTIVE_MODAL') {
    return action.modal;
  } else if (action.type === 'SHOW_AUTHENTICATION_MODAL') {
    return 'signup';
  } else if (action.type === 'GET_TOKEN_SUCCESS') {
    return null;
  }
  return state;
};

export default combineReducers({
  activeModal,
});
