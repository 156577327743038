import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListHeader from './ListHeader';

class PuppyListHeader extends Component {
  static propTypes = {
    total: PropTypes.number,
    totalLabel: PropTypes.string,
    breedType: PropTypes.string,
  };

  static defaultProps = {
    totalLabel: 'Found',
  };

  render() {
    const { total, breedType, totalLabel, ...restProps } = this.props;

    return (
      <ListHeader {...restProps}>
        {total ? <strong>{total.toLocaleString()}</strong> : 'No'}{' '}
        {total === 1 ? (breedType ? `${breedType} Puppy` : 'Puppy') : breedType ? `${breedType} Puppies` : 'Puppies'}{' '}
        {totalLabel}
      </ListHeader>
    );
  }
}

export default PuppyListHeader;
