import React from 'react';
import { Modal, ConfirmationModalBody, ModalFooter, Button, ButtonPlain } from '../../../../../common/components';
import theme from '../../../../../theme';

const ConfirmationModal = ({ conversation, onArchive, onUnarchive, confirmStatusChange, setConfirmStatusChange }) => {
  const archiving = confirmStatusChange === 'archive';
  return (
    <Modal
      isOpen={!!confirmStatusChange}
      onClose={() => setConfirmStatusChange(null)}
      closeOnOutsideClick
      closeOnEscape
    >
      <ConfirmationModalBody
        icon="FatCheck"
        iconFill={theme.colors.green}
        title={archiving ? 'Archive Conversation?' : 'Unarchive Conversation?'}
        subTitle={
          archiving
            ? 'This conversation will be moved to your archive.'
            : 'This conversation will be moved to your inbox where you can continue the conversation.'
        }
      />
      <ModalFooter
        actions={[
          <ButtonPlain variant="red" onClick={() => setConfirmStatusChange(null)}>
            Cancel
          </ButtonPlain>,
          <Button
            buttonType="round"
            onClick={() => {
              if (archiving) {
                onArchive(conversation.get('id'));
              } else {
                onUnarchive(conversation.get('id'));
              }
              setConfirmStatusChange(null);
            }}
          >
            Confirm
          </Button>,
        ]}
      />
    </Modal>
  );
};

export default ConfirmationModal;
