import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import PropTypes from 'prop-types';
import theme from '../../../theme';
import classNames from 'classnames';

const Image1 = isWebpSupported ? `/static/lp1.webp` : `/static/lp1.png`;
const Image2 = isWebpSupported ? `/static/lp2.webp` : `/static/lp2.png`;
const Image3 = isWebpSupported ? `/static/lp3.webp` : `/static/lp3.png`;

class LandingPageCopy extends Component {
  static propTypes = {
    headerText: PropTypes.string,
    sections: PropTypes.array,
    image: PropTypes.string,
    imagePosition: PropTypes.oneOf(['left', 'right']),
  };

  static defaultProps = {
    headerText: 'Why choose Puppies.com?',
    sections: [],
  };
  render() {
    const { styles } = this.props;
    const defaultImages = [Image1, Image2, Image3];
    const colorThemes = [theme.colors.orange, theme.colors.blue, theme.colors.yellow];
    return (
      <div className={styles.root}>
        <h1>{this.props.headerText}</h1>
        <div className={styles.wrapper}>
          {this.props.sections.map((section, i) => (
            <div
              key={i}
              className={classNames([styles.copyItem, section.imagePosition === 'right' && styles.copyItemRight])}
            >
              <div
                style={{
                  backgroundImage: `url(${section.image ? section.image : defaultImages[i]})`,
                  borderColor: `${colorThemes[i]}`,
                }}
                className={styles.image}
              />
              <div className={classNames([styles.copy, section.imagePosition === 'right' && styles.copyRight])}>
                <h2 style={{ color: `${colorThemes[i]}` }}>{section.title}</h2>
                <div
                  className={classNames([
                    styles.barWrapper,
                    section.imagePosition === 'right' && styles.barWrapperRight,
                  ])}
                >
                  <div style={{ backgroundColor: `${colorThemes[i]}` }} className={styles.bar} />
                </div>
                <p>{section.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const styles = props => ({
  root: {
    ...props.theme.globalPageWrapper,
    marginBottom: '100px',
    '> h1': {
      fontSize: '28px',
      lineHeight: '32px',
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansAltBold,
      color: '#1BB9C4',
      textAlign: 'center',
      marginTop: '20px',
      marginBottom: '60px',
      [props.theme.breakpoints.tablet]: {
        fontSize: '40px',
        lineHeight: '48px',
      },
    },
  },
  image: {
    height: '300px',
    width: '300px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '50%',
    borderWidth: '10px',
    borderStyle: 'solid',
    flexShrink: 0,
  },
  wrapper: {
    maxWidth: '800px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    '> * + *': {
      marginTop: '100px',
    },
  },
  copyItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '> * + *': {
      marginTop: '32px',
    },
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      '> * + *': {
        marginTop: 0,
        marginLeft: '100px',
      },
    },
  },
  copyItemRight: {
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row-reverse',
    },
  },
  copy: {
    textAlign: 'center',
    [props.theme.breakpoints.tablet]: {
      textAlign: 'left',
    },
    '> h2': {
      fontSize: '28px',
      lineHeight: '32px',
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansAltBold,
      [props.theme.breakpoints.tablet]: {
        fontSize: '40px',
        lineHeight: '48px',
      },
    },
    '> p': {
      fontSize: '16px',
      lineHeight: '26px',
      color: props.theme.colors.darkerGray,
      marginTop: '30px',
      [props.theme.breakpoints.tablet]: {
        fontSize: '22px',
        lineHeight: '36px',
      },
      '> a': {
        color: props.theme.colors.blue,
        fontWeight: 'bold',
        textDecoration: 'underline',
        transition: '0.2s',

        '&:hover': {
          color: props.theme.colors.darkerBlue,
        },
      },
    },
  },
  copyRight: {
    [props.theme.breakpoints.tablet]: {
      marginRight: '100px',
      marginLeft: 0,
      textAlign: 'right',
    },
  },
  bar: {
    width: '75px',
    height: '6px',
    marginTop: '16px',
  },
  barWrapper: {
    display: 'none',
    [props.theme.breakpoints.tablet]: {
      display: 'flex',
    },
  },
  barWrapperRight: {
    justifyContent: 'flex-end',
  },
});

export default felaConnect(styles)(LandingPageCopy);
