const breedUrlMap = {
  schapendoes: { id: 6, name: 'Schapendoes' },
  'english-cocker-spaniel': { id: 304, name: 'English Cocker Spaniel' },
  'saarloos-wolfdog': { id: 8, name: 'Saarloos Wolfdog' },
  'spanish-water-dog': { id: 25, name: 'Spanish Water Dog' },
  'russianeuropean-laika': { id: 2, name: 'Russian-European Laika' },
  'american-bulldog': { id: 19, name: 'American Bulldog' },
  sarplaninac: { id: 7, name: 'Sarplaninac' },
  'chow-chow': { id: 21, name: 'Chow Chow' },
  'schiller-hound': { id: 5, name: 'Schiller Hound' },
  'estrela-mountain-dog': { id: 38, name: 'Estrela Mountain Dog' },
  'serbian-hound': { id: 4, name: 'Serbian Hound' },
  'clumber-spaniel': { id: 301, name: 'Clumber Spaniel' },
  'english-shepherd': { id: 9, name: 'English Shepherd' },
  'chien-francais-tricolore': { id: 334, name: 'Chien Francais Tricolore' },
  'english-pointer': { id: 10, name: 'English Pointer' },
  'english-bulldog': { id: 12, name: 'English Bulldog' },
  'east-siberian-laika': { id: 3, name: 'East Siberian Laika' },
  'german-wirehaired-pointer': { id: 310, name: 'German Wirehaired Pointer' },
  'bichon-frise': { id: 17, name: 'Bichon Frise' },
  dalmatian: { id: 22, name: 'Dalmatian' },
  'boston-terrier': { id: 18, name: 'Boston Terrier' },
  'epagneul-de-pontaudemer': { id: 27, name: 'Epagneul de Pont-Audemer' },
  'epagneul-picard': { id: 29, name: 'Epagneul Picard' },
  'irish-water-spaniel': { id: 314, name: 'Irish Water Spaniel' },
  'epagneul-bleu-de-picardie': { id: 31, name: 'Epagneul Bleu de Picardie' },
  'german-roughhaired-pointer': { id: 33, name: 'German Rough-haired Pointer' },
  'french-spaniel': { id: 30, name: 'French Spaniel' },
  'nova-scotia-duck-tolling-retriever': { id: 316, name: 'Nova Scotia Duck Tolling Retriever' },
  'english-springer-spaniel': { id: 32, name: 'English Springer Spaniel' },
  'swiss-hound': { id: 387, name: 'Swiss Hound' },
  'fresian-water-dog': { id: 35, name: 'Fresian Water Dog' },
  'griffon-bleu-de-gascogne': { id: 91, name: 'Griffon Bleu de Gascogne' },
  'german-longhaired-pointer': { id: 34, name: 'German Long-haired Pointer' },
  brittany: { id: 299, name: 'Brittany' },
  'francais-blanc-et-orange': { id: 36, name: 'Francais Blanc et Orange' },
  'great-anglo-francais-white-and-orange-hound': { id: 340, name: 'Great Anglo-Francais White and Orange Hound' },
  'finnish-hound': { id: 37, name: 'Finnish Hound' },
  'spanish-hound': { id: 39, name: 'Spanish Hound' },
  'south-russian-shepherd-dog': { id: 40, name: 'South Russian Shepherd Dog' },
  'icelandic-sheepdog': { id: 45, name: 'Icelandic Sheepdog' },
  'smooth-fox-terrier': { id: 41, name: 'Smooth Fox Terrier' },
  'hygen-hound': { id: 46, name: 'Hygen Hound' },
  'italian-hound': { id: 42, name: 'Italian Hound' },
  'hungarian-wirehaired-vizsla': { id: 47, name: 'Hungarian Wire-Haired Vizsla' },
  'istrian-coarsehaired-hound': { id: 44, name: 'Istrian Coarse-Haired Hound' },
  hokkaido: { id: 50, name: 'Hokkaido' },
  'hungarian-greyhound': { id: 48, name: 'Hungarian Greyhound' },
  'chesapeake-bay-retriever': { id: 300, name: 'Chesapeake Bay Retriever' },
  hovawart: { id: 49, name: 'Hovawart' },
  'welsh-springer-spaniel': { id: 323, name: 'Welsh Springer Spaniel' },
  'hellenic-hound': { id: 51, name: 'Hellenic Hound' },
  'tibetan-spaniel': { id: 66, name: 'Tibetan Spaniel' },
  'hanoverian-hound': { id: 52, name: 'Hanoverian Hound' },
  'shiba-inu': { id: 65, name: 'Shiba Inu' },
  hamiltonstovare: { id: 53, name: 'Hamiltonstovare' },
  'american-staffordshire-terrier': { id: 73, name: 'American Staffordshire Terrier' },
  affenpinscher: { id: 54, name: 'Affenpinscher' },
  'tibetan-terrier': { id: 67, name: 'Tibetan Terrier' },
  'american-foxhound': { id: 55, name: 'American Foxhound' },
  puli: { id: 68, name: 'Puli' },
  'aidi-atlas-mountain-dog': { id: 56, name: 'Aidi (Atlas Mountain Dog)' },
  lowchen: { id: 61, name: 'Lowchen' },
  'airedale-terrier': { id: 57, name: 'Airedale Terrier' },
  'cocker-spaniel': { id: 302, name: 'Cocker Spaniel' },
  keeshond: { id: 59, name: 'Keeshond' },
  'curly-coated-retriever': { id: 303, name: 'Curly Coated Retriever' },
  'lhasa-apso': { id: 60, name: 'Lhasa Apso' },
  'shetland-sheepdog': { id: 70, name: 'Shetland Sheepdog' },
  schipperke: { id: 63, name: 'Schipperke' },
  'silky-terrier': { id: 378, name: 'Silky Terrier' },
  'pyrenean-shepherd': { id: 69, name: 'Pyrenean Shepherd' },
  'bull-terrier': { id: 77, name: 'Bull Terrier' },
  'swedish-lapphund': { id: 71, name: 'Swedish Lapphund' },
  'cairn-terrier': { id: 78, name: 'Cairn Terrier' },
  'swedish-vallhund': { id: 72, name: 'Swedish Vallhund' },
  'white-shepherd': { id: 306, name: 'White Shepherd' },
  'australian-terrier': { id: 74, name: 'Australian Terrier' },
  'glen-of-imaal-terrier': { id: 81, name: 'Glen of Imaal Terrier' },
  'bedlington-terrier': { id: 75, name: 'Bedlington Terrier' },
  'lakeland-terrier': { id: 85, name: 'Lakeland Terrier' },
  'dandie-dinmont-terrier': { id: 79, name: 'Dandie Dinmont Terrier' },
  'great-anglo-francais-white-and-black-hound': { id: 93, name: 'Great Anglo-Francais White and Black Hound' },
  'irish-terrier': { id: 82, name: 'Irish Terrier' },
  'greenland-dog': { id: 92, name: 'Greenland Dog' },
  'jack-russell-terrier': { id: 83, name: 'Jack Russell Terrier' },
  'miniature-bull-terrier': { id: 87, name: 'Miniature Bull Terrier' },
  'cimarron-uruguayo': { id: 337, name: 'Cimarron Uruguayo' },
  'haldenstover-halden-hound': { id: 88, name: 'Haldenstover (Halden Hound)' },
  'majorca-mastiff': { id: 345, name: 'Majorca Mastiff' },
  'griffon-fauve-de-bretagne': { id: 90, name: 'Griffon Fauve de Bretagne' },
  'alentejo-mastiff': { id: 326, name: 'Alentejo Mastiff' },
  'griffon-nivernais': { id: 89, name: 'Griffon Nivernais' },
  'petit-bleu-de-gascogne': { id: 362, name: 'Petit Bleu de Gascogne' },
  'grand-basset-griffon-vendeen': { id: 97, name: 'Grand Basset Griffon Vendeen' },
  'cardigan-welsh-corgi': { id: 123, name: 'Cardigan Welsh Corgi' },
  'german-spitz': { id: 98, name: 'German Spitz' },
  'australian-cattle-dog': { id: 109, name: 'Australian Cattle Dog' },
  'norfolk-terrier': { id: 100, name: 'Norfolk Terrier' },
  'australian-shepherd': { id: 111, name: 'Australian Shepherd' },
  'norwich-terrier': { id: 101, name: 'Norwich Terrier' },
  collie: { id: 125, name: 'Collie' },
  'sealyham-terrier': { id: 103, name: 'Sealyham Terrier' },
  'basset-fauve-de-bretagne': { id: 177, name: 'Basset Fauve de Bretagne' },
  'skye-terrier': { id: 104, name: 'Skye Terrier' },
  sloughi: { id: 293, name: 'Sloughi' },
  'welsh-terrier': { id: 107, name: 'Welsh Terrier' },
  'belgian-sheepdog': { id: 116, name: 'Belgian Sheepdog' },
  bergamasco: { id: 118, name: 'Bergamasco' },
  beauceron: { id: 113, name: 'Beauceron' },
  'west-highland-white-terrier': { id: 108, name: 'West Highland White Terrier' },
  briard: { id: 121, name: 'Briard' },
  'soft-coated-wheaten-terrier': { id: 105, name: 'Soft Coated Wheaten Terrier' },
  'polish-hound': { id: 368, name: 'Polish Hound' },
  'belgian-tervuren': { id: 117, name: 'Belgian Tervuren' },
  'polish-lowland-sheepdog': { id: 130, name: 'Polish Lowland Sheepdog' },
  'bouvier-des-flandres': { id: 120, name: 'Bouvier des Flandres' },
  'norwegian-buhund': { id: 127, name: 'Norwegian Buhund' },
  'border-collie': { id: 119, name: 'Border Collie' },
  'american-water-spaniel': { id: 297, name: 'American Water Spaniel' },
  'catahoula-leopard-dog': { id: 124, name: 'Catahoula Leopard Dog' },
  'caucasian-ovcharka': { id: 139, name: 'Caucasian Ovcharka' },
  'canaan-dog': { id: 122, name: 'Canaan Dog' },
  'field-spaniel': { id: 307, name: 'Field Spaniel' },
  'german-shepherd-dog': { id: 126, name: 'German Shepherd Dog' },
  'black-russian-terrier': { id: 136, name: 'Black Russian Terrier' },
  akbash: { id: 131, name: 'Akbash' },
  'carolina-dog': { id: 329, name: 'Carolina Dog' },
  'anatolian-shepherd-dog': { id: 134, name: 'Anatolian Shepherd Dog' },
  kuvasz: { id: 152, name: 'Kuvasz' },
  'bernese-mountain-dog': { id: 135, name: 'Bernese Mountain Dog' },
  bolognese: { id: 328, name: 'Bolognese' },
  akita: { id: 132, name: 'Akita' },
  'doberman-pinscher': { id: 141, name: 'Doberman Pinscher' },
  'old-english-sheepdog': { id: 128, name: 'Old English Sheepdog' },
  komondor: { id: 151, name: 'Komondor' },
  chinook: { id: 140, name: 'Chinook' },
  'german-pinscher': { id: 145, name: 'German Pinscher' },
  'dogue-de-bordeaux': { id: 143, name: 'Dogue de Bordeaux' },
  'central-asian-shepherd': { id: 332, name: 'Central Asian Shepherd' },
  'finnish-lapphund': { id: 211, name: 'Finnish Lapphund' },
  'sussex-spaniel': { id: 320, name: 'Sussex Spaniel' },
  'great-dane': { id: 148, name: 'Great Dane' },
  'dogo-argentino': { id: 142, name: 'Dogo Argentino' },
  'fila-brasileiro': { id: 144, name: 'Fila Brasileiro' },
  'catalonian-sheepdog': { id: 330, name: 'Catalonian Sheepdog' },
  'giant-schnauzer': { id: 146, name: 'Giant Schnauzer' },
  'italian-greyhound': { id: 343, name: 'Italian Greyhound' },
  'great-pyrenees': { id: 149, name: 'Great Pyrenees' },
  'perro-de-presa-canario': { id: 156, name: 'Perro de Presa Canario' },
  mastiff: { id: 153, name: 'Mastiff' },
  'black-forest-hound': { id: 184, name: 'Black Forest Hound' },
  'greater-swiss-mountain-dog': { id: 150, name: 'Greater Swiss Mountain Dog' },
  samoyed: { id: 160, name: 'Samoyed' },
  'portuguese-water-dog': { id: 157, name: 'Portuguese Water Dog' },
  'labrador-retriever': { id: 315, name: 'Labrador Retriever' },
  'saint-bernard': { id: 159, name: 'Saint Bernard' },
  'braque-de-bourbonais': { id: 189, name: 'Braque de Bourbonais' },
  newfoundland: { id: 155, name: 'Newfoundland' },
  'austrian-black-and-tan-hound': { id: 171, name: 'Austrian Black and Tan Hound' },
  'tibetan-mastiff': { id: 163, name: 'Tibetan Mastiff' },
  appenzeller: { id: 169, name: 'Appenzeller' },
  'american-leopard-hound': { id: 164, name: 'American Leopard Hound' },
  'austrian-pinscher': { id: 172, name: 'Austrian Pinscher' },
  'american-bully': { id: 165, name: 'American Bully' },
  'basset-artesien-normand': { id: 175, name: 'Basset Artesien Normand' },
  'siberian-husky': { id: 161, name: 'Siberian Husky' },
  peekapoo: { id: 220, name: 'Peekapoo' },
  ariegeois: { id: 170, name: 'Ariegeois' },
  'bavarian-mountain-hound': { id: 178, name: 'Bavarian Mountain Hound' },
  barbet: { id: 174, name: 'Barbet' },
  'belgian-shepherd-dog': { id: 180, name: 'Belgian Shepherd Dog' },
  'berger-des-pyrenees': { id: 181, name: 'Berger des Pyrenees' },
  'cavalier-king-charles-spaniel': { id: 331, name: 'Cavalier King Charles Spaniel' },
  billy: { id: 183, name: 'Billy' },
  'braque-saintgermain': { id: 192, name: 'Braque Saint-Germain' },
  'bouvier-des-ardennes': { id: 186, name: 'Bouvier des Ardennes' },
  weimaraner: { id: 322, name: 'Weimaraner' },
  'bracco-italiano': { id: 187, name: 'Bracco Italiano' },
  'alaskan-husky': { id: 198, name: 'Alaskan Husky' },
  'braque-de-lariege': { id: 190, name: "Braque de l'Ariege" },
  'chinese-crested': { id: 336, name: 'Chinese Crested' },
  'braque-francais': { id: 191, name: 'Braque Francais' },
  'entlebucher-mountain-dog': { id: 210, name: 'Entlebucher Mountain Dog' },
  'briquet-griffon-vendeen': { id: 193, name: 'Briquet Griffon Vendeen' },
  'cesky-terrier': { id: 204, name: 'Cesky Terrier' },
  'canadian-eskimo-dog': { id: 195, name: 'Canadian Eskimo Dog' },
  'cesky-fousek': { id: 333, name: 'Cesky Fousek' },
  'alapaha-blue-blood-bulldog': { id: 197, name: 'Alapaha Blue Blood Bulldog' },
  chug: { id: 206, name: 'Chug' },
  'designer-breed-small': { id: 408, name: 'Designer Breed Small' },
  'cao-de-castro-laboreiro': { id: 196, name: 'Cao de Castro Laboreiro' },
  pekingese: { id: 360, name: 'Pekingese' },
  basador: { id: 200, name: 'Basador' },
  goldendoodle: { id: 212, name: 'Goldendoodle' },
  aussiedoodle: { id: 199, name: 'Aussiedoodle' },
  'ciobanesc-romanesc-carpatin': { id: 338, name: 'Ciobanesc Romanesc Carpatin' },
  'shih-tzu': { id: 376, name: 'Shih Tzu' },
  cavapom: { id: 202, name: 'Cavapom' },
  'german-shorthaired-pointer': { id: 309, name: 'German Shorthaired Pointer' },
  'chart-polski': { id: 205, name: 'Chart Polski' },
  'maremma-sheepdog': { id: 347, name: 'Maremma Sheepdog' },
  cockapoo: { id: 207, name: 'Cockapoo' },
  'designer-breed-medium': { id: 409, name: 'Designer Breed Medium' },
  'multi-colored-poodle': { id: 352, name: 'Multi-Colored Poodle' },
  'dutch-shepherd': { id: 209, name: 'Dutch Shepherd' },
  'spanish-greyhound': { id: 228, name: 'Spanish Greyhound' },
  labradoodle: { id: 213, name: 'Labradoodle' },
  pugapoo: { id: 222, name: 'Pugapoo' },
  doxiepoo: { id: 208, name: 'Doxiepoo' },
  puggle: { id: 223, name: 'Puggle' },
  'russkiy-toy': { id: 1, name: 'Russkiy Toy' },
  lhasapoo: { id: 216, name: 'Lhasapoo' },
  stabyhoun: { id: 14, name: 'Stabyhoun' },
  'parson-russell-terrier': { id: 358, name: 'Parson Russell Terrier' },
  'chinese-shar-pei': { id: 20, name: 'Chinese Shar-Pei' },
  poodle: { id: 62, name: 'Poodle' },
  'sporting-lucas-terrier': { id: 15, name: 'Sporting Lucas Terrier' },
  'staffordshire-bull-terrier': { id: 106, name: 'Staffordshire Bull Terrier' },
  'american-eskimo': { id: 16, name: 'American Eskimo' },
  'perdiguero-de-burgos': { id: 361, name: 'Perdiguero de Burgos' },
  'spanish-mastiff': { id: 26, name: 'Spanish Mastiff' },
  'fox-terrier': { id: 80, name: 'Fox Terrier' },
  'dutch-smoushond': { id: 13, name: 'Dutch Smoushond' },
  'scottish-terrier': { id: 64, name: 'Scottish Terrier' },
  eurasian: { id: 23, name: 'Eurasian' },
  'french-bulldog': { id: 58, name: 'French Bulldog' },
  'epagneul-breton': { id: 28, name: 'Epagneul Breton' },
  'manchester-terrier': { id: 86, name: 'Manchester Terrier' },
  'finnish-spitz': { id: 24, name: 'Finnish Spitz' },
  'border-terrier': { id: 76, name: 'Border Terrier' },
  'kerry-blue-terrier': { id: 84, name: 'Kerry Blue Terrier' },
  'old-danish-pointing-dog': { id: 355, name: 'Old Danish Pointing Dog' },
  'great-anglo-francais-tricolor-hound': { id: 94, name: 'Great Anglo-Francais Tricolor Hound' },
  bullmastiff: { id: 138, name: 'Bullmastiff' },
  'belgian-laekenois': { id: 114, name: 'Belgian Laekenois' },
  'posavaz-hound': { id: 365, name: 'Posavaz Hound' },
  'australian-kelpie': { id: 110, name: 'Australian Kelpie' },
  porcelaine: { id: 366, name: 'Porcelaine' },
  'miniature-schnauzer': { id: 99, name: 'Miniature Schnauzer' },
  'pembroke-welsh-corgi': { id: 129, name: 'Pembroke Welsh Corgi' },
  'grand-griffon-vendeen': { id: 95, name: 'Grand Griffon Vendeen' },
  'bearded-collie': { id: 112, name: 'Bearded Collie' },
  'grand-gasconsaintongeois': { id: 96, name: 'Grand Gascon-Saintongeois' },
  'belgian-malinois': { id: 115, name: 'Belgian Malinois' },
  'rat-terrier': { id: 102, name: 'Rat Terrier' },
  boxer: { id: 137, name: 'Boxer' },
  'standard-schnauzer': { id: 162, name: 'Standard Schnauzer' },
  'basset-bleu-de-gascogne': { id: 176, name: 'Basset Bleu de Gascogne' },
  'alaskan-malamute': { id: 133, name: 'Alaskan Malamute' },
  rottweiler: { id: 158, name: 'Rottweiler' },
  'neapolitan-mastiff': { id: 154, name: 'Neapolitan Mastiff' },
  'black-mouth-cur': { id: 185, name: 'Black Mouth Cur' },
  'american-hairless-terrier': { id: 166, name: 'American Hairless Terrier' },
  'cane-corso': { id: 201, name: 'Cane Corso' },
  'american-pit-bull-terrier': { id: 167, name: 'American Pit Bull Terrier' },
  maltipoo: { id: 219, name: 'Maltipoo' },
  'anglofrancais-de-petite-venerie': { id: 168, name: 'Anglo-Français de Petite Vénerie' },
  'braque-dauvergne': { id: 188, name: "Braque d'Auvergne" },
  'beagle-harrier': { id: 179, name: 'Beagle Harrier' },
  leonberger: { id: 215, name: 'Leonberger' },
  'ca-de-bestiar': { id: 194, name: 'Ca De Bestiar' },
  lurcher: { id: 218, name: 'Lurcher' },
  barak: { id: 173, name: 'Barak' },
  'podenco-canario': { id: 364, name: 'Podenco Canario' },
  'berger-picard': { id: 182, name: 'Berger Picard' },
  longdog: { id: 217, name: 'Longdog' },
  cavapoo: { id: 203, name: 'Cavapoo' },
  pomapoo: { id: 221, name: 'Pomapoo' },
  'large-munsterlander': { id: 214, name: 'Large Munsterlander' },
  schnoodle: { id: 224, name: 'Schnoodle' },
  'silken-windhound': { id: 227, name: 'Silken Windhound' },
  kooikerhondje: { id: 238, name: 'Kooikerhondje' },
  shihpoo: { id: 225, name: 'Shihpoo' },
  'lapinporokoira-lapponian-herder': { id: 234, name: 'Lapinporokoira (Lapponian Herder)' },
  'treeing-walker-coonhound': { id: 230, name: 'Treeing Walker Coonhound' },
  xoloitzcuintli: { id: 231, name: 'Xoloitzcuintli' },
  'tosa-ken': { id: 229, name: 'Tosa Ken' },
  jagdterrier: { id: 240, name: 'Jagdterrier' },
  'chien-dartois': { id: 232, name: "Chien d'Artois" },
  'karelian-bear-dog': { id: 245, name: 'Karelian Bear Dog' },
  yorkipoo: { id: 233, name: 'Yorkipoo' },
  kishu: { id: 243, name: 'Kishu' },
  'lagotto-romagnolo': { id: 236, name: 'Lagotto Romagnolo' },
  kai: { id: 247, name: 'Kai' },
  'lancashire-heeler': { id: 235, name: 'Lancashire Heeler' },
  'karst-shepherd-dog': { id: 244, name: 'Karst Shepherd Dog' },
  'japanese-akita': { id: 239, name: 'Japanese Akita' },
  'japanese-spitz': { id: 250, name: 'Japanese Spitz' },
  kromfohrlander: { id: 237, name: 'Kromfohrlander' },
  'japanese-terrier': { id: 249, name: 'Japanese Terrier' },
  lundehund: { id: 241, name: 'Lundehund' },
  'croatian-sheepdog': { id: 251, name: 'Croatian Sheepdog' },
  'kangal-dog': { id: 246, name: 'Kangal Dog' },
  'braque-francais-de-petite-taille': { id: 256, name: 'Braque Francais (de Petite Taille)' },
  'english-coonhound': { id: 11, name: 'English Coonhound' },
  'istrian-shorthaired-hound': { id: 43, name: 'Istrian Short-Haired Hound' },
  jindo: { id: 248, name: 'Jindo' },
  pomeranian: { id: 370, name: 'Pomeranian' },
  'louisiana-catahoula-leopard-dog': { id: 242, name: 'Louisiana Catahoula Leopard Dog' },
  'danish-broholmer': { id: 259, name: 'Danish Broholmer' },
  'coton-de-tulear': { id: 252, name: 'Coton de Tulear' },
  drever: { id: 262, name: 'Drever' },
  'ciobanesc-romanesc-mioritic': { id: 254, name: 'Ciobanesc Romanesc Mioritic' },
  'deutscher-wachtelhund': { id: 264, name: 'Deutscher Wachtelhund' },
  'chien-francais-blanc-et-noir': { id: 255, name: 'Chien Francais Blanc et Noir' },
  'deutsche-bracke': { id: 265, name: 'Deutsche Bracke' },
  'braque-francais-de-grande-taille': { id: 257, name: 'Braque Francais (de Grande Taille)' },
  'afghan-hound': { id: 266, name: 'Afghan Hound' },
  'danishswedish-farmdog': { id: 258, name: 'Danish-Swedish Farmdog' },
  azawakh: { id: 267, name: 'Azawakh' },
  'czechoslovakian-vlcak': { id: 260, name: 'Czechoslovakian Vlcak' },
  'designer-breed-large': { id: 410, name: 'Designer Breed Large' },
  poitevin: { id: 367, name: 'Poitevin' },
  dunker: { id: 261, name: 'Dunker' },
  'slovac-cuvac': { id: 379, name: 'Slovac Cuvac' },
  'drentse-patrijshond': { id: 263, name: 'Drentse Patrijshond' },
  'redbone-coonhound': { id: 289, name: 'Redbone Coonhound' },
  'basset-hound': { id: 269, name: 'Basset Hound' },
  dachshund: { id: 276, name: 'Dachshund' },
  beagle: { id: 270, name: 'Beagle' },
  'norwegian-elkhound': { id: 282, name: 'Norwegian Elkhound' },
  bloodhound: { id: 272, name: 'Bloodhound' },
  'english-foxhound': { id: 277, name: 'English Foxhound' },
  'bluetick-coonhound': { id: 273, name: 'Bluetick Coonhound' },
  greyhound: { id: 278, name: 'Greyhound' },
  'american-black-and-tan-coonhound': { id: 271, name: 'American Black and Tan Coonhound' },
  'stephens-cur': { id: 383, name: "Stephens' Cur" },
  borzoi: { id: 274, name: 'Borzoi' },
  'stumpy-tail-cattle-dog': { id: 384, name: 'Stumpy Tail Cattle Dog' },
  'cirneco-delletna': { id: 275, name: "Cirneco dell'Etna" },
  'peruvian-inca-orchid': { id: 284, name: 'Peruvian Inca Orchid' },
  harrier: { id: 279, name: 'Harrier' },
  'pharaoh-hound': { id: 286, name: 'Pharaoh Hound' },
  basenji: { id: 268, name: 'Basenji' },
  plott: { id: 287, name: 'Plott' },
  'ibizan-hound': { id: 280, name: 'Ibizan Hound' },
  'rhodesian-ridgeback': { id: 290, name: 'Rhodesian Ridgeback' },
  'irish-wolfhound': { id: 281, name: 'Irish Wolfhound' },
  saluki: { id: 291, name: 'Saluki' },
  otterhound: { id: 283, name: 'Otterhound' },
  'scottish-deerhound': { id: 292, name: 'Scottish Deerhound' },
  'petit-basset-griffon-vendeen': { id: 285, name: 'Petit Basset Griffon Vendeen' },
  'styrian-coarse-haired-hound': { id: 385, name: 'Styrian Coarse Haired Hound' },
  'portuguese-podengo': { id: 288, name: 'Portuguese Podengo' },
  'thai-ridgeback': { id: 294, name: 'Thai Ridgeback' },
  whippet: { id: 295, name: 'Whippet' },
  'english-setter': { id: 305, name: 'English Setter' },
  'grand-bleu-de-gascogne': { id: 296, name: 'Grand Bleu De Gascogne' },
  'small-munsterlander-pointer': { id: 318, name: 'Small Munsterlander Pointer' },
  'boykin-spaniel': { id: 298, name: 'Boykin Spaniel' },
  'swedish-elkhound': { id: 386, name: 'Swedish Elkhound' },
  'golden-retriever': { id: 311, name: 'Golden Retriever' },
  'westphalian-dachsbracke': { id: 398, name: 'Westphalian Dachsbracke' },
  'flat-coated-retriever': { id: 308, name: 'Flat Coated Retriever' },
  chihuahua: { id: 335, name: 'Chihuahua' },
  'gordon-setter': { id: 312, name: 'Gordon Setter' },
  maltese: { id: 346, name: 'Maltese' },
  pointer: { id: 317, name: 'Pointer' },
  'wirehaired-pointing-griffon': { id: 324, name: 'Wirehaired Pointing Griffon' },
  'irish-red-and-white-setter': { id: 313, name: 'Irish Red and White Setter' },
  'alpine-dachsbracke': { id: 327, name: 'Alpine Dachsbracke' },
  vizsla: { id: 321, name: 'Vizsla' },
  'multi-colored-standard-poodle': { id: 353, name: 'Multi-Colored Standard Poodle' },
  'english-toy-spaniel': { id: 339, name: 'English Toy Spaniel' },
  'miniature-pinscher': { id: 348, name: 'Miniature Pinscher' },
  'irish-setter': { id: 342, name: 'Irish Setter' },
  'mountain-cur': { id: 350, name: 'Mountain Cur' },
  mudi: { id: 351, name: 'Mudi' },
  'patterdale-terrier': { id: 359, name: 'Patterdale Terrier' },
  havanese: { id: 341, name: 'Havanese' },
  papillon: { id: 357, name: 'Papillon' },
  'japanese-chin': { id: 344, name: 'Japanese Chin' },
  shikoku: { id: 377, name: 'Shikoku' },
  'montenegrin-mountain-hound': { id: 349, name: 'Montenegrin Mountain Hound' },
  'petit-gasconsaintongeois': { id: 363, name: 'Petit Gascon-Saintongeois' },
  norrbottenspetz: { id: 354, name: 'Norrbottenspetz' },
  pudelpointer: { id: 371, name: 'Pudelpointer' },
  'olde-english-bulldogge': { id: 356, name: 'Olde English Bulldogge' },
  'portuguese-pointer': { id: 402, name: 'Portuguese Pointer' },
  'slovakian-wirehaired-pointing-dog': { id: 380, name: 'Slovakian Wire-haired Pointing Dog' },
  'pyrenean-mastiff': { id: 374, name: 'Pyrenean Mastiff' },
  'owczarek-podhalanski': { id: 369, name: 'Owczarek Podhalanski' },
  'portuguese-sheepdog': { id: 403, name: 'Portuguese Sheepdog' },
  pug: { id: 372, name: 'Pug' },
  'smaland-hound': { id: 381, name: 'Smaland Hound' },
  'serbian-tricolor-hound': { id: 375, name: 'Serbian Tricolor Hound' },
  'toy-fox-terrier': { id: 390, name: 'Toy Fox Terrier' },
  pumi: { id: 373, name: 'Pumi' },
  boerboel: { id: 412, name: 'Boerboel' },
  'spinone-italiano': { id: 319, name: 'Spinone Italiano' },
  'transylvanian-hound': { id: 391, name: 'Transylvanian Hound' },
  'welsh-hound': { id: 396, name: 'Welsh Hound' },
  tornjak: { id: 389, name: 'Tornjak' },
  'west-siberian-laika': { id: 397, name: 'West Siberian Laika' },
  'wire-fox-terrier': { id: 399, name: 'Wire Fox Terrier' },
  'miniature-poodle': { id: 407, name: 'Miniature Poodle' },
  'small-swiss-hound': { id: 382, name: 'Small Swiss Hound' },
  biewer: { id: 411, name: 'Biewer' },
  'treeing-cur': { id: 392, name: 'Treeing Cur' },
  'yorkshire-terrier': { id: 400, name: 'Yorkshire Terrier' },
  'treeing-feist': { id: 393, name: 'Treeing Feist' },
  'miniature-australian-shepherd': { id: 414, name: 'Miniature Australian Shepherd' },
  'tyrolean-hound': { id: 394, name: 'Tyrolean Hound' },
  'miniature-dachshund': { id: 415, name: 'Miniature Dachshund' },
  'teddy-roosevelt-terrier': { id: 388, name: 'Teddy Roosevelt Terrier' },
  'toy-poodle': { id: 404, name: 'Toy Poodle' },
  'volpino-italiano': { id: 395, name: 'Volpino Italiano' },
  'brussels-griffon': { id: 406, name: 'Brussels Griffon' },
  'alaskan-klee-kai': { id: 401, name: 'Alaskan Klee Kai' },
  morkie: { id: 416, name: 'Morkie' },
  bernedoodle: { id: 417, name: 'Bernedoodle' },
  sheepadoodle: { id: 418, name: 'Sheepadoodle' },
  shichon: { id: 419, name: 'Shichon' },
  cavachon: { id: 420, name: 'Cavachon' },
  pomsky: { id: 421, name: 'Pomsky' },
  'english-cream-golden-retriever': { id: 422, name: 'English Cream Golden Retriever' },
  'toy-australian-shepherd': { id: 423, name: 'Toy Australian Shepherd' },
  'native-american-indian-dog': { id: 424, name: 'Native American Indian Dog' },
  'standard-poodle': { id: 425, name: 'Standard Poodle' },
  'miniature-shar-pei': { id: 426, name: 'Miniature Shar-Pei' },
  frenchton: { id: 427, name: 'Frenchton' },
  'mi-ki': { id: 428, name: 'Mi-Ki' },
  havamalt: { id: 429, name: 'Havamalt' },
  chorkie: { id: 430, name: 'Chorkie' },
  shorkie: { id: 431, name: 'Shorkie' },
  bichpoo: { id: 432, name: 'Bichpoo' },
  'king-shepherd': { id: 433, name: 'King Shepherd' },
  'golden-cocker-retriever': {
    id: 434,
    name: 'Golden Cocker Retriever',
  },
  havapoo: { id: 435, name: 'Havapoo' },
  'mal-shi': { id: 436, name: 'Mal Shi' },
  shiranian: { id: 437, name: 'Shiranian' },
  cockalier: { id: 438, name: 'Cockalier' },
  'carpathian-sheepdog': { id: 439, name: 'Carpathian Sheepdog' },
  'shorty-bull': { id: 440, name: 'Shorty Bull' },
  'mcnab-shepherd': { id: 441, name: 'McNab Shepherd' },
  'english-mastiff': { id: 442, name: 'English Mastiff' },
  'american-mastiff': { id: 443, name: 'American Mastiff' },
  'miniature-american-shepherd': { id: 444, name: 'Miniature American Shepherd' },
  'miniature-bernedoodle': { id: 445, name: 'Miniature Bernedoodle' },
  'golden-irish': { id: 446, name: 'Golden Irish' },
  'miniature-golden-retriever': { id: 447, name: 'Miniature Golden Retriever' },
  'saint-berdoodle': { id: 448, name: 'Saint Berdoodle' },
  'irish-doodle': { id: 449, name: 'Irish Doodle' },
  poochon: { id: 450, name: 'Poochon' },
  tamaskan: { id: 451, name: 'Tamaskan' },
  whoodle: { id: 452, name: 'Whoodle' },
  poovanese: { id: 453, name: 'Poovanese' },
  'springer-doodle': { id: 454, name: 'Springer Doodle' },
  'ori-pei': { id: 455, name: 'Ori Pei' },
  havaton: { id: 456, name: 'Havaton' },
  'russian-tsvetnaya-bolonk': { id: 457, name: 'Russian Tsvetnaya Bolonk' },
  'miniature-aussiedoodle': { id: 458, name: 'Miniature Aussiedoodle' },
  pyredoodle: { id: 459, name: 'Pyredoodle' },
  shepadoodle: { id: 460, name: 'Shepadoodle' },
  chipoo: { id: 461, name: 'Chipoo' },
  pomchi: { id: 462, name: 'Pomchi' },
  'valley-bulldog': { id: 463, name: 'Valley Bulldog' },
  'olde-boston-bulldogge': { id: 468, name: 'olde-boston-bulldogge' },
  'yakutian-aika': { id: 469, name: 'yakutian-aika' },
  'irish-wolfadoodle': { id: 497, name: 'irish-wolfadoodle' },
  bordoodle: { id: 498, name: 'bordoodle' },
  'golden-mountain-dog': { id: 499, name: 'golden-mountain-dog' },
  'australian-cobberdog': { id: 500, name: 'australian-cobberdog' },
  'english-goldendoodle': { id: 501, name: 'english-goldendoodle' },
  'miniature-goldendoodle': { id: 502, name: 'miniature-goldendoodle' },
  'golden-mountain-doodle': { id: 503, name: 'golden-mountain-doodle' },
  'blue-lacy': { id: 504, name: 'blue-lacy' },
  mastador: { id: 505, name: 'mastador' },
  'bohemian-shepherd': { id: 506, name: 'bohemian-shepherd' },
  'basque-shepherd-dog': { id: 507, name: 'basque-shepherd-dog' },
  'chinese-chongqing': { id: 508, name: 'chinese-chongqing' },
  'miniature-labradoodle': { id: 509, name: 'miniature-labradoodle' },
  chiweenie: { id: 510, name: 'chiweenie' },
  beaglier: { id: 511, name: 'beaglier' },
  eskipoo: { id: 512, name: 'eskipoo' },
  goldador: { id: 513, name: 'goldador' },
  'double-doodle': { id: 514, name: 'double-doodle' },
  cavaton: { id: 515, name: 'cavaton' },
  goberian: { id: 516, name: 'goberian' },
  westiepoo: { id: 517, name: 'westiepoo' },
  'australian-labradoodle': { id: 518, name: 'australian-labradoodle' },
  'colombian-fino-hound': { id: 519, name: 'colombian-fino-hound' },
  malchi: { id: 520, name: 'malchi' },
  newfypoo: { id: 521, name: 'newfypoo' },
  aussalier: { id: 522, name: 'aussalier' },
  havachon: { id: 523, name: 'havachon' },
  huskydoodle: { id: 524, name: 'huskydoodle' },
  'karakachan ': { id: 525, name: 'karakachan' },
  havashire: { id: 526, name: 'havashire' },
  pomimo: { id: 527, name: 'pomimo' },
  'golden-pyrenees': { id: 528, name: 'golden-pyrenees' },
  shepweiler: { id: 529, name: 'shepweiler' },
  maltipom: { id: 530, name: 'maltipom' },
  'peek-a-pom': { id: 531, name: 'peek-a-pom' },
  'sprocker-spaniel': { id: 532, name: 'sprocker-spaniel' },
  bernefie: { id: 533, name: 'bernefie' },
  japug: { id: 534, name: 'japug' },
  'thai-bangkaew': { id: 535, name: 'thai-bangkaew' },
  'mini-yorkshire-aussie': { id: 563, name: 'mini-yorkshire-aussie' },
};
export default function getBreedFromUrl(slug) {
  return breedUrlMap[slug?.replace(/[()]/g, '')];
}
