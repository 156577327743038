import React from 'react';

function Add() {
  /* eslint-disable max-len */
  return (
    <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.419 14.733h-.925c-.273 0-.494-.209-.494-.466s.221-.465.494-.465h.67a1.57 1.57 0 0 1-.007-.32h-.663c-.273 0-.494-.208-.494-.465s.221-.465.494-.465h.905l1.264-4.67c.176-.379.63-.658 1.067-.658h3.66V5.517c0-.257.222-.465.494-.465h4.274c.272 0 .493.208.493.465v1.707h3.429c.438 0 .89.279 1.067.658l1.264 4.67h.893c.272 0 .493.208.493.465s-.22.466-.493.466h-.651a1.57 1.57 0 0 1-.008.319h.659c.272 0 .493.208.493.465s-.22.466-.493.466h-.913l-1.244 4.596c-.176.378-.63.657-1.067.657H5.73c-.439 0-.89-.278-1.067-.657l-1.244-4.596zm6.959-7.509h3.286V5.983h-3.286v1.241zm-1.01 8.992c-.612.086-1.214-.635-1.214-.635-.014 1.316 1.298 1.41 1.944 1.377.546.123 1.484.232 2.2.246a9.691 9.691 0 0 0 1.816-.13s.229-.601-1.194-.586c0 0 .91-.111.844-1.228 0 0 .87 1.754 1.318 1.748.589-.007.302-.232-.26-1.481-.658-1.46-.8-2.359-1.475-2.74 1.297-.926.998-1.848.644-2.226a.248.248 0 0 0 .019-.087.267.267 0 0 0-.269-.264.274.274 0 0 0-.262.169 1.91 1.91 0 0 0-.182.017c-.393-.827-1.155-1.077-2.14-.769-1.074.336-1.475 1.359-1.12 2.374.183.523.44.88.792 1.059-.721.516-1.202 1.395-1.37 2.387-.042.25-.098.524-.09.77z"
        fillRule="nonzero"
      />
    </svg>
  );
  /* eslint-enable */
}

export default Add;
