import React from 'react';
import Rocket from '../icons/Rocket';

const BoostSuccess = function({
  listingDays,
  boostedListings,
  styles,
  setBoostSteps,
  setBoostedListings,
  setShowModal,
}) {
  const boostAmount = (listingDays === 7 ? listingDays - 1 : listingDays) * boostedListings.length;
  return (
    <div className={styles.successContainer}>
      <div className={styles.modalHeader}>Listings boosted!</div>
      <input type="hidden" id="boost_total" value={boostAmount} />
      <div className={styles.rocketContainer}>
        <Rocket />
      </div>

      <div className={styles.boostText}>
        They'll show up at the front of default search results for a state or city search.
      </div>
      <div className={styles.successFooter}>
        <div>
          <button
            onClick={() => {
              setBoostedListings([]);
              setBoostSteps(1);
              setShowModal(false);
            }}
            className={styles.closeBtn}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default BoostSuccess;
