import React from 'react';
import { Map } from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';

import { ModalPaymentHeader } from '../../../../../common/components';

import formatPrice from '../../../../../common/utils/formatPrice';

export const formatExpirationDate = expirationDate => {
  return moment(expirationDate).format('MM/DD/YYYY');
};

export const pluralizeMonth = discountDuration => {
  return discountDuration === 1 ? 'month' : 'months';
};

const PremierModalPaymentHeader = ({ price, selectedPlan, userVM }) => {
  return (
    <ModalPaymentHeader
      title="Complete your purchase"
      subTitle={<SubTitleText price={price} selectedPlan={selectedPlan} userVM={userVM} />}
    />
  );
};

PremierModalPaymentHeader.propTypes = {
  price: PropTypes.number,
  selectedPlan: PropTypes.instanceOf(Map),
  userVM: PropTypes.instanceOf(Map),
};

const SubTitleText = ({ price, selectedPlan, userVM }) => {
  const discountDuration = userVM.getIn(['currentPremierCoupon', 'monthsRemaining']);
  const formattedBasePrice = formatPrice(selectedPlan.get('price'));
  const formattedPrice = formatPrice(price);
  const futureBillingDate = formatExpirationDate(userVM.get('expirationDate'));
  const isCurrentMembershipActive = userVM.get('isPremier') && moment(userVM.get('expirationDate')).isAfter();

  if (userVM.get('currentPremierCoupon')) {
    if (!price) {
      return (
        <span data-testid="free-membership">
          Your Premier Membership is <strong>free</strong> for the first {discountDuration}{' '}
          {pluralizeMonth(discountDuration)}. After that time, you will be charged the standard monthly rate of{' '}
          {formattedBasePrice}.
        </span>
      );
    }

    if (isCurrentMembershipActive) {
      return (
        <span data-testid="discounted-current-membership">
          You will be billed for <strong>{formattedPrice}</strong> on <strong>{futureBillingDate}</strong>. This is a
          repeating charge that will last for {discountDuration} {pluralizeMonth(discountDuration)}. After that time,
          you will be charged the standard monthly rate of {formattedBasePrice}.
        </span>
      );
    }

    return (
      <span data-testid="discounted-new-membership">
        You'll be charged a discounted price of <strong>{formattedPrice}</strong> for a Premier Membership. This is a
        repeating charge that will last for {discountDuration} {pluralizeMonth(discountDuration)}. After that time, you
        will be charged the standard monthly rate of {formattedBasePrice}.
      </span>
    );
  }

  if (isCurrentMembershipActive) {
    return (
      <span data-testid="current-membership">
        You will be billed for <strong>{formattedPrice}</strong> on <strong>{futureBillingDate}</strong>.
      </span>
    );
  }

  return (
    <span data-testid="new-membership">
      You'll be charged <strong>{formattedPrice}</strong> for a Premier Membership. This is a <strong>repeating</strong>{' '}
      charge.
    </span>
  );
};

SubTitleText.propTypes = {
  price: PropTypes.number,
  selectedPlan: PropTypes.instanceOf(Map).isRequired,
  userVM: PropTypes.instanceOf(Map).isRequired,
};

export default PremierModalPaymentHeader;
