import React from 'react';
import YourCard from './YourCard';
import moment from 'moment';
import { Button, ButtonPlain, Icon, Modal, ModalBody, ModalFooter } from '../../../../../common/components';

const PendingMembershipCard = ({ userVM, styles, onCancelPendingMemberhip }) => {
  const [showModal, setShowModal] = React.useState(false);
  if (userVM.get('hasPendingMembershipTransaction')) {
    return (
      <>
        <div className={styles.membershipLevel} aria-label="Pending Membership Card">
          <div className={styles.membershipLevelText}>
            <h4>
              Your new{' '}
              <strong>{userVM.get('hasPendingSubscription') ? 'Premier Membership' : 'Standard Membership'}</strong>{' '}
              will start on <strong>{moment(userVM.get('pendingMembershipBillDate')).format('MM/DD/YYYY')}</strong>
            </h4>
            <p>
              <YourCard userVM={userVM} /> will be charged on this date.
            </p>
          </div>
          <ButtonPlain aria-label="Cancel Pending Membership" onClick={() => setShowModal(true)} variant="red">
            Modify Membership
          </ButtonPlain>
        </div>
        <CancelPendingMembershipModal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          onConfirm={() => onCancelPendingMemberhip()}
          styles={styles}
        />
      </>
    );
  }

  return null;
};

const CancelPendingMembershipModal = ({ isOpen, onConfirm, onRequestClose, styles }) => {
  return (
    <Modal closeOnEscape closeOnOutsideClick onClose={onRequestClose} isOpen={isOpen}>
      <ModalBody>
        <div className={styles.cancelModalBody}>
          <Icon icon="Warning" size={40} />
          <h1>Sure about that?</h1>
          <p>Your pending Membership will be canceled and you will not be billed.</p>
        </div>
      </ModalBody>
      <ModalFooter
        actions={[
          <ButtonPlain onClick={() => onRequestClose()}>Never mind, go back</ButtonPlain>,
          <Button
            aria-label="Confirm Cancel Pending Membership"
            type="button"
            buttonType="round"
            onClick={() => {
              onConfirm();
              onRequestClose();
            }}
          >
            CONFIRM
          </Button>,
        ]}
      />
    </Modal>
  );
};

export default PendingMembershipCard;
