import React from 'react';
import classNames from 'classnames';
import MessageTimestamp from './MessageTimestamp';
import MessagePhotos from './MessagePhotos';
const OtherUserMessage = ({ message, styles, newFromId }) => {
  const isNewMessage = newFromId === message.get('id');

  return (
    <div
      key={message.get('id')}
      className={classNames(styles.messageWrapper, isNewMessage ? styles.messageWrapperNew : null)}
      aria-label="Other user"
      role="log"
    >
      {isNewMessage ? <div className={styles.newMessageBorder}>New</div> : null}
      {message.get('body') !== 'NO_CONTENT' ? (
        <div className={styles.innerMessageWrapper}>
          <div className={classNames(styles.message)}>
            <pre style={{ whiteSpace: 'pre-line' }}>{message.get('body')}</pre>
          </div>
        </div>
      ) : null}
      <div className={styles.messagePhotosContainer}>
        {message.get('photos') ? <MessagePhotos styles={styles} photos={message.get('photos')} /> : null}
      </div>
      <MessageTimestamp styles={styles} message={message} />
    </div>
  );
};

export default OtherUserMessage;
