import React from 'react';

function Search() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M15.002 0c4.969 0 9 4.031 9 9 0 4.968-4.031 9-9 9-1.781 0-3.422-.505-4.804-1.395l-6.773 6.808c-.399.387-.926.586-1.418.586-.528 0-1.031-.2-1.418-.586-.785-.774-.785-2.05 0-2.836l6.808-6.773C6.507 12.421 6.003 10.781 6.003 9c0-4.969 4.03-9 9-9zm0 15c3.305 0 6-2.696 6-6 0-3.305-2.695-6-6-6-3.304 0-6 2.695-6 6 0 3.304 2.696 6 6 6z" />
    </svg>
  );
  /* eslint-enable */
}

export default Search;
