import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TestimonialModalSeller from './TestimonialModalSeller';

import { createOrUpdateTestimonial, replyToTestimonial } from '../../../views/Testimonials/redux/actions';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({ createOrUpdateTestimonial, replyToTestimonial }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialModalSeller);
