import React from 'react';
import { Button, ButtonPlain, ModalFooter, ModalBody } from '..';

const PaymentForm = ({ confirmButtonText = 'Pay Now', token, onCancel, onSubmit }) => {
  const { handleSubmit, isFormLoaded, isFormSubmitting } = useBraintreePaymentForm(token, onSubmit);

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <ModalBody>
        <div id="braintree-container" />
      </ModalBody>
      {isFormLoaded ? (
        <ModalFooter
          actions={[
            <ButtonPlain variant="red" onClick={onCancel}>
              Cancel
            </ButtonPlain>,
            <Button buttonType="round" type="button" onClick={handleSubmit} disabled={isFormSubmitting}>
              {confirmButtonText}
            </Button>,
          ]}
        />
      ) : null}
    </form>
  );
};

const useBraintreePaymentForm = (token, onSubmit) => {
  const [isFormLoaded, setIsFormLoaded] = React.useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);
  const [instance, setInstance] = React.useState(null);

  React.useEffect(() => {
    if (token) {
      (async () => {
        const braintree = await import('braintree-web-drop-in');
        braintree.create(
          {
            authorization: token,
            container: '#braintree-container',
            card: {
              cardholderName: {
                required: true,
              },
              overrides: {
                styles: {
                  input: {
                    padding: '0 8px',
                  },
                },
              },
            },
          },
          (err, instance) => {
            if (instance) {
              setInstance(instance);
              setIsFormLoaded(true);
            } else {
              console.error(err || 'Payment form instance could not be initialized');
            }
          }
        );
      })();
    }
  }, [token]);

  React.useEffect(() => {
    return () => {
      if (instance) {
        instance.teardown();
      }
    };
  }, [instance]);

  const handleSubmit = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setIsFormSubmitting(true);

    if (!instance) {
      console.error('Payment form instance not initialized yet');
      setIsFormSubmitting(false);
    } else {
      instance.requestPaymentMethod((err, payload) => {
        if (payload) {
          onSubmit(payload.nonce);
        } else {
          console.error(err || 'An unknown error occurred when submitting payment form');
          setIsFormSubmitting(false);
        }
      });
    }
  };

  return {
    handleSubmit,
    isFormLoaded,
    isFormSubmitting,
  };
};

export default PaymentForm;
