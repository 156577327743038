import React from 'react';

function Block() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M12 0c6.645 0 12 5.355 12 12s-5.355 12-12 12S0 18.645 0 12 5.355 0 12 0zM3 15h18V9H3v6z" />
    </svg>
  );
  /* eslint-enable */
}

export default Block;
