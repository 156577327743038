import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ForgotPasswordForm from './ForgotPasswordForm';
import UserAuthenticationLayout from '../../layouts/UserAuthenticationLayout';

class ForgotPassword extends Component {
  render() {
    const { forgotPassword, isLoggedIn } = this.props;
    if (isLoggedIn) {
      return <Redirect to="/find-a-puppy" />;
    }
    return (
      <UserAuthenticationLayout>
        <ForgotPasswordForm submitAction={forgotPassword} />
      </UserAuthenticationLayout>
    );
  }
}

export default ForgotPassword;
